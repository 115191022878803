type EnvParser<T> = (val: string | undefined, def?: T) => T | undefined;
type EnvGetter<T> = (key: string, def?: T) => T | undefined;

const parseVar =
  <T>(mapper: (val: string) => T): EnvParser<T> =>
  (val: string | undefined, def?: T): T | undefined => {
    if (val === undefined) {
      if (def !== undefined) {
        return def;
      }
      return undefined;
    }
    return mapper(val);
  };

const getVar =
  <T>(parser: EnvParser<T>): EnvGetter<T> =>
  (key: string, def?: T) =>
    parser(process.env[key], def);

export const parseString = parseVar<string>((val) => val);
export const getString = getVar(parseString);

export const parseBool = parseVar<boolean>((val) => {
  switch (val) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      throw new Error(`invalid bool var '${val}'`);
  }
});
export const getBool = getVar(parseBool);

export const parseNumber = parseVar<number>((val) => {
  const num = Number.parseFloat(val);
  if (isNaN(num)) {
    throw new Error(`not a number '${val}'`);
  }
  return num;
});
export const getNumber = getVar(parseNumber);
