import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { IReportColumns, ReportTable } from "../components/table";
import { ReportStatisticsBox } from "../components/statistics-box";
import { ReportPieChart } from "../components/pie-chart";
import { ReportTypography } from "../components/report-typography";
import { omitRedundantFields } from "./report-gwp-summary-page";
import { bsabCodes } from "../bsab-codes";
import { replaceRestInRestRow } from "../../../lib/format";
import useDsrQueryHook from "../hooks/useDsrQueryHook";

import { ReportPageProps } from "../../report-page";
import { rounderInt, rounderTwo } from "../../../lib/rounder";

export default function ReportBsabDetailPage({
  pageNumber,
  projectId,
  scenarioId,
  selectedDatasetIds,
}: ReportPageProps) {
  const chapter = "Resultat";
  const pageTitle = "Specifik BSAB 96-kod";

  const bsabBEUnit = useDsrQueryHook({
    filter: [],
    group: [{ col: "ProductBSAB" }, { col: "BuildingElementUnit" }],
    aggregations: [
      { col: "weight", op: "sum", reduceAggOp: "sum" },
      {
        col: "BuildingElementAmount",
        withinGuidOp: "max",
        op: "sum",
        reduceAggOp: "sum",
      },
      { col: "weightPerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 22,
  });

  const bsabOnGenericMaterial = useDsrQueryHook({
    filter: [],
    group: [{ col: "ProductBSAB" }],
    aggregations: [
      { col: "weight", op: "sum", reduceAggOp: "sum" },
      { col: "A1_A5", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5", desc: true }],
    topN: 8,
  });

  const rows = omitRedundantFields(bsabBEUnit.data)
    .map(({ weight = 0, ProductBSAB = "-", ...row }: any) => ({
      ...row,
      weight: rounderInt(weight / 1000),
      ProductBSAB: bsabCodes[ProductBSAB] || "Saknar BSAB-kod",
      ProductBSABCode: ProductBSAB,
      BuildingElementAmount: `${rounderInt(
        row.BuildingElementAmount || 0
      ).toLocaleString("fr")} ${row.BuildingElementUnit}`,
      A1_A5PerBTA: rounderTwo(row.A1_A5PerBTA || 0),
    }))
    .filter(({ A1_A5PerBTA }) => A1_A5PerBTA > 0)
    .sort((a: any, b: any) => {
      if (a.ProductBSABCode < b.ProductBSABCode) {
        return -1;
      }
      if (a.ProductBSABCode > b.ProductBSABCode) {
        return 1;
      }
      return 0;
    });

  const rows2 = omitRedundantFields([
    ...bsabOnGenericMaterial.data,
    ...(bsabOnGenericMaterial.rest
      ? [{ ...bsabOnGenericMaterial.rest, ProductBSAB: "Resterande" }]
      : []),
  ])
    .map((r) => ({
      name:
        r.ProductBSAB === "Resterande"
          ? "Resterande"
          : `${r.ProductBSAB} - ${bsabCodes[r.ProductBSAB]}` ||
            "Saknar BSAB-kod",
      value: r.A1_A5,
    }))
    .filter((d) => d.name !== "Totalsumma");

  const rowsBuildingElementChart = omitRedundantFields([
    ...[...bsabOnGenericMaterial.data].sort(
      (a: any, b: any) => b.weight - a.weight
    ),
    ...(bsabOnGenericMaterial.rest
      ? [{ ...bsabOnGenericMaterial.rest, ProductBSAB: "Resterande" }]
      : []),
  ])
    .map((r) => ({
      name:
        r.ProductBSAB === "Resterande"
          ? "Resterande"
          : `${r.ProductBSAB} - ${bsabCodes[r.ProductBSAB]}` ||
            "Saknar BSAB-kod",
      value: r.weight,
    }))
    .filter((d) => d.name !== "Totalsumma");

  const columns: IReportColumns[] = [
    {
      label: "BSAB-kod",
      value: "ProductBSABCode",
      width: "10%",
      align: "left",
    },
    {
      label: "Beskrivning",
      value: "ProductBSAB",
      width: "60%",
      align: "left",
    },
    {
      label: "Mängd",
      align: "right",
      value: "BuildingElementAmount",
    },
    {
      label: "kg CO₂e/BTA",
      value: "A1_A5PerBTA",
      align: "right",
    },
  ];

  return (
    <ReportContainer>
      <ReportHeader chapter={chapter} />
      <ReportBodyAlignSpaceBetween>
        <Box mb={1} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>
        <Box flexGrow={1}>
          <ReportTable
            columns={columns}
            hasRawMaterials={true}
            tableUnit="none"
            rows={rows}
            sumRow={{
              ...bsabBEUnit.sum,
              weight: (bsabBEUnit.sum?.weight || 0) / 1000,
              BuildingElementAmount: "",
            }}
            restRow={replaceRestInRestRow({
              ...bsabBEUnit.rest,
              BuildingElementAmount: "",
            })}
          />
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <ReportStatisticsBox
                chart={() => <ReportPieChart data={rows2 || []} />}
                title="Klimatpåverkan per BSAB-kod"
                caption="Fördelning på BSAB-kod, andel av klimatpåverkan"
              />
            </Box>
            <Box>
              <ReportStatisticsBox
                chart={() => (
                  <ReportPieChart data={rowsBuildingElementChart || []} />
                )}
                title="Vikt per BSAB-kod"
                caption="Fördelning på BSAB-kod, andel av vikt"
              />
            </Box>
          </Box>
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} paddingTop={2} />
    </ReportContainer>
  );
}
