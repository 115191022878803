import "react-tabs/style/react-tabs.css";
// @ts-ignore
// import { deepmerge } from "@material-ui/utils";
import { deepmerge } from "@mui/utils";
import "react-datasheet/lib/react-datasheet.css";
import "fontsource-quicksand";
import "fontsource-raleway/500.css";

import { createTheme as createTheme2 } from "@mui/material";
import { createTheme } from "@material-ui/core/styles";
import { extendTheme } from "@chakra-ui/react";
import { colors } from "../lib/styles/colors";

export const chakraTheme = extendTheme({
  styles: {
    global: () => ({
      "@print": {
        margin: "none",
        size: "A4",
        body: {
          WebkitPrintColorAdjust: "exact",
          MozPrintColorAdjust: "exact",
          msPrintColorAdjust: "exact",
          printColorAdjust: "exact",
        },
      },
    }),
  },
});
export const muiTheme = createTheme2({});
export const theme = deepmerge(chakraTheme, muiTheme);
export const materialUiCoreTheme = createTheme({
  palette: {
    primary: {
      light: colors.plant.lightGreen,
      main: colors.plant.green,
      dark: colors.plant.darkGreen,
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
          margin: 0,
          height: "100%",
          fontSize: 13,
        },
        body: {
          margin: 0,
          padding: 0,
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
      },
    },
    MuiTooltip: { tooltip: { fontSize: 12 } },
    MuiStepIcon: {
      root: {
        "&$completed": {
          color: colors.plant.green,
        },
        "&$active": {
          color: colors.plant.green,
        },
      },
      active: {},
      completed: {},
    },
  },
});

export type DefaultTheme = typeof materialUiCoreTheme;
