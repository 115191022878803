import { chunk } from "lodash";
import {
  LcaDataTypeEnum,
  RawMaterialFieldsFragment,
} from "../../../../lib/generated/graphql";
import { ReportBodyNoMargin } from "../../components/body";
import {
  Box,
  lighten,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useTheme,
} from "@material-ui/core";
import { ReportTypography } from "../../components/report-typography";
import { ReportFooter } from "../../components/report-footer";
import { ReportContainerHorizontal } from "../../components/report-container-horizontal";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import rounderOf from "../../../../lib/rounder";
import { DefaultTheme } from "../../../../providers/styling";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeaderRow: {
      padding: 0,
      fontWeight: "bold",
    },
  })
);

const roundOne = rounderOf(1);
const roundTwo = rounderOf(2);

const RawMaterialPages: React.FC<{
  pageNumber?: number;
  rawMaterials: RawMaterialFieldsFragment[];
}> = ({ pageNumber, rawMaterials }) => {
  const { tableHeaderRow } = useStyles();
  const theme: Theme = useTheme<DefaultTheme>();
  const alphaArray = [...rawMaterials].sort((a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  const chunkedRawMaterials = chunk(alphaArray, 30);

  return (
    <>
      {chunkedRawMaterials.map((rawMaterialChunk, chunkNumber) => (
        <ReportContainerHorizontal key={`chunk-${chunkNumber}`}>
          <ReportBodyNoMargin>
            <Box mb={4}>
              <Box textAlign="left" maxWidth={theme.spacing(100)}>
                <Box mb={2}>
                  <ReportTypography variant="h2">Materialdata</ReportTypography>
                </Box>
              </Box>
            </Box>
            <Table size="small">
              <TableBody>
                <TableRow style={{ fontWeight: "bold", padding: "0 5px" }}>
                  <TableCell padding="none" style={{ paddingLeft: 10 }}>
                    Råmaterial
                  </TableCell>
                  <TableCell className={tableHeaderRow}>Källa</TableCell>
                  <TableCell className={tableHeaderRow} align="right">
                    kg / m³
                  </TableCell>
                  <TableCell className={tableHeaderRow} align="right">
                    A1_A3, CO2e/kg
                  </TableCell>
                  <TableCell className={tableHeaderRow} align="right">
                    A4, CO2e/kg
                  </TableCell>
                  <TableCell
                    className={tableHeaderRow}
                    align="right"
                    padding="none"
                  >
                    Spill %
                  </TableCell>
                </TableRow>
                {rawMaterialChunk.map((rm, itter) => {
                  return (
                    <TableRow
                      key={rm.id}
                      style={{
                        background:
                          itter % 2
                            ? undefined
                            : lighten(theme.palette.primary.light, 0.94),
                      }}
                    >
                      <TableCell padding="none" style={{ paddingLeft: 10 }}>
                        {rm.LCADataType ===
                        LcaDataTypeEnum.UserCreatedBuildingElement ? (
                          <span>{`Sammansatt byggdel - ${rm.name}`}</span>
                        ) : (
                          <a
                            href={rm.sourceUrl ?? ""}
                            target="_blank"
                            style={{ color: theme.palette.primary.dark }}
                            rel="noreferrer"
                          >
                            {rm.name}
                          </a>
                        )}
                      </TableCell>

                      <TableCell padding="none">
                        {rm.sourceName ?? ""}
                      </TableCell>
                      <TableCell align="right" padding="none">
                        {roundOne(rm.kgPerM3)?.toLocaleString("fr")}
                      </TableCell>

                      <TableCell align="right" padding="none">
                        {roundTwo(rm.A1_A3)}
                      </TableCell>
                      <TableCell align="right" padding="none">
                        {roundTwo(rm.A4)}
                      </TableCell>
                      <TableCell align="right" padding="none">
                        {roundOne(rm.spill)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </ReportBodyNoMargin>
          <ReportFooter pageNumber={(pageNumber ?? 0) + chunkNumber} />
        </ReportContainerHorizontal>
      ))}
    </>
  );
};

export default RawMaterialPages;
