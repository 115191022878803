import { Box, CircularProgress } from "@material-ui/core";
import { ReportFooter } from "../../../report/components/report-footer";
import { ReportContainer } from "../../../report/components/report-container";
import { ReportHeaderCommon as ReportHeader } from "../../../report/components/report-header";
import { ReportBodyAlignSpaceBetween } from "../../../report/components/body";
import { ReportTable } from "../../../report/components/table";
import { ReportTypography } from "../../../report/components/report-typography";
import {
  useSimplifiedTenantAdaptionProjectQuery,
  Unit,
} from "../../../../lib/generated/graphql";
import { SingleReportPage } from "./index";

const formatUnit = (unit?: Unit) => (unit === "m2" ? "kvm" : unit);

const ScenarioPage: SingleReportPage<{ scenarioId: string }> = ({
  projectId,
  scenarioId,
  pageNumber,
  reportTemplate,
}) => {
  const chapter = "Resultat";
  const pageTitle = "Mängdberäkning";
  const { data: projectData, loading: loadingProject } =
    useSimplifiedTenantAdaptionProjectQuery({
      variables: { id: projectId },
    });

  if (loadingProject) {
    return <CircularProgress />;
  }
  const project = projectData?.simplifiedTenantAdaption?.project!;

  const rows = project.template.data.headers.flatMap((header) =>
    header.rows.map((row) => {
      const scenRows =
        project.scenarios
          .find((s) => s.id === scenarioId)
          ?.rows.filter((r) => r.rowKey === row.key) ?? [];
      return {
        key: row.key + header.name,
        rowKey: row.key,
        unit: formatUnit(row.buildingElement.unit ?? undefined),
        ...project.template.data.columns.reduce(
          (a, c) => ({
            ...a,
            [c.key]:
              scenRows.find((r) => r.columnKey === c.key)
                ?.buildingElementAmount ?? 0,
          }),
          {}
        ),
        total: scenRows.reduce((a, r) => a + (r.buildingElementAmount ?? 0), 0),
      };
    })
  );

  return (
    <ReportContainer>
      <ReportHeader
        chapter={chapter}
        reportType={"simplified"}
        reportTemplate={reportTemplate}
      />
      <ReportBodyAlignSpaceBetween>
        <Box mt={7} mb={1} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>
        <Box flexGrow={1}>
          <Box my={1.5}>
            <ReportTable
              title={
                project.scenarios.find((s) => s.id === scenarioId)?.name ?? ""
              }
              columns={[
                {
                  label: "Byggdel",
                  value: "rowKey",
                  align: "left",
                },
                {
                  label: "Enhet",
                  value: "unit",
                  align: "left",
                },
                ...project.template.data.columns.map((c) => ({
                  label: c.name,
                  value: c.key,
                  align: "right" as const,
                })),
                {
                  label: "Total",
                  value: "total",
                  align: "right",
                },
              ]}
              tableCaption={""}
              rows={rows}
              totalSumIndex={2}
            />
          </Box>
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} skipPaddingTop />
    </ReportContainer>
  );
};

export default ScenarioPage;
