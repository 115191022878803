import { Box, Button, CircularProgress } from "@material-ui/core";
import {
  useSubscribeFilesSubscription,
  FileFieldsFragment,
} from "../lib/generated/graphql";
import { SkeletonTable } from "../lib/components/table-skeleton";
import { SimpleTable } from "../lib/components/simple-table";
import { grey } from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";

const extraRowItem = (extraRow?: "GENERATING" | "FAILED") => {
  switch (extraRow) {
    case "FAILED":
      return [
        {
          id: "fake-generating-file",
          cells: ["Fil skapning misslyckades", "", ""],
        },
      ];
    case "GENERATING":
      return [
        {
          id: "fake-generating-file",
          cells: [
            "Fil genereras... Tar 1-2 minuter",
            "",
            <Box textAlign="center">
              {" "}
              <CircularProgress size={14} />
            </Box>,
          ],
        },
      ];
    default:
      return [];
  }
};

export const FileTableWithSubscription: React.FC<{
  hideExport: boolean;
  files: FileFieldsFragment[];
  loading: boolean;
  extraRow?: "GENERATING" | "FAILED";
  onExportClick?: () => void;
  onSubData?: () => void;
}> = ({ hideExport, loading, files, onSubData, onExportClick, extraRow }) => {
  useSubscribeFilesSubscription({
    skip: loading || files.length === 0,
    variables: { ids: files.map((file) => file.id) || [] },
    onSubscriptionData: onSubData,
  });

  return (
    <Box>
      {!loading ? (
        <Box>
          <SimpleTable
            rows={[
              ...extraRowItem(extraRow),
              ...files.map((file) => {
                return {
                  id: file.id,
                  cells: !file.fileUploaded
                    ? [
                        file.filename,
                        "Fil genereras... Tar 1-2 minuter",
                        <Box textAlign="center">
                          {" "}
                          <CircularProgress size={14} />
                        </Box>,
                      ]
                    : [
                        file.signedUrlRead ? (
                          <a href={file.signedUrlRead}>{file.filename}</a>
                        ) : (
                          file.filename
                        ),
                        moment(Number.parseInt(file.createdAt)).fromNow(),
                        file?.createdBy?.name,
                      ],
                };
              }),
            ]}
            columns={["Filnamn", "Skapad", "Skapad av"]}
          />

          {!hideExport && (
            <Box borderBottom={`1px solid ${grey[400]}`} px={2}>
              <Button
                fullWidth
                style={{ fontWeight: 600, padding: 0 }}
                color="primary"
                onClick={onExportClick}
              >
                <AddIcon style={{ marginBottom: 2 }} />
                {"Skapa ny export"}
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <SkeletonTable includeHeader={true} numColumns={4} numRows={5} />
      )}
    </Box>
  );
};
