import { Box, CircularProgress } from "@material-ui/core";
import { ReportFooter } from "../../../report/components/report-footer";
import { ReportContainer } from "../../../report/components/report-container";
import { ReportHeaderCommon as ReportHeader } from "../../../report/components/report-header";
import { ReportBodyAlignSpaceBetween } from "../../../report/components/body";
import { ReportTable } from "../../../report/components/table";
import { ReportTypography } from "../../../report/components/report-typography";
import {
  useSimplifiedTenantAdaptionProjectQuery,
  useSimplifiedTenantAdaptionProjectDsrsGroupedQuery,
} from "../../../../lib/generated/graphql";
import { SingleReportPage } from "./index";
import _ from "lodash";

const GwpSummaryPage: SingleReportPage = ({
  projectId,
  pageNumber,
  rounder,
  reportTemplate,
}) => {
  const chapter = "Resultat";
  const pageTitle = "Projektets klimatpåverkan";

  const { data: projectData, loading: loadingProject } =
    useSimplifiedTenantAdaptionProjectQuery({
      variables: { id: projectId },
    });

  const { data: gwpData, loading: loadingGwp } =
    useSimplifiedTenantAdaptionProjectDsrsGroupedQuery({
      variables: {
        id: projectId,
        query: {
          filter: [
            { NOT: { "plant::sta::scenario::row::scenarioId": "is_null" } },
            { factor: { gte: 1 } },
          ],
          group: [
            {
              col: "plant::sta::scenario::row::scenarioId",
              as: "scenarioId",
            },
            {
              col: "rowName",
            },
          ],
          aggregations: [
            { col: "A1_A5", op: "sum", reduceAggOp: "sum" },
            { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
            { col: "weight", op: "sum", reduceAggOp: "sum" },
            { col: "weightPerBTA", op: "sum", reduceAggOp: "sum" },
          ],
          sort: [{ col: "A1_A5PerBTA", desc: true }],
          topN: 100,
        },
      },
    });

  if (loadingGwp || loadingProject) {
    return <CircularProgress />;
  }
  const project = projectData?.simplifiedTenantAdaption?.project!;
  const gwpPerRow =
    gwpData?.simplifiedTenantAdaption?.project?.datasetRecords?.grouped?.data;
  const gwpPerScenario = _.groupBy(gwpPerRow, "scenarioId");

  return (
    <ReportContainer>
      <ReportHeader
        chapter={chapter}
        reportType={"simplified"}
        reportTemplate={reportTemplate}
      />
      <ReportBodyAlignSpaceBetween>
        <Box mt={10} mb={1} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>
        <Box flexGrow={1}>
          {project.scenarios.map((scenario, i) => {
            const rows = (gwpPerScenario[scenario.id] ?? [])
              .filter((r) => r.A1_A5PerBTA > 0)
              .map((r, i) => ({ ...r, key: i }));

            const sumRow = rows.reduce(
              (acc, row) => ({
                ...acc,
                weight: acc.weight + row.weight,
                A1_A5: acc.A1_A5 + row.A1_A5,
                weightPerBTA: acc.weightPerBTA + row.weightPerBTA,
                A1_A5PerBTA: acc.A1_A5PerBTA + row.A1_A5PerBTA,
              }),
              {
                weight: 0,
                A1_A5: 0,
                weightPerBTA: 0,
                A1_A5PerBTA: 0,
              }
            );

            return (
              <Box key={scenario.id} my={1.5}>
                <ReportTable
                  title={scenario.name}
                  columns={[
                    {
                      label: "Material",
                      value: "rowName",
                      align: "left",
                    },
                    {
                      label: "Vikt, kg",
                      value: "weight",
                      align: "right",
                    },
                    {
                      label: "CO₂e, kg",
                      value: "A1_A5",
                      align: "right",
                    },
                    {
                      label: "Vikt, kg/LOA",
                      value: "weightPerBTA",
                      align: "right",
                    },
                    {
                      label: "kg CO₂e/LOA",
                      value: "A1_A5PerBTA",
                      align: "right",
                    },
                  ]}
                  tableUnit="none"
                  hasRawMaterials={true}
                  tableCaption={
                    i === (project.scenarios.length ?? 0) - 1
                      ? "BC¹ = Biogen kolinlagring"
                      : ""
                  }
                  rows={rows}
                  sumRow={sumRow}
                />
              </Box>
            );
          })}
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} />
    </ReportContainer>
  );
};

export default GwpSummaryPage;
