import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { IReportColumns, ReportTable } from "../components/table";
import { ReportStatisticsBox } from "../components/statistics-box";
import { ReportPieChart } from "../components/pie-chart";
import { ReportTypography } from "../components/report-typography";
import { useGlobalState } from "../../../providers/GlobalStateProvider";
import { omitRedundantFields } from "./report-gwp-summary-page";
import { replaceRestInRestRow } from "../../../lib/format";
import { ReportPageProps } from "../../report-page";

import useDsrQueryHook from "../hooks/useDsrQueryHook";

function VkPieCharts() {
  const dsrsVKPositionOnGenericMaterial = useDsrQueryHook({
    filter: [],
    group: [{ col: "VKPosition" }],
    aggregations: [
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "weight", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 5,
  });

  const rows2 = omitRedundantFields([
    ...dsrsVKPositionOnGenericMaterial.data,
    ...(dsrsVKPositionOnGenericMaterial.rest
      ? [{ ...dsrsVKPositionOnGenericMaterial.rest, VKPosition: "Resterande" }]
      : []),
  ])
    .map((r) => ({
      name:
        r.VKPosition === "Resterande"
          ? "Resterande"
          : r.VKPosition || "Saknar position",
      value: r.A1_A5PerBTA,
    }))
    .filter((d) => d.name !== "Totalsumma");

  const rowsBuildingElementChart = omitRedundantFields([
    ...[...dsrsVKPositionOnGenericMaterial.data].sort(
      (a: any, b: any) => b.weight - a.weight
    ),
    ...(dsrsVKPositionOnGenericMaterial.rest
      ? [{ ...dsrsVKPositionOnGenericMaterial.rest, VKPosition: "Resterande" }]
      : []),
  ])
    .map((r) => ({
      name:
        r.VKPosition === "Resterande"
          ? "Resterande"
          : r.VKPosition || "Saknar Position",
      value: r.weight,
    }))
    .filter((d) => d.name !== "Totalsumma");

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <ReportStatisticsBox
            chart={() => <ReportPieChart data={rows2 || []} />}
            title="Klimatpåverkan per positon"
            caption="Fördelning på position, andel av klimatpåverkan"
          />
        </Box>
        <Box>
          <ReportStatisticsBox
            chart={() => (
              <ReportPieChart data={rowsBuildingElementChart || []} />
            )}
            title="Vikt per position"
            caption="Fördelning på position, andel av vikt"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default function ReportVKPositionPage({
  pageNumber,
  projectId,
  scenarioId,
  selectedDatasetIds,
}: ReportPageProps) {
  const state = useGlobalState();
  const chapter = "Resultat";
  const pageTitle = "Gruppering enligt Vasakronans mall";

  const dsrs = useDsrQueryHook({
    filter: [],
    group: [{ col: "VKPosition" }],
    aggregations: [
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "A1_A3PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "A4PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "A5PerBTA", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 18,
  });

  const rows = omitRedundantFields(dsrs.data).map(
    ({ VKPosition = "Saknar Position", ...row }: any) => ({
      ...row,
      VKPosition,
    })
  );

  const columns: IReportColumns[] = [
    {
      label: "Beskrivning",
      value: "VKPosition",
      align: "left",
    },
    {
      label: "A1-A3 kg CO₂e/BTA",
      value: "A1_A3PerBTA",
      align: "right",
    },
    {
      label: "A4 kg CO₂e/BTA",
      value: "A4PerBTA",
      align: "right",
    },
    {
      label: "A5 kg CO₂e/BTA",
      value: "A5PerBTA",
      align: "right",
    },

    {
      label: "A1-A5 kg CO₂e/BTA",
      value: "A1_A5PerBTA",
      align: "right",
    },
  ];

  return (
    <ReportContainer>
      <ReportHeader chapter={chapter} />
      <ReportBodyAlignSpaceBetween>
        <Box mt={10} mb={1} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>
        <Box flexGrow={1}>
          <ReportTable
            columns={columns}
            hasRawMaterials={true}
            tableCaption="Kilmatpåverkan från projektet, kg CO₂e/BTA"
            rows={rows}
            sumRow={dsrs.sum}
            restRow={replaceRestInRestRow(dsrs.rest)}
          />
        </Box>
        <VkPieCharts />
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} />
    </ReportContainer>
  );
}
