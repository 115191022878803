import MuiAlert from "@material-ui/lab/Alert";
import { Box, Button } from "@material-ui/core";
import { useSimplifiedTenantAdaptionPatchProjectMutation } from "../../lib/generated/graphql";

const ProjectLockedComponent: React.FC<{
  projectId: string;
  markedAsDone: boolean;
}> = ({ projectId, markedAsDone }) => {
  const [patchProjectDetails, { loading }] =
    useSimplifiedTenantAdaptionPatchProjectMutation();

  return (
    <>
      {markedAsDone && (
        <Box mx={6} pb={2}>
          <MuiAlert
            action={
              <Button
                size="small"
                style={{ fontWeight: 600 }}
                variant={"outlined"}
                color={"secondary"}
                disabled={loading}
                onClick={async () => {
                  await patchProjectDetails({
                    variables: {
                      id: projectId,
                      patch: {
                        markedAsDone: false,
                      },
                      reportTemplatePatch: {},
                    },
                  });
                }}
              >
                {"Lås upp"}
              </Button>
            }
          >
            Projektet har blivit klarmarkerat och låst för inmatning. För att
            låsa upp, tryck på knappen till höger.
          </MuiAlert>
        </Box>
      )}
    </>
  );
};

export default ProjectLockedComponent;
