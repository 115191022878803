import { Chip } from "@material-ui/core";
import { LcaDataTypeEnum } from "../generated/graphql";

interface ILCADataTypeChipProps {
  LCADataType?: LcaDataTypeEnum;
  sourceName: string;
}

export default function LCADataTypeChip(props: ILCADataTypeChipProps) {
  switch (props.LCADataType) {
    case LcaDataTypeEnum.GenericProductLcaData: {
      return (
        <Chip
          style={{ height: 11, fontSize: 8 }}
          label={props.sourceName}
          size="small"
          color="default"
        />
      );
    }
    case LcaDataTypeEnum.ProductEpd: {
      return (
        <Chip
          style={{ height: 11, fontSize: 8 }}
          label={"EPD"}
          size="small"
          color="primary"
        />
      );
    }
    case LcaDataTypeEnum.ProductCategoryEpd: {
      return (
        <Chip
          style={{ height: 11, fontSize: 8 }}
          label={"EPD"}
          size="small"
          color="primary"
        />
      );
    }
    case LcaDataTypeEnum.GenericProductCategoryLcaData: {
      return (
        <Chip
          style={{ height: 11, fontSize: 8 }}
          label={"EPD"}
          size="small"
          color="primary"
        />
      );
    }
    case LcaDataTypeEnum.UserCreatedBuildingElement: {
      return (
        <Chip
          style={{ height: 11, fontSize: 8 }}
          label={"Byggdel"}
          size="small"
          color="secondary"
        />
      );
    }
    default: {
      return (
        <Chip
          style={{ height: 11, fontSize: 8 }}
          label={"Vet ej"}
          size="small"
          color="secondary"
        />
      );
    }
  }
}
