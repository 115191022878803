import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
} from "firebase/firestore";

import config from "../../config";
import { DocumentSnapshot } from "@firebase/firestore";

type FunctionWithFirstArg<K> = (x: K, ...args: any[]) => any;
type ArgsExceptFirst<K, F> = F extends (x: K, ...args: infer P) => any
  ? P
  : never;

// curries functions on the first arguement, useful for the new firebase API
// `auth` is of type `FirebaseAuthType`
// `signInWithEmailAndPassword` is of type `(auth: FirebaseAuthType, username: string, password: string) => Promise<User>`
//
// const authWith = curry(auth)
// const curriedSignInWithEmailAndPassword = authWith(signInWithEmailAndPassword)
// `curriedSignInWithEmailAndPassword` is now of type `(username: string, password: string) => Promise<User>`
// this means `curriedSignInWithEmailAndPassword` does not require the firebase auth to be passed into it.
const curry =
  <T>(obj: T) =>
  <F extends FunctionWithFirstArg<T>>(fn: F) =>
  (...args: ArgsExceptFirst<T, F>) =>
    fn(obj, ...args);

// Initialize Firebase
const app = initializeApp(config.firebase);
const authInt = getAuth(app);
const microsoftProvider = new OAuthProvider("microsoft.com");
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ promt: "select_account" });

const authWith = curry(authInt);

const sendEmailVerificationToCurrentUser = async () => {
  if (authInt.currentUser == null) {
    throw new Error("no current user");
  }
  await sendEmailVerification(authInt.currentUser);
};

export const firestoreInt = getFirestore(app);

const FIRESTORE_USER_FRONTEND_STATE = "userFrontendState";

export const auth = {
  getCurrentToken: async () => authInt?.currentUser?.getIdToken(false) || null,
  getFirebaseUserId: () => authInt?.currentUser?.uid || "",
  refreshToken: async () => authInt?.currentUser?.getIdToken(true),
  waitForToken: async () =>
    new Promise<string | undefined>(async (resolve, reject) => {
      const cu = await authInt?.currentUser;
      if (cu != null) {
        resolve(await cu.getIdToken(false));
      }
      const tl = setTimeout(() => {
        resolve(undefined);
      }, 5000);
      const unsubscribe = onAuthStateChanged(authInt, async (user) => {
        if (user != null) {
          clearTimeout(tl);
          resolve(await user.getIdToken(true));
          unsubscribe();
        }
      });
    }),
  onAuthStateChanged: authWith(onAuthStateChanged),
  signOut: authWith(signOut),
  signInWithGoogle: () => signInWithPopup(authInt, googleProvider),
  signInWithMicrosoft: () => signInWithPopup(authInt, microsoftProvider),
  signInWithEmailAndPassword: authWith(signInWithEmailAndPassword),
  createUserWithEmailAndPassword: async (email: string, password: string) => {
    await authWith(createUserWithEmailAndPassword)(email, password);
    await sendEmailVerificationToCurrentUser();
  },
  sendEmailVerificationToCurrentUser,
};

interface UserFrontendState {
  selectedIFCGUIDs?: string[];
  selectedDatasets?: {
    id: string;
    ifcUrl: string;
  }[];
}

export const firestore = {
  getFrontendState: () =>
    getDoc(
      doc(
        collection(firestoreInt, FIRESTORE_USER_FRONTEND_STATE),
        auth.getFirebaseUserId()
      )
    ),
  setFrontendState: async (newState: UserFrontendState) =>
    setDoc(
      doc(
        collection(firestoreInt, FIRESTORE_USER_FRONTEND_STATE),
        auth.getFirebaseUserId()
      ),
      newState,
      { merge: true }
    ),
  subscribeFrontendState: (
    cb: (snapshot: DocumentSnapshot<DocumentData>) => void
  ) =>
    onSnapshot(
      doc(
        firestoreInt,
        FIRESTORE_USER_FRONTEND_STATE,
        auth.getFirebaseUserId()
      ),
      cb
    ),
};
