import {
  useScenarioDatasetRecordsGroupedQuery,
  usePipelineOutputGroupedQuery,
  DsrGroupedQueryInput,
} from "../../../lib/generated/graphql";
import { useGlobalState } from "../../../providers/GlobalStateProvider";
import { useParams } from "react-router";
import { newMaterialFilter } from "./material-filter";

export interface DsrResp {
  data: any[];
  sum: any;
  rest: any;
}

export const useDsrQueryHook = (
  query: DsrGroupedQueryInput,
  withoutNewMaterialFilter = false
): DsrResp => {
  const { projectId } = useParams();
  const { scenarioId, pipelineId } = useGlobalState();

  const skipScenario = !(projectId && scenarioId) || !!pipelineId;
  const skipPipeline = !(projectId && pipelineId) || !!scenarioId;

  const dsrQuery = {
    ...query,
    filter: [
      ...(query.filter || []),
      ...(withoutNewMaterialFilter ? [] : newMaterialFilter),
    ],
  };

  const scenario = useScenarioDatasetRecordsGroupedQuery({
    skip: skipScenario,
    variables: {
      projectId: projectId || "",
      scenarioId: scenarioId || "",
      query: dsrQuery,
    },
  });

  const pipeline = usePipelineOutputGroupedQuery({
    skip: skipPipeline,
    variables: {
      projectId: projectId || "",
      pipelineId: pipelineId || "",
      query: dsrQuery,
    },
  });

  const result = !skipPipeline
    ? pipeline?.data?.project?.pipeline.output.grouped
    : scenario?.data?.project?.scenario?.datasetRecords?.grouped;

  return {
    data: result?.data ?? [],
    sum: result?.sum ?? {},
    rest: result?.rest ?? {},
  };
};

export default useDsrQueryHook;
