const MicrosoftIcon: React.FC<{ width: number; height: number }> = ({
  width,
  height,
}) => {
  return (
    <svg
      enableBackground="new 0 0 2499.6 2500"
      viewBox="0 0 2499.6 2500"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z" fill="#f1511b" />
      <path d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z" fill="#80cc28" />
      <path d="m1187.9 2500h-1187.9v-1187.9h1187.9z" fill="#00adef" />
      <path d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z" fill="#fbbc09" />
    </svg>
  );
};

export default MicrosoftIcon;
