import { Box, CircularProgress, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import {
  useSimplifiedTenantAdaptionProjectQuery,
  ReportTemplateFieldsFragment,
} from "../../../../lib/generated/graphql";
import rounderOf from "../../../../lib/rounder";

const useStyle = makeStyles((theme: Theme) => ({
  ReportWrapper: {
    "@media screen": {
      zoom: 0.7,
    },
  },
}));

interface IReportPageProps {
  pages: SingleReportPage[];
  isFullPage?: boolean;
  isPrint: boolean;
}

export type SingleReportPageProps = {
  projectId: string;
  pageNumber?: number;
  isPrint: boolean;
  reportTemplate: Omit<ReportTemplateFieldsFragment, "generatedPdfs">;
  rounder: <T>(v: T) => T;
};

export type SingleReportPage<T = {}> = React.FC<SingleReportPageProps & T>;

export default function ReportPage(props: IReportPageProps) {
  const { ReportWrapper } = useStyle();

  const { projectId } = useParams();

  const { data: projectData, loading: loadingProject } =
    useSimplifiedTenantAdaptionProjectQuery({
      variables: { id: projectId! },
    });
  if (loadingProject) {
    return <CircularProgress />;
  }
  const project = projectData?.simplifiedTenantAdaption?.project!;

  return (
    <Box
      maxWidth="1123"
      className={props.isFullPage ? "" : ReportWrapper}
      display="flex"
      justifyContent="center"
    >
      <Box>
        {(props.pages || []).map((PageComponent, pageNumber) => (
          <PageComponent
            projectId={projectId!}
            rounder={rounderOf(2)}
            pageNumber={pageNumber}
            key={pageNumber}
            isPrint={props.isPrint}
            reportTemplate={project.reportTemplate}
          />
        ))}
      </Box>
    </Box>
  );
}
