import { ReportHeader } from "../components/report-header";
import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import {
  BuildingElement,
  useListBuildingElementsQuery,
  useRawMaterialsQuery,
} from "../../../lib/generated/graphql";
import { ReportContainer } from "../components/report-container";
import {
  BuildingElementWithProjectA1_A5,
  ReportBuildingElement,
} from "../components/report-building-element";
import useDsrQueryHook from "../hooks/useDsrQueryHook";

import { ReportPageProps } from "../../report-page";
import _ from "lodash";
import { useLayoutEffect, useRef, useState } from "react";
import { Flex } from "@chakra-ui/react";

const splitChildren = ({
  childrenHeights,
  componentProps = [],
  containerHeight = 0,
  index = 0,
  curHeight = 0,
}: {
  childrenHeights: any[];
  componentProps?: any[];
  containerHeight: number;
  index?: number;
  curHeight?: number;
}) =>
  childrenHeights.reduce((acc, cur, i) => {
    let entry = acc[index] || [];

    curHeight += cur;

    // if not exceeded maxheight, push to the current index
    if (curHeight < containerHeight) {
      entry.push(componentProps[i]);
      return { ...acc, [index]: entry };
    }

    // if exceeded maxheight, push to the next index instead
    curHeight = cur;
    index += 1;
    entry = acc[index] || [];

    entry.push(componentProps[i]);
    return { ...acc, [index]: entry };
  }, {});

const useDimensions = ({ componentProps }: { componentProps?: any[] }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [splitItems, setSplitItems] = useState(null);

  useLayoutEffect(() => {
    if (ref?.current?.children) {
      const children = Array.from(ref.current.children);

      const containerHeight = ref?.current?.getBoundingClientRect()?.height;

      const childrenHeights = children.map(
        (ch) => ch.getBoundingClientRect()?.height || 0
      );

      const itemsSplitOnMaxH = splitChildren({
        childrenHeights,
        componentProps,
        containerHeight,
      });

      if (Object.keys(itemsSplitOnMaxH).length > 0) {
        setSplitItems(itemsSplitOnMaxH);
      }
    }
  }, [ref, componentProps?.length]);

  return [ref, splitItems];
};

const mapNonNull = <P, R>(
  p: P | null | undefined,
  mapper: (p: P) => R
): R | undefined => {
  if (p == null) {
    return;
  }
  return mapper(p);
};

const getRelevantBuildingElements = (
  dsrs: {
    ProductRawMaterialId?: string | null;
    A1_A5?: number | null;
    A1_A3?: number | null;
    A1_A5PerBTA?: number | null;
    ProductAmount?: number | null;
  }[],
  rawMaterials: { id: string | null; buildingElementId?: string | null }[],
  buildingElements: BuildingElement[],
  isSimplifiedReport: boolean
): BuildingElementWithProjectA1_A5[] => {
  const rawMaterialsById = _.keyBy(rawMaterials, (rm) => rm.id!);
  const buildingElementsById = _.keyBy(buildingElements, (be) => be.id);

  const getBuildingElementByRawMaterialId = (
    id: string
  ): BuildingElement | undefined => {
    const rm = rawMaterialsById[id];
    return (
      rm &&
      mapNonNull(
        rm.buildingElementId,
        (PlantBuildingElementId) => buildingElementsById[PlantBuildingElementId]
      )
    );
  };

  function sortName(a: { name: string }, b: { name: string }) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  function sortSerial(
    a: { serialNumber: number | string },
    b: { serialNumber: number | string }
  ) {
    if (b.serialNumber < a.serialNumber) {
      return -1;
    } else if (b.serialNumber > a.serialNumber) {
      return 1;
    }
    return 0;
  }

  return dsrs
    .filter((dsr) =>
      isSimplifiedReport
        ? dsr.A1_A5 && dsr.A1_A5 > 0
        : dsr.A1_A5PerBTA && dsr.A1_A5PerBTA > 0.1
    )
    .flatMap((dsr) => {
      if (dsr.ProductRawMaterialId == null) {
        return [];
      }
      const be = getBuildingElementByRawMaterialId(dsr.ProductRawMaterialId);
      if (be !== undefined) {
        return [
          {
            ...be,
            projectA1_A5: dsr.A1_A5!,
            projectProductAmount: dsr.ProductAmount!,
            projectA1_A5PerBTA: dsr.A1_A5PerBTA!,
          },
        ];
      }
      return [];
    })
    .sort((a, b) => (isSimplifiedReport ? sortSerial(a, b) : sortName(a, b)));
};

export function ReportBuildingElementPages({
  pageNumber,
  isSimplifiedReport,
  projectId,
  scenarioId,
  selectedDatasetIds,
}: ReportPageProps) {
  const rawMaterials = useRawMaterialsQuery({ variables: { projectId } })?.data
    ?.project?.rawMaterials;

  const { data: beData, loading } = useListBuildingElementsQuery({
    variables: {
      projectId,
      filter: {
        ...(isSimplifiedReport
          ? {
              serialNumber: {
                // Hack to get buildingElements for our simplified reporting
                lt: 50,
              },
            }
          : {}),
      },
      orderBy: [{ serialNumber: "asc" }],
    },
  });

  const buildingElements = (beData?.project?.buildingElements ??
    []) as BuildingElement[];

  const dsrs = useDsrQueryHook({
    filter: [],
    group: [{ col: "ProductRawMaterialId" }],
    aggregations: [
      { col: "ProductAmount", op: "sum", reduceAggOp: "sum" },
      { col: "A1_A5", op: "sum", reduceAggOp: "sum" },
      { col: "A1_A3", op: "sum", reduceAggOp: "sum" },
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5", desc: true }],
    topN: 3000,
  });

  const relevantBuildingElements = getRelevantBuildingElements(
    dsrs.data,
    rawMaterials || [],
    buildingElements,
    isSimplifiedReport ?? false
  );

  const [ref, splitItems] = useDimensions({
    componentProps: relevantBuildingElements,
  });

  if (loading) {
    return null;
  }

  return (
    <>
      {/* have to render items to calculate dimensions, hide this when done */}
      <Box display={splitItems ? "none" : "block"}>
        <ReportContainer>
          <ReportHeader chapter="Sammansatta material" />

          <Flex flexDir="column" pt={6} minH={"100%"} ref={ref}>
            {relevantBuildingElements.map((buildingElement) => (
              <Box pb={2} key={buildingElement.id}>
                <ReportBuildingElement
                  buildingElement={buildingElement}
                  isSimplifiedReport={isSimplifiedReport}
                />
              </Box>
            ))}
          </Flex>
          <ReportFooter pageNumber={0} />
        </ReportContainer>
      </Box>

      {/* when calulation is done, render the resulting pages */}
      {Object.values(splitItems || []).map((pageRows, ind) => {
        return (
          <ReportContainer key={ind}>
            <ReportHeader chapter="Sammansatta material" />
            <Flex flexDir="column" pt={6} height={"100%"}>
              {pageRows.map((buildingElement: any) => {
                return (
                  <Box pb={2} key={buildingElement.id}>
                    <ReportBuildingElement
                      buildingElement={buildingElement}
                      isSimplifiedReport={isSimplifiedReport}
                    />
                  </Box>
                );
              })}
            </Flex>

            <ReportFooter pageNumber={pageNumber + Math.round(ind)} />
          </ReportContainer>
        );
      })}
    </>
  );
}
