import { Paper, Theme, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { auth } from "../../firebase";

import { makeStyles } from "@material-ui/styles";
import useSignOut from "./use-sign-out";
import { useGlobalDispatch } from "../../../providers/GlobalStateProvider";
import { ActionType } from "../../../providers/GlobalStateProvider/types";
import { useApolloClient } from "@apollo/client";
import { debounce } from "lodash";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      margin: theme.spacing(1),
    },
    card: {
      width: "300px",
      position: "absolute",
      top: "20%",
      left: 0,
      right: 0,
      margin: "auto",
      textAlign: "center",
    },
    image: {
      position: "absolute",
      top: "-113px",
      width: "240px",
      left: "0",
      background: "#00000066",
      boxShadow: "0 0 42px black",
    },
    textField: {
      width: "70%",
    },
  };
});

const VerifyEmailPrompt: React.FC = () => {
  const client = useApolloClient();
  const classes = useStyles();
  const signOut = useSignOut();
  const dispatch = useGlobalDispatch();

  return (
    <div className={classes.card}>
      <Paper
        style={{
          background: "#00BFA5",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          component="h1"
          noWrap
          style={{
            fontSize: "50px",
            color: "white",
          }}
        >
          Plant
        </Typography>
      </Paper>
      <Card>
        <CardContent>
          <Typography>
            Du måste verifiera din Email. Du har fått ett mail där du måste
            klicka på en länk för att bekräfta din Email.
          </Typography>
          <Button
            id="retry-btn"
            type="submit"
            variant="outlined"
            color="primary"
            classes={{ root: classes.root }}
            onClick={async () => {
              await auth.refreshToken();
              dispatch({
                type: ActionType.SET_EMAIL_VERIFICATION_REQUIRED,
                payload: false,
              });
              client.resetStore().catch(() => {
                // do nothing
              });
            }}
          >
            Ok, jag har bekräftat min mail
          </Button>
          <Button
            id="resend-btn"
            variant="outlined"
            color="primary"
            classes={{ root: classes.root }}
            onClick={debounce(
              async () => {
                await auth.sendEmailVerificationToCurrentUser();
              },
              10_000,
              { leading: true }
            )}
          >
            Skicka mailet igen
          </Button>
          <Button
            id="signout-btn"
            variant="outlined"
            color="secondary"
            classes={{ root: classes.root }}
            onClick={() =>
              signOut(() => {
                // do nothing
              })
            }
          >
            Logga ut
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default VerifyEmailPrompt;
