import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Checkbox } from "@material-ui/core";
import { SimpleTable } from "../lib/components/simple-table";
import {
  useProjectQuery,
  useUpdateProjectMutation,
} from "../lib/generated/graphql";
import { reportPages } from "./report-widget-pages";
import { useLocation, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { Flex } from "@chakra-ui/react";

export default function ReportPagesWidget({
  children,
}: {
  children: React.ReactNode;
}) {
  const { projectId } = useParams();

  const project = useProjectQuery({ variables: { projectId: projectId! } })
    ?.data?.project;
  const [checkedPages, setCheckedPages] = useState<string[]>([]);
  const [updateProject] = useUpdateProjectMutation();
  const location = useLocation();

  const sortedMenuPages = useMemo(
    () =>
      Object.keys(reportPages)
        .sort((pageA, pageB) => pageA.localeCompare(pageB))
        .sort(
          (pageA, pageB) =>
            Number([...(checkedPages || [])]?.includes(pageB)) -
            Number([...(checkedPages || [])]?.includes(pageA))
        )
        .reduce((acc: any, key: any) => {
          const component = reportPages[key as keyof typeof reportPages];
          return { ...acc, [key]: component };
        }, {}),
    [project?.reportPages, checkedPages]
  );

  useEffect(() => {
    if (project?.reportPages != null && project.reportPages.length > 0) {
      setCheckedPages(project.reportPages);
    }
  }, [project?.reportPages]);

  if (project?.id == null) {
    return <CircularProgress />;
  }

  const search = location.search.replace("?page=", "");
  return (
    <Flex gap={2} flexDirection="column" w={"100%"}>
      <SimpleTable
        rows={Object.keys(sortedMenuPages).map((page) => {
          const isActivePage = search === page;

          return {
            id: page,
            name: page,
            isSelected: isActivePage,
            cells: [
              <Box display="flex" justifyContent="center" alignItems="center">
                <Checkbox
                  size="small"
                  style={{ padding: 0 }}
                  checked={checkedPages.includes(page)}
                  onClick={(event) => {
                    event.stopPropagation();
                    setCheckedPages(
                      checkedPages.includes(page)
                        ? [...checkedPages.filter((p) => p !== page)]
                        : [...checkedPages, page]
                    );
                  }}
                />
              </Box>,
              <Link to={`${location.pathname}?page=${page}`}>{page}</Link>,
            ],
          };
        })}
        columns={["Inkludera", "Sida"]}
      />

      <Button
        fullWidth
        id="save-project-btn"
        variant="contained"
        color="primary"
        onClick={async () => {
          await updateProject({
            variables: {
              id: project.id,
              patch: { reportPages: checkedPages },
            },
          });
        }}
      >
        Spara
      </Button>
      {children}
    </Flex>
  );
}
