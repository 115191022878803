export default function LayerPlywood(props: any) {
  return (
    <svg
      height={500}
      viewBox="54.441 -44.995 90.568 942.447"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Hatches" transform="matrix(0, 1, -1, 0, 150.00499, -49.545002)">
        <line stroke="black" fill="none" x1="5" y1="34" x2="194.52" y2="34" />
        <line stroke="black" fill="none" x1="5" y1="52" x2="194.52" y2="52" />
        <line stroke="black" fill="none" x1="5" y1="70" x2="194.52" y2="70" />
        <line stroke="black" fill="none" x1="5" y1="88" x2="195" y2="88" />
        <line stroke="black" fill="none" x1="5" y1="16" x2="194.69" y2="16" />
        <polyline
          stroke="black"
          fill="none"
          points="31.55 95.45 39 88 21 70 38.99 51.92 21.07 34 39 16.07 28.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="49.55 95.45 57 88 39 70 56.99 51.92 39.07 34 57 16.07 46.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="67.55 95.45 75 88 57 70 74.99 51.92 57.07 34 75 16.07 64.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="85.55 95.45 93 88 75 70 92.99 51.92 75.07 34 93 16.07 82.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="103.55 95.45 111 88 93 70 110.99 51.92 93.07 34 111 16.07 100.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="112.55 95.45 120 88 102 70 119.99 51.92 102.07 34 120 16.07 109.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="22.55 95.45 30 88 12 70 29.99 51.92 12.07 34 30 16.07 19.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 32.07 21 16.07 10.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 67.99 20.99 51.92 5 35.93"
        />
        <polyline stroke="black" fill="none" points="13.55 95.45 21 88 5 72" />
        <polyline
          stroke="black"
          fill="none"
          points="5 23.07 12 16.07 5.31 9.31"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 58.95 11.99 51.92 5 44.93"
        />
        <polyline stroke="black" fill="none" points="4.55 95.45 12 88 5 81" />
        <polyline
          stroke="black"
          fill="none"
          points="40.55 95.45 48 88 30 70 47.99 51.92 30.07 34 48 16.07 37.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="58.55 95.45 66 88 48 70 65.99 51.92 48.07 34 66 16.07 55.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="76.55 95.45 84 88 66 70 83.99 51.92 66.07 34 84 16.07 73.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="94.55 95.45 102 88 84 70 101.99 51.92 84.07 34 102 16.07 91.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="121.55 95.45 129 88 111 70 128.99 51.92 111.07 34 129 16.07 118.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="130.55 95.45 138 88 120 70 137.99 51.92 120.07 34 138 16.07 127.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="139.55 95.45 147 88 129 70 146.99 51.92 129.07 34 147 16.07 136.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="148.55 95.45 156 88 138 70 155.99 51.92 138.07 34 156 16.07 145.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="157.55 95.45 165 88 147 70 164.99 51.92 147.07 34 165 16.07 154.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="166.55 95.45 174 88 156 70 173.99 51.92 156.07 34 174 16.07 163.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="175.55 95.45 183 88 165 70 182.99 51.92 165.07 34 183 16.07 172.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="184.55 95.45 192 88 174 70 191.99 51.92 174.07 34 192 16.07 181.01 5.01"
        />
        <line
          stroke="black"
          fill="none"
          x1="195"
          y1="11.04"
          x2="189.01"
          y2="5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="195 46.93 182.07 34 195 21.07"
        />
        <polyline stroke="black" fill="none" points="195 83 182 70 195 56.94" />
        <line
          stroke="black"
          fill="none"
          x1="192.55"
          y1="95.45"
          x2="195"
          y2="93"
        />
      </g>
      <g id="g-1" transform="matrix(0, 1, -1, 0, 150.018982, 138.494919)">
        <line stroke="black" fill="none" x1="5" y1="34" x2="194.52" y2="34" />
        <line stroke="black" fill="none" x1="5" y1="52" x2="194.52" y2="52" />
        <line stroke="black" fill="none" x1="5" y1="70" x2="194.52" y2="70" />
        <line stroke="black" fill="none" x1="5" y1="88" x2="195" y2="88" />
        <line stroke="black" fill="none" x1="5" y1="16" x2="194.69" y2="16" />
        <polyline
          stroke="black"
          fill="none"
          points="31.55 95.45 39 88 21 70 38.99 51.92 21.07 34 39 16.07 28.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="49.55 95.45 57 88 39 70 56.99 51.92 39.07 34 57 16.07 46.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="67.55 95.45 75 88 57 70 74.99 51.92 57.07 34 75 16.07 64.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="85.55 95.45 93 88 75 70 92.99 51.92 75.07 34 93 16.07 82.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="103.55 95.45 111 88 93 70 110.99 51.92 93.07 34 111 16.07 100.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="112.55 95.45 120 88 102 70 119.99 51.92 102.07 34 120 16.07 109.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="22.55 95.45 30 88 12 70 29.99 51.92 12.07 34 30 16.07 19.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 32.07 21 16.07 10.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 67.99 20.99 51.92 5 35.93"
        />
        <polyline stroke="black" fill="none" points="13.55 95.45 21 88 5 72" />
        <polyline
          stroke="black"
          fill="none"
          points="5 23.07 12 16.07 5.31 9.31"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 58.95 11.99 51.92 5 44.93"
        />
        <polyline stroke="black" fill="none" points="4.55 95.45 12 88 5 81" />
        <polyline
          stroke="black"
          fill="none"
          points="40.55 95.45 48 88 30 70 47.99 51.92 30.07 34 48 16.07 37.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="58.55 95.45 66 88 48 70 65.99 51.92 48.07 34 66 16.07 55.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="76.55 95.45 84 88 66 70 83.99 51.92 66.07 34 84 16.07 73.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="94.55 95.45 102 88 84 70 101.99 51.92 84.07 34 102 16.07 91.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="121.55 95.45 129 88 111 70 128.99 51.92 111.07 34 129 16.07 118.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="130.55 95.45 138 88 120 70 137.99 51.92 120.07 34 138 16.07 127.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="139.55 95.45 147 88 129 70 146.99 51.92 129.07 34 147 16.07 136.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="148.55 95.45 156 88 138 70 155.99 51.92 138.07 34 156 16.07 145.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="157.55 95.45 165 88 147 70 164.99 51.92 147.07 34 165 16.07 154.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="166.55 95.45 174 88 156 70 173.99 51.92 156.07 34 174 16.07 163.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="175.55 95.45 183 88 165 70 182.99 51.92 165.07 34 183 16.07 172.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="184.55 95.45 192 88 174 70 191.99 51.92 174.07 34 192 16.07 181.01 5.01"
        />
        <line
          stroke="black"
          fill="none"
          x1="195"
          y1="11.04"
          x2="189.01"
          y2="5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="195 46.93 182.07 34 195 21.07"
        />
        <polyline stroke="black" fill="none" points="195 83 182 70 195 56.94" />
        <line
          stroke="black"
          fill="none"
          x1="192.55"
          y1="95.45"
          x2="195"
          y2="93"
        />
      </g>
      <g id="g-2" transform="matrix(0, 1, -1, 0, 149.959702, 326.561829)">
        <line stroke="black" fill="none" x1="5" y1="34" x2="194.52" y2="34" />
        <line stroke="black" fill="none" x1="5" y1="52" x2="194.52" y2="52" />
        <line stroke="black" fill="none" x1="5" y1="70" x2="194.52" y2="70" />
        <line stroke="black" fill="none" x1="5" y1="88" x2="195" y2="88" />
        <line stroke="black" fill="none" x1="5" y1="16" x2="194.69" y2="16" />
        <polyline
          stroke="black"
          fill="none"
          points="31.55 95.45 39 88 21 70 38.99 51.92 21.07 34 39 16.07 28.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="49.55 95.45 57 88 39 70 56.99 51.92 39.07 34 57 16.07 46.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="67.55 95.45 75 88 57 70 74.99 51.92 57.07 34 75 16.07 64.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="85.55 95.45 93 88 75 70 92.99 51.92 75.07 34 93 16.07 82.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="103.55 95.45 111 88 93 70 110.99 51.92 93.07 34 111 16.07 100.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="112.55 95.45 120 88 102 70 119.99 51.92 102.07 34 120 16.07 109.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="22.55 95.45 30 88 12 70 29.99 51.92 12.07 34 30 16.07 19.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 32.07 21 16.07 10.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 67.99 20.99 51.92 5 35.93"
        />
        <polyline stroke="black" fill="none" points="13.55 95.45 21 88 5 72" />
        <polyline
          stroke="black"
          fill="none"
          points="5 23.07 12 16.07 5.31 9.31"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 58.95 11.99 51.92 5 44.93"
        />
        <polyline stroke="black" fill="none" points="4.55 95.45 12 88 5 81" />
        <polyline
          stroke="black"
          fill="none"
          points="40.55 95.45 48 88 30 70 47.99 51.92 30.07 34 48 16.07 37.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="58.55 95.45 66 88 48 70 65.99 51.92 48.07 34 66 16.07 55.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="76.55 95.45 84 88 66 70 83.99 51.92 66.07 34 84 16.07 73.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="94.55 95.45 102 88 84 70 101.99 51.92 84.07 34 102 16.07 91.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="121.55 95.45 129 88 111 70 128.99 51.92 111.07 34 129 16.07 118.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="130.55 95.45 138 88 120 70 137.99 51.92 120.07 34 138 16.07 127.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="139.55 95.45 147 88 129 70 146.99 51.92 129.07 34 147 16.07 136.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="148.55 95.45 156 88 138 70 155.99 51.92 138.07 34 156 16.07 145.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="157.55 95.45 165 88 147 70 164.99 51.92 147.07 34 165 16.07 154.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="166.55 95.45 174 88 156 70 173.99 51.92 156.07 34 174 16.07 163.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="175.55 95.45 183 88 165 70 182.99 51.92 165.07 34 183 16.07 172.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="184.55 95.45 192 88 174 70 191.99 51.92 174.07 34 192 16.07 181.01 5.01"
        />
        <line
          stroke="black"
          fill="none"
          x1="195"
          y1="11.04"
          x2="189.01"
          y2="5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="195 46.93 182.07 34 195 21.07"
        />
        <polyline stroke="black" fill="none" points="195 83 182 70 195 56.94" />
        <line
          stroke="black"
          fill="none"
          x1="192.55"
          y1="95.45"
          x2="195"
          y2="93"
        />
      </g>
      <g id="g-3" transform="matrix(0, 1, -1, 0, 149.890808, 514.517517)">
        <line stroke="black" fill="none" x1="5" y1="34" x2="194.52" y2="34" />
        <line stroke="black" fill="none" x1="5" y1="52" x2="194.52" y2="52" />
        <line stroke="black" fill="none" x1="5" y1="70" x2="194.52" y2="70" />
        <line stroke="black" fill="none" x1="5" y1="88" x2="195" y2="88" />
        <line stroke="black" fill="none" x1="5" y1="16" x2="194.69" y2="16" />
        <polyline
          stroke="black"
          fill="none"
          points="31.55 95.45 39 88 21 70 38.99 51.92 21.07 34 39 16.07 28.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="49.55 95.45 57 88 39 70 56.99 51.92 39.07 34 57 16.07 46.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="67.55 95.45 75 88 57 70 74.99 51.92 57.07 34 75 16.07 64.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="85.55 95.45 93 88 75 70 92.99 51.92 75.07 34 93 16.07 82.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="103.55 95.45 111 88 93 70 110.99 51.92 93.07 34 111 16.07 100.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="112.55 95.45 120 88 102 70 119.99 51.92 102.07 34 120 16.07 109.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="22.55 95.45 30 88 12 70 29.99 51.92 12.07 34 30 16.07 19.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 32.07 21 16.07 10.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 67.99 20.99 51.92 5 35.93"
        />
        <polyline stroke="black" fill="none" points="13.55 95.45 21 88 5 72" />
        <polyline
          stroke="black"
          fill="none"
          points="5 23.07 12 16.07 5.31 9.31"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 58.95 11.99 51.92 5 44.93"
        />
        <polyline stroke="black" fill="none" points="4.55 95.45 12 88 5 81" />
        <polyline
          stroke="black"
          fill="none"
          points="40.55 95.45 48 88 30 70 47.99 51.92 30.07 34 48 16.07 37.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="58.55 95.45 66 88 48 70 65.99 51.92 48.07 34 66 16.07 55.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="76.55 95.45 84 88 66 70 83.99 51.92 66.07 34 84 16.07 73.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="94.55 95.45 102 88 84 70 101.99 51.92 84.07 34 102 16.07 91.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="121.55 95.45 129 88 111 70 128.99 51.92 111.07 34 129 16.07 118.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="130.55 95.45 138 88 120 70 137.99 51.92 120.07 34 138 16.07 127.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="139.55 95.45 147 88 129 70 146.99 51.92 129.07 34 147 16.07 136.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="148.55 95.45 156 88 138 70 155.99 51.92 138.07 34 156 16.07 145.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="157.55 95.45 165 88 147 70 164.99 51.92 147.07 34 165 16.07 154.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="166.55 95.45 174 88 156 70 173.99 51.92 156.07 34 174 16.07 163.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="175.55 95.45 183 88 165 70 182.99 51.92 165.07 34 183 16.07 172.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="184.55 95.45 192 88 174 70 191.99 51.92 174.07 34 192 16.07 181.01 5.01"
        />
        <line
          stroke="black"
          fill="none"
          x1="195"
          y1="11.04"
          x2="189.01"
          y2="5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="195 46.93 182.07 34 195 21.07"
        />
        <polyline stroke="black" fill="none" points="195 83 182 70 195 56.94" />
        <line
          stroke="black"
          fill="none"
          x1="192.55"
          y1="95.45"
          x2="195"
          y2="93"
        />
      </g>
      <g id="g-4" transform="matrix(0, 1, -1, 0, 149.90033, 702.451782)">
        <line stroke="black" fill="none" x1="5" y1="34" x2="194.52" y2="34" />
        <line stroke="black" fill="none" x1="5" y1="52" x2="194.52" y2="52" />
        <line stroke="black" fill="none" x1="5" y1="70" x2="194.52" y2="70" />
        <line stroke="black" fill="none" x1="5" y1="88" x2="195" y2="88" />
        <line stroke="black" fill="none" x1="5" y1="16" x2="194.69" y2="16" />
        <polyline
          stroke="black"
          fill="none"
          points="31.55 95.45 39 88 21 70 38.99 51.92 21.07 34 39 16.07 28.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="49.55 95.45 57 88 39 70 56.99 51.92 39.07 34 57 16.07 46.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="67.55 95.45 75 88 57 70 74.99 51.92 57.07 34 75 16.07 64.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="85.55 95.45 93 88 75 70 92.99 51.92 75.07 34 93 16.07 82.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="103.55 95.45 111 88 93 70 110.99 51.92 93.07 34 111 16.07 100.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="112.55 95.45 120 88 102 70 119.99 51.92 102.07 34 120 16.07 109.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="22.55 95.45 30 88 12 70 29.99 51.92 12.07 34 30 16.07 19.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 32.07 21 16.07 10.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 67.99 20.99 51.92 5 35.93"
        />
        <polyline stroke="black" fill="none" points="13.55 95.45 21 88 5 72" />
        <polyline
          stroke="black"
          fill="none"
          points="5 23.07 12 16.07 5.31 9.31"
        />
        <polyline
          stroke="black"
          fill="none"
          points="5 58.95 11.99 51.92 5 44.93"
        />
        <polyline stroke="black" fill="none" points="4.55 95.45 12 88 5 81" />
        <polyline
          stroke="black"
          fill="none"
          points="40.55 95.45 48 88 30 70 47.99 51.92 30.07 34 48 16.07 37.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="58.55 95.45 66 88 48 70 65.99 51.92 48.07 34 66 16.07 55.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="76.55 95.45 84 88 66 70 83.99 51.92 66.07 34 84 16.07 73.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="94.55 95.45 102 88 84 70 101.99 51.92 84.07 34 102 16.07 91.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="121.55 95.45 129 88 111 70 128.99 51.92 111.07 34 129 16.07 118.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="130.55 95.45 138 88 120 70 137.99 51.92 120.07 34 138 16.07 127.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="139.55 95.45 147 88 129 70 146.99 51.92 129.07 34 147 16.07 136.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="148.55 95.45 156 88 138 70 155.99 51.92 138.07 34 156 16.07 145.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="157.55 95.45 165 88 147 70 164.99 51.92 147.07 34 165 16.07 154.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="166.55 95.45 174 88 156 70 173.99 51.92 156.07 34 174 16.07 163.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="175.55 95.45 183 88 165 70 182.99 51.92 165.07 34 183 16.07 172.01 5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="184.55 95.45 192 88 174 70 191.99 51.92 174.07 34 192 16.07 181.01 5.01"
        />
        <line
          stroke="black"
          fill="none"
          x1="195"
          y1="11.04"
          x2="189.01"
          y2="5.01"
        />
        <polyline
          stroke="black"
          fill="none"
          points="195 46.93 182.07 34 195 21.07"
        />
        <polyline stroke="black" fill="none" points="195 83 182 70 195 56.94" />
        <line
          stroke="black"
          fill="none"
          x1="192.55"
          y1="95.45"
          x2="195"
          y2="93"
        />
      </g>
    </svg>
  );
}
