import { Box } from "@material-ui/core";

interface IReportBodyProps {
  children?: React.ReactNode;
}

export function ReportBody(props: IReportBodyProps) {
  return (
    <Box mt={10} mb={6} overflow="hidden">
      {props.children}
    </Box>
  );
}

export function ReportBodyNoMargin(props: IReportBodyProps) {
  return <Box overflow="hidden">{props.children}</Box>;
}

export function ReportBodyAlignSpaceBetween(props: IReportBodyProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      height="100%"
    >
      {props.children}
    </Box>
  );
}

export function ReportBodyAlignBottom(props: IReportBodyProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      height="100%"
    >
      <Box flexGrow={1} />
      <Box>{props.children}</Box>
    </Box>
  );
}
