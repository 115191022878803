interface Sorts {
  weightPerBTA: number;
  A1_A5PerBTA: number;
}

export const bsabSumWithRowLimit = (
  rawData: {
    weightPerBTA: number;
    A1_A5PerBTA: number;
    ProductBSAB: string;
    ProductBSABCode: string;
  }[],
  maxRows: number,
  sumOn = "A1_A5PerBTA",
  label = "ProductBSAB"
) => {
  return rawData
    .sort((a, b) => b[sumOn as keyof Sorts] - a[sumOn as keyof Sorts])
    .reduce((acc: { name: string; value: number }[], row, i) => {
      if (i > maxRows) {
        acc[maxRows].value += row[sumOn as keyof Sorts] ?? 0;
      } else if (i === maxRows) {
        acc.push({
          name: "Resterande",
          value: row[sumOn as keyof Sorts] ?? 0,
        });
      } else {
        const name =
          (label === "ProductBSAB" &&
            (row.ProductBSAB === "Saknar BSAB-kod"
              ? row.ProductBSAB
              : `${row.ProductBSABCode} - ${row.ProductBSAB}`)) ||
          // @ts-ignore
          row[label];
        acc.push({
          name,
          value: row[sumOn as keyof Sorts] ?? 0,
        });
      }
      return acc;
    }, []);
};
