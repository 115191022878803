import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { IReportColumns, ReportTable } from "../components/table";
import { ReportStatisticsBox } from "../components/statistics-box";
import { ReportPieChart } from "../components/pie-chart";
import { ReportTypography } from "../components/report-typography";
import { omitRedundantFields } from "./report-gwp-summary-page";
import { bsabCodes } from "../bsab-codes";
import { groupBy } from "lodash";
import { capitalize } from "voca";
import { bsabSumWithRowLimit } from "./bsab-max-row.util";
import useDsrQueryHook from "../hooks/useDsrQueryHook";

import { ReportPageProps } from "../../report-page";
import { rounderInt, rounderTwo } from "../../../lib/rounder";

export const sumFunc = (acc: any, row: any) => ({
  A1_A5PerBTA:
    acc.A1_A5PerBTA + (Number.isFinite(row.A1_A5PerBTA) ? row.A1_A5PerBTA : 0),
  weightPerBTA:
    acc.weightPerBTA +
    (Number.isFinite(row.weightPerBTA) ? row.weightPerBTA : 0),
  weightDiff: acc.weightDiff + (row.weightDiff || 0),
  A1_A5PerBTADiff:
    acc.A1_A5PerBTADiff +
    (Number.isFinite(row.A1_A5PerBTADiff) ? row.A1_A5PerBTADiff : 0),
  A1_A5PerBuildingElementAmount:
    acc.A1_A5PerBuildingElementAmount +
    (Number.isFinite(row.A1_A5PerBuildingElementAmount)
      ? row.A1_A5PerBuildingElementAmount
      : 0),
  weightPerBTADiff:
    acc.weightPerBTADiff +
    (Number.isFinite(row.weightPerBTADiff) ? row.weightPerBTADiff : 0),
  weight: acc.weight + row.weight,
});

export function getRowsGroupedBy(rows: any[], numberOfLetters: number) {
  return Object.entries(
    groupBy(rows, (row) => row.ProductBSABCode.slice(0, numberOfLetters))
  ).map(([key, value]) => {
    const ProductBSAB = bsabCodes[key]
      ? capitalize(bsabCodes[key].toLowerCase())
      : "Saknar BSAB-kod";
    return {
      ProductBSAB:
        ProductBSAB.length > 60
          ? `${ProductBSAB.slice(0, 57)}...`
          : ProductBSAB,
      ProductBSABCode: key,
      ...value.reduce(sumFunc, {
        A1_A5PerBTA: 0,
        weightPerBTA: 0,
        weightDiff: 0,
        A1_A5PerBTADiff: 0,
        A1_A5PerBuildingElementAmount: 0,
        weightPerBTADiff: 0,
        weight: 0,
      }),
    };
  });
}

export default function ReportBsabSummaryPage({
  pageNumber,
  projectId,
  scenarioId,
  selectedDatasetIds,
}: ReportPageProps) {
  const chapter = "Resultat";
  const pageTitle = "Gruppering på BSAB 96-kod";

  const { data, sum: sumRow } = useDsrQueryHook({
    filter: [],
    group: [{ col: "ProductBSAB" }],
    aggregations: [
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "weightPerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "weight", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 3000,
  });

  const rows = omitRedundantFields(data).map(
    ({ weight, ProductBSAB = "-", ...row }: any) => ({
      ...row,
      weight: (weight ?? 0) / 1000,
      ProductBSAB: (ProductBSAB && bsabCodes[ProductBSAB]) || "Saknar BSAB-kod",
      ProductBSABCode: ProductBSAB,
    })
  );

  const columns: IReportColumns[] = [
    {
      label: "BSAB-kod",
      value: "ProductBSABCode",
      width: "1%",
      align: "left",
    },
    {
      label: "Beskrivning",
      value: "ProductBSAB",
      width: "60%",
      align: "left",
    },
    {
      label: "Vikt, kg/BTA",
      value: "weightPerBTA",
      align: "right",
    },

    {
      label: "kg CO₂e/BTA",
      value: "A1_A5PerBTA",
      align: "right",
    },
  ];

  const isBSABProductionResults = /^[A-Z]+/.test(
    rows?.[0]?.ProductBSABCode ?? ""
  ); // ESE - BETONGGJUTNINGAR I HUS
  const rowsGroupedOnFirstLetter = getRowsGroupedBy(rows, 1);
  const rowsGroupedOnFirstTwoLetters = getRowsGroupedBy(
    rows,
    isBSABProductionResults ? 3 : 2
  );

  const first = 10;
  const rowsAfterLimit = rowsGroupedOnFirstTwoLetters.slice(first).reduce(
    (acc, row) => {
      const weightPerBTA =
        (Number.isFinite(acc.weightPerBTA) ? acc.weightPerBTA : 0) +
        (Number.isFinite(row.weightPerBTA) ? row.weightPerBTA : 0);
      const A1_A5PerBTA =
        (Number.isFinite(acc.A1_A5PerBTA) ? acc.A1_A5PerBTA : 0) +
        (Number.isFinite(row.A1_A5PerBTA) ? row.A1_A5PerBTA : 0);
      const weight =
        (Number.isFinite(acc.weight) ? acc.weight : 0) +
        (Number.isFinite(row.weight) ? row.weight : 0);
      return {
        ...acc,
        A1_A5PerBTA: rounderInt(A1_A5PerBTA || 0),
        weight: rounderTwo(weight),
        weightPerBTA: rounderInt(weightPerBTA || 0),
      };
    },
    {
      A1_A5PerBTA: 0,
      ProductBSAB: "Övrigt",
      ProductBSABCode: "",
      weight: 0,
      weightPerBTA: 0,
    }
  );

  const rowsWithLimit = [
    ...(rowsGroupedOnFirstTwoLetters.length > first
      ? rowsGroupedOnFirstTwoLetters.slice(0, first)
      : rowsGroupedOnFirstTwoLetters),
    ...(rowsAfterLimit.A1_A5PerBTA ? [rowsAfterLimit] : []),
  ];

  const newRowsWithLimit = rowsWithLimit.map((row) => ({
    ...row,
    weightPerBTA: rounderInt(row.weightPerBTA),
    A1_A5PerBTA: rounderTwo(row.A1_A5PerBTA),
  }));

  const newRowsGroupedFirstLetter = rowsGroupedOnFirstLetter.map((row) => ({
    ...row,
    weightPerBTA: rounderInt(row.weightPerBTA),
    A1_A5PerBTA: rounderTwo(row.A1_A5PerBTA),
  }));

  return (
    <ReportContainer>
      <ReportHeader chapter={chapter} />
      <ReportBodyAlignSpaceBetween>
        <Box mt={2} mb={1} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>

        <Box flexGrow={1}>
          {!isBSABProductionResults && (
            <ReportTable
              columns={columns}
              hasRawMaterials={true}
              rows={newRowsGroupedFirstLetter}
              sumRow={{
                ...sumRow,
                weight: rounderInt((sumRow?.weight || 0) / 1000),
                A1_A5PerBTA: rounderTwo(sumRow?.A1_A5PerBTA),
              }}
            />
          )}
          <ReportTable
            columns={columns}
            hasRawMaterials={true}
            rows={newRowsWithLimit}
            sumRow={{
              ...sumRow,
              weight: rounderInt((sumRow?.weight || 0) / 1000),
              A1_A5PerBTA: rounderTwo(sumRow?.A1_A5PerBTA),
            }}
          />
        </Box>

        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <ReportStatisticsBox
                chart={() => (
                  <ReportPieChart
                    data={bsabSumWithRowLimit(rowsGroupedOnFirstLetter, 8)}
                  />
                )}
                title="Klimatpåverkan per BSAB-kod"
                caption="Fördelning på BSAB-kod, andel av klimatpåverkan"
              />
            </Box>
            <Box>
              <ReportStatisticsBox
                chart={() => (
                  <ReportPieChart
                    data={bsabSumWithRowLimit(rowsGroupedOnFirstTwoLetters, 8)}
                  />
                )}
                title="Klimatpåverkan per mer detaljerad BSAB-kod"
                caption="Fördelning på BSAB-kod, andel av klimatpåverkan"
              />
            </Box>
          </Box>
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} />
    </ReportContainer>
  );
}
