import { Box, Typography, Theme, makeStyles } from "@material-ui/core";
import {
  useProjectQuery,
  ReportTemplateFieldsFragment,
} from "../../../lib/generated/graphql";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  chapter: {
    fontWeight: 600,
  },
  project: {
    color: theme.palette.grey[500],
  },
}));

interface IReportHeaderProps {
  chapter?: string;
  reportType?: string;
}

export function ReportHeader(props: IReportHeaderProps) {
  const { projectId } = useParams();

  const project = useProjectQuery({
    skip: props.reportType === "simplified",
    variables: { projectId: projectId! },
  })?.data?.project;
  return (
    <ReportHeaderCommon
      {...props}
      reportTemplate={{
        id: project?.id ?? "",
        title: project?.name ?? "",
        logoUrl: project?.reportLogo?.url ?? "",
      }}
    />
  );
}

export function ReportHeaderCommon(
  props: IReportHeaderProps & {
    reportTemplate: Omit<ReportTemplateFieldsFragment, "generatedPdfs">;
  }
) {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex">
        <Typography className={classes.project}>
          {props.reportTemplate.title}
        </Typography>
        <Box ml={12}>
          <Typography className={classes.chapter} color="primary">
            {props.chapter && props.chapter.toUpperCase()}
          </Typography>
        </Box>
      </Box>
      <Box px={props.reportType === "simplified" ? 8 : 0}>
        <img
          src={
            props.reportTemplate.logoUrl ||
            "https://storage.googleapis.com/public-plant/app-assets/report/Plant-logo-utan-slogan-space-grey.svg"
          }
          style={{ maxWidth: 120, maxHeight: 55 }}
        />
        {props.reportType === "simplified" && (
          <div
            className="ribbon"
            style={{
              paddingTop: 25,
              textAlign: "center",
            }}
          >
            <div
              style={{
                color: "white",
                fontWeight: 600,
                fontSize: 5,
                paddingBottom: 2,
              }}
            >
              {"Förenklad"}
            </div>
            <div style={{ color: "white", fontWeight: 600, fontSize: 5 }}>
              {"Klimatberäkning"}
            </div>
          </div>
        )}
      </Box>
    </Box>
  );
}
