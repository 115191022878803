import { Box, Grid } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportTypography } from "../components/report-typography";
import InfabIllustration from "./assets/climate-declaration.png";

interface ListProps {
  listItems: string[] | React.ReactNode[];
}
const List = ({ listItems = [] }: ListProps) => {
  return (
    <ul>
      {listItems.map((item: string | React.ReactNode, ind) => {
        return (
          <li key={`listitem-${ind}`}>
            <ReportTypography variant="body1">{item}</ReportTypography>
          </li>
        );
      })}
    </ul>
  );
};

const Deliverables = () => {
  const listItem1 = `Vilka förändringar som skett i projektet från det underlag som ligger till grund för denna
    klimatberäkning till färdig byggnad`;
  const listItem2 = `Verifierade mängder för byggdelar som står för hälften av klimatpåverkan`;
  const listItem3 = `Uppmätt energianvändning på byggarbetsplats`;
  const listItem4 = `Leverantörsspecifik klimatdata som projektet vill använda i deklarationen, i form av
    EPDer, samt för vilka mängder dessa ska användas`;

  return <List listItems={[listItem1, listItem2, listItem3, listItem4]} />;
};

const A1A5 = () => {
  const a1a3Title = `A1-A3 Produktskedet: `;
  const a1a3text = `Baseras på BIM-modell och/eller kostnadskalkyl från projektet.`;
  const a4Title = `A4 Transport: `;
  const a4Text = `Generiska transportscenarion till byggarbetsplats från Boverkets klimatdatabas om inget annat angetts av projektet.`;
  const a5ATitle = `A5 Byggspill: `;
  const a5AText = `Generiska spillfaktorer från Boverkets klimatdatabas om inget annat angetts av projektet.`;
  const a5BTitle = `A5 Energi: `;
  const a5BText1 = `Schablon från KTH`;
  const a5BText2 = ` används innan underlag från projektet finns tillgängligt men i deklarationen måste faktiskt uppmätta mängder användas.`;

  return (
    <List
      listItems={[
        <>
          <b>{a1a3Title}</b> {a1a3text}
        </>,
        <>
          <b>{a4Title}</b> {a4Text}
        </>,
        <>
          <b>{a5ATitle}</b> {a5AText}
        </>,
        <>
          <b>{a5BTitle}</b>
          {a5BText1}
          <sup>1</sup>
          {a5BText2}
        </>,
      ]}
    />
  );
};

const BuildInstallationProcess = () => {
  const BIP1 = `Byggarbetsplatsens fordon, maskiner och verktyg`;
  const BIP2 = `Uppvärmning och driftsel för tillfälliga bodar, kontor, förråd och andra byggnader inklusive byggnaden under produktion`;
  const BIP3 = `Övriga energivaror, till exempel gasol och diesel för värmare, uttorkning och dylikt, köpt el, fjärrvärme med mera\n\n`;

  return <List listItems={[BIP1, BIP2, BIP3]} />;
};

export default function ClimateDeclarationPage(props: {
  pageNumber?: number;
  reportType: string;
}) {
  const pageTitle = "Klimatdeklaration".toUpperCase();
  const textPart1 = `Lagen om klimatdeklarationer innebär att de flesta byggnader med en bruttoarea på över 100,0 m2 som sökt bygglov från och med den 1 januari 2022 ska ta fram en klimatdeklaration. Det är byggherren som ansvarar för att klimatdeklarationen tas fram och lämnas in till Boverket innan kommunen ger slutbesked. Byggherren behöver inte själva ta fram deklarationen, utan kan delegera ansvaret till annan lämplig part.\n\n`;
  const headerPart2 = `Vad krävs av projektet för att klimatberäkningen ska bli en giltig klimatdeklaration?\n`;
  const textPart2 = `För att Plant ska kunna leverera en giltig klimatdeklaration som når upp till lagkrav måste projektet leverera följande:\n\n`;

  const subHeaderPart3 = `Omfattning\n`;
  const textPart3 = `Klimatdeklaration ska spegla färdig byggnad. Det innebär att för att vara en giltig deklaration ska underlaget som använts i beräkning komma från bygghandlingsskedet (upphandlingsskedet) eller senare men hänsyn ska också tas till ändringar som skett därefter som har betydelse för den totala klimatpåverkan. Deklarationen ska omfatta klimatpåverkan för klimatskärm, samtliga bärande konstruktionsdelar samt innerväggar för LCA-skedena A1-A5, se bild och specifikation nedan:\n\n`;

  const header4 = `Klimatdata\n`;
  const textPart4a = `​​I en klimatdeklaration får man använda antingen generiska klimatdata från Boverkets klimatdatabas eller specifika klimatdata för byggprodukter (EPD:er). Generisk klimatdata för Boverket finns att tillgå som ett genomsnittsvärde (typiskt värde) alternativt som ett konservativt värde. Det konservativa värdet har ett påslag av 25 % med syftet att stimulera användning av specifik klimatdata. Vi på Plant använder genomsnittsvärden fram till att en deklaration ska lämnas in, då kräver Boverket att konservativa värden används för generisk klimatdata.\n\n`;
  const textPart4b = `I de fall specifik klimatdata används kräver Boverket också verifikat på vilken mängd som avses. Därför behöver Plant få information om vilken produkt som köpts in samt vart den används.\n\n`;

  const header5 = `Täckningsgrad\n`;
  const textPart5a = `Täckningsgraden är ett mått på hur stor del av byggnadens klimatpåverkan som kunnat beräknas och utifrån den görs en uppräkning av klimatpåverkan för att klimatdeklarationen ska spegla 100 procent av byggnadens klimatpåverkan från de byggprodukter som omfattas. Det vanligaste sättet hittills att beräkna täckningsgraden är utifrån en kostnadskalkyl där summan av kostnaden för alla byggprodukter i resurssammanställningen som kunnat beräknas divideras med den totala kostnaden för alla byggprodukter som ingår.\n\n`;
  const textPart5b = `Denna metod fungerar inte när underlaget för klimatberäkningen istället i huvudsak är BIM-modeller utan i detta fall får beräkningen kring täckningsgrad baseras på tidigare erfarenhet vilket också uppfyller kraven enligt lagen. Plants metodik för att beräkna täckningsgraden är att ta fram erfarenhetsvärden för klimatpåverkan från byggdelar som ofta saknas i modellerna eller som är svåra att beräkna för att genom tillägg av dessa kunna räkna upp klimatpåverkan till att omfatta byggnaden i sin helhet inom klimatdeklarationens omfattning.`;
  const textPart5c = `Denna metodik är under utveckling och vår bedömning är att vi i dagsläget saknar erfarenhetsvärden för byggdelar motsvarande 5 % av byggnadens klimatpåverkan varför en sista uppräkning utifrån detta görs.\n\n`;

  const header6 = `Verifierade mängder\n`;
  const textPart6 = `För att en klimatdeklaration ska vara giltig måste vissa byggprodukter som används i byggnaden för modul A1–A3 och dess mängder verifieras. Verifikat kan exempelvis utgöras av fakturaunderlag, leveransbesked, sammanställning av mängder av aktuella byggprodukter från leverantör eller dylikt, där egenskaper som är viktiga för klimatpåverkan framgår. Totalt ska minst 50 % av byggnadens totala klimatpåverkan verifieras med faktiska mängder.\n\n`;

  const header7 = `Bygg och installationsprocess\n`;
  const textPart7a = `Boverket kravställer att all användning av el, värme och bränslen på byggarbetsplatsen, förutom energi och bränsle för markarbeten, ska mätas och ingå i klimatdeklarationen. Detta gäller exempelvis för:\n\n`;
  const textPart7b = `Denna information måste sammanställas och skickas vidare till oss på Plant.`;

  return (
    <>
      <ReportContainer>
        <ReportHeader
          chapter="Klimatdeklaration"
          reportType={props.reportType}
        />

        <Box mb={2} mt={4} textAlign="left">
          <ReportTypography variant="h2">{pageTitle}</ReportTypography>
        </Box>

        <Box height={940} display="grid" gridTemplateRows="auto 1fr">
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={6}>
              <ReportTypography variant="body1">{textPart1}</ReportTypography>
              <ReportTypography variant="h6">{headerPart2}</ReportTypography>
              <ReportTypography variant="body1">{textPart2}</ReportTypography>
              <Deliverables />
            </Grid>
            <Grid item xs={6}>
              <ReportTypography variant="h6">{subHeaderPart3}</ReportTypography>
              <ReportTypography variant="body1">{textPart3}</ReportTypography>
              <A1A5 />
            </Grid>

            <Grid item xs={9}>
              <img alt="building-illustration" src={InfabIllustration} />
              <ReportTypography variant="body1">
                Illustration: Infab<sup>2</sup>
              </ReportTypography>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" flexDirection="column">
            <hr style={{ marginBottom: "1rem" }} />
            <ReportTypography variant="body1">
              <sup>{`1 `}</sup>
              Malmqvist et al. (2021).{" "}
              <i>
                Referensvärden för klimatpåverkan vid uppförande av byggnader
                (finns att hämta på:{" "}
              </i>{" "}
              <br />
              <a
                href="https://www.boverket.se/sv/byggande/hallbart-byggande-och-forvaltning/referensvarden-for-byggandets-klimatpaverkan/"
                style={{ color: "#1151C8" }}
                target="_blank"
              >
                {`https://www.boverket.se/sv/byggande/hallbart-byggande-och-forvaltning/referensvarden-for-byggandets-klimatpaverkan `}
              </a>
              <i>)</i>
            </ReportTypography>
            <ReportTypography variant="body1">
              <sup>{`2 `}</sup>
              Boverket (2022) <i>Byggdelar som ingår.</i> <br />
              <a
                href="https://www.boverket.se/sv/klimatdeklaration/gor-sa-har/byggdelar-som-ingar/"
                style={{ color: "#1151C8" }}
                target="_blank"
              >
                {`https://www.boverket.se/sv/klimatdeklaration/gor-sa-har/byggdelar-som-ingar `}
              </a>
              Hämtad 2022-06-14.
            </ReportTypography>
          </Box>
        </Box>

        <ReportFooter pageNumber={props.pageNumber} />
      </ReportContainer>

      <ReportContainer>
        <ReportHeader
          chapter="Klimatdeklaration"
          reportType={props.reportType}
        />
        <Box height="100%" mt={4} display="grid" gridTemplateRows="auto 1fr">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ReportTypography variant="h6">{header4}</ReportTypography>
              <ReportTypography variant="body1">{textPart4a}</ReportTypography>
              <ReportTypography variant="body1">{textPart4b}</ReportTypography>
              <ReportTypography variant="h6">{header5}</ReportTypography>
              <ReportTypography variant="body1">{textPart5a}</ReportTypography>
              <ReportTypography variant="body1">{textPart5b}</ReportTypography>
            </Grid>

            <Grid item xs={6}>
              <ReportTypography variant="body1">{textPart5c}</ReportTypography>
              <ReportTypography variant="h6">{header6}</ReportTypography>
              <ReportTypography variant="body1">{textPart6}</ReportTypography>
              <ReportTypography variant="h6">{header7}</ReportTypography>
              <ReportTypography variant="body1">{textPart7a}</ReportTypography>
              <BuildInstallationProcess />
              <ReportTypography variant="body1">{textPart7b}</ReportTypography>
            </Grid>
          </Grid>
        </Box>

        <ReportFooter pageNumber={props.pageNumber} />
      </ReportContainer>
    </>
  );
}
