import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider as MaterialUiCoreProvider } from "@material-ui/core/styles";
import { ThemeProvider as MuiProvider } from "@mui/material";
import { ChakraProvider } from "@chakra-ui/react";
import { ApolloProvider } from "./ApolloProvider";
import { GlobalStateProvider } from "./GlobalStateProvider";
import * as themes from "./styling";

export const WithProviders: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => (
  <BrowserRouter>
    <GlobalStateProvider>
      <ApolloProvider>
        <MaterialUiCoreProvider theme={themes.materialUiCoreTheme}>
          <MuiProvider theme={themes.theme}>
            <ChakraProvider theme={themes.chakraTheme}>
              {
                children // extra long
              }
            </ChakraProvider>
          </MuiProvider>
        </MaterialUiCoreProvider>
      </ApolloProvider>
    </GlobalStateProvider>
  </BrowserRouter>
);
