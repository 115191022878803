import moment from "moment";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportTypography } from "../components/report-typography";
import { useProjectQuery } from "../../../lib/generated/graphql";
import FirstPageGraphic from "../components/FirstPageGraphic.svg";
import { ReportPageProps } from "../../report-page";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";

export default function ReportFirstPage(props: ReportPageProps) {
  const { projectId } = props;

  const project = useProjectQuery({ variables: { projectId } })?.data
    ?.project || {
    name: "",
    reportTitle: "",
    reportSubTitle: "",
    reportLogo: { url: "" },
    reportAuthor: "",
  };

  const bgcolor = props.reportType === "simplified" ? "#ff9408" : "#434343";
  const color = props.reportType === "simplified" ? "#ffae23" : "#575757";
  const title =
    props.reportType === "simplified"
      ? "Förenklad Klimatberäkning"
      : "Klimatrapport";

  return (
    <ReportContainer style={{ padding: 0, position: "relative" }}>
      <Grid flexGrow={1} gridTemplateRows="1fr auto auto" overflow={"hidden"}>
        <GridItem color="white" bg={bgcolor} position="relative" width={"100%"}>
          <Flex
            position="absolute"
            height={"100vh"}
            width="100vw"
            display="block"
          >
            <FirstPageGraphic color={color} />
          </Flex>
          <Box mx={10} position="absolute" bottom={10} zIndex="1">
            <ReportTypography variant="h1">{title}</ReportTypography>
            <ReportTypography variant="h1">
              {project.reportTitle || project.name}
            </ReportTypography>
            <Flex mt={2}>
              <ReportTypography variant="h4">
                {project.reportSubTitle ||
                  `Beräknad klimatpåverkan för projekt ${project.name}`}
              </ReportTypography>
            </Flex>
          </Box>
        </GridItem>
        <GridItem mt={10} textAlign="left" px={10}>
          {project.reportLogo?.url && (
            <Flex mb={1}>
              <img
                src={project.reportLogo.url}
                style={{ maxWidth: 177, maxHeight: 90 }}
              />
            </Flex>
          )}
          {props.reportType !== "simplified" && (
            <ReportTypography variant="h5">
              {`Rapport framställd av: ${project.reportAuthor}`}
            </ReportTypography>
          )}
          <ReportTypography variant="caption">
            www.plant.se, info@plant.se
          </ReportTypography>
          <Flex>
            <ReportTypography variant="caption">
              {moment().format("YYYY-MM-DD")}
            </ReportTypography>
          </Flex>
        </GridItem>
        <GridItem textAlign="left" pb={"32px"} px="70px">
          <ReportFooter skipPaddingTop />
        </GridItem>
      </Grid>
    </ReportContainer>
  );
}
