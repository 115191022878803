import { FunctionComponent } from "react";
import { Box } from "@material-ui/core";
import { ReportTypography } from "./report-typography";

interface IReportStatisticsBoxProps {
  barchart?: FunctionComponent;
  gwpScorecard?: FunctionComponent;
  chart?: FunctionComponent;
  title?: string;
  caption?: string;
  skipMarginLeft?: boolean;
  skipMarginY?: boolean;
  skipBorderBottom?: boolean;
  skipBorderTop?: boolean;
  skipHeight?: boolean;
}

export function ReportStatisticsBox(props: IReportStatisticsBoxProps) {
  return (
    <Box
      borderTop={props.skipBorderTop ? 0 : 2}
      borderBottom={props.skipBorderBottom ? 0 : 2}
      width={315}
    >
      <Box
        my={0.5}
        height={props.skipHeight ? "" : 200}
        display="flex"
        flexDirection="column"
      >
        {props.title && (
          <Box height={21} mx={1.5}>
            <ReportTypography
              variant="h5"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: 12,
              }}
            >
              {props.title}
            </ReportTypography>
          </Box>
        )}
        <Box
          my={props.skipMarginY ? 0 : 1}
          flexGrow={1}
          display="flex"
          alignItems="center"
          width="100%"
          marginLeft={
            props.skipMarginLeft
              ? 0
              : props.barchart
              ? 1
              : props.gwpScorecard
              ? 1.5
              : -1.5
          }
        >
          <Box width="100%">
            {(props.barchart && props.barchart({})) ||
              (props.gwpScorecard && props.gwpScorecard({})) ||
              (props.chart && props.chart({})) ||
              ""}
          </Box>
        </Box>
        <Box mx={1.5}>
          <ReportTypography variant="caption">{props.caption}</ReportTypography>
        </Box>
      </Box>
    </Box>
  );
}
