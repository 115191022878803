import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportTypography } from "../components/report-typography";
import { ReportPageProps } from "../../report-page";
import { useParams } from "react-router-dom";
import { useProjectQuery } from "../../../lib/generated/graphql";
import useDsrQueryHook from "../hooks/useDsrQueryHook";
import { IReportColumns, ReportTable } from "../components/table";
import { ReportStatisticsBox } from "../components/statistics-box";
import { ReportPieChart } from "../components/pie-chart";
import { Flex, Box } from "@chakra-ui/react";
import { useMemo } from "react";
import { rounderTwo } from "../../../lib/rounder";

const query = {
  filter: [{ Include: { eq: "TRUE" } }],
  aggregations: [
    {
      col: "A1_A3PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A4PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A5PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A1_A5PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A1_A5",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A5_1",
      op: "sum",
      reduceAggOp: "sum",
    },
  ],
  group: [{ col: "FunctionType" }],
  sort: [{ col: "FunctionType", desc: false }],
  topN: 100,
};

const columns: IReportColumns[] = [
  {
    label: "Function Type",
    value: "FunctionType",
    align: "left",
  },
  {
    label: "A1-A3 *",
    value: "A1_A3PerBTA",
    align: "right",
  },
  {
    label: "A4 *",
    value: "A4PerBTA",
    align: "right",
  },
  {
    label: "A5 *",
    value: "A5PerBTA",
    align: "right",
  },
  {
    label: "A5 spill *",
    value: "A5_1",
    align: "right",
  },
  {
    label: "A1-A5 *",
    value: "A1_A5PerBTA",
    align: "right",
  },
  {
    label: "A1-A5 kg CO2e",
    value: "A1_A5",
    align: "right",
  },
];

export default function ReportFunctionTypes(props: ReportPageProps) {
  const pageTitle = "Function Types".toUpperCase();

  const params = useParams();
  const projectId = params.projectId!;

  const project = useProjectQuery({ variables: { projectId } });

  const area = project?.data?.project?.area || 1;
  const declaredBuildingParts =
    project?.data?.project?.declaredBuildingParts || [];
  const dsrs = useDsrQueryHook(query);

  const data = dsrs?.data || [];

  const roundedData = useMemo(
    () =>
      data.map((row) => {
        return {
          ...row,
          A1_A3PerBTA: rounderTwo(row.A1_A3PerBTA),
          A4PerBTA: rounderTwo(row.A4PerBTA),
          A5PerBTA: rounderTwo(row.A5PerBTA),
          A5_1: rounderTwo(row.A5_1 / area),
          A1_A5PerBTA: rounderTwo(row.A1_A5PerBTA),
          A1_A5: rounderTwo(row.A1_A5),
        };
      }),
    [data]
  );

  const groupedOnFirstLetter = useMemo(() => {
    return data.reduce((acc, cur) => {
      const key = cur?.FunctionType?.[0];
      const value = (acc[key] || 0) + cur.A1_A5;
      return { ...acc, [key]: Math.round(value) };
    }, {});
  }, [data]);

  const pieGroupData = useMemo(() => {
    return Object.entries(groupedOnFirstLetter).map(([key, value]) => {
      const name =
        declaredBuildingParts.find((part) => part.code === key)?.label || "";

      return {
        name,
        value: value as number,
      };
    });
  }, [groupedOnFirstLetter, declaredBuildingParts]);

  const pieTop10Data = useMemo(() => {
    return [...data]
      .sort((a, b) => b.A1_A5 - a.A1_A5)
      .map((d) => ({
        name: d.FunctionType,
        value: Math.round(d.A1_A5),
      }))
      .splice(0, 10);
  }, [data]);

  return (
    <ReportContainer>
      <ReportHeader chapter="Tillvägagångssätt" reportType={props.reportType} />

      <Box mt={5} mb={4} textAlign="left">
        <ReportTypography variant="h3">{pageTitle}</ReportTypography>
      </Box>

      <Box mb={4}>
        <ReportTable
          columns={columns}
          hasRawMaterials={true}
          tableUnit="* = kg CO2e/BTA"
          rows={roundedData}
          sumRow={dsrs.sum}
        />
      </Box>

      <Flex>
        <ReportStatisticsBox
          chart={() => <ReportPieChart data={pieGroupData} />}
          title="A1-A5 per function type group"
        />
        <ReportStatisticsBox
          chart={() => <ReportPieChart data={pieTop10Data} />}
          title="A1-A5 per function type (top 10)"
        />
      </Flex>

      <ReportFooter pageNumber={props.pageNumber} />
    </ReportContainer>
  );
}
