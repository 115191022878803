import { DsrResp } from "./useDsrQueryHook";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DsrGroupedQueryInput,
  DsrQuery,
  PipelineOutputGroupedDocument,
  PipelineOutputGroupedQuery,
  PipelineOutputGroupedQueryVariables,
  ScenarioDatasetRecordsGroupedDocument,
  ScenarioDatasetRecordsGroupedQuery,
  ScenarioDatasetRecordsGroupedQueryVariables,
  useListScenariosQuery,
  useProjectPipelinesQuery,
} from "../../../lib/generated/graphql";
import { useParams } from "react-router";
import { useGlobalState } from "../../../providers/GlobalStateProvider";
import { useApolloClient } from "@apollo/client";
import { newMaterialFilter } from "./material-filter";

export interface DsrScenarioResp extends DsrResp {
  sumByScenario: { [key: string]: any };
  restByScenario: { [key: string]: any };
}

export const useGetBenchmarks = (projectId: string) => {
  const state = useGlobalState();

  const scenarios =
    useListScenariosQuery({
      skip: !!state.pipelineId,
      variables: { projectId },
    })?.data?.project?.scenarios || [];

  const pipelines =
    useProjectPipelinesQuery({
      skip: !state.pipelineId,
      variables: { projectId },
    }).data?.project?.pipelines.nodes || [];

  const pipelineData = useMemo(() => {
    const result = pipelines.filter(({ id }) =>
      state.benchmarkPipelineIds.includes(id)
    );

    const mainPipeline = pipelines.find(({ id }) => id === state.pipelineId);
    if (mainPipeline) {
      result.unshift(mainPipeline);
    }

    return result.map(({ id, name }) => ({ id, name, type: "pipeline" }));
  }, [state.benchmarkPipelineIds, pipelines]);

  const scenarioData = useMemo(
    () => scenarios.map(({ id, name }) => ({ id, name, type: "scenario" })),
    [scenarios]
  );

  if (state.pipelineId) {
    return pipelineData;
  }

  return scenarioData;
};

const useFetchScenario = () => {
  const client = useApolloClient();

  const fetcher = useCallback(
    async (query: {
      variables: ScenarioDatasetRecordsGroupedQueryVariables;
    }) => {
      const resp = await client.query<
        ScenarioDatasetRecordsGroupedQuery,
        ScenarioDatasetRecordsGroupedQueryVariables
      >({
        query: ScenarioDatasetRecordsGroupedDocument,
        ...query,
      });
      const data = resp?.data?.project?.scenario?.datasetRecords?.grouped;

      return {
        data: data?.data ?? [],
        sum: data?.sum ?? {},
        rest: data?.rest ?? {},
      };
    },
    [client]
  );

  return fetcher;
};
const useFetchPipeline = () => {
  const client = useApolloClient();

  const fetcher = useCallback(
    async (query: { variables: PipelineOutputGroupedQueryVariables }) => {
      const resp = await client.query<
        PipelineOutputGroupedQuery,
        PipelineOutputGroupedQueryVariables
      >({
        query: PipelineOutputGroupedDocument,
        ...query,
      });
      const data = resp?.data?.project?.pipeline.output.grouped;

      return {
        data: data?.data ?? [],
        sum: data?.sum ?? {},
        rest: data?.rest ?? {},
      };
    },
    [client]
  );

  return fetcher;
};

const useScenariosQueries = (query: DsrGroupedQueryInput): DsrScenarioResp => {
  const [mergedDsrs, setMergedDsrs] = useState<DsrScenarioResp>({
    data: [],
    rest: undefined,
    sum: undefined,
    sumByScenario: {},
    restByScenario: {},
  });
  const params = useParams();
  const projectId = params.projectId!;
  const { pipelineId } = useGlobalState();
  const ids = useGetBenchmarks(projectId);

  const fetchScenario = useFetchScenario();
  const fetchPipeline = useFetchPipeline();

  useEffect(() => {
    (async () => {
      const allDsrs = await Promise.all(
        ids.map(async ({ id }) => {
          if (pipelineId) {
            const dsrs = await fetchPipeline({
              variables: {
                projectId: projectId || "",
                pipelineId: id || "",
                query: {
                  ...query,
                  filter: [...(query.filter || []), ...newMaterialFilter],
                },
              },
            });

            return {
              scenarioId: id,
              data: dsrs.data.map((r) => ({ ...r, id })),
              sum: dsrs.sum,
              rest: dsrs.rest,
            };
          } else {
            const dsrs = await fetchScenario({
              variables: {
                projectId: projectId || "",
                scenarioId: id || "",
                query: {
                  ...query,
                  filter: [...(query.filter || []), ...newMaterialFilter],
                },
              },
            });

            return {
              scenarioId: id,
              data: dsrs.data.map((r) => ({ ...r, id })),
              sum: dsrs.sum,
              rest: dsrs.rest,
            };
          }
        })
      );

      setMergedDsrs({
        data: allDsrs.flatMap((d) => d.data),
        sum: undefined,
        rest: undefined,
        sumByScenario: allDsrs.reduce(
          (agg, { scenarioId, sum }) => ({ ...agg, [scenarioId]: sum }),
          {}
        ),
        restByScenario: allDsrs.reduce(
          (agg, { scenarioId, rest }) => ({ ...agg, [scenarioId]: rest }),
          {}
        ),
      });
    })();
  }, [ids]);
  return mergedDsrs;
};

export default useScenariosQueries;
