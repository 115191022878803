import { Box, capitalize } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { ReportStatisticsBox } from "../components/statistics-box";
import { ReportPieChart } from "../components/pie-chart";
import { ReportTypography } from "../components/report-typography";
import { useRawMaterialsQuery } from "../../../lib/generated/graphql";
import {
  omitRedundantFields,
  replaceProductRawMaterialIdWithName,
} from "./report-gwp-summary-page";
import { bsabCodes } from "../bsab-codes";
import { groupBy } from "lodash";
import { bsabSumWithRowLimit } from "./bsab-max-row.util";
import { ReportPageProps } from "../../report-page";

import useDsrQueryHook from "../hooks/useDsrQueryHook";

const sumFunc = <A, E>(acc: A, row: E): A => {
  // @ts-ignore
  Object.entries(row).forEach(([columnName, columnValue]) => {
    // @ts-ignore
    if (Number.isFinite(columnValue) && Number.isFinite(acc[columnName])) {
      // @ts-ignore
      acc[columnName] = (acc[columnName] ?? 0) + (columnValue ?? 0);
    } else {
      // @ts-ignore
      acc[columnName] = columnValue;
    }
  });

  return acc;
};

export function getRowsGroupedByMaterial(rows: any[]) {
  return Object.entries(groupBy(rows, (row) => row.ProductRawMaterialId)).map(
    ([key, value]) => {
      return {
        ProductRawMaterialId: key,
        ...value.reduce(sumFunc, {}),
      };
    }
  );
}

export default function ReportBsabRawmaterialsPage({
  pageNumber,
  projectId,
  scenarioId,
  selectedDatasetIds,
}: ReportPageProps) {
  const chapter = "Resultat";
  const pageTitle = "Material grupperade på BSAB96-kod";
  const rawMaterials = useRawMaterialsQuery({ variables: { projectId } })?.data
    ?.project?.rawMaterials;

  const rawMaterialsById = groupBy(rawMaterials, "id");

  const dsrs = useDsrQueryHook({
    filter: [
      {
        NOT: {
          ProductBSAB: "is_null",
        },
      },
    ],
    group: [
      { col: "ProductBSAB" },
      { col: "plant::rawMaterial::id", as: "ProductRawMaterialId" },
    ],
    aggregations: [
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "weight", op: "sum", reduceAggOp: "sum" },
      { col: "weightPerBTA", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 3000,
  });

  const rows = replaceProductRawMaterialIdWithName(
    omitRedundantFields(dsrs.data).map(
      ({ weight, ProductBSAB = "-", ...row }: any) => ({
        ...row,
        weight: (weight ?? 0) / 1000,
        ProductBSAB:
          (ProductBSAB && bsabCodes[ProductBSAB]) || "Saknar BSAB96-kod",
        ProductBSABCode: ProductBSAB,
      })
    ),
    rawMaterialsById
  );

  const bsabAndMaterialGroups = Object.entries(
    groupBy(rows, (row) => row.ProductBSABCode.slice(0, 1))
  );

  const nameIndex = [
    ...Object.keys(
      groupBy(rows, ({ ProductRawMaterialId }) => ProductRawMaterialId)
    ),
    "Resterande",
  ];

  const charts = bsabAndMaterialGroups.map(([key, value]) => (
    <Box key={key}>
      <ReportStatisticsBox
        chart={() => (
          <ReportPieChart
            data={bsabSumWithRowLimit(
              // @ts-ignore
              getRowsGroupedByMaterial(value),
              8,
              "A1_A5PerBTA",
              "ProductRawMaterialId"
            )}
            nameIndex={nameIndex}
            centerLabel={`${Math.round(
              value.reduce(sumFunc, {})?.A1_A5PerBTA
            )} kg CO₂e/BTA`}
          />
        )}
        title={`${key ?? ""} - ${capitalize(
          (bsabCodes[key] ?? "").toLowerCase()
        )}`}
        caption={`Klimatpåverkan per material för BSAB96-kod ${key ?? ""}`}
      />
    </Box>
  ));

  return (
    <>
      <ReportContainer>
        <ReportHeader chapter={chapter} />
        <ReportBodyAlignSpaceBetween>
          <Box mt={5} mb={1} textAlign="left">
            <ReportTypography variant="h3">{pageTitle}</ReportTypography>
          </Box>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            {charts.slice(0, 8).map((chart) => chart)}
          </Box>
        </ReportBodyAlignSpaceBetween>
        <ReportFooter pageNumber={pageNumber} />
      </ReportContainer>
      {charts.length > 8 && (
        <ReportContainer>
          <ReportBodyAlignSpaceBetween>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              {charts.slice(8).map((chart) => chart)}
            </Box>
          </ReportBodyAlignSpaceBetween>
          <ReportFooter pageNumber={pageNumber} />
        </ReportContainer>
      )}
    </>
  );
}
