import { useGetMeQuery } from "../../generated/graphql";
import LoginComponent from "./login-component";
import { useGlobalState } from "../../../providers/GlobalStateProvider";
import { AuthState } from "../../../providers/GlobalStateProvider/types";
import FullscreenLoader from "../fullscreen-loader";
import VerifyEmailPrompt from "./verify-email-prompt";

const isLoadingAuthState = (a: AuthState): boolean =>
  a === AuthState.LOGGING_IN ||
  a === AuthState.LOGGING_OUT ||
  a === AuthState.LOADING;

// export const withAuthorization = (
//   Component: React.ReactElement,
//   requiredScope: string
// ): React.FC => props => {
//   const { data, loading } = useGetMeQuery();
//   const authState = useGlobalState().authState;
//   const emailVerificationRequired = useGlobalState().emailVerificationRequired;
//
//   if (emailVerificationRequired) {
//     return <VerifyEmailPrompt />;
//   }
//
//   if (
//     loading ||
//     isLoadingAuthState(authState) ||
//     (authState === AuthState.LOGGED_IN && data?.me == null)
//   ) {
//     return <FullscreenLoader />;
//   }
//
//   if (data?.me?.scopes?.includes(requiredScope)) {
//     return <Component {...props} />;
//   }
//
//   if (authState === AuthState.LOGGED_OUT) {
//     return (
//       <LoginComponent
//         onLoggedIn={() => {
//           /*do nothing*/
//         }}
//       />
//     );
//   }
//
//   return <div>Unauthorized</div>;
// };

const WithAuthorization: React.FC<{
  requiredScope: string;
  children: React.ReactElement;
}> = ({ requiredScope, children }) => {
  const { data, loading } = useGetMeQuery();
  const authState = useGlobalState().authState;
  const emailVerificationRequired = useGlobalState().emailVerificationRequired;

  if (emailVerificationRequired) {
    return <VerifyEmailPrompt />;
  }

  if (
    loading ||
    isLoadingAuthState(authState) ||
    (authState === AuthState.LOGGED_IN && data?.me == null)
  ) {
    return <FullscreenLoader />;
  }

  if (data?.me?.scopes?.includes(requiredScope)) {
    return children;
  }

  if (authState === AuthState.LOGGED_OUT) {
    return (
      <LoginComponent
        onLoggedIn={() => {
          /*do nothing*/
        }}
      />
    );
  }

  return <div>Unauthorized</div>;
};

export default WithAuthorization;
