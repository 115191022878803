import React, { useState } from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import DataField from "./dataField";
import {
  SimplifiedTenantAdaptionTemplateData,
  SimplifiedTenantAdaptionScenario,
  BuildingElement,
} from "../../lib/generated/graphql";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { MdHelp as HelpIcon } from "react-icons/md";
import { grey } from "@material-ui/core/colors";
import {
  BuildingElementCard,
  getUnit,
} from "../../lib/components/building-element/building-element-card";
import { unnestGQLBuildingElement } from "../../lib/components/building-element/common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupName: {
      fontWeight: 600,
    },
    hover: {
      "&:hover": {
        background: theme.palette.action.hover,
      },
    },
  })
);

const ScenarioDataTable: React.FC<{
  onChange: () => void;
  projectId: string;
  templateData: SimplifiedTenantAdaptionTemplateData;
  scenario: SimplifiedTenantAdaptionScenario;
  disabled: boolean;
}> = ({ onChange, projectId, templateData, scenario, disabled }) => {
  const classes = useStyles();
  const [showBuildingElement, setShowBuildingElement] = useState<
    string | undefined
  >();
  return (
    <Box mb={2}>
      {templateData.headers.map((header) => (
        <Box key={header.name} mt={2} pl={6} pr={8}>
          <Box color="text.disabled" mb={1}>
            <Typography className={classes.groupName}>{header.name}</Typography>
          </Box>
          <Box pl={4}>
            {header.rows.map((row) => {
              const items = templateData.columns.map((column) => {
                const buildingElementAmount = scenario.rows.find(
                  (r) => r.rowKey === row.key && r.columnKey === column.key
                )?.buildingElementAmount;
                return {
                  node: (
                    <DataField
                      onChange={onChange}
                      key={column.key}
                      projectId={projectId}
                      scenarioId={scenario.id}
                      columnKey={column.key}
                      rowKey={row.key}
                      buildingElementAmount={buildingElementAmount ?? undefined}
                      label={`${row.name}, ${column.name}`}
                      unit={row.buildingElement.unit ?? ""}
                      disabled={disabled}
                    />
                  ),
                  buildingElement: row.buildingElement,
                };
              });
              const buildingElement = items[0]?.buildingElement;

              return (
                <Box key={row.key} position="relative">
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.hover}
                    position="relative"
                  >
                    {items.map(({ node }) => node)}
                  </Box>
                  <Box position="absolute" top={-16} left={296} zIndex={5}>
                    <IconButton
                      onClick={() => {
                        setShowBuildingElement(
                          row.key === showBuildingElement ? undefined : row.key
                        );
                      }}
                    >
                      <HelpIcon cursor="pointer" color={grey[600]} size={20} />
                    </IconButton>
                  </Box>
                  {buildingElement && row.key === showBuildingElement && (
                    <Box mt={2} ml={15}>
                      <BuildingElementCard
                        buildingElement={unnestGQLBuildingElement(
                          buildingElement
                        )}
                        columns={[
                          { key: "thickness", label: "Tjocklek" },
                          { key: "amount", label: "Mängd" },
                          { key: "name", label: "" },
                          {
                            key: "A1_A5",
                            label: (be) =>
                              `CO₂e/${getUnit(be.unit ?? undefined)}`,
                          },
                        ]}
                      />
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ScenarioDataTable;
