import { useLocation } from "react-router-dom";
import qs from "query-string";

type LocationApiKeyType = string | null;

export const useLocationApiKey = (): LocationApiKeyType => {
  const location = useLocation();
  const apiKey = qs.parse(location.search)?.apiKey;

  if (typeof apiKey === "string") {
    return apiKey;
  }
  return null;
};
