import * as React from "react";
import { createContext, useReducer } from "react";
import { Action, State } from "./types";
import { initialState, reducer } from "./GlobalState.reducer";

interface IStateProvider {
  children: React.ReactNode;
}

export const StateContext = createContext<State>({ ...initialState });
export const DispatchContext = createContext<React.Dispatch<Action>>(() => 0);

export const GlobalStateProvider: React.FC<IStateProvider> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};
