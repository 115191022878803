import { Box, useTheme, Grid } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportTypography } from "../components/report-typography";
import { useProjectQuery } from "../../../lib/generated/graphql";
import { ReportBodyAlignBottom } from "../components/body";
import { ReportStatisticsBox } from "../components/statistics-box";
import { ReportPieChart } from "../components/pie-chart";
import { ReportGWPScorecard } from "../components/gwp-scorecard";
import useDsrQueryHook from "../hooks/useDsrQueryHook";

import { ReportPageProps } from "../../report-page";
import { DefaultTheme } from "../../../providers/styling";

export default function ReportIntroductionPage({
  tmplVars,
  pageNumber,
  projectId,
  scenarioId,
  selectedDatasetIds,
}: ReportPageProps) {
  const theme = useTheme<DefaultTheme>();
  const project = useProjectQuery({ variables: { projectId } })?.data?.project;

  const dsrs = useDsrQueryHook({
    filter: [],
    group: [{ col: "datasetId" }],
    aggregations: [
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "A1_A5", op: "sum", reduceAggOp: "sum" },
      { col: "A1_A3", op: "sum", reduceAggOp: "sum" },
      { col: "A4", op: "sum", reduceAggOp: "sum" },
      { col: "A5", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 30,
  });

  const gwpScorecard = dsrs.sum || {};

  const pageTitle = "Tillvägagångssätt, osäkerheter och resultat".toUpperCase();
  const subTitle = `Projektet har kartlagt sin miljöpåverkan genom att beräkna byggnadens materialanvändning. Med begreppet materialanvändning avses allt material som tillförts byggarbetsplats d.v.s det som byggts in i byggnaden, men även spill.`;

  const introText = `Sverige har som mål att senast 2045 vara klimatneutralt. För att nå detta mål behöver flera industrier förändra sitt klimatarbete, och byggindustrin är en av dem. \n\n Ett första steg för att påverka byggindustrins klimatpåverkan är att börja mäta hur stor påverkan våra byggnader har.\n\n`;

  const headerPart1 = `Rimlighetskontroll ökar tillförlitlighet`;
  const textPart1 = `Vid slutet av analysen har en rimlighetskontroll gjorts av det sammanställda materialet. Rapporterade mängder har jämförts med likartade projekt för att undvika missade byggdelar.`;

  const headerPart2 = `Verktyg för livscykelanalys`;
  const textPart2 = `Kartläggningen har gjorts via ett LCA-verktyg, Plant.se. BIM-modell, kostnadskalkyl eller materialsammanställning har importerats till verktyget, där objekt har tolkats till vikter och volymer av generiska material. Vid rapportering av mer komplext sammansatta byggdelar (ex. elcentraler, armaturer, hiss) har kvalificerade uppskattningar eller kalkyluppgifter använts. Vid användning av kalkyluppgifter har ett påslag för spill tagits med. Vid osäkerhet har det mindre gynnsamma alternativet angivits, för att vara försiktig med att ge en överdrivet positiv bild.`;

  return (
    <ReportContainer>
      <ReportHeader chapter="Tillvägagångssätt" />
      <ReportBodyAlignBottom>
        <Box mb={2}>
          <Box textAlign="left" maxWidth={theme.spacing(70)}>
            <Box mb={2}>
              <ReportTypography variant="h2">{pageTitle}</ReportTypography>
            </Box>
          </Box>
          <Box mb={3}>
            <ReportTypography variant="subtitle2">{subTitle}</ReportTypography>
          </Box>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ReportTypography variant="body1">{introText}</ReportTypography>
                <ReportTypography variant="h6">{headerPart1}</ReportTypography>
                <Box>
                  <ReportTypography variant="body1">
                    {textPart1}
                  </ReportTypography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <ReportTypography variant="h6">{headerPart2}</ReportTypography>
                <ReportTypography variant="body1">{textPart2}</ReportTypography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <ReportStatisticsBox
            gwpScorecard={() => (
              <ReportGWPScorecard
                A1_A5PerBTA={gwpScorecard.A1_A5PerBTA}
                A1_A5={gwpScorecard.A1_A5}
                projectBTA={project?.area ?? 0}
                tmplVars={tmplVars}
              />
            )}
            title=""
            caption={`${Math.round(gwpScorecard.A1_A5PerBTA)} kg CO₂e/${
              tmplVars.BTA.inUnit
            } fördelat på bruttoarean ${project?.area ?? 0}`}
          />
          <ReportStatisticsBox
            chart={() => (
              <ReportPieChart
                data={[
                  { name: "Produktskede", value: gwpScorecard.A1_A3 },
                  { name: "Transportskede", value: gwpScorecard.A4 },
                  { name: "Byggskede", value: gwpScorecard.A5 },
                ]}
              />
            )}
            title="Klimatpåverkan per skede"
            caption="Fördelning per skede, andel av klimatpåverkan"
          />
        </Box>
      </ReportBodyAlignBottom>
      <ReportFooter pageNumber={pageNumber} />
    </ReportContainer>
  );
}
