import moment from "moment";
import { ReportFooter } from "../../../report/components/report-footer";
import { ReportContainer } from "../../../report/components/report-container";
import { ReportTypography } from "../../../report/components/report-typography";
import { useSimplifiedTenantAdaptionProjectQuery } from "../../../../lib/generated/graphql";
import { SingleReportPage } from "./index";
import FirstPageGraphic from "../../../report/components/FirstPageGraphic.svg";
import { Box, Flex, Grid, GridItem, Spinner } from "@chakra-ui/react";

const FirstPage: SingleReportPage = ({ projectId, reportTemplate }) => {
  const { data: projectData, loading: loadingProject } =
    useSimplifiedTenantAdaptionProjectQuery({
      variables: { id: projectId },
    });

  if (loadingProject) {
    return <Spinner />;
  }
  const project = projectData?.simplifiedTenantAdaption?.project!;

  const bgcolor = "#ff9408";
  const color = "#ffae23";
  const title = "Förenklad Klimatberäkning";
  return (
    <ReportContainer style={{ padding: 0, position: "relative" }}>
      <Grid flexGrow={1} gridTemplateRows="1fr auto auto" overflow={"hidden"}>
        <GridItem color="white" bg={bgcolor} position="relative" width={"100%"}>
          <Flex
            position="absolute"
            height={"100vh"}
            width="100vw"
            display="block"
          >
            <FirstPageGraphic color={color} />
          </Flex>
          <Box mx={10} position="absolute" bottom={10} zIndex="1">
            <ReportTypography variant="h1">{title}</ReportTypography>
            <ReportTypography variant="h1">{project.name}</ReportTypography>
            <Flex mt={2}>
              <ReportTypography variant="h4">{`Beräknad klimatpåverkan för projekt ${project.name}`}</ReportTypography>
            </Flex>
          </Box>
        </GridItem>
        <GridItem mt={10} textAlign="left" px={10}>
          {reportTemplate.logoUrl && (
            <Flex mb={1}>
              <img
                src={reportTemplate.logoUrl}
                style={{ maxWidth: 177, maxHeight: 90 }}
              />
            </Flex>
          )}
          <ReportTypography variant="caption">
            www.plant.se, info@plant.se
          </ReportTypography>
          <Flex>
            <ReportTypography variant="caption">
              {moment().format("YYYY-MM-DD")}
            </ReportTypography>
          </Flex>
        </GridItem>
        <GridItem textAlign="left" pb={"32px"} px="70px">
          <ReportFooter skipPaddingTop />
        </GridItem>
      </Grid>
    </ReportContainer>
  );
};

export default FirstPage;
