import { useMemo } from "react";
import { Legend, BarChart, YAxis, XAxis, Bar } from "recharts";

import {
  SimpleGrid,
  Flex,
  Image,
  Box,
  Text,
  Tbody,
  Td,
  Table,
  Th,
  Thead,
  Tr,
  TableContainer,
  Heading,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";

import {
  useProjectQuery,
  ProjectTypeEnum,
  ProjectStatus,
  useProjectPipelinesQuery,
} from "../../../lib/generated/graphql";

import { ReportPageProps } from "../../report-page";

import useDsrQueryHook from "../hooks/useDsrQueryHook";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { chartColorArray } from "../components/chart-colors";

import useScenariosQueries from "../hooks/scenario-queries";
import {
  translateBuildingFrame,
  typeTranslation,
  useStyles,
} from "./report-introduction-page-new";

const query = {
  filter: [{ Include: { eq: "TRUE" } }],
  aggregations: [
    {
      col: "A1_A3PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A4PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A5PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A1_A5PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A1_A5",
      op: "sum",
      reduceAggOp: "sum",
    },
  ],
  group: [{ col: "FunctionType" }],
  sort: [{ col: "A1_A5PerBTA", desc: true }],
  topN: 10,
};
const ResultTable = () => {
  const params = useParams();
  const projectId = params.projectId!;

  const pipelines = useProjectPipelinesQuery({ variables: { projectId } }).data
    ?.project?.pipelines.nodes;

  const sumByScenario = useScenariosQueries(query)?.sumByScenario;

  const getName = (inputId: string) =>
    pipelines?.find((pipeline: any) => pipeline.id === inputId)?.name || null;

  const dataWithName = useMemo(() => {
    const roundedNumbers = Object.entries(sumByScenario).reduce(
      (acc, [key, values]) => {
        const { FunctionType, ...numberEntries } = values;
        const roundedNumberEntries = Object.entries(numberEntries).reduce(
          (acc, [key, value]) => {
            return { ...acc, [key]: Math.round((value as number) || 0) };
          },
          {}
        );
        const idToName = getName(key) as string;
        return { ...acc, [idToName]: roundedNumberEntries };
      },
      {}
    );

    return roundedNumbers;
  }, [sumByScenario]);

  const headerCells = [
    "Scenario",
    "A1-A3/BTA",
    "A4/BTA",
    "A5/BTA",
    "A1-A5/BTA",
    "A1-A5",
  ];

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            {headerCells.map((cell, index) => (
              <Th isNumeric={index !== 0} key={cell + index}>
                {cell}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(dataWithName).map(([key, data]) => {
            return (
              <Tr key={"entry" + key}>
                <Td maxW="80px" overflow="hidden" whiteSpace="normal">
                  {key}
                </Td>
                {Object.values(data as number[]).map((d) => (
                  <Td isNumeric key={d}>
                    {d.toLocaleString("fr")}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const sumSBEFGroup = (arr: any[]) => {
  return arr
    .filter((el) => el.sbefCode)
    .reduce((acc, cur) => {
      // sbef group in belongs to, ex: 13 => 1, 105 => 10
      const sbefGroup = cur?.sbefCode?.slice(0, -1);

      const summed = acc[sbefGroup] || 0;
      return { ...acc, [sbefGroup]: summed + cur.A1_A5PerBTA };
    }, {});
};

const BarCharts = () => {
  const classes = useStyles();
  const barSize = 50;
  const params = useParams();
  const projectId = params.projectId!;

  const dsrs = useDsrQueryHook({
    filter: [{ Include: { eq: "TRUE" } }],
    group: [{ col: "sbefCode" }],
    aggregations: [{ col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" }],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 115,
  });

  const declaredBuildingParts =
    useProjectQuery({ variables: { projectId } })?.data?.project
      ?.declaredBuildingParts || [];

  const summed = sumSBEFGroup(dsrs.data) || {};
  const groupedOnSBEFGenre = useMemo(() => {
    return Object.keys(summed).reduce((acc, cur) => {
      const roundedValue = Math.round(summed[cur] || 0);

      let name =
        declaredBuildingParts.find((part) => part.code === cur)?.label || "";

      if (cur === "10") name = "Energi på byggarbetsplats";
      if (cur === "11") name = "Uppräkning för täckningsgrad";
      return { ...acc, [`${cur} - ${name}`]: roundedValue };
    }, {});
  }, [summed, declaredBuildingParts]);

  const CustomBarLabel = ({ x, y, value }: any) => {
    return (
      <text x={x + barSize / 2} y={y} fill="#777" dy={-6} textAnchor="middle">
        {value}
      </text>
    );
  };

  return (
    <Box overflow="visible">
      <BarChart
        width={650}
        height={200}
        data={[groupedOnSBEFGenre]}
        margin={{ top: 15 }}
        className={classes.barChart}
      >
        <XAxis dataKey="name" />
        <YAxis width={20} />

        <Legend
          verticalAlign="bottom"
          content={(a) => {
            return (
              <SimpleGrid columns={3} gap={2}>
                {[...(a?.payload || [])].map((square: any) => {
                  return (
                    <Flex key={square.dataKey} alignItems="start" gap={2}>
                      <Box boxSize="1rem" flexShrink={0} bg={square.color} />
                      <Flex>
                        <Text>{square.dataKey}</Text>
                      </Flex>
                    </Flex>
                  );
                })}
              </SimpleGrid>
            );
          }}
        />

        {Object.keys(groupedOnSBEFGenre).map((key, i) => (
          <Bar
            key={key}
            isAnimationActive={false}
            barSize={barSize}
            dataKey={key}
            fill={chartColorArray[i]}
            label={CustomBarLabel}
          />
        ))}
      </BarChart>
    </Box>
  );
};

export default function ReportAbstractSBEFPage(props: ReportPageProps) {
  const project = useProjectQuery({ variables: { projectId: props.projectId } })
    ?.data?.project;

  const firstImageURL = project?.images?.[0]?.data?.url;
  const firstImageDescription = project?.images?.[0]?.description;

  const dsrs = useDsrQueryHook(query);

  const descriptionLowerLimit = 600;
  const descriptionMaxLimit = 1000;
  const reportSummary = project?.reportSummary;
  return (
    <ReportContainer>
      <ReportHeader
        chapter="Resultat LCA-projekt"
        reportType={props.reportType}
      />

      <Box my={4}></Box>
      <Box h="100%" maxH="100%">
        <Heading fontWeight="bold" as="h1" size="3xl" gridColumn={"1 /span 2"}>
          {project?.name}
        </Heading>
        <SimpleGrid columns={2} gap={8}>
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="stretch"
            flexDir="column"
          >
            <Text fontWeight="bold" fontStyle="italic">
              {project?.reportTitle}
            </Text>

            <Text mb={4} fontStyle="italic">
              {project?.reportSubTitle}
            </Text>

            <Flex justifyContent="space-between">
              <Text>{typeTranslation(project?.projectType)}</Text>
              <Text>{`${Math.round(dsrs.sum.A1_A5PerBTA || 0)} CO₂e/BTA`}</Text>
            </Flex>

            <Flex justifyContent="space-between">
              <Text>{project?.constructionType}</Text>
              <Text>{`${project?.stories} våningar`}</Text>
            </Flex>

            {(reportSummary || "")?.length < descriptionLowerLimit && (
              <Text my={4} whiteSpace="pre-wrap">{`${reportSummary}`}</Text>
            )}

            <Flex justifyContent="space-between">
              <Text>Stomme</Text>
              <Text whiteSpace="nowrap">
                {translateBuildingFrame(project?.buildingFrame || "")}
              </Text>
            </Flex>
            <Text>{`Skriven av: ${project?.reportAuthor || "Okänd"}`}</Text>
          </Flex>
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
            px={8}
          >
            {firstImageURL && (
              <>
                <Image
                  alt="introduction image"
                  borderRadius="md"
                  objectFit="cover"
                  overflow="hidden"
                  src={firstImageURL}
                  width="250px"
                />
                <Text fontStyle="italic" mt={4}>
                  {firstImageDescription}
                </Text>
              </>
            )}
          </Flex>

          {reportSummary && reportSummary?.length > descriptionLowerLimit && (
            <Text whiteSpace="pre-wrap" bg="white" gridColumn={"1 /span 2"}>
              {`${
                reportSummary?.length > descriptionMaxLimit
                  ? reportSummary?.slice(0, descriptionMaxLimit) +
                    " ... (rapportsummering fortsätter, för lång för att visa)"
                  : reportSummary
              }`}
            </Text>
          )}
          <Box bg="white" gridColumn={"1 /span 2"} overflow="visible">
            <BarCharts />
          </Box>
          <Box bg="white" gridColumn={"1 /span 2"}>
            <ResultTable />
          </Box>
        </SimpleGrid>
      </Box>
      <ReportFooter pageNumber={props.pageNumber} />
    </ReportContainer>
  );
}
