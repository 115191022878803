import Skeleton from "@material-ui/lab/Skeleton";
import { Box } from "@material-ui/core";

function SkeletonHeaderCell() {
  return (
    <Box mr={1} flexGrow={1}>
      <Skeleton height={30} />
    </Box>
  );
}

function SkeletonHeaderRow(props: { numColumns?: number }) {
  return (
    <Box display="flex" alignItems="center">
      {[...Array(props.numColumns || 10)].map((a, i) => (
        <SkeletonHeaderCell key={i} />
      ))}
    </Box>
  );
}

function SkeletonRow() {
  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <Skeleton height={30} width={30} />
      </Box>
      <Box width="100%">
        <Skeleton width="100%" height={30} />
      </Box>
    </Box>
  );
}

function SkeletonSearch() {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%">
        <Skeleton width="100%" height={30} />
      </Box>
      <Box m={1}>
        <Skeleton variant="circle" width={40} height={40} />
      </Box>
    </Box>
  );
}

interface ITableSkeletonProps {
  includeHeader?: boolean;
  includeSearch?: boolean;
  numColumns?: number;
  numRows?: number;
  showRect?: boolean;
}

export function SkeletonTable(props: ITableSkeletonProps) {
  return (
    <Box>
      {props.includeSearch && <SkeletonSearch />}
      {props.includeHeader && (
        <SkeletonHeaderRow numColumns={props.numColumns || 8} />
      )}
      {[...Array(props.numRows)].map((a, i) => (
        <SkeletonRow key={i} />
      ))}
      {props.showRect && <Skeleton variant="rect" height={200} />}
    </Box>
  );
}

export function SkeletonTableMini(props: ITableSkeletonProps) {
  return (
    <Box>
      {props.includeSearch && <SkeletonSearch />}
      {props.includeHeader && <SkeletonHeaderRow numColumns={4} />}
      {[...Array(8)].map((a, i) => (
        <SkeletonRow key={i} />
      ))}
    </Box>
  );
}
