import {
  Box,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ReportTypography } from "./report-typography";
import { TemplatingVariables } from "../templating";

const useStyles = makeStyles((theme: Theme) => ({
  tableCellLeft: {
    borderBottom: "none",
    verticalAlign: "baseline",
  },

  tableCellMiddle: {
    borderBottom: "none",
    verticalAlign: "baseline",
    color: theme.palette.primary.main,
  },

  tableCellRight: {
    borderBottom: "none",
    verticalAlign: "baseline",
  },
}));

function ScorecardRow(props: any) {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell padding="none" className={classes.tableCellLeft}>
        <Box mb={1}>
          <ReportTypography variant={"h5"}>{props.left || ""}</ReportTypography>
        </Box>
      </TableCell>
      <TableCell padding="none" className={classes.tableCellMiddle}>
        <ReportTypography variant={"h2"}>{props.middle || ""}</ReportTypography>
      </TableCell>
      <TableCell padding="none" className={classes.tableCellRight}>
        <ReportTypography variant={"h5"}>{props.right || ""}</ReportTypography>
      </TableCell>
    </TableRow>
  );
}

interface IReportGWPScorecardProps {
  children?: any;
  A1_A5PerBTA: number;
  A1_A5: number;
  projectBTA: number;
  tmplVars: TemplatingVariables;
}

export function ReportGWPScorecard(props: IReportGWPScorecardProps) {
  const { tmplVars } = props;
  return (
    <Table>
      <TableBody>
        <ScorecardRow
          left={tmplVars.GWP.simpleName}
          middle={Math.round(props.A1_A5PerBTA)}
          right={`KG CO₂e/${tmplVars.BTA.inUnit}`}
        />
        <ScorecardRow
          left={tmplVars.BTA.simpleName}
          middle={Math.round(props.projectBTA)}
          right={tmplVars.BTA.unit}
        />
        <ScorecardRow
          left={tmplVars.GWP.simpleName}
          middle={Math.round(props.A1_A5 / 1000)}
          right={"Ton CO₂e"}
        />
      </TableBody>
    </Table>
  );
}
