import React, { useState } from "react";
import {
  useSimplifiedTenantAdaptionCreateScenarioMutation,
  useSimplifiedTenantAdaptionCopyScenarioMutation,
  namedOperations,
} from "../../lib/generated/graphql";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@material-ui/core";

const CreateScenarioDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onCreate: (id: string) => void;
  projectId: string;
  scenarioIdToCopy?: string;
}> = ({ projectId, open, onClose, onCreate, scenarioIdToCopy }) => {
  const [createScenarioMutation, { loading: creating }] =
    useSimplifiedTenantAdaptionCreateScenarioMutation({
      awaitRefetchQueries: true,
      onCompleted: (d) => {
        setScenarioDetails({});
        onCreate(d.simplifiedTenantAdaption.project.scenarios.create.id);
      },
    });
  const [copyScenarioMutation, { loading: copying }] =
    useSimplifiedTenantAdaptionCopyScenarioMutation({
      awaitRefetchQueries: true,
      onCompleted: (d) => {
        setScenarioDetails({});
        onCreate(d.simplifiedTenantAdaption.project.scenario.copy.id);
      },
    });
  const loading = creating || copying;
  const [scenarioDetails, setScenarioDetails] = useState<{ name?: string }>({});

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="qto-dialog"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Skapa scenario</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vänligen ange ett namn på ditt scenario
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Scenario namn"
          fullWidth
          value={scenarioDetails.name ?? ""}
          disabled={loading}
          onChange={(e: any) =>
            setScenarioDetails({ ...scenarioDetails, name: e.target.value })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => onClose()} color="secondary">
          Avbryt
        </Button>
        <Button
          disabled={loading || scenarioDetails.name == null}
          onClick={async () => {
            if (scenarioIdToCopy != null) {
              await copyScenarioMutation({
                variables: {
                  projectId,
                  scenarioId: scenarioIdToCopy,
                  input: {
                    name: scenarioDetails.name!,
                  },
                },
                refetchQueries: [
                  namedOperations.Query.SimplifiedTenantAdaptionProject,
                ],
              });
            } else {
              await createScenarioMutation({
                variables: {
                  projectId,
                  input: {
                    name: scenarioDetails.name!,
                  },
                },
                refetchQueries: [
                  namedOperations.Query.SimplifiedTenantAdaptionProject,
                ],
              });
            }
          }}
          color="primary"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateScenarioDialog;
