import { Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportHeader } from "../components/report-header";
import { ReportTypography } from "../components/report-typography";
import {
  DeclaredBuildingPart,
  useProjectQuery,
} from "../../../lib/generated/graphql";
import { ReportContainerHorizontal } from "../components/report-container-horizontal";
import { ReportPageProps } from "../../report-page";
import { useTheme } from "@material-ui/styles";
import { DefaultTheme } from "../../../providers/styling";
import useDsrQueryHook from "../hooks/useDsrQueryHook";
import { useMemo } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

const cellStyling = {
  border: "1px solid black",
  verticalAlign: "top",
  padding: "2px 4px 4px 2px",
  fontSize: 10,
};

const lineBreakOnSlash = (str: string) => str.split("/").join("\n");

const getCellStyling = (cell: any, theme: any) => {
  return {
    ...cellStyling,
    height: 48,
    fontSize: 8,
  };
};

const getNotDeclaredColor = (cell: any, theme: any) =>
  cell.declarationStatus !== "DECLARED"
    ? theme.palette.grey[300]
    : "transparent";

const getDeclarationStyle = (cell: any) => {
  return {
    ...(cell.declarationStatus === "DECLARED"
      ? { fontWeight: "bold", opacity: 0.7 }
      : { opacity: 0.7 }),
  };
};

const normalize = (num: number, min: number, max: number) => {
  const delta = max - min;
  return (num - min) / delta;
};

const getRedToGreenColor = (normalizedNum: number) =>
  `hsl(${(1 - normalizedNum) * 120},90%,80%)`;

const getNum = (sbefCode: string, data: any[]) => {
  const sbefEntry = data.find(
    (entry) => entry.sbefCode === sbefCode.toString()
  );
  if (sbefEntry) {
    return Math.round(sbefEntry.A1_A5PerBTA);
  }
};

const SBEFTable = () => {
  const params = useParams();
  const projectId = params.projectId!;
  const theme = useTheme<DefaultTheme>();

  const declaredBuildingParts =
    useProjectQuery({ variables: { projectId } })?.data?.project
      ?.declaredBuildingParts || [];

  const dsrQuery = useDsrQueryHook({
    filter: [],
    group: [{ col: "sbefCode" }],
    aggregations: [{ col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" }],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 45,
  });

  const data = dsrQuery?.data || [];

  const maxA1A5 = useMemo(() => {
    return Math.max(...data.map((d) => d.sbefCode && d.A1_A5PerBTA));
  }, [data]);

  const rowHundred = useMemo(
    () => declaredBuildingParts.find((part: any) => part.code === "10"),
    [declaredBuildingParts]
  );

  const sideHeaders = useMemo(() => {
    const headers = declaredBuildingParts
      .filter((p: any) => p.code.length === 1)
      .sort(
        (a: any, b: any) => Number.parseInt(a.code) - Number.parseInt(b.code)
      );
    return [...headers, { ...rowHundred, label: "" }];
  }, [declaredBuildingParts]);

  const cells = useMemo(() => {
    return declaredBuildingParts
      .filter((p: any) => p.code.length !== 1)
      .sort(
        (a: any, b: any) => Number.parseInt(a.code) - Number.parseInt(b.code)
      );
  }, []);

  const tenArray = Array.from(Array(10).keys());

  return (
    <Table size="small">
      <TableBody>
        {sideHeaders.map((sideHeader: any, i: number) => {
          const cellsOnRow = cells.filter((c: any) => {
            const indexStr = i.toString();
            const startsWith = c.code.startsWith(indexStr);
            const isIndexPlusOne = c.code.length === indexStr.length + 1;

            if (c.code === "110" && isIndexPlusOne) return true;
            return startsWith && isIndexPlusOne;
          });

          return (
            <TableRow key={sideHeader.id}>
              <TableCell padding="none" style={cellStyling}>
                {sideHeader.code}
              </TableCell>
              <TableCell padding="none" style={{ ...cellStyling, width: 120 }}>
                <span style={{ whiteSpace: "pre-line" }}>
                  {lineBreakOnSlash(sideHeader.label)}
                </span>
              </TableCell>

              {i === 0
                ? tenArray.map((j) => (
                    <TableCell
                      key={`0${j}`}
                      style={cellStyling}
                    >{`0${j}`}</TableCell>
                  ))
                : cellsOnRow.map((cell: any) => {
                    const sbefImpact = getNum(cell.code, data);
                    const background = sbefImpact
                      ? getRedToGreenColor(normalize(sbefImpact, 0, maxA1A5))
                      : getNotDeclaredColor(cell, theme);

                    return (
                      <>
                        {cell.code === "110" && (
                          <TableCell
                            style={{ border: 0 }}
                            colSpan={3}
                          ></TableCell>
                        )}
                        <TableCell
                          key={cell.code}
                          colSpan={cell.code === "110" ? 2 : 1}
                          style={{
                            ...getCellStyling(cell, theme),
                            background,
                            opacity:
                              cell.declarationStatus === "DECLARED"
                                ? "1"
                                : ".4",
                          }}
                        >
                          <Box style={getDeclarationStyle(cell)}>
                            {cell.code}
                          </Box>

                          <Box>
                            <span style={{ whiteSpace: "pre-line" }}>
                              {lineBreakOnSlash(cell.label)}
                            </span>
                          </Box>

                          {sbefImpact && (
                            <Box
                              fontWeight="bold"
                              style={getDeclarationStyle(cell)}
                            >
                              <span>{`${sbefImpact} kg CO`}</span>
                              <sub>2</sub>
                              <span>/BTA</span>
                            </Box>
                          )}
                        </TableCell>
                      </>
                    );
                  })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default function ReportSBEFPage(props: ReportPageProps) {
  const theme = useTheme<DefaultTheme>();
  return (
    <ReportContainerHorizontal>
      <ReportHeader chapter="Redovisade byggdelar enligt SBEF" />

      <Box width={"100%"}>
        <SBEFTable />
      </Box>
      <Flex gap={4} ml={2} mt={2} rowGap={1} wrap="wrap">
        <ReportTypography variant="body1">
          Byggdelar inkluderade i livscykelanalysen. Enligt Svenska
          Byggnadsentreprenörföreningen (SBEF).
        </ReportTypography>
        <Box
          opacity="0.4"
          border="1px solid"
          h="1.5rem"
          w="3rem"
          bg={theme.palette.grey[300]}
        />
        <ReportTypography variant="body1">
          Odeklarerade byggdelar
        </ReportTypography>
        <ReportTypography variant="body1">
          Spill redovisas inte separat på kod 101 utan är inkluderat i den
          redovisade klimatpåverkan för de olika byggdelarna.
        </ReportTypography>
      </Flex>

      <ReportFooter pageNumber={props.pageNumber} skipPaddingTop={true} />
    </ReportContainerHorizontal>
  );
}
