import React, { useEffect, useState } from "react";
import {
  useSimplifiedTenantAdaptionPatchScenarioMutation,
  useSimplifiedTenantAdaptionDeleteScenarioMutation,
  SimplifiedTenantAdaptionScenario,
  namedOperations,
} from "../../lib/generated/graphql";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@material-ui/core";

const CreateScenarioDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onDelete: (id: string) => void;
  projectId: string;
  scenario: SimplifiedTenantAdaptionScenario;
}> = ({ projectId, open, onClose, onDelete, scenario }) => {
  const [patchScenarioMutation, { loading: patching }] =
    useSimplifiedTenantAdaptionPatchScenarioMutation({
      onCompleted: () => onClose(),
    });
  const [deleteScenarioMutation, { loading: deleting }] =
    useSimplifiedTenantAdaptionDeleteScenarioMutation({
      awaitRefetchQueries: true,
      onCompleted: () => onDelete(scenario.id),
    });
  const loading = deleting || patching;
  const [scenarioDetails, setScenarioDetails] = useState<{ name: string }>({
    ...scenario,
  });
  useEffect(() => setScenarioDetails({ ...scenario }), [scenario]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="qto-dialog"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Redigera Scernario</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vänligen ange ett namn på ditt scenario
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Scenario namn"
          fullWidth
          value={scenarioDetails.name ?? ""}
          disabled={loading}
          onChange={(e: any) =>
            setScenarioDetails({ ...scenarioDetails, name: e.target.value })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            if (
              window.confirm(`Är du säker på att du vill ta bort scenario?`)
            ) {
              await deleteScenarioMutation({
                variables: {
                  projectId,
                  scenarioId: scenario.id,
                },
                refetchQueries: [
                  namedOperations.Query.SimplifiedTenantAdaptionProject,
                ],
              });
            }
          }}
          color="secondary"
          style={{ fontWeight: 600 }}
          variant="contained"
        >
          Ta bort
        </Button>
        <Button disabled={loading} onClick={() => onClose()} color="secondary">
          Avbryt
        </Button>
        <Button
          disabled={loading || scenarioDetails.name === ""}
          onClick={async () => {
            await patchScenarioMutation({
              variables: {
                projectId,
                scenarioId: scenario.id,
                patch: {
                  name: scenarioDetails.name!,
                },
              },
            });
          }}
          color="primary"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateScenarioDialog;
