import { colors } from "../../../lib/styles/colors";

export const chartColorArray = [
  colors.pieOrange,
  colors.pieCyan,
  colors.pieGreen,
  colors.pieTurquoise,
  colors.piePink,
  colors.pieYellow,
  colors.plant.green,
  colors.plant.lightGreen,
  colors.plant.darkGreen,
  colors.plant.pinkSalmon,
  colors.plant.sunnyMustard,
  colors.orange.light,
  colors.orange.dark,
  colors.green.light,
  colors.green.dark,
  colors.purple.light,
  colors.purple.dark,
  colors.red.light,
  colors.red.dark,
  colors.blue.light,
  colors.blue.dark,
  colors.brown.light,
  colors.brown.dark,
  colors.slackGreen.dark,
  colors.slackGreen.dark,
  colors.pink.light,
  colors.pink.dark,
  colors.googleBlue,
];
