import { Box } from "@material-ui/core";

export default function LayerInsulation(props: any) {
  return (
    <svg viewBox="0 -0.348 87.702 124.896" xmlns="http://www.w3.org/2000/svg">
      <g id="Hatches" transform="matrix(0, 1, -1, 0, 93.791748, -5)">
        <path
          stroke="black"
          fill="none"
          d="M 129.548 6.097 C 113.771 5.759 103.544 22.627 111.14 36.46 C 111.281 36.716 111.427 36.97 111.579 37.22 L 126.139 62.66 C 134.322 76.154 124.829 93.445 109.052 93.785 C 108.904 93.788 108.757 93.79 108.609 93.79 C 97.289 93.79 88.609 84.61 88.609 73.29 C 88.615 69.539 89.652 65.861 91.609 62.66 L 104.459 37.15 C 106.416 33.949 107.453 30.271 107.459 26.52 C 107.459 15.2 98.859 6.09 87.539 6.09 C 76.219 6.09 68.389 15.14 68.389 26.46 C 68.39 30.212 69.428 33.891 71.389 37.09 L 84.509 62.66 C 92.692 76.154 83.199 93.445 67.422 93.785 C 67.274 93.788 67.127 93.79 66.979 93.79 C 54.559 93.79 46.479 84.61 46.479 73.29 C 46.48 69.538 47.518 65.859 49.479 62.66 L 63.379 37.22 C 71.562 23.726 62.069 6.435 46.292 6.095 C 46.144 6.092 45.997 6.09 45.849 6.09 C 34.529 6.09 26.349 15.26 26.349 26.59 C 26.35 30.342 27.388 34.021 29.349 37.22 L 42.469 62.66 C 44.426 65.861 45.463 69.539 45.469 73.29 C 45.659 84.529 36.62 93.753 25.379 93.79 C 14.253 93.938 5.203 84.866 5.379 73.74 C 5.379 69.985 6.417 66.302 8.379 63.1 L 21.739 37.22 C 29.922 23.726 20.429 6.435 4.652 6.095"
        />
      </g>
    </svg>
  );
}
