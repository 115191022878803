import { useSimplifiedTenantAdaptionProjectQuery } from "../../../../lib/generated/graphql";
import CommonRawMaterialPages from "../../../report/pages/common/common-raw-material-pages";
import { SingleReportPage } from "./index";
import { CircularProgress } from "@material-ui/core";
import _ from "lodash";

const RawMaterialPages: SingleReportPage = ({ projectId, pageNumber }) => {
  const { data: projectData, loading: loadingProject } =
    useSimplifiedTenantAdaptionProjectQuery({
      variables: { id: projectId },
    });
  if (loadingProject) {
    return <CircularProgress />;
  }
  const headers =
    projectData?.simplifiedTenantAdaption?.project?.template?.data?.headers!;

  const rawMaterials = headers.flatMap(({ rows }) =>
    rows.flatMap(
      ({ buildingElement }) =>
        buildingElement.products?.flatMap((p) =>
          p.rawMaterial != null ? [p.rawMaterial] : []
        ) ?? []
    )
  );

  const uniqueRawMaterials = _.uniqBy(rawMaterials, (r) => r.id);

  return (
    <CommonRawMaterialPages
      rawMaterials={uniqueRawMaterials}
      pageNumber={pageNumber}
    />
  );
};

export default RawMaterialPages;
