//@ts-nocheck

import xpath from "xpath";
import * as mammoth from "mammoth/mammoth.browser";
import { saveAs } from "file-saver";

import { useDropzone } from "react-dropzone";
import blue from "@material-ui/core/colors/blue";
import { Paper } from "@material-ui/core";

const htmlNodesWithData = ["strong", "h2"];

const codesWithParagraphs = [
  "BESKrub1",
  "BESKrub2",
  "BESKrub3gemen",
  "BESKrub3versal",
  "BESKrub4",
  "BESKrub5",
  "BESKrub6",
  "BESKrub7",
  "BESKrub8",

  "Strong AMA",
  "Heading 1 AMA",
  "Heading 2 AMA",
  "Heading 3 AMA",
  "Heading 4 AMA",
  "Heading 5 AMA",
  "Heading 6 AMA",
  "Heading 7 AMA",
  "Heading 8 AMA",
  "Heading 9 AMA",
].map((code) => `p[style-name='${code}'] => h2:fresh`);

const paragrahpStyles = [
  "Emphasis AMA",
  "Coverpage AMA",

  "toc 1",
  "toc 2",

  "BESKinnehållsrub",
  "BESKbrödtext",
  "BESKrådtank",

  "REDAbesktext",
  "REDAintank",

  "REDAokod1",
  "REDAokod2",
  "REDAokod3",
  "REDArub4",

  "BESKokod1",
  "BESKokod2",
  "BESKokod3",

  "Indraget stycke1",
  "Indraget stycke2",

  "Emphasis AMA",
  "Coverpage AMA",
  "Placeholder Text",
  "toc 1",
  "Text AMA",
  "Product AMA",
  "Technical Data AMA",
  "Underline AMA",
  "Placeholder Text",
].map((code) => `p[style-name='${code}'] => p:fresh`);

const options = {
  styleMap: [...codesWithParagraphs, ...paragrahpStyles],
};

async function convertFile(data: any) {
  const parser = new DOMParser();
  const file = data[0];

  const fileReader = new window.FileReader();

  fileReader.readAsArrayBuffer(file);
  fileReader.onload = (event) => {
    mammoth
      .convertToHtml({ arrayBuffer: event.target.result }, options)
      .then(function (result) {
        const text = result.value;
        const html = `<html lang="sv">
	<head>
		<meta charset="UTF-8"/>
		<style>
			p,
			ul {
				margin-left: 40px;
			}
			h2,
			p,
			ul,
			body {
				font-family: "Helvetica", "Arial", sans-serif;
			}
			td {
				border: 1px solid grey;
			}
		</style>
	</head>
<body>${text}</body></html>`;
        const xmlDoc = parser.parseFromString(html, "text/xml");
        const nodes = htmlNodesWithData
          .map((nodeName) => xpath.select(`//${nodeName}`, xmlDoc))
          .flat();
        const keyDictionary = nodes.reduce((acc, node, i) => {
          const AMAMatch = node.textContent.match(
            /^(([0-9]{1,2})\.?([A-Z]{1,4})?(\/[1-9])?)(\t| )/
          );
          const BSABMatch = node.textContent.match(
            /^(([A-Z]{1,3})\.?([0-9]{1,5})?(\/[1-9])?)(\t| )/
          );
          if (AMAMatch) {
            const nodeId = AMAMatch[1];
            if (acc[nodeId]) {
              alert(`coliding keys${nodeId}`);
            }
            acc[nodeId] = true;
            node.setAttribute("id", nodeId);
          }
          if (BSABMatch) {
            const nodeId = BSABMatch[1];
            if (acc[nodeId]) {
              alert(`coliding keys${nodeId}`);
            }
            acc[nodeId] = true;
            node.setAttribute("id", nodeId);
          }
          return acc;
        }, {});
        const newText = new XMLSerializer().serializeToString(xmlDoc);
        const blob = new Blob([newText], { type: "text/plain;charset=utf-8" });
        saveAs(blob, "Document.html");
      })
      .done();
  };
}

export default function AMAParseWidget() {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: convertFile,
  });
  return (
    <div>
      <Paper
        variant="outlined"
        {...getRootProps({ refKey: "innerRef" })}
        style={{
          ...(isDragActive
            ? { borderColor: blue[900], background: blue[100] }
            : {}),
          minHeight: "100px",
          padding: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          borderStyle: "dashed",
          borderWidth: 3,
        }}
      >
        <input {...getInputProps()} />
        Släpp en fil här
      </Paper>
    </div>
  );
}
