import { Box } from "@material-ui/core";
import ReportPage from "./pages";
import FilesTable from "../filesTable";
import IntroductionPage from "./pages/introduction-page";

const EmbeddedReportComponent: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  return (
    <Box ml={3}>
      <FilesTable projectId={projectId} />
      <ReportPage isPrint={false} pages={[IntroductionPage]} />
    </Box>
  );
};

export default EmbeddedReportComponent;
