import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import { useGlobalDispatch } from "../../providers/GlobalStateProvider";
import { ActionType } from "../../providers/GlobalStateProvider/types";

function Alert<T>(props: T) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PlantErrorSnackbar(props: {
  open: boolean;
  index: number;
  message: string;
  details?: string;
}) {
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useGlobalDispatch();

  const handleClose = () => {
    dispatch({
      type: ActionType.CLEAR_GRAPH_QL_ERROR,
      payload: { index: props.index },
    });
  };

  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={handleClose}
    >
      <Alert severity="error" onClose={handleClose}>
        <AlertTitle>{props.message}</AlertTitle>
        <Button
          color="inherit"
          size="small"
          onClick={() => setShowDetails(!showDetails)}
        >
          {"Details"}
        </Button>
        {props.details && showDetails && (
          <Box overflow="auto" maxHeight={450}>
            <CopyToClipboard text={props.details || ""}>
              <Button variant="contained">Copy</Button>
            </CopyToClipboard>
            <pre id="json" className="bg-white">
              {props.details}
            </pre>
          </Box>
        )}
      </Alert>
    </Snackbar>
  );
}
