import { Navigate, useParams } from "react-router-dom";

const mapping: { [k: string]: string } = {
  "c0290ee5-c7a0-44c2-b68f-45c78bde4396":
    "7d312ea0-dd82-4056-822d-8b2dcc001843",
  "d75d0a9e-c7cd-4ec6-8cba-50e1f9ed80cc":
    "8f63f776-e2d7-46a3-8bd3-04882b3a7598",
  "1a8b8b75-f8c0-4512-8403-fddac6870c8d":
    "1d345b9c-9585-40a1-982e-684c49d01e5d",
  "c32ddcea-f7ea-46d5-b2b9-4234214bcfa9":
    "8f16eb93-dc27-46d3-91b8-9bd1431bb7e9",
  "c22fb0d0-9bd3-4f90-a9e1-3fca994edf22":
    "05819428-4ec0-4c27-8bf8-2c66af5ad4ce",
  "0fe4bc0a-0e69-44cb-a3d5-f248d84d6632":
    "72422775-deb1-476a-bbdf-d4b7cfeb1427",
  "613f85b1-86d0-4913-8328-2ab78801e065":
    "4a40b313-b979-4542-9f98-1f1692e082a1",
  "ca83aa75-8207-4308-861f-ffe3a29601ac":
    "5bae94cb-b86f-43a1-971c-9be024cba486",
  "223b1365-57e6-4b91-a887-8b6bbccfa7fa":
    "93b81beb-f667-4311-9811-8efb9656a0c5",
  "20b6b7dc-9aed-4faa-8519-dd14215e242e":
    "26baf64f-43b3-48ed-ac31-d5e9cc24b3e6",
  "5b0e32c5-d627-49ba-b9f5-ccfd0c0c8536":
    "e008b68b-2f2a-4182-8c91-755e1668c488",
  "aa8f669e-7786-4773-8cdf-1eab05bcbe16":
    "42225559-66bd-4194-bdc0-51e8a7b7f6fc",
  "dc879650-45cb-4729-b956-26ddfa70d031":
    "abd1ebcb-000b-49d6-b529-5a742593f9d0",
  "05a23831-bc30-4187-9a95-6a449db1be57":
    "f7511d9d-f678-48bb-8a4e-d6eee7cf4946",
  "1c34fd21-989a-46d1-9a1b-519c2e249e3f":
    "cde23aba-81bd-4208-94d5-229b1c1d2f98",
  "a338e34f-832b-4784-bf78-b747d9164132":
    "5f777b9d-6aa1-4b33-8782-d705cc61f863",
  "d25f0684-bc34-45e6-9713-bf135b039c69":
    "061471dc-8095-42bc-a9b2-46df5da46e15",
  "0f520130-4e8c-413d-b0bc-483adee9a880":
    "21dce401-3e97-438f-93ab-e27c0db73ca4",
  "b6ee4f06-39e3-42b6-813e-82ebc7326b45":
    "f3285d4f-480e-40fc-83fd-1fc5cb173045",
  "fe3e057e-c99f-4c1f-a8f4-d4a161181c0a":
    "b5c4e296-3abe-4c8e-b9a6-f03aaf2a12ab",
  "4f50a422-a2a1-4dfc-8218-50e042bec128":
    "e671d8e7-7e67-4a5e-b565-f0c17f5c64cf",
  "2bc984d6-8685-48d8-9be6-43a1f16d9ea0":
    "7203a58e-524a-4eb0-b448-bf0ca6d3936b",
  "7e9d5c3b-dbd4-444f-b19f-111c75343732":
    "194191bb-bbe2-431e-804d-b0bc183cc1f9",
  "a774a6d2-25d9-490f-ab1d-9d1c5c814d1c":
    "0932e0a2-5b3c-4177-a327-398c726d0dda",
  "8b2f0862-c3bb-4576-85af-24179adf81cf":
    "da597d37-f295-4800-aa2f-d52f192219d0",
  "58f59703-894e-4254-831f-4fdb008d1ddf":
    "7ce61d67-f5e5-407c-9c79-c2caebbf8f20",
  "f7bbc58a-0a75-411f-b961-e3488c408db2":
    "906e0bb8-7fbb-4cca-89c8-90fd2a300b44",
  "ee1e4db1-4713-4459-8fa6-448153eaf9f9":
    "a7083214-6692-42a5-a179-9b254aff555d",
  "ad1a6608-06b9-4cb9-a713-d34539dcaf13":
    "561f9a46-c3f8-45bf-ba0f-daa108ddc706",
  "3de73c6a-047b-4cdd-af50-9558f9e8e405":
    "e52472a7-7518-4681-a56c-26df048f5dbe",
  "61c128f6-fa12-43a5-a59f-dbc8e77cda89":
    "06ac439d-6f74-485a-8abb-d0ec984cdf8f",
};

const StaRedirect = () => {
  const { projectId } = useParams();
  const newProjId = mapping[projectId ?? ""];
  if (newProjId != null) {
    return <Navigate to={`/sta/projects/${newProjId}`} />;
  }
  return (
    <div>
      Vi kunde inte hitta ditt projekt, hör av dig på info@plant.se så löser vi
      det
    </div>
  );
};

export default StaRedirect;
