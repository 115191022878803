import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportTypography } from "../components/report-typography";
import { useProjectQuery } from "../../../lib/generated/graphql";
import { ReportPageProps } from "../../report-page";

export default function ReportAbstractPage(props: ReportPageProps) {
  const project = useProjectQuery({ variables: { projectId: props.projectId } })
    ?.data?.project || {
    reportSummary: "",
  };

  return (
    <ReportContainer>
      <ReportHeader chapter="Sammanfattning" reportType={props.reportType} />
      <Box
        height={940}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box mb={4} textAlign="left">
          <ReportTypography variant="h2" align="center">
            Sammanfattning
          </ReportTypography>
        </Box>
        <Box maxWidth={550}>
          <ReportTypography variant="body1">
            {project.reportSummary}
          </ReportTypography>
        </Box>
      </Box>
      <ReportFooter pageNumber={props.pageNumber} />
    </ReportContainer>
  );
}
