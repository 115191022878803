export const colors = {
  orange: {
    light: "#ffd687",
    dark: "#ffcc6c",
  },
  green: {
    light: "#b3dec3",
    dark: "#a4d8b8",
  },
  purple: {
    light: "#b7b4e7",
    dark: "#9d99de",
  },
  red: {
    light: "#ab4645",
    dark: "#d42d2b",
  },
  blue: {
    light: "#4b7198",
    dark: "#1565C0",
  },
  brown: {
    light: "#9d776d",
    dark: "#4E342E",
  },
  slackGreen: {
    light: "#114e35",
    dark: "#1bb978",
  },
  pink: {
    light: "#bf7e9b",
    dark: "#964b6e",
  },
  plantDark: {
    dark: "#282526",
  },
  plant: {
    lightGreen: "#4fc397",
    green: "#20a271",
    // green: '#24b47e',
    darkGreen: "#197d58",
    spaceGrey: "#272324",
    sunnyMustard: "#F3F4C1",
    pinkSalmon: "#FFB2A3",
  },
  background: "#ffffff",
  googleBlue: "#1a73e8",

  pieOrange: "#f4974b",
  pieCyan: "#048ea7",
  pieGreen: "#9ebfad",
  piePink: "#f39fb2",
  pieTurquoise: "#46b7e7",
  pieYellow: "#feca3c",
};

export const colorsArray = [
  colors.blue.dark,
  colors.purple.dark,
  colors.red.dark,
  colors.orange.dark,
  colors.purple.light,
  colors.orange.light,
  colors.green.light,
  colors.green.dark,
  colors.brown.dark,
  colors.blue.dark,
  colors.purple.dark,
  colors.orange.dark,
  colors.red.dark,
];
