export default function LayerWoodProfile(props: any) {
  return (
    <svg
      // height={props.thickness * 0.94}
      // width={props.thickness}
      viewBox="7.958 4.844 179.412 87.025"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        fill="white"
        stroke="rgb(0, 0, 0)"
        strokeWidth={props.thickness > 35 ? "3px" : "5px"}
        x1="8.823529819400806"
        y1="5.363322047086763"
        x2="187.3702508708053"
        y2="91.8685163549378"
      />
      <line
        fill="white"
        stroke="rgb(0, 0, 0)"
        strokeWidth={props.thickness > 35 ? "3px" : "5px"}
        x1="7.958"
        y1="91.349"
        x2="186.505"
        y2="4.844"
      />
      <line
        fill="white"
        stroke="rgb(0, 0, 0)"
        strokeWidth={props.thickness > 35 ? "3px" : "5px"}
        x1="8.650519430785096"
        y1="91.17646952061695"
        x2="187.1972404821896"
        y2="91.69550068646403"
      />
      <line
        fill="white"
        stroke="rgb(0, 0, 0)"
        strokeWidth={props.thickness > 35 ? "3px" : "5px"}
        x1="185.813157373264"
        y1="5.017295989997308"
        x2="9.515571373863608"
        y2="5.190306378613016"
      />
    </svg>
  );
}
