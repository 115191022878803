import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { ReportTable } from "../components/table";
import { ReportStatisticsBox } from "../components/statistics-box";
import { ReportPieChart } from "../components/pie-chart";
import { ReportBarChart } from "../components/bar-chart";
import { ReportTypography } from "../components/report-typography";
import { useRawMaterialsQuery } from "../../../lib/generated/graphql";
import _, { groupBy } from "lodash";
import { replaceRestInRestRow } from "../../../lib/format";
import rounderOf, { rounderInt, rounderTwo } from "../../../lib/rounder";
import useDsrQueryHook from "../hooks/useDsrQueryHook";

import { ReportPageProps } from "../../report-page";

export function replaceProductRawMaterialIdWithName(
  rows: any[],
  rawMaterialsById: any
) {
  return rows.map(({ ProductRawMaterialId, ...rest }) => {
    return {
      ...rest,
      ...(ProductRawMaterialId
        ? {
            ProductRawMaterialId:
              rawMaterialsById[ProductRawMaterialId]?.[0]?.name ||
              ProductRawMaterialId,
          }
        : {}),
    };
  });
}

export function omitRedundantFields(rows: any[]) {
  return rows
    .map((r) => _(r).omitBy(_.isNil).omit("__typename").value())
    .map(({ otherFields, ...rest }) => ({
      ...rest,
      ...otherFields,
    }));
}

export default function ReportGwpSummaryPage({
  tmplVars,
  decimals,
  pageNumber,
  projectId,
  scenarioId,
  selectedDatasetIds,
}: ReportPageProps) {
  const chapter = "Resultat";
  const pageTitle = "Projektets klimatpåverkan";
  const round = rounderOf(decimals);
  const rawMaterials = useRawMaterialsQuery({ variables: { projectId } })?.data
    ?.project?.rawMaterials;

  const rawMaterialsById = groupBy(rawMaterials, "id");

  const primaryDsrs = useDsrQueryHook({
    filter: [],
    group: [{ col: "plant::rawMaterial::id", as: "ProductRawMaterialId" }],
    aggregations: [
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "weightPerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "biogenicCarbonPerBTA", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 18,
  });

  const gwpOnGenericMaterial = useDsrQueryHook({
    filter: [],
    group: [{ col: "plant::rawMaterial::id", as: "ProductRawMaterialId" }],
    aggregations: [{ col: "A1_A5", op: "sum", reduceAggOp: "sum" }],
    sort: [{ col: "A1_A5", desc: true }],
    topN: 5,
  });

  const { sum: barchartData } = useDsrQueryHook({
    filter: [],
    group: [{ col: "datasetId" }],
    aggregations: [
      { col: "A1_A3PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "A4PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "A5PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [],
    topN: 1,
  });

  const rows = replaceProductRawMaterialIdWithName(
    omitRedundantFields(primaryDsrs.data),
    rawMaterialsById
  ).map((row) => ({
    ...row,
    weightPerBTA: rounderInt(row.weightPerBTA),
    A1_A5PerBTA: rounderTwo(row.A1_A5PerBTA),
  }));

  const rows2 = replaceProductRawMaterialIdWithName(
    omitRedundantFields([
      ...gwpOnGenericMaterial.data,
      ...(gwpOnGenericMaterial.rest
        ? [{ ...gwpOnGenericMaterial.rest, ProductRawMaterialId: "Resterande" }]
        : []),
    ]),
    rawMaterialsById
  )
    .map((r) => ({
      name: r.ProductRawMaterialId,
      value: r.A1_A5,
    }))
    .filter((d) => d.name);

  const columns = [
    {
      label: "Generiskt material",
      value: "ProductRawMaterialId",
      align: "left" as const,
    },
    {
      label: `Vikt material, kg/${tmplVars.BTA.inUnit}`,
      value: "weightPerBTA",
      align: "right" as const,
    },
    {
      label: `kg CO₂e/${tmplVars.BTA.inUnit}`,
      value: "A1_A5PerBTA",
      align: "right" as const,
    },
  ];

  return (
    <ReportContainer>
      <ReportHeader chapter={chapter} />
      <ReportBodyAlignSpaceBetween>
        <Box mt={5} mb={1} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>
        <Box flexGrow={1}>
          <ReportTable
            columns={columns}
            hasRawMaterials={true}
            tableCaption="Generiska råmaterial fördelade på byggnadens bruttoarea, kg/BTA."
            rows={round(rows)}
            restRow={round(replaceRestInRestRow(primaryDsrs.rest))}
            sumRow={round(primaryDsrs.sum)}
          />
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Box>
            <ReportStatisticsBox
              chart={() => <ReportPieChart data={rows2 || []} />}
              title="Klimatpåverkan, generiskt material"
              caption="Fördelning per material, andel av klimatpåverkan"
            />
          </Box>
          <Box>
            <ReportStatisticsBox
              barchart={() => (
                <ReportBarChart
                  data={[
                    {
                      Produktskede: Math.round(
                        barchartData.A1_A5PerBTA && barchartData.A1_A3PerBTA
                          ? barchartData.A1_A3PerBTA
                          : 0
                      ),
                      Transportskede: Math.round(
                        barchartData.A1_A5PerBTA && barchartData.A4PerBTA
                          ? barchartData.A4PerBTA
                          : 0
                      ),
                      Byggskede: Math.round(
                        barchartData.A1_A5PerBTA && barchartData.A5PerBTA
                          ? barchartData.A5PerBTA
                          : 0
                      ),
                    },
                  ]}
                />
              )}
              title="Klimatpåverkan i livscyekelskeden"
              caption="Fördelning per skede, andel av klimatpåverkan"
            />
          </Box>
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} />
    </ReportContainer>
  );
}
