import { useParams } from "react-router-dom";
import { colors } from "../lib/styles/colors";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Button } from "@material-ui/core";
import PlantLogo from "../assets/PlantLogo";
import { useProjectQuery } from "../lib/generated/graphql";
import useSignOut from "../lib/components/authed/use-sign-out";
import { DefaultTheme } from "../providers/styling";

const drawerWidth = 200;

const useStyles = makeStyles((theme: DefaultTheme) => ({
  drawer: {
    [theme.breakpoints.up("xl")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: 20,
  },
  toolbar: theme.mixins.toolbar,
  active: {
    background: "#e8f0fe",
  },
  root: {
    backgroundColor: "#ffffff",
    height: "100%",

    [theme.breakpoints.down(960)]: {
      position: "relative",
    },
    [theme.breakpoints.up(960)]: {
      display: "flex",
    },
  },
  appBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 12px",
    zIndex: 1201,
    height: 48,
    fontWeight: 400,
    "@media print": {
      display: "none",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("xl")]: {
      position: "relative",
    },
    borderRight: "none",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  content: {
    width: `100%`,
    [theme.breakpoints.up("xl")]: {
      width: "100%",
    },
    height: "calc(100% - 48px)",
    margin: `${theme.spacing(6)}px ${theme.spacing(0)}px 0`,
    "@media print": {
      margin: 0,
      height: "100%",
    },
    backgroundColor: "#ffffff",
  },
  logoContainer: {
    padding: "15px",
    textAlign: "center",
  },
  logoText: {
    fontSize: "40px",
    color: "white",
  },
  listItem: {
    borderRadius: "0 66px 66px 0",
    padding: theme.spacing(1),
    "&:hover": {
      background: "rgba(0,0,0,.04)",
    },
    "&:active": {
      background: "#e8f0fe",
      color: colors.plant.green,
    },
  },
  listItemIcon: {
    minWidth: "40px",
  },
  listItemText: {
    color: "#5f6368",
    fontWeight: 600,
    fontSize: 15,
    "&:hover": {
      color: "#5f6368",
    },
    "&:active": {
      color: colors.plant.green,
    },
  },
  listItemActiveText: {
    fontWeight: 600,
    fontSize: 15,
    color: colors.plant.green,
  },
  menuLinks: {
    textDecoration: "none",
  },
}));

interface IResponsiveDrawer {
  children: string | JSX.Element;
}

export const MenuComopnent = ({ children }: IResponsiveDrawer) => {
  const classes = useStyles();
  const signOut = useSignOut();

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{
          boxShadow: "none",
          left: 0,
          right: "auto",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ width: 130, marginLeft: 15 }}>
            <PlantLogo />
          </div>

          <Button
            id="logout-btn"
            variant="outlined"
            style={{
              color: "white",
              borderColor: "rgba(255,255,255,0.5",
              marginLeft: 20,
            }}
            onClick={() => signOut(() => window.location.assign("/"))}
          >
            Logga ut
          </Button>
        </div>
      </AppBar>
      <main className={classes.content}>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ width: "100%", height: "100%" }}>{children}</div>
        </div>
      </main>
    </div>
  );
};
export default function Menu({ children }: IResponsiveDrawer) {
  const { projectId } = useParams();
  const { data } = useProjectQuery({
    skip: !projectId,
    variables: { projectId: projectId || "" },
  });

  if (data?.project == null) {
    return null;
  }

  return <MenuComopnent>{children}</MenuComopnent>;
}
