import { useMemo } from "react";
import { Bar, BarChart, Cell, LabelList, XAxis, YAxis } from "recharts";

import {
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";

import {
  ProjectTypeEnum,
  useProjectPipelinesQuery,
  useProjectQuery,
} from "../../../lib/generated/graphql";

import { ReportPageProps } from "../../report-page";

import useDsrQueryHook from "../hooks/useDsrQueryHook";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { chartColorArray } from "../components/chart-colors";

import useScenariosQueries from "../hooks/scenario-queries";

export const typeTranslation = (str?: ProjectTypeEnum | null) => {
  switch (str) {
    case ProjectTypeEnum.NewBuilding:
      return "Nybyggnation";
    case ProjectTypeEnum.Extension:
      return "Utbyggnad";
    case ProjectTypeEnum.Remodeling:
      return "Ombyggnad";
    default:
      return "Okänd";
  }
};

export const translateBuildingFrame = (str: string) => {
  if (str === "YVB + IVB ") {
    return "Bärande väggar";
  } else if (str === "Pelare/balk") {
    return "Pelare och balk";
  }
  return "Okänd";
};

export const useStyles = makeStyles(() =>
  createStyles({
    barChart: {
      "& .recharts-legend-item-text": {
        fontSize: 10,
      },
    },
  })
);

const query = {
  filter: [{ Include: { eq: "TRUE" } }],
  aggregations: [
    {
      col: "A1_A3PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A4PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A5PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A1_A5PerBTA",
      op: "sum",
      reduceAggOp: "sum",
    },
    {
      col: "A1_A5",
      op: "sum",
      reduceAggOp: "sum",
    },
  ],
  group: [{ col: "FunctionType" }],
  sort: [{ col: "A1_A5PerBTA", desc: true }],
  topN: 100,
};
const ResultTable = () => {
  const params = useParams();
  const projectId = params.projectId!;

  const pipelines = useProjectPipelinesQuery({ variables: { projectId } }).data
    ?.project?.pipelines.nodes;

  const sumByScenario = useScenariosQueries(query)?.sumByScenario;

  const getName = (inputId: string) =>
    pipelines?.find((pipeline: any) => pipeline.id === inputId)?.name || null;

  const dataWithName = useMemo(() => {
    return Object.entries(sumByScenario).reduce((acc, [key, values]) => {
      const { FunctionType, ...numberEntries } = values;
      const roundedNumberEntries = Object.entries(numberEntries).reduce(
        (acc, [key, value]) => {
          return { ...acc, [key]: Math.round((value as number) || 0) };
        },
        {}
      );
      const idToName = getName(key) as string;
      return { ...acc, [idToName]: roundedNumberEntries };
    }, {});
  }, [sumByScenario]);

  const headerCells = [
    "Scenario",
    "A1-A3/BTA",
    "A4/BTA",
    "A5/BTA",
    "A1-A5/BTA",
    "A1-A5",
  ];

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            {headerCells.map((cell, index) => (
              <Th isNumeric={index !== 0} key={cell + index}>
                {cell}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(dataWithName).map(([key, data]) => {
            return (
              <Tr key={"entry" + key}>
                <Td maxW="80px" overflow="hidden" whiteSpace="normal">
                  {key}
                </Td>
                {Object.values(data as number[]).map((d) => (
                  <Td isNumeric key={d}>
                    {d.toLocaleString("fr")}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const BarCharts = () => {
  const rows = useDsrQueryHook({
    filter: [{ Include: { eq: "TRUE" } }],
    aggregations: [
      {
        col: "A1_A5PerBTA",
        op: "sum",
        reduceAggOp: "sum",
      },
    ],
    group: [
      {
        col: "FunctionType",
        as: "FT",
      },
    ],
    sort: [{ col: "FT", desc: false }],
    topN: 100,
  });

  const roundedData = useMemo(
    () =>
      Object.entries(
        [...(rows.data || [])].reduce((acc, cur) => {
          const value = Math.round(cur?.A1_A5PerBTA || 0);
          if (value > 0) {
            return { ...acc, [`${cur?.FT}`]: value };
          }

          return acc;
        }, {})
      ).map(([key, value]) => ({ key, value })),
    []
  );

  return (
    <>
      <BarChart width={650} height={350} data={roundedData}>
        <XAxis
          dataKey="key"
          angle={-45}
          interval={0}
          height={120}
          padding={{ left: 20, right: 0 }}
          textAnchor="end"
        />
        <YAxis width={20} />
        <Bar isAnimationActive={false} dataKey={"value"}>
          {roundedData.map((entry, index) => (
            <>
              <Cell key={`cell-${index}`} fill={chartColorArray[index]}></Cell>
              <LabelList
                dataKey="value"
                position="top"
                fill={"#777"}
                fontSize={"10px"}
              />
            </>
          ))}
        </Bar>
      </BarChart>
    </>
  );
};

export default function ReportAbstractPage(props: ReportPageProps) {
  const project = useProjectQuery({ variables: { projectId: props.projectId } })
    ?.data?.project;

  const firstImageURL = project?.images?.[0]?.data?.url;
  const firstImageDescription = project?.images?.[0]?.description;

  const dsrs = useDsrQueryHook(query);

  const descriptionLimit = 600;
  const maxLimit = 1100;
  const reportSummary = project?.reportSummary;
  return (
    <ReportContainer>
      <ReportHeader
        chapter="Resultat LCA-projekt"
        reportType={props.reportType}
      />

      <Box my={4}></Box>
      <Box h="100%" maxH="100%">
        <Heading fontWeight="bold" as="h1" size="3xl" gridColumn={"1 /span 2"}>
          {project?.name}
        </Heading>
        <SimpleGrid columns={2} gap={8}>
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="stretch"
            flexDir="column"
          >
            <Text fontWeight="bold" fontStyle="italic">
              {project?.reportTitle}
            </Text>

            <Text mb={4} fontStyle="italic">
              {project?.reportSubTitle}
            </Text>

            <Flex justifyContent="space-between">
              <Text>{typeTranslation(project?.projectType)}</Text>
              <Text>{`${Math.round(dsrs.sum.A1_A5PerBTA || 0)} CO₂e/BTA`}</Text>
            </Flex>

            <Flex justifyContent="space-between">
              <Text>{project?.constructionType}</Text>
              <Text>{`${project?.stories} våningar`}</Text>
            </Flex>

            {(reportSummary || "")?.length < descriptionLimit && (
              <Text my={4} whiteSpace="pre-wrap">{`${reportSummary}`}</Text>
            )}

            <Flex justifyContent="space-between">
              <Text>Stomme</Text>
              <Text whiteSpace="nowrap">
                {translateBuildingFrame(project?.buildingFrame || "")}
              </Text>
            </Flex>
            <Text>{`Skriven av: ${project?.reportAuthor || "Okänd"}`}</Text>
          </Flex>
          <Flex
            bg="white"
            justifyContent="center"
            alignItems="center"
            flexDir="column"
            px={8}
          >
            {firstImageURL && (
              <>
                <Image
                  alt="introduction image"
                  borderRadius="md"
                  objectFit="cover"
                  overflow="hidden"
                  src={firstImageURL}
                  width="250px"
                />
                <Text fontStyle="italic" mt={4}>
                  {firstImageDescription}
                </Text>
              </>
            )}
          </Flex>

          {reportSummary && reportSummary?.length > descriptionLimit && (
            <Text whiteSpace="pre-wrap" bg="white" gridColumn={"1 /span 2"}>
              {`${
                reportSummary?.length > maxLimit
                  ? reportSummary?.slice(0, maxLimit) +
                    "... (summary continues)"
                  : reportSummary
              }`}
            </Text>
          )}
          <Box bg="white" gridColumn={"1 /span 2"}>
            <BarCharts />
          </Box>
          <Box bg="white" gridColumn={"1 /span 2"}>
            <ResultTable />
          </Box>
        </SimpleGrid>
      </Box>
      <ReportFooter pageNumber={props.pageNumber} />
    </ReportContainer>
  );
}
