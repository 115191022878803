import { useTheme } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportTypography } from "../components/report-typography";
import { useProjectQuery } from "../../../lib/generated/graphql";
import { IReportColumns, ReportTable } from "../components/table";
import { grey } from "@material-ui/core/colors";
import { ReportStackedBarChart } from "../components/report-stacked-bar-chart";
import { useLocation } from "react-router-dom";
import { ReportPageProps } from "../../report-page";
import useScenariosQueries, {
  useGetBenchmarks,
} from "../hooks/scenario-queries";
import { DefaultTheme } from "../../../providers/styling";
import { rounderTwo } from "../../../lib/rounder";
import { Box, Flex, Text } from "@chakra-ui/react";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getScenarioName(
  dsr: any,
  scenarios: { name: string; id: string }[]
) {
  return scenarios?.find((s) => s.id === dsr?.scenarioId)?.name ?? "";
}

export default function ReportScenariosIntroductionPage({
  pageNumber,
  reportType,
  projectId,
}: ReportPageProps) {
  const theme = useTheme<DefaultTheme>();
  const pipelinesOrScenarios = useGetBenchmarks(projectId);

  const project = useProjectQuery({ variables: { projectId } })?.data
    ?.project || {
    area: "",
    reportScenarioSummary: "",
  };

  const dsrs = useScenariosQueries({
    filter: [],
    group: [{ col: "datasetId" }],
    aggregations: [
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "biogenicCarbonPerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "weight", op: "sum", reduceAggOp: "sum" },
      { col: "A1_A5", op: "sum", reduceAggOp: "sum" },
      { col: "A1_A3PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "A4PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "A5PerBTA", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 10,
  });

  const pageTitle = "Scenarion".toUpperCase();
  const subTitle = `På följande sidor presenteras byggnadens klimatpåverkan utifrån olika scenarion.`;

  const rowsWithoutReductionBiogenicCarbon = Object.entries(
    dsrs.sumByScenario
  ).map(([scenarioId, sum]) => {
    return {
      ...sum,
      id: scenarioId,
      scenarioId,
      scenarioName: (pipelinesOrScenarios || [])?.find(
        ({ id }) => id === scenarioId
      )?.name,
      weight: Math.round(sum?.weight ?? 0 / 1000),
      A1_A5PerBTAWithBC: rounderTwo(
        (sum?.A1_A5PerBTA ?? 0) - (sum?.biogenicCarbonPerBTA ?? 0) * 0.5
      ),
      Produktskede: Math.round(sum?.A1_A3PerBTA ?? 0),
      Transportskede: Math.round(sum?.A4PerBTA ?? 0),
      Byggskede: Math.round(sum?.A5PerBTA ?? 0),
      A1_A5PerBTA: rounderTwo(sum?.A1_A5PerBTA ?? 0),
      A1_A5: Math.round((sum?.A1_A5 ?? 0) / 1000),
    };
  });

  const columns: IReportColumns[] = [
    {
      label: "Scenario",
      value: "scenarioName",
      align: "left",
    },
    {
      label: "kg CO₂e/BTA",
      value: "A1_A5PerBTA",
      align: "right",
    },
    {
      label: "Ton CO₂e",
      value: "A1_A5",
      align: "right",
    },
  ];

  return (
    <ReportContainer>
      <ReportHeader chapter="Tillvägagångssätt" reportType={reportType} />

      <Flex flexDir="column" flexGrow={1} mt={10} gap={10}>
        <Box textAlign="left" maxWidth={theme.spacing(70)}>
          <ReportTypography variant="h2">{pageTitle}</ReportTypography>
          <ReportTypography variant="subtitle2">{subTitle}</ReportTypography>
        </Box>

        <ReportTypography
          variant="subtitle2"
          style={{ whiteSpace: "pre-line" }}
        >
          {project?.reportScenarioSummary}
        </ReportTypography>

        <Flex gap={10} flexDir="column" mt={10}>
          <ReportStackedBarChart
            isPrint={true}
            fullWidth
            data={rowsWithoutReductionBiogenicCarbon.map((row) => ({
              ...row,
              scenarioName:
                (row.scenarioName?.length ?? 0) > 14
                  ? `${row.scenarioName?.slice(0, 12)}...`
                  : row.scenarioName,
            }))}
          />

          <ReportTable
            borderBottomHeaderCell={`2px solid ${grey[400]}`}
            columns={columns}
            hasRawMaterials={false}
            rows={rowsWithoutReductionBiogenicCarbon}
            title="Klimatpåverkan"
            tableUnit="none"
          />
        </Flex>
      </Flex>

      <ReportFooter pageNumber={pageNumber} />
    </ReportContainer>
  );
}
