import { useCallback } from "react";
import { auth } from "../../lib/firebase";
import { useLocationApiKey } from "./useLocationApiKey";

type ApolloAuthKeyType = () => Promise<string | undefined>;

export const useApolloAuthKey = (): ApolloAuthKeyType => {
  const apiKey = useLocationApiKey();

  const getAuthorizationHeader = useCallback(async () => {
    if (apiKey != null) {
      return `APIKey ${apiKey}`;
    }

    const token = await auth.getCurrentToken();
    if (token) {
      return `Bearer ${token}`;
    }
    const forcedToken = await auth.waitForToken();
    if (forcedToken) {
      return `Bearer ${forcedToken}`;
    }

    return undefined;
  }, [apiKey]);

  return getAuthorizationHeader;
};
