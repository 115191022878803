import { ReportHeaderCommon as ReportHeader } from "../../../report/components/report-header";
import { Box, CircularProgress } from "@material-ui/core";
import { ReportFooter } from "../../../report/components/report-footer";
import {
  BuildingElementDetailedFieldsFragment,
  useSimplifiedTenantAdaptionProjectQuery,
} from "../../../../lib/generated/graphql";
import { ReportContainer } from "../../../report/components/report-container";

import { ReportSimplifiedTenantAdaptionBuildingElement } from "../../../report/components/report-simplified-tenant-adaption-building-element";
import { SingleReportPage } from "./index";
import _ from "lodash";

function chunkBuildingElementsByHeight<
  T extends BuildingElementDetailedFieldsFragment
>(buildingElements: T[]) {
  const maxHeight = 1050;
  const pxEachBE = 140;
  const pxProduct = 35;

  const chunks: T[][] = [[]];
  let chunkIndex = 0;
  let currentHeight = 0;

  for (const buildingElement of buildingElements) {
    const numProducts = buildingElement?.products?.length || 0;
    currentHeight = currentHeight + numProducts * pxProduct + pxEachBE;

    if (currentHeight > maxHeight) {
      chunks.push([buildingElement]);
      chunkIndex = chunkIndex + 1;
      currentHeight = numProducts * pxProduct + pxEachBE;
    } else {
      chunks[chunkIndex].push(buildingElement);
    }
  }

  return chunks;
}

const BuildingElementPages: SingleReportPage = ({
  pageNumber = 0,
  projectId,
  reportTemplate,
}) => {
  const { data: projectData, loading: loadingProject } =
    useSimplifiedTenantAdaptionProjectQuery({
      variables: { id: projectId },
    });
  if (loadingProject) {
    return <CircularProgress />;
  }
  const headers =
    projectData?.simplifiedTenantAdaption?.project?.template?.data?.headers!;
  const buildingElements = headers
    .flatMap(({ rows }) => rows)
    .map(({ buildingElement }) => buildingElement);

  const uniqueBuildingElements = _.uniqBy(buildingElements, (r) => r.id);

  const chunks = chunkBuildingElementsByHeight(uniqueBuildingElements);

  return (
    <>
      {chunks.map((page, chunkNumber) => (
        <ReportContainer key={chunkNumber}>
          <ReportHeader
            chapter="Sammansatta material"
            reportType={"simplified"}
            reportTemplate={reportTemplate}
          />
          <Box mt={6} height={"100%"}>
            {chunks[chunkNumber].map((buildingElement) => (
              <Box mb={2}>
                <ReportSimplifiedTenantAdaptionBuildingElement
                  buildingElement={buildingElement}
                />
              </Box>
            ))}
          </Box>
          <ReportFooter pageNumber={pageNumber + chunkNumber} />
        </ReportContainer>
      ))}
    </>
  );
};

export default BuildingElementPages;
