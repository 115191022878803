import { useMemo } from "react";
import {
  useRawMaterialsQuery,
  RawMaterial,
} from "../../../lib/generated/graphql";
import { ReportPageProps } from "../../report-page";
import useDsrQueryHook from "../hooks/useDsrQueryHook";
import CommonRawMaterialPages from "./common/common-raw-material-pages";

export function ReportRawMaterialPages({
  pageNumber,
  projectId,
}: ReportPageProps) {
  const allRawMaterials =
    useRawMaterialsQuery({
      variables: {
        projectId,
        filter: {
          buildingElement: null,
          sourceName: { not: "KTH" },
        },
      },
    })?.data?.project?.rawMaterials ?? [];

  const filteredRawMaterials =
    useDsrQueryHook(
      {
        filter: [],
        group: [{ col: "plant::rawMaterial::id", as: "ProductRawMaterialId" }],
        aggregations: [],
        sort: [],
        topN: 3000,
      },
      true
    )?.data || [];

  const rawMaterialIdsInScenario = useMemo(
    () => filteredRawMaterials?.map((rM) => rM.ProductRawMaterialId),
    [filteredRawMaterials]
  );

  const rawMaterialsInScenario = useMemo(() => {
    const filteredMaterials = allRawMaterials?.reduce(
      (acc: RawMaterial[], cur: any) => {
        if (rawMaterialIdsInScenario.includes(cur?.id)) {
          return [...acc, cur];
        }
        return acc;
      },
      []
    );

    return filteredMaterials;
  }, [allRawMaterials, rawMaterialIdsInScenario]);

  return (
    <CommonRawMaterialPages
      rawMaterials={rawMaterialsInScenario}
      pageNumber={pageNumber}
    />
  );
}
