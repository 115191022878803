import React, { createContext } from "react";
import { ApolloProvider } from "@apollo/client";
import FullscreenLoader from "../../lib/components/fullscreen-loader";
import { useApolloClient, ApolloClientHook } from "./useApolloClient";

const defaultState: ApolloClientHook = {
  isReady: false,
  apolloClient: undefined,
  authState: undefined,
};

export const ApolloAuthContext = createContext<ApolloClientHook>(defaultState);

const AuthorizedApolloProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const apollo = useApolloClient();
  const { isReady, apolloClient } = apollo;
  if (!isReady || apolloClient == null) {
    return <FullscreenLoader />;
  }

  return (
    <ApolloAuthContext.Provider value={apollo}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </ApolloAuthContext.Provider>
  );
};

export default AuthorizedApolloProvider;
