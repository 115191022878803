export default function LayerBrick(props: any) {
  return (
    <svg viewBox="0 0.27 190 90.43" xmlns="http://www.w3.org/2000/svg">
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="29.28"
        y1="5.27"
        x2="5"
        y2="29.54"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="20.47"
        y1="5.27"
        x2="5.01"
        y2="20.73"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="11.73"
        y1="5.27"
        x2="5.01"
        y2="11.99"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="47.33"
        y1="5.27"
        x2="5.6"
        y2="47"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="38.53"
        y1="5.27"
        x2="5"
        y2="38.79"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="74.68"
        y1="5.27"
        x2="5"
        y2="74.95"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="65.87"
        y1="5.27"
        x2="5"
        y2="66.14"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="57.14"
        y1="5.27"
        x2="5.01"
        y2="57.39"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="92.73"
        y1="5.27"
        x2="5.01"
        y2="92.99"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="83.93"
        y1="5.27"
        x2="5.01"
        y2="84.18"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="119.71"
        y1="5.27"
        x2="29.98"
        y2="95"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="110.91"
        y1="5.27"
        x2="21.17"
        y2="95"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="102.17"
        y1="5.27"
        x2="11.73"
        y2="95.7"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="137.77"
        y1="5.27"
        x2="48.03"
        y2="95"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="128.96"
        y1="5.27"
        x2="39.22"
        y2="95"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="164.71"
        y1="5.27"
        x2="74.98"
        y2="95"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="155.91"
        y1="5.27"
        x2="66.77"
        y2="94.4"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="147.17"
        y1="5.27"
        x2="58.03"
        y2="94.4"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="182.77"
        y1="5.27"
        x2="93.03"
        y2="95"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="173.96"
        y1="5.27"
        x2="83.93"
        y2="95.3"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="195"
        y1="19.98"
        x2="119.84"
        y2="95.13"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="195"
        y1="11.17"
        x2="111.34"
        y2="94.83"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="192.17"
        y1="5.27"
        x2="102.14"
        y2="95.3"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="195"
        y1="38.03"
        x2="138.03"
        y2="95"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="195"
        y1="29.22"
        x2="129.09"
        y2="95.13"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="195"
        y1="55.81"
        x2="155.67"
        y2="95.13"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="195"
        y1="47"
        x2="147.17"
        y2="94.83"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="195"
        y1="73.86"
        x2="174.03"
        y2="94.83"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="195"
        y1="83.2"
        x2="183.37"
        y2="94.83"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="195"
        y1="91.16"
        x2="191.32"
        y2="94.83"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
      <line
        stroke="black"
        strokeWidth="3px"
        fill="none"
        x1="195"
        y1="65.05"
        x2="165.22"
        y2="94.83"
        transform="matrix(1, 0, 0, 1, -5, -5)"
      />
    </svg>
  );
}
