import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportTypography } from "../components/report-typography";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { ReportPageProps } from "../../report-page";

export default function ReportDictionaryPage(props: ReportPageProps) {
  const pageTitle = "Ordlista".toUpperCase();

  const dictionary = [
    {
      word: "Klimatpåverkan",
      description: "Utsläpp och upptag av växthusgaser i GWP",
    },
    {
      word: "Yta",
      description: "Den yta som är beräknad i projektet, vanligtvis BTA",
    },
    { word: "BTA", description: "Bruttoarea" },
    {
      word: "Biogen kolinlagring",
      description:
        "När material ur det biologiska kretsloppet används som byggnadsmaterial förvaras denna koldioxid i byggnaden under materialets livslängd. Denna förvaring av koldioxid gör att utsläppen sker vid ett senare tillfälle.",
    },
    {
      word: "CO₂e",
      description:
        "Koldioxidekvivalenter eller CO₂e är ett mått på utsläpp av växthusgaser som tar hänsyn till att olika gaser har olika förmåga att bidra till växthuseffekten och global uppvärmning. När man uttrycker utsläppen av en viss växthusgas i koldioxidekvivalenter anger man hur mycket koldioxid som skulle behöva släppas ut för att ge samma verkan på klimatet.",
    },
    {
      word: "GWP",
      description:
        "Global Warming Potential, faktor som beskriver ett materials klimatpåverkan",
    },
    {
      word: "GWP-BC",
      description:
        "Global Warming Potential - Biogenic Carbon. Klimatpåverkan från nettoupptag och utsläpp av biogent kol från materialen",
    },
    {
      word: "GWP-IOBC",
      description:
        "Global Warming Potential - Instant Oxidation of Biogenic Carbon. Klimatpåverkan beräknat efter omedelbar oxidation av biogent kol-principen. Positiva effekter från biogent kol är exkluderad från denna siffra",
    },
    { word: "LCA", description: "Livscykelanalys" },
  ];

  return (
    <ReportContainer>
      <ReportHeader chapter="Tillvägagångssätt" reportType={props.reportType} />
      <ReportBodyAlignSpaceBetween>
        <Box mt={20} mb={4} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>
        <Box mb={2}>
          <ReportTypography variant={"body1"}>
            Då avsikten med denna rapport är att en bred publik ska kunna ta del
            av innehållet så har vi valt att byta ut vissa facktermer mot mer
            vardagliga ord. Detta gör rapporten mer lätttillgänglig.
            Översättningen av de vardagliga orden till facktermerna finns i
            ordlistan.
          </ReportTypography>
        </Box>
        <Box flexGrow={1}>
          {dictionary.map(({ word, description }) => {
            return (
              <Box mb={2} key={word}>
                <ReportTypography variant={"h5"}>{word}</ReportTypography>
                <ReportTypography variant={"body1"}>
                  {description}
                </ReportTypography>
              </Box>
            );
          })}
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={props.pageNumber} />
    </ReportContainer>
  );
}
