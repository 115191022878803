export default function PlantLogo({
  fillColor = "#fff",
  width = 400,
  height = 46,
}) {
  return (
    <svg
      id="PlantLogo"
      data-name="Plant logo"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={{ maxWidth: "100%" }}
      viewBox="0 0 400 76"
    >
      <title>Plant logo</title>
      <path
        fill={fillColor}
        d="M65.48,21.8A3.26,3.26,0,0,0,65.91,25c.72,1.06,1.6,1.27,2,.55a3.65,3.65,0,0,0-.41-3.12c-.62-1-1.53-1.35-2-.65"
      />
      <path
        fill={fillColor}
        d="M61.55,18.32c.91,1,2.07,1.29,2.59.6s.24-2-.6-3-2-1.31-2.58-.67-.32,2,.59,3.07"
      />
      <path
        fill={fillColor}
        d="M68.87,35.44a4.1,4.1,0,0,0,0,3.41c.5.94,1.21.88,1.57,0a4.9,4.9,0,0,0,0-3.25c-.39-.93-1.1-1-1.58-.14"
      />
      <path
        fill={fillColor}
        d="M64.44,29.25a3.43,3.43,0,0,0,.2,3.57c.74,1,1.77,1.11,2.3.21a3.64,3.64,0,0,0-.2-3.45c-.64-1-1.67-1.22-2.3-.33"
      />
      <path
        fill={fillColor}
        d="M62.16,23.18c-.85-1.07-2.13-1.28-2.86-.43a2.92,2.92,0,0,0,.34,3.57c.92,1.1,2.22,1.22,2.88.34a3,3,0,0,0-.36-3.48"
      />
      <path
        fill={fillColor}
        d="M56.5,16.83c-1-1-2.45-1.23-3.25-.44s-.63,2.33.42,3.39S56.21,21,57,20.16a2.5,2.5,0,0,0-.46-3.33"
      />
      <path
        fill={fillColor}
        d="M67.68,44.13a3.63,3.63,0,0,0-.2,3.47c.54.85,1.43.65,2-.37a4.07,4.07,0,0,0,.19-3.33c-.43-.86-1.31-.8-2,.23"
      />
      <path
        fill={fillColor}
        d="M62.56,38.25a3.32,3.32,0,0,0,0,3.74,1.5,1.5,0,0,0,2.61-.12,3.45,3.45,0,0,0,0-3.61,1.5,1.5,0,0,0-2.61,0"
      />
      <path
        fill={fillColor}
        d="M57.33,31.75a3.12,3.12,0,0,0,.1,3.88,2,2,0,0,0,3.16.07,3.16,3.16,0,0,0-.11-3.78,2,2,0,0,0-3.15-.17"
      />
      <path
        fill={fillColor}
        d="M51.19,25a2.86,2.86,0,0,0,.2,3.87,2.39,2.39,0,0,0,3.57.17,2.87,2.87,0,0,0-.23-3.79A2.41,2.41,0,0,0,51.19,25"
      />
      <path
        fill={fillColor}
        d="M64.69,53.06c-.8,1.07-1,2.58-.43,3.31s1.59.44,2.31-.6A3.27,3.27,0,0,0,67,52.56c-.47-.76-1.5-.56-2.31.5"
      />
      <path
        fill={fillColor}
        d="M59.85,47.73a3,3,0,0,0-.26,3.68c.75.9,2,.71,2.88-.36a3.13,3.13,0,0,0,.27-3.58c-.68-.92-2-.84-2.89.26"
      />
      <path
        fill={fillColor}
        d="M57.87,45.34A3.07,3.07,0,0,0,58,41.48a2.18,2.18,0,0,0-3.4.07,3,3,0,0,0-.09,3.94,2.17,2.17,0,0,0,3.38-.15"
      />
      <path
        fill={fillColor}
        d="M48.43,38.9a2.55,2.55,0,0,0,3.78,0,3,3,0,0,0,0-4,2.54,2.54,0,0,0-3.77-.06,3,3,0,0,0,0,4.09"
      />
      <path
        fill={fillColor}
        d="M41.74,32a2.81,2.81,0,0,0,4,.06,2.89,2.89,0,0,0-.11-4,2.81,2.81,0,0,0-4-.11A2.9,2.9,0,0,0,41.74,32"
      />
      <path
        fill={fillColor}
        d="M60.52,61.55c-.93,1-1.22,2.31-.67,2.88s1.7.26,2.57-.69,1.17-2.21.68-2.84-1.65-.35-2.58.65"
      />
      <path
        fill={fillColor}
        d="M56.43,57c-1,1.06-1.2,2.58-.46,3.34s2.1.53,3.05-.49a2.6,2.6,0,0,0,.5-3.29c-.69-.8-2.08-.63-3.09.44"
      />
      <path
        fill={fillColor}
        d="M54.77,51.23a2.41,2.41,0,0,0-3.54.24A2.73,2.73,0,0,0,51,55.21a2.43,2.43,0,0,0,3.51-.29,2.77,2.77,0,0,0,.29-3.69"
      />
      <path
        fill={fillColor}
        d="M45,49.13A2.69,2.69,0,0,0,48.87,49,2.93,2.93,0,0,0,49,45a2.69,2.69,0,0,0-3.9.09,2.91,2.91,0,0,0-.1,4"
      />
      <path
        fill={fillColor}
        d="M42.45,42.37a3,3,0,0,0,0-4.16,2.88,2.88,0,0,0-4.12,0,3,3,0,0,0,0,4.19,2.87,2.87,0,0,0,4.11,0"
      />
      <path
        fill={fillColor}
        d="M51.86,68.06c.7.57,2.07.34,3.1-.51s1.35-2,.68-2.69-2.1-.43-3.17.49-1.31,2.15-.61,2.71"
      />
      <path
        fill={fillColor}
        d="M51,60.43a2.7,2.7,0,0,0-3.58.31c-1.11,1-1.25,2.48-.37,3.25a2.74,2.74,0,0,0,3.51-.35A2.2,2.2,0,0,0,51,60.43"
      />
      <path
        fill={fillColor}
        d="M45.17,58.59a2.51,2.51,0,0,0,.23-3.66,2.87,2.87,0,0,0-3.92.15,2.52,2.52,0,0,0-.16,3.7,2.88,2.88,0,0,0,3.85-.19"
      />
      <path
        fill={fillColor}
        d="M38.88,52.58a2.78,2.78,0,0,0,.08-4,3,3,0,0,0-4.13,0,2.78,2.78,0,0,0,0,4,3,3,0,0,0,4.09-.05"
      />
      <path
        fill={fillColor}
        d="M32.06,45.89a2.94,2.94,0,0,0,0-4.17,3,3,0,0,0-4.21,0,2.94,2.94,0,0,0,0,4.17,3,3,0,0,0,4.19,0"
      />
      <path
        fill={fillColor}
        d="M50.48,73.2c1.05-.55,1.44-1.37.82-1.79s-2-.24-3.1.43-1.35,1.45-.71,1.76a3.9,3.9,0,0,0,3-.4"
      />
      <path
        fill={fillColor}
        d="M47,68.24a3.13,3.13,0,0,0-3.48.31c-1.09.8-1.24,1.9-.42,2.43a3.34,3.34,0,0,0,3.36-.3c1.07-.71,1.34-1.8.54-2.44"
      />
      <path
        fill={fillColor}
        d="M31.38,61.88a3.09,3.09,0,0,0,3.94,0,2.31,2.31,0,0,0,.07-3.53,3.06,3.06,0,0,0-4,0,2.3,2.3,0,0,0,0,3.53"
      />
      <path
        fill={fillColor}
        d="M28.61,52a3,3,0,0,0-4.1,0,2.65,2.65,0,0,0,.1,3.87,3,3,0,0,0,4,.07,2.66,2.66,0,0,0,0-3.89"
      />
      <path
        fill={fillColor}
        d="M42.91,73.81a4.3,4.3,0,0,0-3.23.22c-1,.49-1.15,1.07-.41,1.3a5.59,5.59,0,0,0,3.07-.19c1-.35,1.3-1,.57-1.33"
      />
      <path
        fill={fillColor}
        d="M25.46,61.23a3,3,0,0,0-3.87-.15,2.12,2.12,0,0,0,.24,3.29,3.07,3.07,0,0,0,3.79.15,2.09,2.09,0,0,0-.16-3.29"
      />
      <path
        fill={fillColor}
        d="M55.86,12.18c1,.93,2.38,1.17,3,.56s.27-1.82-.72-2.68-2.29-1.13-2.94-.58-.36,1.76.68,2.7"
      />
      <path
        fill={fillColor}
        d="M49.3,7.07c1.07.76,2.52,1,3.18.45s.29-1.48-.76-2.13A3.37,3.37,0,0,0,48.64,5c-.68.41-.42,1.36.66,2.11"
      />
      <path
        fill={fillColor}
        d="M42.38,3.27a4.11,4.11,0,0,0,3.17.28c.68-.36.33-1-.69-1.35a5.37,5.37,0,0,0-3-.25c-.68.22-.49.81.55,1.32"
      />
      <path
        fill={fillColor}
        d="M50.17,11a2.84,2.84,0,0,0-3.43-.36c-.85.69-.69,2,.41,3a2.77,2.77,0,0,0,3.52.34c.82-.77.58-2.1-.5-3"
      />
      <path
        fill={fillColor}
        d="M43.66,6.19A3.42,3.42,0,0,0,40.23,6c-.87.55-.76,1.64.32,2.43a3.22,3.22,0,0,0,3.56.24c.85-.66.62-1.75-.45-2.43"
      />
      <path
        fill={fillColor}
        d="M37.45,2.74a4.65,4.65,0,0,0-3.28-.1c-.87.38-.86,1.14.12,1.7a3.9,3.9,0,0,0,3.44.1c.87-.5.7-1.27-.28-1.7"
      />
      <path
        fill={fillColor}
        d="M32.14.39a10.71,10.71,0,0,0-3,0c-.87.15-1,.53-.17.83a5.52,5.52,0,0,0,3.17,0c.88-.31.82-.69,0-.83"
      />
      <path
        fill={fillColor}
        d="M44.54,18.5a2.53,2.53,0,0,0,.22,3.67,2.75,2.75,0,0,0,3.81.19,2.55,2.55,0,0,0-.28-3.63,2.77,2.77,0,0,0-3.75-.23"
      />
      <path
        fill={fillColor}
        d="M41.58,12.76a3.06,3.06,0,0,0-3.78-.16A2.11,2.11,0,0,0,38,15.9a3,3,0,0,0,3.88.14,2.11,2.11,0,0,0-.25-3.28"
      />
      <path
        fill={fillColor}
        d="M35.06,7.75a3.39,3.39,0,0,0-3.66,0,1.62,1.62,0,0,0,0,2.76,3.24,3.24,0,0,0,3.78,0,1.61,1.61,0,0,0-.11-2.75"
      />
      <path
        fill={fillColor}
        d="M25.47,6.21A3.57,3.57,0,0,0,29,6.11c1-.66,1-1.6.11-2.08a3.93,3.93,0,0,0-3.41.1c-1,.54-1.17,1.48-.26,2.08"
      />
      <path
        fill={fillColor}
        d="M24.14,1.79A5.79,5.79,0,0,0,21.07,2c-1,.35-1.3,1-.57,1.33a4.25,4.25,0,0,0,3.23-.22c1-.49,1.15-1.07.41-1.3"
      />
      <path
        fill={fillColor}
        d="M34.8,25.15a3,3,0,0,0,4.1.05,2.64,2.64,0,0,0-.1-3.86,3,3,0,0,0-4-.07,2.64,2.64,0,0,0,0,3.88"
      />
      <path
        fill={fillColor}
        d="M32,18.77a2.3,2.3,0,0,0,0-3.53,3.08,3.08,0,0,0-3.93,0A2.29,2.29,0,0,0,28,18.79a3.06,3.06,0,0,0,4,0"
      />
      <path
        fill={fillColor}
        d="M25.76,10.09a3.16,3.16,0,0,0-3.7.16,1.89,1.89,0,0,0-.27,3,3.07,3.07,0,0,0,3.8-.15,1.88,1.88,0,0,0,.17-3"
      />
      <path
        fill={fillColor}
        d="M16.42,8.88a3.11,3.11,0,0,0,3.47-.3c1.09-.81,1.24-1.9.42-2.44A3.32,3.32,0,0,0,17,6.45c-1.07.7-1.34,1.79-.53,2.43"
      />
      <path
        fill={fillColor}
        d="M31.36,35.41a3,3,0,1,0,0-4.18,2.94,2.94,0,0,0,0,4.18"
      />
      <path
        fill={fillColor}
        d="M24.45,28.54a3,3,0,0,0,4.13,0,2.78,2.78,0,0,0,0-4,3,3,0,0,0-4.09.06,2.76,2.76,0,0,0-.08,4"
      />
      <path
        fill={fillColor}
        d="M18,22.19A2.85,2.85,0,0,0,21.93,22a2.51,2.51,0,0,0,.17-3.69,2.88,2.88,0,0,0-3.86.18A2.52,2.52,0,0,0,18,22.19"
      />
      <path
        fill={fillColor}
        d="M16,16.38c1.11-1,1.25-2.48.37-3.25a2.76,2.76,0,0,0-3.5.35,2.2,2.2,0,0,0-.45,3.22A2.69,2.69,0,0,0,16,16.38"
      />
      <path
        fill={fillColor}
        d="M11.55,9.06c-.7-.57-2.07-.34-3.1.51s-1.34,2-.68,2.7,2.11.43,3.17-.5,1.31-2.14.61-2.71"
      />
      <path
        fill={fillColor}
        d="M25.07,34.72a2.87,2.87,0,0,0-4.11,0,3,3,0,0,0,0,4.16,2.88,2.88,0,0,0,4.12,0,3,3,0,0,0,0-4.19"
      />
      <path
        fill={fillColor}
        d="M14.41,32.12A2.7,2.7,0,0,0,18.32,32a2.92,2.92,0,0,0,.1-4,2.7,2.7,0,0,0-3.88.13,2.91,2.91,0,0,0-.13,4"
      />
      <path
        fill={fillColor}
        d="M12.19,25.66a2.75,2.75,0,0,0,.25-3.75,2.42,2.42,0,0,0-3.5.3,2.76,2.76,0,0,0-.3,3.68,2.42,2.42,0,0,0,3.55-.23"
      />
      <path
        fill={fillColor}
        d="M7.44,16.75c-.74-.76-2.1-.52-3.05.5a2.6,2.6,0,0,0-.5,3.28c.69.81,2.08.63,3.09-.43s1.2-2.59.46-3.35"
      />
      <path
        fill={fillColor}
        d="M21.67,45.14a2.81,2.81,0,0,0-4-.07,2.91,2.91,0,0,0,.11,4,2.82,2.82,0,0,0,4,.1,2.9,2.9,0,0,0-.08-4.07"
      />
      <path
        fill={fillColor}
        d="M8.83,35.58a3.06,3.06,0,0,0,.09-3.95,2.18,2.18,0,0,0-3.38.15,3.1,3.1,0,0,0-.11,3.86,2.18,2.18,0,0,0,3.4-.06"
      />
      <path
        fill={fillColor}
        d="M3.56,29.39a3,3,0,0,0,.27-3.67c-.76-.91-2-.72-2.88.35a3.13,3.13,0,0,0-.28,3.58c.68.92,2,.84,2.89-.26"
      />
      <path
        fill={fillColor}
        d="M86.84,16.31h24.31c8.39,0,16,2.39,16,11.87s-7.64,11.92-16,11.92H91.87V57.79h-5Zm5,19.34h18c8.33,0,12.55-1.77,12.55-7.42s-4.22-7.47-12.55-7.47h-18Z"
      />
      <path fill={fillColor} d="M154.91,16.31h5v37h32.75v4.45H154.91Z" />
      <path
        fill={fillColor}
        d="M233.64,16.31h6.16l21.28,41.48h-5.65L250.07,47H223.31l-5.42,10.78H212.3Zm14.21,26.25L236.72,20.25,225.59,42.56Z"
      />
      <path
        fill={fillColor}
        d="M288.06,16.31h7.13l30.87,34.12V16.31h5V57.79h-5l-33.21-37v37h-4.79Z"
      />
      <path
        fill={fillColor}
        d="M375.18,20.76h-20V16.31h45.08v4.45h-20v37h-5Z"
      />
    </svg>
  );
}
