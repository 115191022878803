const rounderOf = (digits: number) => {
  const rounder = Math.pow(10, digits);

  const roundNumber = (n: number) => Math.round(n * rounder) / rounder;

  const roundValue = <T>(val: T, recursionsLeft: number): T => {
    if (recursionsLeft <= 0) {
      return val;
    }
    if (val == null) {
      return val;
    }
    if (Array.isArray(val)) {
      // @ts-ignore
      return val.map((e) => roundValue(e, recursionsLeft - 1));
    }
    switch (typeof val) {
      case "number":
        // @ts-ignore
        return roundNumber(val);
      case "object":
        // @ts-ignore
        return Object.entries(val).reduce((m, [k, v]) => {
          // @ts-ignore
          m[k] = roundValue(v, recursionsLeft - 1);
          return m;
        }, {});
      default:
        return val;
    }
  };

  return <T>(val: T): T => roundValue(val, 4);
};

export default rounderOf;

/* 
Följande kolumner i rapportens tabeller ska vi visa exakt 2 decimaler:

- kg CO₂e/BTA
- kg CO₂e/FU
- Avrunda små värden uppåt: 0.00000033 = 0.01.
*/
export const rounderTwo = (num: number) => {
  if (num == undefined) {
    return "N/A";
  }

  const sign = Math.sign(num);
  if (Math.abs(num) < 0.01) {
    return sign * 0.01;
  }
  return Number(num.toFixed(2));
};

/* 
Följande kolumner i rapportens tabeller ska vi visa med heltal:

- Mängd
- Vikt, kg/BTA
- Vikt material kg/BTA
- Avrunda små värden uppåt: 0.03 = 1.
*/
export const rounderInt = (num: number) => {
  if (num == undefined) {
    return "N/A";
  }
  const sign = Math.sign(num);
  if (Math.abs(num) < 1) {
    return sign * 1;
  }
  return Math.round(num);
};
