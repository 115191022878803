import { Box } from "@material-ui/core";
import { orderBy } from "lodash";

import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { IReportTableProps, ReportTable } from "../components/table";
import { ReportTypography } from "../components/report-typography";
import { omitRedundantFields } from "./report-gwp-summary-page";
import { bsabCodes } from "../bsab-codes";
import useDsrQueryHook from "../hooks/useDsrQueryHook";

import { ReportPageProps } from "../../report-page";
import { rounderInt, rounderTwo } from "../../../lib/rounder";

function removeBIMEntityTypeFromName(buildingElementName: string): string {
  return buildingElementName.includes(":")
    ? buildingElementName.split(":")[1]
    : buildingElementName;
}

export default function ReportBuildingElementPage({
  tmplVars,
  pageNumber,
  projectId,
  scenarioId,
  selectedDatasetIds,
}: ReportPageProps) {
  const chapter = "Resultat";
  const pageTitle = "Byggdelar med högst påverkan";

  const dsrs = useDsrQueryHook({
    filter: [{ NOT: { BuildingElementName: "is_null" } }],
    group: [
      { col: "BuildingElementName" },
      { col: "BuildingElementUnit" },
      { col: "ProductBSAB" },
    ],
    aggregations: [
      { col: "A1_A5", op: "sum", reduceAggOp: "sum" },
      {
        col: "BuildingElementAmount",
        op: "sum",
        withinGuidOp: "max",
        reduceAggOp: "sum",
      },
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
    ],
    postProcessing: [
      {
        as: "A1_A5PerBuildingElementAmount",
        reduceAggOp: "sum",
        raw: {
          op: "div",
          left: { col: "A1_A5", lenient: false },
          right: { col: "BuildingElementAmount", lenient: false },
        },
      },
    ],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 30,
  });

  const rows = omitRedundantFields(dsrs.data);

  const columns: IReportTableProps["columns"] = [
    {
      label: "Byggdel",
      value: "BuildingElementName",
      align: "left",
    },
    {
      label: "BSAB",
      value: "ProductBSAB",
      align: "left",
    },
    {
      label: "Mängd",
      align: "right",
      value: "BuildingElementAmount",
    },
    {
      label: "kg CO₂e/FU",
      align: "right",
      value: "A1_A5PerBuildingElementAmount",
    },
    {
      label: `kg CO₂e/${tmplVars.BTA.inUnit}`,
      align: "right",
      value: "A1_A5PerBTA",
    },
  ];

  const isBsabProductionResult = /^[A-Z]+/.test(rows?.[0]?.ProductBSAB);

  const orderdRows = orderBy(
    rows.slice(0, rows.length - 1).map((r) => ({
      ...r,
      ProductBSAB: (r.ProductBSAB ?? "").slice(
        0,
        (r.ProductBSAB ?? "").indexOf(".") === 3 ? 3 : 4
      ),
    })),
    isBsabProductionResult ? ["A1_A5PerBTA"] : ["ProductBSAB", "A1_A5PerBTA"],
    isBsabProductionResult ? ["desc"] : ["asc", "desc"]
  ).map((r) => ({
    ...r,
    BuildingElementAmount: `${rounderInt(
      r.BuildingElementAmount || 0
    ).toLocaleString("fr")} ${r.BuildingElementUnit}`,
    ProductBSAB: bsabCodes[r.ProductBSAB]
      ? `${r.ProductBSAB} - ${bsabCodes[r.ProductBSAB]}`
      : r.ProductBSAB
      ? `${r.ProductBSAB} - Saknar beskrivning`
      : "-",
    BuildingElementName: removeBIMEntityTypeFromName(r.BuildingElementName),
    A1_A5PerBTA: rounderTwo(r.A1_A5PerBTA),
    A1_A5PerBuildingElementAmount: rounderTwo(r.A1_A5PerBuildingElementAmount),
  }));

  return (
    <ReportContainer>
      <ReportHeader chapter={chapter} />
      <ReportBodyAlignSpaceBetween>
        <Box mt={5} mb={1} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>
        <Box flexGrow={1}>
          <ReportTable
            showSumRow={false}
            columns={columns}
            hasRawMaterials={true}
            tableCaption={`Kilmatpåverkan från projektet, kg CO₂e/${tmplVars.BTA.inUnit}`}
            rows={orderdRows}
          />
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} skipPaddingTop />
    </ReportContainer>
  );
}
