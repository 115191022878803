import { Box } from "@material-ui/core";
import { ReportContainer } from "../../../report/components/report-container";
import { useTheme, Theme } from "@material-ui/core/styles";
import { SingleReportPage } from "./index";
import { DefaultTheme } from "../../../../providers/styling";

const GiveThanksPage: SingleReportPage = () => {
  const theme: Theme = useTheme<DefaultTheme>();

  return (
    <ReportContainer background={theme.palette.primary.main}>
      <Box
        height={940}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box mb={4} textAlign="center" color="white">
          <Box mb={2}></Box>
        </Box>
      </Box>
      <Box mb={10} display="flex" justifyContent="center">
        <img
          src={
            "https://storage.googleapis.com/public-plant/app-assets/report/Plant-logo-utan-slogan-vit.png"
          }
          width={200}
        />
      </Box>
    </ReportContainer>
  );
};

export default GiveThanksPage;
