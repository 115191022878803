import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useToast } from "@chakra-ui/react";
import _ from "lodash";
import {
  Box,
  Fade,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSimplifiedTenantAdaptionSetScenarioRowMutation } from "../../lib/generated/graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textFieldSmall: {
      width: "102.5px",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    textField: {
      width: 340,
      paddingRight: theme.spacing(1),
    },
    hover: {
      "&:hover": {
        background: theme.palette.action.hover,
      },
    },
  })
);

export const parseNum = (val: string): number | undefined => {
  if (val.includes(",")) {
    return Number(val.replace(".", "").replace(",", "."));
  } else {
    return Number(val);
  }
};

const DataField: React.FC<{
  projectId: string;
  scenarioId: string;
  columnKey: string;
  rowKey: string;
  label: string;
  buildingElementAmount?: number;
  unit: string;
  onChange?: () => void;
  disabled: boolean;
}> = ({
  onChange,
  projectId,
  scenarioId,
  label,
  columnKey,
  rowKey,
  buildingElementAmount,
  unit,
  disabled,
}) => {
  const toast = useToast();
  const classes = useStyles();
  const [showSaveSuccessful, setShowSaveSuccessful] = useState(false);
  const [displayVal, setDisplayVal] = useState(
    `${buildingElementAmount ?? ""}`
  );
  const [error, setError] = useState(false);
  const [setRowAmount, { loading: saving }] =
    useSimplifiedTenantAdaptionSetScenarioRowMutation({
      onCompleted: () => {
        if (onChange) {
          onChange();
        }
        setShowSaveSuccessful(true);
        setTimeout(() => {
          setShowSaveSuccessful(false);
        }, 2000);
      },
      onError: () => {
        toast({
          title: "Något gick fel",
          description: `Något gick fel när vi skulle spara`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    });
  useEffect(
    () => setDisplayVal(`${buildingElementAmount ?? ""}`),
    [buildingElementAmount, scenarioId]
  );
  const saveBuildingElementAmount = useMemo(
    () =>
      _.debounce(
        async (
          vars: {
            projectId: string;
            scenarioId: string;
            columnKey: string;
            rowKey: string;
          },
          val?: number
        ) => {
          if (val === buildingElementAmount) {
            return;
          }
          await setRowAmount({
            variables: {
              ...vars,
              buildingElementAmount: val,
            },
          });
        },
        2000,
        { trailing: true }
      ),
    [setRowAmount, buildingElementAmount, scenarioId, columnKey, rowKey]
  );

  const handleChange = useCallback(
    async (val: string) => {
      setDisplayVal(val);
      const num = parseNum(val);
      if (!Number.isFinite(num) && val !== "") {
        setError(true);
        return;
      }
      setError(false);
      const newBeAmount = val === "" ? undefined : num;
      await saveBuildingElementAmount(
        { projectId, scenarioId, columnKey, rowKey },
        newBeAmount
      );
    },
    [saveBuildingElementAmount, scenarioId, projectId, columnKey, rowKey]
  );

  return (
    <Box>
      <Box position="absolute" left={-60} top={18}>
        <Fade in={showSaveSuccessful} timeout={{ enter: 500, exit: 1000 }}>
          <Box color="success">
            <Typography color="primary" variant="body2">
              {"Sparad!"}
            </Typography>
          </Box>
        </Fade>
      </Box>
      <TextField
        className={classes.textField}
        label={label}
        disabled={disabled || saving}
        value={displayVal}
        variant="outlined"
        margin="dense"
        error={error}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">{unit}</InputAdornment>
          ),
        }}
        onChange={async (event) => {
          event.preventDefault();
          await handleChange(event.target.value);
        }}
        onBlur={async (event) => {
          event.preventDefault();
          await saveBuildingElementAmount.flush();
        }}
      />
    </Box>
  );
};

export default DataField;
