import { Box, Paper, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { PreliminaryRibbon } from "./PreliminaryRibbon";

interface IReportContainerProps {
  children?: React.ReactNode;
  background?: string;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reportPaper: {
      width: "794px",
      height: "1122px",
      padding: "32px 70px",
      position: "relative",
      pageBreakAfter: "always",
      overflow: "hidden",
      marginBottom: 25,

      "@media print": {
        marginBottom: 0,
      },
    },
  })
);

export function ReportContainer({
  children,
  background,
  style,
}: IReportContainerProps) {
  const classes = useStyles();
  const matchesPrint = useMediaQuery("print");

  return (
    <Box
      fontFamily="'Quicksand', sans-serif"
      style={{ position: "relative" }}
      textAlign="left"
    >
      {!matchesPrint ? (
        <Paper
          elevation={2}
          className={classes.reportPaper}
          style={{ background, ...style }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            {children}
          </Box>
        </Paper>
      ) : (
        <Box
          display="flex"
          className={classes.reportPaper}
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          style={{ background, ...style }}
        >
          {children}
        </Box>
      )}
      <PreliminaryRibbon />
    </Box>
  );
}
