import { PieChart, Pie, Cell, Legend, Label } from "recharts";
import { chartColorArray } from "./chart-colors";
import { grey } from "@material-ui/core/colors";
import {
  createStyles,
  getContrastRatio,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

interface IReportPieChartProps {
  data: { name: string; value: number }[];
  nameIndex?: string[];
  centerLabel?: string;
}

function shortenText(text: string) {
  return text.length > 23 ? `${text.slice(0, 20)}...` : text;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pieChart: {
      "& .recharts-legend-item-text": {
        fontSize: 10,
      },
    },
  })
);

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const filColor =
    (getContrastRatio(grey[400], chartColorArray[index]) > 2.5 && grey[400]) ||
    (getContrastRatio(grey[700], chartColorArray[index]) > 2.5 && grey[700]) ||
    (getContrastRatio(grey[900], chartColorArray[index]) > 2.5 && grey[900]) ||
    (getContrastRatio(grey[50], chartColorArray[index]) > 2.5 && grey[50]) ||
    grey[700];
  return (
    <text
      fontSize={9}
      fontWeight={600}
      x={x}
      y={y}
      fill={filColor}
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export function ReportPieChart(props: IReportPieChartProps) {
  const classes = useStyles();

  return (
    <PieChart width={340} height={150} className={classes.pieChart}>
      <Legend layout="vertical" verticalAlign="top" align="right" />
      <Pie
        data={props.data.map((d: any) => ({
          ...d,
          name: shortenText(d.name || ""),
        }))}
        isAnimationActive={false}
        dataKey="value"
        labelLine={false}
        nameKey="name"
        label={renderCustomizedLabel}
        innerRadius={40}
        startAngle={90}
        endAngle={450}
        outerRadius={70}
        blendStroke
        legendType="circle"
        fill="#82ca9d"
      >
        {props.data.map(({ name }, i) => {
          return (
            <Cell
              key={name}
              fill={
                props.nameIndex
                  ? chartColorArray[
                      props.nameIndex.findIndex((nameKey) => nameKey === name)
                    ]
                  : chartColorArray[i]
              }
            />
          );
        })}
        {props.centerLabel && (
          <Label
            value={props.centerLabel}
            fontWeight="bold"
            fontSize={9}
            position="center"
          />
        )}
      </Pie>
    </PieChart>
  );
}
