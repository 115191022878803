import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { lighten, makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import { useTheme } from "@material-ui/styles";
import { darken, Theme } from "@material-ui/core";
import { DefaultTheme } from "../../providers/styling";

const useStyles = makeStyles({
  columnCell: {
    fontWeight: 600,
    padding: "2px 4px",
    borderBottom: `2px solid ${grey[300]}`,
    whiteSpace: "nowrap",
  },
  bodyCell: {
    padding: "2px 4px",
    borderLeft: `1px solid ${grey[200]}`,
    "&:last-child": {
      borderRight: `1px solid ${grey[200]}`,
    },
  },
  table: {},
});

interface ISimpleTableProps {
  rows: {
    id: string;
    onClick?: () => void;
    isSelected?: boolean;
    cells: React.ReactNode[];
  }[];
  columns: React.ReactNode[];
}

export function SimpleTable(props: ISimpleTableProps) {
  const classes = useStyles();
  const theme: Theme = useTheme<DefaultTheme>();

  return (
    <Table className={classes.table} stickyHeader>
      <TableHead>
        <TableRow>
          {props.columns.map((column, colItter) => {
            return (
              <TableCell
                className={classes.columnCell}
                key={colItter}
                style={{ background: grey[200], zIndex: 0 }}
              >
                {column}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.rows.map((row) => {
          return (
            <TableRow
              key={row.id}
              hover
              onClick={() => row.onClick && row.onClick()}
              style={{
                cursor: row.onClick ? "pointer" : "",
                background: row.isSelected
                  ? lighten(theme.palette.primary.dark, 0.8)
                  : "",
              }}
            >
              {row.cells.map((cell, celItter) => {
                if (typeof cell === "function") {
                  // @ts-ignore
                  return cell({ key: celItter, className: classes.bodyCell });
                }

                return (
                  <TableCell key={celItter} className={classes.bodyCell}>
                    {cell}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
