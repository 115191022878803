import { Box } from "@chakra-ui/react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useGlobalState } from "../../../providers/GlobalStateProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bookmark: {
      position: "absolute",
      top: "0",
      right: "1em",
      height: "auto",
      width: "2em",
      backgroundColor: "red",
      boxSizing: "border-box",
      backgroundImage:
        "linear-gradient( hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.5) 5px, hsla(0, 0%, 100%, 0) 25px)",
      filter: "drop-shadow(0 2px 2px hsla(0, 0%, 0%, 0.5))",
      color: "white",
      fontSize: "12pt",
      textShadow: "0 -1px 0 hsla(0, 0%, 0%, 0.5)",
      writingMode: "vertical-lr",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "15px",
      paddingBottom: "10px",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    reportPaper: {
      width: "210mm",
      height: "296.8mm",
      size: "210mm 296.8mm",
      padding: "32px 70px",
      boxSizing: "border-box",
      position: "relative",
      pageBreakAfter: "always",
      overflow: "hidden",
      marginBottom: 25,

      "@media print": {
        marginBottom: 0,
      },
    },
  })
);

export const PreliminaryRibbon = () => {
  const classes = useStyles();
  const state = useGlobalState();

  if (!state.isPreliminaryReport) {
    return null;
  }

  return <Box className={classes.bookmark}>Preliminär</Box>;
};
