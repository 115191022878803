import { useEffect, useState, useMemo } from "react";
import {
  ApolloClient,
  NormalizedCacheObject,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useGlobalDispatch } from "../GlobalStateProvider";
import { ActionType, AuthState } from "../GlobalStateProvider/types";
import { auth } from "../../lib/firebase";

import { useLocationApiKey } from "./useLocationApiKey";
import { useApolloRequestHandler } from "./useApolloRequestHandler";
import { useApolloAuthKey } from "./useApolloAuthKey";

export interface ApolloClientHook {
  isReady: boolean;
  apolloClient?: ApolloClient<NormalizedCacheObject>;
  authState?: AuthState;
}

export const useApolloClient = (): ApolloClientHook => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const [authState, setAuthState] = useState<AuthState>();

  const apiKey = useLocationApiKey();
  const dispatch = useGlobalDispatch();
  const cache = useMemo(() => new InMemoryCache(), []);
  const getAuthKey = useApolloAuthKey();
  const requestHandler = useApolloRequestHandler();

  useEffect(() => {
    const authLink = setContext(async (_, { headers }) => ({
      headers: {
        ...headers,
        authorization: await getAuthKey(),
      },
    }));

    const apolloClient = new ApolloClient({
      cache,
      link: requestHandler(authLink, getAuthKey),
    });

    const setStates = (state: AuthState, ready = false) => {
      if (ready) {
        setIsReady(true);
      }
      setAuthState(state);
      dispatch({ type: ActionType.SET_AUTH_STATE, payload: state });
    };

    if (apiKey) {
      dispatch({
        type: ActionType.SET_AUTH_STATE,
        payload: AuthState.LOGGED_IN,
      });
      setIsReady(true);
    } else {
      auth.onAuthStateChanged((user) => {
        if (user?.email != null) {
          setStates(AuthState.LOGGING_IN);
          dispatch({
            type: ActionType.SET_EMAIL_VERIFICATION_REQUIRED,
            payload: false,
          });
        } else {
          setStates(AuthState.LOGGING_OUT);
        }

        apolloClient
          .resetStore()
          .catch()
          .finally(() => {
            if (user?.email != null) {
              setStates(AuthState.LOGGED_IN, true);
            } else {
              setStates(AuthState.LOGGED_OUT, true);
            }
          });
      });
    }

    setClient(apolloClient);
  }, []);

  return {
    isReady: !!client && isReady,
    apolloClient: client,
    authState,
  };
};
