import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import {
  DeclarationStatus,
  LcaSystemBoundary as ILcaSystemBoundary,
  Project,
  useProjectQuery,
  useSetLcaSystemBoundaryMutation,
} from "../generated/graphql";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { DefaultTheme } from "../../providers/styling";

enum LCA_STAGES {
  ProductStage = "productStage",
  AssemblyStage = "assemblyStage",
  UseStage = "useStage",
  EndOfLife = "endOfLife",
  BeyondSystemBoundaries = "beyondSystemBoundaries",
}

const LCAStageInformation = {
  [LCA_STAGES.ProductStage]: { groupName: "Produktskede" },
  [LCA_STAGES.AssemblyStage]: { groupName: "Byggprod. skede" },
  [LCA_STAGES.UseStage]: { groupName: "Användningsskede" },
  [LCA_STAGES.EndOfLife]: { groupName: "Slutskede" },
  [LCA_STAGES.BeyondSystemBoundaries]: {
    groupName: "Tillägsinfo",
  },
};

interface ILCAModuleInformation {
  key: string;
  name: string;
  stage: string;
  value?: DeclarationStatus;
}

const LCAModuleInformation = {
  A1: { key: "A1", stage: LCA_STAGES.ProductStage, name: "Råvaruförsörjning" },
  A2: { key: "A2", stage: LCA_STAGES.ProductStage, name: "Transport" },
  A3: { key: "A3", stage: LCA_STAGES.ProductStage, name: "Tillverkning" },
  A4: { key: "A4", stage: LCA_STAGES.AssemblyStage, name: "Transport" },
  A5: {
    key: "A5",
    stage: LCA_STAGES.AssemblyStage,
    name: "Bygg & Installationsproc.",
  },
  B1: { key: "B1", stage: LCA_STAGES.UseStage, name: "Användning" },
  B2: { key: "B2", stage: LCA_STAGES.UseStage, name: "Underhåll" },
  B3: { key: "B3", stage: LCA_STAGES.UseStage, name: "Reparation" },
  B4: { key: "B4", stage: LCA_STAGES.UseStage, name: "Utbyte" },
  B5: { key: "B5", stage: LCA_STAGES.UseStage, name: "Ombyggnad" },
  B6: { key: "B6", stage: LCA_STAGES.UseStage, name: "Driftsenergi" },
  B7: {
    key: "B7",
    stage: LCA_STAGES.UseStage,
    name: "Driftens vattenanvändning",
  },
  C1: { key: "C1", stage: LCA_STAGES.EndOfLife, name: "Demonterning, rivning" },
  C2: { key: "C2", stage: LCA_STAGES.EndOfLife, name: "Transport" },
  C3: {
    key: "C3",
    stage: LCA_STAGES.EndOfLife,
    name: "Restproduktsbehandling",
  },
  C4: { key: "C4", stage: LCA_STAGES.EndOfLife, name: "Bortskaffning" },
  D: {
    key: "D",
    stage: LCA_STAGES.BeyondSystemBoundaries,
    name: "Fördelar och belastningar utanför systemgränsen",
  },
};

const defaultLCASystemBoundary = {
  A1: "DECLARED" as DeclarationStatus,
  A2: "DECLARED" as DeclarationStatus,
  A3: "DECLARED" as DeclarationStatus,
  A4: "DECLARED" as DeclarationStatus,
  A5: "DECLARED" as DeclarationStatus,
  B1: "NOT_DECLARED" as DeclarationStatus,
  B2: "NOT_DECLARED" as DeclarationStatus,
  B3: "NOT_DECLARED" as DeclarationStatus,
  B4: "NOT_DECLARED" as DeclarationStatus,
  B5: "NOT_DECLARED" as DeclarationStatus,
  B6: "NOT_DECLARED" as DeclarationStatus,
  B7: "NOT_DECLARED" as DeclarationStatus,
  C1: "NOT_DECLARED" as DeclarationStatus,
  C2: "NOT_DECLARED" as DeclarationStatus,
  C3: "NOT_DECLARED" as DeclarationStatus,
  C4: "NOT_DECLARED" as DeclarationStatus,
  D: "NOT_RELEVANT" as DeclarationStatus,
};

const LcaStatusDislayName = {
  [DeclarationStatus.Declared]: "X",
  [DeclarationStatus.NotDeclared]: "MND",
  [DeclarationStatus.NotRelevant]: "MNR",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lcaGroupWrapper: {
      border: "1px solid grey",
      borderLeft: "none",
      "&:first-child": {
        borderLeft: "1px solid grey",
      },
    },
    lcaGroupTitle: {
      background: theme.palette.primary.light,
      textAlign: "center",
      // maxWidth: 130,
      width: "100%",
      height: 48,
      fontSize: 14,
      fontWeight: "bold",
      color: "white",
      // margin: 'auto',
      verticalAlign: "middle",
      // margin: `${theme.spacing(1)}px`
    },
    // lcaGroup: {
    // 	padding: `${theme.spacing(6)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px `
    // },

    twistedTitleWrapper: {
      height: 175,
      background: lighten(theme.palette.primary.light, 0.7),
      borderBottom: "1px solid grey",
    },
    twistedTitle: {
      display: "flex",
      alignItems: "center",
      transform: "rotate(-90deg) translate(-164px)",
      transformOrigin: "top left",
      height: 50,
      whiteSpace: "nowrap",
      // background: theme.palette.primary.light
    },
    shortCode: {
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
      textAlign: "center",
    },
    lcaModule: {
      width: 44,
    },
  })
);

export function useLCASystemBoundaryForm(project?: Project) {
  const [updateLCASystemBoundary] = useSetLcaSystemBoundaryMutation();

  const [LCASystemBoundary, setLCASystemBoundary] = useState(
    defaultLCASystemBoundary
  );
  useEffect(() => {
    const projectBoundary = project?.LCASystemBoundary;
    if (projectBoundary) {
      setLCASystemBoundary(
        _.merge(
          LCASystemBoundary,
          _.omit(projectBoundary, ["id", "__typename"])
        )
      );
    }
  }, [project]);

  return {
    LCASystemBoundary,
  };
}

interface ILCASystemBoundaryForm {
  LCASystemBoundary: Omit<ILcaSystemBoundary, "id">;
}

export default function LCASystemBoundaryForm({
  LCASystemBoundary,
}: ILCASystemBoundaryForm) {
  const { lcaGroupTitle, lcaGroupWrapper } = useStyles();

  const LCAWithDescriptions = Object.entries(LCAModuleInformation).reduce(
    (acc, [key, val]: any) => {
      return {
        ...acc,
        [key]: { ...val, value: (LCASystemBoundary as any)[key] },
      };
    },
    {}
  );

  return (
    <Box display="flex" justifyContent="center">
      {Object.entries(LCAStageInformation).map(
        ([lcaStageKey, { groupName }]) => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={lcaGroupWrapper}
          >
            <Box
              className={lcaGroupTitle}
              display="flex"
              justifyContent="center"
              alignItems="center"
              px={2}
            >
              <Box>{groupName}</Box>
            </Box>
            <Box display="flex">
              {(Object.values(LCAWithDescriptions) as ILCAModuleInformation[])
                .filter(({ stage }) => stage === lcaStageKey)
                .map(({ name, key, value }) => (
                  <LCASystemBoundaryButtonGroup
                    lcaStageKey={lcaStageKey}
                    title={name}
                    shortCode={key}
                    isDeclared={value === "DECLARED"}
                  >
                    <div
                      style={{
                        ...(value === "DECLARED"
                          ? {
                              fontWeight: "bold",
                            }
                          : { opacity: 0.7 }),
                      }}
                    >
                      {value != null ? LcaStatusDislayName[value] : ""}
                    </div>
                  </LCASystemBoundaryButtonGroup>
                ))}
            </Box>
          </Box>
        )
      )}
    </Box>
  );
}

function LCASystemBoundaryButtonGroup(props: {
  title: string;
  shortCode: string;
  children: React.ReactNode;
  lcaStageKey: string;
  isDeclared: boolean;
}) {
  const { twistedTitle, twistedTitleWrapper, lcaModule, shortCode } =
    useStyles();
  const theme: Theme = useTheme<DefaultTheme>();

  return (
    <Box
      className={lcaModule}
      style={{
        width:
          (props.lcaStageKey === "assemblyStage" && 72) ||
          (props.lcaStageKey === "beyondSystemBoundaries" && 100) ||
          undefined,
        background: props.isDeclared
          ? lighten(theme.palette.primary.light, 0.7)
          : undefined,
      }}
    >
      <Box className={twistedTitleWrapper}>
        <Box
          className={twistedTitle}
          style={{
            ...(props.lcaStageKey === "assemblyStage"
              ? { height: 72 }
              : props.lcaStageKey === "beyondSystemBoundaries"
              ? { height: 100, whiteSpace: "normal" }
              : {}),
          }}
        >
          <Box>{props.title}</Box>
        </Box>
      </Box>
      <Box
        className={shortCode}
        style={{
          ...(props.isDeclared ? { fontWeight: "bold" } : { opacity: 0.7 }),
        }}
      >
        {props.shortCode}
      </Box>
      <Box textAlign="center" pb={1}>
        {props.children}
      </Box>
    </Box>
  );
}
