import { lighten, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box } from "@material-ui/core";
import { ReportTypography } from "./report-typography";
import { useTheme } from "@material-ui/styles";
import { grey } from "@material-ui/core/colors";
import { DefaultTheme } from "../../../providers/styling";
import { rounderInt, rounderTwo } from "../../../lib/rounder";
import { Flex } from "@chakra-ui/react";

const useStyles = makeStyles(() => ({
  table: {
    // minWidth: 650,
    maxWidth: 650,
    fontFamily: "'Trebuchet', sans-serif",
  },
  headerCell: {
    fontFamily: "'TrebuchetBold', sans-serif",
    borderBottom: "2px solid black",
    whiteSpace: "nowrap",
    fontSize: 12,
    padding: "0px 12px 0px 0px",
  },
  bodyCell: {
    maxWidth: 215,
    fontFamily: "'Trebuchet', sans-serif",
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingBottom: 4,
    paddingTop: 4,
    padding: "0px 12px 0px 0px",
  },
  sumCell: {
    maxWidth: 215,
    fontFamily: "'TrebuchetBold', sans-serif",
    borderTop: "2px solid black",
    borderBottom: "none",
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingBottom: 2,
    paddingTop: 6,
    fontWeight: 600,
    padding: "0px 12px 0px 0px",
  },
}));

export interface IReportColumns {
  label: string;
  value: string;
  align?: "inherit" | "left" | "center" | "right" | "justify" | undefined;
  width?: string | number;
}

export interface IReportTableProps {
  tableCaption?: string;
  tableUnit?: string;
  rows?: any;
  hasRawMaterials?: boolean;
  columns: IReportColumns[];
  showSumRow?: boolean;
  restRow?: any;
  sumRow?: any;
  borderBottomHeaderCell?: string;
  title?: string;
  totalSumIndex?: number;
}

const getTableCellStyling = ({
  column,
  borderBottomHeaderCell = "",
}: {
  column: IReportColumns;
  borderBottomHeaderCell?: string;
}) => {
  const style = {
    ...(column.align === "left"
      ? {
          padding: "0px 0px 0px 12px",
          ...(borderBottomHeaderCell
            ? { borderBottom: borderBottomHeaderCell }
            : {}),
        }
      : {
          width: "10%",
          ...(borderBottomHeaderCell
            ? { borderBottom: borderBottomHeaderCell }
            : {}),
        }),
    ...(column.width ? { width: column.width } : {}),
  };
  return style;
};

const getTableRowStyling = ({
  column,
  i,
  numColumns,
  theme,
}: {
  column: IReportColumns;
  i: number;
  numColumns: number;
  theme: any;
}) => {
  const style = {
    ...(column.align === "left"
      ? {
          padding: "0px 0px 0px 12px",
        }
      : {
          width: "10%",
          ...(i === numColumns - 1
            ? {
                background: lighten(theme.palette.primary.light, 0.85),
              }
            : {}),
        }),
    ...(column.width ? { width: column.width } : {}),
  };
  return style;
};

export function ReportTable({
  tableCaption,
  tableUnit,
  rows,
  columns,
  sumRow,
  restRow,
  borderBottomHeaderCell,
  title,
  totalSumIndex,
}: IReportTableProps) {
  const classes = useStyles();
  const theme: Theme = useTheme<DefaultTheme>();

  const numColumns = columns.length;

  const tableUnitComp = (
    <Flex alignItems="flex-end" justifyContent="flex-end" w="100%" mr={1.5}>
      {tableUnit ? (
        <ReportTypography variant="caption">{tableUnit}</ReportTypography>
      ) : (
        <Box height="16px" />
      )}
    </Flex>
  );

  return (
    <>
      {tableUnit === "none" || title ? null : tableUnitComp}
      {title && (
        <Box>
          <Box borderBottom={`3px solid ${grey[400]}`} pl={0} pr={5}>
            <ReportTypography variant="h6">{title}</ReportTypography>
          </Box>
          {tableUnit !== "none" && tableUnitComp}
        </Box>
      )}

      <Box mb={1}>
        <Table className={classes.table} aria-label="simple table">
          {columns && (
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  const [start, end] = column.label.split(" - ");

                  return (
                    <TableCell
                      key={column.label}
                      className={classes.headerCell}
                      style={getTableCellStyling({
                        column,
                        borderBottomHeaderCell,
                      })}
                      align={column.align}
                    >
                      {end ? end : start}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {rows.map((row: any, ind: number) => {
              return (
                <TableRow key={`row${ind}`}>
                  {columns.map((column, i) => {
                    const isNumber = typeof row[column.value] === "number";

                    const value = isNumber
                      ? row[column.value].toLocaleString("fr")
                      : row[column.value];

                    return (
                      <TableCell
                        key={column.label}
                        className={classes.bodyCell}
                        align={column.align}
                        style={getTableRowStyling({
                          column,
                          i,
                          numColumns,
                          theme,
                        })}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {restRow && (
              <TableRow key={restRow.id}>
                {columns.map((column, i) => {
                  const isNumber = typeof restRow[column.value] === "number";
                  const value = isNumber
                    ? (
                        Math.round(restRow[column.value] * 100) / 100
                      ).toLocaleString("fr")
                    : restRow[column.value];

                  return (
                    <TableCell
                      key={column.label}
                      className={classes.bodyCell}
                      align={column.align}
                      style={getTableRowStyling({
                        column,
                        i,
                        numColumns,
                        theme,
                      })}
                    >
                      {i === 0 ? "Övrigt" : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            )}

            {sumRow && (
              <TableRow key={sumRow.id}>
                {columns.map((column, i) => {
                  const isNumber = typeof sumRow[column.value] === "number";
                  const value = isNumber
                    ? (
                        Math.round(sumRow[column.value] * 100) / 100
                      ).toLocaleString("fr")
                    : sumRow[column.value];

                  return (
                    <TableCell
                      key={column.label}
                      className={classes.sumCell}
                      align={isNumber ? "right" : "left"}
                      style={
                        i === (totalSumIndex || 0)
                          ? { padding: "0px 0px 0px 12px" }
                          : { width: "10%" }
                      }
                    >
                      {i === (totalSumIndex || 0) ? "Totalsumma" : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      {tableCaption && (
        <Box>
          <ReportTypography
            variant="caption"
            style={{ whiteSpace: "pre-line" }}
          >
            {tableCaption}
          </ReportTypography>
        </Box>
      )}
    </>
  );
}
