import ReportPage, { SingleReportPage, SingleReportPageProps } from "./pages";
import ReportMethodPage from "../../report/pages/report-method-page";
import { ReportLcaModulesPageCommon as ReportLcaModulesPage } from "../../report/pages/report-lca-modules-page";
import { useSimplifiedTenantAdaptionProjectQuery } from "../../../lib/generated/graphql";
import IntroductionPage from "./pages/introduction-page";
import GwpSummaryPage from "./pages/gwp-summary-page";
import ScenarioPage from "./pages/scenario-page";
import { CircularProgress } from "@material-ui/core";
import FirstPage from "./pages/first-page";
import GiveThanksPage from "./pages/give-thanks-page";
import RawMaterialPages from "./pages/raw-material-pages";
import BuildingElementPages from "./pages/building-element-pages";

const PrintableReportComponent: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const { data: projectData, loading: loadingProject } =
    useSimplifiedTenantAdaptionProjectQuery({
      variables: { id: projectId },
    });
  if (loadingProject) {
    return <CircularProgress />;
  }
  const project = projectData?.simplifiedTenantAdaption?.project!;

  const scenPages = project.scenarios.map(
    (s) =>
      ((p: SingleReportPageProps) => (
        <ScenarioPage {...p} scenarioId={s.id} />
      )) as SingleReportPage
  );
  return (
    <ReportPage
      isPrint={true}
      pages={[
        FirstPage,
        IntroductionPage,
        (p: SingleReportPageProps) => (
          <ReportMethodPage {...p} reportType="simplified" />
        ),
        GwpSummaryPage,
        ...scenPages,
        BuildingElementPages,
        GiveThanksPage,
        ReportLcaModulesPage,
        RawMaterialPages,
      ]}
    />
  );
};

export default PrintableReportComponent;
