import { Legend, BarChart, YAxis, XAxis, Bar } from "recharts";
import { chartColorArray } from "./chart-colors";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    barChart: {
      "& .recharts-legend-item-text": {
        fontSize: 10,
      },
    },
  })
);

export function ReportBarChart(props: any) {
  const classes = useStyles();

  const barSize = 50;

  const CustomBarLabel = ({ x, y, value }: any) => {
    return (
      <text x={x + barSize / 2} y={y} fill="#777" dy={-6} textAnchor="middle">
        {value}
      </text>
    );
  };

  return (
    <BarChart
      width={315}
      height={150}
      data={props.data}
      className={classes.barChart}
    >
      <XAxis dataKey="name" />
      <YAxis width={40} />
      <Legend verticalAlign="top" height={40} />
      <Bar
        isAnimationActive={false}
        barSize={barSize}
        dataKey="Produktskede"
        fill={chartColorArray[0]}
        label={CustomBarLabel}
      />
      <Bar
        isAnimationActive={false}
        barSize={barSize}
        dataKey="Transportskede"
        fill={chartColorArray[1]}
        label={CustomBarLabel}
      />
      <Bar
        isAnimationActive={false}
        barSize={barSize}
        dataKey="Byggskede"
        fill={chartColorArray[2]}
        label={CustomBarLabel}
      />
    </BarChart>
  );
}
