import { useEffect, useMemo, useState } from "react";
import ReportPage from "./report-page";

import ScenarioMenu from "./report/components/scenario-pipeline-selector";
import ReportFirstPage from "./report/pages/report-first-page";
import ReportFunctionTypes from "./report/pages/report-function-types";
import ReportAbstractPage from "./report/pages/report-abstract-page";
import ReportIntroductionPage from "./report/pages/report-introduction-page";
import ReportIntroductionPageNew from "./report/pages/report-introduction-page-new";
import ReportIntroductionPageNewSBEF from "./report/pages/report-introduction-page-new-sbef";
import ReportMethodPage from "./report/pages/report-method-page";
import ReportGwpSummaryPage from "./report/pages/report-gwp-summary-page";

import ReportBsabDetailPage from "./report/pages/report-bsab-detail-page";

import ReportBuildingElementPage from "./report/pages/report-building-element-page";

import ReportGiveThanksPage from "./report/pages/report-give-thanks-page";
import ReportReferencesPage from "./report/pages/report-references-page";
import ReportLcaModulesPage from "./report/pages/report-lca-modules-page";
import ReportSBEFPage from "./report/pages/report-sbef-page";
import { ReportRawMaterialPages } from "./report/pages/report-raw-material-pages";
import { ReportBuildingElementPages } from "./report/pages/report-building-element-pages";
import ReportBsabSummaryPage from "./report/pages/report-bsab-summary-page";
import ReportBsabSingleDigitPage from "./report/pages/report-bsab-single-digit-sum";
import ReportBsabTwoDigitPage from "./report/pages/report-bsab-two-digit-sum";
import ReportBSABImagePage from "./report/pages/report-bsab-image-page";
import ReportVKPositionPage from "./report/pages/report-vk-position-page";
import {
  useProjectPipelinesQuery,
  useProjectQuery,
} from "../lib/generated/graphql";
import ReportPagesWidget from "./report-pages-widget";
import ReportScenariosBsabPage from "./report/pages/report-scenarios-bsab-page";
import ReportScenariosBsabDetailPage from "./report/pages/report-scenarios-bsab-detail-page";
import ReportScenariosGwpSummaryPage from "./report/pages/report-scenarios-gwp-summary-page";
import ReportScenariosIntroductionPage, {
  useQuery,
} from "./report/pages/report-scenarios-introduction-page";
import ReportBsabRawmaterialsPage from "./report/pages/report-bsab-rawmaterials-page";
import ReportBuildingElementCO2Page from "./report/pages/report-building-element-co2-page";
import ReportDictionaryPage from "./report/pages/report-dictionary";
import { useParams } from "react-router-dom";
import ClimateDeclarationPage from "./report/pages/report-climate-declaration-page";
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  useGlobalDispatch,
  useGlobalState,
} from "../providers/GlobalStateProvider";
import { ActionType } from "../providers/GlobalStateProvider/types";

import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import { Switch } from "@mui/material";
import { FormControlLabel } from "@material-ui/core";

export const reportPages = {
  "building-element-co2": ReportBuildingElementCO2Page,
  "first-page": ReportFirstPage,
  "abstract-page": ReportAbstractPage,
  "introduction-page": ReportIntroductionPage,
  "introduction-page-new": ReportIntroductionPageNew,
  "introduction-page-new-sbef": ReportIntroductionPageNewSBEF,
  "v-k-position-page": ReportVKPositionPage,
  "b-s-a-b-image-page": ReportBSABImagePage,
  "bsab-summary-page": ReportBsabSummaryPage,
  "bsab-single-digit-sum": ReportBsabSingleDigitPage,
  "bsab-two-digit-sum": ReportBsabTwoDigitPage,
  "bsab-detail-page": ReportBsabDetailPage,
  "bsab-rawmaterial": ReportBsabRawmaterialsPage,
  "gwp-summary-page": ReportGwpSummaryPage,
  "building-element-page": ReportBuildingElementPage,
  "introduction-scenarios-page": ReportScenariosIntroductionPage,
  "scenarios-gwp-summary-page": ReportScenariosGwpSummaryPage,
  "scenarios-bsab-page": ReportScenariosBsabPage,
  "scenarios-bsab-detail-page": ReportScenariosBsabDetailPage,
  "building-element-pages": ReportBuildingElementPages,
  "building-element-pages-not-simplified": ReportBuildingElementPages,
  "method-page": ReportMethodPage,
  "climate-declaration": ClimateDeclarationPage,
  dictionary: ReportDictionaryPage,
  "give-thanks-page": ReportGiveThanksPage,
  "references-page": ReportReferencesPage,
  "lca-modules-page": ReportLcaModulesPage,
  "s-b-e-f-page": ReportSBEFPage,
  "raw-material-pages": ReportRawMaterialPages,
  "function-types": ReportFunctionTypes,
};

const PipelineBenchmarkTable = () => {
  const params = useParams();
  const projectId = params.projectId!;
  const state = useGlobalState();
  const pipelines = useProjectPipelinesQuery({ variables: { projectId } }).data
    ?.project?.pipelines.nodes;

  const dispatch = useGlobalDispatch();

  const handleOnToggle = (id: string) => {
    if (state.benchmarkPipelineIds.includes(id)) {
      dispatch({
        type: ActionType.SET_BENCHMAKR_PIPELINE_IDS,
        payload: [
          ...state.benchmarkPipelineIds.filter((pipeId) => pipeId !== id),
        ],
      });
    } else {
      dispatch({
        type: ActionType.SET_BENCHMAKR_PIPELINE_IDS,
        payload: [...state.benchmarkPipelineIds, id],
      });
    }
  };

  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Bench</Th>
            <Th>Pipeline</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pipelines?.map((pipe) => (
            <Tr key={pipe.id} opacity={state.pipelineId === pipe.id ? 0.5 : 1}>
              <Td>
                <Checkbox
                  isChecked={state.benchmarkPipelineIds.includes(pipe.id)}
                  disabled={state.pipelineId === pipe.id}
                  onChange={() => handleOnToggle(pipe.id)}
                />
              </Td>
              <Td>{pipe.name}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const ReportTabs = ({
  isPrinting,
  setIsPrinting,
}: {
  isPrinting: boolean;
  setIsPrinting: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const state = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const params = useParams();
  const projectId = params.projectId;
  const project = useProjectQuery({
    skip: !projectId,
    variables: { projectId: projectId ?? "" },
  });
  // first check is needed to handle STA projects and second is to handle non initialized projectStatus
  useEffect(() => {
    dispatch({
      type: ActionType.SET_PRELIMINARY_REPORT,
      payload:
        project.data?.project != null &&
        project.data.project.projectStatus !== "COMPLETED",
    });
  }, [project]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (state.scenarioId) {
      setTabIndex(0);
    }
  }, [state.scenarioId]);

  return (
    <Tabs index={tabIndex} onChange={handleTabsChange}>
      <TabList>
        <Tab>Pages</Tab>
        <Tab isDisabled={!state.pipelineId}>Pipeline Benchmarks</Tab>
      </TabList>

      <TabPanels overflow={"auto"}>
        <TabPanel p={0} pt={2}>
          <ReportPagesWidget>
            <FormControlLabel
              control={
                <Switch
                  checked={state.isPreliminaryReport}
                  onClick={() =>
                    dispatch({
                      type: ActionType.SET_PRELIMINARY_REPORT,
                      payload: !state.isPreliminaryReport,
                    })
                  }
                />
              }
              label="Preliminär"
            />

            <Button
              rightIcon={
                <Icon as={isPrinting ? ToggleOnIcon : ToggleOffIcon} />
              }
              colorScheme={"green"}
              variant={isPrinting ? "solid" : "outline"}
              onClick={() => {
                setIsPrinting(!isPrinting);
              }}
            >
              Visa alla valda sidor
            </Button>
          </ReportPagesWidget>
        </TabPanel>

        {state.pipelineId && (
          <TabPanel
            p={0}
            pt={2}
            gap={4}
            display={"flex"}
            flexDirection="column"
          >
            <Flex flexDirection={"column"} gap={2}>
              <Heading size={"sm"}>Benchmark Pipeline</Heading>
              <PipelineBenchmarkTable />
            </Flex>
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

export default function ReportWidgetPages() {
  const { projectId } = useParams();

  const project = useProjectQuery({ variables: { projectId: projectId! } })
    ?.data?.project;
  const query = useQuery();
  const pageFromUrl = query.get("page") || "first-page";
  const isPdfGenerator = query.get("isPdfGenerator") === "true";
  const [renderAllPages, setIsPrinting] = useState(isPdfGenerator);
  const [isPrint] = useMediaQuery("print");

  return (
    <Grid
      bg={isPrint ? "transparent" : "gray.50"}
      templateColumns={isPrint ? "1fr" : "25rem 1fr"}
      templateRows={"1fr"}
      columnGap={4}
      height={"100%"}
    >
      <GridItem
        boxShadow="md"
        p={4}
        bg={"white"}
        display={isPrint ? "none" : "flex"}
        gap={4}
        flexDirection="column"
        sx={{
          "@media print": {
            display: "none",
          },
        }}
      >
        <Flex>
          <ScenarioMenu />
        </Flex>
        <ReportTabs isPrinting={renderAllPages} setIsPrinting={setIsPrinting} />
      </GridItem>

      <GridItem
        display={isPrint ? "block" : "flex"}
        flexDirection="column"
        alignItems={"center"}
        sx={{
          overflow: "scroll",
          p: 10,
          zoom: 0.85,
          "@media print": {
            zoom: 1,
            p: 0,
            overflow: "visible",
            alignItems: "unset",
          },
        }}
      >
        <ReportPage
          isPrint={isPdfGenerator}
          isFullPage={!isPdfGenerator}
          reportType={"lca"}
          // @ts-ignore
          pages={Object.entries(reportPages)
            .filter(([key]) => {
              if (!renderAllPages) {
                return key === pageFromUrl;
              }

              return project?.reportPages?.includes(key);
            })
            .map(([, value]) => value)}
        />
      </GridItem>
    </Grid>
  );
}
