import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { IReportColumns, ReportTable } from "../components/table";
import { ReportTypography } from "../components/report-typography";
import { omitRedundantFields } from "./report-gwp-summary-page";
import { bsabCodes } from "../bsab-codes";
import { groupBy } from "lodash";
import { sumFuncBsabPages } from "./report-scenarios-bsab-page";
import useScenariosQueries, {
  useGetBenchmarks,
} from "../hooks/scenario-queries";
import { ReportPageProps } from "../../report-page";

export function compareValue(
  a: { value: string },
  b: { value: string }
): number {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
}

export default function ReportScenariosBsabDetailPage({
  tmplVars,
  pageNumber,
  projectId,
}: ReportPageProps) {
  const chapter = "Resultat";
  const pageTitle = "Scenario – Detaljerad BSAB96-kod";
  const pipelinesOrScenarios = useGetBenchmarks(projectId);

  const limitNumRows = 28;
  const dsrs = useScenariosQueries({
    filter: [],
    group: [{ col: "ProductBSAB" }],
    aggregations: [{ col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" }],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 3000,
  });

  const rows = omitRedundantFields(dsrs.data).map(
    ({ weight, ProductBSAB = "-", ...row }: any) => ({
      ...row,
      scenarioName: pipelinesOrScenarios.find(({ id }) => id === row.id)?.name,
      ProductBSAB:
        (ProductBSAB && bsabCodes[ProductBSAB]) || "Saknar BSAB96-kod",
      ProductBSABCode: ProductBSAB,
    })
  );

  const columns: IReportColumns[] = [
    {
      label: "BSAB96-kod",
      value: "ProductBSABCode",
      width: "1%",
      align: "left",
    },
    {
      label: "Beskrivning",
      value: "ProductBSAB",
      width: "60%",
      align: "left",
    },
    ...Object.keys(groupBy(rows, "scenarioName"))
      .map((scenarioName) => scenarioName?.replace(/\+.*/, ""))
      .map((scenarioName) => ({
        label: `${scenarioName}`,
        value: scenarioName,
        align: "right" as const,
      }))
      .sort(compareValue),
  ];

  const rowsGroupedOnScenarioName = Object.values(
    groupBy(rows, "ProductBSABCode")
  )
    .map((bsabRows) => {
      return bsabRows
        .map(({ scenarioName, ...rest }) => ({
          scenarioName: scenarioName?.replace(/\+.*/, ""),
          ...rest,
        }))
        .reduce((acc, dsr) => {
          return {
            ...acc,
            ...dsr,
            [dsr.scenarioName]: dsr.A1_A5PerBTA,
          };
        }, {});
    })
    .sort((a: any, b: any) => b.A1_A5PerBTA - a.A1_A5PerBTA);

  const sumRow = {
    ...rowsGroupedOnScenarioName.reduce(sumFuncBsabPages, {}),
    ProductBSAB: "",
  };
  const restRow = {
    ...rowsGroupedOnScenarioName.slice(limitNumRows).reduce(sumFuncBsabPages, {
      A1_A5PerBTA: 0,
      ProductBSAB: "Övrigt",
      ProductBSABCode: "",
    }),
  };

  return (
    <ReportContainer>
      <ReportHeader chapter={chapter} />
      <ReportBodyAlignSpaceBetween>
        <Box mt={4} mb={1} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>

        <Box flexGrow={1}>
          <ReportTable
            columns={columns}
            hasRawMaterials={true}
            tableUnit={`kg CO₂e/${tmplVars.BTA.inUnit}`}
            rows={rowsGroupedOnScenarioName.slice(0, limitNumRows)}
            restRow={restRow}
            sumRow={{ ...sumRow, weight: (sumRow?.weight || 0) / 1000 }}
          />
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} />
    </ReportContainer>
  );
}
