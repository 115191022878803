import { Box, Button } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import MUTabs from "../../lib/components/mu-tabs";
import ScenarioDataTable from "./scenarioDataTable";
import React, { useMemo, useState } from "react";
import {
  SimplifiedTenantAdaptionTemplateData,
  SimplifiedTenantAdaptionScenario,
} from "../../lib/generated/graphql";
import CreateScenarioDialog from "./createScenarioDialog";
import EditScenarioDialog from "./editScenarioDialog";

const ScenariosComponent: React.FC<{
  projectId: string;
  disabled: boolean;
  onChange: () => void;
  mainScenarioId?: string;
  templateData: SimplifiedTenantAdaptionTemplateData;
  scenarios: SimplifiedTenantAdaptionScenario[];
}> = ({
  projectId,
  disabled,
  onChange,
  templateData,
  mainScenarioId,
  scenarios,
}) => {
  const [selectedScenarioId, setSelectedScenarioId] = useState<string | null>(
    scenarios[0]?.id ?? null
  );
  const selectedScenario = useMemo(
    () => scenarios.find(({ id }) => id === selectedScenarioId),
    [selectedScenarioId, scenarios]
  );

  const [isCreateScenarioOpen, setIsCreateScenarioOpen] = useState(false);
  const [isEditScenarioOpen, setIsEditScenarioOpen] = useState(false);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={grey[300]}
      >
        <MUTabs
          onChange={(id) => setSelectedScenarioId(id)}
          tabs={
            scenarios.map((s) => ({
              label: s.name || "",
              id: s.id,
              isSelected: s.id === mainScenarioId,
            })) ?? []
          }
          value={selectedScenarioId ?? ""}
        />
        <Box display="flex">
          {selectedScenario && (
            <Box mr={2}>
              <Button
                style={{ fontWeight: 600 }}
                variant="outlined"
                onClick={() => setIsEditScenarioOpen(true)}
              >
                {"Redigera scenario"}
              </Button>
            </Box>
          )}

          <Box mr={2}>
            <Button
              style={{ fontWeight: 600 }}
              variant="contained"
              color="primary"
              onClick={() => setIsCreateScenarioOpen(true)}
            >
              {"Nytt scenario"}
            </Button>
          </Box>
        </Box>
        <CreateScenarioDialog
          open={isCreateScenarioOpen}
          onClose={() => setIsCreateScenarioOpen(false)}
          onCreate={(id) => {
            setIsCreateScenarioOpen(false);
            setSelectedScenarioId(id);
          }}
          projectId={projectId}
          scenarioIdToCopy={selectedScenario?.id}
        />
        <EditScenarioDialog
          open={isEditScenarioOpen}
          onClose={() => setIsEditScenarioOpen(false)}
          onDelete={(deletedId: string) => {
            setSelectedScenarioId(
              scenarios.filter(({ id }) => id !== deletedId)[0]?.id ?? null
            );
            setIsEditScenarioOpen(false);
          }}
          projectId={projectId}
          scenario={selectedScenario as SimplifiedTenantAdaptionScenario}
        />
      </Box>
      {selectedScenario && (
        <ScenarioDataTable
          onChange={onChange}
          projectId={projectId}
          templateData={templateData}
          scenario={selectedScenario}
          disabled={disabled}
        />
      )}
    </Box>
  );
};

export default ScenariosComponent;
