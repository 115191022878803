import { Box } from "@material-ui/core";

interface IReportFooterProps {
  pageNumber?: number;
  whitePlantLogo?: boolean;
  skipPaddingTop?: boolean;
  paddingTop?: number;
}

export function ReportFooter(props: IReportFooterProps) {
  const plantLogoUrl = props.whitePlantLogo
    ? "https://storage.googleapis.com/public-plant/app-assets/report/Plant-logo-utan-slogan-vit.png"
    : "https://storage.googleapis.com/public-plant/app-assets/report/Plant-logo-utan-slogan-space-grey.svg";

  const paddingTop = props.paddingTop || (!props.skipPaddingTop && 5) || 0;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      pt={paddingTop}
      alignItems="flex-end"
    >
      <Box mb={0.5}>{props.pageNumber}</Box>
      <Box display="flex" alignItems="">
        <img src={plantLogoUrl} width={150} />
      </Box>
    </Box>
  );
}
