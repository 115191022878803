import { useGlobalDispatch } from "../../../providers/GlobalStateProvider";
import {
  ActionType,
  AuthState,
} from "../../../providers/GlobalStateProvider/types";
import { auth } from "../../firebase";

const useSignOut = () => {
  const dispatch = useGlobalDispatch();

  return (onSignedOut?: () => void) => {
    dispatch({
      type: ActionType.SET_AUTH_STATE,
      payload: AuthState.LOGGING_OUT,
    });
    auth.signOut().then(() => onSignedOut && onSignedOut());
  };
};

export default useSignOut;
