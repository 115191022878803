export enum ActionType {
  SET_USER = "SET_USER",
  RESET_GLOBAL_STATE = "RESET_GLOBAL_STATE",
  SET_GRAPH_QL_ERRORS = "SET_GRAPH_QL_ERRORS",
  CLEAR_GRAPH_QL_ERROR = "CLEAR_GRAPH_QL_ERROR",
  SET_AUTH_STATE = "SET_AUTH_STATE",
  SET_SHOW_DOWNLOAD_MODAL = "SET_SHOW_DOWNLOAD_MODAL",
  SET_EMAIL_VERIFICATION_REQUIRED = "SET_EMAIL_VERIFICATION_REQUIRED",
  SET_SCENARIO_ID = "SET_SCENARIO_ID",
  SET_PIPELINE_ID = "SET_PIPELINE_ID",
  SET_BENCHMAKR_PIPELINE_IDS = "SET_BENCHMAKR_PIPELINE_IDS",
  SET_PRELIMINARY_REPORT = "SET_PRELIMINARY_REPORT",
}

export type Action =
  | { type: ActionType.SET_USER; payload: { user: User | null } }
  | { type: ActionType.RESET_GLOBAL_STATE }
  | { type: ActionType.SET_AUTH_STATE; payload: AuthState }
  | { type: ActionType.SET_EMAIL_VERIFICATION_REQUIRED; payload: boolean }
  | { type: ActionType.SET_GRAPH_QL_ERRORS; payload: GraphQlError[] }
  | { type: ActionType.SET_SHOW_DOWNLOAD_MODAL; payload: boolean }
  | { type: ActionType.SET_SCENARIO_ID; payload: string | null }
  | { type: ActionType.SET_PIPELINE_ID; payload: string | null }
  | { type: ActionType.SET_BENCHMAKR_PIPELINE_IDS; payload: string[] }
  | { type: ActionType.CLEAR_GRAPH_QL_ERROR; payload: { index: number } }
  | { type: ActionType.SET_PRELIMINARY_REPORT; payload: boolean };

export interface GraphQlError {
  message: string;
}

export interface User {
  id: string;
  name: string;
}

export interface State {
  user: User | null;
  graphQlErrors: GraphQlError[];
  authState: AuthState;
  emailVerificationRequired: boolean;
  showDownloadModal: boolean;
  scenarioId: string | null;
  pipelineId: string | null;
  benchmarkPipelineIds: string[];
  isPreliminaryReport: boolean;
}

export enum AuthState {
  LOADING,
  LOGGED_OUT,
  LOGGING_OUT,
  LOGGING_IN,
  LOGGED_IN,
}
