import { Box, Typography } from "@material-ui/core";
import { SimpleTable } from "../simple-table";
import {
  BuildingElementLayerDisplay,
  Hatching,
  Unit,
} from "../../generated/graphql";
import LCADataTypeChip from "../lca-data-type-chip";
import { calc } from "../../calculation-utils";
import { rounderInt, rounderTwo } from "../../rounder";
import { BuildingElement, getEmission } from "./common";
import { Flex } from "@chakra-ui/react";
import { BEPreview } from "./BEPreview";

interface IBuildingElementCard {
  buildingElement: any;
  projectA1_A5?: number;
  projectA1_A5PerBTA?: number;
  columns?: Column[];
}

export function getUnit(unit?: Unit) {
  if (!unit) {
    return "";
  }
  return unit === "m2" ? "m²" : unit;
}

function ConnectingLine(props: any) {
  const cellHeight = 20;

  if (!props.displayVertically) {
    const invertLine =
      props.currentThicknessLayers -
        (props.i + 1) * cellHeight +
        props.thickness / 3 >
      -9;
    return (
      <>
        <div
          style={{
            width: 22 + 22 - props.i * 6,
            right: 22 + 26 - props.i * 6,
            height: 1,
            top: -9,
            borderTop: "1px solid black",
            position: "relative",
          }}
        />

        <div
          style={{
            width: 36 + props.i * 6,
            right: 22 + 61,
            height: 1,
            top:
              props.currentThicknessLayers -
              (props.i + 1) * cellHeight +
              props.thickness / 3,
            borderTop: "1px solid black",
            position: "relative",
          }}
        />

        <div
          style={{
            width: 22 + 1 - props.i * 6,
            right: 22 + 26 - props.i * 6,
            height: invertLine
              ? Math.abs(
                  Math.abs(
                    props.currentThicknessLayers -
                      props.i * cellHeight +
                      props.thickness / 3
                  ) - 11
                )
              : Math.abs(
                  Math.abs(
                    props.currentThicknessLayers -
                      (props.i + 1) * cellHeight +
                      props.thickness / 3
                  ) - 11
                ),
            top: invertLine
              ? -10
              : props.currentThicknessLayers -
                (props.i + 1) * cellHeight +
                props.thickness / 3,
            borderLeft: "1px solid black",
            position: "relative",
          }}
        />
      </>
    );
  }

  return (
    <div
      style={{
        width: 36 + props.currentThicknessLayers + props.thickness / 2,
        right: 36 + 5 + props.currentThicknessLayers + props.thickness / 2,
        height: 1,
        top: -9,
        borderTop: "1px solid black",
        position: "relative",
      }}
    />
  );
}

export interface Column {
  key: string;
  label:
    | string
    | React.ReactNode
    | ((be: BuildingElement) => string | React.ReactNode);
}

export interface TableComponentProps {
  buildingElement: BuildingElement;
  projectA1_A5?: number;
  projectA1_A5PerBTA?: number;
  columns: Column[];
}

export const defaultColumns = [
  {
    key: "thickness",
    label: "Tjocklek",
  },
  {
    key: "amount",
    label: "Mängd",
  },
  {
    key: "name",
    label: "",
  },
  {
    key: "A1_A5",
    label: (be: BuildingElement) => `A1-A5/${getUnit(be.unit ?? undefined)}`,
  },
  {
    key: "A1_A5_KG",
    label: `A1-A5/kg`,
  },
  {
    key: "weight",
    label: (be: BuildingElement) => `kg/${getUnit(be.unit ?? undefined)}`,
  },
];

function TableComponent({
  buildingElement,
  projectA1_A5,
  projectA1_A5PerBTA,
  columns = defaultColumns,
}: TableComponentProps) {
  const total = buildingElement && getEmission(buildingElement);

  const totalRow = {
    id: "total",
    index: "",
    thickness: (
      <span style={{ fontWeight: 600, whiteSpace: "nowrap" }}>{`${rounderInt(
        total.thickness
      )} mm`}</span>
    ),
    amount: <span style={{ fontWeight: 600, whiteSpace: "nowrap" }}>-</span>,
    name: "",
    A1_A5: (
      <span style={{ fontWeight: 600, whiteSpace: "nowrap" }}>
        {rounderTwo(total.A1_A5)}
      </span>
    ),
    A1_A5_KG: (
      <span style={{ fontWeight: 600, whiteSpace: "nowrap" }}>
        {rounderInt(total.A1_A5 / total.weight)}
      </span>
    ),
    projectA1_A5: (
      <span style={{ fontWeight: 600, whiteSpace: "nowrap" }}>
        {(projectA1_A5 || 0) > 1000
          ? `${rounderInt((projectA1_A5 || 0) / 1000)} ton`
          : `${rounderInt(projectA1_A5 || 0)} kg`}
      </span>
    ),
    A1_A3: (
      <span style={{ fontWeight: 600, whiteSpace: "nowrap" }}>
        {rounderTwo(total.A1_A3)}
      </span>
    ),
    projectA1_A5PerBTA: (
      <span style={{ fontWeight: 600, whiteSpace: "nowrap" }}>
        {`${rounderInt(projectA1_A5PerBTA ?? 0)} kg`}
      </span>
    ),
    weight: (
      <span style={{ fontWeight: 600, whiteSpace: "nowrap" }}>
        {rounderInt(total.weight)}
      </span>
    ),
  };

  const rows = buildingElement?.products
    .slice(0)
    .sort((a: any, b: any) => a.index - b.index)
    .map((product, i) => {
      const index = product.index ?? 0;
      const currentThicknessLayers = buildingElement?.products.reduce(
        (acc: any, p: any) => {
          const isSteelProfile =
            p?.rawMaterial?.hatching === Hatching.SteelProfile;
          const isWoodProfile =
            p?.rawMaterial?.hatching === Hatching.Wood && p?.unit === "m";
          return (buildingElement.layerDisplay ===
          BuildingElementLayerDisplay.Vertical
            ? p.index <= index
            : p.index >= index) ||
            isWoodProfile ||
            isSteelProfile
            ? acc
            : acc + p.thickness;
        },
        0
      );

      const isSteelProfile =
        product.rawMaterial?.hatching === Hatching.SteelProfile;
      const isWoodProfile =
        product.rawMaterial?.hatching === Hatching.Wood && product.unit === "m";

      const weight = calc.weight(product);
      const { A1_A5, A1_A3 } = calc.gwp(weight, product.rawMaterial!);

      const projectA1_A5Material = (A1_A5 / total.A1_A5) * (projectA1_A5 || 0);
      const projectA1_A5PerBTAMaterial =
        (A1_A5 / total.A1_A5) * (projectA1_A5PerBTA || 0);

      return {
        id: product.id,
        index: (
          <Box width={12} height={12}>
            <div>{i + 1 || ""}</div>
            {product.thickness &&
            buildingElement.layerDisplay !==
              BuildingElementLayerDisplay.Hidden ? (
              <ConnectingLine
                name={product.name}
                i={i}
                currentThicknessLayers={currentThicknessLayers / 3}
                thickness={
                  ((isSteelProfile || isWoodProfile) &&
                  buildingElement.layerDisplay !==
                    BuildingElementLayerDisplay.Vertical
                    ? -product.thickness
                    : product.thickness) / 3
                }
                displayVertically={
                  buildingElement.layerDisplay ===
                  BuildingElementLayerDisplay.Vertical
                }
              />
            ) : (
              ""
            )}
          </Box>
        ),
        thickness: (
          <span style={{ whiteSpace: "nowrap" }}>
            {(product.thickness ?? 0) > 0
              ? `${product.thickness || 0} mm`
              : "-"}
          </span>
        ),
        amount: (
          <span style={{ whiteSpace: "nowrap" }}>{`${
            Math.round((product.amount ?? 0) * 100) / 100
          } ${getUnit(product.unit ?? undefined)}`}</span>
        ),
        name: (
          <Box width={220} style={{ lineHeight: 1.03 }}>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>{product.name}</Box>
              <LCADataTypeChip
                LCADataType={product.rawMaterial?.LCADataType ?? undefined}
                sourceName={product.rawMaterial?.sourceName ?? ""}
              />
            </Box>
            <Box>
              <Typography variant="caption">
                <i>
                  <a
                    style={{ color: "blue" }}
                    href={product.rawMaterial?.sourceUrl ?? undefined}
                    target="_blank"
                  >
                    {product.rawMaterial?.name}
                  </a>
                </i>
              </Typography>
            </Box>
          </Box>
        ),
        A1_A5: `${rounderTwo(A1_A5 || 0)}`,
        A1_A3: `${rounderTwo(A1_A3 || 0)}`,
        A1_A5_KG: rounderInt(A1_A5 / weight),
        projectA1_A5:
          projectA1_A5Material > 1000
            ? `${rounderInt(projectA1_A5Material / 1000)} ton`
            : `${rounderInt(projectA1_A5Material)} kg`,
        projectA1_A5PerBTA:
          projectA1_A5PerBTAMaterial < 0.1
            ? `${rounderInt(projectA1_A5PerBTAMaterial)} kg`
            : `${rounderInt(projectA1_A5PerBTAMaterial)} kg`,
        weight: rounderInt(weight || 0),
      };
    });

  return (
    <Box>
      <SimpleTable
        rows={[...rows, totalRow].map((r) => ({
          id: r.id,
          // @ts-ignore
          cells: columns.map((c) => r[c.key]),
        }))}
        columns={columns.map((c) => {
          const val = c.label;
          if (typeof val === "function") {
            return val(buildingElement);
          }
          return val;
        })}
      />
    </Box>
  );
}

export function BuildingElementCard(props: IBuildingElementCard) {
  const hasCustomImage =
    props.buildingElement?.customImageUrl &&
    props.buildingElement?.customImageUrl !== "";

  return (
    <Box display="flex">
      {hasCustomImage && (
        <Box
          width={250}
          justifyContent="center"
          alignItems="flex-start"
          display="flex"
        >
          <img
            alt="custom-image"
            src={props.buildingElement?.customImageUrl}
            style={{ height: "100%", maxHeight: "120px" }}
          />
        </Box>
      )}
      <Flex gap={4}>
        {props.buildingElement.layerDisplay !== "HIDDEN" && (
          <Box>
            <BEPreview buildingElement={props.buildingElement} />
          </Box>
        )}
        <TableComponent
          projectA1_A5={props.projectA1_A5}
          projectA1_A5PerBTA={props.projectA1_A5PerBTA}
          buildingElement={props.buildingElement}
          columns={props.columns ?? []}
        />
      </Flex>
    </Box>
  );
}
