import { RecycleType, Unit } from "./generated/graphql";

interface Product {
  weight?: number | null;
  unit?: Unit | string | null;
  amount?: number | null;
  cost?: number | null;
  unitWeight?: number | null;
  thickness?: number | null;
  volume?: number | null;
  length?: number | null;
  count?: number | null;
  area?: number | null;
  rawMaterial?: {
    kgPerM3?: number | null;
  } | null;
}

// weight in kg
function weight(product: Product): number {
  const weight = product.weight ?? product.amount ?? 0;
  const cost = product.cost ?? product.amount ?? 0;
  const volume = product.volume ?? product.amount ?? 0;
  const length = product.length ?? product.amount ?? 0;
  const count = product.count ?? product.amount ?? 0;
  const area = product.area ?? product.amount ?? 0;
  const kgPerM3 = product.rawMaterial?.kgPerM3 ?? 0;
  const thickness = (product.thickness ?? 0) / 1000;
  const unitWeight = product.unitWeight ?? 0;

  switch (product.unit) {
    case Unit.Kg:
      return weight;
    case Unit.Ton:
      return weight * 1000;
    case Unit.Kr:
      return cost * kgPerM3;
    case Unit.M3:
      return volume * kgPerM3;
    case Unit.M:
      return length * unitWeight;
    case Unit.St:
      return count * unitWeight;
    case Unit.M2:
      return area * thickness * kgPerM3;
    default:
      return 0;
  }
}

interface RawMaterial {
  A1_A5?: number | null;
  A1_A3?: number | null;
  A4?: number | null;
  A5_1?: number | null;
  A5_2_5?: number | null;
  A5?: number | null;
  biogenicCarbon?: number | null;
}

// gwp in kg CO2e

function gwp(
  weight: number,
  rawMaterial: RawMaterial,
  recycleType?: RecycleType,
  respectRecycleType = true
) {
  /* Product is both used in building-element-template-to-raw-material and when parsing rules, we need area, volume, weight etc to be here
        When we have the quantities we can move towards having the rawMaterial selecting which quantity to base calculation on.
     */
  if (
    respectRecycleType &&
    recycleType != null &&
    ["REUSED", "PRESERVED"].includes(recycleType)
  ) {
    return {
      A1_A3: 0,
      A4: 0,
      A5_1: 0,
      A5_2_5: 0,
      A5: 0,
      A1_A5: 0,
      biogenicCarbon: 0,
    };
  }

  const biogenicCarbonPerKg = rawMaterial?.biogenicCarbon ?? 0;
  const A5_2_5PerKg = rawMaterial?.A5_2_5 ?? 0;
  const A5_1PerKg = rawMaterial?.A5_1 ?? 0;
  const A5PerKg = rawMaterial?.A5 ?? 0;
  const A1_A3PerKg = rawMaterial?.A1_A3 ?? 0;
  const A4PerKg = rawMaterial?.A4 ?? 0;

  const A5_2_5 = A5_2_5PerKg * weight;

  const biogenicCarbon = biogenicCarbonPerKg * weight;
  const A1_A3 = A1_A3PerKg * weight;
  const A4 = A4PerKg * weight;
  const A5_1 = A5_1PerKg * weight;

  const A5 = (A5PerKg || A5_1PerKg + A5_2_5PerKg) * weight;
  const A1_A5 = A1_A3 + A4 + A5;

  // kg CO2e
  return { A1_A3, A4, A5_1, A5_2_5, A5, A1_A5, biogenicCarbon };
}

export const calc = {
  gwp,
  weight,
};
