import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { IReportColumns, ReportTable } from "../components/table";
import { ReportTypography } from "../components/report-typography";

import { omitRedundantFields } from "./report-gwp-summary-page";
import { bsabCodes } from "../bsab-codes";
import { groupBy } from "lodash";
import { capitalize } from "voca";

import { compareValue } from "./report-scenarios-bsab-detail-page";
import useScenariosQueries, {
  useGetBenchmarks,
} from "../hooks/scenario-queries";

import { ReportPageProps } from "../../report-page";

export const sumFuncBsabPages = (acc: any, row: any) => {
  Object.entries(row).forEach(([columnName, columnValue]) => {
    const accValue = Number.isFinite(acc[columnName]) ? acc[columnName] : 0;
    const aditionValue = Number.isFinite(columnValue) ? columnValue : 0;
    acc[columnName] = accValue + aditionValue;
  });

  return acc;
};

export function getRowsGroupedBy(rows: any[], numberOfLetters: number) {
  return Object.entries(
    // @ts-ignore
    groupBy(rows, (row) => row.ProductBSABCode.slice(0, numberOfLetters))
  ).map(([key, value]) => {
    const ProductBSAB = bsabCodes[key]
      ? capitalize(bsabCodes[key].toLowerCase())
      : "Saknar BSAB96-kod";
    return {
      ...value.reduce(sumFuncBsabPages, {}),
      ProductBSAB:
        ProductBSAB.length > 60
          ? `${ProductBSAB.slice(0, 57)}...`
          : ProductBSAB,
      ProductBSABCode: key,
    };
  });
}

export default function ReportScenariosBsabPage({
  tmplVars,
  pageNumber,
  projectId,
}: ReportPageProps) {
  const chapter = "Resultat";
  const pageTitle = "Scenario – BSAB96-koder";
  const pipelinesOrScenarios = useGetBenchmarks(projectId);

  const dsrs = useScenariosQueries({
    filter: [],
    group: [{ col: "ProductBSAB" }],
    aggregations: [{ col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" }],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 3000,
  });

  const rows = omitRedundantFields(
    dsrs.data.sort((a, b) => b.A1_A5PerBTA - a.A1_A5PerBTA)
  ).map(({ weight, ProductBSAB = "-", ...row }: any) => ({
    ...row,
    scenarioName: pipelinesOrScenarios.find(({ id }) => id === row.id)?.name,
    weight: (weight ?? 0) / 1000,
    ProductBSAB: (ProductBSAB && bsabCodes[ProductBSAB]) || "Saknar BSAB96-kod",
    ProductBSABCode: ProductBSAB,
  }));

  const columns: IReportColumns[] = [
    {
      label: "BSAB96-kod",
      value: "ProductBSABCode",
      width: "1%",
      align: "left",
    },
    {
      label: "Beskrivning",
      value: "ProductBSAB",
      width: "60%",
      align: "left",
    },
    ...Object.keys(groupBy(rows, "scenarioName"))
      .map((scenarioName) => scenarioName?.replace(/\+.*/, ""))
      .map((scenarioName) => ({
        label: `${scenarioName}`,
        value: scenarioName,
        align: "right" as const,
      }))
      .sort(compareValue),
  ];

  const rowsGroupedOnScenarioName = Object.values(
    groupBy(rows, "ProductBSABCode")
  ).map((bsabRows) => {
    return bsabRows
      .map(({ scenarioName, ...rest }) => ({
        scenarioName: scenarioName?.replace(/\+.*/, ""),
        ...rest,
      }))
      .reduce((acc, dsr) => {
        return {
          ...acc,
          ...dsr,
          [dsr.scenarioName]: dsr.A1_A5PerBTA,
        };
      }, {});
  });

  const isBSABProductionResults = /^[A-Z]+/.test(
    // @ts-ignore
    rowsGroupedOnScenarioName?.[0]?.ProductBSABCode ?? ""
  );
  const rowsGroupedOnFirstLetter = getRowsGroupedBy(
    rowsGroupedOnScenarioName,
    1
  );
  const rowsGroupedOnFirstTwoLetters = getRowsGroupedBy(
    rowsGroupedOnScenarioName,
    isBSABProductionResults ? 3 : 2
  );

  const sumRow = {
    ...rowsGroupedOnScenarioName.reduce(sumFuncBsabPages, {}),
    ProductBSAB: "",
  };
  const first = 10;
  const rowsAfterLimit = rowsGroupedOnFirstTwoLetters
    .slice(first)
    .reduce(sumFuncBsabPages, {
      A1_A5PerBTA: 0,
      ProductBSAB: "Övrigt",
      ProductBSABCode: "",
    });
  const rowsWithLimit = [
    ...(rowsGroupedOnFirstTwoLetters.length > first
      ? rowsGroupedOnFirstTwoLetters.slice(0, first)
      : rowsGroupedOnFirstTwoLetters),
    ...(rowsAfterLimit.A1_A5PerBTA ? [rowsAfterLimit] : []),
  ];

  return (
    <ReportContainer>
      <ReportHeader chapter={chapter} />
      <ReportBodyAlignSpaceBetween>
        <Box mt={10} mb={1} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>

        <Box flexGrow={1}>
          {!isBSABProductionResults && (
            <ReportTable
              tableUnit={`kg CO₂e/${tmplVars.BTA.inUnit}`}
              columns={columns}
              hasRawMaterials={true}
              rows={rowsGroupedOnFirstLetter}
              sumRow={{ ...sumRow, weight: (sumRow?.weight || 0) / 1000 }}
            />
          )}
          <ReportTable
            tableUnit={`kg CO₂e/${tmplVars.BTA.inUnit}`}
            columns={columns}
            hasRawMaterials={true}
            rows={rowsWithLimit}
            sumRow={{ ...sumRow, weight: (sumRow?.weight || 0) / 1000 }}
          />
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} />
    </ReportContainer>
  );
}
