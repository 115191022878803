export default function LayerTimber(props: any) {
  return (
    <svg
      viewBox="-610.091 -240.427 451.385 2374.424"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Boxes" transform="matrix(0, 1, -1, 0, -243.976868, 614.211914)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="Hatches" transform="matrix(0, 1, -1, 0, -243.976868, 614.211914)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-1" transform="matrix(0, 1, -1, 0, -334.19339, 508.566254)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-2" transform="matrix(0, 1, -1, 0, -334.19339, 508.566254)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-3" transform="matrix(0, 1, -1, 0, -244.192657, 424.227631)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-4" transform="matrix(0, 1, -1, 0, -244.192657, 424.227631)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-5" transform="matrix(0, 1, -1, 0, -334.409149, 318.58194)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-6" transform="matrix(0, 1, -1, 0, -334.409149, 318.58194)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-7" transform="matrix(0, 1, -1, 0, -243.914841, 234.916626)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-8" transform="matrix(0, 1, -1, 0, -243.914841, 234.916626)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-9" transform="matrix(0, 1, -1, 0, -334.131348, 129.270935)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-10" transform="matrix(0, 1, -1, 0, -334.131348, 129.270935)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-11" transform="matrix(0, 1, -1, 0, -244.130615, 44.932316)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-12" transform="matrix(0, 1, -1, 0, -244.130615, 44.932316)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-13" transform="matrix(0, 1, -1, 0, -334.347107, -60.713375)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-14" transform="matrix(0, 1, -1, 0, -334.347107, -60.713375)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-15" transform="matrix(0, 1, -1, 0, -244.073914, -141.445312)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-16" transform="matrix(0, 1, -1, 0, -244.073914, -141.445312)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-17" transform="matrix(0, 1, -1, 0, -334.290436, -247.091003)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-18" transform="matrix(0, 1, -1, 0, -334.290436, -247.091003)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-19" transform="matrix(0, 1, -1, 0, -244.289688, -331.429626)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-20" transform="matrix(0, 1, -1, 0, -244.289688, -331.429626)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-21" transform="matrix(0, 1, -1, 0, -334.506195, -437.075317)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-22" transform="matrix(0, 1, -1, 0, -334.506195, -437.075317)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-23" transform="matrix(0, 1, -1, 0, -423.331818, 607.860413)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-24" transform="matrix(0, 1, -1, 0, -423.331818, 607.860413)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-25" transform="matrix(0, 1, -1, 0, -513.54834, 502.214722)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-26" transform="matrix(0, 1, -1, 0, -513.54834, 502.214722)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-27" transform="matrix(0, 1, -1, 0, -423.547607, 417.876099)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-28" transform="matrix(0, 1, -1, 0, -423.547607, 417.876099)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-29" transform="matrix(0, 1, -1, 0, -513.764099, 312.230408)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-30" transform="matrix(0, 1, -1, 0, -513.764099, 312.230408)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-31" transform="matrix(0, 1, -1, 0, -423.269775, 228.565094)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-32" transform="matrix(0, 1, -1, 0, -423.269775, 228.565094)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-33" transform="matrix(0, 1, -1, 0, -513.486328, 122.919403)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-34" transform="matrix(0, 1, -1, 0, -513.486328, 122.919403)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-35" transform="matrix(0, 1, -1, 0, -423.485565, 38.580753)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-36" transform="matrix(0, 1, -1, 0, -423.485565, 38.580753)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-37" transform="matrix(0, 1, -1, 0, -513.702087, -67.064941)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-38" transform="matrix(0, 1, -1, 0, -513.702087, -67.064941)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-39" transform="matrix(0, 1, -1, 0, -423.428864, -147.796875)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-40" transform="matrix(0, 1, -1, 0, -423.428864, -147.796875)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-41" transform="matrix(0, 1, -1, 0, -513.645386, -253.442566)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-42" transform="matrix(0, 1, -1, 0, -513.645386, -253.442566)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-43" transform="matrix(0, 1, -1, 0, -423.644623, -337.781189)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-44" transform="matrix(0, 1, -1, 0, -423.644623, -337.781189)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-45" transform="matrix(0, 1, -1, 0, -513.861145, -443.42688)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-46" transform="matrix(0, 1, -1, 0, -513.861145, -443.42688)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-47" transform="matrix(0, 1, -1, 0, -244.756271, 1741.547607)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-48" transform="matrix(0, 1, -1, 0, -244.756271, 1741.547607)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-49" transform="matrix(0, 1, -1, 0, -334.972809, 1635.901978)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-50" transform="matrix(0, 1, -1, 0, -334.972809, 1635.901978)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-51" transform="matrix(0, 1, -1, 0, -244.972061, 1551.563354)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-52" transform="matrix(0, 1, -1, 0, -244.972061, 1551.563354)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-53" transform="matrix(0, 1, -1, 0, -335.188568, 1445.917603)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-54" transform="matrix(0, 1, -1, 0, -335.188568, 1445.917603)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-55" transform="matrix(0, 1, -1, 0, -244.69426, 1362.252319)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-56" transform="matrix(0, 1, -1, 0, -244.69426, 1362.252319)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-57" transform="matrix(0, 1, -1, 0, -334.910767, 1256.606567)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-58" transform="matrix(0, 1, -1, 0, -334.910767, 1256.606567)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-59" transform="matrix(0, 1, -1, 0, -244.910019, 1172.267944)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-60" transform="matrix(0, 1, -1, 0, -244.910019, 1172.267944)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-61" transform="matrix(0, 1, -1, 0, -335.126526, 1066.622314)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-62" transform="matrix(0, 1, -1, 0, -335.126526, 1066.622314)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-63" transform="matrix(0, 1, -1, 0, -244.853317, 985.890381)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-64" transform="matrix(0, 1, -1, 0, -244.853317, 985.890381)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-65" transform="matrix(0, 1, -1, 0, -335.069855, 880.24469)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-66" transform="matrix(0, 1, -1, 0, -335.069855, 880.24469)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-67" transform="matrix(0, 1, -1, 0, -245.069107, 795.906067)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-68" transform="matrix(0, 1, -1, 0, -245.069107, 795.906067)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-69" transform="matrix(0, 1, -1, 0, -335.285614, 690.260376)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-70" transform="matrix(0, 1, -1, 0, -335.285614, 690.260376)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-71" transform="matrix(0, 1, -1, 0, -424.111237, 1735.196045)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-72" transform="matrix(0, 1, -1, 0, -424.111237, 1735.196045)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-73" transform="matrix(0, 1, -1, 0, -514.327759, 1629.550415)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-74" transform="matrix(0, 1, -1, 0, -514.327759, 1629.550415)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-75" transform="matrix(0, 1, -1, 0, -424.327026, 1545.211792)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-76" transform="matrix(0, 1, -1, 0, -424.327026, 1545.211792)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-77" transform="matrix(0, 1, -1, 0, -514.543518, 1439.56604)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-78" transform="matrix(0, 1, -1, 0, -514.543518, 1439.56604)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-79" transform="matrix(0, 1, -1, 0, -424.049194, 1355.900757)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-80" transform="matrix(0, 1, -1, 0, -424.049194, 1355.900757)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-81" transform="matrix(0, 1, -1, 0, -514.265747, 1250.255127)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-82" transform="matrix(0, 1, -1, 0, -514.265747, 1250.255127)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-83" transform="matrix(0, 1, -1, 0, -424.264984, 1165.916382)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-84" transform="matrix(0, 1, -1, 0, -424.264984, 1165.916382)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-85" transform="matrix(0, 1, -1, 0, -514.481506, 1060.270752)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-86" transform="matrix(0, 1, -1, 0, -514.481506, 1060.270752)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-87" transform="matrix(0, 1, -1, 0, -424.208282, 979.538818)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-88" transform="matrix(0, 1, -1, 0, -424.208282, 979.538818)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-89" transform="matrix(0, 1, -1, 0, -514.424805, 873.893127)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-90" transform="matrix(0, 1, -1, 0, -514.424805, 873.893127)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-91" transform="matrix(0, 1, -1, 0, -424.424042, 789.554504)">
        <rect
          fill={"none"}
          stroke="black"
          x="4.44"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-92" transform="matrix(0, 1, -1, 0, -424.424042, 789.554504)">
        <path
          stroke="black"
          fill="none"
          d="M68.53,95.07c3.19-2,6.43-4.1,10-5.18,4.28-1.27,8.83-1.1,13.28-.91,7.17.31,14.61.72,20.91,4.15.84.46,1.94.94,2.65.3"
        />
        <path
          stroke="black"
          fill="none"
          d="M55.24,94.37a87.14,87.14,0,0,1,47.53-11,45.52,45.52,0,0,1,10.07,1.55A31.1,31.1,0,0,1,127.24,94"
        />
        <path
          stroke="black"
          fill="none"
          d="M46.49,95A81.46,81.46,0,0,1,112.55,76.7c5.15.89,10.45,2.42,14.18,6.07s5.84,9.28,10.73,11.08"
        />
        <path
          stroke="black"
          fill="none"
          d="M27.47,94.63A198.12,198.12,0,0,0,47.35,79.68C52.15,75.57,57,71.11,63,69.26a41.36,41.36,0,0,1,8.65-1.36l12.8-1.11c15.4-1.32,31.15-2.62,46.12,1.23a17.57,17.57,0,0,1,5.91,2.44,22.86,22.86,0,0,1,4.75,5.46,86.28,86.28,0,0,0,17.86,18.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M8.81,94.67c13.67-6,21.17-23.62,32-33.83,6.5-6.11,15-9.76,23.69-11.76s17.66-2.46,26.58-2.9a111.8,111.8,0,0,1,15.53-.06c10.59.94,20.65,4.85,30.55,8.73,4,1.55,8,3.16,11.2,6,2,1.8,3.59,4,5.46,6,4.68,4.88,11.21,8.06,14.66,13.88,2.65,4.46,3.4,10.32,7.6,13.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.52,78.35a147.28,147.28,0,0,0,44-37.28c2.21-2.79,4.38-5.72,7.35-7.68S62.76,30.51,66.45,30a100.58,100.58,0,0,1,20.89-.24A195.63,195.63,0,0,1,139.47,41a87.64,87.64,0,0,1,15.3,7c9.06,5.46,16.4,13.39,22.57,22a97.3,97.3,0,0,0,6.94,9.18,36,36,0,0,0,9.08,7.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.65,65.5C13.73,65.21,22,59.86,28.2,53.2S39,38.58,44.2,31.14a30.05,30.05,0,0,1,7.22-7.85c3.6-2.46,8-3.41,12.32-4.07A113.31,113.31,0,0,1,84.64,18,225.55,225.55,0,0,1,110.54,21a115.63,115.63,0,0,1,18.08,3.76c12.17,3.92,22.65,11.72,32.87,19.4,6.66,5,13.44,10.11,18.14,17,3.62,5.29,6.91,12.19,13.26,13"
        />
        <path
          stroke="black"
          fill="none"
          d="M3.54,60.76c4.75,0,9.17-2.62,12.52-6s5.81-7.48,8.46-11.42c3.05-4.52,6.37-8.85,9.76-13.12,4.83-6.05,10-12.15,17-15.46,6.31-3,13.48-3.41,20.44-3.79,15-.82,30.14-1.63,45.05.32s29.76,6.89,41.4,16.42c2.46,2,4.8,4.24,7.64,5.68,3.31,1.67,7.1,2.15,10.55,3.54,7.39,3,12.4,9.83,17,16.34"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.4,47.87A39.58,39.58,0,0,0,26.68,27.76c1.69-3.69,2.8-7.64,4.75-11.2,2.12-3.87,5.15-7.14,8.15-10.37"
        />
        <path
          stroke="black"
          fill="none"
          d="M4.26,38c5.64-.61,10.57-4.31,14-8.85s5.46-9.92,7.48-15.22a20.36,20.36,0,0,1,2.12-4.44,10.09,10.09,0,0,1,4.56-3.58"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,24.18a14.9,14.9,0,0,0,11.53-6.33c2.82-4.1,3.72-9.79,7.94-12.43"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.05,15.68A15.13,15.13,0,0,0,17.22,6"
        />
        <path
          stroke="black"
          fill="none"
          d="M133.07,5c3.86-.42,7.58,2.82,11.06,4.54L167,20.83c9.76,4.81,20,10,25.89,19.18"
        />
        <path
          stroke="black"
          fill="none"
          d="M159.17,5.63l16.91,8.94c4.16,2.2,8.44,4.5,11.39,8.16,2.1,2.62,3.42,5.81,5.65,8.31.14.17.34.35.55.28s.14-.49,0-.39"
        />
        <path
          stroke="black"
          fill="none"
          d="M194.45,19.25a10,10,0,0,0-2.56-4,19.87,19.87,0,0,0-6.54-3.63L169.76,5.4"
        />
        <path
          stroke="black"
          fill="none"
          d="M17.11,94.66c4.37-.33,8-3.43,11.21-6.35q9.82-8.78,19.94-17.2a53.45,53.45,0,0,1,9.81-6.94C63.19,61.6,69,60.68,74.63,59.89c17.67-2.47,35.9-4.08,53.17.39a36,36,0,0,1,8.22,3A48.18,48.18,0,0,1,142.7,68c4.25,3.36,8.56,6.76,11.84,11.06,4,5.23,6.49,11.79,11.82,15.64"
        />
      </g>
      <g id="g-93" transform="matrix(0, 1, -1, 0, -514.640564, 683.908813)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-94" transform="matrix(0, 1, -1, 0, -514.640564, 683.908813)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-95" transform="matrix(0, 1, -1, 0, -153.767227, 507.583801)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-96" transform="matrix(0, 1, -1, 0, -153.767227, 507.583801)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-97" transform="matrix(0, 1, -1, 0, -153.982986, 317.599487)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-98" transform="matrix(0, 1, -1, 0, -153.982986, 317.599487)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-99" transform="matrix(0, 1, -1, 0, -153.705215, 128.288483)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-100" transform="matrix(0, 1, -1, 0, -153.705215, 128.288483)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-101" transform="matrix(0, 1, -1, 0, -153.920975, -61.69585)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-102" transform="matrix(0, 1, -1, 0, -153.920975, -61.69585)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-103" transform="matrix(0, 1, -1, 0, -153.864273, -248.073486)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-104" transform="matrix(0, 1, -1, 0, -153.864273, -248.073486)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-105" transform="matrix(0, 1, -1, 0, -154.080032, -438.0578)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-106" transform="matrix(0, 1, -1, 0, -154.080032, -438.0578)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-107" transform="matrix(0, 1, -1, 0, -154.546646, 1634.919556)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-108" transform="matrix(0, 1, -1, 0, -154.546646, 1634.919556)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-109" transform="matrix(0, 1, -1, 0, -154.762405, 1444.935181)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-110" transform="matrix(0, 1, -1, 0, -154.762405, 1444.935181)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-111" transform="matrix(0, 1, -1, 0, -154.484634, 1255.624268)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-112" transform="matrix(0, 1, -1, 0, -154.484634, 1255.624268)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-113" transform="matrix(0, 1, -1, 0, -154.700394, 1065.639771)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-114" transform="matrix(0, 1, -1, 0, -154.700394, 1065.639771)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-115" transform="matrix(0, 1, -1, 0, -154.643692, 879.262207)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-116" transform="matrix(0, 1, -1, 0, -154.643692, 879.262207)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
      <g id="g-117" transform="matrix(0, 1, -1, 0, -154.859451, 689.277893)">
        <rect
          fill={"none"}
          stroke="black"
          x="5"
          y="5"
          width="190"
          height="90"
        />
      </g>
      <g id="g-118" transform="matrix(0, 1, -1, 0, -154.859451, 689.277893)">
        <path
          stroke="black"
          fill="none"
          d="M40.68,5,31,17.85a27.07,27.07,0,0,1-2.52,3,27.57,27.57,0,0,1-3.72,2.91L13.55,31.5A7.86,7.86,0,0,0,12,32.74a7.59,7.59,0,0,0-1.2,2.31L5,50.23"
        />
        <path
          stroke="black"
          fill="none"
          d="M5.08,76A131.35,131.35,0,0,1,20.37,54.65a29.92,29.92,0,0,1,4.49-4.37c4-3,9.14-4.06,13.44-6.62A72.33,72.33,0,0,1,32.51,67,84.38,84.38,0,0,1,23.86,81.8c-3.22,4.52-7.32,8.56-10.32,13.2"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,85.53,18.49,64.46a35.16,35.16,0,0,1,2.69-3.8c2.47-2.9,5.8-4.89,9.07-6.84L22.19,70.75c-.71,1.48-1.42,3-2.27,4.36C16.21,81.18,10,86.52,5,91.51"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.84,95,38,71.85a35,35,0,0,0,2.4-4.09,32.88,32.88,0,0,0,1.93-5.93c1.32-5.15,2.61-10.32,4.07-15.43,4.14-14.53,9.73-28.8,18.24-41.28"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.58,5,61.32,23.89a36.3,36.3,0,0,0-3,4.77A35.2,35.2,0,0,0,56.39,34l-6.67,21.7a44.27,44.27,0,0,0-1.84,7.49c-.7,5.49.61,11.24-1.12,16.49C44.83,85.52,39.49,89.57,36.57,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M42,95.45l8.46-7.14c2.63-2.22,5.53-3.78,6.38-7.37,1.1-4.64.65-9.83.94-14.57.34-5.4-.45-12.62,1.64-17.65,4.12-9.88,9.27-19.43,13.87-29.09L84.39,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M50.07,18.2c-3.6,3-6.09,7.25-9,10.85a7.74,7.74,0,0,0-1.3,2,6.75,6.75,0,0,0-.38,1.61l-1,6.13,6.94-4.11a2.84,2.84,0,0,0,1-.81,3.17,3.17,0,0,0,.36-1C47.73,28.37,50.08,22.76,50.07,18.2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.64,5c-3.45,4.23-6.85,8.5-10.28,12.75A5.6,5.6,0,0,1,20.18,19a5.71,5.71,0,0,1-1.91.71l-4.18,1a3,3,0,0,0-1.21.5,3,3,0,0,0-.65,1c-2.41,5-4.8,10-7.23,15.05"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,25.83,9.66,14.88a3.54,3.54,0,0,1,.63-1.1A3.59,3.59,0,0,1,11.64,13l3-1.14a5.62,5.62,0,0,0,2-1.05,5.39,5.39,0,0,0,.83-1.17L20.26,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,11,9.13,9.57a2.63,2.63,0,0,0,.66-.3,2.62,2.62,0,0,0,.65-.79c.73-1.16,1.44-2.33,2.19-3.47"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.82,95.45Q61.89,88,67,80.65a9.81,9.81,0,0,0,.83-1.39c1.1-2.4.39-6.14.39-8.74V60.12c0-1.54,0-3.08,0-4.61.07-1.94,1.12-3.28,2.22-4.77,2.69-3.64,5.51-7.2,8-11,5.24-7.79,3.4-9.59,5.18-18.67.34-1.7,6-10.8,7.26-11.61C93.26,8,94.18,6.6,96.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.83,5c-3.33,4.74-6.74,9.41-10,14.2-2.92,4.29-4.26,9.16-5.78,14.05a145.2,145.2,0,0,0-3.63,14.22c-.92,4.6-.49,9-.84,13.59-.23,3-1.5,5.4-2.61,8.19Q87.24,76.15,84.39,83L72.75,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M85.94,95.45l4-7.13c2.71-4.8,2.12-11.46,5.7-15.5,2.33-2.65,5.12-5.25,7.09-8.2.75-1.13-.06-4.48-.17-5.79-.31-3.77-1.41-8.12-.93-11.89.53-4.22,3.77-9,5.5-12.94,1-2.21,3-3.88,4.55-5.7,2.51-3,4.89-6.11,7.34-9.17,2.73-3.4,2.94-9.89,3.88-14.12"
        />
        <path
          stroke="black"
          fill="none"
          d="M129.38,5c-3,7.68-5.5,15.66-9.5,22.93-1.56,2.85-3.24,5.64-4.93,8.42-1.36,2.24-3.85,5.06-4.37,7.64-.82,4.13-.4,9-.43,13.17,0,3.46.79,7-.41,10.26-1.72,4.72-3.93,9.26-5.55,14A60,60,0,0,0,101.71,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M138.43,5c-5.82,12.86-9,26.58-11.88,40.34-1,4.86-3.52,9.27-5.82,13.63-1.14,2.17-2.19,3.84-2.21,6.29,0,2.82,0,5.65,0,8.48,0,4.41-2.47,8.85-4,12.93L111.28,95"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.85,95.45c2.12-3.21,4.5-6.46,6-10,2.39-5.92,2.15-12.69,3.81-18.87,1.12-4.2,1.92-8.71,3.45-12.77a94,94,0,0,1,5-10.61c1.08-2,2.56-3.83,3.61-5.89,3-5.8-3.51-11.49-1.1-17.37a51.24,51.24,0,0,1,4.12-7L154.47,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.23,21.17a51.23,51.23,0,0,0-4.06,3.74,44.46,44.46,0,0,0-4.36,5.33A105.27,105.27,0,0,0,160,41.94a50.28,50.28,0,0,0-2.86,6.14,49.41,49.41,0,0,0-1.6,6c-1.47,6.57-4.45,14.25-4.18,21a7.73,7.73,0,0,0,2.17,5.36c5.75-.74,10.64-4.37,15.25-7.88l5.94-4.53a14.92,14.92,0,0,0,4.55-5.49,45,45,0,0,0,4-13.85c.65-5.16-.23-10.33.32-15.44.29-2.63,1.33-5.1,2.06-7.64a34.08,34.08,0,0,0,1.17-13C182.9,15.45,178.89,18.12,175.23,21.17Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M177,28.55a48.67,48.67,0,0,0-3.45,4.59c-2.85,4.22-5.91,8.58-7.4,13.51-1,3.41-1.93,6.86-2.89,10.29l-4.14,9,6.09-1.3c.9-.19,2.61-.21,3.39-.73,1.73-1.15,2.4-3.67,3.3-5.42.58-1.15,2.74-3.5,2.74-4.7-.14-2.23.81-5,1.16-7.2,1.23-7.8,3.79-15.25,6.08-22.8-.35,1.15-3.42,3.17-4.37,4.22Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,5a134.62,134.62,0,0,0-20.18,24c-5.85,8.55-8.63,19.39-10.83,29.41Q146.24,64.19,145,70c-.68,3.1-.15,4.79.44,7.82.73,3.76,1.68,7.19,1.15,11a46.35,46.35,0,0,0-.88,6.19"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.11,95c1.14-2.78,3.57-6.53,3.87-9.48a18.31,18.31,0,0,0-.59-3.94l-1.34-8.94c-.64-4.25,0-8.66,0-12.93,0-1.6,2.33-4.55,3-5.94,1.46-2.84,3.37-5.73,4.49-8.71,1.17-3.14,1.59-6.87,2.23-10.16s.07-6.5.26-9.86c.16-2.95,2.41-5.29,3.9-7.67C155.34,12,162.58,8.65,167.65,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M152.91,94.22c.49-2.39.55-6.43,2.27-8,2.24-2,6.71-3.09,9.44-4.41,3-1.43,5.94-2.87,8.87-4.4C180,74,183,67.84,186.79,61.78c2-6.41,6.2-19.84,8.21-26.32"
        />
        <path
          stroke="black"
          fill="none"
          d="M164,95.45c1.85-2.87,3.46-6.62,6.39-8.48a35.2,35.2,0,0,1,7.92-4.15c2-.63,5.32-.59,6.9-2.17,1-1,9.76-14,9.76-14"
        />
        <path
          stroke="black"
          fill="none"
          d="M178.51,95c2.8-3.56,7.17-5.79,10.27-9.16L195,79.1"
        />
        <path
          stroke="black"
          fill="none"
          d="M189.63,95c0-4.19,2.32-5.42,5.37-8"
        />
        <path
          stroke="black"
          fill="none"
          d="M195,24.8c0-2.84-.92-5.89-1.41-8.67-.39-2.19-.49-4.62-1.59-6.59s-3.36-3.2-5.21-4.53"
        />
        <path
          stroke="black"
          fill="none"
          d="M5,59.59c2.79-3.69,4.92-6.79,6.36-8.93,3.6-5.39,4.5-7.37,7.67-11.44,2.63-3.39,4-5.14,6.42-7.12,4-3.3,5.54-2.73,9.77-5.86A32.73,32.73,0,0,0,43,17.87,32.13,32.13,0,0,0,47.77,5"
        />
        <path
          stroke="black"
          fill="none"
          d="M64.58,95a54.56,54.56,0,0,0,9.11-12.48c2.85-5.38,4.24-10.61,6.89-20.84,2.47-9.54,1.9-9.08,3.72-15.63a122.89,122.89,0,0,1,7.81-21.76A104.05,104.05,0,0,1,104.2,5"
        />
      </g>
    </svg>
  );
}
