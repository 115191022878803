export default function LayerSteelProfile(props: any) {
  return (
    <svg
      height={props.thickness}
      width={props.thickness}
      viewBox="160 106 35 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        stroke="rgb(0, 0, 0)"
        strokeWidth={"3px"}
        x1="140.184"
        y1="165.867"
        x2="140.184"
        y2="160.244"
        transform="matrix(0, -1, 1, 0, -22.871498, 303.239502)"
      />
      <line
        stroke="rgb(0, 0, 0)"
        strokeWidth={"3px"}
        x1="120.045"
        y1="145.755"
        x2="154.647"
        y2="145.755"
        transform="matrix(0, -1, 1, 0, -8.409004, 283.101013)"
      />
      <line
        stroke="rgb(0, 0, 0)"
        strokeWidth={"3px"}
        x1="156.999"
        y1="132.346"
        x2="142.294"
        y2="138.401"
        transform="matrix(0, -1, 1, 0, 14.273003, 285.019989)"
      />
      <line
        stroke="rgb(0, 0, 0)"
        strokeWidth={"3px"}
        x1="176.761"
        y1="118.073"
        x2="177.193"
        y2="166.949"
        transform="matrix(0, -1, 1, 0, 34.465996, 319.488007)"
      />
      <line
        stroke="rgb(0, 0, 0)"
        strokeWidth={"3px"}
        x1="142.074"
        y1="133.456"
        x2="142.074"
        y2="123.508"
        transform="matrix(0, -1, 1, 0, 13.592007, 270.556)"
      />
      <line
        stroke="rgb(0, 0, 0)"
        strokeWidth={"3px"}
        x1="212.162"
        y1="138.734"
        x2="197.457"
        y2="132.678"
        transform="matrix(0, 1, -1, 0, 340.515503, -69.103508)"
      />
      <line
        stroke="rgb(0, 0, 0)"
        strokeWidth={"3px"}
        x1="212.151"
        y1="133.445"
        x2="212.151"
        y2="123.497"
        transform="matrix(0, -1, 1, 0, 83.679993, 340.622009)"
      />
      <line
        stroke="rgb(0, 0, 0)"
        strokeWidth={"3px"}
        x1="213.981"
        y1="160.258"
        x2="213.981"
        y2="165.881"
        transform="matrix(0, -1, 1, 0, 50.911507, 377.050507)"
      />
      <line
        stroke="rgb(0, 0, 0)"
        strokeWidth={"3px"}
        x1="199.519"
        y1="145.769"
        x2="234.121"
        y2="145.769"
        transform="matrix(0, -1, 1, 0, 71.051003, 362.588989)"
      />
    </svg>
  );
}
