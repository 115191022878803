import { Box, Typography, Paper, makeStyles, Theme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { TypographyTypeMap } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  h1: {
    fontSize: 48,
    fontWeight: 600,
    fontFamily: "'Quicksand', sans-serif",
  },
  h2: {
    fontSize: 32,
    fontFamily: "'RalewayBold', sans-serif",
    fontWeight: 600,
  },
  h3: {
    fontSize: 36,
    fontFamily: "'TrebuchetBold', sans-serif",
  },
  h4: {
    fontSize: 20,
    fontWeight: 600,
  },

  h5: {
    fontSize: 14,
    fontWeight: 800,
    fontFamily: "'TrebuchetBold', sans-serif",
    whiteSpace: "pre-line",
    color: grey[800],
  },
  h6: {
    fontFamily: "'Quicksand', sans-serif",
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: "pre-line",
  },

  caption: {
    fontFamily: "'Quicksand', sans-serif",
    fontSize: 10,
    color: grey[600],
    fontWeight: 600,
  },

  body1: {
    fontFamily: "'Quicksand', sans-serif",
    fontSize: 12,
    color: grey[600],
    whiteSpace: "pre-line",
  },
  subtitle2: {
    fontFamily: "'Quicksand', sans-serif",
    fontSize: 14,
    color: grey[600],
    // fontWeight: 600,
    whiteSpace: "pre-line",
  },
  // fontFamily: "'Quicksand', sans-serif"
}));

interface IReportTypography {
  variant:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "caption"
    | "body1"
    | "subtitle2";
  children: React.ReactNode;
  align?: "center";
  style?: any;
}

export function ReportTypography(props: IReportTypography) {
  const classes = useStyles();

  const classFromVariant = classes[props.variant];

  return <Typography {...props} className={classFromVariant} />;
}
