import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import LoginComponent from "../lib/components/authed/login-component";
import {
  useGetMeQuery,
  useAcceptInviteMutation,
  namedOperations,
} from "../lib/generated/graphql";
import { useGlobalState } from "../providers/GlobalStateProvider";
import VerifyEmailPrompt from "../lib/components/authed/verify-email-prompt";

export function SimplifiedTenantReroute() {
  const [query] = useSearchParams();
  const loginDetails = query.get("loginDetails");
  const { projectId } =
    loginDetails == null ? null : JSON.parse(atob(loginDetails));
  const emailVerificationRequired = useGlobalState().emailVerificationRequired;
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const { data } = useGetMeQuery();
  const [acceptInvite] = useAcceptInviteMutation({
    refetchQueries: [namedOperations.Query.GetMe],
  });

  useEffect(() => {
    if (data?.me?.scopes.length === 0 && loginDetails != null) {
      acceptInvite({ variables: { id: loginDetails } });
    }
    if ((data?.me?.scopes?.length ?? 0) > 0) {
      setLoggedIn(true);
    }
  }, [data?.me?.scopes]);

  if (!loggedIn) {
    return <LoginComponent onLoggedIn={() => setLoggedIn(true)} />;
  }

  if (emailVerificationRequired) {
    return <VerifyEmailPrompt />;
  }

  if (data?.me?.scopes.includes("SIMPLIFIED_TENANT_ADAPTION")) {
    return <Navigate to={`/sta/projects/${projectId}`} />;
  }

  return <div>Loading and checking auth</div>;
}
