import React, { useEffect, useState } from "react";
import { useSimplifiedTenantAdaptionPatchProjectMutation } from "../../lib/generated/graphql";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

const SubmitProjectDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  projectId: string;
  mainScenarioId?: string;
  scenarios: { id: string; name: string }[];
}> = ({ projectId, open, onClose, mainScenarioId, scenarios }) => {
  const [patchProjectDetails, { loading }] =
    useSimplifiedTenantAdaptionPatchProjectMutation({
      onCompleted: () => onClose(),
    });
  const [newMainScenarioId, setNewMainScenarioId] = useState<string>();
  useEffect(() => {
    if (mainScenarioId && scenarios.some(({ id }) => id === mainScenarioId)) {
      setNewMainScenarioId(mainScenarioId);
    } else if (scenarios.length === 1) {
      setNewMainScenarioId(scenarios[0].id);
    }
  }, [mainScenarioId, scenarios, setNewMainScenarioId]);

  return (
    <Dialog onClose={onClose} aria-labelledby="qto-dialog" open={open}>
      <DialogTitle id="form-dialog-title">Klarmarkera beräkning</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Genom att klarmarkera beräkningen kommer projektet att tas med i
          bolagets hållbarhetsrapportering.
        </DialogContentText>
        {scenarios?.length > 1 && (
          <>
            <DialogContentText>
              Vänligen välj vilket scenario som valts i projektet.
            </DialogContentText>
            <FormControl disabled={loading} component="fieldset">
              <FormLabel component="legend">Valt scenario</FormLabel>
              <RadioGroup
                value={newMainScenarioId}
                name="radio-buttons-group"
                onChange={(e) => setNewMainScenarioId(e.target.value)}
              >
                {scenarios.map((s) => (
                  <FormControlLabel
                    key={s.id}
                    value={s.id}
                    control={<Radio />}
                    label={s.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => onClose()} color="secondary">
          Avbryt
        </Button>
        <Button
          disabled={loading}
          onClick={async () => {
            await patchProjectDetails({
              variables: {
                id: projectId,
                patch: {
                  markedAsDone: true,
                  mainScenarioId: newMainScenarioId,
                },
                reportTemplatePatch: {},
              },
            });
          }}
          color="primary"
        >
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitProjectDialog;
