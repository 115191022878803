import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { grey } from "@material-ui/core/colors";
import { Chip } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: grey[300],
  },
}));

interface IMUTabsProps {
  onChange: (newTab: string) => void;
  value: string;
  tabs: { label: string; id: string; isSelected?: boolean }[];
}

export default function MUTabs(props: IMUTabsProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tabs
        value={props.value}
        onChange={(e, value) => {
          props.onChange(value);
        }}
        aria-label="simple tabs example"
      >
        {props.tabs.map((tab) => {
          return (
            <Tab
              icon={
                tab.isSelected ? (
                  <Chip
                    style={{ height: 18, fontSize: 9 }}
                    label={"VALT SCENARIO"}
                    size="small"
                    color="primary"
                  />
                ) : undefined
              }
              key={tab.id}
              value={tab.id}
              style={{ fontWeight: 600 }}
              label={tab.label}
            />
          );
        })}
      </Tabs>
    </div>
  );
}
