import { Box } from "@material-ui/core";
import { BuildingElementDetailedFieldsFragment } from "../../../lib/generated/graphql";
import {
  BuildingElementCard,
  getUnit,
} from "../../../lib/components/building-element/building-element-card";
import { ReportTypography } from "./report-typography";
import { unnestGQLBuildingElement } from "../../../lib/components/building-element/common";

interface IReportBuildingElementProps {
  buildingElement: BuildingElementDetailedFieldsFragment;
}

export function ReportSimplifiedTenantAdaptionBuildingElement(
  props: IReportBuildingElementProps
) {
  const buildingElementUnnestedProducts = unnestGQLBuildingElement(
    props.buildingElement
  );

  return (
    <Box>
      <Box mb={2} borderBottom={2} width="55%">
        <ReportTypography variant="h5">
          {props.buildingElement.name}
        </ReportTypography>
      </Box>
      <BuildingElementCard
        buildingElement={buildingElementUnnestedProducts}
        columns={[
          { key: "thickness", label: "Tjocklek" },
          { key: "amount", label: "Mängd" },
          { key: "name", label: "" },
          {
            key: "A1_A5",
            label: (be) => `CO₂e/${getUnit(be.unit ?? undefined)}`,
          },
        ]}
      />
    </Box>
  );
}
