import "katex/dist/katex.min.css";
import { Box, useTheme, Grid } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportTypography } from "../components/report-typography";
import { DefaultTheme } from "../../../providers/styling";

export default function ReportMethodPage(props: {
  pageNumber?: number;
  reportType: string;
}) {
  const theme = useTheme<DefaultTheme>();

  const pageTitle = "Redovisning och metod".toUpperCase();
  const headerPart1 = `Omfattning och datakällor`;
  const textPart1 = `Den klimatberäkning som presenteras i denna rapport omfattar hela byggnaden, det vill säga byggdel 1-8 enligt SBEFs byggdelstabell (se bilaga “Redovisade byggdelar enligt SBEF”). De byggdelar som beräknas utifrån projektets underlag kopplas antingen till generisk klimatdata från framförallt Boverkets databas (typiska värden) eller produktspecifika EPDer.\n\n Byggdel 7 och 8 (enligt SBEF) samt energianvändningen på byggarbetsplats beräknas genom schabloner från KTHs rapport "Referensvärden för klimatpåverkan vid uppförande av byggnader" (de återfinns också i IVLs "Anvisningar för LCA-beräkning av byggprojekt" men där är de konservativa genom ett påslag med 25 %).\n\n Byggdel 1 för mark samt delar av byggdel 2 (20-23+25) beräknas med en icke-konservativ variant av schablonen från IVLs "Anvisningar för LCA-beräkning av byggprojekt". I denna schablon ingår pålning men om projektet kan leverera underlag så görs ett avdrag från schablonen utifrån Plants erfarenhetsvärde och pålningen beräknas istället specifikt för projektet. \n\n`;
  const headerPart3 = `Metod och standarder`;
  const textPart3 = `Denna rapport är framtagen med utgångspunkt i SS-EN 15978:2011, samtliga produkter är tolkade till data framtaget utifrån materialstandarden SS-EN 15804:2012+A1:2013 samt i vissa fall SS-EN 15804:2012+A2:2019.`;

  const headerPart4 = `Biogen kolinlagring`;
  const textPart4 = `Den miljöpåverkanskategori som används är GWP-GHG (även kallad GWP-IOBC) vilken inte inkluderar upptag och utsläpp av biogen koldioxid. GWP-GHG har valts eftersom endast byggskedet (A1-A5) studeras samt att det är den miljöpåverkanskategori som används för klimatdeklarationer. Positiva effekter av biogen kolinlagring är något som sker över en byggnads livscykel och tillhör därför B-skedet.`;

  return (
    <ReportContainer>
      <ReportHeader chapter="Tillvägagångssätt" reportType={props.reportType} />

      <Box mt={4} flex={1}>
        <Box textAlign="left" maxWidth={theme.spacing(100)}>
          <Box mb={2}>
            <ReportTypography variant="h2">{pageTitle}</ReportTypography>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ReportTypography variant="h6">{headerPart1}</ReportTypography>
              <ReportTypography variant="body1">{textPart1}</ReportTypography>
              <ReportTypography variant="h6">{headerPart3}</ReportTypography>
              <ReportTypography variant="body1">{textPart3}</ReportTypography>
            </Grid>
            <Grid item xs={6}>
              <ReportTypography variant="h6">{headerPart4}</ReportTypography>
              <ReportTypography variant="body1">{textPart4}</ReportTypography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ReportFooter pageNumber={props.pageNumber} />
    </ReportContainer>
  );
}
