import { Box } from "@material-ui/core";

export default function LayerWood(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94 4000">
      <rect
        id="backgroundrect"
        width="100%"
        height="100%"
        x="0"
        y="0"
        fill="none"
        stroke="none"
      />
      <g>
        <title>Layer 1</title>
        <g
          id="svg_29"
          transform="rotate(-90.05069732666016 45.87718963623046,95.66358947753906) "
        >
          <path
            stroke="black"
            fill="none"
            d="M-13.442815847396844,50.43859529495239 L-23.12281584739685,63.288595294952394 a27.07,27.07 0 0 1 -2.52,3 a27.57,27.57 0 0 1 -3.72,2.91 L-40.57281584739684,76.93859529495239 A7.86,7.86 0 0 0 -42.12281584739685,78.1785952949524 a7.59,7.59 0 0 0 -1.2,2.31 L-49.12281584739685,95.66859529495238 "
            id="svg_30"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.04281584739687,121.43859529495239 A131.35,131.35 0 0 1 -33.752815847396846,100.0885952949524 a29.92,29.92 0 0 1 4.49,-4.37 c4,-3 9.14,-4.06 13.44,-6.62 A72.33,72.33 0 0 1 -21.61281584739686,112.43859529495239 A84.38,84.38 0 0 1 -30.262815847396837,127.23859529495239 c-3.22,4.52 -7.32,8.56 -10.32,13.2 "
            id="svg_31"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.12281584739685,130.9685952949524 L-35.63281584739684,109.89859529495239 a35.16,35.16 0 0 1 2.6900000000000004,-3.8 c2.47,-2.9 5.8,-4.8900000000000015 9.07,-6.84 L-31.932815847396853,116.18859529495239 c-0.7100000000000006,1.48 -1.42,3 -2.27,4.36 C-37.91281584739684,126.6185952949524 -44.12281584739685,131.95859529495237 -49.12281584739685,136.94859529495238 "
            id="svg_32"
          />
          <path
            stroke="black"
            fill="none"
            d="M-31.282815847396847,140.4385952949524 L-16.12281584739685,117.28859529495239 a35,35 0 0 0 2.4,-4.09 a32.88,32.88 0 0 0 1.9300000000000002,-5.93 c1.32,-5.15 2.6100000000000003,-10.32 4.07,-15.43 c4.14,-14.53 9.73,-28.8 18.24,-41.28 "
            id="svg_33"
          />
          <path
            stroke="black"
            fill="none"
            d="M20.457184152603148,50.43859529495239 L7.197184152603143,69.3285952949524 a36.3,36.3 0 0 0 -3,4.77 A35.2,35.2 0 0 0 2.2671841526031358,79.43859529495239 l-6.67,21.7 a44.27,44.27 0 0 0 -1.84,7.49 c-0.7000000000000006,5.49 0.6100000000000009,11.24 -1.12,16.49 C-9.292815847396867,130.95859529495237 -14.632815847396841,135.00859529495239 -17.552815847396857,140.4385952949524 "
            id="svg_34"
          />
          <path
            stroke="black"
            fill="none"
            d="M-12.12281584739685,140.88859529495238 l8.46,-7.14 c2.63,-2.22 5.53,-3.7800000000000002 6.38,-7.37 c1.1,-4.6400000000000015 0.650000000000001,-9.83 0.9400000000000006,-14.57 c0.3400000000000006,-5.4 -0.45,-12.620000000000001 1.6400000000000001,-17.65 c4.12,-9.88 9.27,-19.43 13.870000000000001,-29.09 L30.26718415260315,50.43859529495239 "
            id="svg_35"
          />
          <path
            stroke="black"
            fill="none"
            d="M-4.052815847396857,63.638595294952395 c-3.6,3 -6.09,7.25 -9,10.85 a7.74,7.74 0 0 0 -1.3,2 a6.75,6.75 0 0 0 -0.38000000000000045,1.61 l-1,6.13 l6.94,-4.11 a2.84,2.84 0 0 0 1,-0.81 a3.17,3.17 0 0 0 0.3600000000000003,-1 C-6.392815847396861,73.8085952949524 -4.0428158473968665,68.1985952949524 -4.052815847396857,63.638595294952395 z"
            id="svg_36"
          />
          <path
            stroke="black"
            fill="none"
            d="M-22.482815847396864,50.43859529495239 c-3.45,4.23 -6.85,8.5 -10.28,12.75 A5.6,5.6 0 0 1 -33.942815847396844,64.43859529495239 a5.71,5.71 0 0 1 -1.9100000000000001,0.7100000000000006 l-4.18,1 a3,3 0 0 0 -1.21,0.5 a3,3 0 0 0 -0.650000000000001,1 c-2.41,5 -4.8,10 -7.23,15.05 "
            id="svg_37"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.12281584739685,71.26859529495239 L-44.462815847396854,60.318595294952395 a3.54,3.54 0 0 1 0.6300000000000009,-1.1 A3.59,3.59 0 0 1 -42.482815847396864,58.43859529495239 l3,-1.1400000000000001 a5.62,5.62 0 0 0 2,-1.05 a5.39,5.39 0 0 0 0.8300000000000006,-1.17 L-33.86281584739686,50.43859529495239 "
            id="svg_38"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.12281584739685,56.43859529495239 L-44.992815847396855,55.00859529495239 a2.63,2.63 0 0 0 0.6600000000000011,-0.3000000000000003 a2.62,2.62 0 0 0 0.650000000000001,-0.79 c0.7300000000000006,-1.1600000000000001 1.44,-2.33 2.19,-3.47 "
            id="svg_39"
          />
          <path
            stroke="black"
            fill="none"
            d="M2.6971841526031426,140.88859529495238 Q7.767184152603137,133.4385952949524 12.87718415260315,126.0885952949524 a9.81,9.81 0 0 0 0.8300000000000006,-1.3900000000000001 c1.1,-2.4 0.3900000000000005,-6.14 0.3900000000000005,-8.74 V105.5585952949524 c0,-1.54 0,-3.08 0,-4.61 c0.07,-1.94 1.12,-3.2800000000000002 2.22,-4.77 c2.6900000000000004,-3.64 5.51,-7.2 8,-11 c5.24,-7.79 3.4,-9.59 5.18,-18.67 c0.3400000000000006,-1.7000000000000002 6,-10.8 7.26,-11.61 C39.137184152603155,53.43859529495239 40.057184152603156,52.038595294952394 42.34718415260315,50.43859529495239 "
            id="svg_40"
          />
          <path
            stroke="black"
            fill="none"
            d="M58.70718415260313,50.43859529495239 c-3.33,4.74 -6.74,9.41 -10,14.2 c-2.92,4.29 -4.26,9.16 -5.78,14.05 a145.2,145.2 0 0 0 -3.63,14.22 c-0.92,4.6 -0.4900000000000003,9 -0.8400000000000006,13.59 c-0.23,3 -1.5,5.4 -2.6100000000000003,8.19 Q33.117184152603144,121.5885952949524 30.26718415260315,128.4385952949524 L18.62718415260315,140.4385952949524 "
            id="svg_41"
          />
          <path
            stroke="black"
            fill="none"
            d="M31.817184152603147,140.88859529495238 l4,-7.13 c2.71,-4.8 2.12,-11.46 5.7,-15.5 c2.33,-2.6500000000000004 5.12,-5.25 7.09,-8.2 c0.7500000000000009,-1.13 -0.06000000000000009,-4.48 -0.17,-5.79 c-0.31000000000000044,-3.77 -1.4100000000000001,-8.12 -0.93,-11.89 c0.53,-4.22 3.77,-9 5.5,-12.94 c1,-2.21 3,-3.88 4.55,-5.7 c2.51,-3 4.8900000000000015,-6.11 7.34,-9.17 c2.73,-3.4 2.94,-9.89 3.88,-14.12 "
            id="svg_42"
          />
          <path
            stroke="black"
            fill="none"
            d="M75.25718415260314,50.43859529495239 c-3,7.68 -5.5,15.66 -9.5,22.93 c-1.56,2.85 -3.24,5.6400000000000015 -4.93,8.42 c-1.36,2.24 -3.85,5.06 -4.37,7.6400000000000015 c-0.8200000000000006,4.13 -0.4,9 -0.4300000000000003,13.17 c0,3.46 0.79,7 -0.4100000000000003,10.26 c-1.7200000000000002,4.72 -3.93,9.26 -5.55,14 A60,60 0 0 0 47.58718415260314,140.4385952949524 "
            id="svg_43"
          />
          <path
            stroke="black"
            fill="none"
            d="M84.30718415260316,50.43859529495239 c-5.82,12.86 -9,26.58 -11.88,40.34 c-1,4.86 -3.52,9.27 -5.82,13.63 c-1.1400000000000001,2.17 -2.19,3.84 -2.21,6.29 c0,2.8200000000000003 0,5.65 0,8.48 c0,4.41 -2.47,8.85 -4,12.93 L57.15718415260315,140.4385952949524 "
            id="svg_44"
          />
          <path
            stroke="black"
            fill="none"
            d="M66.72718415260314,140.88859529495238 c2.12,-3.21 4.5,-6.46 6,-10 c2.39,-5.92 2.15,-12.69 3.81,-18.87 c1.12,-4.2 1.92,-8.71 3.45,-12.77 a94,94 0 0 1 5,-10.61 c1.08,-2 2.56,-3.83 3.6100000000000003,-5.8900000000000015 c3,-5.8 -3.51,-11.49 -1.1,-17.37 a51.24,51.24 0 0 1 4.12,-7 L100.34718415260315,50.43859529495239 "
            id="svg_45"
          />
          <path
            stroke="black"
            fill="none"
            d="M121.10718415260314,66.6085952949524 a51.23,51.23 0 0 0 -4.06,3.74 a44.46,44.46 0 0 0 -4.36,5.33 A105.27,105.27 0 0 0 105.87718415260315,87.37859529495239 a50.28,50.28 0 0 0 -2.8600000000000003,6.14 a49.41,49.41 0 0 0 -1.6,6 c-1.47,6.57 -4.45,14.25 -4.18,21 a7.73,7.73 0 0 0 2.17,5.36 c5.75,-0.7400000000000009 10.64,-4.37 15.25,-7.88 l5.94,-4.53 a14.92,14.92 0 0 0 4.55,-5.49 a45,45 0 0 0 4,-13.85 c0.650000000000001,-5.16 -0.23,-10.33 0.3200000000000005,-15.44 c0.2900000000000003,-2.63 1.33,-5.1 2.06,-7.6400000000000015 a34.08,34.08 0 0 0 1.17,-13 C128.77718415260316,60.888595294952395 124.76718415260314,63.5585952949524 121.10718415260314,66.6085952949524 z"
            id="svg_46"
          />
          <path
            stroke="black"
            fill="none"
            d="M122.87718415260315,73.98859529495239 a48.67,48.67 0 0 0 -3.45,4.59 c-2.85,4.22 -5.91,8.58 -7.4,13.51 c-1,3.41 -1.9300000000000002,6.86 -2.89,10.29 l-4.14,9 l6.09,-1.3 c0.9,-0.19000000000000025 2.6100000000000003,-0.21000000000000021 3.39,-0.7300000000000006 c1.73,-1.15 2.4,-3.67 3.3,-5.42 c0.5800000000000006,-1.15 2.74,-3.5 2.74,-4.7 c-0.14,-2.23 0.81,-5 1.1600000000000001,-7.2 c1.23,-7.8 3.79,-15.25 6.08,-22.8 c-0.3500000000000003,1.15 -3.42,3.17 -4.37,4.22 z"
            id="svg_47"
          />
          <path
            stroke="black"
            fill="none"
            d="M124.38718415260314,50.43859529495239 a134.62,134.62 0 0 0 -20.18,24 c-5.85,8.55 -8.63,19.39 -10.83,29.41 Q92.11718415260316,109.62859529495239 90.87718415260315,115.43859529495239 c-0.6800000000000012,3.1 -0.15000000000000022,4.79 0.44000000000000045,7.82 c0.7300000000000006,3.7600000000000002 1.6800000000000006,7.19 1.15,11 a46.35,46.35 0 0 0 -0.8800000000000009,6.19 "
            id="svg_48"
          />
          <path
            stroke="black"
            fill="none"
            d="M81.98718415260316,140.4385952949524 c1.1400000000000001,-2.7800000000000002 3.5700000000000003,-6.53 3.87,-9.48 a18.31,18.31 0 0 0 -0.5900000000000006,-3.94 l-1.34,-8.94 c-0.640000000000001,-4.25 0,-8.66 0,-12.93 c0,-1.6 2.33,-4.55 3,-5.94 c1.46,-2.84 3.37,-5.73 4.49,-8.71 c1.17,-3.14 1.59,-6.87 2.23,-10.16 s0.07,-6.5 0.26,-9.86 c0.16000000000000028,-2.95 2.41,-5.29 3.9,-7.67 C101.21718415260315,57.43859529495239 108.45718415260316,54.08859529495239 113.52718415260316,50.43859529495239 "
            id="svg_49"
          />
          <path
            stroke="black"
            fill="none"
            d="M98.78718415260315,139.6585952949524 c0.4900000000000003,-2.39 0.55,-6.43 2.27,-8 c2.24,-2 6.71,-3.09 9.44,-4.41 c3,-1.4300000000000002 5.94,-2.87 8.870000000000001,-4.4 C125.87718415260315,119.43859529495239 128.87718415260315,113.2785952949524 132.66718415260314,107.2185952949524 c2,-6.41 6.2,-19.84 8.21,-26.32 "
            id="svg_50"
          />
          <path
            stroke="black"
            fill="none"
            d="M109.87718415260315,140.88859529495238 c1.85,-2.87 3.46,-6.62 6.39,-8.48 a35.2,35.2 0 0 1 7.92,-4.15 c2,-0.6300000000000009 5.32,-0.5900000000000006 6.9,-2.17 c1,-1 9.76,-14 9.76,-14 "
            id="svg_51"
          />
          <path
            stroke="black"
            fill="none"
            d="M124.38718415260314,140.4385952949524 c2.8,-3.56 7.17,-5.79 10.27,-9.16 L140.87718415260315,124.53859529495239 "
            id="svg_52"
          />
          <path
            d="M135.50718415260314,140.4385952949524 c0,-4.19 2.3200000000000003,-5.42 5.37,-8 "
            stroke="black"
            fill="none"
            id="svg_53"
          />
          <path
            stroke="black"
            fill="none"
            d="M140.87718415260315,70.23859529495239 c0,-2.84 -0.92,-5.8900000000000015 -1.4100000000000001,-8.67 c-0.3900000000000005,-2.19 -0.4900000000000003,-4.62 -1.59,-6.59 s-3.36,-3.2 -5.21,-4.53 "
            id="svg_54"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.12281584739685,105.0285952949524 c2.79,-3.6900000000000004 4.92,-6.79 6.36,-8.93 c3.6,-5.39 4.5,-7.37 7.67,-11.44 c2.63,-3.39 4,-5.14 6.42,-7.12 c4,-3.3 5.54,-2.73 9.77,-5.86 A32.73,32.73 0 0 0 -11.12281584739685,63.3085952949524 A32.13,32.13 0 0 0 -6.3528158473968395,50.43859529495239 "
            id="svg_55"
          />
          <path
            stroke="black"
            fill="none"
            d="M10.457184152603133,140.4385952949524 a54.56,54.56 0 0 0 9.11,-12.48 c2.85,-5.38 4.24,-10.61 6.8900000000000015,-20.84 c2.47,-9.54 1.9,-9.08 3.72,-15.63 a122.89,122.89 0 0 1 7.8100000000000005,-21.76 A104.05,104.05 0 0 1 50.07718415260315,50.43859529495239 "
            id="svg_56"
          />
        </g>
        <g
          transform="rotate(-90.05069732666016 46.57894134521484,288.9092102050781) "
          id="svg_1"
        >
          <path
            stroke="black"
            fill="none"
            d="M-12.741061992645257,243.68420958518982 L-22.421061992645264,256.53420958518984 a27.07,27.07 0 0 1 -2.52,3 a27.57,27.57 0 0 1 -3.72,2.91 L-39.87106199264525,270.1842095851898 A7.86,7.86 0 0 0 -41.421061992645264,271.42420958518983 a7.59,7.59 0 0 0 -1.2,2.31 L-48.421061992645264,288.91420958518984 "
            id="svg_2"
          />
          <path
            stroke="black"
            fill="none"
            d="M-48.34106199264528,314.6842095851898 A131.35,131.35 0 0 1 -33.05106199264526,293.3342095851898 a29.92,29.92 0 0 1 4.49,-4.37 c4,-3 9.14,-4.06 13.44,-6.62 A72.33,72.33 0 0 1 -20.911061992645273,305.6842095851898 A84.38,84.38 0 0 1 -29.56106199264525,320.48420958518983 c-3.22,4.52 -7.32,8.56 -10.32,13.2 "
            id="svg_3"
          />
          <path
            stroke="black"
            fill="none"
            d="M-48.421061992645264,324.2142095851898 L-34.931061992645255,303.1442095851898 a35.16,35.16 0 0 1 2.6900000000000004,-3.8 c2.47,-2.9 5.8,-4.8900000000000015 9.07,-6.84 L-31.231061992645266,309.4342095851898 c-0.7100000000000006,1.48 -1.42,3 -2.27,4.36 C-37.211061992645256,319.8642095851898 -43.421061992645264,325.2042095851898 -48.421061992645264,330.1942095851898 "
            id="svg_4"
          />
          <path
            stroke="black"
            fill="none"
            d="M-30.58106199264526,333.6842095851898 L-15.421061992645264,310.53420958518984 a35,35 0 0 0 2.4,-4.09 a32.88,32.88 0 0 0 1.9300000000000002,-5.93 c1.32,-5.15 2.6100000000000003,-10.32 4.07,-15.43 c4.14,-14.53 9.73,-28.8 18.24,-41.28 "
            id="svg_5"
          />
          <path
            stroke="black"
            fill="none"
            d="M21.158938007354735,243.68420958518982 L7.8989380073547295,262.5742095851898 a36.3,36.3 0 0 0 -3,4.77 A35.2,35.2 0 0 0 2.9689380073547227,272.6842095851898 l-6.67,21.7 a44.27,44.27 0 0 0 -1.84,7.49 c-0.7000000000000006,5.49 0.6100000000000014,11.24 -1.12,16.49 C-8.59106199264528,324.2042095851898 -13.931061992645255,328.2542095851898 -16.85106199264527,333.6842095851898 "
            id="svg_6"
          />
          <path
            stroke="black"
            fill="none"
            d="M-11.421061992645264,334.1342095851898 l8.46,-7.14 c2.63,-2.22 5.53,-3.7800000000000002 6.38,-7.37 c1.1,-4.6400000000000015 0.6500000000000017,-9.83 0.9400000000000006,-14.57 c0.34000000000000097,-5.4 -0.45,-12.620000000000001 1.6400000000000001,-17.65 c4.12,-9.88 9.27,-19.43 13.870000000000001,-29.09 L30.968938007354737,243.68420958518982 "
            id="svg_7"
          />
          <path
            stroke="black"
            fill="none"
            d="M-3.3510619926452705,256.8842095851898 c-3.6,3 -6.09,7.25 -9,10.85 a7.74,7.74 0 0 0 -1.3,2 a6.75,6.75 0 0 0 -0.3800000000000007,1.61 l-1,6.13 l6.94,-4.11 a2.84,2.84 0 0 0 1,-0.81 a3.17,3.17 0 0 0 0.3600000000000003,-1 C-5.691061992645272,267.0542095851898 -3.3410619926452796,261.4442095851898 -3.3510619926452705,256.8842095851898 z"
            id="svg_8"
          />
          <path
            stroke="black"
            fill="none"
            d="M-21.781061992645277,243.68420958518982 c-3.45,4.23 -6.85,8.5 -10.28,12.75 A5.6,5.6 0 0 1 -33.24106199264526,257.6842095851898 a5.71,5.71 0 0 1 -1.9100000000000001,0.7100000000000006 l-4.18,1 a3,3 0 0 0 -1.21,0.5 a3,3 0 0 0 -0.6500000000000017,1 c-2.41,5 -4.8,10 -7.23,15.05 "
            id="svg_9"
          />
          <path
            stroke="black"
            fill="none"
            d="M-48.421061992645264,264.5142095851898 L-43.76106199264527,253.56420958518981 a3.54,3.54 0 0 1 0.6300000000000014,-1.1 A3.59,3.59 0 0 1 -41.78106199264528,251.68420958518982 l3,-1.1400000000000001 a5.62,5.62 0 0 0 2,-1.05 a5.39,5.39 0 0 0 0.8300000000000006,-1.17 L-33.16106199264527,243.68420958518982 "
            id="svg_10"
          />
          <path
            stroke="black"
            fill="none"
            d="M-48.421061992645264,249.68420958518982 L-44.29106199264527,248.2542095851898 a2.63,2.63 0 0 0 0.6600000000000019,-0.3000000000000003 a2.62,2.62 0 0 0 0.6500000000000017,-0.79 c0.7300000000000006,-1.1600000000000001 1.44,-2.33 2.19,-3.47 "
            id="svg_11"
          />
          <path
            stroke="black"
            fill="none"
            d="M3.3989380073547295,334.1342095851898 Q8.468938007354723,326.6842095851898 13.578938007354736,319.3342095851898 a9.81,9.81 0 0 0 0.8300000000000006,-1.3900000000000001 c1.1,-2.4 0.39000000000000085,-6.14 0.39000000000000085,-8.74 V298.8042095851898 c0,-1.54 0,-3.08 0,-4.61 c0.07,-1.94 1.12,-3.2800000000000002 2.22,-4.77 c2.6900000000000004,-3.64 5.51,-7.2 8,-11 c5.24,-7.79 3.4,-9.59 5.18,-18.67 c0.34000000000000097,-1.7000000000000002 6,-10.8 7.26,-11.61 C39.83893800735474,246.68420958518982 40.75893800735474,245.2842095851898 43.048938007354735,243.68420958518982 "
            id="svg_12"
          />
          <path
            stroke="black"
            fill="none"
            d="M59.40893800735472,243.68420958518982 c-3.33,4.74 -6.74,9.41 -10,14.2 c-2.92,4.29 -4.26,9.16 -5.78,14.05 a145.2,145.2 0 0 0 -3.63,14.22 c-0.92,4.6 -0.4900000000000003,9 -0.8400000000000006,13.59 c-0.23,3 -1.5,5.4 -2.6100000000000003,8.19 Q33.81893800735473,314.8342095851898 30.968938007354737,321.6842095851898 L19.328938007354736,333.6842095851898 "
            id="svg_13"
          />
          <path
            stroke="black"
            fill="none"
            d="M32.518938007354734,334.1342095851898 l4,-7.13 c2.71,-4.8 2.12,-11.46 5.7,-15.5 c2.33,-2.6500000000000004 5.12,-5.25 7.09,-8.2 c0.7500000000000014,-1.13 -0.06000000000000013,-4.48 -0.17,-5.79 c-0.3100000000000007,-3.77 -1.4100000000000001,-8.12 -0.93,-11.89 c0.53,-4.22 3.77,-9 5.5,-12.94 c1,-2.21 3,-3.88 4.55,-5.7 c2.51,-3 4.8900000000000015,-6.11 7.34,-9.17 c2.73,-3.4 2.94,-9.89 3.88,-14.12 "
            id="svg_14"
          />
          <path
            stroke="black"
            fill="none"
            d="M75.95893800735473,243.68420958518982 c-3,7.68 -5.5,15.66 -9.5,22.93 c-1.56,2.85 -3.24,5.6400000000000015 -4.93,8.42 c-1.36,2.24 -3.85,5.06 -4.37,7.6400000000000015 c-0.8200000000000006,4.13 -0.4,9 -0.4300000000000003,13.17 c0,3.46 0.79,7 -0.4100000000000003,10.26 c-1.7200000000000002,4.72 -3.93,9.26 -5.55,14 A60,60 0 0 0 48.28893800735473,333.6842095851898 "
            id="svg_15"
          />
          <path
            stroke="black"
            fill="none"
            d="M85.00893800735474,243.68420958518982 c-5.82,12.86 -9,26.58 -11.88,40.34 c-1,4.86 -3.52,9.27 -5.82,13.63 c-1.1400000000000001,2.17 -2.19,3.84 -2.21,6.29 c0,2.8200000000000003 0,5.65 0,8.48 c0,4.41 -2.47,8.85 -4,12.93 L57.85893800735474,333.6842095851898 "
            id="svg_16"
          />
          <path
            stroke="black"
            fill="none"
            d="M67.42893800735473,334.1342095851898 c2.12,-3.21 4.5,-6.46 6,-10 c2.39,-5.92 2.15,-12.69 3.81,-18.87 c1.12,-4.2 1.92,-8.71 3.45,-12.77 a94,94 0 0 1 5,-10.61 c1.08,-2 2.56,-3.83 3.6100000000000003,-5.8900000000000015 c3,-5.8 -3.51,-11.49 -1.1,-17.37 a51.24,51.24 0 0 1 4.12,-7 L101.04893800735474,243.68420958518982 "
            id="svg_17"
          />
          <path
            stroke="black"
            fill="none"
            d="M121.80893800735473,259.85420958518984 a51.23,51.23 0 0 0 -4.06,3.74 a44.46,44.46 0 0 0 -4.36,5.33 A105.27,105.27 0 0 0 106.57893800735474,280.6242095851898 a50.28,50.28 0 0 0 -2.8600000000000003,6.14 a49.41,49.41 0 0 0 -1.6,6 c-1.47,6.57 -4.45,14.25 -4.18,21 a7.73,7.73 0 0 0 2.17,5.36 c5.75,-0.7400000000000014 10.64,-4.37 15.25,-7.88 l5.94,-4.53 a14.92,14.92 0 0 0 4.55,-5.49 a45,45 0 0 0 4,-13.85 c0.6500000000000017,-5.16 -0.23,-10.33 0.32000000000000084,-15.44 c0.2900000000000003,-2.63 1.33,-5.1 2.06,-7.6400000000000015 a34.08,34.08 0 0 0 1.17,-13 C129.47893800735474,254.1342095851898 125.46893800735472,256.8042095851898 121.80893800735473,259.85420958518984 z"
            id="svg_18"
          />
          <path
            stroke="black"
            fill="none"
            d="M123.57893800735474,267.23420958518983 a48.67,48.67 0 0 0 -3.45,4.59 c-2.85,4.22 -5.91,8.58 -7.4,13.51 c-1,3.41 -1.9300000000000002,6.86 -2.89,10.29 l-4.14,9 l6.09,-1.3 c0.9,-0.1900000000000004 2.6100000000000003,-0.21000000000000021 3.39,-0.7300000000000006 c1.73,-1.15 2.4,-3.67 3.3,-5.42 c0.5800000000000006,-1.15 2.74,-3.5 2.74,-4.7 c-0.14,-2.23 0.81,-5 1.1600000000000001,-7.2 c1.23,-7.8 3.79,-15.25 6.08,-22.8 c-0.3500000000000003,1.15 -3.42,3.17 -4.37,4.22 z"
            id="svg_19"
          />
          <path
            stroke="black"
            fill="none"
            d="M125.08893800735473,243.68420958518982 a134.62,134.62 0 0 0 -20.18,24 c-5.85,8.55 -8.63,19.39 -10.83,29.41 Q92.81893800735475,302.8742095851898 91.57893800735474,308.6842095851898 c-0.6800000000000019,3.1 -0.15000000000000024,4.79 0.4400000000000007,7.82 c0.7300000000000006,3.7600000000000002 1.6800000000000006,7.19 1.15,11 a46.35,46.35 0 0 0 -0.8800000000000014,6.19 "
            id="svg_20"
          />
          <path
            stroke="black"
            fill="none"
            d="M82.68893800735475,333.6842095851898 c1.1400000000000001,-2.7800000000000002 3.5700000000000003,-6.53 3.87,-9.48 a18.31,18.31 0 0 0 -0.5900000000000006,-3.94 l-1.34,-8.94 c-0.6400000000000017,-4.25 0,-8.66 0,-12.93 c0,-1.6 2.33,-4.55 3,-5.94 c1.46,-2.84 3.37,-5.73 4.49,-8.71 c1.17,-3.14 1.59,-6.87 2.23,-10.16 s0.07,-6.5 0.26,-9.86 c0.16000000000000042,-2.95 2.41,-5.29 3.9,-7.67 C101.91893800735474,250.68420958518982 109.15893800735475,247.33420958518983 114.22893800735474,243.68420958518982 "
            id="svg_21"
          />
          <path
            stroke="black"
            fill="none"
            d="M99.48893800735473,332.90420958518985 c0.4900000000000003,-2.39 0.55,-6.43 2.27,-8 c2.24,-2 6.71,-3.09 9.44,-4.41 c3,-1.4300000000000002 5.94,-2.87 8.870000000000001,-4.4 C126.57893800735474,312.6842095851898 129.57893800735474,306.52420958518985 133.36893800735473,300.4642095851898 c2,-6.41 6.2,-19.84 8.21,-26.32 "
            id="svg_22"
          />
          <path
            stroke="black"
            fill="none"
            d="M110.57893800735474,334.1342095851898 c1.85,-2.87 3.46,-6.62 6.39,-8.48 a35.2,35.2 0 0 1 7.92,-4.15 c2,-0.6300000000000014 5.32,-0.5900000000000006 6.9,-2.17 c1,-1 9.76,-14 9.76,-14 "
            id="svg_23"
          />
          <path
            stroke="black"
            fill="none"
            d="M125.08893800735473,333.6842095851898 c2.8,-3.56 7.17,-5.79 10.27,-9.16 L141.57893800735474,317.78420958518984 "
            id="svg_24"
          />
          <path
            d="M136.20893800735473,333.6842095851898 c0,-4.19 2.3200000000000003,-5.42 5.37,-8 "
            stroke="black"
            fill="none"
            id="svg_25"
          />
          <path
            stroke="black"
            fill="none"
            d="M141.57893800735474,263.48420958518983 c0,-2.84 -0.92,-5.8900000000000015 -1.4100000000000001,-8.67 c-0.39000000000000085,-2.19 -0.4900000000000003,-4.62 -1.59,-6.59 s-3.36,-3.2 -5.21,-4.53 "
            id="svg_26"
          />
          <path
            stroke="black"
            fill="none"
            d="M-48.421061992645264,298.27420958518985 c2.79,-3.6900000000000004 4.92,-6.79 6.36,-8.93 c3.6,-5.39 4.5,-7.37 7.67,-11.44 c2.63,-3.39 4,-5.14 6.42,-7.12 c4,-3.3 5.54,-2.73 9.77,-5.86 A32.73,32.73 0 0 0 -10.421061992645264,256.5542095851898 A32.13,32.13 0 0 0 -5.651061992645253,243.68420958518982 "
            id="svg_27"
          />
          <path
            stroke="black"
            fill="none"
            d="M11.15893800735472,333.6842095851898 a54.56,54.56 0 0 0 9.11,-12.48 c2.85,-5.38 4.24,-10.61 6.8900000000000015,-20.84 c2.47,-9.54 1.9,-9.08 3.72,-15.63 a122.89,122.89 0 0 1 7.8100000000000005,-21.76 A104.05,104.05 0 0 1 50.77893800735474,243.68420958518982 "
            id="svg_28"
          />
        </g>
        <g
          transform="rotate(-90.05069732666016 45.96490478515623,480.40045166015625) "
          id="svg_57"
        >
          <path
            stroke="black"
            fill="none"
            d="M-13.355096645355218,435.1754357814789 L-23.035096645355225,448.0254357814789 a27.07,27.07 0 0 1 -2.52,3 a27.57,27.57 0 0 1 -3.72,2.91 L-40.48509664535521,461.6754357814789 A7.86,7.86 0 0 0 -42.035096645355225,462.9154357814789 a7.59,7.59 0 0 0 -1.2,2.31 L-49.035096645355225,480.4054357814789 "
            id="svg_58"
          />
          <path
            stroke="black"
            fill="none"
            d="M-48.95509664535524,506.1754357814789 A131.35,131.35 0 0 1 -33.66509664535522,484.82543578147886 a29.92,29.92 0 0 1 4.49,-4.37 c4,-3 9.14,-4.06 13.44,-6.62 A72.33,72.33 0 0 1 -21.525096645355234,497.1754357814789 A84.38,84.38 0 0 1 -30.17509664535521,511.9754357814789 c-3.22,4.52 -7.32,8.56 -10.32,13.2 "
            id="svg_59"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.035096645355225,515.7054357814789 L-35.545096645355216,494.63543578147886 a35.16,35.16 0 0 1 2.6900000000000004,-3.8 c2.47,-2.9 5.8,-4.8900000000000015 9.07,-6.84 L-31.845096645355227,500.9254357814789 c-0.7100000000000006,1.48 -1.42,3 -2.27,4.36 C-37.82509664535522,511.3554357814789 -44.035096645355225,516.6954357814789 -49.035096645355225,521.6854357814789 "
            id="svg_60"
          />
          <path
            stroke="black"
            fill="none"
            d="M-31.19509664535522,525.1754357814789 L-16.035096645355225,502.0254357814789 a35,35 0 0 0 2.4,-4.09 a32.88,32.88 0 0 0 1.9300000000000002,-5.93 c1.32,-5.15 2.6100000000000003,-10.32 4.07,-15.43 c4.14,-14.53 9.73,-28.8 18.24,-41.28 "
            id="svg_61"
          />
          <path
            stroke="black"
            fill="none"
            d="M20.544903354644774,435.1754357814789 L7.284903354644769,454.06543578147887 a36.3,36.3 0 0 0 -3,4.77 A35.2,35.2 0 0 0 2.3549033546447617,464.1754357814789 l-6.67,21.7 a44.27,44.27 0 0 0 -1.84,7.49 c-0.7000000000000006,5.49 0.6100000000000017,11.24 -1.12,16.49 C-9.20509664535524,515.6954357814789 -14.545096645355216,519.7454357814788 -17.46509664535523,525.1754357814789 "
            id="svg_62"
          />
          <path
            stroke="black"
            fill="none"
            d="M-12.035096645355225,525.6254357814789 l8.46,-7.14 c2.63,-2.22 5.53,-3.7800000000000002 6.38,-7.37 c1.1,-4.6400000000000015 0.6500000000000019,-9.83 0.9400000000000006,-14.57 c0.3400000000000011,-5.4 -0.45,-12.620000000000001 1.6400000000000001,-17.65 c4.12,-9.88 9.27,-19.43 13.870000000000001,-29.09 L30.354903354644776,435.1754357814789 "
            id="svg_63"
          />
          <path
            stroke="black"
            fill="none"
            d="M-3.9650966453552314,448.37543578147887 c-3.6,3 -6.09,7.25 -9,10.85 a7.74,7.74 0 0 0 -1.3,2 a6.75,6.75 0 0 0 -0.38000000000000084,1.61 l-1,6.13 l6.94,-4.11 a2.84,2.84 0 0 0 1,-0.81 a3.17,3.17 0 0 0 0.3600000000000003,-1 C-6.305096645355233,458.5454357814789 -3.9550966453552405,452.9354357814789 -3.9650966453552314,448.37543578147887 z"
            id="svg_64"
          />
          <path
            stroke="black"
            fill="none"
            d="M-22.39509664535524,435.1754357814789 c-3.45,4.23 -6.85,8.5 -10.28,12.75 A5.6,5.6 0 0 1 -33.85509664535522,449.1754357814789 a5.71,5.71 0 0 1 -1.9100000000000001,0.7100000000000006 l-4.18,1 a3,3 0 0 0 -1.21,0.5 a3,3 0 0 0 -0.6500000000000019,1 c-2.41,5 -4.8,10 -7.23,15.05 "
            id="svg_65"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.035096645355225,456.00543578147887 L-44.37509664535523,445.0554357814789 a3.54,3.54 0 0 1 0.6300000000000017,-1.1 A3.59,3.59 0 0 1 -42.39509664535524,443.1754357814789 l3,-1.1400000000000001 a5.62,5.62 0 0 0 2,-1.05 a5.39,5.39 0 0 0 0.8300000000000006,-1.17 L-33.775096645355234,435.1754357814789 "
            id="svg_66"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.035096645355225,441.1754357814789 L-44.90509664535523,439.7454357814789 a2.63,2.63 0 0 0 0.6600000000000021,-0.3000000000000003 a2.62,2.62 0 0 0 0.6500000000000019,-0.79 c0.7300000000000006,-1.1600000000000001 1.44,-2.33 2.19,-3.47 "
            id="svg_67"
          />
          <path
            stroke="black"
            fill="none"
            d="M2.7849033546447686,525.6254357814789 Q7.854903354644762,518.1754357814789 12.964903354644775,510.82543578147886 a9.81,9.81 0 0 0 0.8300000000000006,-1.3900000000000001 c1.1,-2.4 0.39000000000000096,-6.14 0.39000000000000096,-8.74 V490.2954357814789 c0,-1.54 0,-3.08 0,-4.61 c0.07,-1.94 1.12,-3.2800000000000002 2.22,-4.77 c2.6900000000000004,-3.64 5.51,-7.2 8,-11 c5.24,-7.79 3.4,-9.59 5.18,-18.67 c0.3400000000000011,-1.7000000000000002 6,-10.8 7.26,-11.61 C39.22490335464478,438.1754357814789 40.14490335464478,436.7754357814789 42.434903354644774,435.1754357814789 "
            id="svg_68"
          />
          <path
            stroke="black"
            fill="none"
            d="M58.79490335464476,435.1754357814789 c-3.33,4.74 -6.74,9.41 -10,14.2 c-2.92,4.29 -4.26,9.16 -5.78,14.05 a145.2,145.2 0 0 0 -3.63,14.22 c-0.92,4.6 -0.4900000000000003,9 -0.8400000000000006,13.59 c-0.23,3 -1.5,5.4 -2.6100000000000003,8.19 Q33.20490335464477,506.32543578147886 30.354903354644776,513.1754357814789 L18.714903354644775,525.1754357814789 "
            id="svg_69"
          />
          <path
            stroke="black"
            fill="none"
            d="M31.904903354644773,525.6254357814789 l4,-7.13 c2.71,-4.8 2.12,-11.46 5.7,-15.5 c2.33,-2.6500000000000004 5.12,-5.25 7.09,-8.2 c0.7500000000000017,-1.13 -0.06000000000000015,-4.48 -0.17,-5.79 c-0.31000000000000083,-3.77 -1.4100000000000001,-8.12 -0.93,-11.89 c0.53,-4.22 3.77,-9 5.5,-12.94 c1,-2.21 3,-3.88 4.55,-5.7 c2.51,-3 4.8900000000000015,-6.11 7.34,-9.17 c2.73,-3.4 2.94,-9.89 3.88,-14.12 "
            id="svg_70"
          />
          <path
            stroke="black"
            fill="none"
            d="M75.34490335464477,435.1754357814789 c-3,7.68 -5.5,15.66 -9.5,22.93 c-1.56,2.85 -3.24,5.6400000000000015 -4.93,8.42 c-1.36,2.24 -3.85,5.06 -4.37,7.6400000000000015 c-0.8200000000000006,4.13 -0.4,9 -0.4300000000000003,13.17 c0,3.46 0.79,7 -0.4100000000000003,10.26 c-1.7200000000000002,4.72 -3.93,9.26 -5.55,14 A60,60 0 0 0 47.67490335464477,525.1754357814789 "
            id="svg_71"
          />
          <path
            stroke="black"
            fill="none"
            d="M84.39490335464478,435.1754357814789 c-5.82,12.86 -9,26.58 -11.88,40.34 c-1,4.86 -3.52,9.27 -5.82,13.63 c-1.1400000000000001,2.17 -2.19,3.84 -2.21,6.29 c0,2.8200000000000003 0,5.65 0,8.48 c0,4.41 -2.47,8.85 -4,12.93 L57.24490335464478,525.1754357814789 "
            id="svg_72"
          />
          <path
            stroke="black"
            fill="none"
            d="M66.81490335464477,525.6254357814789 c2.12,-3.21 4.5,-6.46 6,-10 c2.39,-5.92 2.15,-12.69 3.81,-18.87 c1.12,-4.2 1.92,-8.71 3.45,-12.77 a94,94 0 0 1 5,-10.61 c1.08,-2 2.56,-3.83 3.6100000000000003,-5.8900000000000015 c3,-5.8 -3.51,-11.49 -1.1,-17.37 a51.24,51.24 0 0 1 4.12,-7 L100.43490335464477,435.1754357814789 "
            id="svg_73"
          />
          <path
            stroke="black"
            fill="none"
            d="M121.19490335464477,451.3454357814789 a51.23,51.23 0 0 0 -4.06,3.74 a44.46,44.46 0 0 0 -4.36,5.33 A105.27,105.27 0 0 0 105.96490335464478,472.1154357814789 a50.28,50.28 0 0 0 -2.8600000000000003,6.14 a49.41,49.41 0 0 0 -1.6,6 c-1.47,6.57 -4.45,14.25 -4.18,21 a7.73,7.73 0 0 0 2.17,5.36 c5.75,-0.7400000000000017 10.64,-4.37 15.25,-7.88 l5.94,-4.53 a14.92,14.92 0 0 0 4.55,-5.49 a45,45 0 0 0 4,-13.85 c0.6500000000000019,-5.16 -0.23,-10.33 0.32000000000000095,-15.44 c0.2900000000000003,-2.63 1.33,-5.1 2.06,-7.6400000000000015 a34.08,34.08 0 0 0 1.17,-13 C128.86490335464478,445.62543578147887 124.85490335464476,448.2954357814789 121.19490335464477,451.3454357814789 z"
            id="svg_74"
          />
          <path
            stroke="black"
            fill="none"
            d="M122.96490335464478,458.7254357814789 a48.67,48.67 0 0 0 -3.45,4.59 c-2.85,4.22 -5.91,8.58 -7.4,13.51 c-1,3.41 -1.9300000000000002,6.86 -2.89,10.29 l-4.14,9 l6.09,-1.3 c0.9,-0.19000000000000045 2.6100000000000003,-0.21000000000000021 3.39,-0.7300000000000006 c1.73,-1.15 2.4,-3.67 3.3,-5.42 c0.5800000000000006,-1.15 2.74,-3.5 2.74,-4.7 c-0.14,-2.23 0.81,-5 1.1600000000000001,-7.2 c1.23,-7.8 3.79,-15.25 6.08,-22.8 c-0.3500000000000003,1.15 -3.42,3.17 -4.37,4.22 z"
            id="svg_75"
          />
          <path
            stroke="black"
            fill="none"
            d="M124.47490335464477,435.1754357814789 a134.62,134.62 0 0 0 -20.18,24 c-5.85,8.55 -8.63,19.39 -10.83,29.41 Q92.20490335464478,494.3654357814789 90.96490335464478,500.1754357814789 c-0.6800000000000022,3.1 -0.15000000000000024,4.79 0.44000000000000083,7.82 c0.7300000000000006,3.7600000000000002 1.6800000000000006,7.19 1.15,11 a46.35,46.35 0 0 0 -0.8800000000000017,6.19 "
            id="svg_76"
          />
          <path
            stroke="black"
            fill="none"
            d="M82.07490335464479,525.1754357814789 c1.1400000000000001,-2.7800000000000002 3.5700000000000003,-6.53 3.87,-9.48 a18.31,18.31 0 0 0 -0.5900000000000006,-3.94 l-1.34,-8.94 c-0.6400000000000019,-4.25 0,-8.66 0,-12.93 c0,-1.6 2.33,-4.55 3,-5.94 c1.46,-2.84 3.37,-5.73 4.49,-8.71 c1.17,-3.14 1.59,-6.87 2.23,-10.16 s0.07,-6.5 0.26,-9.86 c0.16000000000000048,-2.95 2.41,-5.29 3.9,-7.67 C101.30490335464478,442.1754357814789 108.54490335464479,438.82543578147886 113.61490335464478,435.1754357814789 "
            id="svg_77"
          />
          <path
            stroke="black"
            fill="none"
            d="M98.87490335464477,524.3954357814789 c0.4900000000000003,-2.39 0.55,-6.43 2.27,-8 c2.24,-2 6.71,-3.09 9.44,-4.41 c3,-1.4300000000000002 5.94,-2.87 8.870000000000001,-4.4 C125.96490335464478,504.1754357814789 128.96490335464478,498.0154357814789 132.75490335464477,491.95543578147885 c2,-6.41 6.2,-19.84 8.21,-26.32 "
            id="svg_78"
          />
          <path
            stroke="black"
            fill="none"
            d="M109.96490335464478,525.6254357814789 c1.85,-2.87 3.46,-6.62 6.39,-8.48 a35.2,35.2 0 0 1 7.92,-4.15 c2,-0.6300000000000017 5.32,-0.5900000000000006 6.9,-2.17 c1,-1 9.76,-14 9.76,-14 "
            id="svg_79"
          />
          <path
            stroke="black"
            fill="none"
            d="M124.47490335464477,525.1754357814789 c2.8,-3.56 7.17,-5.79 10.27,-9.16 L140.96490335464478,509.2754357814789 "
            id="svg_80"
          />
          <path
            d="M135.59490335464477,525.1754357814789 c0,-4.19 2.3200000000000003,-5.42 5.37,-8 "
            stroke="black"
            fill="none"
            id="svg_81"
          />
          <path
            stroke="black"
            fill="none"
            d="M140.96490335464478,454.9754357814789 c0,-2.84 -0.92,-5.8900000000000015 -1.4100000000000001,-8.67 c-0.39000000000000096,-2.19 -0.4900000000000003,-4.62 -1.59,-6.59 s-3.36,-3.2 -5.21,-4.53 "
            id="svg_82"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.035096645355225,489.7654357814789 c2.79,-3.6900000000000004 4.92,-6.79 6.36,-8.93 c3.6,-5.39 4.5,-7.37 7.67,-11.44 c2.63,-3.39 4,-5.14 6.42,-7.12 c4,-3.3 5.54,-2.73 9.77,-5.86 A32.73,32.73 0 0 0 -11.035096645355225,448.0454357814789 A32.13,32.13 0 0 0 -6.265096645355214,435.1754357814789 "
            id="svg_83"
          />
          <path
            stroke="black"
            fill="none"
            d="M10.54490335464476,525.1754357814789 a54.56,54.56 0 0 0 9.11,-12.48 c2.85,-5.38 4.24,-10.61 6.8900000000000015,-20.84 c2.47,-9.54 1.9,-9.08 3.72,-15.63 a122.89,122.89 0 0 1 7.8100000000000005,-21.76 A104.05,104.05 0 0 1 50.16490335464478,435.1754357814789 "
            id="svg_84"
          />
        </g>
        <g
          transform="rotate(-90.05069732666016 45.35087203979487,673.2074584960938) "
          id="svg_85"
        >
          <path
            stroke="black"
            fill="none"
            d="M-13.969131536483758,627.9824578762054 L-23.649131536483765,640.8324578762055 a27.07,27.07 0 0 1 -2.52,3 a27.57,27.57 0 0 1 -3.72,2.91 L-41.09913153648375,654.4824578762054 A7.86,7.86 0 0 0 -42.649131536483765,655.7224578762055 a7.59,7.59 0 0 0 -1.2,2.31 L-49.649131536483765,673.2124578762055 "
            id="svg_86"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.56913153648378,698.9824578762054 A131.35,131.35 0 0 1 -34.27913153648376,677.6324578762054 a29.92,29.92 0 0 1 4.49,-4.37 c4,-3 9.14,-4.06 13.44,-6.62 A72.33,72.33 0 0 1 -22.139131536483774,689.9824578762054 A84.38,84.38 0 0 1 -30.78913153648375,704.7824578762054 c-3.22,4.52 -7.32,8.56 -10.32,13.2 "
            id="svg_87"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.649131536483765,708.5124578762054 L-36.159131536483756,687.4424578762055 a35.16,35.16 0 0 1 2.6900000000000004,-3.8 c2.47,-2.9 5.8,-4.8900000000000015 9.07,-6.84 L-32.45913153648377,693.7324578762054 c-0.7100000000000006,1.48 -1.42,3 -2.27,4.36 C-38.43913153648376,704.1624578762055 -44.649131536483765,709.5024578762054 -49.649131536483765,714.4924578762054 "
            id="svg_88"
          />
          <path
            stroke="black"
            fill="none"
            d="M-31.80913153648376,717.9824578762054 L-16.649131536483765,694.8324578762055 a35,35 0 0 0 2.4,-4.09 a32.88,32.88 0 0 0 1.9300000000000002,-5.93 c1.32,-5.15 2.6100000000000003,-10.32 4.07,-15.43 c4.14,-14.53 9.73,-28.8 18.24,-41.28 "
            id="svg_89"
          />
          <path
            stroke="black"
            fill="none"
            d="M19.930868463516234,627.9824578762054 L6.6708684635162285,646.8724578762054 a36.3,36.3 0 0 0 -3,4.77 A35.2,35.2 0 0 0 1.7408684635162217,656.9824578762054 l-6.67,21.7 a44.27,44.27 0 0 0 -1.84,7.49 c-0.7000000000000006,5.49 0.610000000000002,11.24 -1.12,16.49 C-9.81913153648378,708.5024578762054 -15.159131536483756,712.5524578762054 -18.07913153648377,717.9824578762054 "
            id="svg_90"
          />
          <path
            stroke="black"
            fill="none"
            d="M-12.649131536483765,718.4324578762055 l8.46,-7.14 c2.63,-2.22 5.53,-3.7800000000000002 6.38,-7.37 c1.1,-4.6400000000000015 0.6500000000000022,-9.83 0.9400000000000006,-14.57 c0.34000000000000136,-5.4 -0.45,-12.620000000000001 1.6400000000000001,-17.65 c4.12,-9.88 9.27,-19.43 13.870000000000001,-29.09 L29.740868463516236,627.9824578762054 "
            id="svg_91"
          />
          <path
            stroke="black"
            fill="none"
            d="M-4.5791315364837715,641.1824578762055 c-3.6,3 -6.09,7.25 -9,10.85 a7.74,7.74 0 0 0 -1.3,2 a6.75,6.75 0 0 0 -0.380000000000001,1.61 l-1,6.13 l6.94,-4.11 a2.84,2.84 0 0 0 1,-0.81 a3.17,3.17 0 0 0 0.3600000000000003,-1 C-6.919131536483773,651.3524578762054 -4.569131536483781,645.7424578762054 -4.5791315364837715,641.1824578762055 z"
            id="svg_92"
          />
          <path
            stroke="black"
            fill="none"
            d="M-23.009131536483782,627.9824578762054 c-3.45,4.23 -6.85,8.5 -10.28,12.75 A5.6,5.6 0 0 1 -34.46913153648376,641.9824578762054 a5.71,5.71 0 0 1 -1.9100000000000001,0.7100000000000006 l-4.18,1 a3,3 0 0 0 -1.21,0.5 a3,3 0 0 0 -0.6500000000000022,1 c-2.41,5 -4.8,10 -7.23,15.05 "
            id="svg_93"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.649131536483765,648.8124578762054 L-44.98913153648377,637.8624578762054 a3.54,3.54 0 0 1 0.630000000000002,-1.1 A3.59,3.59 0 0 1 -43.00913153648378,635.9824578762054 l3,-1.1400000000000001 a5.62,5.62 0 0 0 2,-1.05 a5.39,5.39 0 0 0 0.8300000000000006,-1.17 L-34.389131536483774,627.9824578762054 "
            id="svg_94"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.649131536483765,633.9824578762054 L-45.51913153648377,632.5524578762054 a2.63,2.63 0 0 0 0.6600000000000027,-0.3000000000000003 a2.62,2.62 0 0 0 0.6500000000000022,-0.79 c0.7300000000000006,-1.1600000000000001 1.44,-2.33 2.19,-3.47 "
            id="svg_95"
          />
          <path
            stroke="black"
            fill="none"
            d="M2.1708684635162285,718.4324578762055 Q7.240868463516222,710.9824578762054 12.350868463516235,703.6324578762054 a9.81,9.81 0 0 0 0.8300000000000006,-1.3900000000000001 c1.1,-2.4 0.3900000000000011,-6.14 0.3900000000000011,-8.74 V683.1024578762054 c0,-1.54 0,-3.08 0,-4.61 c0.07,-1.94 1.12,-3.2800000000000002 2.22,-4.77 c2.6900000000000004,-3.64 5.51,-7.2 8,-11 c5.24,-7.79 3.4,-9.59 5.18,-18.67 c0.34000000000000136,-1.7000000000000002 6,-10.8 7.26,-11.61 C38.61086846351624,630.9824578762054 39.53086846351624,629.5824578762055 41.820868463516234,627.9824578762054 "
            id="svg_96"
          />
          <path
            stroke="black"
            fill="none"
            d="M58.18086846351622,627.9824578762054 c-3.33,4.74 -6.74,9.41 -10,14.2 c-2.92,4.29 -4.26,9.16 -5.78,14.05 a145.2,145.2 0 0 0 -3.63,14.22 c-0.92,4.6 -0.4900000000000003,9 -0.8400000000000006,13.59 c-0.23,3 -1.5,5.4 -2.6100000000000003,8.19 Q32.59086846351623,699.1324578762054 29.740868463516236,705.9824578762054 L18.100868463516235,717.9824578762054 "
            id="svg_97"
          />
          <path
            stroke="black"
            fill="none"
            d="M31.290868463516233,718.4324578762055 l4,-7.13 c2.71,-4.8 2.12,-11.46 5.7,-15.5 c2.33,-2.6500000000000004 5.12,-5.25 7.09,-8.2 c0.750000000000002,-1.13 -0.06000000000000018,-4.48 -0.17,-5.79 c-0.310000000000001,-3.77 -1.4100000000000001,-8.12 -0.93,-11.89 c0.53,-4.22 3.77,-9 5.5,-12.94 c1,-2.21 3,-3.88 4.55,-5.7 c2.51,-3 4.8900000000000015,-6.11 7.34,-9.17 c2.73,-3.4 2.94,-9.89 3.88,-14.12 "
            id="svg_98"
          />
          <path
            stroke="black"
            fill="none"
            d="M74.73086846351623,627.9824578762054 c-3,7.68 -5.5,15.66 -9.5,22.93 c-1.56,2.85 -3.24,5.6400000000000015 -4.93,8.42 c-1.36,2.24 -3.85,5.06 -4.37,7.6400000000000015 c-0.8200000000000006,4.13 -0.4,9 -0.4300000000000003,13.17 c0,3.46 0.79,7 -0.4100000000000003,10.26 c-1.7200000000000002,4.72 -3.93,9.26 -5.55,14 A60,60 0 0 0 47.06086846351623,717.9824578762054 "
            id="svg_99"
          />
          <path
            stroke="black"
            fill="none"
            d="M83.78086846351624,627.9824578762054 c-5.82,12.86 -9,26.58 -11.88,40.34 c-1,4.86 -3.52,9.27 -5.82,13.63 c-1.1400000000000001,2.17 -2.19,3.84 -2.21,6.29 c0,2.8200000000000003 0,5.65 0,8.48 c0,4.41 -2.47,8.85 -4,12.93 L56.63086846351624,717.9824578762054 "
            id="svg_100"
          />
          <path
            stroke="black"
            fill="none"
            d="M66.20086846351623,718.4324578762055 c2.12,-3.21 4.5,-6.46 6,-10 c2.39,-5.92 2.15,-12.69 3.81,-18.87 c1.12,-4.2 1.92,-8.71 3.45,-12.77 a94,94 0 0 1 5,-10.61 c1.08,-2 2.56,-3.83 3.6100000000000003,-5.8900000000000015 c3,-5.8 -3.51,-11.49 -1.1,-17.37 a51.24,51.24 0 0 1 4.12,-7 L99.82086846351623,627.9824578762054 "
            id="svg_101"
          />
          <path
            stroke="black"
            fill="none"
            d="M120.58086846351623,644.1524578762055 a51.23,51.23 0 0 0 -4.06,3.74 a44.46,44.46 0 0 0 -4.36,5.33 A105.27,105.27 0 0 0 105.35086846351624,664.9224578762055 a50.28,50.28 0 0 0 -2.8600000000000003,6.14 a49.41,49.41 0 0 0 -1.6,6 c-1.47,6.57 -4.45,14.25 -4.18,21 a7.73,7.73 0 0 0 2.17,5.36 c5.75,-0.740000000000002 10.64,-4.37 15.25,-7.88 l5.94,-4.53 a14.92,14.92 0 0 0 4.55,-5.49 a45,45 0 0 0 4,-13.85 c0.6500000000000022,-5.16 -0.23,-10.33 0.3200000000000011,-15.44 c0.2900000000000003,-2.63 1.33,-5.1 2.06,-7.6400000000000015 a34.08,34.08 0 0 0 1.17,-13 C128.25086846351624,638.4324578762055 124.24086846351622,641.1024578762054 120.58086846351623,644.1524578762055 z"
            id="svg_102"
          />
          <path
            stroke="black"
            fill="none"
            d="M122.35086846351624,651.5324578762054 a48.67,48.67 0 0 0 -3.45,4.59 c-2.85,4.22 -5.91,8.58 -7.4,13.51 c-1,3.41 -1.9300000000000002,6.86 -2.89,10.29 l-4.14,9 l6.09,-1.3 c0.9,-0.19000000000000053 2.6100000000000003,-0.21000000000000021 3.39,-0.7300000000000006 c1.73,-1.15 2.4,-3.67 3.3,-5.42 c0.5800000000000006,-1.15 2.74,-3.5 2.74,-4.7 c-0.14,-2.23 0.81,-5 1.1600000000000001,-7.2 c1.23,-7.8 3.79,-15.25 6.08,-22.8 c-0.3500000000000003,1.15 -3.42,3.17 -4.37,4.22 z"
            id="svg_103"
          />
          <path
            stroke="black"
            fill="none"
            d="M123.86086846351623,627.9824578762054 a134.62,134.62 0 0 0 -20.18,24 c-5.85,8.55 -8.63,19.39 -10.83,29.41 Q91.59086846351624,687.1724578762055 90.35086846351624,692.9824578762054 c-0.6800000000000027,3.1 -0.15000000000000024,4.79 0.440000000000001,7.82 c0.7300000000000006,3.7600000000000002 1.6800000000000006,7.19 1.15,11 a46.35,46.35 0 0 0 -0.880000000000002,6.19 "
            id="svg_104"
          />
          <path
            stroke="black"
            fill="none"
            d="M81.46086846351625,717.9824578762054 c1.1400000000000001,-2.7800000000000002 3.5700000000000003,-6.53 3.87,-9.48 a18.31,18.31 0 0 0 -0.5900000000000006,-3.94 l-1.34,-8.94 c-0.6400000000000022,-4.25 0,-8.66 0,-12.93 c0,-1.6 2.33,-4.55 3,-5.94 c1.46,-2.84 3.37,-5.73 4.49,-8.71 c1.17,-3.14 1.59,-6.87 2.23,-10.16 s0.07,-6.5 0.26,-9.86 c0.16000000000000056,-2.95 2.41,-5.29 3.9,-7.67 C100.69086846351624,634.9824578762054 107.93086846351625,631.6324578762054 113.00086846351624,627.9824578762054 "
            id="svg_105"
          />
          <path
            stroke="black"
            fill="none"
            d="M98.26086846351623,717.2024578762055 c0.4900000000000003,-2.39 0.55,-6.43 2.27,-8 c2.24,-2 6.71,-3.09 9.44,-4.41 c3,-1.4300000000000002 5.94,-2.87 8.870000000000001,-4.4 C125.35086846351624,696.9824578762054 128.35086846351624,690.8224578762055 132.14086846351623,684.7624578762054 c2,-6.41 6.2,-19.84 8.21,-26.32 "
            id="svg_106"
          />
          <path
            stroke="black"
            fill="none"
            d="M109.35086846351624,718.4324578762055 c1.85,-2.87 3.46,-6.62 6.39,-8.48 a35.2,35.2 0 0 1 7.92,-4.15 c2,-0.630000000000002 5.32,-0.5900000000000006 6.9,-2.17 c1,-1 9.76,-14 9.76,-14 "
            id="svg_107"
          />
          <path
            stroke="black"
            fill="none"
            d="M123.86086846351623,717.9824578762054 c2.8,-3.56 7.17,-5.79 10.27,-9.16 L140.35086846351624,702.0824578762055 "
            id="svg_108"
          />
          <path
            d="M134.98086846351623,717.9824578762054 c0,-4.19 2.3200000000000003,-5.42 5.37,-8 "
            stroke="black"
            fill="none"
            id="svg_109"
          />
          <path
            stroke="black"
            fill="none"
            d="M140.35086846351624,647.7824578762054 c0,-2.84 -0.92,-5.8900000000000015 -1.4100000000000001,-8.67 c-0.3900000000000011,-2.19 -0.4900000000000003,-4.62 -1.59,-6.59 s-3.36,-3.2 -5.21,-4.53 "
            id="svg_110"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.649131536483765,682.5724578762055 c2.79,-3.6900000000000004 4.92,-6.79 6.36,-8.93 c3.6,-5.39 4.5,-7.37 7.67,-11.44 c2.63,-3.39 4,-5.14 6.42,-7.12 c4,-3.3 5.54,-2.73 9.77,-5.86 A32.73,32.73 0 0 0 -11.649131536483765,640.8524578762054 A32.13,32.13 0 0 0 -6.879131536483754,627.9824578762054 "
            id="svg_111"
          />
          <path
            stroke="black"
            fill="none"
            d="M9.93086846351622,717.9824578762054 a54.56,54.56 0 0 0 9.11,-12.48 c2.85,-5.38 4.24,-10.61 6.8900000000000015,-20.84 c2.47,-9.54 1.9,-9.08 3.72,-15.63 a122.89,122.89 0 0 1 7.8100000000000005,-21.76 A104.05,104.05 0 0 1 49.55086846351624,627.9824578762054 "
            id="svg_112"
          />
        </g>
        <g
          transform="rotate(-90.05069732666016 45.614025115966804,866.4530639648438) "
          id="svg_113"
        >
          <path
            stroke="black"
            fill="none"
            d="M-13.705974645614617,821.2280786037445 L-23.385974645614624,834.0780786037445 a27.07,27.07 0 0 1 -2.52,3 a27.57,27.57 0 0 1 -3.72,2.91 L-40.83597464561461,847.7280786037445 A7.86,7.86 0 0 0 -42.385974645614624,848.9680786037445 a7.59,7.59 0 0 0 -1.2,2.31 L-49.385974645614624,866.4580786037445 "
            id="svg_114"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.30597464561464,892.2280786037445 A131.35,131.35 0 0 1 -34.01597464561462,870.8780786037445 a29.92,29.92 0 0 1 4.49,-4.37 c4,-3 9.14,-4.06 13.44,-6.62 A72.33,72.33 0 0 1 -21.875974645614633,883.2280786037445 A84.38,84.38 0 0 1 -30.52597464561461,898.0280786037445 c-3.22,4.52 -7.32,8.56 -10.32,13.2 "
            id="svg_115"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.385974645614624,901.7580786037445 L-35.895974645614615,880.6880786037445 a35.16,35.16 0 0 1 2.6900000000000004,-3.8 c2.47,-2.9 5.8,-4.8900000000000015 9.07,-6.84 L-32.195974645614626,886.9780786037445 c-0.7100000000000006,1.48 -1.42,3 -2.27,4.36 C-38.175974645614616,897.4080786037446 -44.385974645614624,902.7480786037445 -49.385974645614624,907.7380786037445 "
            id="svg_116"
          />
          <path
            stroke="black"
            fill="none"
            d="M-31.54597464561462,911.2280786037445 L-16.385974645614624,888.0780786037445 a35,35 0 0 0 2.4,-4.09 a32.88,32.88 0 0 0 1.9300000000000002,-5.93 c1.32,-5.15 2.6100000000000003,-10.32 4.07,-15.43 c4.14,-14.53 9.73,-28.8 18.24,-41.28 "
            id="svg_117"
          />
          <path
            stroke="black"
            fill="none"
            d="M20.194025354385374,821.2280786037445 L6.934025354385369,840.1180786037445 a36.3,36.3 0 0 0 -3,4.77 A35.2,35.2 0 0 0 2.0040253543853623,850.2280786037445 l-6.67,21.7 a44.27,44.27 0 0 0 -1.84,7.49 c-0.7000000000000006,5.49 0.6100000000000022,11.24 -1.12,16.49 C-9.55597464561464,901.7480786037445 -14.895974645614615,905.7980786037444 -17.81597464561463,911.2280786037445 "
            id="svg_118"
          />
          <path
            stroke="black"
            fill="none"
            d="M-12.385974645614624,911.6780786037446 l8.46,-7.14 c2.63,-2.22 5.53,-3.7800000000000002 6.38,-7.37 c1.1,-4.6400000000000015 0.6500000000000026,-9.83 0.9400000000000006,-14.57 c0.34000000000000147,-5.4 -0.45,-12.620000000000001 1.6400000000000001,-17.65 c4.12,-9.88 9.27,-19.43 13.870000000000001,-29.09 L30.004025354385377,821.2280786037445 "
            id="svg_119"
          />
          <path
            stroke="black"
            fill="none"
            d="M-4.315974645614631,834.4280786037446 c-3.6,3 -6.09,7.25 -9,10.85 a7.74,7.74 0 0 0 -1.3,2 a6.75,6.75 0 0 0 -0.3800000000000011,1.61 l-1,6.13 l6.94,-4.11 a2.84,2.84 0 0 0 1,-0.81 a3.17,3.17 0 0 0 0.3600000000000003,-1 C-6.6559746456146325,844.5980786037445 -4.30597464561464,838.9880786037445 -4.315974645614631,834.4280786037446 z"
            id="svg_120"
          />
          <path
            stroke="black"
            fill="none"
            d="M-22.74597464561464,821.2280786037445 c-3.45,4.23 -6.85,8.5 -10.28,12.75 A5.6,5.6 0 0 1 -34.20597464561462,835.2280786037445 a5.71,5.71 0 0 1 -1.9100000000000001,0.7100000000000006 l-4.18,1 a3,3 0 0 0 -1.21,0.5 a3,3 0 0 0 -0.6500000000000026,1 c-2.41,5 -4.8,10 -7.23,15.05 "
            id="svg_121"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.385974645614624,842.0580786037444 L-44.72597464561463,831.1080786037445 a3.54,3.54 0 0 1 0.6300000000000022,-1.1 A3.59,3.59 0 0 1 -42.74597464561464,829.2280786037445 l3,-1.1400000000000001 a5.62,5.62 0 0 0 2,-1.05 a5.39,5.39 0 0 0 0.8300000000000006,-1.17 L-34.12597464561463,821.2280786037445 "
            id="svg_122"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.385974645614624,827.2280786037445 L-45.25597464561463,825.7980786037444 a2.63,2.63 0 0 0 0.6600000000000029,-0.3000000000000003 a2.62,2.62 0 0 0 0.6500000000000026,-0.79 c0.7300000000000006,-1.1600000000000001 1.44,-2.33 2.19,-3.47 "
            id="svg_123"
          />
          <path
            stroke="black"
            fill="none"
            d="M2.434025354385369,911.6780786037446 Q7.504025354385362,904.2280786037445 12.614025354385376,896.8780786037445 a9.81,9.81 0 0 0 0.8300000000000006,-1.3900000000000001 c1.1,-2.4 0.3900000000000013,-6.14 0.3900000000000013,-8.74 V876.3480786037445 c0,-1.54 0,-3.08 0,-4.61 c0.07,-1.94 1.12,-3.2800000000000002 2.22,-4.77 c2.6900000000000004,-3.64 5.51,-7.2 8,-11 c5.24,-7.79 3.4,-9.59 5.18,-18.67 c0.34000000000000147,-1.7000000000000002 6,-10.8 7.26,-11.61 C38.87402535438538,824.2280786037445 39.79402535438538,822.8280786037445 42.084025354385375,821.2280786037445 "
            id="svg_124"
          />
          <path
            stroke="black"
            fill="none"
            d="M58.44402535438536,821.2280786037445 c-3.33,4.74 -6.74,9.41 -10,14.2 c-2.92,4.29 -4.26,9.16 -5.78,14.05 a145.2,145.2 0 0 0 -3.63,14.22 c-0.92,4.6 -0.4900000000000003,9 -0.8400000000000006,13.59 c-0.23,3 -1.5,5.4 -2.6100000000000003,8.19 Q32.85402535438537,892.3780786037445 30.004025354385377,899.2280786037445 L18.364025354385376,911.2280786037445 "
            id="svg_125"
          />
          <path
            stroke="black"
            fill="none"
            d="M31.554025354385374,911.6780786037446 l4,-7.13 c2.71,-4.8 2.12,-11.46 5.7,-15.5 c2.33,-2.6500000000000004 5.12,-5.25 7.09,-8.2 c0.7500000000000022,-1.13 -0.0600000000000002,-4.48 -0.17,-5.79 c-0.3100000000000011,-3.77 -1.4100000000000001,-8.12 -0.93,-11.89 c0.53,-4.22 3.77,-9 5.5,-12.94 c1,-2.21 3,-3.88 4.55,-5.7 c2.51,-3 4.8900000000000015,-6.11 7.34,-9.17 c2.73,-3.4 2.94,-9.89 3.88,-14.12 "
            id="svg_126"
          />
          <path
            stroke="black"
            fill="none"
            d="M74.99402535438537,821.2280786037445 c-3,7.68 -5.5,15.66 -9.5,22.93 c-1.56,2.85 -3.24,5.6400000000000015 -4.93,8.42 c-1.36,2.24 -3.85,5.06 -4.37,7.6400000000000015 c-0.8200000000000006,4.13 -0.4,9 -0.4300000000000003,13.17 c0,3.46 0.79,7 -0.4100000000000003,10.26 c-1.7200000000000002,4.72 -3.93,9.26 -5.55,14 A60,60 0 0 0 47.32402535438537,911.2280786037445 "
            id="svg_127"
          />
          <path
            stroke="black"
            fill="none"
            d="M84.04402535438538,821.2280786037445 c-5.82,12.86 -9,26.58 -11.88,40.34 c-1,4.86 -3.52,9.27 -5.82,13.63 c-1.1400000000000001,2.17 -2.19,3.84 -2.21,6.29 c0,2.8200000000000003 0,5.65 0,8.48 c0,4.41 -2.47,8.85 -4,12.93 L56.89402535438538,911.2280786037445 "
            id="svg_128"
          />
          <path
            stroke="black"
            fill="none"
            d="M66.46402535438537,911.6780786037446 c2.12,-3.21 4.5,-6.46 6,-10 c2.39,-5.92 2.15,-12.69 3.81,-18.87 c1.12,-4.2 1.92,-8.71 3.45,-12.77 a94,94 0 0 1 5,-10.61 c1.08,-2 2.56,-3.83 3.6100000000000003,-5.8900000000000015 c3,-5.8 -3.51,-11.49 -1.1,-17.37 a51.24,51.24 0 0 1 4.12,-7 L100.08402535438537,821.2280786037445 "
            id="svg_129"
          />
          <path
            stroke="black"
            fill="none"
            d="M120.84402535438537,837.3980786037446 a51.23,51.23 0 0 0 -4.06,3.74 a44.46,44.46 0 0 0 -4.36,5.33 A105.27,105.27 0 0 0 105.61402535438538,858.1680786037446 a50.28,50.28 0 0 0 -2.8600000000000003,6.14 a49.41,49.41 0 0 0 -1.6,6 c-1.47,6.57 -4.45,14.25 -4.18,21 a7.73,7.73 0 0 0 2.17,5.36 c5.75,-0.7400000000000022 10.64,-4.37 15.25,-7.88 l5.94,-4.53 a14.92,14.92 0 0 0 4.55,-5.49 a45,45 0 0 0 4,-13.85 c0.6500000000000026,-5.16 -0.23,-10.33 0.3200000000000013,-15.44 c0.2900000000000003,-2.63 1.33,-5.1 2.06,-7.6400000000000015 a34.08,34.08 0 0 0 1.17,-13 C128.51402535438538,831.6780786037446 124.50402535438536,834.3480786037445 120.84402535438537,837.3980786037446 z"
            id="svg_130"
          />
          <path
            stroke="black"
            fill="none"
            d="M122.61402535438538,844.7780786037445 a48.67,48.67 0 0 0 -3.45,4.59 c-2.85,4.22 -5.91,8.58 -7.4,13.51 c-1,3.41 -1.9300000000000002,6.86 -2.89,10.29 l-4.14,9 l6.09,-1.3 c0.9,-0.19000000000000059 2.6100000000000003,-0.21000000000000021 3.39,-0.7300000000000006 c1.73,-1.15 2.4,-3.67 3.3,-5.42 c0.5800000000000006,-1.15 2.74,-3.5 2.74,-4.7 c-0.14,-2.23 0.81,-5 1.1600000000000001,-7.2 c1.23,-7.8 3.79,-15.25 6.08,-22.8 c-0.3500000000000003,1.15 -3.42,3.17 -4.37,4.22 z"
            id="svg_131"
          />
          <path
            stroke="black"
            fill="none"
            d="M124.12402535438537,821.2280786037445 a134.62,134.62 0 0 0 -20.18,24 c-5.85,8.55 -8.63,19.39 -10.83,29.41 Q91.85402535438539,880.4180786037446 90.61402535438538,886.2280786037445 c-0.6800000000000029,3.1 -0.15000000000000024,4.79 0.4400000000000011,7.82 c0.7300000000000006,3.7600000000000002 1.6800000000000006,7.19 1.15,11 a46.35,46.35 0 0 0 -0.8800000000000022,6.19 "
            id="svg_132"
          />
          <path
            stroke="black"
            fill="none"
            d="M81.72402535438539,911.2280786037445 c1.1400000000000001,-2.7800000000000002 3.5700000000000003,-6.53 3.87,-9.48 a18.31,18.31 0 0 0 -0.5900000000000006,-3.94 l-1.34,-8.94 c-0.6400000000000025,-4.25 0,-8.66 0,-12.93 c0,-1.6 2.33,-4.55 3,-5.94 c1.46,-2.84 3.37,-5.73 4.49,-8.71 c1.17,-3.14 1.59,-6.87 2.23,-10.16 s0.07,-6.5 0.26,-9.86 c0.16000000000000064,-2.95 2.41,-5.29 3.9,-7.67 C100.95402535438538,828.2280786037445 108.19402535438539,824.8780786037445 113.26402535438538,821.2280786037445 "
            id="svg_133"
          />
          <path
            stroke="black"
            fill="none"
            d="M98.52402535438537,910.4480786037445 c0.4900000000000003,-2.39 0.55,-6.43 2.27,-8 c2.24,-2 6.71,-3.09 9.44,-4.41 c3,-1.4300000000000002 5.94,-2.87 8.870000000000001,-4.4 C125.61402535438538,890.2280786037445 128.61402535438538,884.0680786037445 132.40402535438537,878.0080786037445 c2,-6.41 6.2,-19.84 8.21,-26.32 "
            id="svg_134"
          />
          <path
            stroke="black"
            fill="none"
            d="M109.61402535438538,911.6780786037446 c1.85,-2.87 3.46,-6.62 6.39,-8.48 a35.2,35.2 0 0 1 7.92,-4.15 c2,-0.6300000000000022 5.32,-0.5900000000000006 6.9,-2.17 c1,-1 9.76,-14 9.76,-14 "
            id="svg_135"
          />
          <path
            stroke="black"
            fill="none"
            d="M124.12402535438537,911.2280786037445 c2.8,-3.56 7.17,-5.79 10.27,-9.16 L140.61402535438538,895.3280786037445 "
            id="svg_136"
          />
          <path
            d="M135.24402535438537,911.2280786037445 c0,-4.19 2.3200000000000003,-5.42 5.37,-8 "
            stroke="black"
            fill="none"
            id="svg_137"
          />
          <path
            stroke="black"
            fill="none"
            d="M140.61402535438538,841.0280786037445 c0,-2.84 -0.92,-5.8900000000000015 -1.4100000000000001,-8.67 c-0.3900000000000013,-2.19 -0.4900000000000003,-4.62 -1.59,-6.59 s-3.36,-3.2 -5.21,-4.53 "
            id="svg_138"
          />
          <path
            stroke="black"
            fill="none"
            d="M-49.385974645614624,875.8180786037445 c2.79,-3.6900000000000004 4.92,-6.79 6.36,-8.93 c3.6,-5.39 4.5,-7.37 7.67,-11.44 c2.63,-3.39 4,-5.14 6.42,-7.12 c4,-3.3 5.54,-2.73 9.77,-5.86 A32.73,32.73 0 0 0 -11.385974645614624,834.0980786037445 A32.13,32.13 0 0 0 -6.615974645614614,821.2280786037445 "
            id="svg_139"
          />
          <path
            stroke="black"
            fill="none"
            d="M10.19402535438536,911.2280786037445 a54.56,54.56 0 0 0 9.11,-12.48 c2.85,-5.38 4.24,-10.61 6.8900000000000015,-20.84 c2.47,-9.54 1.9,-9.08 3.72,-15.63 a122.89,122.89 0 0 1 7.8100000000000005,-21.76 A104.05,104.05 0 0 1 49.81402535438538,821.2280786037445 "
            id="svg_140"
          />
        </g>
      </g>
    </svg>
  );
}
