import { State, Action, ActionType, AuthState } from "./types";

export const initialState: State = {
  user: {
    id: "58f82d34-080b-4f1f-8a95-9f79f7d8ccdc",
    name: "No one",
  },
  graphQlErrors: [],
  authState: AuthState.LOADING,
  emailVerificationRequired: false,
  showDownloadModal: false,
  scenarioId: null,
  pipelineId: null,
  benchmarkPipelineIds: [],
  isPreliminaryReport: false,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.RESET_GLOBAL_STATE: {
      return {
        ...initialState,
        authState: state.authState,
      };
    }
    case ActionType.SET_USER: {
      return {
        ...state,
        user: action.payload.user,
      };
    }

    case ActionType.SET_GRAPH_QL_ERRORS: {
      return {
        ...state,
        graphQlErrors: action.payload,
      };
    }

    case ActionType.CLEAR_GRAPH_QL_ERROR: {
      return {
        ...state,
        graphQlErrors:
          state.graphQlErrors?.filter(
            (_, index) => index !== action.payload.index
          ) || [],
      };
    }

    case ActionType.SET_AUTH_STATE: {
      return {
        ...state,
        authState: action.payload,
      };
    }

    case ActionType.SET_EMAIL_VERIFICATION_REQUIRED: {
      return {
        ...state,
        emailVerificationRequired: action.payload,
      };
    }

    case ActionType.SET_SHOW_DOWNLOAD_MODAL: {
      return {
        ...state,
        showDownloadModal: action.payload,
      };
    }

    case ActionType.SET_SCENARIO_ID: {
      return {
        ...state,
        scenarioId: action.payload,
        pipelineId: null,
      };
    }

    case ActionType.SET_PIPELINE_ID: {
      return {
        ...state,
        pipelineId: action.payload,
        scenarioId: null,
        benchmarkPipelineIds: state.benchmarkPipelineIds.filter(
          (id) => action.payload !== id
        ),
      };
    }

    case ActionType.SET_BENCHMAKR_PIPELINE_IDS: {
      return {
        ...state,
        benchmarkPipelineIds: action.payload,
      };
    }

    case ActionType.SET_PRELIMINARY_REPORT: {
      return {
        ...state,
        isPreliminaryReport: action.payload,
      };
    }

    default:
      return state;
  }
};
