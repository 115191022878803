import CssBaseline from "@material-ui/core/CssBaseline";
import { WithProviders } from "./providers/withProviders";
import Routes from "./apps/routes";

const App = () => {
  return (
    <WithProviders>
      <div style={{ height: "100%" }} className="App">
        <CssBaseline>
          <Routes />
        </CssBaseline>
      </div>
    </WithProviders>
  );
};

export default App;
