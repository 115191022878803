import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  useTheme,
  ButtonGroup,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PlantLogo from "../../assets/PlantLogo";
import { grey } from "@material-ui/core/colors";
import { useParams } from "react-router-dom";
import useSignOut from "../../lib/components/authed/use-sign-out";
import { useQuery } from "../report/pages/report-scenarios-introduction-page";
import { Button as ChakraButton } from "@chakra-ui/react";
import ProjectComponent from "./projectEditComponent";
import EmbeddedReportComponent from "./report/embeddedReportComponent";
import PrintableReportComponent from "./report/printableReportComponent";
import UserReportComponent from "./report/userReportComponent";
import {
  useSimplifiedTenantAdaptionGeneratePdfReportMutation,
  useSimplifiedTenantAdaptionProjectQuery,
  useReportTemplatesSubscription,
  namedOperations,
} from "../../lib/generated/graphql";
import { DefaultTheme } from "../../providers/styling";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      background: grey[100],
      height: "100%",
      boxSizing: "border-box",
    },
    formControl: {
      marginRight: theme.spacing(1),
      width: `calc(70% - ${theme.spacing(2)}px)`,
      maxWidth: 400,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        maxWidth: "none",
      },
    },
    input: {
      fontWeight: 600,
    },
  })
);

const SimplifiedTenantAdaptionPageInner: React.FC<{
  isPrint: boolean;
}> = ({ isPrint }) => {
  const classes = useStyles();
  const signOut = useSignOut();
  const { projectId } = useParams();
  if (projectId == null) {
    return <CircularProgress />;
  }

  const theme = useTheme<DefaultTheme>();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const [generatePdf, { loading: loadingCreateReport }] =
    useSimplifiedTenantAdaptionGeneratePdfReportMutation({
      awaitRefetchQueries: true,
      refetchQueries: [namedOperations.Query.SimplifiedTenantAdaptionProject],
    });

  const { data, loading } = useSimplifiedTenantAdaptionProjectQuery({
    variables: { id: projectId },
  });
  const reportTemplateId =
    data?.simplifiedTenantAdaption?.project?.reportTemplate?.id;

  useReportTemplatesSubscription({
    skip: loading,
    variables: {
      ids: reportTemplateId != null ? [reportTemplateId] : [],
    },
  });

  const [activeView, setActiveView] = useState("form");

  useEffect(() => {
    setActiveView("form");
  }, [isLargeScreen]);

  if (isPrint) {
    return <PrintableReportComponent projectId={projectId} />;
  }
  if (loading) {
    return <CircularProgress />;
  }
  const project = data?.simplifiedTenantAdaption?.project!;

  return (
    <Box>
      <Box
        className={classes.background}
        height="100%"
        pt={2}
        pb={6}
        px={12}
        display="flex"
        justifyContent="center"
      >
        <Box height="100%">
          <Box display="flex" mb={2} justifyContent="flex-end">
            <Button
              id="logout-btn"
              variant="contained"
              style={{
                color: "red",
                borderColor: "rgba(255,255,255,0.5",
                marginRight: 20,
              }}
              onClick={() =>
                signOut(() =>
                  window.location.assign(`/projects/${projectId}/formulär`)
                )
              }
            >
              Logga ut
            </Button>
            <ChakraButton
              variant="outline"
              colorScheme="green"
              isLoading={
                loadingCreateReport ||
                ["SCHEDULED", "PROCESSING"].includes(
                  project.reportTemplate?.status ?? ""
                )
              }
              onClick={async () =>
                generatePdf({ variables: { id: projectId } })
              }
            >
              {"Skapa rapport"}
            </ChakraButton>
            {!isLargeScreen && (
              <Box ml={2}>
                <ButtonGroup aria-label="outlined primary button group">
                  <Button
                    variant={activeView === "form" ? "contained" : "outlined"}
                    onClick={() => {
                      setActiveView("form");
                    }}
                  >
                    Formulär
                  </Button>
                  <Button
                    variant={activeView === "report" ? "contained" : "outlined"}
                    onClick={() => setActiveView("report")}
                  >
                    Rapport
                  </Button>
                </ButtonGroup>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="center">
            <Box>
              {activeView === "report" ? (
                <UserReportComponent projectId={projectId} />
              ) : (
                <ProjectComponent projectId={projectId} />
              )}
            </Box>
            {isLargeScreen && <EmbeddedReportComponent projectId={projectId} />}
          </Box>

          <Box
            mt={5}
            display="flex"
            justifyContent="center"
            color="text.disabled"
          >
            <PlantLogo fillColor={grey[700]} width={200} height={38} />
          </Box>
          <Box
            mt={1}
            pb={10}
            display="flex"
            justifyContent="center"
            color="text.disabled"
          >
            <Typography variant="subtitle1">{"Powered by Plant"}</Typography>
          </Box>
        </Box>
      </Box>
      <Box position="fixed" bottom={10} right={10}>
        <Paper>
          <Box
            display="flex"
            width={200}
            height={80}
            justifyContent="center"
            alignItems="center"
          >
            <Box textAlign="center">
              {"Kört fast eller har feedback?"}
              <Box>
                <a href="mailto:info@plant.se">{"info@plant.se"}</a>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default function SimplifiedTenantAdaptionPage() {
  const query = useQuery();

  return (
    <SimplifiedTenantAdaptionPageInner
      isPrint={query.get("isPrint") === "true"}
    />
  );
}
