import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { IReportColumns, ReportTable } from "../components/table";
import { ReportStatisticsBox } from "../components/statistics-box";
import { ReportPieChart } from "../components/pie-chart";
import { ReportTypography } from "../components/report-typography";
import { omitRedundantFields } from "./report-gwp-summary-page";
import { bsabCodes } from "../bsab-codes";
import { bsabSumWithRowLimit } from "./bsab-max-row.util";
import useDsrQueryHook from "../hooks/useDsrQueryHook";

import { ReportPageProps } from "../../report-page";
import { getRowsGroupedBy } from "./report-bsab-summary-page";
import { rounderInt, rounderTwo } from "../../../lib/rounder";

export default function ReportBsabSingleDigitSum({
  pageNumber,
}: ReportPageProps) {
  const chapter = "Resultat";
  const pageTitle = "Gruppering på BSAB 96-kod";

  const { data, sum: sumRow } = useDsrQueryHook({
    filter: [],
    group: [{ col: "ProductBSAB" }],
    aggregations: [
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "weightPerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "weight", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 3000,
  });

  const rows = omitRedundantFields(data).map(
    ({ weight, ProductBSAB = "-", ...row }: any) => ({
      ...row,
      weight: (weight ?? 0) / 1000,
      ProductBSAB: (ProductBSAB && bsabCodes[ProductBSAB]) || "Saknar BSAB-kod",
      ProductBSABCode: ProductBSAB,
      weightPerBTA: rounderInt(row.weightPerBTA || 0),
      A1_A5PerBTA: rounderTwo(row.A1_A5PerBTA || 0),
    })
  );

  const columns: IReportColumns[] = [
    {
      label: "BSAB-kod",
      value: "ProductBSABCode",
      width: "1%",
      align: "left",
    },
    {
      label: "Beskrivning",
      value: "ProductBSAB",
      width: "60%",
      align: "left",
    },
    {
      label: "Vikt, kg/BTA",
      value: "weightPerBTA",
      align: "right",
    },

    {
      label: "kg CO₂e/BTA",
      value: "A1_A5PerBTA",
      align: "right",
    },
  ];

  const isBSABProductionResults = /^[A-Z]+/.test(
    rows?.[0]?.ProductBSABCode ?? ""
  ); // ESE - BETONGGJUTNINGAR I HUS
  const rowsGroupedOnFirstLetter = getRowsGroupedBy(rows, 1);

  return (
    <ReportContainer>
      <ReportHeader chapter={chapter} />
      <ReportBodyAlignSpaceBetween>
        <Box mt={5} mb={1} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>

        <Box flexGrow={1}>
          {!isBSABProductionResults && (
            <ReportTable
              columns={columns}
              hasRawMaterials={true}
              tableCaption="Klimatpåverkan från projektet, kg CO₂e/BTA"
              rows={rowsGroupedOnFirstLetter}
              sumRow={{
                ...sumRow,
                weightPerBTA: rounderInt(sumRow?.weightPerBTA || 0),
                A1_A5PerBTA: rounderTwo(sumRow?.A1_A5PerBTA || 0),
              }}
            />
          )}
        </Box>

        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <ReportStatisticsBox
                chart={() => (
                  <ReportPieChart
                    data={bsabSumWithRowLimit(
                      rowsGroupedOnFirstLetter,
                      8,
                      "weightPerBTA"
                    )}
                  />
                )}
                title="Vikt per BSAB-kod"
                caption="Fördelning på BSAB-kod"
              />
            </Box>
            <Box>
              <ReportStatisticsBox
                chart={() => (
                  <ReportPieChart
                    data={bsabSumWithRowLimit(rowsGroupedOnFirstLetter, 8)}
                  />
                )}
                title="Klimatpåverkan per BSAB-kod"
                caption="Fördelning på BSAB-kod, andel av klimatpåverkan"
              />
            </Box>
          </Box>
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} />
    </ReportContainer>
  );
}
