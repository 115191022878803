import { useSimplifiedTenantAdaptionProjectQuery } from "../../lib/generated/graphql";
import { FileTableWithSubscription } from "../file-view";

import { Box } from "@mui/material";

const extraRowStatus = (status?: string) => {
  switch (status) {
    case "FAILED":
      return "FAILED";
    case "PROCESSING":
    case "SCHEDULED":
      return "GENERATING";
    default:
      return undefined;
  }
};

const FilesTable: React.FC<{ projectId: string }> = ({ projectId }) => {
  const { data, loading } = useSimplifiedTenantAdaptionProjectQuery({
    variables: { id: projectId },
  });
  const reportTemplate =
    data?.simplifiedTenantAdaption?.project?.reportTemplate;

  return (
    <Box mb={2}>
      <FileTableWithSubscription
        hideExport
        loading={loading}
        files={reportTemplate?.generatedPdfs ?? []}
        extraRow={extraRowStatus(reportTemplate?.status ?? undefined)}
      />
    </Box>
  );
};

export default FilesTable;
