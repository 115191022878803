const RAW_MATERIAL_ID_SAKNAR_TOLKNING = "55f74cd3-7573-4049-927d-5cd8d8f89e52";

export const newMaterialFilter = [
  {
    NOT: {
      ProductRawMaterialId: "is_null",
    },
  },
  {
    NOT: {
      ProductRawMaterialId: {
        eq: RAW_MATERIAL_ID_SAKNAR_TOLKNING,
      },
    },
  },
  {
    NOT: {
      BuildingElementComponentSubGroup: {
        includes: "Exkl",
      },
    },
  },
  {
    NOT: {
      BuildingElementComponentSubGroup: "is_null",
    },
  },
  {
    NOT: {
      BuildingElementComponentSubGroup: {
        includes: "Saknar tolkning",
      },
    },
  },
];
