import qs from "query-string";
import { useLocation, useParams } from "react-router-dom";
import * as templating from "./report/templating";
import { useProjectQuery } from "../lib/generated/graphql";
import { useGlobalState } from "../providers/GlobalStateProvider";
import { CircularProgress } from "@mui/material";

interface IReportPageProps {
  pages: React.ComponentType<ReportPageProps>[];
  isPrint?: boolean;
  reportType?: string;
  isSimplifiedReport?: boolean;
}

export interface ReportPageProps {
  isSimplifiedReport: boolean;
  reportType?: string;
  decimals: number;
  pageNumber: number;
  tmplVars: any;
  key: string;
  isPrint: boolean;
  projectId: string;
  scenarioId: string;
  selectedDatasetIds?: string[];
}

export default function ReportPage(props: IReportPageProps) {
  const location = useLocation();
  const { decimals = 2, bta } = qs.parse(location.search);

  const reportType = props.reportType || "simplified";

  const templateVars = {
    BTA: bta === "simple" ? templating.simplifiedBTA : templating.defaultBTA,
    GWP: templating.defaultGWP,
  };

  const state = useGlobalState();
  const { projectId } = useParams();
  const project = useProjectQuery({ variables: { projectId: projectId! } })
    ?.data?.project;
  const scenarioId = state.scenarioId || project?.mainScenario?.id || "";

  if (!project) {
    return <CircularProgress />;
  }

  return (
    <>
      {(props.pages || []).map((PageComponent, pageNumber) => (
        <PageComponent
          isSimplifiedReport={!!props.isSimplifiedReport}
          reportType={reportType}
          decimals={Number(decimals)}
          pageNumber={pageNumber}
          tmplVars={templateVars}
          key={`${pageNumber}`}
          isPrint={props.isPrint ?? false}
          projectId={projectId!}
          scenarioId={scenarioId}
          selectedDatasetIds={undefined}
        />
      ))}
    </>
  );
}
