export const defaultBTA = {
  name: "BTA",
  simpleName: "BTA",
  unit: "m²",
  inUnit: "BTA",
};

export const simplifiedBTA = {
  name: "BTA",
  simpleName: "Yta",
  unit: "m²",
  inUnit: "m²",
};

export const defaultGWP = {
  name: "GWP",
  simpleName: "Klimatpåverkan",
};

export interface TemplatingVariables {
  BTA: {
    name: string;
    simpleName: string;
    unit: string; // the unit of the BTA (usually m2)
    inUnit: string; // when used in a calculation or unit: kg Co2e / {inUnit}
  };
  GWP: {
    name: string;
    simpleName: string;
  };
}
