export default function LayerConcrete(props: any) {
  return (
    <svg
      height={150}
      viewBox="0.237 0.604 351.262 189.28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Hatches" transform="matrix(0, 1, -1, 0, 95.447006, -7.091672)">
        <polygon
          stroke="black"
          fill="none"
          points="51.74 36.04 49.3 44.46 57.17 39.03 51.74 36.04"
        />
        <polygon
          stroke="black"
          fill="none"
          points="98.43 72.96 103.31 62.64 106.03 71.06 98.43 72.96"
        />
        <polygon
          stroke="black"
          fill="none"
          points="165.74 24.91 162.49 31.16 167.64 35.77 165.74 24.91"
        />
        <polygon
          stroke="black"
          fill="none"
          points="13.47 84.9 14.01 90.06 22.16 83.54 13.47 84.9"
        />
        <polygon
          stroke="black"
          fill="none"
          points="17.54 20.03 14.83 25.46 18.09 25.73 17.54 20.03"
        />
        <polygon
          stroke="black"
          fill="none"
          points="157.87 60.2 155.97 64.81 161.4 66.98 157.87 60.2"
        />
        <polygon
          stroke="black"
          fill="none"
          points="119.06 17.58 114.17 20.57 119.87 27.63 119.06 17.58"
        />
        <polygon
          stroke="black"
          fill="none"
          points="69.93 9.17 69.66 16.5 76.98 12.43 69.93 9.17"
        />
        <polygon
          stroke="black"
          fill="none"
          points="143.49 95.21 147.01 88.16 147.29 94.94 143.49 95.21"
        />
        <line
          stroke="black"
          fill="none"
          x1="80.43"
          y1="47.59"
          x2="81.26"
          y2="46.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="75.96"
          y1="68.1"
          x2="76.1"
          y2="69.22"
        />
        <line
          stroke="black"
          fill="none"
          x1="58.52"
          y1="53.45"
          x2="60.05"
          y2="52.33"
        />
        <line
          stroke="black"
          fill="none"
          x1="44.98"
          y1="28.19"
          x2="46.1"
          y2="27.22"
        />
        <line
          stroke="black"
          fill="none"
          x1="71.77"
          y1="29.87"
          x2="72.75"
          y2="28.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="91.87"
          y1="38.52"
          x2="92.43"
          y2="39.63"
        />
        <line
          stroke="black"
          fill="none"
          x1="112.66"
          y1="51.22"
          x2="114.05"
          y2="50.52"
        />
        <line
          stroke="black"
          fill="none"
          x1="127.73"
          y1="63.5"
          x2="128.98"
          y2="62.66"
        />
        <line
          stroke="black"
          fill="none"
          x1="131.08"
          y1="80.94"
          x2="132.19"
          y2="81.63"
        />
        <line
          stroke="black"
          fill="none"
          x1="106.8"
          y1="85.4"
          x2="105.68"
          y2="85.82"
        />
        <line
          stroke="black"
          fill="none"
          x1="86.71"
          y1="88.47"
          x2="85.59"
          y2="89.03"
        />
        <line
          stroke="black"
          fill="none"
          x1="69.26"
          y1="89.45"
          x2="68.15"
          y2="88.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="54.33"
          y1="88.89"
          x2="52.94"
          y2="89.73"
        />
        <line
          stroke="black"
          fill="none"
          x1="23.78"
          y1="70.33"
          x2="24.19"
          y2="71.31"
        />
        <line
          stroke="black"
          fill="none"
          x1="93.05"
          y1="55.26"
          x2="93.47"
          y2="56.24"
        />
        <path d="M46.55,79.86a3.9,3.9,0,0,0-.64.58,3.55,3.55,0,0,0-.5.7.5.5,0,0,0,0,.38.5.5,0,0,0,.23.3.51.51,0,0,0,.68-.18,4,4,0,0,1,.29-.43l-.08.1a3.64,3.64,0,0,1,.62-.62l-.1.08.06,0a.68.68,0,0,0,.23-.3.59.59,0,0,0,0-.39.52.52,0,0,0-.3-.23.48.48,0,0,0-.39,0Z" />
        <path d="M36.92,77.33l-.19-.19a.34.34,0,0,0-.16-.1.47.47,0,0,0-.38,0,.34.34,0,0,0-.16.1l-.08.1a.46.46,0,0,0-.07.26l0,.13a.42.42,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.43.43,0,0,0,.19,0,.42.42,0,0,0,.16-.11l.08-.1a.52.52,0,0,0,.07-.25l0-.13a.54.54,0,0,0-.13-.22Z" />
        <path d="M41.16,68.19V68a.45.45,0,0,0,0-.2.42.42,0,0,0-.11-.16.34.34,0,0,0-.16-.1.31.31,0,0,0-.19,0h-.13a.54.54,0,0,0-.22.13l-.08.1a.48.48,0,0,0-.07.26v.18a.45.45,0,0,0,0,.2.42.42,0,0,0,.11.16.29.29,0,0,0,.16.1.31.31,0,0,0,.19,0h.13a.54.54,0,0,0,.22-.13l.08-.1a.48.48,0,0,0,.07-.26Z" />
        <path d="M30.24,40.9h-.19a.34.34,0,0,0-.19.05.39.39,0,0,0-.16.1.34.34,0,0,0-.1.16.41.41,0,0,0-.05.19l0,.14a.71.71,0,0,0,.13.22l.1.07a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.44.44,0,0,0,.16-.1.39.39,0,0,0,.11-.17.38.38,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M123.08,73.27h-.19a.41.41,0,0,0-.19,0,.34.34,0,0,0-.16.1.42.42,0,0,0-.11.16.43.43,0,0,0,0,.19l0,.14a.5.5,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.19a.31.31,0,0,0,.19,0,.34.34,0,0,0,.16-.1.39.39,0,0,0,.1-.16.35.35,0,0,0,0-.2l0-.13a.5.5,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M89.9,82.05,89,81.68a.5.5,0,0,0-.56.73l.08.1a.45.45,0,0,0,.22.13l.93.38A.56.56,0,0,0,90,83a.51.51,0,0,0,.18-.69l-.08-.1a.54.54,0,0,0-.22-.13Z" />
        <path d="M70.05,79.6h-.18a.38.38,0,0,0-.19,0,.39.39,0,0,0-.17.11.44.44,0,0,0-.1.16.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.18a.45.45,0,0,0,.2,0,.42.42,0,0,0,.16-.11.34.34,0,0,0,.1-.16.31.31,0,0,0,0-.19V80a.54.54,0,0,0-.13-.22l-.1-.08a.48.48,0,0,0-.26-.07Z" />
        <path d="M59.64,25.83h-.19a.32.32,0,0,0-.19.05.29.29,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19l0,.14a.46.46,0,0,0,.13.22l.1.07a.43.43,0,0,0,.25.07h.19a.33.33,0,0,0,.19,0,.54.54,0,0,0,.16-.1.39.39,0,0,0,.1-.16.46.46,0,0,0,0-.2l0-.13A.54.54,0,0,0,60,26l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M90.33,14.86h-.18a.34.34,0,0,0-.2,0,.42.42,0,0,0-.26.26.34.34,0,0,0,0,.2v.13a.61.61,0,0,0,.13.22l.1.08a.59.59,0,0,0,.26.07h.18a.46.46,0,0,0,.2,0,.42.42,0,0,0,.26-.26.33.33,0,0,0,0-.19v-.14a.61.61,0,0,0-.13-.22l-.1-.08a.58.58,0,0,0-.26-.06Z" />
        <path d="M100.38,28.62h-.56a.32.32,0,0,0-.19.05.34.34,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19.53.53,0,0,0,.15.36.52.52,0,0,0,.35.14h.56a.31.31,0,0,0,.19,0,.34.34,0,0,0,.16-.1.42.42,0,0,0,.11-.16.45.45,0,0,0,0-.2.51.51,0,0,0-.5-.5Z" />
        <path d="M104.64,79.34l.19-.19a.44.44,0,0,0,.1-.16.31.31,0,0,0,0-.19.34.34,0,0,0,0-.2.54.54,0,0,0-.1-.16l-.11-.07a.43.43,0,0,0-.25-.07l-.13,0a.43.43,0,0,0-.22.12l-.19.19a.39.39,0,0,0-.1.16.31.31,0,0,0,0,.19.34.34,0,0,0,0,.2.39.39,0,0,0,.1.16l.1.08a.58.58,0,0,0,.26.06h.13a.61.61,0,0,0,.22-.13Z" />
        <path d="M122.65,35.17,121.9,35a.56.56,0,0,0-.38.05.51.51,0,0,0-.18.69.5.5,0,0,0,.3.23l.74.19a.59.59,0,0,0,.39,0,.57.57,0,0,0,.23-.3.53.53,0,0,0,0-.39.5.5,0,0,0-.3-.23Z" />
        <path d="M117.5,31.41h-.19a.41.41,0,0,0-.19.05.34.34,0,0,0-.16.1.42.42,0,0,0-.11.16.43.43,0,0,0,0,.19l0,.14a.42.42,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.19a.33.33,0,0,0,.19,0,.42.42,0,0,0,.26-.26.46.46,0,0,0,0-.2l0-.13a.61.61,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M160.84,81.65h-.37a.31.31,0,0,0-.19,0,.34.34,0,0,0-.16.1.39.39,0,0,0-.1.16.35.35,0,0,0-.05.2l0,.13a.5.5,0,0,0,.13.22l.1.08a.55.55,0,0,0,.25.07h.37a.46.46,0,0,0,.2-.05.42.42,0,0,0,.26-.26.31.31,0,0,0,0-.19V82a.71.71,0,0,0-.13-.22l-.1-.08a.58.58,0,0,0-.26-.06Z" />
        <path d="M157.85,91.1l-.19-.19a.34.34,0,0,0-.16-.1.39.39,0,0,0-.38,0,.29.29,0,0,0-.16.1l-.08.1a.45.45,0,0,0-.07.25l0,.14a.46.46,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.43.43,0,0,0,.19,0,.42.42,0,0,0,.16-.11l.08-.1a.55.55,0,0,0,.07-.25l0-.13a.54.54,0,0,0-.13-.22Z" />
        <path d="M174.7,77.41l-.56-.38A.3.3,0,0,0,174,77a.44.44,0,0,0-.2,0,.28.28,0,0,0-.17.09.29.29,0,0,0-.13.14.51.51,0,0,0-.05.38l.05.12a.39.39,0,0,0,.18.18l.55.38a.33.33,0,0,0,.19.06.35.35,0,0,0,.2,0,.32.32,0,0,0,.17-.09.44.44,0,0,0,.13-.14.5.5,0,0,0,.05-.38l-.05-.12a.52.52,0,0,0-.18-.18Z" />
        <path d="M179.4,54.92l-.37.56a.33.33,0,0,0-.06.19.35.35,0,0,0,0,.2.32.32,0,0,0,.09.17.44.44,0,0,0,.14.13.5.5,0,0,0,.38,0l.12,0a.52.52,0,0,0,.18-.18l.37-.56a.33.33,0,0,0,.06-.19.45.45,0,0,0,0-.2.4.4,0,0,0-.09-.17.35.35,0,0,0-.14-.13.51.51,0,0,0-.38-.05l-.12.05a.52.52,0,0,0-.18.18Z" />
        <path d="M155.47,42.72l-.19.19a.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19.34.34,0,0,0,0,.2.39.39,0,0,0,.1.16l.1.07a.46.46,0,0,0,.26.07l.13,0a.48.48,0,0,0,.22-.12l.18-.19a.42.42,0,0,0,.11-.16.41.41,0,0,0,0-.19.45.45,0,0,0,0-.2.42.42,0,0,0-.11-.16l-.1-.08a.51.51,0,0,0-.25-.06h-.13a.54.54,0,0,0-.22.13Z" />
        <path d="M151.56,21l-.19.18a.54.54,0,0,0-.1.16.45.45,0,0,0,0,.2.43.43,0,0,0,0,.19.54.54,0,0,0,.1.16l.11.08a.52.52,0,0,0,.25.07l.13,0a.54.54,0,0,0,.22-.13l.19-.19a.34.34,0,0,0,.1-.16.47.47,0,0,0,0-.38.29.29,0,0,0-.1-.16l-.1-.08a.48.48,0,0,0-.26-.07l-.13,0a.42.42,0,0,0-.22.13Z" />
        <path d="M161.77,16.53h-.55a.45.45,0,0,0-.2,0,.42.42,0,0,0-.16.11.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19.46.46,0,0,0,.14.35.53.53,0,0,0,.36.15h.55a.45.45,0,0,0,.2,0,.42.42,0,0,0,.16-.11.34.34,0,0,0,.1-.16.31.31,0,0,0,0-.19.46.46,0,0,0-.14-.35.53.53,0,0,0-.36-.15Z" />
        <path d="M155.82,16h-.37a.32.32,0,0,0-.19,0,.29.29,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.23l.1.07a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.37.37,0,0,0,.16-.1.39.39,0,0,0,.11-.17.38.38,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.52.52,0,0,0-.25-.07Z" />
        <path d="M184.1,7.6h-.37a.41.41,0,0,0-.19,0,.39.39,0,0,0-.17.11.44.44,0,0,0-.1.16.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.53.53,0,0,0,.27-.27.41.41,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.45.45,0,0,0-.25-.07Z" />
        <path d="M188.94,48.53h-.19a.41.41,0,0,0-.19,0,.53.53,0,0,0-.27.27.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.22l.1.08a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11.34.34,0,0,0,.1-.16.32.32,0,0,0,.05-.19l0-.13a.45.45,0,0,0-.13-.22l-.1-.08a.45.45,0,0,0-.25-.07Z" />
        <path d="M187.43,93l-.19-.19a.29.29,0,0,0-.16-.1.39.39,0,0,0-.38,0,.34.34,0,0,0-.16.1l-.08.1a.45.45,0,0,0-.07.25l0,.14a.56.56,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11l.08-.1a.55.55,0,0,0,.07-.25l0-.13a.45.45,0,0,0-.13-.22Z" />
        <path d="M8.86,85.33l-.18.18a.42.42,0,0,0-.11.16.45.45,0,0,0,0,.2.41.41,0,0,0,0,.19.36.36,0,0,0,.11.16l.1.08a.55.55,0,0,0,.25.07l.13,0a.45.45,0,0,0,.22-.13L9.57,86a.29.29,0,0,0,.1-.16.39.39,0,0,0,0-.38.34.34,0,0,0-.1-.16l-.1-.08a.45.45,0,0,0-.25-.07l-.14,0a.56.56,0,0,0-.22.13Z" />
        <path d="M25.4,91.13h-.18a.46.46,0,0,0-.2.05.42.42,0,0,0-.26.26.33.33,0,0,0,0,.19v.14a.61.61,0,0,0,.13.22l.1.08a.58.58,0,0,0,.26.06h.18a.34.34,0,0,0,.2,0,.42.42,0,0,0,.26-.26.34.34,0,0,0,0-.2V91.5a.61.61,0,0,0-.13-.22l-.1-.08a.59.59,0,0,0-.26-.07Z" />
        <path d="M11.82,54.67h-.18a.45.45,0,0,0-.2,0,.42.42,0,0,0-.16.11.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19v.13a.54.54,0,0,0,.13.22l.1.08a.48.48,0,0,0,.26.07h.18a.41.41,0,0,0,.19,0,.39.39,0,0,0,.17-.11.44.44,0,0,0,.1-.16.41.41,0,0,0,0-.19l0-.13a.43.43,0,0,0-.12-.22l-.11-.08a.43.43,0,0,0-.25-.07Z" />
        <path d="M15.9,40.49l-.19-.19a.54.54,0,0,0-.16-.1.41.41,0,0,0-.19,0,.45.45,0,0,0-.2,0,.54.54,0,0,0-.16.1l-.08.11a.51.51,0,0,0-.06.25v.13A.54.54,0,0,0,15,41l.19.19a.34.34,0,0,0,.16.1.31.31,0,0,0,.19,0,.34.34,0,0,0,.2,0,.39.39,0,0,0,.16-.1l.07-.1a.48.48,0,0,0,.07-.26l0-.13a.48.48,0,0,0-.12-.22Z" />
        <path d="M10.58,27.94l-.19-.37a.29.29,0,0,0-.13-.14.26.26,0,0,0-.17-.09.34.34,0,0,0-.2,0,.32.32,0,0,0-.18.06l-.1.08a.45.45,0,0,0-.13.22l0,.13a.43.43,0,0,0,.07.25l.19.38a.53.53,0,0,0,.12.14.56.56,0,0,0,.17.09.34.34,0,0,0,.2,0,.33.33,0,0,0,.19-.06l.1-.07a.56.56,0,0,0,.13-.22l0-.14a.55.55,0,0,0-.07-.25Z" />
        <path d="M46.43,8.34h-.38a.45.45,0,0,0-.19.05.42.42,0,0,0-.26.26.45.45,0,0,0-.05.19l0,.14a.61.61,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.38a.33.33,0,0,0,.19,0A.42.42,0,0,0,46.88,9a.46.46,0,0,0,0-.2l0-.13a.61.61,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M39.52,20l-.18-.19a.39.39,0,0,0-.16-.1.35.35,0,0,0-.2,0,.34.34,0,0,0-.19,0,.39.39,0,0,0-.16.1l-.08.1a.55.55,0,0,0-.07.25l0,.14a.71.71,0,0,0,.13.22l.19.18a.36.36,0,0,0,.16.11.47.47,0,0,0,.38,0,.36.36,0,0,0,.16-.11l.08-.1a.45.45,0,0,0,.07-.25l0-.13a.38.38,0,0,0-.13-.22Z" />
        <path d="M41.48,42.22l.19-.37a.6.6,0,0,0,.06-.19.46.46,0,0,0,0-.2.56.56,0,0,0-.09-.17.41.41,0,0,0-.14-.12l-.12-.05a.42.42,0,0,0-.27,0l-.12.05a.52.52,0,0,0-.18.18l-.18.37a.3.3,0,0,0-.06.18.34.34,0,0,0,0,.2.28.28,0,0,0,.09.17.29.29,0,0,0,.14.13l.12.05a.39.39,0,0,0,.26,0l.12-.05a.39.39,0,0,0,.18-.18Z" />
        <path d="M137.72,33l-.19.38a.3.3,0,0,0-.06.18.34.34,0,0,0,0,.2.28.28,0,0,0,.09.17.29.29,0,0,0,.14.13l.12,0a.57.57,0,0,0,.26,0l.12,0a.39.39,0,0,0,.18-.18l.19-.37a.42.42,0,0,0,.06-.19.46.46,0,0,0,0-.2.56.56,0,0,0-.09-.17.58.58,0,0,0-.14-.13l-.12-.05a.62.62,0,0,0-.27,0l-.12.05a.58.58,0,0,0-.17.18Z" />
        <path d="M141.87,56.23h.56a.34.34,0,0,0,.19,0,.39.39,0,0,0,.16-.1.34.34,0,0,0,.1-.16.41.41,0,0,0,.05-.19.53.53,0,0,0-.15-.36.52.52,0,0,0-.35-.14h-.56a.38.38,0,0,0-.19,0,.39.39,0,0,0-.27.27.38.38,0,0,0,0,.19.5.5,0,0,0,.14.35.53.53,0,0,0,.36.15Z" />
        <path d="M175.73,22.86h-.19a.31.31,0,0,0-.19,0,.34.34,0,0,0-.16.1.39.39,0,0,0-.1.16.35.35,0,0,0-.05.2l0,.13a.5.5,0,0,0,.13.22l.1.08a.55.55,0,0,0,.25.07h.19a.41.41,0,0,0,.19-.05.34.34,0,0,0,.16-.1.57.57,0,0,0,.11-.16.43.43,0,0,0,0-.19l0-.14a.5.5,0,0,0-.13-.22l-.1-.08a.54.54,0,0,0-.25-.06Z" />
        <path d="M175.17,34h-.37a.38.38,0,0,0-.19,0,.39.39,0,0,0-.27.27.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.53.53,0,0,0,.27-.27.41.41,0,0,0,0-.19l0-.13a.45.45,0,0,0-.13-.23l-.1-.07a.45.45,0,0,0-.25-.07Z" />
        <path d="M192.47,34.58h-.18a.34.34,0,0,0-.2,0,.42.42,0,0,0-.26.26.34.34,0,0,0,0,.2v.13a.61.61,0,0,0,.13.22l.1.08a.59.59,0,0,0,.26.07h.18a.38.38,0,0,0,.19,0,.37.37,0,0,0,.17-.1.54.54,0,0,0,.1-.16.41.41,0,0,0,0-.19l0-.14a.53.53,0,0,0-.12-.22l-.11-.08a.51.51,0,0,0-.25-.06Z" />
        <path d="M181.87,17.09h-.19a.41.41,0,0,0-.19,0,.44.44,0,0,0-.16.1.39.39,0,0,0-.11.17.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.22l.1.08a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11.34.34,0,0,0,.1-.16.32.32,0,0,0,.05-.19l0-.13a.53.53,0,0,0-.13-.23l-.1-.07a.45.45,0,0,0-.25-.07Z" />
        <path d="M31.11,54.57l.56-.75a.63.63,0,0,0,.12-.37.78.78,0,0,0,0-.4,1,1,0,0,0-.18-.34.75.75,0,0,0-.28-.25l-.24-.11a1.23,1.23,0,0,0-.54,0l-.23.11a.93.93,0,0,0-.36.35l-.56.75a.71.71,0,0,0-.12.37.78.78,0,0,0,0,.4.75.75,0,0,0,.18.34.78.78,0,0,0,.28.26L30,55a1.19,1.19,0,0,0,.53,0l.24-.1a.93.93,0,0,0,.35-.36Z" />
        <path d="M60.94,16.59h-.19a.71.71,0,0,0-.38.08.78.78,0,0,0-.33.21.83.83,0,0,0-.2.32.86.86,0,0,0-.09.39l0,.26a1,1,0,0,0,.25.45l.21.15a1,1,0,0,0,.5.14h.19a.82.82,0,0,0,.38-.09.76.76,0,0,0,.32-.2.78.78,0,0,0,.21-.33.82.82,0,0,0,.09-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.16a1,1,0,0,0-.5-.13Z" />
        <path d="M134.65,24l-.19.19a.76.76,0,0,0-.2.32.65.65,0,0,0-.09.38.66.66,0,0,0,.09.39.83.83,0,0,0,.2.32l.21.16a1,1,0,0,0,.5.13l.27,0a1,1,0,0,0,.44-.26l.18-.18a.78.78,0,0,0,.21-.33.82.82,0,0,0,.09-.38.86.86,0,0,0-.09-.39.75.75,0,0,0-.21-.32l-.2-.16a1,1,0,0,0-.5-.13l-.27,0a1,1,0,0,0-.44.26Z" />
        <path d="M176.25,57.44l-.19-.19a.76.76,0,0,0-.32-.2.73.73,0,0,0-.38-.09.75.75,0,0,0-.39.09.83.83,0,0,0-.32.2l-.16.21a1,1,0,0,0-.13.5l0,.27a1.1,1.1,0,0,0,.26.44l.19.18a.69.69,0,0,0,.32.21.82.82,0,0,0,.38.09.86.86,0,0,0,.39-.09.75.75,0,0,0,.32-.21l.16-.2a1,1,0,0,0,.13-.5l0-.27a1,1,0,0,0-.26-.44Z" />
        <path d="M9,8.59H8.84a.73.73,0,0,0-.38.08.94.94,0,0,0-.53.53.86.86,0,0,0-.09.39l0,.26a1,1,0,0,0,.26.45l.2.15a1,1,0,0,0,.5.14H9a.86.86,0,0,0,.39-.09.83.83,0,0,0,.32-.2.75.75,0,0,0,.2-.33A.63.63,0,0,0,10,9.59l0-.27a1.1,1.1,0,0,0-.26-.44l-.2-.16A1,1,0,0,0,9,8.59Z" />
        <path d="M35.26,89.7h-.18a.86.86,0,0,0-.39.09.94.94,0,0,0-.53.53.73.73,0,0,0-.08.38l0,.27a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.13h.18a1,1,0,0,0,1-1l0-.26A1,1,0,0,0,36,90l-.2-.16a1.15,1.15,0,0,0-.51-.14Z" />
        <path d="M188.88,28.06l-.18-.18a1.11,1.11,0,0,0-.49-.31,1,1,0,0,0-.57-.13,1.06,1.06,0,0,0-.58.13,1.11,1.11,0,0,0-.49.31l-.23.3a1.44,1.44,0,0,0-.2.76l.05.4a1.57,1.57,0,0,0,.38.66l.19.18a1.14,1.14,0,0,0,.48.31,1.36,1.36,0,0,0,1.16,0,1,1,0,0,0,.48-.31l.24-.3a1.54,1.54,0,0,0,.2-.76l0-.4a1.49,1.49,0,0,0-.39-.66Z" />
        <path d="M64.47,66.72a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M105.4,14.62a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M129.22,47a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M122.89,88.67a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M185.4,83.09a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M179.09,89v-.37a1.15,1.15,0,0,0-.13-.58,1,1,0,0,0-.31-.48,1.14,1.14,0,0,0-.48-.31,1.06,1.06,0,0,0-.58-.13l-.4,0a1.49,1.49,0,0,0-.66.39l-.24.3a1.54,1.54,0,0,0-.2.76V89a1.06,1.06,0,0,0,.13.58,1.14,1.14,0,0,0,.31.48,1,1,0,0,0,.48.31,1.15,1.15,0,0,0,.58.13l.4,0a1.49,1.49,0,0,0,.66-.39l.23-.3a1.48,1.48,0,0,0,.21-.76Z" />
        <path d="M33.22,14.25a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M17.22,65.47a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M24.29,47.61a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M147.45,69.43h-.75a.73.73,0,0,0-.38.08.94.94,0,0,0-.53.53.86.86,0,0,0-.09.39l0,.26a.94.94,0,0,0,.26.44l.2.16a1.11,1.11,0,0,0,.5.14h.75a.79.79,0,0,0,.38-.09.72.72,0,0,0,.33-.21.83.83,0,0,0,.2-.32.73.73,0,0,0,.09-.38l0-.27a.92.92,0,0,0-.25-.44l-.21-.16a1,1,0,0,0-.5-.13Z" />
        <path d="M142.24,10.26h-.37a.86.86,0,0,0-.39.09.75.75,0,0,0-.32.21.69.69,0,0,0-.21.32.73.73,0,0,0-.08.38l0,.27a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.13h.37A1,1,0,0,0,143,12a.83.83,0,0,0,.2-.32.66.66,0,0,0,.09-.39l0-.26a.92.92,0,0,0-.25-.44l-.21-.16a1,1,0,0,0-.5-.14Z" />
        <path d="M84.19,61.38a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M83.82,27h-.74a1,1,0,0,0-1,1l0,.26a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.14h.74a.86.86,0,0,0,.39-.09.75.75,0,0,0,.32-.21.78.78,0,0,0,.21-.32.73.73,0,0,0,.08-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.16a1,1,0,0,0-.51-.13Z" />
        <path d="M31.73,27.89a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M56.66,79.24a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M187.64,70.68a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M189.12,13a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M149,37.64l.38.38a.76.76,0,0,0,.32.2.73.73,0,0,0,.38.09.75.75,0,0,0,.39-.09.83.83,0,0,0,.32-.2l.16-.21a1,1,0,0,0,.13-.5l0-.27a1.1,1.1,0,0,0-.26-.44l-.37-.37a1,1,0,0,0-.71-.29.71.71,0,0,0-.38.08.78.78,0,0,0-.33.21l-.15.2a1,1,0,0,0-.14.51l0,.26a.92.92,0,0,0,.25.44Z" />
        <path d="M164.57,50.45h-.75a.71.71,0,0,0-.38.08.81.81,0,0,0-.33.21.83.83,0,0,0-.2.32.75.75,0,0,0-.09.39l0,.26a1,1,0,0,0,.25.45l.21.15a1,1,0,0,0,.5.14h.75a.73.73,0,0,0,.38-.09.83.83,0,0,0,.32-.2.72.72,0,0,0,.21-.33.82.82,0,0,0,.09-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.15a.9.9,0,0,0-.5-.14Z" />
        <path d="M46.39,62l.38-.75a.77.77,0,0,0,.12-.37.78.78,0,0,0,0-.4.75.75,0,0,0-.18-.34.68.68,0,0,0-.28-.25l-.24-.1a1,1,0,0,0-.53,0l-.24.1a.93.93,0,0,0-.36.35l-.37.75a.71.71,0,0,0-.12.37.78.78,0,0,0,0,.4.75.75,0,0,0,.18.34.78.78,0,0,0,.28.26l.24.1a1.19,1.19,0,0,0,.53,0l.24-.1a.93.93,0,0,0,.35-.36Z" />
        <path d="M71.91,45.38a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M82.19,77.73l-1.12.74a.78.78,0,0,0-.28.26.75.75,0,0,0-.18.34.78.78,0,0,0,0,.4.81.81,0,0,0,.12.37,1,1,0,0,0,.6.46l.27,0a1,1,0,0,0,.5-.14l1.12-.74a1.15,1.15,0,0,0,.46-.6.77.77,0,0,0,0-.4.85.85,0,0,0-.12-.37,1,1,0,0,0-.6-.46l-.26,0a1,1,0,0,0-.51.13Z" />
        <path d="M105.66,39.78l-.38.75a.77.77,0,0,0-.12.37.91.91,0,0,0,0,.4.75.75,0,0,0,.18.34.84.84,0,0,0,.28.25l.24.1a1,1,0,0,0,.53,0l.24-.1a1,1,0,0,0,.36-.36l.37-.74a.63.63,0,0,0,.12-.37.78.78,0,0,0,0-.4.75.75,0,0,0-.18-.34.78.78,0,0,0-.28-.26l-.24-.1a1,1,0,0,0-.53,0l-.24.1a.93.93,0,0,0-.35.36Z" />
        <path d="M119.55,68.57l1.12-1.11a.72.72,0,0,0,.2-.32.66.66,0,0,0,.09-.39.63.63,0,0,0-.09-.38.7.7,0,0,0-.2-.33,1,1,0,0,0-.71-.29,1.07,1.07,0,0,0-.71.29l-1.11,1.12a.75.75,0,0,0-.21.32.86.86,0,0,0-.09.39.82.82,0,0,0,.09.38.69.69,0,0,0,.21.32,1,1,0,0,0,1.41,0Z" />
      </g>
      <g id="g-1" transform="matrix(0, 1, -1, 0, 181.911697, -3.939697)">
        <polygon
          stroke="black"
          fill="none"
          points="51.74 36.04 49.3 44.46 57.17 39.03 51.74 36.04"
        />
        <polygon
          stroke="black"
          fill="none"
          points="98.43 72.96 103.31 62.64 106.03 71.06 98.43 72.96"
        />
        <polygon
          stroke="black"
          fill="none"
          points="165.74 24.91 162.49 31.16 167.64 35.77 165.74 24.91"
        />
        <polygon
          stroke="black"
          fill="none"
          points="13.47 84.9 14.01 90.06 22.16 83.54 13.47 84.9"
        />
        <polygon
          stroke="black"
          fill="none"
          points="17.54 20.03 14.83 25.46 18.09 25.73 17.54 20.03"
        />
        <polygon
          stroke="black"
          fill="none"
          points="157.87 60.2 155.97 64.81 161.4 66.98 157.87 60.2"
        />
        <polygon
          stroke="black"
          fill="none"
          points="119.06 17.58 114.17 20.57 119.87 27.63 119.06 17.58"
        />
        <polygon
          stroke="black"
          fill="none"
          points="69.93 9.17 69.66 16.5 76.98 12.43 69.93 9.17"
        />
        <polygon
          stroke="black"
          fill="none"
          points="143.49 95.21 147.01 88.16 147.29 94.94 143.49 95.21"
        />
        <line
          stroke="black"
          fill="none"
          x1="80.43"
          y1="47.59"
          x2="81.26"
          y2="46.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="75.96"
          y1="68.1"
          x2="76.1"
          y2="69.22"
        />
        <line
          stroke="black"
          fill="none"
          x1="58.52"
          y1="53.45"
          x2="60.05"
          y2="52.33"
        />
        <line
          stroke="black"
          fill="none"
          x1="44.98"
          y1="28.19"
          x2="46.1"
          y2="27.22"
        />
        <line
          stroke="black"
          fill="none"
          x1="71.77"
          y1="29.87"
          x2="72.75"
          y2="28.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="91.87"
          y1="38.52"
          x2="92.43"
          y2="39.63"
        />
        <line
          stroke="black"
          fill="none"
          x1="112.66"
          y1="51.22"
          x2="114.05"
          y2="50.52"
        />
        <line
          stroke="black"
          fill="none"
          x1="127.73"
          y1="63.5"
          x2="128.98"
          y2="62.66"
        />
        <line
          stroke="black"
          fill="none"
          x1="131.08"
          y1="80.94"
          x2="132.19"
          y2="81.63"
        />
        <line
          stroke="black"
          fill="none"
          x1="106.8"
          y1="85.4"
          x2="105.68"
          y2="85.82"
        />
        <line
          stroke="black"
          fill="none"
          x1="86.71"
          y1="88.47"
          x2="85.59"
          y2="89.03"
        />
        <line
          stroke="black"
          fill="none"
          x1="69.26"
          y1="89.45"
          x2="68.15"
          y2="88.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="54.33"
          y1="88.89"
          x2="52.94"
          y2="89.73"
        />
        <line
          stroke="black"
          fill="none"
          x1="23.78"
          y1="70.33"
          x2="24.19"
          y2="71.31"
        />
        <line
          stroke="black"
          fill="none"
          x1="93.05"
          y1="55.26"
          x2="93.47"
          y2="56.24"
        />
        <path d="M46.55,79.86a3.9,3.9,0,0,0-.64.58,3.55,3.55,0,0,0-.5.7.5.5,0,0,0,0,.38.5.5,0,0,0,.23.3.51.51,0,0,0,.68-.18,4,4,0,0,1,.29-.43l-.08.1a3.64,3.64,0,0,1,.62-.62l-.1.08.06,0a.68.68,0,0,0,.23-.3.59.59,0,0,0,0-.39.52.52,0,0,0-.3-.23.48.48,0,0,0-.39,0Z" />
        <path d="M36.92,77.33l-.19-.19a.34.34,0,0,0-.16-.1.47.47,0,0,0-.38,0,.34.34,0,0,0-.16.1l-.08.1a.46.46,0,0,0-.07.26l0,.13a.42.42,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.43.43,0,0,0,.19,0,.42.42,0,0,0,.16-.11l.08-.1a.52.52,0,0,0,.07-.25l0-.13a.54.54,0,0,0-.13-.22Z" />
        <path d="M41.16,68.19V68a.45.45,0,0,0,0-.2.42.42,0,0,0-.11-.16.34.34,0,0,0-.16-.1.31.31,0,0,0-.19,0h-.13a.54.54,0,0,0-.22.13l-.08.1a.48.48,0,0,0-.07.26v.18a.45.45,0,0,0,0,.2.42.42,0,0,0,.11.16.29.29,0,0,0,.16.1.31.31,0,0,0,.19,0h.13a.54.54,0,0,0,.22-.13l.08-.1a.48.48,0,0,0,.07-.26Z" />
        <path d="M30.24,40.9h-.19a.34.34,0,0,0-.19.05.39.39,0,0,0-.16.1.34.34,0,0,0-.1.16.41.41,0,0,0-.05.19l0,.14a.71.71,0,0,0,.13.22l.1.07a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.44.44,0,0,0,.16-.1.39.39,0,0,0,.11-.17.38.38,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M123.08,73.27h-.19a.41.41,0,0,0-.19,0,.34.34,0,0,0-.16.1.42.42,0,0,0-.11.16.43.43,0,0,0,0,.19l0,.14a.5.5,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.19a.31.31,0,0,0,.19,0,.34.34,0,0,0,.16-.1.39.39,0,0,0,.1-.16.35.35,0,0,0,0-.2l0-.13a.5.5,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M89.9,82.05,89,81.68a.5.5,0,0,0-.56.73l.08.1a.45.45,0,0,0,.22.13l.93.38A.56.56,0,0,0,90,83a.51.51,0,0,0,.18-.69l-.08-.1a.54.54,0,0,0-.22-.13Z" />
        <path d="M70.05,79.6h-.18a.38.38,0,0,0-.19,0,.39.39,0,0,0-.17.11.44.44,0,0,0-.1.16.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.18a.45.45,0,0,0,.2,0,.42.42,0,0,0,.16-.11.34.34,0,0,0,.1-.16.31.31,0,0,0,0-.19V80a.54.54,0,0,0-.13-.22l-.1-.08a.48.48,0,0,0-.26-.07Z" />
        <path d="M59.64,25.83h-.19a.32.32,0,0,0-.19.05.29.29,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19l0,.14a.46.46,0,0,0,.13.22l.1.07a.43.43,0,0,0,.25.07h.19a.33.33,0,0,0,.19,0,.54.54,0,0,0,.16-.1.39.39,0,0,0,.1-.16.46.46,0,0,0,0-.2l0-.13A.54.54,0,0,0,60,26l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M90.33,14.86h-.18a.34.34,0,0,0-.2,0,.42.42,0,0,0-.26.26.34.34,0,0,0,0,.2v.13a.61.61,0,0,0,.13.22l.1.08a.59.59,0,0,0,.26.07h.18a.46.46,0,0,0,.2,0,.42.42,0,0,0,.26-.26.33.33,0,0,0,0-.19v-.14a.61.61,0,0,0-.13-.22l-.1-.08a.58.58,0,0,0-.26-.06Z" />
        <path d="M100.38,28.62h-.56a.32.32,0,0,0-.19.05.34.34,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19.53.53,0,0,0,.15.36.52.52,0,0,0,.35.14h.56a.31.31,0,0,0,.19,0,.34.34,0,0,0,.16-.1.42.42,0,0,0,.11-.16.45.45,0,0,0,0-.2.51.51,0,0,0-.5-.5Z" />
        <path d="M104.64,79.34l.19-.19a.44.44,0,0,0,.1-.16.31.31,0,0,0,0-.19.34.34,0,0,0,0-.2.54.54,0,0,0-.1-.16l-.11-.07a.43.43,0,0,0-.25-.07l-.13,0a.43.43,0,0,0-.22.12l-.19.19a.39.39,0,0,0-.1.16.31.31,0,0,0,0,.19.34.34,0,0,0,0,.2.39.39,0,0,0,.1.16l.1.08a.58.58,0,0,0,.26.06h.13a.61.61,0,0,0,.22-.13Z" />
        <path d="M122.65,35.17,121.9,35a.56.56,0,0,0-.38.05.51.51,0,0,0-.18.69.5.5,0,0,0,.3.23l.74.19a.59.59,0,0,0,.39,0,.57.57,0,0,0,.23-.3.53.53,0,0,0,0-.39.5.5,0,0,0-.3-.23Z" />
        <path d="M117.5,31.41h-.19a.41.41,0,0,0-.19.05.34.34,0,0,0-.16.1.42.42,0,0,0-.11.16.43.43,0,0,0,0,.19l0,.14a.42.42,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.19a.33.33,0,0,0,.19,0,.42.42,0,0,0,.26-.26.46.46,0,0,0,0-.2l0-.13a.61.61,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M160.84,81.65h-.37a.31.31,0,0,0-.19,0,.34.34,0,0,0-.16.1.39.39,0,0,0-.1.16.35.35,0,0,0-.05.2l0,.13a.5.5,0,0,0,.13.22l.1.08a.55.55,0,0,0,.25.07h.37a.46.46,0,0,0,.2-.05.42.42,0,0,0,.26-.26.31.31,0,0,0,0-.19V82a.71.71,0,0,0-.13-.22l-.1-.08a.58.58,0,0,0-.26-.06Z" />
        <path d="M157.85,91.1l-.19-.19a.34.34,0,0,0-.16-.1.39.39,0,0,0-.38,0,.29.29,0,0,0-.16.1l-.08.1a.45.45,0,0,0-.07.25l0,.14a.46.46,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.43.43,0,0,0,.19,0,.42.42,0,0,0,.16-.11l.08-.1a.55.55,0,0,0,.07-.25l0-.13a.54.54,0,0,0-.13-.22Z" />
        <path d="M174.7,77.41l-.56-.38A.3.3,0,0,0,174,77a.44.44,0,0,0-.2,0,.28.28,0,0,0-.17.09.29.29,0,0,0-.13.14.51.51,0,0,0-.05.38l.05.12a.39.39,0,0,0,.18.18l.55.38a.33.33,0,0,0,.19.06.35.35,0,0,0,.2,0,.32.32,0,0,0,.17-.09.44.44,0,0,0,.13-.14.5.5,0,0,0,.05-.38l-.05-.12a.52.52,0,0,0-.18-.18Z" />
        <path d="M179.4,54.92l-.37.56a.33.33,0,0,0-.06.19.35.35,0,0,0,0,.2.32.32,0,0,0,.09.17.44.44,0,0,0,.14.13.5.5,0,0,0,.38,0l.12,0a.52.52,0,0,0,.18-.18l.37-.56a.33.33,0,0,0,.06-.19.45.45,0,0,0,0-.2.4.4,0,0,0-.09-.17.35.35,0,0,0-.14-.13.51.51,0,0,0-.38-.05l-.12.05a.52.52,0,0,0-.18.18Z" />
        <path d="M155.47,42.72l-.19.19a.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19.34.34,0,0,0,0,.2.39.39,0,0,0,.1.16l.1.07a.46.46,0,0,0,.26.07l.13,0a.48.48,0,0,0,.22-.12l.18-.19a.42.42,0,0,0,.11-.16.41.41,0,0,0,0-.19.45.45,0,0,0,0-.2.42.42,0,0,0-.11-.16l-.1-.08a.51.51,0,0,0-.25-.06h-.13a.54.54,0,0,0-.22.13Z" />
        <path d="M151.56,21l-.19.18a.54.54,0,0,0-.1.16.45.45,0,0,0,0,.2.43.43,0,0,0,0,.19.54.54,0,0,0,.1.16l.11.08a.52.52,0,0,0,.25.07l.13,0a.54.54,0,0,0,.22-.13l.19-.19a.34.34,0,0,0,.1-.16.47.47,0,0,0,0-.38.29.29,0,0,0-.1-.16l-.1-.08a.48.48,0,0,0-.26-.07l-.13,0a.42.42,0,0,0-.22.13Z" />
        <path d="M161.77,16.53h-.55a.45.45,0,0,0-.2,0,.42.42,0,0,0-.16.11.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19.46.46,0,0,0,.14.35.53.53,0,0,0,.36.15h.55a.45.45,0,0,0,.2,0,.42.42,0,0,0,.16-.11.34.34,0,0,0,.1-.16.31.31,0,0,0,0-.19.46.46,0,0,0-.14-.35.53.53,0,0,0-.36-.15Z" />
        <path d="M155.82,16h-.37a.32.32,0,0,0-.19,0,.29.29,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.23l.1.07a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.37.37,0,0,0,.16-.1.39.39,0,0,0,.11-.17.38.38,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.52.52,0,0,0-.25-.07Z" />
        <path d="M184.1,7.6h-.37a.41.41,0,0,0-.19,0,.39.39,0,0,0-.17.11.44.44,0,0,0-.1.16.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.53.53,0,0,0,.27-.27.41.41,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.45.45,0,0,0-.25-.07Z" />
        <path d="M188.94,48.53h-.19a.41.41,0,0,0-.19,0,.53.53,0,0,0-.27.27.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.22l.1.08a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11.34.34,0,0,0,.1-.16.32.32,0,0,0,.05-.19l0-.13a.45.45,0,0,0-.13-.22l-.1-.08a.45.45,0,0,0-.25-.07Z" />
        <path d="M187.43,93l-.19-.19a.29.29,0,0,0-.16-.1.39.39,0,0,0-.38,0,.34.34,0,0,0-.16.1l-.08.1a.45.45,0,0,0-.07.25l0,.14a.56.56,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11l.08-.1a.55.55,0,0,0,.07-.25l0-.13a.45.45,0,0,0-.13-.22Z" />
        <path d="M8.86,85.33l-.18.18a.42.42,0,0,0-.11.16.45.45,0,0,0,0,.2.41.41,0,0,0,0,.19.36.36,0,0,0,.11.16l.1.08a.55.55,0,0,0,.25.07l.13,0a.45.45,0,0,0,.22-.13L9.57,86a.29.29,0,0,0,.1-.16.39.39,0,0,0,0-.38.34.34,0,0,0-.1-.16l-.1-.08a.45.45,0,0,0-.25-.07l-.14,0a.56.56,0,0,0-.22.13Z" />
        <path d="M25.4,91.13h-.18a.46.46,0,0,0-.2.05.42.42,0,0,0-.26.26.33.33,0,0,0,0,.19v.14a.61.61,0,0,0,.13.22l.1.08a.58.58,0,0,0,.26.06h.18a.34.34,0,0,0,.2,0,.42.42,0,0,0,.26-.26.34.34,0,0,0,0-.2V91.5a.61.61,0,0,0-.13-.22l-.1-.08a.59.59,0,0,0-.26-.07Z" />
        <path d="M11.82,54.67h-.18a.45.45,0,0,0-.2,0,.42.42,0,0,0-.16.11.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19v.13a.54.54,0,0,0,.13.22l.1.08a.48.48,0,0,0,.26.07h.18a.41.41,0,0,0,.19,0,.39.39,0,0,0,.17-.11.44.44,0,0,0,.1-.16.41.41,0,0,0,0-.19l0-.13a.43.43,0,0,0-.12-.22l-.11-.08a.43.43,0,0,0-.25-.07Z" />
        <path d="M15.9,40.49l-.19-.19a.54.54,0,0,0-.16-.1.41.41,0,0,0-.19,0,.45.45,0,0,0-.2,0,.54.54,0,0,0-.16.1l-.08.11a.51.51,0,0,0-.06.25v.13A.54.54,0,0,0,15,41l.19.19a.34.34,0,0,0,.16.1.31.31,0,0,0,.19,0,.34.34,0,0,0,.2,0,.39.39,0,0,0,.16-.1l.07-.1a.48.48,0,0,0,.07-.26l0-.13a.48.48,0,0,0-.12-.22Z" />
        <path d="M10.58,27.94l-.19-.37a.29.29,0,0,0-.13-.14.26.26,0,0,0-.17-.09.34.34,0,0,0-.2,0,.32.32,0,0,0-.18.06l-.1.08a.45.45,0,0,0-.13.22l0,.13a.43.43,0,0,0,.07.25l.19.38a.53.53,0,0,0,.12.14.56.56,0,0,0,.17.09.34.34,0,0,0,.2,0,.33.33,0,0,0,.19-.06l.1-.07a.56.56,0,0,0,.13-.22l0-.14a.55.55,0,0,0-.07-.25Z" />
        <path d="M46.43,8.34h-.38a.45.45,0,0,0-.19.05.42.42,0,0,0-.26.26.45.45,0,0,0-.05.19l0,.14a.61.61,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.38a.33.33,0,0,0,.19,0A.42.42,0,0,0,46.88,9a.46.46,0,0,0,0-.2l0-.13a.61.61,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M39.52,20l-.18-.19a.39.39,0,0,0-.16-.1.35.35,0,0,0-.2,0,.34.34,0,0,0-.19,0,.39.39,0,0,0-.16.1l-.08.1a.55.55,0,0,0-.07.25l0,.14a.71.71,0,0,0,.13.22l.19.18a.36.36,0,0,0,.16.11.47.47,0,0,0,.38,0,.36.36,0,0,0,.16-.11l.08-.1a.45.45,0,0,0,.07-.25l0-.13a.38.38,0,0,0-.13-.22Z" />
        <path d="M41.48,42.22l.19-.37a.6.6,0,0,0,.06-.19.46.46,0,0,0,0-.2.56.56,0,0,0-.09-.17.41.41,0,0,0-.14-.12l-.12-.05a.42.42,0,0,0-.27,0l-.12.05a.52.52,0,0,0-.18.18l-.18.37a.3.3,0,0,0-.06.18.34.34,0,0,0,0,.2.28.28,0,0,0,.09.17.29.29,0,0,0,.14.13l.12.05a.39.39,0,0,0,.26,0l.12-.05a.39.39,0,0,0,.18-.18Z" />
        <path d="M137.72,33l-.19.38a.3.3,0,0,0-.06.18.34.34,0,0,0,0,.2.28.28,0,0,0,.09.17.29.29,0,0,0,.14.13l.12,0a.57.57,0,0,0,.26,0l.12,0a.39.39,0,0,0,.18-.18l.19-.37a.42.42,0,0,0,.06-.19.46.46,0,0,0,0-.2.56.56,0,0,0-.09-.17.58.58,0,0,0-.14-.13l-.12-.05a.62.62,0,0,0-.27,0l-.12.05a.58.58,0,0,0-.17.18Z" />
        <path d="M141.87,56.23h.56a.34.34,0,0,0,.19,0,.39.39,0,0,0,.16-.1.34.34,0,0,0,.1-.16.41.41,0,0,0,.05-.19.53.53,0,0,0-.15-.36.52.52,0,0,0-.35-.14h-.56a.38.38,0,0,0-.19,0,.39.39,0,0,0-.27.27.38.38,0,0,0,0,.19.5.5,0,0,0,.14.35.53.53,0,0,0,.36.15Z" />
        <path d="M175.73,22.86h-.19a.31.31,0,0,0-.19,0,.34.34,0,0,0-.16.1.39.39,0,0,0-.1.16.35.35,0,0,0-.05.2l0,.13a.5.5,0,0,0,.13.22l.1.08a.55.55,0,0,0,.25.07h.19a.41.41,0,0,0,.19-.05.34.34,0,0,0,.16-.1.57.57,0,0,0,.11-.16.43.43,0,0,0,0-.19l0-.14a.5.5,0,0,0-.13-.22l-.1-.08a.54.54,0,0,0-.25-.06Z" />
        <path d="M175.17,34h-.37a.38.38,0,0,0-.19,0,.39.39,0,0,0-.27.27.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.53.53,0,0,0,.27-.27.41.41,0,0,0,0-.19l0-.13a.45.45,0,0,0-.13-.23l-.1-.07a.45.45,0,0,0-.25-.07Z" />
        <path d="M192.47,34.58h-.18a.34.34,0,0,0-.2,0,.42.42,0,0,0-.26.26.34.34,0,0,0,0,.2v.13a.61.61,0,0,0,.13.22l.1.08a.59.59,0,0,0,.26.07h.18a.38.38,0,0,0,.19,0,.37.37,0,0,0,.17-.1.54.54,0,0,0,.1-.16.41.41,0,0,0,0-.19l0-.14a.53.53,0,0,0-.12-.22l-.11-.08a.51.51,0,0,0-.25-.06Z" />
        <path d="M181.87,17.09h-.19a.41.41,0,0,0-.19,0,.44.44,0,0,0-.16.1.39.39,0,0,0-.11.17.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.22l.1.08a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11.34.34,0,0,0,.1-.16.32.32,0,0,0,.05-.19l0-.13a.53.53,0,0,0-.13-.23l-.1-.07a.45.45,0,0,0-.25-.07Z" />
        <path d="M31.11,54.57l.56-.75a.63.63,0,0,0,.12-.37.78.78,0,0,0,0-.4,1,1,0,0,0-.18-.34.75.75,0,0,0-.28-.25l-.24-.11a1.23,1.23,0,0,0-.54,0l-.23.11a.93.93,0,0,0-.36.35l-.56.75a.71.71,0,0,0-.12.37.78.78,0,0,0,0,.4.75.75,0,0,0,.18.34.78.78,0,0,0,.28.26L30,55a1.19,1.19,0,0,0,.53,0l.24-.1a.93.93,0,0,0,.35-.36Z" />
        <path d="M60.94,16.59h-.19a.71.71,0,0,0-.38.08.78.78,0,0,0-.33.21.83.83,0,0,0-.2.32.86.86,0,0,0-.09.39l0,.26a1,1,0,0,0,.25.45l.21.15a1,1,0,0,0,.5.14h.19a.82.82,0,0,0,.38-.09.76.76,0,0,0,.32-.2.78.78,0,0,0,.21-.33.82.82,0,0,0,.09-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.16a1,1,0,0,0-.5-.13Z" />
        <path d="M134.65,24l-.19.19a.76.76,0,0,0-.2.32.65.65,0,0,0-.09.38.66.66,0,0,0,.09.39.83.83,0,0,0,.2.32l.21.16a1,1,0,0,0,.5.13l.27,0a1,1,0,0,0,.44-.26l.18-.18a.78.78,0,0,0,.21-.33.82.82,0,0,0,.09-.38.86.86,0,0,0-.09-.39.75.75,0,0,0-.21-.32l-.2-.16a1,1,0,0,0-.5-.13l-.27,0a1,1,0,0,0-.44.26Z" />
        <path d="M176.25,57.44l-.19-.19a.76.76,0,0,0-.32-.2.73.73,0,0,0-.38-.09.75.75,0,0,0-.39.09.83.83,0,0,0-.32.2l-.16.21a1,1,0,0,0-.13.5l0,.27a1.1,1.1,0,0,0,.26.44l.19.18a.69.69,0,0,0,.32.21.82.82,0,0,0,.38.09.86.86,0,0,0,.39-.09.75.75,0,0,0,.32-.21l.16-.2a1,1,0,0,0,.13-.5l0-.27a1,1,0,0,0-.26-.44Z" />
        <path d="M9,8.59H8.84a.73.73,0,0,0-.38.08.94.94,0,0,0-.53.53.86.86,0,0,0-.09.39l0,.26a1,1,0,0,0,.26.45l.2.15a1,1,0,0,0,.5.14H9a.86.86,0,0,0,.39-.09.83.83,0,0,0,.32-.2.75.75,0,0,0,.2-.33A.63.63,0,0,0,10,9.59l0-.27a1.1,1.1,0,0,0-.26-.44l-.2-.16A1,1,0,0,0,9,8.59Z" />
        <path d="M35.26,89.7h-.18a.86.86,0,0,0-.39.09.94.94,0,0,0-.53.53.73.73,0,0,0-.08.38l0,.27a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.13h.18a1,1,0,0,0,1-1l0-.26A1,1,0,0,0,36,90l-.2-.16a1.15,1.15,0,0,0-.51-.14Z" />
        <path d="M188.88,28.06l-.18-.18a1.11,1.11,0,0,0-.49-.31,1,1,0,0,0-.57-.13,1.06,1.06,0,0,0-.58.13,1.11,1.11,0,0,0-.49.31l-.23.3a1.44,1.44,0,0,0-.2.76l.05.4a1.57,1.57,0,0,0,.38.66l.19.18a1.14,1.14,0,0,0,.48.31,1.36,1.36,0,0,0,1.16,0,1,1,0,0,0,.48-.31l.24-.3a1.54,1.54,0,0,0,.2-.76l0-.4a1.49,1.49,0,0,0-.39-.66Z" />
        <path d="M64.47,66.72a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M105.4,14.62a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M129.22,47a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M122.89,88.67a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M185.4,83.09a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M179.09,89v-.37a1.15,1.15,0,0,0-.13-.58,1,1,0,0,0-.31-.48,1.14,1.14,0,0,0-.48-.31,1.06,1.06,0,0,0-.58-.13l-.4,0a1.49,1.49,0,0,0-.66.39l-.24.3a1.54,1.54,0,0,0-.2.76V89a1.06,1.06,0,0,0,.13.58,1.14,1.14,0,0,0,.31.48,1,1,0,0,0,.48.31,1.15,1.15,0,0,0,.58.13l.4,0a1.49,1.49,0,0,0,.66-.39l.23-.3a1.48,1.48,0,0,0,.21-.76Z" />
        <path d="M33.22,14.25a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M17.22,65.47a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M24.29,47.61a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M147.45,69.43h-.75a.73.73,0,0,0-.38.08.94.94,0,0,0-.53.53.86.86,0,0,0-.09.39l0,.26a.94.94,0,0,0,.26.44l.2.16a1.11,1.11,0,0,0,.5.14h.75a.79.79,0,0,0,.38-.09.72.72,0,0,0,.33-.21.83.83,0,0,0,.2-.32.73.73,0,0,0,.09-.38l0-.27a.92.92,0,0,0-.25-.44l-.21-.16a1,1,0,0,0-.5-.13Z" />
        <path d="M142.24,10.26h-.37a.86.86,0,0,0-.39.09.75.75,0,0,0-.32.21.69.69,0,0,0-.21.32.73.73,0,0,0-.08.38l0,.27a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.13h.37A1,1,0,0,0,143,12a.83.83,0,0,0,.2-.32.66.66,0,0,0,.09-.39l0-.26a.92.92,0,0,0-.25-.44l-.21-.16a1,1,0,0,0-.5-.14Z" />
        <path d="M84.19,61.38a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M83.82,27h-.74a1,1,0,0,0-1,1l0,.26a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.14h.74a.86.86,0,0,0,.39-.09.75.75,0,0,0,.32-.21.78.78,0,0,0,.21-.32.73.73,0,0,0,.08-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.16a1,1,0,0,0-.51-.13Z" />
        <path d="M31.73,27.89a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M56.66,79.24a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M187.64,70.68a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M189.12,13a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M149,37.64l.38.38a.76.76,0,0,0,.32.2.73.73,0,0,0,.38.09.75.75,0,0,0,.39-.09.83.83,0,0,0,.32-.2l.16-.21a1,1,0,0,0,.13-.5l0-.27a1.1,1.1,0,0,0-.26-.44l-.37-.37a1,1,0,0,0-.71-.29.71.71,0,0,0-.38.08.78.78,0,0,0-.33.21l-.15.2a1,1,0,0,0-.14.51l0,.26a.92.92,0,0,0,.25.44Z" />
        <path d="M164.57,50.45h-.75a.71.71,0,0,0-.38.08.81.81,0,0,0-.33.21.83.83,0,0,0-.2.32.75.75,0,0,0-.09.39l0,.26a1,1,0,0,0,.25.45l.21.15a1,1,0,0,0,.5.14h.75a.73.73,0,0,0,.38-.09.83.83,0,0,0,.32-.2.72.72,0,0,0,.21-.33.82.82,0,0,0,.09-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.15a.9.9,0,0,0-.5-.14Z" />
        <path d="M46.39,62l.38-.75a.77.77,0,0,0,.12-.37.78.78,0,0,0,0-.4.75.75,0,0,0-.18-.34.68.68,0,0,0-.28-.25l-.24-.1a1,1,0,0,0-.53,0l-.24.1a.93.93,0,0,0-.36.35l-.37.75a.71.71,0,0,0-.12.37.78.78,0,0,0,0,.4.75.75,0,0,0,.18.34.78.78,0,0,0,.28.26l.24.1a1.19,1.19,0,0,0,.53,0l.24-.1a.93.93,0,0,0,.35-.36Z" />
        <path d="M71.91,45.38a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M82.19,77.73l-1.12.74a.78.78,0,0,0-.28.26.75.75,0,0,0-.18.34.78.78,0,0,0,0,.4.81.81,0,0,0,.12.37,1,1,0,0,0,.6.46l.27,0a1,1,0,0,0,.5-.14l1.12-.74a1.15,1.15,0,0,0,.46-.6.77.77,0,0,0,0-.4.85.85,0,0,0-.12-.37,1,1,0,0,0-.6-.46l-.26,0a1,1,0,0,0-.51.13Z" />
        <path d="M105.66,39.78l-.38.75a.77.77,0,0,0-.12.37.91.91,0,0,0,0,.4.75.75,0,0,0,.18.34.84.84,0,0,0,.28.25l.24.1a1,1,0,0,0,.53,0l.24-.1a1,1,0,0,0,.36-.36l.37-.74a.63.63,0,0,0,.12-.37.78.78,0,0,0,0-.4.75.75,0,0,0-.18-.34.78.78,0,0,0-.28-.26l-.24-.1a1,1,0,0,0-.53,0l-.24.1a.93.93,0,0,0-.35.36Z" />
        <path d="M119.55,68.57l1.12-1.11a.72.72,0,0,0,.2-.32.66.66,0,0,0,.09-.39.63.63,0,0,0-.09-.38.7.7,0,0,0-.2-.33,1,1,0,0,0-.71-.29,1.07,1.07,0,0,0-.71.29l-1.11,1.12a.75.75,0,0,0-.21.32.86.86,0,0,0-.09.39.82.82,0,0,0,.09.38.69.69,0,0,0,.21.32,1,1,0,0,0,1.41,0Z" />
      </g>
      <g id="g-2" transform="matrix(0, 1, -1, 0, 359.087585, -7.236397)">
        <polygon
          stroke="black"
          fill="none"
          points="51.74 36.04 49.3 44.46 57.17 39.03 51.74 36.04"
        />
        <polygon
          stroke="black"
          fill="none"
          points="98.43 72.96 103.31 62.64 106.03 71.06 98.43 72.96"
        />
        <polygon
          stroke="black"
          fill="none"
          points="165.74 24.91 162.49 31.16 167.64 35.77 165.74 24.91"
        />
        <polygon
          stroke="black"
          fill="none"
          points="13.47 84.9 14.01 90.06 22.16 83.54 13.47 84.9"
        />
        <polygon
          stroke="black"
          fill="none"
          points="17.54 20.03 14.83 25.46 18.09 25.73 17.54 20.03"
        />
        <polygon
          stroke="black"
          fill="none"
          points="157.87 60.2 155.97 64.81 161.4 66.98 157.87 60.2"
        />
        <polygon
          stroke="black"
          fill="none"
          points="119.06 17.58 114.17 20.57 119.87 27.63 119.06 17.58"
        />
        <polygon
          stroke="black"
          fill="none"
          points="69.93 9.17 69.66 16.5 76.98 12.43 69.93 9.17"
        />
        <polygon
          stroke="black"
          fill="none"
          points="143.49 95.21 147.01 88.16 147.29 94.94 143.49 95.21"
        />
        <line
          stroke="black"
          fill="none"
          x1="80.43"
          y1="47.59"
          x2="81.26"
          y2="46.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="75.96"
          y1="68.1"
          x2="76.1"
          y2="69.22"
        />
        <line
          stroke="black"
          fill="none"
          x1="58.52"
          y1="53.45"
          x2="60.05"
          y2="52.33"
        />
        <line
          stroke="black"
          fill="none"
          x1="44.98"
          y1="28.19"
          x2="46.1"
          y2="27.22"
        />
        <line
          stroke="black"
          fill="none"
          x1="71.77"
          y1="29.87"
          x2="72.75"
          y2="28.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="91.87"
          y1="38.52"
          x2="92.43"
          y2="39.63"
        />
        <line
          stroke="black"
          fill="none"
          x1="112.66"
          y1="51.22"
          x2="114.05"
          y2="50.52"
        />
        <line
          stroke="black"
          fill="none"
          x1="127.73"
          y1="63.5"
          x2="128.98"
          y2="62.66"
        />
        <line
          stroke="black"
          fill="none"
          x1="131.08"
          y1="80.94"
          x2="132.19"
          y2="81.63"
        />
        <line
          stroke="black"
          fill="none"
          x1="106.8"
          y1="85.4"
          x2="105.68"
          y2="85.82"
        />
        <line
          stroke="black"
          fill="none"
          x1="86.71"
          y1="88.47"
          x2="85.59"
          y2="89.03"
        />
        <line
          stroke="black"
          fill="none"
          x1="69.26"
          y1="89.45"
          x2="68.15"
          y2="88.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="54.33"
          y1="88.89"
          x2="52.94"
          y2="89.73"
        />
        <line
          stroke="black"
          fill="none"
          x1="23.78"
          y1="70.33"
          x2="24.19"
          y2="71.31"
        />
        <line
          stroke="black"
          fill="none"
          x1="93.05"
          y1="55.26"
          x2="93.47"
          y2="56.24"
        />
        <path d="M46.55,79.86a3.9,3.9,0,0,0-.64.58,3.55,3.55,0,0,0-.5.7.5.5,0,0,0,0,.38.5.5,0,0,0,.23.3.51.51,0,0,0,.68-.18,4,4,0,0,1,.29-.43l-.08.1a3.64,3.64,0,0,1,.62-.62l-.1.08.06,0a.68.68,0,0,0,.23-.3.59.59,0,0,0,0-.39.52.52,0,0,0-.3-.23.48.48,0,0,0-.39,0Z" />
        <path d="M36.92,77.33l-.19-.19a.34.34,0,0,0-.16-.1.47.47,0,0,0-.38,0,.34.34,0,0,0-.16.1l-.08.1a.46.46,0,0,0-.07.26l0,.13a.42.42,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.43.43,0,0,0,.19,0,.42.42,0,0,0,.16-.11l.08-.1a.52.52,0,0,0,.07-.25l0-.13a.54.54,0,0,0-.13-.22Z" />
        <path d="M41.16,68.19V68a.45.45,0,0,0,0-.2.42.42,0,0,0-.11-.16.34.34,0,0,0-.16-.1.31.31,0,0,0-.19,0h-.13a.54.54,0,0,0-.22.13l-.08.1a.48.48,0,0,0-.07.26v.18a.45.45,0,0,0,0,.2.42.42,0,0,0,.11.16.29.29,0,0,0,.16.1.31.31,0,0,0,.19,0h.13a.54.54,0,0,0,.22-.13l.08-.1a.48.48,0,0,0,.07-.26Z" />
        <path d="M30.24,40.9h-.19a.34.34,0,0,0-.19.05.39.39,0,0,0-.16.1.34.34,0,0,0-.1.16.41.41,0,0,0-.05.19l0,.14a.71.71,0,0,0,.13.22l.1.07a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.44.44,0,0,0,.16-.1.39.39,0,0,0,.11-.17.38.38,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M123.08,73.27h-.19a.41.41,0,0,0-.19,0,.34.34,0,0,0-.16.1.42.42,0,0,0-.11.16.43.43,0,0,0,0,.19l0,.14a.5.5,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.19a.31.31,0,0,0,.19,0,.34.34,0,0,0,.16-.1.39.39,0,0,0,.1-.16.35.35,0,0,0,0-.2l0-.13a.5.5,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M89.9,82.05,89,81.68a.5.5,0,0,0-.56.73l.08.1a.45.45,0,0,0,.22.13l.93.38A.56.56,0,0,0,90,83a.51.51,0,0,0,.18-.69l-.08-.1a.54.54,0,0,0-.22-.13Z" />
        <path d="M70.05,79.6h-.18a.38.38,0,0,0-.19,0,.39.39,0,0,0-.17.11.44.44,0,0,0-.1.16.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.18a.45.45,0,0,0,.2,0,.42.42,0,0,0,.16-.11.34.34,0,0,0,.1-.16.31.31,0,0,0,0-.19V80a.54.54,0,0,0-.13-.22l-.1-.08a.48.48,0,0,0-.26-.07Z" />
        <path d="M59.64,25.83h-.19a.32.32,0,0,0-.19.05.29.29,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19l0,.14a.46.46,0,0,0,.13.22l.1.07a.43.43,0,0,0,.25.07h.19a.33.33,0,0,0,.19,0,.54.54,0,0,0,.16-.1.39.39,0,0,0,.1-.16.46.46,0,0,0,0-.2l0-.13A.54.54,0,0,0,60,26l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M90.33,14.86h-.18a.34.34,0,0,0-.2,0,.42.42,0,0,0-.26.26.34.34,0,0,0,0,.2v.13a.61.61,0,0,0,.13.22l.1.08a.59.59,0,0,0,.26.07h.18a.46.46,0,0,0,.2,0,.42.42,0,0,0,.26-.26.33.33,0,0,0,0-.19v-.14a.61.61,0,0,0-.13-.22l-.1-.08a.58.58,0,0,0-.26-.06Z" />
        <path d="M100.38,28.62h-.56a.32.32,0,0,0-.19.05.34.34,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19.53.53,0,0,0,.15.36.52.52,0,0,0,.35.14h.56a.31.31,0,0,0,.19,0,.34.34,0,0,0,.16-.1.42.42,0,0,0,.11-.16.45.45,0,0,0,0-.2.51.51,0,0,0-.5-.5Z" />
        <path d="M104.64,79.34l.19-.19a.44.44,0,0,0,.1-.16.31.31,0,0,0,0-.19.34.34,0,0,0,0-.2.54.54,0,0,0-.1-.16l-.11-.07a.43.43,0,0,0-.25-.07l-.13,0a.43.43,0,0,0-.22.12l-.19.19a.39.39,0,0,0-.1.16.31.31,0,0,0,0,.19.34.34,0,0,0,0,.2.39.39,0,0,0,.1.16l.1.08a.58.58,0,0,0,.26.06h.13a.61.61,0,0,0,.22-.13Z" />
        <path d="M122.65,35.17,121.9,35a.56.56,0,0,0-.38.05.51.51,0,0,0-.18.69.5.5,0,0,0,.3.23l.74.19a.59.59,0,0,0,.39,0,.57.57,0,0,0,.23-.3.53.53,0,0,0,0-.39.5.5,0,0,0-.3-.23Z" />
        <path d="M117.5,31.41h-.19a.41.41,0,0,0-.19.05.34.34,0,0,0-.16.1.42.42,0,0,0-.11.16.43.43,0,0,0,0,.19l0,.14a.42.42,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.19a.33.33,0,0,0,.19,0,.42.42,0,0,0,.26-.26.46.46,0,0,0,0-.2l0-.13a.61.61,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M160.84,81.65h-.37a.31.31,0,0,0-.19,0,.34.34,0,0,0-.16.1.39.39,0,0,0-.1.16.35.35,0,0,0-.05.2l0,.13a.5.5,0,0,0,.13.22l.1.08a.55.55,0,0,0,.25.07h.37a.46.46,0,0,0,.2-.05.42.42,0,0,0,.26-.26.31.31,0,0,0,0-.19V82a.71.71,0,0,0-.13-.22l-.1-.08a.58.58,0,0,0-.26-.06Z" />
        <path d="M157.85,91.1l-.19-.19a.34.34,0,0,0-.16-.1.39.39,0,0,0-.38,0,.29.29,0,0,0-.16.1l-.08.1a.45.45,0,0,0-.07.25l0,.14a.46.46,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.43.43,0,0,0,.19,0,.42.42,0,0,0,.16-.11l.08-.1a.55.55,0,0,0,.07-.25l0-.13a.54.54,0,0,0-.13-.22Z" />
        <path d="M174.7,77.41l-.56-.38A.3.3,0,0,0,174,77a.44.44,0,0,0-.2,0,.28.28,0,0,0-.17.09.29.29,0,0,0-.13.14.51.51,0,0,0-.05.38l.05.12a.39.39,0,0,0,.18.18l.55.38a.33.33,0,0,0,.19.06.35.35,0,0,0,.2,0,.32.32,0,0,0,.17-.09.44.44,0,0,0,.13-.14.5.5,0,0,0,.05-.38l-.05-.12a.52.52,0,0,0-.18-.18Z" />
        <path d="M179.4,54.92l-.37.56a.33.33,0,0,0-.06.19.35.35,0,0,0,0,.2.32.32,0,0,0,.09.17.44.44,0,0,0,.14.13.5.5,0,0,0,.38,0l.12,0a.52.52,0,0,0,.18-.18l.37-.56a.33.33,0,0,0,.06-.19.45.45,0,0,0,0-.2.4.4,0,0,0-.09-.17.35.35,0,0,0-.14-.13.51.51,0,0,0-.38-.05l-.12.05a.52.52,0,0,0-.18.18Z" />
        <path d="M155.47,42.72l-.19.19a.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19.34.34,0,0,0,0,.2.39.39,0,0,0,.1.16l.1.07a.46.46,0,0,0,.26.07l.13,0a.48.48,0,0,0,.22-.12l.18-.19a.42.42,0,0,0,.11-.16.41.41,0,0,0,0-.19.45.45,0,0,0,0-.2.42.42,0,0,0-.11-.16l-.1-.08a.51.51,0,0,0-.25-.06h-.13a.54.54,0,0,0-.22.13Z" />
        <path d="M151.56,21l-.19.18a.54.54,0,0,0-.1.16.45.45,0,0,0,0,.2.43.43,0,0,0,0,.19.54.54,0,0,0,.1.16l.11.08a.52.52,0,0,0,.25.07l.13,0a.54.54,0,0,0,.22-.13l.19-.19a.34.34,0,0,0,.1-.16.47.47,0,0,0,0-.38.29.29,0,0,0-.1-.16l-.1-.08a.48.48,0,0,0-.26-.07l-.13,0a.42.42,0,0,0-.22.13Z" />
        <path d="M161.77,16.53h-.55a.45.45,0,0,0-.2,0,.42.42,0,0,0-.16.11.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19.46.46,0,0,0,.14.35.53.53,0,0,0,.36.15h.55a.45.45,0,0,0,.2,0,.42.42,0,0,0,.16-.11.34.34,0,0,0,.1-.16.31.31,0,0,0,0-.19.46.46,0,0,0-.14-.35.53.53,0,0,0-.36-.15Z" />
        <path d="M155.82,16h-.37a.32.32,0,0,0-.19,0,.29.29,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.23l.1.07a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.37.37,0,0,0,.16-.1.39.39,0,0,0,.11-.17.38.38,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.52.52,0,0,0-.25-.07Z" />
        <path d="M184.1,7.6h-.37a.41.41,0,0,0-.19,0,.39.39,0,0,0-.17.11.44.44,0,0,0-.1.16.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.53.53,0,0,0,.27-.27.41.41,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.45.45,0,0,0-.25-.07Z" />
        <path d="M188.94,48.53h-.19a.41.41,0,0,0-.19,0,.53.53,0,0,0-.27.27.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.22l.1.08a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11.34.34,0,0,0,.1-.16.32.32,0,0,0,.05-.19l0-.13a.45.45,0,0,0-.13-.22l-.1-.08a.45.45,0,0,0-.25-.07Z" />
        <path d="M187.43,93l-.19-.19a.29.29,0,0,0-.16-.1.39.39,0,0,0-.38,0,.34.34,0,0,0-.16.1l-.08.1a.45.45,0,0,0-.07.25l0,.14a.56.56,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11l.08-.1a.55.55,0,0,0,.07-.25l0-.13a.45.45,0,0,0-.13-.22Z" />
        <path d="M8.86,85.33l-.18.18a.42.42,0,0,0-.11.16.45.45,0,0,0,0,.2.41.41,0,0,0,0,.19.36.36,0,0,0,.11.16l.1.08a.55.55,0,0,0,.25.07l.13,0a.45.45,0,0,0,.22-.13L9.57,86a.29.29,0,0,0,.1-.16.39.39,0,0,0,0-.38.34.34,0,0,0-.1-.16l-.1-.08a.45.45,0,0,0-.25-.07l-.14,0a.56.56,0,0,0-.22.13Z" />
        <path d="M25.4,91.13h-.18a.46.46,0,0,0-.2.05.42.42,0,0,0-.26.26.33.33,0,0,0,0,.19v.14a.61.61,0,0,0,.13.22l.1.08a.58.58,0,0,0,.26.06h.18a.34.34,0,0,0,.2,0,.42.42,0,0,0,.26-.26.34.34,0,0,0,0-.2V91.5a.61.61,0,0,0-.13-.22l-.1-.08a.59.59,0,0,0-.26-.07Z" />
        <path d="M11.82,54.67h-.18a.45.45,0,0,0-.2,0,.42.42,0,0,0-.16.11.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19v.13a.54.54,0,0,0,.13.22l.1.08a.48.48,0,0,0,.26.07h.18a.41.41,0,0,0,.19,0,.39.39,0,0,0,.17-.11.44.44,0,0,0,.1-.16.41.41,0,0,0,0-.19l0-.13a.43.43,0,0,0-.12-.22l-.11-.08a.43.43,0,0,0-.25-.07Z" />
        <path d="M15.9,40.49l-.19-.19a.54.54,0,0,0-.16-.1.41.41,0,0,0-.19,0,.45.45,0,0,0-.2,0,.54.54,0,0,0-.16.1l-.08.11a.51.51,0,0,0-.06.25v.13A.54.54,0,0,0,15,41l.19.19a.34.34,0,0,0,.16.1.31.31,0,0,0,.19,0,.34.34,0,0,0,.2,0,.39.39,0,0,0,.16-.1l.07-.1a.48.48,0,0,0,.07-.26l0-.13a.48.48,0,0,0-.12-.22Z" />
        <path d="M10.58,27.94l-.19-.37a.29.29,0,0,0-.13-.14.26.26,0,0,0-.17-.09.34.34,0,0,0-.2,0,.32.32,0,0,0-.18.06l-.1.08a.45.45,0,0,0-.13.22l0,.13a.43.43,0,0,0,.07.25l.19.38a.53.53,0,0,0,.12.14.56.56,0,0,0,.17.09.34.34,0,0,0,.2,0,.33.33,0,0,0,.19-.06l.1-.07a.56.56,0,0,0,.13-.22l0-.14a.55.55,0,0,0-.07-.25Z" />
        <path d="M46.43,8.34h-.38a.45.45,0,0,0-.19.05.42.42,0,0,0-.26.26.45.45,0,0,0-.05.19l0,.14a.61.61,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.38a.33.33,0,0,0,.19,0A.42.42,0,0,0,46.88,9a.46.46,0,0,0,0-.2l0-.13a.61.61,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M39.52,20l-.18-.19a.39.39,0,0,0-.16-.1.35.35,0,0,0-.2,0,.34.34,0,0,0-.19,0,.39.39,0,0,0-.16.1l-.08.1a.55.55,0,0,0-.07.25l0,.14a.71.71,0,0,0,.13.22l.19.18a.36.36,0,0,0,.16.11.47.47,0,0,0,.38,0,.36.36,0,0,0,.16-.11l.08-.1a.45.45,0,0,0,.07-.25l0-.13a.38.38,0,0,0-.13-.22Z" />
        <path d="M41.48,42.22l.19-.37a.6.6,0,0,0,.06-.19.46.46,0,0,0,0-.2.56.56,0,0,0-.09-.17.41.41,0,0,0-.14-.12l-.12-.05a.42.42,0,0,0-.27,0l-.12.05a.52.52,0,0,0-.18.18l-.18.37a.3.3,0,0,0-.06.18.34.34,0,0,0,0,.2.28.28,0,0,0,.09.17.29.29,0,0,0,.14.13l.12.05a.39.39,0,0,0,.26,0l.12-.05a.39.39,0,0,0,.18-.18Z" />
        <path d="M137.72,33l-.19.38a.3.3,0,0,0-.06.18.34.34,0,0,0,0,.2.28.28,0,0,0,.09.17.29.29,0,0,0,.14.13l.12,0a.57.57,0,0,0,.26,0l.12,0a.39.39,0,0,0,.18-.18l.19-.37a.42.42,0,0,0,.06-.19.46.46,0,0,0,0-.2.56.56,0,0,0-.09-.17.58.58,0,0,0-.14-.13l-.12-.05a.62.62,0,0,0-.27,0l-.12.05a.58.58,0,0,0-.17.18Z" />
        <path d="M141.87,56.23h.56a.34.34,0,0,0,.19,0,.39.39,0,0,0,.16-.1.34.34,0,0,0,.1-.16.41.41,0,0,0,.05-.19.53.53,0,0,0-.15-.36.52.52,0,0,0-.35-.14h-.56a.38.38,0,0,0-.19,0,.39.39,0,0,0-.27.27.38.38,0,0,0,0,.19.5.5,0,0,0,.14.35.53.53,0,0,0,.36.15Z" />
        <path d="M175.73,22.86h-.19a.31.31,0,0,0-.19,0,.34.34,0,0,0-.16.1.39.39,0,0,0-.1.16.35.35,0,0,0-.05.2l0,.13a.5.5,0,0,0,.13.22l.1.08a.55.55,0,0,0,.25.07h.19a.41.41,0,0,0,.19-.05.34.34,0,0,0,.16-.1.57.57,0,0,0,.11-.16.43.43,0,0,0,0-.19l0-.14a.5.5,0,0,0-.13-.22l-.1-.08a.54.54,0,0,0-.25-.06Z" />
        <path d="M175.17,34h-.37a.38.38,0,0,0-.19,0,.39.39,0,0,0-.27.27.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.53.53,0,0,0,.27-.27.41.41,0,0,0,0-.19l0-.13a.45.45,0,0,0-.13-.23l-.1-.07a.45.45,0,0,0-.25-.07Z" />
        <path d="M192.47,34.58h-.18a.34.34,0,0,0-.2,0,.42.42,0,0,0-.26.26.34.34,0,0,0,0,.2v.13a.61.61,0,0,0,.13.22l.1.08a.59.59,0,0,0,.26.07h.18a.38.38,0,0,0,.19,0,.37.37,0,0,0,.17-.1.54.54,0,0,0,.1-.16.41.41,0,0,0,0-.19l0-.14a.53.53,0,0,0-.12-.22l-.11-.08a.51.51,0,0,0-.25-.06Z" />
        <path d="M181.87,17.09h-.19a.41.41,0,0,0-.19,0,.44.44,0,0,0-.16.1.39.39,0,0,0-.11.17.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.22l.1.08a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11.34.34,0,0,0,.1-.16.32.32,0,0,0,.05-.19l0-.13a.53.53,0,0,0-.13-.23l-.1-.07a.45.45,0,0,0-.25-.07Z" />
        <path d="M31.11,54.57l.56-.75a.63.63,0,0,0,.12-.37.78.78,0,0,0,0-.4,1,1,0,0,0-.18-.34.75.75,0,0,0-.28-.25l-.24-.11a1.23,1.23,0,0,0-.54,0l-.23.11a.93.93,0,0,0-.36.35l-.56.75a.71.71,0,0,0-.12.37.78.78,0,0,0,0,.4.75.75,0,0,0,.18.34.78.78,0,0,0,.28.26L30,55a1.19,1.19,0,0,0,.53,0l.24-.1a.93.93,0,0,0,.35-.36Z" />
        <path d="M60.94,16.59h-.19a.71.71,0,0,0-.38.08.78.78,0,0,0-.33.21.83.83,0,0,0-.2.32.86.86,0,0,0-.09.39l0,.26a1,1,0,0,0,.25.45l.21.15a1,1,0,0,0,.5.14h.19a.82.82,0,0,0,.38-.09.76.76,0,0,0,.32-.2.78.78,0,0,0,.21-.33.82.82,0,0,0,.09-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.16a1,1,0,0,0-.5-.13Z" />
        <path d="M134.65,24l-.19.19a.76.76,0,0,0-.2.32.65.65,0,0,0-.09.38.66.66,0,0,0,.09.39.83.83,0,0,0,.2.32l.21.16a1,1,0,0,0,.5.13l.27,0a1,1,0,0,0,.44-.26l.18-.18a.78.78,0,0,0,.21-.33.82.82,0,0,0,.09-.38.86.86,0,0,0-.09-.39.75.75,0,0,0-.21-.32l-.2-.16a1,1,0,0,0-.5-.13l-.27,0a1,1,0,0,0-.44.26Z" />
        <path d="M176.25,57.44l-.19-.19a.76.76,0,0,0-.32-.2.73.73,0,0,0-.38-.09.75.75,0,0,0-.39.09.83.83,0,0,0-.32.2l-.16.21a1,1,0,0,0-.13.5l0,.27a1.1,1.1,0,0,0,.26.44l.19.18a.69.69,0,0,0,.32.21.82.82,0,0,0,.38.09.86.86,0,0,0,.39-.09.75.75,0,0,0,.32-.21l.16-.2a1,1,0,0,0,.13-.5l0-.27a1,1,0,0,0-.26-.44Z" />
        <path d="M9,8.59H8.84a.73.73,0,0,0-.38.08.94.94,0,0,0-.53.53.86.86,0,0,0-.09.39l0,.26a1,1,0,0,0,.26.45l.2.15a1,1,0,0,0,.5.14H9a.86.86,0,0,0,.39-.09.83.83,0,0,0,.32-.2.75.75,0,0,0,.2-.33A.63.63,0,0,0,10,9.59l0-.27a1.1,1.1,0,0,0-.26-.44l-.2-.16A1,1,0,0,0,9,8.59Z" />
        <path d="M35.26,89.7h-.18a.86.86,0,0,0-.39.09.94.94,0,0,0-.53.53.73.73,0,0,0-.08.38l0,.27a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.13h.18a1,1,0,0,0,1-1l0-.26A1,1,0,0,0,36,90l-.2-.16a1.15,1.15,0,0,0-.51-.14Z" />
        <path d="M188.88,28.06l-.18-.18a1.11,1.11,0,0,0-.49-.31,1,1,0,0,0-.57-.13,1.06,1.06,0,0,0-.58.13,1.11,1.11,0,0,0-.49.31l-.23.3a1.44,1.44,0,0,0-.2.76l.05.4a1.57,1.57,0,0,0,.38.66l.19.18a1.14,1.14,0,0,0,.48.31,1.36,1.36,0,0,0,1.16,0,1,1,0,0,0,.48-.31l.24-.3a1.54,1.54,0,0,0,.2-.76l0-.4a1.49,1.49,0,0,0-.39-.66Z" />
        <path d="M64.47,66.72a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M105.4,14.62a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M129.22,47a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M122.89,88.67a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M185.4,83.09a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M179.09,89v-.37a1.15,1.15,0,0,0-.13-.58,1,1,0,0,0-.31-.48,1.14,1.14,0,0,0-.48-.31,1.06,1.06,0,0,0-.58-.13l-.4,0a1.49,1.49,0,0,0-.66.39l-.24.3a1.54,1.54,0,0,0-.2.76V89a1.06,1.06,0,0,0,.13.58,1.14,1.14,0,0,0,.31.48,1,1,0,0,0,.48.31,1.15,1.15,0,0,0,.58.13l.4,0a1.49,1.49,0,0,0,.66-.39l.23-.3a1.48,1.48,0,0,0,.21-.76Z" />
        <path d="M33.22,14.25a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M17.22,65.47a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M24.29,47.61a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M147.45,69.43h-.75a.73.73,0,0,0-.38.08.94.94,0,0,0-.53.53.86.86,0,0,0-.09.39l0,.26a.94.94,0,0,0,.26.44l.2.16a1.11,1.11,0,0,0,.5.14h.75a.79.79,0,0,0,.38-.09.72.72,0,0,0,.33-.21.83.83,0,0,0,.2-.32.73.73,0,0,0,.09-.38l0-.27a.92.92,0,0,0-.25-.44l-.21-.16a1,1,0,0,0-.5-.13Z" />
        <path d="M142.24,10.26h-.37a.86.86,0,0,0-.39.09.75.75,0,0,0-.32.21.69.69,0,0,0-.21.32.73.73,0,0,0-.08.38l0,.27a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.13h.37A1,1,0,0,0,143,12a.83.83,0,0,0,.2-.32.66.66,0,0,0,.09-.39l0-.26a.92.92,0,0,0-.25-.44l-.21-.16a1,1,0,0,0-.5-.14Z" />
        <path d="M84.19,61.38a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M83.82,27h-.74a1,1,0,0,0-1,1l0,.26a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.14h.74a.86.86,0,0,0,.39-.09.75.75,0,0,0,.32-.21.78.78,0,0,0,.21-.32.73.73,0,0,0,.08-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.16a1,1,0,0,0-.51-.13Z" />
        <path d="M31.73,27.89a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M56.66,79.24a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M187.64,70.68a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M189.12,13a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M149,37.64l.38.38a.76.76,0,0,0,.32.2.73.73,0,0,0,.38.09.75.75,0,0,0,.39-.09.83.83,0,0,0,.32-.2l.16-.21a1,1,0,0,0,.13-.5l0-.27a1.1,1.1,0,0,0-.26-.44l-.37-.37a1,1,0,0,0-.71-.29.71.71,0,0,0-.38.08.78.78,0,0,0-.33.21l-.15.2a1,1,0,0,0-.14.51l0,.26a.92.92,0,0,0,.25.44Z" />
        <path d="M164.57,50.45h-.75a.71.71,0,0,0-.38.08.81.81,0,0,0-.33.21.83.83,0,0,0-.2.32.75.75,0,0,0-.09.39l0,.26a1,1,0,0,0,.25.45l.21.15a1,1,0,0,0,.5.14h.75a.73.73,0,0,0,.38-.09.83.83,0,0,0,.32-.2.72.72,0,0,0,.21-.33.82.82,0,0,0,.09-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.15a.9.9,0,0,0-.5-.14Z" />
        <path d="M46.39,62l.38-.75a.77.77,0,0,0,.12-.37.78.78,0,0,0,0-.4.75.75,0,0,0-.18-.34.68.68,0,0,0-.28-.25l-.24-.1a1,1,0,0,0-.53,0l-.24.1a.93.93,0,0,0-.36.35l-.37.75a.71.71,0,0,0-.12.37.78.78,0,0,0,0,.4.75.75,0,0,0,.18.34.78.78,0,0,0,.28.26l.24.1a1.19,1.19,0,0,0,.53,0l.24-.1a.93.93,0,0,0,.35-.36Z" />
        <path d="M71.91,45.38a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M82.19,77.73l-1.12.74a.78.78,0,0,0-.28.26.75.75,0,0,0-.18.34.78.78,0,0,0,0,.4.81.81,0,0,0,.12.37,1,1,0,0,0,.6.46l.27,0a1,1,0,0,0,.5-.14l1.12-.74a1.15,1.15,0,0,0,.46-.6.77.77,0,0,0,0-.4.85.85,0,0,0-.12-.37,1,1,0,0,0-.6-.46l-.26,0a1,1,0,0,0-.51.13Z" />
        <path d="M105.66,39.78l-.38.75a.77.77,0,0,0-.12.37.91.91,0,0,0,0,.4.75.75,0,0,0,.18.34.84.84,0,0,0,.28.25l.24.1a1,1,0,0,0,.53,0l.24-.1a1,1,0,0,0,.36-.36l.37-.74a.63.63,0,0,0,.12-.37.78.78,0,0,0,0-.4.75.75,0,0,0-.18-.34.78.78,0,0,0-.28-.26l-.24-.1a1,1,0,0,0-.53,0l-.24.1a.93.93,0,0,0-.35.36Z" />
        <path d="M119.55,68.57l1.12-1.11a.72.72,0,0,0,.2-.32.66.66,0,0,0,.09-.39.63.63,0,0,0-.09-.38.7.7,0,0,0-.2-.33,1,1,0,0,0-.71-.29,1.07,1.07,0,0,0-.71.29l-1.11,1.12a.75.75,0,0,0-.21.32.86.86,0,0,0-.09.39.82.82,0,0,0,.09.38.69.69,0,0,0,.21.32,1,1,0,0,0,1.41,0Z" />
      </g>
      <g id="g-4" transform="matrix(0, 1, -1, 0, 270.499634, -3.08805)">
        <polygon
          stroke="black"
          fill="none"
          points="51.74 36.04 49.3 44.46 57.17 39.03 51.74 36.04"
        />
        <polygon
          stroke="black"
          fill="none"
          points="98.43 72.96 103.31 62.64 106.03 71.06 98.43 72.96"
        />
        <polygon
          stroke="black"
          fill="none"
          points="165.74 24.91 162.49 31.16 167.64 35.77 165.74 24.91"
        />
        <polygon
          stroke="black"
          fill="none"
          points="13.47 84.9 14.01 90.06 22.16 83.54 13.47 84.9"
        />
        <polygon
          stroke="black"
          fill="none"
          points="17.54 20.03 14.83 25.46 18.09 25.73 17.54 20.03"
        />
        <polygon
          stroke="black"
          fill="none"
          points="157.87 60.2 155.97 64.81 161.4 66.98 157.87 60.2"
        />
        <polygon
          stroke="black"
          fill="none"
          points="119.06 17.58 114.17 20.57 119.87 27.63 119.06 17.58"
        />
        <polygon
          stroke="black"
          fill="none"
          points="69.93 9.17 69.66 16.5 76.98 12.43 69.93 9.17"
        />
        <polygon
          stroke="black"
          fill="none"
          points="143.49 95.21 147.01 88.16 147.29 94.94 143.49 95.21"
        />
        <line
          stroke="black"
          fill="none"
          x1="80.43"
          y1="47.59"
          x2="81.26"
          y2="46.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="75.96"
          y1="68.1"
          x2="76.1"
          y2="69.22"
        />
        <line
          stroke="black"
          fill="none"
          x1="58.52"
          y1="53.45"
          x2="60.05"
          y2="52.33"
        />
        <line
          stroke="black"
          fill="none"
          x1="44.98"
          y1="28.19"
          x2="46.1"
          y2="27.22"
        />
        <line
          stroke="black"
          fill="none"
          x1="71.77"
          y1="29.87"
          x2="72.75"
          y2="28.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="91.87"
          y1="38.52"
          x2="92.43"
          y2="39.63"
        />
        <line
          stroke="black"
          fill="none"
          x1="112.66"
          y1="51.22"
          x2="114.05"
          y2="50.52"
        />
        <line
          stroke="black"
          fill="none"
          x1="127.73"
          y1="63.5"
          x2="128.98"
          y2="62.66"
        />
        <line
          stroke="black"
          fill="none"
          x1="131.08"
          y1="80.94"
          x2="132.19"
          y2="81.63"
        />
        <line
          stroke="black"
          fill="none"
          x1="106.8"
          y1="85.4"
          x2="105.68"
          y2="85.82"
        />
        <line
          stroke="black"
          fill="none"
          x1="86.71"
          y1="88.47"
          x2="85.59"
          y2="89.03"
        />
        <line
          stroke="black"
          fill="none"
          x1="69.26"
          y1="89.45"
          x2="68.15"
          y2="88.61"
        />
        <line
          stroke="black"
          fill="none"
          x1="54.33"
          y1="88.89"
          x2="52.94"
          y2="89.73"
        />
        <line
          stroke="black"
          fill="none"
          x1="23.78"
          y1="70.33"
          x2="24.19"
          y2="71.31"
        />
        <line
          stroke="black"
          fill="none"
          x1="93.05"
          y1="55.26"
          x2="93.47"
          y2="56.24"
        />
        <path d="M46.55,79.86a3.9,3.9,0,0,0-.64.58,3.55,3.55,0,0,0-.5.7.5.5,0,0,0,0,.38.5.5,0,0,0,.23.3.51.51,0,0,0,.68-.18,4,4,0,0,1,.29-.43l-.08.1a3.64,3.64,0,0,1,.62-.62l-.1.08.06,0a.68.68,0,0,0,.23-.3.59.59,0,0,0,0-.39.52.52,0,0,0-.3-.23.48.48,0,0,0-.39,0Z" />
        <path d="M36.92,77.33l-.19-.19a.34.34,0,0,0-.16-.1.47.47,0,0,0-.38,0,.34.34,0,0,0-.16.1l-.08.1a.46.46,0,0,0-.07.26l0,.13a.42.42,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.43.43,0,0,0,.19,0,.42.42,0,0,0,.16-.11l.08-.1a.52.52,0,0,0,.07-.25l0-.13a.54.54,0,0,0-.13-.22Z" />
        <path d="M41.16,68.19V68a.45.45,0,0,0,0-.2.42.42,0,0,0-.11-.16.34.34,0,0,0-.16-.1.31.31,0,0,0-.19,0h-.13a.54.54,0,0,0-.22.13l-.08.1a.48.48,0,0,0-.07.26v.18a.45.45,0,0,0,0,.2.42.42,0,0,0,.11.16.29.29,0,0,0,.16.1.31.31,0,0,0,.19,0h.13a.54.54,0,0,0,.22-.13l.08-.1a.48.48,0,0,0,.07-.26Z" />
        <path d="M30.24,40.9h-.19a.34.34,0,0,0-.19.05.39.39,0,0,0-.16.1.34.34,0,0,0-.1.16.41.41,0,0,0-.05.19l0,.14a.71.71,0,0,0,.13.22l.1.07a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.44.44,0,0,0,.16-.1.39.39,0,0,0,.11-.17.38.38,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M123.08,73.27h-.19a.41.41,0,0,0-.19,0,.34.34,0,0,0-.16.1.42.42,0,0,0-.11.16.43.43,0,0,0,0,.19l0,.14a.5.5,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.19a.31.31,0,0,0,.19,0,.34.34,0,0,0,.16-.1.39.39,0,0,0,.1-.16.35.35,0,0,0,0-.2l0-.13a.5.5,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M89.9,82.05,89,81.68a.5.5,0,0,0-.56.73l.08.1a.45.45,0,0,0,.22.13l.93.38A.56.56,0,0,0,90,83a.51.51,0,0,0,.18-.69l-.08-.1a.54.54,0,0,0-.22-.13Z" />
        <path d="M70.05,79.6h-.18a.38.38,0,0,0-.19,0,.39.39,0,0,0-.17.11.44.44,0,0,0-.1.16.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.18a.45.45,0,0,0,.2,0,.42.42,0,0,0,.16-.11.34.34,0,0,0,.1-.16.31.31,0,0,0,0-.19V80a.54.54,0,0,0-.13-.22l-.1-.08a.48.48,0,0,0-.26-.07Z" />
        <path d="M59.64,25.83h-.19a.32.32,0,0,0-.19.05.29.29,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19l0,.14a.46.46,0,0,0,.13.22l.1.07a.43.43,0,0,0,.25.07h.19a.33.33,0,0,0,.19,0,.54.54,0,0,0,.16-.1.39.39,0,0,0,.1-.16.46.46,0,0,0,0-.2l0-.13A.54.54,0,0,0,60,26l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M90.33,14.86h-.18a.34.34,0,0,0-.2,0,.42.42,0,0,0-.26.26.34.34,0,0,0,0,.2v.13a.61.61,0,0,0,.13.22l.1.08a.59.59,0,0,0,.26.07h.18a.46.46,0,0,0,.2,0,.42.42,0,0,0,.26-.26.33.33,0,0,0,0-.19v-.14a.61.61,0,0,0-.13-.22l-.1-.08a.58.58,0,0,0-.26-.06Z" />
        <path d="M100.38,28.62h-.56a.32.32,0,0,0-.19.05.34.34,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19.53.53,0,0,0,.15.36.52.52,0,0,0,.35.14h.56a.31.31,0,0,0,.19,0,.34.34,0,0,0,.16-.1.42.42,0,0,0,.11-.16.45.45,0,0,0,0-.2.51.51,0,0,0-.5-.5Z" />
        <path d="M104.64,79.34l.19-.19a.44.44,0,0,0,.1-.16.31.31,0,0,0,0-.19.34.34,0,0,0,0-.2.54.54,0,0,0-.1-.16l-.11-.07a.43.43,0,0,0-.25-.07l-.13,0a.43.43,0,0,0-.22.12l-.19.19a.39.39,0,0,0-.1.16.31.31,0,0,0,0,.19.34.34,0,0,0,0,.2.39.39,0,0,0,.1.16l.1.08a.58.58,0,0,0,.26.06h.13a.61.61,0,0,0,.22-.13Z" />
        <path d="M122.65,35.17,121.9,35a.56.56,0,0,0-.38.05.51.51,0,0,0-.18.69.5.5,0,0,0,.3.23l.74.19a.59.59,0,0,0,.39,0,.57.57,0,0,0,.23-.3.53.53,0,0,0,0-.39.5.5,0,0,0-.3-.23Z" />
        <path d="M117.5,31.41h-.19a.41.41,0,0,0-.19.05.34.34,0,0,0-.16.1.42.42,0,0,0-.11.16.43.43,0,0,0,0,.19l0,.14a.42.42,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.19a.33.33,0,0,0,.19,0,.42.42,0,0,0,.26-.26.46.46,0,0,0,0-.2l0-.13a.61.61,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M160.84,81.65h-.37a.31.31,0,0,0-.19,0,.34.34,0,0,0-.16.1.39.39,0,0,0-.1.16.35.35,0,0,0-.05.2l0,.13a.5.5,0,0,0,.13.22l.1.08a.55.55,0,0,0,.25.07h.37a.46.46,0,0,0,.2-.05.42.42,0,0,0,.26-.26.31.31,0,0,0,0-.19V82a.71.71,0,0,0-.13-.22l-.1-.08a.58.58,0,0,0-.26-.06Z" />
        <path d="M157.85,91.1l-.19-.19a.34.34,0,0,0-.16-.1.39.39,0,0,0-.38,0,.29.29,0,0,0-.16.1l-.08.1a.45.45,0,0,0-.07.25l0,.14a.46.46,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.43.43,0,0,0,.19,0,.42.42,0,0,0,.16-.11l.08-.1a.55.55,0,0,0,.07-.25l0-.13a.54.54,0,0,0-.13-.22Z" />
        <path d="M174.7,77.41l-.56-.38A.3.3,0,0,0,174,77a.44.44,0,0,0-.2,0,.28.28,0,0,0-.17.09.29.29,0,0,0-.13.14.51.51,0,0,0-.05.38l.05.12a.39.39,0,0,0,.18.18l.55.38a.33.33,0,0,0,.19.06.35.35,0,0,0,.2,0,.32.32,0,0,0,.17-.09.44.44,0,0,0,.13-.14.5.5,0,0,0,.05-.38l-.05-.12a.52.52,0,0,0-.18-.18Z" />
        <path d="M179.4,54.92l-.37.56a.33.33,0,0,0-.06.19.35.35,0,0,0,0,.2.32.32,0,0,0,.09.17.44.44,0,0,0,.14.13.5.5,0,0,0,.38,0l.12,0a.52.52,0,0,0,.18-.18l.37-.56a.33.33,0,0,0,.06-.19.45.45,0,0,0,0-.2.4.4,0,0,0-.09-.17.35.35,0,0,0-.14-.13.51.51,0,0,0-.38-.05l-.12.05a.52.52,0,0,0-.18.18Z" />
        <path d="M155.47,42.72l-.19.19a.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19.34.34,0,0,0,0,.2.39.39,0,0,0,.1.16l.1.07a.46.46,0,0,0,.26.07l.13,0a.48.48,0,0,0,.22-.12l.18-.19a.42.42,0,0,0,.11-.16.41.41,0,0,0,0-.19.45.45,0,0,0,0-.2.42.42,0,0,0-.11-.16l-.1-.08a.51.51,0,0,0-.25-.06h-.13a.54.54,0,0,0-.22.13Z" />
        <path d="M151.56,21l-.19.18a.54.54,0,0,0-.1.16.45.45,0,0,0,0,.2.43.43,0,0,0,0,.19.54.54,0,0,0,.1.16l.11.08a.52.52,0,0,0,.25.07l.13,0a.54.54,0,0,0,.22-.13l.19-.19a.34.34,0,0,0,.1-.16.47.47,0,0,0,0-.38.29.29,0,0,0-.1-.16l-.1-.08a.48.48,0,0,0-.26-.07l-.13,0a.42.42,0,0,0-.22.13Z" />
        <path d="M161.77,16.53h-.55a.45.45,0,0,0-.2,0,.42.42,0,0,0-.16.11.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19.46.46,0,0,0,.14.35.53.53,0,0,0,.36.15h.55a.45.45,0,0,0,.2,0,.42.42,0,0,0,.16-.11.34.34,0,0,0,.1-.16.31.31,0,0,0,0-.19.46.46,0,0,0-.14-.35.53.53,0,0,0-.36-.15Z" />
        <path d="M155.82,16h-.37a.32.32,0,0,0-.19,0,.29.29,0,0,0-.16.1.36.36,0,0,0-.11.16.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.23l.1.07a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.37.37,0,0,0,.16-.1.39.39,0,0,0,.11-.17.38.38,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.52.52,0,0,0-.25-.07Z" />
        <path d="M184.1,7.6h-.37a.41.41,0,0,0-.19,0,.39.39,0,0,0-.17.11.44.44,0,0,0-.1.16.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.53.53,0,0,0,.27-.27.41.41,0,0,0,0-.19l0-.13a.38.38,0,0,0-.13-.22l-.1-.08a.45.45,0,0,0-.25-.07Z" />
        <path d="M188.94,48.53h-.19a.41.41,0,0,0-.19,0,.53.53,0,0,0-.27.27.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.22l.1.08a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11.34.34,0,0,0,.1-.16.32.32,0,0,0,.05-.19l0-.13a.45.45,0,0,0-.13-.22l-.1-.08a.45.45,0,0,0-.25-.07Z" />
        <path d="M187.43,93l-.19-.19a.29.29,0,0,0-.16-.1.39.39,0,0,0-.38,0,.34.34,0,0,0-.16.1l-.08.1a.45.45,0,0,0-.07.25l0,.14a.56.56,0,0,0,.13.22l.18.18a.42.42,0,0,0,.16.11.45.45,0,0,0,.2,0,.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11l.08-.1a.55.55,0,0,0,.07-.25l0-.13a.45.45,0,0,0-.13-.22Z" />
        <path d="M8.86,85.33l-.18.18a.42.42,0,0,0-.11.16.45.45,0,0,0,0,.2.41.41,0,0,0,0,.19.36.36,0,0,0,.11.16l.1.08a.55.55,0,0,0,.25.07l.13,0a.45.45,0,0,0,.22-.13L9.57,86a.29.29,0,0,0,.1-.16.39.39,0,0,0,0-.38.34.34,0,0,0-.1-.16l-.1-.08a.45.45,0,0,0-.25-.07l-.14,0a.56.56,0,0,0-.22.13Z" />
        <path d="M25.4,91.13h-.18a.46.46,0,0,0-.2.05.42.42,0,0,0-.26.26.33.33,0,0,0,0,.19v.14a.61.61,0,0,0,.13.22l.1.08a.58.58,0,0,0,.26.06h.18a.34.34,0,0,0,.2,0,.42.42,0,0,0,.26-.26.34.34,0,0,0,0-.2V91.5a.61.61,0,0,0-.13-.22l-.1-.08a.59.59,0,0,0-.26-.07Z" />
        <path d="M11.82,54.67h-.18a.45.45,0,0,0-.2,0,.42.42,0,0,0-.16.11.34.34,0,0,0-.1.16.31.31,0,0,0,0,.19v.13a.54.54,0,0,0,.13.22l.1.08a.48.48,0,0,0,.26.07h.18a.41.41,0,0,0,.19,0,.39.39,0,0,0,.17-.11.44.44,0,0,0,.1-.16.41.41,0,0,0,0-.19l0-.13a.43.43,0,0,0-.12-.22l-.11-.08a.43.43,0,0,0-.25-.07Z" />
        <path d="M15.9,40.49l-.19-.19a.54.54,0,0,0-.16-.1.41.41,0,0,0-.19,0,.45.45,0,0,0-.2,0,.54.54,0,0,0-.16.1l-.08.11a.51.51,0,0,0-.06.25v.13A.54.54,0,0,0,15,41l.19.19a.34.34,0,0,0,.16.1.31.31,0,0,0,.19,0,.34.34,0,0,0,.2,0,.39.39,0,0,0,.16-.1l.07-.1a.48.48,0,0,0,.07-.26l0-.13a.48.48,0,0,0-.12-.22Z" />
        <path d="M10.58,27.94l-.19-.37a.29.29,0,0,0-.13-.14.26.26,0,0,0-.17-.09.34.34,0,0,0-.2,0,.32.32,0,0,0-.18.06l-.1.08a.45.45,0,0,0-.13.22l0,.13a.43.43,0,0,0,.07.25l.19.38a.53.53,0,0,0,.12.14.56.56,0,0,0,.17.09.34.34,0,0,0,.2,0,.33.33,0,0,0,.19-.06l.1-.07a.56.56,0,0,0,.13-.22l0-.14a.55.55,0,0,0-.07-.25Z" />
        <path d="M46.43,8.34h-.38a.45.45,0,0,0-.19.05.42.42,0,0,0-.26.26.45.45,0,0,0-.05.19l0,.14a.61.61,0,0,0,.13.22l.1.08a.54.54,0,0,0,.25.06h.38a.33.33,0,0,0,.19,0A.42.42,0,0,0,46.88,9a.46.46,0,0,0,0-.2l0-.13a.61.61,0,0,0-.13-.22l-.1-.08a.55.55,0,0,0-.25-.07Z" />
        <path d="M39.52,20l-.18-.19a.39.39,0,0,0-.16-.1.35.35,0,0,0-.2,0,.34.34,0,0,0-.19,0,.39.39,0,0,0-.16.1l-.08.1a.55.55,0,0,0-.07.25l0,.14a.71.71,0,0,0,.13.22l.19.18a.36.36,0,0,0,.16.11.47.47,0,0,0,.38,0,.36.36,0,0,0,.16-.11l.08-.1a.45.45,0,0,0,.07-.25l0-.13a.38.38,0,0,0-.13-.22Z" />
        <path d="M41.48,42.22l.19-.37a.6.6,0,0,0,.06-.19.46.46,0,0,0,0-.2.56.56,0,0,0-.09-.17.41.41,0,0,0-.14-.12l-.12-.05a.42.42,0,0,0-.27,0l-.12.05a.52.52,0,0,0-.18.18l-.18.37a.3.3,0,0,0-.06.18.34.34,0,0,0,0,.2.28.28,0,0,0,.09.17.29.29,0,0,0,.14.13l.12.05a.39.39,0,0,0,.26,0l.12-.05a.39.39,0,0,0,.18-.18Z" />
        <path d="M137.72,33l-.19.38a.3.3,0,0,0-.06.18.34.34,0,0,0,0,.2.28.28,0,0,0,.09.17.29.29,0,0,0,.14.13l.12,0a.57.57,0,0,0,.26,0l.12,0a.39.39,0,0,0,.18-.18l.19-.37a.42.42,0,0,0,.06-.19.46.46,0,0,0,0-.2.56.56,0,0,0-.09-.17.58.58,0,0,0-.14-.13l-.12-.05a.62.62,0,0,0-.27,0l-.12.05a.58.58,0,0,0-.17.18Z" />
        <path d="M141.87,56.23h.56a.34.34,0,0,0,.19,0,.39.39,0,0,0,.16-.1.34.34,0,0,0,.1-.16.41.41,0,0,0,.05-.19.53.53,0,0,0-.15-.36.52.52,0,0,0-.35-.14h-.56a.38.38,0,0,0-.19,0,.39.39,0,0,0-.27.27.38.38,0,0,0,0,.19.5.5,0,0,0,.14.35.53.53,0,0,0,.36.15Z" />
        <path d="M175.73,22.86h-.19a.31.31,0,0,0-.19,0,.34.34,0,0,0-.16.1.39.39,0,0,0-.1.16.35.35,0,0,0-.05.2l0,.13a.5.5,0,0,0,.13.22l.1.08a.55.55,0,0,0,.25.07h.19a.41.41,0,0,0,.19-.05.34.34,0,0,0,.16-.1.57.57,0,0,0,.11-.16.43.43,0,0,0,0-.19l0-.14a.5.5,0,0,0-.13-.22l-.1-.08a.54.54,0,0,0-.25-.06Z" />
        <path d="M175.17,34h-.37a.38.38,0,0,0-.19,0,.39.39,0,0,0-.27.27.41.41,0,0,0,0,.19l0,.13a.43.43,0,0,0,.12.22l.11.08a.43.43,0,0,0,.25.07h.37a.41.41,0,0,0,.19,0,.53.53,0,0,0,.27-.27.41.41,0,0,0,0-.19l0-.13a.45.45,0,0,0-.13-.23l-.1-.07a.45.45,0,0,0-.25-.07Z" />
        <path d="M192.47,34.58h-.18a.34.34,0,0,0-.2,0,.42.42,0,0,0-.26.26.34.34,0,0,0,0,.2v.13a.61.61,0,0,0,.13.22l.1.08a.59.59,0,0,0,.26.07h.18a.38.38,0,0,0,.19,0,.37.37,0,0,0,.17-.1.54.54,0,0,0,.1-.16.41.41,0,0,0,0-.19l0-.14a.53.53,0,0,0-.12-.22l-.11-.08a.51.51,0,0,0-.25-.06Z" />
        <path d="M181.87,17.09h-.19a.41.41,0,0,0-.19,0,.44.44,0,0,0-.16.1.39.39,0,0,0-.11.17.41.41,0,0,0,0,.19l0,.13a.45.45,0,0,0,.13.22l.1.08a.45.45,0,0,0,.25.07h.19a.41.41,0,0,0,.19,0,.36.36,0,0,0,.16-.11.34.34,0,0,0,.1-.16.32.32,0,0,0,.05-.19l0-.13a.53.53,0,0,0-.13-.23l-.1-.07a.45.45,0,0,0-.25-.07Z" />
        <path d="M31.11,54.57l.56-.75a.63.63,0,0,0,.12-.37.78.78,0,0,0,0-.4,1,1,0,0,0-.18-.34.75.75,0,0,0-.28-.25l-.24-.11a1.23,1.23,0,0,0-.54,0l-.23.11a.93.93,0,0,0-.36.35l-.56.75a.71.71,0,0,0-.12.37.78.78,0,0,0,0,.4.75.75,0,0,0,.18.34.78.78,0,0,0,.28.26L30,55a1.19,1.19,0,0,0,.53,0l.24-.1a.93.93,0,0,0,.35-.36Z" />
        <path d="M60.94,16.59h-.19a.71.71,0,0,0-.38.08.78.78,0,0,0-.33.21.83.83,0,0,0-.2.32.86.86,0,0,0-.09.39l0,.26a1,1,0,0,0,.25.45l.21.15a1,1,0,0,0,.5.14h.19a.82.82,0,0,0,.38-.09.76.76,0,0,0,.32-.2.78.78,0,0,0,.21-.33.82.82,0,0,0,.09-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.16a1,1,0,0,0-.5-.13Z" />
        <path d="M134.65,24l-.19.19a.76.76,0,0,0-.2.32.65.65,0,0,0-.09.38.66.66,0,0,0,.09.39.83.83,0,0,0,.2.32l.21.16a1,1,0,0,0,.5.13l.27,0a1,1,0,0,0,.44-.26l.18-.18a.78.78,0,0,0,.21-.33.82.82,0,0,0,.09-.38.86.86,0,0,0-.09-.39.75.75,0,0,0-.21-.32l-.2-.16a1,1,0,0,0-.5-.13l-.27,0a1,1,0,0,0-.44.26Z" />
        <path d="M176.25,57.44l-.19-.19a.76.76,0,0,0-.32-.2.73.73,0,0,0-.38-.09.75.75,0,0,0-.39.09.83.83,0,0,0-.32.2l-.16.21a1,1,0,0,0-.13.5l0,.27a1.1,1.1,0,0,0,.26.44l.19.18a.69.69,0,0,0,.32.21.82.82,0,0,0,.38.09.86.86,0,0,0,.39-.09.75.75,0,0,0,.32-.21l.16-.2a1,1,0,0,0,.13-.5l0-.27a1,1,0,0,0-.26-.44Z" />
        <path d="M9,8.59H8.84a.73.73,0,0,0-.38.08.94.94,0,0,0-.53.53.86.86,0,0,0-.09.39l0,.26a1,1,0,0,0,.26.45l.2.15a1,1,0,0,0,.5.14H9a.86.86,0,0,0,.39-.09.83.83,0,0,0,.32-.2.75.75,0,0,0,.2-.33A.63.63,0,0,0,10,9.59l0-.27a1.1,1.1,0,0,0-.26-.44l-.2-.16A1,1,0,0,0,9,8.59Z" />
        <path d="M35.26,89.7h-.18a.86.86,0,0,0-.39.09.94.94,0,0,0-.53.53.73.73,0,0,0-.08.38l0,.27a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.13h.18a1,1,0,0,0,1-1l0-.26A1,1,0,0,0,36,90l-.2-.16a1.15,1.15,0,0,0-.51-.14Z" />
        <path d="M188.88,28.06l-.18-.18a1.11,1.11,0,0,0-.49-.31,1,1,0,0,0-.57-.13,1.06,1.06,0,0,0-.58.13,1.11,1.11,0,0,0-.49.31l-.23.3a1.44,1.44,0,0,0-.2.76l.05.4a1.57,1.57,0,0,0,.38.66l.19.18a1.14,1.14,0,0,0,.48.31,1.36,1.36,0,0,0,1.16,0,1,1,0,0,0,.48-.31l.24-.3a1.54,1.54,0,0,0,.2-.76l0-.4a1.49,1.49,0,0,0-.39-.66Z" />
        <path d="M64.47,66.72a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M105.4,14.62a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M129.22,47a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M122.89,88.67a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M185.4,83.09a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M179.09,89v-.37a1.15,1.15,0,0,0-.13-.58,1,1,0,0,0-.31-.48,1.14,1.14,0,0,0-.48-.31,1.06,1.06,0,0,0-.58-.13l-.4,0a1.49,1.49,0,0,0-.66.39l-.24.3a1.54,1.54,0,0,0-.2.76V89a1.06,1.06,0,0,0,.13.58,1.14,1.14,0,0,0,.31.48,1,1,0,0,0,.48.31,1.15,1.15,0,0,0,.58.13l.4,0a1.49,1.49,0,0,0,.66-.39l.23-.3a1.48,1.48,0,0,0,.21-.76Z" />
        <path d="M33.22,14.25a1.5,1.5,0,0,0,0-3,1.5,1.5,0,0,0,0,3Z" />
        <path d="M17.22,65.47a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M24.29,47.61a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M147.45,69.43h-.75a.73.73,0,0,0-.38.08.94.94,0,0,0-.53.53.86.86,0,0,0-.09.39l0,.26a.94.94,0,0,0,.26.44l.2.16a1.11,1.11,0,0,0,.5.14h.75a.79.79,0,0,0,.38-.09.72.72,0,0,0,.33-.21.83.83,0,0,0,.2-.32.73.73,0,0,0,.09-.38l0-.27a.92.92,0,0,0-.25-.44l-.21-.16a1,1,0,0,0-.5-.13Z" />
        <path d="M142.24,10.26h-.37a.86.86,0,0,0-.39.09.75.75,0,0,0-.32.21.69.69,0,0,0-.21.32.73.73,0,0,0-.08.38l0,.27a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.13h.37A1,1,0,0,0,143,12a.83.83,0,0,0,.2-.32.66.66,0,0,0,.09-.39l0-.26a.92.92,0,0,0-.25-.44l-.21-.16a1,1,0,0,0-.5-.14Z" />
        <path d="M84.19,61.38a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M83.82,27h-.74a1,1,0,0,0-1,1l0,.26a1,1,0,0,0,.26.44l.2.16a1,1,0,0,0,.51.14h.74a.86.86,0,0,0,.39-.09.75.75,0,0,0,.32-.21.78.78,0,0,0,.21-.32.73.73,0,0,0,.08-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.16a1,1,0,0,0-.51-.13Z" />
        <path d="M31.73,27.89a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M56.66,79.24a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M187.64,70.68a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M189.12,13a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M149,37.64l.38.38a.76.76,0,0,0,.32.2.73.73,0,0,0,.38.09.75.75,0,0,0,.39-.09.83.83,0,0,0,.32-.2l.16-.21a1,1,0,0,0,.13-.5l0-.27a1.1,1.1,0,0,0-.26-.44l-.37-.37a1,1,0,0,0-.71-.29.71.71,0,0,0-.38.08.78.78,0,0,0-.33.21l-.15.2a1,1,0,0,0-.14.51l0,.26a.92.92,0,0,0,.25.44Z" />
        <path d="M164.57,50.45h-.75a.71.71,0,0,0-.38.08.81.81,0,0,0-.33.21.83.83,0,0,0-.2.32.75.75,0,0,0-.09.39l0,.26a1,1,0,0,0,.25.45l.21.15a1,1,0,0,0,.5.14h.75a.73.73,0,0,0,.38-.09.83.83,0,0,0,.32-.2.72.72,0,0,0,.21-.33.82.82,0,0,0,.09-.38l0-.27a1,1,0,0,0-.26-.44l-.2-.15a.9.9,0,0,0-.5-.14Z" />
        <path d="M46.39,62l.38-.75a.77.77,0,0,0,.12-.37.78.78,0,0,0,0-.4.75.75,0,0,0-.18-.34.68.68,0,0,0-.28-.25l-.24-.1a1,1,0,0,0-.53,0l-.24.1a.93.93,0,0,0-.36.35l-.37.75a.71.71,0,0,0-.12.37.78.78,0,0,0,0,.4.75.75,0,0,0,.18.34.78.78,0,0,0,.28.26l.24.1a1.19,1.19,0,0,0,.53,0l.24-.1a.93.93,0,0,0,.35-.36Z" />
        <path d="M71.91,45.38a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z" />
        <path d="M82.19,77.73l-1.12.74a.78.78,0,0,0-.28.26.75.75,0,0,0-.18.34.78.78,0,0,0,0,.4.81.81,0,0,0,.12.37,1,1,0,0,0,.6.46l.27,0a1,1,0,0,0,.5-.14l1.12-.74a1.15,1.15,0,0,0,.46-.6.77.77,0,0,0,0-.4.85.85,0,0,0-.12-.37,1,1,0,0,0-.6-.46l-.26,0a1,1,0,0,0-.51.13Z" />
        <path d="M105.66,39.78l-.38.75a.77.77,0,0,0-.12.37.91.91,0,0,0,0,.4.75.75,0,0,0,.18.34.84.84,0,0,0,.28.25l.24.1a1,1,0,0,0,.53,0l.24-.1a1,1,0,0,0,.36-.36l.37-.74a.63.63,0,0,0,.12-.37.78.78,0,0,0,0-.4.75.75,0,0,0-.18-.34.78.78,0,0,0-.28-.26l-.24-.1a1,1,0,0,0-.53,0l-.24.1a.93.93,0,0,0-.35.36Z" />
        <path d="M119.55,68.57l1.12-1.11a.72.72,0,0,0,.2-.32.66.66,0,0,0,.09-.39.63.63,0,0,0-.09-.38.7.7,0,0,0-.2-.33,1,1,0,0,0-.71-.29,1.07,1.07,0,0,0-.71.29l-1.11,1.12a.75.75,0,0,0-.21.32.86.86,0,0,0-.09.39.82.82,0,0,0,.09.38.69.69,0,0,0,.21.32,1,1,0,0,0,1.41,0Z" />
      </g>
    </svg>
  );
}
