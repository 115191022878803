import { parseString } from "./env-config";

export interface Config {
  api: {
    baseUrl: string;
    graphQlWebsocketUrl: string;
  };
  simplifiedTenant: {
    tutorialUrl: string;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
}

const loadConfig = (): Config => {
  const en = parseString(process.env.REACT_APP_FIREBASE)!;
  return {
    api: {
      baseUrl: parseString(process.env.REACT_APP_API_BASE)!,
      graphQlWebsocketUrl: parseString(
        process.env.REACT_APP_GRAPHQL_WEBSOCKET_API_URL
      )!,
    },
    simplifiedTenant: {
      tutorialUrl: parseString(
        process.env.REACT_APP_SIMPLIFIED_TENANT_TUTORIAL_URL
      )!,
    },
    firebase: JSON.parse(en),
  };
};

export default loadConfig();
