import { Box } from "@material-ui/core";
import { ReportContainer } from "../components/report-container";
import { ReportTypography } from "../components/report-typography";
import { useProjectQuery } from "../../../lib/generated/graphql";
import { useTheme, Theme } from "@material-ui/core/styles";
import { ReportPageProps } from "../../report-page";
import { DefaultTheme } from "../../../providers/styling";

export default function ReportGiveThanksPage(props: ReportPageProps) {
  const theme: Theme = useTheme<DefaultTheme>();
  const { projectId } = props;

  const project = useProjectQuery({ variables: { projectId } })?.data
    ?.project || {
    reportThanksTo: "",
  };

  return (
    <ReportContainer background={theme.palette.primary.main}>
      <Box
        height={940}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box mb={4} textAlign="center" color="white">
          <Box mb={2}>
            {project.reportThanksTo && (
              <ReportTypography variant="h4" align="center">
                {"Tack till..."}
              </ReportTypography>
            )}
          </Box>
          <ReportTypography
            variant="h5"
            align="center"
            style={{ color: "white" }}
          >
            {project.reportThanksTo}
          </ReportTypography>
        </Box>
      </Box>
      <Box mb={10} display="flex" justifyContent="center">
        <img
          src={
            "https://storage.googleapis.com/public-plant/app-assets/report/Plant-logo-utan-slogan-vit.png"
          }
          width={200}
        />
      </Box>
      {/*<ReportFooter whitePlantLogo />*/}
    </ReportContainer>
  );
}
