import "whatwg-fetch";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import LoginComponent from "../lib/components/authed/login-component";

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      margin: theme.spacing(1),
    },
    background: {
      background: "#ffffff",
      display: "block",
      width: "100%",
      height: "100%",
      position: "absolute",
    },
  };
});

export default function LoginPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.background}>
      <LoginComponent onLoggedIn={() => navigate("/projects")} />
    </div>
  );
}
