import { useMemo } from "react";

import { Box, Flex } from "@chakra-ui/react";

import {
  BuildingElementFieldsFragment,
  BuildingElementLayerDisplay,
  Hatching,
  ProductFieldsFragment,
  Unit,
} from "../../generated/graphql";

import LayerGypsum from "../../../assets/material-layers/gypsum";
import LayerInsulation from "../../../assets/material-layers/insulation";
import LayerWood from "../../../assets/material-layers/wood";

import LayerSteelProfile from "../../../assets/material-layers/steel-profile";
import LayerWoodProfile from "../../../assets/material-layers/wood-profile";
import LayerBrick from "../../../assets/material-layers/brick";
import LayerPlywood from "../../../assets/material-layers/plywood";
import LayerConcrete from "../../../assets/material-layers/concrete";
import LayerGrout from "../../../assets/material-layers/grout";
import LayerTimber from "../../../assets/material-layers/timber";

export interface MaterialLayerProps {
  thickness: number;
  totalThickness: number;
  hatching?: Hatching;
  unit?: Unit;
}

const iter = (num: number): number[] => Array.from(Array(num).keys());

export const MaterialLayer = ({
  thickness,
  totalThickness,
  hatching,
  unit,
}: {
  thickness: number;
  totalThickness: number;
  hatching?: Hatching | null;
  unit?: Unit | null;
}) => {
  const pxWidth = (thickness / totalThickness) * 100;
  const w = `${pxWidth}%`;

  switch (hatching) {
    case Hatching.Brick:
      return (
        <Box bg="white" width={w}>
          {iter(48).map((i) => (
            <Flex key={i} marginBottom={0.5} overflow="hidden">
              <LayerBrick />
            </Flex>
          ))}
        </Box>
      );
    case Hatching.Concrete:
      return (
        <Box bg="white" overflow="hidden" width={w}>
          {iter(3).map((i) => (
            <LayerConcrete key={i} />
          ))}
        </Box>
      );

    case Hatching.Grout:
      return (
        <Box bg="white" width={w}>
          {iter(4).map((i) => (
            <LayerGrout key={i} />
          ))}
        </Box>
      );
    case Hatching.Gypsum:
      return (
        <Flex bg="white" height="100%" width={w}>
          {iter(12).map((i) => (
            <Box
              height="100%"
              key={i}
              style={{ borderLeft: "1px dotted black" }}
              width={5}
            />
          ))}
        </Flex>
      );
    case Hatching.Insulation:
      return (
        <Box bg="white" width={w}>
          {iter(16).map((i) => (
            <Box height="auto" key={i}>
              <LayerInsulation />
            </Box>
          ))}
        </Box>
      );

    case Hatching.Plywood:
      return (
        <Box bg="white" width={w}>
          <LayerPlywood />
        </Box>
      );

    case Hatching.SteelProfile:
      return (
        <Flex
          alignItems="center"
          bg="white"
          flexDir="column"
          justifyContent="center"
          width={w}
        >
          {iter(16).map((i) => (
            <Box key={i}>
              <LayerSteelProfile thickness={pxWidth} />
            </Box>
          ))}
        </Flex>
      );
    case Hatching.Timber:
      return (
        <Box bg="white" width={w}>
          <LayerTimber />
        </Box>
      );

    case Hatching.Wood:
      switch (unit) {
        case Unit.M:
          return (
            <Box bg="white" width="100%">
              <LayerWoodProfile thickness={thickness / totalThickness} />
              <LayerWoodProfile thickness={thickness / totalThickness} />
            </Box>
          );
        default:
          return (
            <Box bg="white" width={w}>
              <LayerWood />
            </Box>
          );
      }
    default:
      return <Box bg="white" width={w} />;
  }
};

export interface BE extends BuildingElementFieldsFragment {
  products: ProductFieldsFragment[];
}

const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

const getClampedSize = (totalThickness: number, containerSize: number) => {
  const maxThickness = 500; // största thickness på nåt be = 452
  const minThickness = maxThickness / 20; // använd minst 10 % av max

  const normalized =
    (totalThickness - minThickness) / (maxThickness - minThickness);
  const clampedW = clamp(
    normalized * containerSize,
    minThickness,
    maxThickness
  );
  return clampedW;
};

export const BEPreview = ({ buildingElement }: { buildingElement: BE }) => {
  const displayVertically =
    buildingElement.layerDisplay === BuildingElementLayerDisplay.Vertical;

  const totalThickness = useMemo(() => {
    return [...(buildingElement?.products || [])].reduce((acc, cur) => {
      return acc + (Number(cur?.thickness) || 0);
    }, 0);
  }, [buildingElement?.products]);

  const sortedProducts = useMemo(
    () =>
      [...(buildingElement?.products || [])]
        .filter(
          (product) => product?.thickness && Number(product.thickness) > 0
        )
        .sort((a: any, b: any) => a.index - b.index),
    [buildingElement?.products]
  );

  const containerSize = 200;
  const clampedW = getClampedSize(totalThickness, containerSize);

  if (totalThickness === 0) {
    return <Box w={200} />;
  }
  return (
    <>
      <Flex alignItems="start" boxSize={200} justifyContent="center">
        <Flex
          bg="black"
          border="1px solid black"
          gap="1px"
          h={containerSize}
          overflow="hidden"
          transform={displayVertically ? "" : "rotate(90deg)"}
          w={clampedW}
        >
          {totalThickness > 0 &&
            sortedProducts.map(
              (product) =>
                product.thickness && (
                  <MaterialLayer
                    hatching={product?.rawMaterial?.hatching}
                    key={product.id}
                    thickness={product.thickness}
                    totalThickness={totalThickness}
                    unit={product.unit}
                  />
                )
            )}
        </Flex>
      </Flex>
    </>
  );
};
