import ReportPage, { SingleReportPageProps } from "./pages";
// import FilesTable from "../filesTable";
import IntroductionPage from "./pages/introduction-page";
import GwpSummaryPage from "./pages/gwp-summary-page";
import { CircularProgress } from "@material-ui/core";
import { useSimplifiedTenantAdaptionProjectQuery } from "../../../lib/generated/graphql";
import ScenarioPage from "./pages/scenario-page";
import FilesTable from "../filesTable";

const UserReportComponent: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const { data: projectData, loading: loadingProject } =
    useSimplifiedTenantAdaptionProjectQuery({
      variables: { id: projectId },
    });
  if (loadingProject) {
    return <CircularProgress />;
  }
  const project = projectData?.simplifiedTenantAdaption?.project!;

  return (
    <>
      <FilesTable projectId={projectId} />
      <ReportPage
        isPrint={false}
        pages={[
          IntroductionPage,
          GwpSummaryPage,
          ...project.scenarios.map((s) => (p: SingleReportPageProps) => (
            <ScenarioPage {...p} scenarioId={s.id} />
          )),
        ]}
      />
    </>
  );
};

export default UserReportComponent;
