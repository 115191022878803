import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportBody } from "../components/body";
import { ReportTypography } from "../components/report-typography";
import {
  useProjectQuery,
  LcaSystemBoundary,
} from "../../../lib/generated/graphql";
import LCASystemBoundaryForm from "../../../lib/components/lca-system-boundary-form";
import { ReportContainerHorizontal } from "../components/report-container-horizontal";
import { ReportPageProps } from "../../report-page";

export default function ReportLcaModulesPage(props: ReportPageProps) {
  const { projectId } = props;

  const project = useProjectQuery({ variables: { projectId: projectId! } })
    ?.data?.project || {
    LCASystemBoundary: {},
  };
  return (
    <ReportLcaModulesPageCommon
      {...props}
      lcaSystemBoundary={project.LCASystemBoundary ?? {}}
    />
  );
}

export function ReportLcaModulesPageCommon(
  props: { pageNumber?: number } & {
    lcaSystemBoundary?: Omit<LcaSystemBoundary, "id">;
  }
) {
  return (
    <ReportContainerHorizontal>
      <ReportBody>
        <Box mb={4} textAlign="left">
          <ReportTypography variant="h3">
            Redovisade LCA-moduler
          </ReportTypography>
        </Box>
      </ReportBody>
      <Box>
        <Box width={"100%"}>
          <LCASystemBoundaryForm
            LCASystemBoundary={props.lcaSystemBoundary ?? {}}
          />
        </Box>
        <Box ml={10} mt={3}>
          <ReportTypography variant="body1">
            {`LCA-skeden som är redovisade i rapporten,
                        X = Inkluderat
                        MND = Module Not Declared
                        MNR = Module Not Relevant`}
          </ReportTypography>
        </Box>
      </Box>
      <ReportFooter pageNumber={props.pageNumber} />
    </ReportContainerHorizontal>
  );
}
