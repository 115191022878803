import { Box } from "@material-ui/core";
import {
  BuildingElementCard,
  getUnit,
} from "../../../lib/components/building-element/building-element-card";
import { BuildingElement } from "../../../lib/generated/graphql";
import { ReportTypography } from "./report-typography";
import {
  unnestGQLBuildingElement,
  BuildingElement as BECommon,
} from "../../../lib/components/building-element/common";
import { rounderInt } from "../../../lib/rounder";

export interface BuildingElementWithProjectA1_A5 extends BuildingElement {
  projectA1_A5: number;
  projectA1_A5PerBTA: number;
  projectProductAmount: number;
}

interface IReportBuildingElementProps {
  buildingElement: BuildingElementWithProjectA1_A5;
  isSimplifiedReport: boolean;
}

export function ReportBuildingElement(props: IReportBuildingElementProps) {
  const buildingElementUnnestedProducts = unnestGQLBuildingElement(
    props.buildingElement
  );

  return (
    <Box>
      <Box mb={2} borderBottom={2} width="55%" display="flex">
        <ReportTypography variant="h5">
          {props.buildingElement.name}
        </ReportTypography>
      </Box>
      <BuildingElementCard
        buildingElement={buildingElementUnnestedProducts}
        projectA1_A5={props.buildingElement.projectA1_A5}
        projectA1_A5PerBTA={props.buildingElement.projectA1_A5PerBTA}
        columns={[
          { key: "thickness", label: "Tjocklek" },
          { key: "amount", label: "Mängd" },
          { key: "name", label: "" },
          {
            key: "A1_A3",
            label: (be: BECommon) =>
              `kg (A1-A3) CO₂e/${getUnit(be.unit ?? undefined)}`,
          },
          props.isSimplifiedReport
            ? { key: "projectA1_A5", label: "CO₂e tot" }
            : {
                key: "weight",
                label: (be: BECommon) => `kg/${getUnit(be.unit ?? undefined)}`,
              },
        ]}
      />
    </Box>
  );
}
