import React, { FormEvent, useState } from "react";
import "whatwg-fetch";
import { auth } from "../../firebase";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  TextField,
  Theme,
} from "@material-ui/core";
import firebase from "firebase/compat";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import MicrosoftTeamsIcon from "./icons/microsoft-teams-icon";
import MicrosoftIcon from "./icons/microsoft-icon";
import GoogleIcon from "./icons/google-icon";

function isFirebaseError(
  e: firebase.FirebaseError | any
): e is firebase.FirebaseError {
  return e.code !== undefined;
}

const getAuthErrorMessage = (e: firebase.FirebaseError | any): string => {
  if (isFirebaseError(e)) {
    switch (e.code) {
      case "auth/account-exists-with-different-credential":
        return "email already used with different sign in method";
      case "auth/email-already-in-use":
        return "email is already in use";
      default:
        return e.message;
    }
  } else {
    return e.message ?? "unknown error";
  }
};

const SignInSignUpWithUsernamePassword: React.FC<{
  disabled: boolean;
  signIn: (email: string, password: string) => void;
}> = ({ disabled, signIn }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Tabs>
        <TabList>
          <Tab>Logga in</Tab>
          <Tab>Skapa konto</Tab>
        </TabList>
        <TabPanel>
          <SignInWithUsernamePassword disabled={disabled} signIn={signIn} />
        </TabPanel>
        <TabPanel>
          <SignUpWithUsernamePassword disabled={disabled} onSignedUp={signIn} />
        </TabPanel>
      </Tabs>
    </Box>
  );
};

const SignInWithUsernamePassword: React.FC<{
  disabled?: boolean;
  signIn: (email: string, password: string) => void;
}> = ({ disabled, signIn }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    signIn(email, password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        className={classes.root}
        width={"100%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
      >
        <TextField
          id="email"
          type="email"
          label="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          classes={{ root: classes.textField }}
          disabled={disabled}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          classes={{ root: classes.textField }}
          disabled={disabled}
        />
        <Button
          id="login-btn"
          type="submit"
          variant="outlined"
          color="primary"
          disabled={disabled}
          classes={{ root: classes.root }}
        >
          Logga in
        </Button>
      </Box>
    </form>
  );
};

const SignUpWithUsernamePassword: React.FC<{
  disabled?: boolean;
  onSignedUp: (email: string, password: string) => void;
}> = ({ disabled, onSignedUp }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (confirmPassword !== password) {
        setLoading(false);
        setError("passwords don't match");
        return;
      }
      await auth.createUserWithEmailAndPassword(email, password);
      onSignedUp(email, password);
    } catch (e) {
      setLoading(false);
      setError(getAuthErrorMessage(e));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        className={classes.root}
        width={"100%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
      >
        <TextField
          id="email"
          type="email"
          label="Email"
          required
          value={email}
          disabled={disabled}
          onChange={(e) => setEmail(e.target.value)}
          classes={{ root: classes.textField }}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          required
          value={password}
          disabled={disabled}
          onChange={(e) => setPassword(e.target.value)}
          classes={{ root: classes.textField }}
        />
        <TextField
          id="confirmPassword"
          type="password"
          label="Confirm Password"
          required
          value={confirmPassword}
          disabled={disabled}
          onChange={(e) => setConfirmPassword(e.target.value)}
          classes={{ root: classes.textField }}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            id="login-btn"
            type="submit"
            variant="outlined"
            color="primary"
            disabled={disabled}
            classes={{ root: classes.root }}
          >
            Skapa Konto
          </Button>
        )}
        {error && <Typography>{error}</Typography>}
      </Box>
    </form>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      margin: theme.spacing(1),
    },
    card: {
      width: "300px",
      position: "absolute",
      top: "20%",
      left: 0,
      right: 0,
      margin: "auto",
      textAlign: "center",
    },
    image: {
      position: "absolute",
      top: "-113px",
      width: "240px",
      left: "0",
      background: "#00000066",
      boxShadow: "0 0 42px black",
    },
    textField: {
      width: "70%",
    },
    button: {
      textAlign: "left",
      justifyContent: "left",
      marginTop: theme.spacing(1),
    },
  };
});

const LoginComponent: React.FC<{ onLoggedIn: () => void }> = ({
  onLoggedIn,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const handleSignIn =
    <T extends any[]>(provider: (...t: T) => Promise<any>) =>
    async (...args: T) => {
      setLoading(true);
      try {
        await provider(...args);
        onLoggedIn();
      } catch (e) {
        setLoading(false);
        setError(getAuthErrorMessage(e));
      }
    };

  return (
    <div className={classes.card}>
      <Paper
        style={{
          background: "#00BFA5",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          component="h1"
          noWrap
          style={{
            fontSize: "50px",
            color: "white",
          }}
        >
          Plant
        </Typography>
      </Paper>
      <Card>
        <CardContent>
          <Box>
            {error && <Typography color="error">{error}</Typography>}
            {error && <Divider style={{ marginTop: 12 }} />}
            <Button
              id="login-ms-teams-btn"
              variant="outlined"
              fullWidth
              disabled={loading}
              startIcon={<MicrosoftTeamsIcon width={23} height={23} />}
              classes={{ root: classes.button }}
              onClick={handleSignIn(auth.signInWithMicrosoft)}
            >
              <span style={{ paddingLeft: 10 }}>Logga in med Teams</span>
            </Button>
            <Button
              id="login-google-btn"
              variant="outlined"
              fullWidth
              disabled={loading}
              startIcon={<GoogleIcon width={23} height={23} />}
              classes={{ root: classes.button }}
              onClick={handleSignIn(auth.signInWithGoogle)}
            >
              <span style={{ paddingLeft: 10 }}>Logga in med Google</span>
            </Button>
            <Button
              id="login-ms-btn"
              variant="outlined"
              fullWidth
              disabled={loading}
              startIcon={<MicrosoftIcon width={23} height={23} />}
              classes={{ root: classes.button }}
              onClick={handleSignIn(auth.signInWithMicrosoft)}
            >
              <span style={{ paddingLeft: 10 }}>Logga in med Microsoft</span>
            </Button>
            <Divider style={{ marginTop: 12 }} />
            <SignInSignUpWithUsernamePassword
              disabled={loading}
              signIn={handleSignIn(auth.signInWithEmailAndPassword)}
            />
            {loading && <CircularProgress />}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginComponent;
