import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Paper,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  useSimplifiedTenantAdaptionProjectQuery,
  SimplifiedTenantAdaptionTemplateData,
  SimplifiedTenantAdaptionProjectPatch,
  useSimplifiedTenantAdaptionPatchProjectMutation,
  useSimplifiedTenantAdaptionRecalculateProjectMutation,
} from "../../lib/generated/graphql";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useToast } from "@chakra-ui/react";
import _ from "lodash";
import { parseNum } from "./dataField";
import TutorialAlert from "./tutorialComponent";
import ScenariosComponent from "./scenariosComponent";
import SubmitProjectDialog from "./submitProjectDialog";
import ProjectLockedAlert from "./projectLockedComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      background: grey[100],
      height: "100%",
      boxSizing: "border-box",
    },
    formControl: {
      marginRight: theme.spacing(1),
      width: `calc(70% - ${theme.spacing(2)}px)`,
      maxWidth: 400,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        maxWidth: "none",
      },
    },
    input: {
      fontWeight: 600,
    },
  })
);

const numberToPatch =
  (field: keyof SimplifiedTenantAdaptionProjectPatch) =>
  (v: string): SimplifiedTenantAdaptionProjectPatch | undefined => {
    if (v === "") {
      return { [field]: null };
    }
    const num = parseNum(v);
    if (Number.isFinite(num)) {
      return { [field]: num };
    }
    return undefined;
  };

const stringToPatch =
  (field: keyof SimplifiedTenantAdaptionProjectPatch) =>
  (v: string): SimplifiedTenantAdaptionProjectPatch | undefined => {
    if (v === "") {
      return { [field]: null };
    }
    return { [field]: v };
  };

type ProjectFieldEditFieldProps = {
  projectId: string;
  initialDisplayVal: string;
  label: string;
  id: string;
  onChange?: () => void;
  toPatch: (val: string) => SimplifiedTenantAdaptionProjectPatch | undefined;
} & TextFieldProps;

const ProjectFieldEditField: React.FC<ProjectFieldEditFieldProps> = ({
  label,
  id,
  projectId,
  initialDisplayVal,
  toPatch,
  disabled,
  onChange,
  ...restProps
}) => {
  const toast = useToast();
  const [displayVal, setDisplayVal] = useState(`${initialDisplayVal}`);
  const [error, setError] = useState(false);
  const [patchProjectDetails, { loading: saving }] =
    useSimplifiedTenantAdaptionPatchProjectMutation({
      onError: () => {
        setDisplayVal(initialDisplayVal);
        toast({
          title: "Något gick fel",
          description: `Något gick fel när vi skulle spara`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
      onCompleted: onChange,
    });
  useEffect(() => setDisplayVal(`${initialDisplayVal}`), [initialDisplayVal]);
  const patchFieldValue = useMemo(
    () =>
      _.debounce(
        async (patch: SimplifiedTenantAdaptionProjectPatch) => {
          await patchProjectDetails({
            variables: { id: projectId, patch, reportTemplatePatch: {} },
          });
        },
        2000,
        { trailing: true }
      ),
    [patchProjectDetails]
  );

  const handleChange = useCallback(
    async (val: string) => {
      setDisplayVal(val);
      const patch = toPatch(val);
      if (patch == null) {
        setError(true);
        return;
      }
      setError(false);
      await patchFieldValue(patch);
    },
    [patchFieldValue]
  );

  return (
    <TextField
      id={id}
      label={label}
      disabled={disabled || saving}
      value={displayVal}
      error={error}
      onChange={async (event) => {
        event.preventDefault();
        await handleChange(event.target.value);
      }}
      onBlur={async (event) => {
        event.preventDefault();
        await patchFieldValue.flush();
      }}
      {...restProps}
    />
  );
};

const ProjectComponent: React.FC<{ projectId: string }> = ({ projectId }) => {
  const classes = useStyles();
  const { data, loading } = useSimplifiedTenantAdaptionProjectQuery({
    variables: { id: projectId },
  });
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const project = data?.simplifiedTenantAdaption?.project;
  const [recalculate, { loading: recalculating }] =
    useSimplifiedTenantAdaptionRecalculateProjectMutation({
      variables: { id: projectId },
      update: (cache, { data }) => {
        const id = data?.simplifiedTenantAdaption?.project?.recalculate?.id;
        if (id) {
          cache.evict({ id: `Dsrs:${id}` });
        }
      },
    });

  const onDataChange = useMemo(
    () => _.debounce(recalculate, 2000, { trailing: true }),
    [recalculate]
  );

  if (loading || !project) {
    return <div> LOADING </div>;
  }
  const locked = project.markedAsDone;

  return (
    <>
      <Box pb={2}>
        <Paper>
          <Box px={6} pb={2}>
            <ProjectFieldEditField
              projectId={projectId}
              initialDisplayVal={project?.name ?? ""}
              label="Projektets namn"
              id="name"
              toPatch={stringToPatch("name")}
              inputProps={{ className: classes.input }}
              className={classes.formControl}
              style={{ width: 280, fontWeight: 600 }}
              margin="dense"
              disabled={locked}
            />
            <ProjectFieldEditField
              projectId={projectId}
              initialDisplayVal={`${project?.area ?? ""}`}
              onChange={() => onDataChange()}
              label="Projektes area, kvm"
              id="area"
              toPatch={numberToPatch("area")}
              inputProps={{ className: classes.input }}
              className={classes.formControl}
              style={{ width: 120, fontWeight: 600 }}
              margin="dense"
              disabled={locked}
            />
            <ProjectFieldEditField
              projectId={projectId}
              initialDisplayVal={`${project?.totalCost ?? ""}`}
              label="Total kostnad, mkr"
              id="totalCost"
              toPatch={numberToPatch("totalCost")}
              inputProps={{ className: classes.input }}
              className={classes.formControl}
              style={{ width: 120, fontWeight: 600 }}
              margin="dense"
              disabled={locked}
            />
            <ProjectFieldEditField
              projectId={projectId}
              initialDisplayVal={`${project?.materialCost ?? ""}`}
              label="Varav materialkostnad, mkr (om uppgift finns)"
              id="materialCost, mkr"
              toPatch={numberToPatch("materialCost")}
              inputProps={{ className: classes.input }}
              className={classes.formControl}
              style={{ width: 280, fontWeight: 600 }}
              margin="dense"
              disabled={locked}
            />
          </Box>
          {/*<TutorialAlert />*/}
          <ProjectLockedAlert
            projectId={projectId}
            markedAsDone={project.markedAsDone}
          />
        </Paper>
      </Box>
      <Paper square elevation={2}>
        <Box pb={4}>
          <ScenariosComponent
            projectId={projectId}
            disabled={locked}
            scenarios={project.scenarios}
            onChange={() => onDataChange()}
            mainScenarioId={project.mainScenarioId ?? undefined}
            templateData={
              project.template.data as SimplifiedTenantAdaptionTemplateData
            }
          />
          <Box px={4} width="100%">
            <ProjectFieldEditField
              projectId={projectId}
              initialDisplayVal={project?.description ?? ""}
              label="Byggdelar ej upptagna enligt ovan t.ex. intern trapp, podie etc."
              id="description"
              toPatch={stringToPatch("description")}
              minRows={9}
              variant="outlined"
              multiline
              fullWidth={true}
              disabled={locked}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          px={4}
          borderTop={1}
          borderColor="grey.400"
          height={80}
          bgcolor="grey.200"
        >
          <SubmitProjectDialog
            open={submitDialogOpen}
            onClose={() => setSubmitDialogOpen(false)}
            projectId={projectId}
            scenarios={project.scenarios}
            mainScenarioId={project.mainScenarioId ?? undefined}
          />
          {!project.markedAsDone && (
            <Button
              style={{ fontWeight: 600 }}
              variant="contained"
              color="primary"
              onClick={() => setSubmitDialogOpen(true)}
            >
              <Box display="flex" alignItems="center">
                <div>Klarmarkera</div>
              </Box>
            </Button>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default ProjectComponent;
