import { useMemo, useCallback } from "react";
import { ApolloLink, HttpLink, split } from "@apollo/client";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import fetch from "isomorphic-unfetch";

import { useGlobalDispatch } from "../GlobalStateProvider";
import { ActionType } from "../GlobalStateProvider/types";
import config from "../../config";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

const API_BASE = config.api.baseUrl;

type ApolloRequestHandlerType = (
  authLink: ApolloLink,
  getAuthKey: () => Promise<string | undefined>
) => ApolloLink;

export const useApolloRequestHandler = (): ApolloRequestHandlerType => {
  const dispatch = useGlobalDispatch();

  const httpLink = useMemo(
    () => new HttpLink({ uri: `${API_BASE}/graphql`, fetch }),
    []
  );

  const errorLink = useMemo(
    () =>
      onError((error: ErrorResponse) => {
        if (
          error.graphQLErrors?.some(
            (e) => e.extensions?.code === "EMAIL_VERIFICATION_REQUIRED"
          )
        ) {
          dispatch({
            type: ActionType.SET_EMAIL_VERIFICATION_REQUIRED,
            payload: true,
          });
          return;
        }

        dispatch({
          type: ActionType.SET_GRAPH_QL_ERRORS,
          payload: error.graphQLErrors?.map((e) => ({ ...e })) ?? [],
        });

        console.error(error); // eslint-disable-line no-console
      }),
    []
  );

  const apolloRequestHandler = useCallback(
    (authLink: ApolloLink, getAuthKey: () => Promise<string | undefined>) => {
      const wsLink = new GraphQLWsLink(
        createClient({
          url: config.api.graphQlWebsocketUrl,
          shouldRetry: () => true,
          connectionParams: async () => ({
            authorization: await getAuthKey(),
          }),
        })
      );
      const splitLink = split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        httpLink
      );
      return errorLink.concat(authLink.concat(splitLink));
    },
    [errorLink, httpLink]
  );

  return apolloRequestHandler;
};
