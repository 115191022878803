import {
  BuildingElementDetailedFieldsFragment,
  ProductFieldsFragment,
} from "../../generated/graphql";
import { calc } from "../../calculation-utils";

export interface BuildingElement
  extends Omit<BuildingElementDetailedFieldsFragment, "products"> {
  products: ProductFieldsFragment[];
}

export const unnestGQLBuildingElement = (
  be: BuildingElementDetailedFieldsFragment
): BuildingElement => ({
  ...be,
  products: be.products || [],
});

export interface BuildingElementEmissions {
  thickness: number;
  A1_A5: number;
  A1_A3: number;
  weight: number;
}

export const getEmission = (
  buildingElement: BuildingElement
): BuildingElementEmissions =>
  buildingElement.products?.reduce(
    (acc: any, product: any) => {
      const weight = calc.weight(product);
      const { A1_A5, A1_A3 } = calc.gwp(
        weight,
        product.rawMaterial,
        product.recycleType
      );

      return {
        thickness:
          acc.thickness + (product.unit === "m2" ? product.thickness : 0),
        A1_A5: acc.A1_A5 + A1_A5,
        A1_A3: acc.A1_A3 + A1_A3,
        weight: acc.weight + weight,
      };
    },
    {
      thickness: 0,
      A1_A5: 0,
      A1_A3: 0,
      weight: 0,
    }
  );
