import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis } from "recharts";
import { chartColorArray } from "./chart-colors";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    barChart: {
      "& .recharts-legend-item-text": {
        fontSize: 10,
      },
      "& .xAxis text": {
        textAnchor: "start !important",
      },
      "& .xAxis .recharts-cartesian-axis-tick": {
        transform: "translate(-5px)",
      },
      "& .xAxis .recharts-cartesian-axis-tick line": {
        transform: "translate(5px)",
      },
    },
  })
);

export function ReportStackedBarChart(props: {
  isPrint: boolean;
  data: any;
  fullWidth?: boolean;
}) {
  const classes = useStyles();

  const barSize = 55;

  return (
    <BarChart
      width={props.fullWidth === true ? 654 : 330}
      height={160}
      data={props.data}
      className={classes.barChart}
      margin={{ top: 8, right: 5, bottom: 5, left: 5 }}
    >
      <XAxis
        dataKey="scenarioName"
        angle={18}
        allowDataOverflow
        minTickGap={-40}
      />
      <YAxis width={50} />
      <Legend
        height={32}
        verticalAlign={"top"}
        wrapperStyle={{ top: "-12px" }}
      />
      <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#acacac" />
      <Bar
        isAnimationActive={!props.isPrint}
        barSize={barSize}
        dataKey="Produktskede"
        stackId="a"
        fill={chartColorArray[2]}
      />
      <Bar
        isAnimationActive={!props.isPrint}
        barSize={barSize}
        dataKey="Transportskede"
        stackId="a"
        fill={chartColorArray[1]}
      />
      <Bar
        isAnimationActive={!props.isPrint}
        barSize={barSize}
        dataKey="Byggskede"
        stackId="a"
        fill={chartColorArray[0]}
      />
    </BarChart>
  );
}
