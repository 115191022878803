import { Box, Paper, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { PreliminaryRibbon } from "./PreliminaryRibbon";

interface IReportContainerProps {
  children?: React.ReactNode;
  background?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageRotator: {
      position: "relative",
      "@media print": {
        page: "rotated",
        width: 794,
        height: 1122,
        overflow: "hidden",
      },
    },
    reportPaper: {
      width: 1123,
      height: 794,
      "@media print": {
        transform: "rotate(-90deg) translate(-100%, 0)",
        transformOrigin: "0 0",
        boxShadow: "none",
        border: "none",
      },
      padding: `40px 60px`,
      position: "relative",
      pageBreakBefore: "always",
      overflow: "hidden",

      "@media screen": {
        marginBottom: 25,
      },
    },
  })
);

export function ReportContainerHorizontal({
  children,
  background,
}: IReportContainerProps) {
  const classes = useStyles();
  const matchesPrint = useMediaQuery("print");

  return (
    <Box
      fontFamily="'Quicksand', sans-serif"
      textAlign="left"
      className={classes.pageRotator}
    >
      {!matchesPrint ? (
        <Paper
          elevation={2}
          className={classes.reportPaper}
          style={{ background }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            {children}
          </Box>
        </Paper>
      ) : (
        <Box
          display="flex"
          className={classes.reportPaper}
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          {children}
        </Box>
      )}
      <PreliminaryRibbon />
    </Box>
  );
}
