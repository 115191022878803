import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportTypography } from "../components/report-typography";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { useProjectQuery } from "../../../lib/generated/graphql";
import { ReportPageProps } from "../../report-page";

export default function ReportReferencesPage(props: ReportPageProps) {
  const project = useProjectQuery({ variables: { projectId: props.projectId } })
    ?.data?.project || {
    reportReferencePageText: "",
  };

  const pageTitle = "Referenser".toUpperCase();

  return (
    <ReportContainer>
      <ReportHeader chapter="Referenser" />
      <ReportBodyAlignSpaceBetween>
        <Box mx={2}>
          <Box height={160} />
          <Box mb={3} textAlign="left">
            <ReportTypography variant="h3">{pageTitle}</ReportTypography>
          </Box>
          <Box maxWidth={550}>
            {project.reportReferencePageText &&
              project.reportReferencePageText?.split("\n").map((text) => {
                return (
                  <Box
                    mb={3}
                    style={{
                      textIndent: "-32px",
                      marginLeft: 32,
                    }}
                  >{`${text} \n\n`}</Box>
                );
              })}
          </Box>
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={props.pageNumber} />
    </ReportContainer>
  );
}
