import { Box, CircularProgress, Grid, useTheme } from "@material-ui/core";
import { ReportFooter } from "../../../report/components/report-footer";
import { ReportContainer } from "../../../report/components/report-container";
import { ReportHeaderCommon as ReportHeader } from "../../../report/components/report-header";
import { ReportTypography } from "../../../report/components/report-typography";
import {
  useSimplifiedTenantAdaptionProjectQuery,
  useSimplifiedTenantAdaptionProjectDsrsGroupedQuery,
} from "../../../../lib/generated/graphql";
import { ReportBodyAlignBottom } from "../../../report/components/body";
import { ReportStatisticsBox } from "../../../report/components/statistics-box";
import { ReportTable } from "../../../report/components/table";
import { grey } from "@material-ui/core/colors";
import { ReportStackedBarChart } from "../../../report/components/report-stacked-bar-chart";
import { SingleReportPage } from "./index";
import _ from "lodash";
import { DefaultTheme } from "../../../../providers/styling";

const amountAndFactor = (
  amount: number | undefined,
  factor: number
): number => {
  if (amount == null || amount === 0) {
    return 0;
  }
  return amount * (factor < 1 ? -1 : 1);
};

const IntroductionPage: SingleReportPage = ({
  projectId,
  pageNumber,
  isPrint,
  reportTemplate,
}) => {
  const theme = useTheme<DefaultTheme>();
  const { data: projectData, loading: loadingProject } =
    useSimplifiedTenantAdaptionProjectQuery({
      variables: { id: projectId },
    });

  const { data: gwpData, loading: loadingGwp } =
    useSimplifiedTenantAdaptionProjectDsrsGroupedQuery({
      variables: {
        id: projectId,
        query: {
          filter: [
            { NOT: { "plant::sta::scenario::row::scenarioId": "is_null" } },
            { factor: { gte: 1 } },
          ],
          group: [
            {
              col: "plant::sta::scenario::row::scenarioId",
              as: "scenarioId",
            },
            {
              col: "plant::sta::scenario::name",
              as: "scenarioName",
            },
          ],
          aggregations: [
            { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
            { col: "weight", op: "sum", reduceAggOp: "sum" },
            { col: "A1_A5", op: "sum", reduceAggOp: "sum" },
            {
              col: "A1_A3PerBTA",
              op: "sum",
              reduceAggOp: "sum",
              as: "Produktskede",
            },
            {
              col: "A4PerBTA",
              op: "sum",
              reduceAggOp: "sum",
              as: "Transportskede",
            },
            { col: "A5PerBTA", op: "sum", reduceAggOp: "sum", as: "Byggskede" },
          ],
          sort: [{ col: "A1_A5PerBTA", desc: true }],
          topN: 100,
        },
      },
    });

  const { data: gwpPerColumnData, loading: loadingGwpPerColumn } =
    useSimplifiedTenantAdaptionProjectDsrsGroupedQuery({
      variables: {
        id: projectId,
        query: {
          filter: [
            { NOT: { "plant::sta::scenario::row::scenarioId": "is_null" } },
          ],
          group: [
            {
              col: "plant::sta::scenario::row::scenarioId",
              as: "scenarioId",
            },
            {
              col: "plant::sta::scenario::name",
              as: "scenarioName",
            },
            { col: "columnKey" },
            { col: "factor" },
          ],
          aggregations: [{ col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" }],
          sort: [{ col: "A1_A5PerBTA", desc: true }],
          topN: 100,
        },
      },
    });

  if (loadingProject || loadingGwp || loadingGwpPerColumn) {
    return <CircularProgress />;
  }

  const project = projectData?.simplifiedTenantAdaption?.project!;
  const gwpPerScenario =
    gwpData?.simplifiedTenantAdaption?.project?.datasetRecords?.grouped?.data;
  const gwpPerColumn =
    gwpPerColumnData?.simplifiedTenantAdaption?.project?.datasetRecords?.grouped
      ?.data;

  const pageTitle = "Tillvägagångssätt, osäkerheter och resultat".toUpperCase();
  const subTitle = `Projektet har estimerat sin miljöpåverkan genom att beräkna byggnadens materialanvändning. Med begreppet materialanvändning avses material som tillförs byggarbetsplats d.v.s det som byggs in i byggnaden.`;

  const introText = `Sverige har som mål att senast 2045 vara klimatneutralt. För att nå detta mål behöver flera industrier förändra sitt klimatarbete, och byggindustrin är en av dem. \n\n Ett första steg för att påverka byggindustrins klimatpåverkan är att börja mäta hur stor påverkan våra byggnader har.\n\n`;

  const headerPart1 = `Förenklad beräkning`;
  const textPart1 = `Denna rapport baseras på grov mängdning, där syftet är att jämföra klimatpåverkan vid olika val vid lokalanpassning. Mängder är grovt uppskattade och kan komma att avvika från faktisk använd mängd material.`;

  const headerPart2 = `Verktyg för klimatberäkning`;
  const textPart2 = `Klimatberäkningen har gjorts via ett klimatberäkningsverktyg, Plant.se. Mängdförteckningar matas in i verktyget, där mängder tolkats till generiska material. Vid användning av kalkyluppgifter bör påslag för spill exkluderas, då beräknat spill ingår i matierldata. Vid osäkerhet bör det mindre gynnsamma alternativet anges, för att vara försiktig med att ge en överdrivet positiv bild. \n\n Denna rapport bör ses som en estimering av projektets klimatpåverkan och ska inte ses som en fullständig livscykelanalys.`;

  const nonAffectingCols = project.template.data.columns.filter(
    (c) => c.factor < 1
  );
  const affectingColKeys = project.template.data.columns
    .filter((c) => c.factor === 1)
    .map((c) => c.key);

  const rowsPerColumn = Object.values(
    _.groupBy(gwpPerColumn, (r) => r.scenarioId)
  ).map((rows) => ({
    scenarioName: rows.find((r) => r.scenarioName)?.scenarioName ?? "",
    allA1_A5PerBTA: rows.reduce((a, r) => a + r.A1_A5PerBTA ?? 0, 0),
    ...nonAffectingCols.reduce(
      (a, c) => ({
        ...a,
        [c.key]: amountAndFactor(
          rows.find((r) => r.columnKey === c.key)?.A1_A5PerBTA,
          c.factor
        ),
      }),
      {}
    ),
    A1_A5PerBTA: rows
      .filter((r) => affectingColKeys.includes(r.columnKey))
      .reduce((a, r) => a + r.A1_A5PerBTA ?? 0, 0),
  }));

  return (
    <ReportContainer>
      <ReportHeader
        chapter="Tillvägagångssätt"
        reportType={"simplified"}
        reportTemplate={reportTemplate}
      />
      <ReportBodyAlignBottom>
        <Box mb={2}>
          <Box textAlign="left" maxWidth={theme.spacing(70)}>
            <Box mb={2}>
              <ReportTypography variant="h2">{pageTitle}</ReportTypography>
            </Box>
          </Box>
          <Box mb={3}>
            <ReportTypography variant="subtitle2">{subTitle}</ReportTypography>
          </Box>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ReportTypography variant="body1">{introText}</ReportTypography>
                <ReportTypography variant="h6">{headerPart1}</ReportTypography>
                <Box>
                  <ReportTypography variant="body1">
                    {textPart1}
                  </ReportTypography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <ReportTypography variant="h6">{headerPart2}</ReportTypography>
                <ReportTypography variant="body1">{textPart2}</ReportTypography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <ReportStatisticsBox
            gwpScorecard={() => (
              <Box>
                <ReportTable
                  tableCaption={`LOA: ${project.area} m²`}
                  borderBottomHeaderCell={`2px solid ${grey[400]}`}
                  columns={[
                    {
                      label: "Scenario",
                      value: "scenarioName",
                    },
                    {
                      label: "kg CO₂e/LOA",
                      value: "A1_A5PerBTA",
                      align: "right",
                    },
                  ]}
                  hasRawMaterials={false}
                  rows={gwpPerScenario}
                  tableUnit={"none"}
                />
              </Box>
            )}
            skipMarginLeft={true}
            skipMarginY={true}
            title="Beräknad klimatpåverkan"
            caption="Klimatpåverkan vid valt scenario"
          />
          <ReportStatisticsBox
            skipMarginY={true}
            chart={() => (
              <ReportStackedBarChart isPrint={isPrint} data={gwpPerScenario} />
            )}
            title="Klimatpåverkan per skede"
            caption="Fördelning per skede, andel av klimatpåverkan"
          />
        </Box>

        <Box pt={2}>
          <ReportTable
            tableCaption={`
						¹ Hade inte projektet valt att återbruka material eller bibehålla planlösning, skulle klimatpåverkan varit på denna nivå
						² Genom att återbruka material har materialåtgång och klimatpåverkan minskat i projektet
						³ Genom att bibehålla planlösning har materialåtgång och klimatpåverkan minskat i projektet`}
            borderBottomHeaderCell={`2px solid ${grey[400]}`}
            columns={[
              {
                label: "Scenario",
                value: "scenarioName",
              },
              {
                label: "Vid allt nytt¹",
                value: "allA1_A5PerBTA",
                align: "right",
              },
              ...nonAffectingCols.map((c) => ({
                label: c.name,
                value: c.key,
                align: "right" as const,
              })),
              {
                label: "Klimatpåverkan",
                value: "A1_A5PerBTA",
                align: "right",
              },
            ]}
            hasRawMaterials={false}
            rows={rowsPerColumn}
            tableUnit={"kg CO₂e/LOA"}
          />
        </Box>
      </ReportBodyAlignBottom>
      <ReportFooter pageNumber={pageNumber} />
    </ReportContainer>
  );
};

export default IntroductionPage;
