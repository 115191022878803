import { Box } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { IReportColumns, ReportTable } from "../components/table";
import { ReportTypography } from "../components/report-typography";
import { useRawMaterialsQuery } from "../../../lib/generated/graphql";
import {
  omitRedundantFields,
  replaceProductRawMaterialIdWithName,
} from "./report-gwp-summary-page";
import { groupBy } from "lodash";
import { sumFuncBsabPages } from "./report-scenarios-bsab-page";
import { compareValue } from "./report-scenarios-bsab-detail-page";
import useScenariosQueries, {
  useGetBenchmarks,
} from "../hooks/scenario-queries";
import { ReportPageProps } from "../../report-page";

export default function ReportScenariosGwpSummaryPage({
  tmplVars,
  pageNumber,
  projectId,
}: ReportPageProps) {
  const chapter = "Resultat";
  const pageTitle = "Scenario – Jämförelse material";
  const scenarioOrPipelines = useGetBenchmarks(projectId);
  const rawMaterials = useRawMaterialsQuery({ variables: { projectId } })?.data
    ?.project?.rawMaterials;

  const rawMaterialsById = groupBy(rawMaterials, "id");

  const limitNumRows = 28;
  const dsrs = useScenariosQueries({
    filter: [],
    group: [{ col: "ProductRawMaterialId" }],
    aggregations: [{ col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" }],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 3000,
  });

  const rows = omitRedundantFields(
    dsrs.data.sort((a, b) => b.A1_A5PerBTA - a.A1_A5PerBTA)
  ).map(({ weight, ProductRawMaterialId = "-", ...row }: any) => ({
    ...row,
    scenarioName: scenarioOrPipelines.find(({ id }) => id === row.id)?.name,
    ProductRawMaterialId: ProductRawMaterialId,
  }));

  const columns: IReportColumns[] = [
    {
      label: "Generiskt material",
      value: "ProductRawMaterialId",
      width: "60%",
      align: "left",
    },
    ...Object.keys(groupBy(rows, "scenarioName"))
      .map((scenarioName) => scenarioName?.replace(/\+.*/, ""))
      .map((scenarioName) => ({
        label: `${scenarioName}`,
        value: scenarioName,
        align: "right" as const,
      }))
      .sort(compareValue),
  ];

  const rowsGroupedOnScenarioName = replaceProductRawMaterialIdWithName(
    Object.values(groupBy(rows, "ProductRawMaterialId"))
      .map((rows) => {
        return rows
          .map(({ scenarioName, ...rest }) => ({
            scenarioName: scenarioName?.replace(/\+.*/, ""),
            ...rest,
          }))
          .reduce((acc, dsr) => {
            return {
              ...acc,
              ...dsr,
              [dsr.scenarioName]: dsr.A1_A5PerBTA || 0,
            };
          }, {});
      })
      .map((d) => ({
        ...d,
        ...scenarioOrPipelines?.reduce(
          (acc, scenario) =>
            // @ts-ignore
            d[scenario.name] == null ? { ...acc, [scenario.name]: 0 } : acc,
          {}
        ),
      })),
    rawMaterialsById
  );

  const sumRow = {
    ...rowsGroupedOnScenarioName.reduce(sumFuncBsabPages, {}),
    ProductRawMaterialId: "",
  };

  const restRow = {
    ...rowsGroupedOnScenarioName
      .slice(limitNumRows)
      .reduce(sumFuncBsabPages, {}),
  };

  return (
    <ReportContainer>
      <ReportHeader chapter={chapter} />
      <ReportBodyAlignSpaceBetween>
        <Box mt={4} mb={1} textAlign="left">
          <ReportTypography variant="h3">{pageTitle}</ReportTypography>
        </Box>

        <Box flexGrow={1}>
          <ReportTable
            columns={columns}
            hasRawMaterials={true}
            tableUnit={`kg CO₂e/${tmplVars.BTA.inUnit}`}
            rows={rowsGroupedOnScenarioName.slice(0, limitNumRows)}
            restRow={restRow}
            sumRow={{ ...sumRow, weight: (sumRow?.weight || 0) / 1000 }}
          />
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} />
    </ReportContainer>
  );
}
