import { useMemo, useCallback, useEffect } from "react";
import {
  useGlobalDispatch,
  useGlobalState,
} from "../../../providers/GlobalStateProvider";
import { ActionType } from "../../../providers/GlobalStateProvider/types";
import { useParams } from "react-router-dom";
import {
  useListScenariosQuery,
  useProjectPipelinesQuery,
  useProjectQuery,
} from "../../../lib/generated/graphql";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Tag,
  Flex,
} from "@chakra-ui/react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const ScenarioPipelineSelector = () => {
  const state = useGlobalState();
  const dispatch = useGlobalDispatch();
  const params = useParams();
  const projectId = params.projectId!;

  const payload = { variables: { projectId } };
  const project = useProjectQuery(payload)?.data?.project;
  const scenarios = useListScenariosQuery(payload)?.data?.project?.scenarios;
  const pipelines = useProjectPipelinesQuery({ variables: { projectId } }).data
    ?.project?.pipelines.nodes;

  const getActiveName = useCallback(() => {
    if (state.scenarioId) {
      return scenarios?.find(({ id }) => id === state.scenarioId)?.name || null;
    } else if (state.pipelineId) {
      return pipelines?.find(({ id }) => id === state.pipelineId)?.name || null;
    }
  }, [scenarios, pipelines, state.scenarioId, state.pipelineId]);

  const outputName = useMemo(() => {
    const name = getActiveName();
    if (state.scenarioId) {
      return `Scenario: ${name}`;
    } else if (state.pipelineId) {
      return `Pipeline: ${name}`;
    }
  }, [scenarios, pipelines, state.scenarioId, state.pipelineId]);

  const handleChangeScenario = (value: any) => {
    dispatch({ type: ActionType.SET_SCENARIO_ID, payload: value });
  };
  const handleChangePipeline = (value: any) => {
    dispatch({ type: ActionType.SET_PIPELINE_ID, payload: value });
  };

  useEffect(() => {
    if (!(state.scenarioId && state.pipelineId)) {
      if (project?.mainScenario?.id) {
        handleChangeScenario(project?.mainScenario?.id);
      } else if (pipelines && pipelines?.length > 0) {
        handleChangePipeline(pipelines[0].id);
      }
    }
  }, [project?.mainScenario?.id, pipelines]);

  return (
    <Menu isLazy>
      <MenuButton
        width={"100%"}
        as={Button}
        rightIcon={<KeyboardArrowDownIcon fontSize="small" />}
        variant="outline"
      >
        <Flex alignItems={"center"}>
          {outputName}
          {project?.mainScenario?.id === state.scenarioId && (
            <Tag
              ml={2}
              size={"md"}
              borderRadius="full"
              variant="solid"
              colorScheme="green"
            >
              M.S
            </Tag>
          )}
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue={state.scenarioId ?? ""}
          title="Select Scenario"
          type="radio"
          onChange={handleChangeScenario}
        >
          {scenarios?.map((scenario) => (
            <MenuItemOption key={scenario.id} value={scenario.id}>
              <Flex alignItems={"center"}>
                {scenario.name}
                {project?.mainScenario?.id === scenario.id && (
                  <Tag
                    ml={2}
                    size={"sm"}
                    borderRadius="full"
                    variant="solid"
                    colorScheme="green"
                  >
                    Main scenario
                  </Tag>
                )}
              </Flex>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
        {pipelines?.length && (
          <MenuOptionGroup
            defaultValue={state.pipelineId ?? ""}
            title="Select Pipeline"
            type="radio"
            onChange={handleChangePipeline}
          >
            {pipelines?.map((pipeline) => (
              <MenuItemOption key={pipeline.id} value={pipeline.id}>
                <Flex alignItems={"center"}>{pipeline.name}</Flex>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  );
};

export default ScenarioPipelineSelector;
