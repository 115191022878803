export const bsabCodes: { [key: string]: string } = {
  "0": "SAMMANSATTA BYGGDELAR OCH INSTALLATIONSSYSTEM",
  "1": "UNDERGRUND, UNDERBYGGNAD, SKYDDANDE LAGER I MARK, GRUNDKONSTRUKTIONER OCH STÖDKONSTRUKTIONER",
  "01": "SAMMANSATTA BYGGDELAR",
  "2": "BÄRVERK",
  "3": "ÖVERBYGGNADER OCH ANLÄGGNINGSKOMPLETTERINGAR",
  "4": "RUMSBILDANDE BYGGDELAR, HUSKOMPLETTERINGAR, YTSKIKT OCH RUMSKOMPLETTERINGAR",
  "5": "VA-, VVS-, KYL- och processmediesystem",
  "6": "EL- OCH TELESYSTEM",
  "7": "TRANSPORTSYSTEM M M",
  "8": "STYR- OCH ÖVERVAKNINGSSYSTEM",
  "9": "Övriga byggdelar och installationssystem",
  "11": "UNDERGRUND",
  "12": "UNDERBYGGNAD",
  "13": "LAGER I MARK FÖR SKYDD AV BYGGNADSVERK",
  "14": "LAGER I MARK FÖR SKYDD AV NATUR",
  "15": "GRUNDKONSTRUKTIONER",
  "16": "STÖDKONSTRUKTIONER",
  "20": "SAMMANSATTA BÄRVERK",
  "21": "BÄRVERK I ANLÄGGNING",
  "27": "BÄRVERK I HUSSTOMME",
  "31": "ÖVERBYGGNADER",
  "32": "ANLÄGGNINGSKOMPLETTERINGAR",
  "40": "SAMMANSATTA RUMSBILDANDE BYGGDELAR, HUSKOMPLETTERINGAR, YTSKIKT OCH RUMSKOMPLETTERINGAR",
  "41": "KLIMATSKILJANDE DELAR OCH KOMPLETTERINGAR I YTTERTAK OCH YTTERBJÄLKLAG",
  "42": "KLIMATSKILJANDE DELAR OCH KOMPLETTERINGAR I YTTERVÄGG",
  "43": "INRE RUMSBILDANDE BYGGDELAR",
  "44": "INVÄNDIGA YTSKIKT",
  "45": "HUSKOMPLETTERINGAR",
  "46": "RUMSKOMPLETTERINGAR",
  "49": "ÖVRIGA RUMSBILDANDE BYGGDELAR, HUSKOMPLETTERINGAR, YTSKIKT OCH RUMSKOMPLETTERINGAR",
  "50": "SAMMANSATTA VA-, VVS-, KYL- OCH PROCESSMEDIESYSTEM",
  "52": "FÖRSÖRJNINGSSYSTEM FÖR FLYTANDE ELLER GASFORMIGT MEDIUM",
  "53": "AVLOPPSVATTENSYSTEM OCH PNEUMATISKA AVFALLSTRANSPORTSYSTEM E D",
  "54": "BRANDSLÄCKNINGSSYSTEM",
  "55": "KYLSYSTEM",
  "56": "VÄRMESYSTEM",
  "57": "LUFTBEHANDLINGSSYSTEM",
  "51": "VATTEN-, AVLOPPS-, FJÄRRVÄRME- OCH GASLEDNINGSSYSTEM M M, ANLÄGGNING",
  "61": "KANALISATIONSSYSTEM",
  "63": "ELKRAFTSYSTEM",
  "64": "TELESYSTEM",
  "66": "SYSTEM FÖR SPÄNNINGSUTJÄMNING OCH ELEKTRISK SEPARATION",
  "71": "HISSYSTEM",
  "73": "RULLTRAPPSSYSTEM OCH RULLRAMPSSYSTEM",
  "74": "KRANSYSTEM",
  "75": "RÖRPOSTSYSTEM",
  "76": "SYSTEM MED MASKINDRIVEN PORT, GRIND, DÖRR M M",
  "78": "DIVERSE TRANSPORTSYSTEM",
  "81": "STYR- OCH ÖVERVAKNINGSSYSTEM FÖR FASTIGHETSDRIFT",
  "82": "STYR- OCH ÖVERVAKNINGSSYSTEM FÖR PROCESSINSTALLATIONER",
  "84": "STYR- OCH ÖVERVAKNINGSSYSTEM FÖR TRAFIK",
  "91": "GEMENSAMMA ARBETEN OCH TILLFÄLLIGA FABRIKER",
  "01.B": "Sammansatta byggdelar för anläggning",
  "01.BB": "Sammansatta byggdelar i väg, plan o d",
  "01.BC": "Sammansatta byggdelar i spåranläggning",
  "01.S": "Sammansatta byggdelar i hus",
  "01.SB": "Innerväggar, sammansatta",
  "01.SC": "Ytterväggar, sammansatta",
  "01.SC/31": "Ytterväggar, sammansatta - element av betong",
  "01.SC/35":
    "Ytterväggar, sammansatta - element av trä eller träbaserat material",
  "01.SF": "Bjälklag, sammansatta",
  "01.SG": "Yttertak och ytterbjälklag, sammansatta",
  "01.SH": "Trappor, sammansatta",
  "01.SJ": "Balkonger, sammansatta",
  "01.SK": "Loftgångar, sammansatta",
  "01.SL": "Lastkajer, sammansatta",
  "01.SZ": "Övriga sammansatta byggdelar i hus",
  "11.B": "Obearbetad undergrund",
  "11.C": "Bearbetad undergrund",
  "12.B": "Fyllningar",
  "13.G": "Termisk isolering i mark för skydd av byggnadsverk",
  "13.GS": "Termisk isolering i mark för skydd av hus",
  "15.A": "Sammansatta grundkonstruktioner",
  "15.B": "Grundkonstruktioner för anläggning",
  "15.S": "Grundkonstruktioner för hus",
  "15.S/11": "Grundkonstruktioner - platsgjuten betong",
  "15.SB": "Pålar",
  "15.SC": "Grundplintar",
  "15.SE": "Pålplintar och pålplattor",
  "15.SF": "Pelarholkar i grundkonstruktion",
  "15.SG": "Grundplattor, hela",
  "15.SG/11": "Grundplattor, hela - platsgjuten betong",
  "15.SH": "Grundsulor",
  "15.SJ": "Grundbalkar",
  "15.SK": "Grundmurar",
  "15.SL": "Påldäck",
  "15.ST": "Fundament i grundkonstruktion",
  "15.SU": "Kompletteringar i grundkonstruktion",
  "15.SUB": "Förankringar i grundkonstruktion",
  "15.SZ": "Övriga grundkonstruktioner för hus",
  "16.B": "Stödkonstruktioner i anläggning",
  "16.BC": "Stödmurar",
  "16.BZ": "Övriga stödkonstruktioner",
  "21.B": "Bärverk i bro, brygga, kaj o d",
  "21.BB": "Bärverk i bro",
  "21.BC": "Bärverk i brygga, kaj o d",
  "21.C": "Bärverk i tunnel, bergrum o d",
  "21.CB": "Bärverk i tunnel",
  "21.CC": "Bärverk i bergrum",
  "21.D": "Bärverk i mast, torn, fyr o d",
  "21.Z": "Övriga bärverk i anläggning",
  "27.A": "Sammansatta bärverk i husstomme",
  "27.B": "Stominnerväggar",
  "27.B/11": "Stominnerväggar - platsgjuten betong",
  "27.B/12": "Stominnerväggar - glidformsgjuten betong",
  "27.B/21": "Stominnerväggar - murverk",
  "27.B/31": "Stominnerväggar - element av betong",
  "27.B/35": "Stominnerväggar - element av trä eller träbaserat material",
  "27.B/36": "Stominnerväggar - element av skivor och stålregelverk",
  "27.B/37":
    "Stominnerväggar - element av skivor och träregelverk eller träbaserade regelverk",
  "27.B/41": "Stominnerväggar - skivor och stålregelverk",
  "27.B/42":
    "Stominnerväggar - skivor och träregelverk eller träbaserade regelverk",
  "27.C": "Stomytterväggar",
  "27.C/11": "Stomytterväggar - platsgjuten betong",
  "27.C/12": "Stomytterväggar - glidformsgjuten betong",
  "27.C/21": "Stomytterväggar - murverk",
  "27.C/31": "Stomytterväggar - element av betong",
  "27.C/32": "Stomytterväggar - element av autoklaverad lättbetong",
  "27.C/33": "Stomytterväggar - element av lättklinkerbetong",
  "27.C/34": "Stomytterväggar - element av stål",
  "27.C/35": "Stomytterväggar - element av trä eller träbaserat material",
  "27.C/36": "Stomytterväggar - element av skivor och stålregelverk",
  "27.C/37":
    "Stomytterväggar - element av skivor och träregelverk eller träbaserade regelverk",
  "27.C/41": "Stomytterväggar - skivor och stålregelverk",
  "27.C/42":
    "Stomytterväggar - skivor och träregelverk eller träbaserade regelverk",
  "27.D": "Pelarstommar",
  "27.D/11": "Pelarstommar - platsgjuten betong",
  "27.D/21": "Pelarstommar - murverk",
  "27.D/31": "Pelarstommar - element av betong",
  "27.D/34": "Pelarstommar - element av stål",
  "27.D/35": "Pelarstommar - element av trä eller träbaserat material",
  "27.D/51": "Pelarstommar - formvaror av stål",
  "27.E": "Balkstommar",
  "27.E/11": "Balkstommar - platsgjuten betong",
  "27.E/21": "Balkstommar - murverk",
  "27.E/31": "Balkstommar - element av betong",
  "27.E/33": "Balkstommar - element av lättklinkerbetong",
  "27.E/34": "Balkstommar - element av stål",
  "27.E/35": "Balkstommar - element av trä eller träbaserat material",
  "27.E/51": "Balkstommar - formvaror av stål",
  "27.F": "Stombjälklag",
  "27.F/11": "Stombjälklag - platsgjuten betong",
  "27.F/31": "Stombjälklag - element av betong",
  "27.F/32": "Stombjälklag - element av autoklaverad lättbetong",
  "27.F/33": "Stombjälklag - element av lättklinkerbetong",
  "27.F/35": "Stombjälklag - element av trä eller träbaserat material",
  "27.G": "Yttertaks- och ytterbjälklagsstommar",
  "27.G/11": "Yttertaks- och ytterbjälklagsstommar - platsgjuten betong",
  "27.G/31": "Yttertaks- och ytterbjälklagsstommar - element av betong",
  "27.G/32":
    "Yttertaks- och ytterbjälklagsstommar - element av autoklaverad lättbetong",
  "27.G/33":
    "Yttertaks- och ytterbjälklagsstommar - element av lättklinkerbetong",
  "27.G/34": "Yttertaks- och ytterbjälklagsstommar - element av stål",
  "27.G/35":
    "Yttertaks- och ytterbjälklagsstommar - element av trä eller träbaserat material",
  "27.H": "Kompletterande bärverk i husstomme",
  "27.HB": "Trappstommar i husstomme",
  "27.HC": "Balkongstommar i husstomme",
  "27.HC/31": "Balkongstommar i husstomme - element av betong",
  "27.HC/33": "Balkongstommar i husstomme - element av lättklinkerbetong",
  "27.HC/35":
    "Balkongstommar i husstomme - element av trä eller träbaserat material",
  "27.HD": "Loftgångsstommar i husstomme",
  "27.HD/31": "Loftgångsstommar i husstomme - element av betong",
  "27.HD/33": "Loftgångsstommar i husstomme - element av lättklinkerbetong",
  "27.HD/35":
    "Loftgångsstommar i husstomme - element av trä eller träbaserat material",
  "27.HE": "Lastkajsstommar i husstomme",
  "27.HF": "Schaktstommar i husstomme",
  "27.HFB": "Hisschaktstommar i husstomme",
  "27.HFB/11": "Hisschaktstommar i husstomme - platsgjuten betong",
  "27.HFB/12": "Hisschaktstommar i husstomme - glidformsgjuten betong",
  "27.HFB/31": "Hisschaktstommar i husstomme - element av betong",
  "27.HFB/35":
    "Hisschaktstommar i husstomme - element av trä eller träbaserat material",
  "27.Z": "Övriga bärverk i husstomme",
  "31.B": "Överbyggnader för väg och plan",
  "31.BC": "Körbanor",
  "31.BD": "Vägrenar",
  "31.BE": "Stödremsor",
  "31.C": "Överbyggnader för spåranläggning",
  "31.CB": "Överbyggnad för spåranläggning i gata",
  "31.CC": "Överbyggnad för spåranläggning på banvall",
  "31.CZ": "Övriga spåröverbyggnader",
  "31.D": "Överbyggnader för flygplatsbanor",
  "31.E": "Överbyggnader för slänter",
  "31.EB": "Innerslänt",
  "31.EC": "Ytterslänt",
  "31.F": "Överbyggnader för vegetationsytor",
  "31.FB": "Naturmark",
  "31.FC": "Gräsytor",
  "31.FD": "Planteringsytor",
  "31.H": "Överbyggnader för vägbro",
  "31.J": "Överbyggnader för järnvägsbro",
  "31.K": "Överbyggnader för kaj o d",
  "31.Y": "Diverse överbyggnader i mark",
  "31.YB": "Sandytor",
  "31.YC": "Ytor belagda med konstmaterial",
  "31.Z": "Övriga överbyggnader",
  "32.B": "Trafikanordningar",
  "32.C": "Väganordningar",
  "32.D": "Järnvägsanordningar",
  "32.E": "Kantstöd, ränndalar o d",
  "32.F": "Diken",
  "32.G": "Terrängtrappor",
  "32.H": "Terrängmurar",
  "32.J": "Fasta utrustningar i mark",
  "32.K": "Utsmyckningar i mark",
  "32.L": "Växtmaterial",
  "32.M": "Anordningar för bro",
  "32.N": "Anordningar för brygga, kaj o d",
  "32.Z": "Övriga anläggningskompletteringar",
  "32.ZB": "Dammar, bassänger o d",
  "41.A":
    "Sammansatta klimatskiljande delar och kompletteringar i yttertak och ytterbjälklag",
  "41.B": "Kompletterande bärverk i yttertak",
  "41.C": "Ytterklimatskärmar i yttertak och ytterbjälklag",
  "41.D": "Innerklimatskärmar i yttertak och ytterbjälklag",
  "41.E": "Öppningskompletteringar i yttertak och ytterbjälklag",
  "41.F": "Kompletteringar till yttertak och ytterbjälklag",
  "41.FB": "Utvändiga avvattningssystem från yttertak och ytterbjälklag",
  "41.FC": "Invändiga avvattningssystem från yttertak och ytterbjälklag",
  "41.FD": "Säkerhetsanordningar på yttertak och ytterbjälklag",
  "41.FE": "Tillträdesanordningar på yttertak och ytterbjälklag",
  "41.FY": "Diverse kompletteringar till yttertak och ytterbjälklag",
  "41.Z":
    "Övriga klimatskiljande delar och kompletteringar i yttertak och ytterbjälklag",
  "42.A": "Sammansatta klimatskiljande delar och kompletteringar i yttervägg",
  "42.A/21":
    "Sammansatta klimatskiljande delar och kompletteringar i yttervägg - murverk",
  "42.B": "Ytterklimatskärmar i yttervägg",
  "42.B/20": "Ytterklimatskärmar i yttervägg - murverk, puts",
  "42.B/35":
    "Ytterklimatskärmar i yttervägg - element av trä eller träbaserat material",
  "42.B/41": "Ytterväggar, ytterklimatskärmar - skivor och stålreglar",
  "42.C": "Innerklimatskärmar i yttervägg",
  "42.C/20": "Innerklimatskärmar i yttervägg - murverk, puts",
  "42.C/35":
    "Innerklimatskärmar i yttervägg - element av trä eller träbaserat material",
  "42.C/36":
    "Innerklimatskärmar i yttervägg - element av skivor och stålregelverk",
  "42.C/37":
    "Innerklimatskärmar i yttervägg - element av skivor och träregelverk eller träbaserade regelverk",
  "42.C/41": "Innerklimatskärmar i yttervägg - skivor och stålregelverk",
  "42.C/42":
    "Innerklimatskärmar i yttervägg - skivor och träregelverk eller träbaserade regelverk",
  "42.D": "Öppningskompletteringar i yttervägg",
  "42.DB": "Fönster",
  "42.DC": "Fönsterpartier, klimatskiljande",
  "42.DD": "Väggpartier, klimatskiljande",
  "42.DE": "Ytterdörrar",
  "42.E": "Ytterväggskompletteringar",
  "42.Z": "Övriga klimatskiljande delar och kompletteringar i yttervägg",
  "43.A": "Sammansatta inre rumsbildande byggdelar",
  "43.B": "Kompletterande väggkonstruktioner",
  "43.B/22": "Kompletterande väggkonstruktioner - puts",
  "43.B/41": "Kompletterande väggkonstruktioner - skivor och stålregelverk",
  "43.B/42":
    "Kompletterande väggkonstruktioner - skivor och träregelverk eller träbaserade regelverk",
  "43.C": "Innerväggar (ej stominnerväggar) och öppningskompletteringar",
  "43.CB": "Innerväggar (ej stominnerväggar)",
  "43.CB/20": "Innerväggar (ej stominnerväggar) - murverk, puts",
  "43.CB/35":
    "Innerväggar (ej stominnerväggar) - element av trä eller träbaserat material",
  "43.CB/37":
    "Innerväggar (ej stominnerväggar) - element av skivor och träregelverk eller träbaserade regelverk",
  "43.CB/40": "Innerväggar (ej stominnerväggar) - skivor och regelverk",
  "43.CB/41": "Innerväggar (ej stominnerväggar) - skivor och stålregelverk",
  "43.CB/42":
    "Innerväggar (ej stominnerväggar) - skivor och träregelverk eller träbaserade regelverk",
  "43.CC": "Öppningskompletteringar i innervägg",
  "43.CCE": "Innerdörrar",
  "43.CCD": "Väggpartier",
  "43.D": "Bjälklagsöverbyggnader och öppningskompletteringar",
  "43.DA": "Sammansatta bjälklagsöverbyggnader och öppningskompletteringar",
  "43.DB": "Golv",
  "43.DB/11": "Golv - platsgjuten betong",
  "43.DC": "Undergolv",
  "43.DD": "Skyddsbeläggningar",
  "43.DE": "Öppningskompletteringar i bjälklag",
  "43.E": "Innertak",
  "43.E/22": "Innertak - puts",
  "43.E/40": "Innertak - skivor och regelverk",
  "43.Z": "Övriga inre rumsbildande byggdelar",
  "44.A": "Sammansatta invändiga ytskikt",
  "44.B": "Ytskikt på golv och trappor",
  "44.BB": "Ytskikt på golv",
  "44.BC": "Ytskikt på trappor",
  "44.C": "Ytskikt på väggar",
  "44.D": "Ytskikt på innertak",
  "44.Z": "Övriga invändiga ytskikt",
  "45.A": "Sammansatta huskompletteringar",
  "45.B": "Utvändiga huskompletteringar",
  "45.BB": "Balkonger",
  "45.BC": "Loftgångar",
  "45.BD": "Skärmtak",
  "45.BE": "Entrétrappor",
  "45.BF": "Fasadstegar",
  "45.BG": "Vindskupor",
  "45.BY": "Diverse huskompletteringar",
  "45.BY/31": "Diverse huskompletteringar - element av betong",
  "45.BY/32": "Diverse huskompletteringar - element av autoklaverad lättbetong",
  "45.BY/33": "Diverse huskompletteringar - element av lättklinkerbetong",
  "45.BY/35":
    "Diverse huskompletteringar - element av trä eller träbaserat material",
  "45.C": "Invändiga huskompletteringar",
  "45.CB": "Invändiga trappor",
  "45.Z": "Övriga huskompletteringar",
  "46.A": "Sammansatta rumskompletteringar",
  "46.B": "Inredningar",
  "46.C": "Utrustningar",
  "46.Z": "Övriga rumskompletteringar",
  "49.B": "Schakt i hus",
  "51.B": "Vattenledningssystem",
  "51.C": "Avloppsledningssystem",
  "51.CB": "Spillvattensystem",
  "51.CC": "Dagvattensystem",
  "51.CD": "Dränvattensystem",
  "51.D": "Ledningssystem för fjärrvärme",
  "51.E": "Ledningssystem för fjärrkyla",
  "51.F": "Ledningssystem för energigas",
  "51.G": "Ledningssystem för olja och drivmedel",
  "52.B": "Tappvattensystem",
  "53.BB": "Spillvattensystem",
  "54.B": "Vattensläcksystem",
  "56.B": "Värmevattensystem",
  "57.B": "Allmänventilationssystem",
  "63.B": "Eldistributionsnät",
  "63.F": "Belysnings- och ljussystem",
  A: "Administrativa föreskrifter",
  AF: "Administrativa föreskrifter",
  AFA: "Allmän Orientering",
  "AFA.1": "Kontaktuppgifter",
  "AFA.11": "Byggherre",
  "AFA.12": "Beställare",
  "AFA.121": "Beställarens kontaktperson under anbudstiden",
  "AFA.122": "Beställarens kontaktperson för visning av arbetsområdet",
  "AFA.13": "Projektörer",
  "AFA.14": "Utsedda entreprenörer",
  "AFA.15": "Nätägare",
  "AFA.2": "Orientering om objektet",
  "AFA.21": "Översiktlig information om objektet",
  "AFA.22": "Objektets läge",
  "AFA.3": "Förkortningar",
  "AFA.4": "Begreppsförklaringar",
  AFB: "UPPHANDLINGSFÖRESKRIFTER",
  "AFB.1": "Former m m för upphandling",
  "AFB.11": "Upphandlingsform",
  "AFB.12": "Upphandlingsförfarande vid offentlig upphandling",
  "AFB.13": "Entreprenadform",
  "AFB.14": "Ersättningsform",
  "AFB.15": "Ersättning för anbudsgivning",
  "AFB.17": "Förutsättningar för upphandlingens genomförande",
  "AFB.2": "Förfrågningsunderlag",
  "AFB.21": "Tillhandahållande av förfrågningsunderlag",
  "AFB.22": "Förteckning över förfrågningsunderlag",
  "AFB.221": "Förfrågningsunderlag för blivande generalentreprenör",
  "AFB.222": "Förfrågningsunderlag för blivande underentreprenörer",
  "AFB.23": "Kompletterande förfrågningsunderlag",
  "AFB.24": "Projektgenomgång under anbudstiden",
  "AFB.25": "Frågor under anbudstiden",
  "AFB.3": "Anbudsgivning",
  "AFB.31": "Anbuds form och innehåll",
  "AFB.311": "Huvudanbud",
  "AFB.312": "Sidoanbud",
  "AFB.313": "Alternativa utföranden",
  "AFB.314": "Kompletteringar till anbud",
  "AFB.32": "Anbudstidens utgång",
  "AFB.33": "Anbuds giltighetstid",
  "AFB.34": "Adressering",
  "AFB.4": "Anbudsöppning",
  "AFB.5": "Prövning av anbudsgivare och anbud",
  "AFB.51": "Uteslutning av anbudsgivare",
  "AFB.511": "Uppgifter från skatte- och kronofogdemyndighet",
  "AFB.52": "Prövning av anbudsgivares lämplighet",
  "AFB.53": "Prövning av anbud",
  "AFB.54": "Meddelande om beslut efter prövning av anbudsgivare och anbud",
  "AFB.55": "Sekretess för anbudshandlingar",
  "AFB.56": "Kontraktstecknande",
  AFC: "Entreprenadföreskrifter vid utförandeentreprenad",
  "AFC.1": "Omfattning",
  "AFC.11": "Kontraktshandlingar",
  "AFC.111": "Sammanställning över ändringar i AB 04 eller AB-U 07",
  "AFC.114": "Undantagna arbeten",
  "AFC.115": "Uppföljning av avtal",
  "AFC.12": "Arbetsområde",
  "AFC.121": "Arbetsområdets gränser",
  "AFC.122": "Syn före påbörjande av arbete",
  "AFC.13": "Förutsättningar",
  "AFC.131": "Uppgifter om sidoentreprenader och andra arbeten",
  "AFC.132": "Arbetstider",
  "AFC.133": "Pågående drift eller verksamhet inom och invid arbetsområdet",
  "AFC.1331": "Förutsättningar med hänsyn till kvarboende och lokalnyttjare",
  "AFC.134": "Förutsättningar med hänsyn till befintliga byggnader",
  "AFC.135": "Förutsättningar med hänsyn till vägtrafik",
  "AFC.136": "Förutsättningar med hänsyn till spårtrafik",
  "AFC.137": "Förutsättningar med hänsyn till sjöfart",
  "AFC.138": "Förutsättningar med hänsyn till luftfart",
  "AFC.14": "Skydds- och säkerhetsföreskrifter m m",
  "AFC.141":
    "Skydds- och säkerhetsföreskrifter vid arbete inom byggnad, industri, bergrum m m",
  "AFC.142":
    "Skydds- och säkerhetsföreskrifter vid arbete i anslutning till väg",
  "AFC.143":
    "Skydds- och säkerhetsföreskrifter vid arbete i anslutning till spår",
  "AFC.144":
    "Skydds- och säkerhetsföreskrifter vid arbete i anslutning till sjöled",
  "AFC.145":
    "Skydds- och säkerhetsföreskrifter vid arbete i anslutning till flygplats",
  "AFC.146":
    "Skydds- och säkerhetsföreskrifter med hänsyn till militär verksamhet",
  "AFC.15": "Varor m m",
  "AFC.151": "Varor från entreprenören",
  "AFC.152": "Varor eller arbeten från beställaren",
  "AFC.1521": "Förbeställda varor eller arbeten",
  "AFC.1522": "Varor som tillhandahålls",
  "AFC.1523": "Tillval",
  "AFC.157": "Utbildning och support",
  "AFC.158": "Utveckling av varor och metoder",
  "AFC.16": "Tillstånd m m",
  "AFC.161": "Tillstånd från myndigheter",
  "AFC.162": "Myndighetsbesiktning",
  "AFC.163": "Överenskommelser m m",
  "AFC.17": "Anmälningar",
  "AFC.171": "Anmälningar till myndigheter",
  "AFC.172": "Anmälningar till beställaren",
  "AFC.18": "Författningar",
  "AFC.181": "Tillsyn och kontroll enligt PBL",
  "AFC.1811": "Kontrollplan enligt PBL",
  "AFC.1812": "Kontrollansvarig enligt PBL",
  "AFC.1813": "Samordning av kontrollansvariga enligt PBL",
  "AFC.183": "Ansvar för byggarbetsmiljö",
  "AFC.1831": "Arbetsmiljöplan",
  "AFC.1832":
    "Byggarbetsmiljösamordnare för planering och projektering (BAS-P)",
  "AFC.1833": "Byggarbetsmiljösamordnare för utförande (BAS-U)",
  "AFC.1834": "Upplysning om byggarbetsmiljösamordnare",
  "AFC.184": "Skyddsombud m fl",
  "AFC.185": "CE-märkning av sammansatta maskinanläggningar",
  "AFC.1851":
    "CE-märkning av hiss, maskinanläggning och tryckbärande anordning",
  "AFC.1852": "CE-märkning av hiss",
  "AFC.1853": "CE-märkning av maskinanläggning",
  "AFC.1854": "CE-märkning av tryckbärande anordning",
  "AFC.186": "Underlag för CE-märkning av sammansatta maskinanläggningar",
  "AFC.187": "Registrering enligt elsäkerhetslagen",
  "AFC.2": "Utförande",
  "AFC.21": "Kvalitetsangivelser",
  "AFC.22": "Kvalitets- och miljöarbete",
  "AFC.221": "Kvalitetsledning",
  "AFC.222": "Miljöledning",
  "AFC.2221": "Miljöcertifiering av byggnadsobjekt",
  "AFC.223": "Beställarens kvalitets- och miljöplan",
  "AFC.224": "Entreprenörens kvalitets- och miljöplan",
  "AFC.225": "Kvalitets- och miljörevision",
  "AFC.2251": "Beställarens kvalitets- och miljörevision",
  "AFC.2252": "Entreprenörens kvalitets- och miljörevision",
  "AFC.226": "Riskhantering",
  "AFC.23": "ÄTA-arbeten",
  "AFC.24": "Tillhandahållande av handlingar",
  "AFC.241":
    "Tillhandahållande av handlingar och uppgifter från beställaren under entreprenadtiden",
  "AFC.242":
    "Tillhandahållande av handlingar och uppgifter från entreprenören under entreprenadtiden",
  "AFC.25": "Beställarens krav på sekretess",
  "AFC.26": "Information",
  "AFC.262": "Beställarens informationsverksamhet",
  "AFC.263": "Information till fastighetsägare, boende m fl",
  "AFC.264": "Information till väghållare m fl",
  "AFC.27": "Underrättelser om avvikelser o d",
  "AFC.28": "Entreprenörens kontroll",
  "AFC.3": "Organisation",
  "AFC.31": "Beställarens organisation",
  "AFC.311": "Beställarens ombud",
  "AFC.312": "Beställarens projektledare m fl",
  "AFC.313": "Beställarens kontrollant",
  "AFC.314": "Beställarens kvalitetsansvarige",
  "AFC.315": "Beställarens miljöansvarige",
  "AFC.316": "Beställarens informationsansvarige",
  "AFC.318": "Beställarens övriga befattningshavare",
  "AFC.32": "Entreprenörens organisation",
  "AFC.321": "Entreprenörens ombud",
  "AFC.323": "Entreprenörens arbetschef, platschef m fl",
  "AFC.324": "Entreprenörens kvalitetsansvarige",
  "AFC.325": "Entreprenörens miljöansvarige",
  "AFC.328": "Entreprenörens övriga befattningshavare",
  "AFC.33": "Möten",
  "AFC.331": "Startmöte",
  "AFC.332": "Projekteringsmöten",
  "AFC.333": "Byggmöten",
  "AFC.338": "Övriga möten",
  "AFC.34": "Arbetsledning och anställda",
  "AFC.342": "Arbetsledning",
  "AFC.343":
    "Allmänna bestämmelser om legitimationsplikt och närvaroredovisning, ID06",
  "AFC.344": "Personalförteckning och legitimationsplikt",
  "AFC.345": "Elektronisk personalliggare",
  "AFC.3451": "Upplysning om elektronisk personalliggare",
  "AFC.346": "Anställda",
  "AFC.35": "Underentreprenörer",
  "AFC.36": "Beställarens kontroll",
  "AFC.37": "Samordning",
  "AFC.371": "Samordning av arbeten",
  "AFC.372": "Samordning av installationer",
  "AFC.373": "Upplysning om samordning av installationer",
  "AFC.38": "Dagbok",
  "AFC.39": "Uppmätning",
  "AFC.4": "Tider",
  "AFC.41": "Tidplan",
  "AFC.42": "Igångsättningstid",
  "AFC.43": "Avrop",
  "AFC.44": "Deltider",
  "AFC.45": "Färdigställandetider",
  "AFC.46": "Förändring av kontraktstiden",
  "AFC.47": "Garantitid",
  "AFC.471": "Garantitid för entreprenaden",
  "AFC.472": "Särskild varugaranti",
  "AFC.5": "Ansvar och avhjälpande",
  "AFC.51": "Vite",
  "AFC.511": "Vite vid försening",
  "AFC.518": "Övriga viten",
  "AFC.52": "Bonus",
  "AFC.521": "Tidsbonus",
  "AFC.53": "Ansvar mot tredje man",
  "AFC.531": "Syn inom närliggande område",
  "AFC.532": "Syn i lägenheter, lokaler m m",
  "AFC.54": "Försäkringar",
  "AFC.541": "Försäkringar under garantitiden",
  "AFC.542": "Försäkring för särskilt riskfyllda arbeten",
  "AFC.543": "Försäkring avseende befintlig egendom",
  "AFC.5431": "Försäkring avseende byggherrens befintliga egendom",
  "AFC.5432":
    "Försäkring avseende egendom tillhörig nyttjanderättshavare som inte är konsument",
  "AFC.5433":
    "Försäkring avseende egendom tillhörig nyttjanderättshavare som är konsument",
  "AFC.544": "Byggfelsförsäkring",
  "AFC.545": "Byggherreansvarsförsäkring vid miljöskada",
  "AFC.547": "Färdigställandeskydd",
  "AFC.55": "Ansvar för brandskydd",
  "AFC.551": "Ansvar för brandfarliga heta arbeten",
  "AFC.5511": "Beställarens tillståndsansvarige",
  "AFC.5512": "Entreprenörens tillståndsansvarige",
  "AFC.5513": "Samordning av tillståndsansvariga",
  "AFC.56": "Avsyning",
  "AFC.57": "Avhjälpande",
  "AFC.58": "Ansvar efter garantitiden",
  "AFC.59": "Entreprenörsansvar för löner",
  "AFC.6": "Ekonomi",
  "AFC.61": "Ersättning",
  "AFC.611": "Ersättning för ÄTA-arbeten",
  "AFC.612": "Ersättning för reglerbara mängder",
  "AFC.614": "Ersättning för kostnadsändring (indexreglering)",
  "AFC.615": "Slutavräkning",
  "AFC.62": "Betalning",
  "AFC.621": "Betalningsansvar",
  "AFC.622": "Betalningsplan",
  "AFC.623": "Förskott",
  "AFC.624": "Fakturering",
  "AFC.625": "Dröjsmålsränta",
  "AFC.63": "Säkerhet",
  "AFC.631": "Säkerhet till beställaren",
  "AFC.6311": "Säkerhet till byggherren",
  "AFC.632": "Säkerhet till entreprenören",
  "AFC.6321": "Säkerhet till generalentreprenören",
  "AFC.7": "Besiktning",
  "AFC.71": "Entreprenadbesiktningar",
  "AFC.711": "Besiktningsplan",
  "AFC.712": "Förbesiktning",
  "AFC.713": "Slutbesiktning",
  "AFC.714": "Garantibesiktning",
  "AFC.715": "Särskild besiktning",
  "AFC.716": "Efterbesiktning",
  "AFC.717": "Överbesiktning",
  "AFC.718": "Besiktningsman",
  "AFC.72": "Besiktningssammanträden",
  "AFC.79": "Övriga besiktningar",
  "AFC.8": "Hävning",
  "AFC.9": "Tvistelösning",
  "AFC.91": "Förenklad tvistelösning",
  AFD: "ENTREPRENADFÖRESKRIFTER VID TOTALENTREPRENAD",
  "AFD.1": "Omfattning",
  "AFD.11": "Kontraktshandlingar",
  "AFD.111": "Sammanställning över ändringar i ABT 06 eller ABT-U 07",
  "AFD.113": "Ansvar för uppgifter",
  "AFD.114": "Undantagna arbeten",
  "AFD.115": "Uppföljning av avtal",
  "AFD.12": "Arbetsområde",
  "AFD.121": "Arbetsområdets gränser",
  "AFD.122": "Syn före påbörjande av arbete",
  "AFD.13": "Förutsättningar",
  "AFD.131": "Uppgifter om sidoentreprenader och andra arbeten",
  "AFD.132": "Arbetstider",
  "AFD.133": "Pågående drift eller verksamhet inom och invid arbetsområdet",
  "AFD.1331": "Förutsättningar med hänsyn till kvarboende och lokalnyttjare",
  "AFD.134": "Förutsättningar med hänsyn till befintliga byggnader",
  "AFD.135": "Förutsättningar med hänsyn till vägtrafik",
  "AFD.136": "Förutsättningar med hänsyn till spårtrafik",
  "AFD.137": "Förutsättningar med hänsyn till sjöfart",
  "AFD.138": "Förutsättningar med hänsyn till luftfart",
  "AFD.14": "Skydds- och säkerhetsföreskrifter m m",
  "AFD.141":
    "Skydds- och säkerhetsföreskrifter vid arbete inom byggnad, industri, bergrum m m",
  "AFD.142":
    "Skydds- och säkerhetsföreskrifter vid arbete i anslutning till väg",
  "AFD.143":
    "Skydds- och säkerhetsföreskrifter vid arbete i anslutning till spår",
  "AFD.144":
    "Skydds- och säkerhetsföreskrifter vid arbete i anslutning till sjöled",
  "AFD.145":
    "Skydds- och säkerhetsföreskrifter vid arbete i anslutning till flygplats",
  "AFD.146":
    "Skydds- och säkerhetsföreskrifter med hänsyn till militär verksamhet",
  "AFD.15": "Varor m m",
  "AFD.151": "Varor från entreprenören",
  "AFD.152": "Varor eller arbeten från beställaren",
  "AFD.1521": "Förbeställda varor eller arbeten",
  "AFD.1522": "Varor som tillhandahålls",
  "AFD.1523": "Tillval",
  "AFD.157": "Utbildning och support",
  "AFD.158": "Utveckling av varor och metoder",
  "AFD.16": "Tillstånd m m",
  "AFD.161": "Tillstånd från myndigheter",
  "AFD.162": "Myndighetsbesiktning",
  "AFD.163": "Överenskommelser m m",
  "AFD.17": "Anmälningar",
  "AFD.171": "Anmälningar till myndigheter",
  "AFD.172": "Anmälningar till beställaren",
  "AFD.18": "Författningar",
  "AFD.181": "Tillsyn och kontroll enligt PBL",
  "AFD.1811": "Kontrollplan enligt PBL",
  "AFD.1812": "Kontrollansvarig enligt PBL",
  "AFD.1813": "Samordning av kontrollansvariga enligt PBL",
  "AFD.183": "Ansvar för byggarbetsmiljö",
  "AFD.1831": "Arbetsmiljöplan",
  "AFD.1832":
    "Byggarbetsmiljösamordnare för planering och projektering (BAS-P)",
  "AFD.1833": "Byggarbetsmiljösamordnare för utförande (BAS-U)",
  "AFD.1834": "Upplysning om byggarbetsmiljösamordnare",
  "AFD.184": "Skyddsombud m fl",
  "AFD.185": "CE-märkning av sammansatta maskinanläggningar",
  "AFD.1851":
    "CE-märkning av hiss, maskinanläggning och tryckbärande anordning",
  "AFD.1852": "CE-märkning av hiss",
  "AFD.1853": "CE-märkning av maskinanläggning",
  "AFD.1854": "CE-märkning av tryckbärande anordning",
  "AFD.186": "Underlag för CE-märkning av sammansatta maskinanläggningar",
  "AFD.187": "Registrering enligt elsäkerhetslagen",
  "AFD.2": "Utförande",
  "AFD.21": "Kvalitetsangivelser",
  "AFD.22": "Kvalitets- och miljöarbete",
  "AFD.221": "Kvalitetsledning",
  "AFD.222": "Miljöledning",
  "AFD.2221": "Miljöcertifiering av byggnadsobjekt",
  "AFD.223": "Beställarens kvalitets- och miljöplan",
  "AFD.224": "Entreprenörens kvalitets- och miljöplan",
  "AFD.225": "Kvalitets- och miljörevision",
  "AFD.2251": "Beställarens kvalitets- och miljörevision",
  "AFD.2252": "Entreprenörens kvalitets- och miljörevision",
  "AFD.226": "Riskhantering",
  "AFD.23": "ÄTA-arbeten",
  "AFD.24": "Tillhandahållande av handlingar",
  "AFD.241":
    "Tillhandahållande av handlingar och uppgifter från beställaren under entreprenadtiden",
  "AFD.242":
    "Tillhandahållande av handlingar och uppgifter från entreprenören under entreprenadtiden",
  "AFD.2421": "Redovisning av projekteringsplan",
  "AFD.2422": "Arkivering av handlingar m m",
  "AFD.25": "Beställarens krav på sekretess",
  "AFD.26": "Information",
  "AFD.262": "Beställarens informationsverksamhet",
  "AFD.263": "Information till fastighetsägare, boende m fl",
  "AFD.264": "Information till väghållare m fl",
  "AFD.27": "Underrättelser om avvikelser o d",
  "AFD.28": "Entreprenörens kontroll",
  "AFD.3": "Organisation",
  "AFD.31": "Beställarens organisation",
  "AFD.311": "Beställarens ombud",
  "AFD.312": "Beställarens projektledare m fl",
  "AFD.313": "Beställarens kontrollant",
  "AFD.314": "Beställarens kvalitetsansvarige",
  "AFD.315": "Beställarens miljöansvarige",
  "AFD.316": "Beställarens informationsansvarige",
  "AFD.318": "Beställarens övriga befattningshavare",
  "AFD.32": "Entreprenörens organisation",
  "AFD.321": "Entreprenörens ombud",
  "AFD.322": "Entreprenörens projekteringsledare",
  "AFD.323": "Entreprenörens arbetschef, platschef m fl",
  "AFD.324": "Entreprenörens kvalitetsansvarige",
  "AFD.325": "Entreprenörens miljöansvarige",
  "AFD.328": "Entreprenörens övriga befattningshavare",
  "AFD.33": "Möten",
  "AFD.331": "Startmöte",
  "AFD.332": "Projekteringsmöten",
  "AFD.333": "Byggmöten",
  "AFD.338": "Övriga möten",
  "AFD.34": "Projekteringsledning, arbetsledning och anställda",
  "AFD.341": "Projekteringsledning",
  "AFD.342": "Arbetsledning",
  "AFD.343":
    "Allmänna bestämmelser om legitimationsplikt och närvaroredovisning, ID06",
  "AFD.344": "Personalförteckning och legitimationsplikt",
  "AFD.345": "Elektronisk personalliggare",
  "AFD.3451": "Upplysning om elektronisk personalliggare",
  "AFD.346": "Anställda",
  "AFD.35": "Underentreprenörer",
  "AFD.36": "Beställarens kontroll",
  "AFD.37": "Samordning",
  "AFD.371": "Samordning av arbeten",
  "AFD.38": "Dagbok",
  "AFD.39": "Uppmätning",
  "AFD.4": "Tider",
  "AFD.41": "Tidplan",
  "AFD.42": "Igångsättningstid",
  "AFD.43": "Avrop",
  "AFD.44": "Deltider",
  "AFD.45": "Färdigställandetider",
  "AFD.46": "Förändring av kontraktstiden",
  "AFD.47": "Garantitid",
  "AFD.471": "Garantitid för entreprenaden",
  "AFD.472": "Särskild varugaranti",
  "AFD.5": "Ansvar och avhjälpande",
  "AFD.51": "Vite",
  "AFD.511": "Vite vid försening",
  "AFD.518": "Övriga viten",
  "AFD.52": "Bonus",
  "AFD.521": "Tidsbonus",
  "AFD.522": "Prestandabonus",
  "AFD.53": "Ansvar mot tredje man",
  "AFD.531": "Syn inom närliggande område",
  "AFD.532": "Syn i lägenheter, lokaler m m",
  "AFD.54": "Försäkringar",
  "AFD.541": "Försäkringar under garantitiden",
  "AFD.542": "Försäkring för särskilt riskfyllda arbeten",
  "AFD.543": "Försäkring avseende befintlig egendom",
  "AFD.5431": "Försäkring avseende byggherrens befintliga egendom",
  "AFD.5432":
    "Försäkring avseende egendom tillhörig nyttjanderättshavare som inte är konsument",
  "AFD.5433":
    "Försäkring avseende egendom tillhörig nyttjanderättshavare som är konsument",
  "AFD.544": "Byggfelsförsäkring",
  "AFD.545": "Byggherreansvarsförsäkring vid miljöskada",
  "AFD.547": "Färdigställandeskydd",
  "AFD.55": "Ansvar för brandskydd",
  "AFD.551": "Ansvar för brandfarliga heta arbeten",
  "AFD.5511": "Beställarens tillståndsansvarige",
  "AFD.5512": "Entreprenörens tillståndsansvarige",
  "AFD.5513": "Samordning av tillståndsansvariga",
  "AFD.56": "Avsyning",
  "AFD.57": "Avhjälpande",
  "AFD.58": "Ansvar efter garantitiden",
  "AFD.59": "Entreprenörsansvar för löner",
  "AFD.6": "Ekonomi",
  "AFD.61": "Ersättning",
  "AFD.611": "Ersättning för ÄTA-arbeten",
  "AFD.612": "Ersättning för reglerbara mängder",
  "AFD.614": "Ersättning för kostnadsändring (indexreglering)",
  "AFD.615": "Slutavräkning",
  "AFD.62": "Betalning",
  "AFD.621": "Betalningsansvar",
  "AFD.622": "Betalningsplan",
  "AFD.623": "Förskott",
  "AFD.624": "Fakturering",
  "AFD.625": "Dröjsmålsränta",
  "AFD.63": "Säkerhet",
  "AFD.631": "Säkerhet till beställaren",
  "AFD.632": "Säkerhet till entreprenören",
  "AFD.7": "Besiktning",
  "AFD.71": "Entreprenadbesiktningar",
  "AFD.711": "Besiktningsplan",
  "AFD.712": "Förbesiktning",
  "AFD.713": "Slutbesiktning",
  "AFD.714": "Garantibesiktning",
  "AFD.715": "Särskild besiktning",
  "AFD.716": "Efterbesiktning",
  "AFD.717": "Överbesiktning",
  "AFD.718": "Besiktningsman",
  "AFD.72": "Besiktningssammanträden",
  "AFD.79": "Övriga besiktningar",
  "AFD.8": "Hävning",
  "AFD.9": "Tvistelösning",
  "AFD.91": "Förenklad tvistelösning",
  AFG: "ALLMÄNNA ARBETEN OCH HJÄLPMEDEL",
  "AFG.1": "Etablering av arbetsplats",
  "AFG.11": "Placering av allmänna hjälpmedel",
  "AFG.111": "Placeringsritning som upprättas av entreprenören",
  "AFG.112": "Placeringsritning som tillhandahålls",
  "AFG.12": "Bodar",
  "AFG.121": "Personalbod och toalett",
  "AFG.1211": "Personalbod och toalett för sidoentreprenör",
  "AFG.1212": "Personalbod och toalett som tillhandahålls",
  "AFG.122": "Förläggningsbod",
  "AFG.1221": "Förläggningsbod för sidoentreprenör",
  "AFG.1222": "Förläggningsbod som tillhandahålls",
  "AFG.124": "Kontorsbod",
  "AFG.1241": "Kontorsbod för sidoentreprenör",
  "AFG.1242": "Kontorsbod som tillhandahålls",
  "AFG.1243": "Kontorsbod för beställaren",
  "AFG.1244": "Plats i kontorsbod för beställaren",
  "AFG.126": "Förrådsbod",
  "AFG.1261": "Förrådsbod för sidoentreprenör",
  "AFG.1262": "Förrådsbod som tillhandahålls",
  "AFG.127": "Utrymme",
  "AFG.1271": "Utrymme i byggnad under uppförande vilket tillhandahålls",
  "AFG.1272": "Utrymme i befintlig byggnad vilket tillhandahålls",
  "AFG.1273":
    "Utrymme i befintlig byggnad vilket tillhandahålls mot ersättning",
  "AFG.128": "Övriga bodar",
  "AFG.13": "Tillfällig väg och plan",
  "AFG.131": "Tillfällig väg och plan för sidoentreprenör",
  "AFG.132": "Tillfällig väg och plan som tillhandahålls",
  "AFG.14": "Tillfällig el- och va-försörjning",
  "AFG.141": "Tillfällig elförsörjning",
  "AFG.1411": "Tillfällig elförsörjning för sidoentreprenör",
  "AFG.1412": "Tillfällig elförsörjning som tillhandahålls",
  "AFG.142": "Tillfällig va-försörjning",
  "AFG.1421": "Tillfällig va-försörjning för sidoentreprenör",
  "AFG.1422": "Tillfällig va-försörjning som tillhandahålls",
  "AFG.15": "Tillfällig kommunikationsutrustning",
  "AFG.151": "Tillfällig kommunikationsutrustning för sidoentreprenör",
  "AFG.152": "Tillfällig kommunikationsutrustning som tillhandahålls",
  "AFG.16": "Tillfällig skyltställning och tillfällig orienteringstavla",
  "AFG.17": "Tillfällig flaggstång",
  "AFG.2": "Inmätning och utsättning",
  "AFG.21": "Mätutrustning",
  "AFG.22": "Inmätning",
  "AFG.221": "Inmätning vid anläggningsbyggnad",
  "AFG.2211": "Inmätning för sidoentreprenör vid anläggningsbyggnad",
  "AFG.2212": "Inmätning som tillhandahålls vid anläggningsbyggnad",
  "AFG.222": "Inmätning vid husbyggnad",
  "AFG.2221": "Inmätning för sidoentreprenör vid husbyggnad",
  "AFG.2222": "Inmätning som tillhandahålls vid husbyggnad",
  "AFG.23": "Utsättning",
  "AFG.231": "Utsättning vid anläggningsbyggnad",
  "AFG.2311": "Utsättning för sidoentreprenör vid anläggningsbyggnad",
  "AFG.2312": "Utsättning som tillhandahålls vid anläggningsbyggnad",
  "AFG.232": "Utsättning vid husbyggnad",
  "AFG.2321": "Utsättning för sidoentreprenör vid husbyggnad",
  "AFG.2322": "Utsättning som tillhandahålls vid husbyggnad",
  "AFG.3": "Skydd m m",
  "AFG.31": "Skydd av arbete och egendom m m",
  "AFG.311": "Skydd av arbete",
  "AFG.312": "Skydd av ledning, mätpunkt m m",
  "AFG.313": "Skydd av vegetation",
  "AFG.314": "Skydd av fornminne",
  "AFG.315": "Skydd av egendom",
  "AFG.316": "Tillfällig inhägnad",
  "AFG.32": "Skyddsanordningar",
  "AFG.321": "Skyddsanordningar för sidoentreprenör",
  "AFG.322": "Skyddsanordningar som tillhandahålls",
  "AFG.33": "Vakthållning",
  "AFG.34": "Bullerskydd",
  "AFG.35": "Dammskydd",
  "AFG.36": "Begränsning av miljöstörande utsläpp",
  "AFG.4": "Leverans, transport m m",
  "AFG.41": "Leverans av varor till arbetsplatsen",
  "AFG.411": "Leveransplan",
  "AFG.412": "Lossningsplan",
  "AFG.413": "Märkning",
  "AFG.414": "Avisering av leverans och restleverans",
  "AFG.415": "Leveranssätt",
  "AFG.416": "Förpackningar och lastbärare",
  "AFG.4161": "Retursystem byggpall",
  "AFG.42": "Lossning",
  "AFG.421": "Lossning för sidoentreprenör",
  "AFG.422": "Lossning som tillhandahålls",
  "AFG.423": "Mottagningskontroll",
  "AFG.424": "Mellanlagring",
  "AFG.43": "Transport inom arbetsområdet",
  "AFG.431": "Transportöppningar",
  "AFG.432": "Transport för sidoentreprenör",
  "AFG.433": "Transport som tillhandahålls",
  "AFG.44": "Lyftanordningar",
  "AFG.441": "Permanent hiss",
  "AFG.4411": "Permanent hiss som tillhandahålls",
  "AFG.442": "Bygghiss",
  "AFG.4421": "Bygghiss för sidoentreprenör",
  "AFG.4422": "Bygghiss som tillhandahålls",
  "AFG.443": "Stationär kran",
  "AFG.4431": "Stationär kran för sidoentreprenör",
  "AFG.4432": "Stationär kran som tillhandahålls",
  "AFG.444": "Mobilkran",
  "AFG.4441": "Mobilkran för sidoentreprenör",
  "AFG.4442": "Mobilkran som tillhandahålls",
  "AFG.448": "Övriga lyftanordningar",
  "AFG.4481": "Övriga lyftanordningar för sidoentreprenör",
  "AFG.4482": "Övriga lyftanordningar som tillhandahålls",
  "AFG.5": "Arbetsställningar och montering",
  "AFG.51": "Arbetsställningar",
  "AFG.511": "Ställningar för sidoentreprenör",
  "AFG.512": "Ställningar som tillhandahålls",
  "AFG.513": "Specificerade ställningar för sidoentreprenör",
  "AFG.514": "Specificerade ställningar som tillhandahålls",
  "AFG.52": "Montering",
  "AFG.521": "Montering av tillhandahållet infästningsgods m m",
  "AFG.522": "Montering för sidoentreprenör",
  "AFG.523": "Montering som tillhandahålls",
  "AFG.6": "Ursparning, håltagning, igensättning och tätning",
  "AFG.61": "Ursparning och håltagning",
  "AFG.611": "Samordning av ursparning och håltagning",
  "AFG.612": "Upplysning om samordning av ursparning och håltagning",
  "AFG.613": "Ursparning och håltagning för sidoentreprenör",
  "AFG.614": "Ursparning och håltagning som tillhandahålls",
  "AFG.62": "Igensättning och tätning",
  "AFG.621": "Samordning av igensättning och tätning",
  "AFG.622": "Upplysning om samordning av igensättning och tätning",
  "AFG.623": "Igensättning och tätning för sidoentreprenör",
  "AFG.624": "Igensättning och tätning som tillhandahålls",
  "AFG.7": "Uppvärmning, uttorkning och väderberoende arbeten m m",
  "AFG.71": "Uppvärmning och uttorkning",
  "AFG.72": "Värme som tillhandahålls mot ersättning",
  "AFG.73": "Skötsel av permanent värmeanläggning",
  "AFG.74": "Uppvärmning och uttorkning som tillhandahålls",
  "AFG.75": "Väderberoende arbeten",
  "AFG.751": "Väderskydd",
  "AFG.752": "Snöröjning",
  "AFG.7521": "Snöröjning för sidoentreprenör",
  "AFG.7522": "Snöröjning som tillhandahålls",
  "AFG.8": "Länshållning, renhållning, rengöring m m",
  "AFG.81": "Länshållning",
  "AFG.811": "Länshållning för sidoentreprenör",
  "AFG.812": "Länshållning som tillhandahålls",
  "AFG.82": "Renhållning",
  "AFG.821": "Uppsamling och bortforsling av avfall för sidoentreprenör",
  "AFG.822": "Uppsamling och bortforsling av avfall som tillhandahålls",
  "AFG.83": "Städning och slutrengöring",
  "AFG.831": "Städning",
  "AFG.832": "Slutrengöring",
  "AFG.8321": "Slutrengöring för sidoentreprenör",
  "AFG.8322": "Slutrengöring som tillhandahålls",
  "AFG.84": "Ohyres- och insektsbekämpning",
  "AFG.85": "Återställande av mark",
  B: "FÖRARBETEN, HJÄLPARBETEN, SANERINGSARBETEN, FLYTTNING, DEMONTERING, RIVNING, RÖJNING M M",
  BB: "FÖRARBETEN",
  BBB: "UTFÖRDA UNDERSÖKNINGAR O D",
  "BBB.1": "Mark- och vattenförhållanden m m",
  "BBB.11": "Topografiska förhållanden",
  "BBB.12": "Jordmåns- och vegetationsförhållanden",
  "BBB.13": "Geotekniska förhållanden",
  "BBB.131": "Geotekniska förhållanden i jord",
  "BBB.132": "Geotekniska förhållanden i berg",
  "BBB.14": "Hydrogeologiska förhållanden",
  "BBB.15": "Föroreningar",
  "BBB.16": "Markgasförhållanden",
  "BBB.17": "Utförda inventeringar av skaderisker",
  "BBB.18":
    "Diverse utförda undersökningar av mark- och vattenförhållanden m m",
  "BBB.181":
    "Utförda undersökningar av förekomst av föremål på markyta eller sjöbotten",
  "BBB.1812": "Utförda undersökningar av förekomst av föremål på sjöbotten",
  "BBB.2": "Utförda provarbeten",
  "BBB.21": "Utförd provbelastning, provdragning",
  "BBB.22": "Utförd provpålning",
  "BBB.23": "Utförd provschaktning",
  "BBB.24": "Utförd provsprängning",
  "BBB.25": "Utförd provpumpning",
  "BBB.26": "Utfört vatteninfiltrationsförsök",
  "BBB.3": "Befintliga anläggningar m m",
  "BBB.31": "Befintliga grundkonstruktioner",
  "BBB.32": "Befintliga ledningar, kablar m m",
  "BBB.33": "Befintliga bergtunnlar, bergrum m m",
  "BBB.34": "Befintliga vattentäkter",
  "BBB.35": "Fornminnen",
  "BBB.36": "Befintliga vägar, planer o d samt spåranläggningar",
  "BBB.361": "Befintliga vägar, planer o d",
  "BBB.362": "Befintliga spåranläggningar",
  "BBB.37":
    "Befintliga broar, bryggor, kajer, tunnlar, kammare, master, murar o d",
  "BBB.38": "Diverse befintliga anläggningar m m",
  "BBB.381": "Befintliga energibrunnar",
  "BBB.4": "Utförda undersökningar av hus",
  BBC: "UNDERSÖKNINGAR O D",
  "BBC.1": "Undersökningar av mark- och vattenförhållanden m m",
  "BBC.11": "Avvägning, pejling, deformationsmätning m m",
  "BBC.111": "Avvägning",
  "BBC.112": "Pejling",
  "BBC.113": "Vibrationsmätning m m",
  "BBC.114": "Inklinometermätning",
  "BBC.115": "Slangsättningsmätning",
  "BBC.116": "Bälgslangsmätning",
  "BBC.117": "Bullermätning",
  "BBC.12": "Jordmåns- och vegetationsundersökning",
  "BBC.13": "Geoteknisk undersökning",
  "BBC.131": "Geoteknisk undersökning i jord",
  "BBC.132": "Geoteknisk undersökning i berg",
  "BBC.1321": "Kärnborrning",
  "BBC.1322": "Sonderingsborrning",
  "BBC.14": "Hydrogeologisk undersökning",
  "BBC.15": "Föroreningsundersökning",
  "BBC.16": "Markgasundersökningar",
  "BBC.17": "Inventering av skaderisker",
  "BBC.18": "Diverse undersökningar av mark- och vattenförhållanden m m",
  "BBC.181":
    "Undersökningar av förekomst av föremål på markyta eller sjöbotten",
  "BBC.1811": "Undersökningar av förekomst av föremål på markyta",
  "BBC.1812": "Undersökningar av förekomst av föremål på sjöbotten",
  "BBC.2": "Provarbeten",
  "BBC.21": "Provbelastning, provdragning",
  "BBC.211": "Provbelastning av jordterrass",
  "BBC.212": "Provbelastning av befintlig mark",
  "BBC.213": "Provdragning av förankringar",
  "BBC.214": "Provdragning av jordspikar",
  "BBC.22": "Provpålning",
  "BBC.23": "Provschaktning",
  "BBC.24": "Provsprängning",
  "BBC.25": "Provpumpning",
  "BBC.26": "Vatteninfiltrationsförsök",
  "BBC.27": "Provborrning",
  "BBC.28": "Diverse provningar",
  "BBC.281": "Förprovningspelare",
  "BBC.3": "Undersökningar av anläggningar m m",
  "BBC.31": "Undersökningar av grundkonstruktioner",
  "BBC.32": "Undersökningar av ledningar, kablar m m",
  "BBC.33": "Undersökningar av bergtunnlar, bergrum m m",
  "BBC.34": "Undersökningar av vattentäkter",
  "BBC.35": "Undersökningar av fornminnen",
  "BBC.36": "Undersökningar av vägar, planer o d samt spåranläggningar",
  "BBC.361": "Undersökningar av vägar, planer o d",
  "BBC.362": "Undersökningar av spåranläggningar",
  "BBC.37":
    "Undersökningar av broar, bryggor, kajer, tunnlar, kammare, master, murar o d",
  "BBC.38": "Undersökningar av diverse anläggningar m m",
  "BBC.381": "Undersökningar av energibrunnar",
  "BBC.4": "Undersökningar av hus",
  BC: "HJÄLPARBETEN, TILLFÄLLIGA ANORDNINGAR OCH ÅTGÄRDER M M",
  BCB: "HJÄLPARBETEN I ANLÄGGNING",
  "BCB.1": "Hantering av vatten",
  "BCB.12": "Tillfällig grundvattenhöjning",
  "BCB.121": "Tillfällig grundvattenhöjning genom infiltration",
  "BCB.13": "Tillfällig grundvattensänkning eller portryckssänkning",
  "BCB.131": "Tillfällig grundvattensänkning med dränerande lager",
  "BCB.132": "Tillfällig grundvattensänkning med brunnar, spetsar o d",
  "BCB.133": "Tillfällig grundvattensänkning med pumpgropar o d",
  "BCB.14": "Tillfällig avledning av vatten från byggproduktion",
  "BCB.15": "Tillfällig avledning av dagvatten",
  "BCB.16": "Tillfällig avledning av ytvatten",
  "BCB.161": "Tillfällig avledning av dike, bäck o d",
  "BCB.2": "Tillfälliga åtgärder på angränsande byggnad eller anläggning",
  "BCB.21": "Tillfällig förstärkning av grundkonstruktion",
  "BCB.22": "Tillfällig förstärkning av bärverk, stomme o d",
  "BCB.3": "Tillfälliga åtgärder för skydd m m av ledning och kabel",
  "BCB.31": "Åtgärd för rörledning i mark",
  "BCB.32": "Åtgärd för el- och telekablar o d i mark",
  "BCB.33": "Åtgärd för luftledning",
  "BCB.4":
    "Tillfälliga skydd av mark, vegetation, mätpunkt, gränsmarkering m m",
  "BCB.41": "Skyddsplank, skyddsinhägnad o d",
  "BCB.411": "Skyddsplank",
  "BCB.412": "Skyddsinhägnad av träd",
  "BCB.413": "Skyddsinhägnad av vegetationsytor",
  "BCB.414": "Skyddsinhägnad av arbetsområde",
  "BCB.42": "Avspärrning av markyta",
  "BCB.43": "Inbrädning av träd, påkörningsskydd",
  "BCB.44": "Skydd av markyta i träds och buskars rotzon",
  "BCB.45": "Åtgärd för mätpunkt, gränsmarkering o d",
  "BCB.5": "Åtgärd vid skada på vegetation",
  "BCB.51": "Åtgärd i träds och buskars rotzon",
  "BCB.52": "Åtgärd i trädkrona",
  "BCB.6": "Skyddsåtgärder vid arbete i förorenade områden",
  "BCB.7": "Åtgärd för allmän trafik",
  "BCB.71": "Åtgärd för vägtrafik",
  "BCB.711": "Tillfällig väg, plan o d",
  "BCB.7111": "Tillfällig väg med bituminös beläggning",
  "BCB.7112": "Tillfällig väg med slitlager av grus",
  "BCB.712": "Tillfällig bro, gångbrygga, körbrygga o d",
  "BCB.7121": "Tillfällig bro",
  "BCB.7122": "Tillfällig gångbrygga",
  "BCB.7123": "Tillfällig körbrygga",
  "BCB.713": "Tillfällig vägtrafikanordning",
  "BCB.714": "Tillfällig trafikdirigering",
  "BCB.715": "Tillfällig vägbelysning",
  "BCB.716": "Tillfällig tillsyn av väg m m",
  "BCB.717": "Tillfällig skyddsanordning",
  "BCB.718": "Diverse tillfälliga åtgärder för vägtrafik",
  "BCB.7181": "Tillfälliga utspetsningar mot betäckning o d",
  "BCB.7182": "Tillfälliga kantstöd",
  "BCB.72": "Åtgärd för järnvägstrafik",
  "BCB.721": "Tillfällig spåranläggning",
  "BCB.722": "Tillfällig bro för järnväg",
  "BCB.723": "Tillfällig perrong o d",
  "BCB.724": "Tillfällig skyddsåtgärd för järnväg",
  "BCB.73": "Åtgärd för sjötrafik",
  "BCB.731": "Tillfällig brygga o d",
  "BCB.732": "Tillfällig åtgärd i farled",
  "BCB.8": "Diverse hjälparbeten i anläggning",
  "BCB.81": "Tillfälliga va-anordningar",
  "BCB.811": "Tillfälliga anordningar för vattenförsörjning",
  "BCB.812": "Tillfälliga anordningar för avlopp",
  "BCB.82": "Tillfälliga anordningar för gasförsörjning",
  "BCB.83": "Tillfälliga anordningar för fjärrvärme- och fjärrkyleförsörjning",
  "BCB.831": "Tillfälliga anordningar för fjärrvärmeförsörjning",
  "BCB.832": "Tillfälliga anordningar för fjärrkyleförsörjning",
  "BCB.87": "Tillfällig skyltning till allmänheten",
  BCD: "OMRÅDE FÖR TILLFÄLLIG UPPLÄGGNING AV AVFALL OCH FÖRORENADE MASSOR",
  "BCD.1": "Tillfällig uppläggning av förorenade massor",
  "BCD.2": "Tillfällig uppläggning av avfall",
  BCS: "HJÄLPARBETEN FÖR HUS",
  "BCS.1": "Skyddsanordningar vid arbete för hus",
  "BCS.11": "Väderskyddsanordningar vid arbete för hus",
  "BCS.2": "Åtgärder för mätpunkt e d för hus",
  "BCS.3": "Åtgärder för angränsande hus",
  BD: "SANERINGSARBETEN",
  BDB: "SANERINGSARBETEN I MARK, ANLÄGGNING O D",
  "BDB.1": "Saneringsarbeten på föroreningsplatsen",
  "BDB.11": "Sanering av uppgrävda massor eller avfall",
  "BDB.12": "Sanering i mark utan uppgrävning",
  "BDB.13": "Sanering i rörledning m m",
  "BDB.2": "Saneringsarbeten på annan plats än föroreningsplatsen",
  BDS: "SANERINGSARBETEN FÖR HUS",
  BE: "FLYTTNING, DEMONTERING OCH RIVNING",
  BEB: "FLYTTNING",
  "BEB.1": "Flyttning av anläggning",
  "BEB.11": "Flyttning av stolpe, staket, skylt m m",
  "BEB.110": "Flyttning av enheter bestående av stolpe, staket, skylt e d",
  "BEB.1101":
    "Flyttning av enheter bestående av stolpfundament, skyltstolpe och skylt",
  "BEB.1102":
    "Flyttning av enheter bestående av stolpfundament, belysningsstolpe och belysningsarmatur e d",
  "BEB.1103":
    "Flyttning av enheter bestående av stängsel, staket, räcke med fundament, stolpe e d",
  "BEB.111": "Flyttning av stolpfundament",
  "BEB.112": "Flyttning av skyltstolpe",
  "BEB.113": "Flyttning av belysningsstolpe",
  "BEB.114": "Flyttning av stängsel, staket o d",
  "BEB.115": "Flyttning av skylt",
  "BEB.12": "Flyttning av träd och buskar",
  "BEB.13": "Flyttning av spåranläggning",
  "BEB.14": "Flyttning av utrustningar och utsmyckningar",
  "BEB.4": "Flyttning av hus",
  BEC: "DEMONTERING",
  "BEC.1": "Demontering av anläggning",
  "BEC.11": "Demontering av ledning m m",
  "BEC.111": "Demontering av rörledning m m",
  "BEC.1111": "Demontering av rörledning m m i pumpstation o d",
  "BEC.1112": "Demontering av rörledning m m i väg, plan o d",
  "BEC.12": "Demontering av väg, plan o d",
  "BEC.121": "Demontering av beläggning m m på väg, plan o d",
  "BEC.1211": "Demontering av beläggning av natursten",
  "BEC.1212": "Demontering av beläggning av betongmarksten",
  "BEC.1213": "Demontering av beläggning av betongmarkplattor",
  "BEC.122": "Demontering av stödkonstruktion m m",
  "BEC.1222": "Demontering av stödmur",
  "BEC.14": "Demontering av bro, brygga, kaj, mur, tunnel, kammare o d",
  "BEC.141": "Demontering av bro",
  "BEC.142": "Demontering av brygga, kaj o d",
  "BEC.143": "Demontering av mur, trappa o d",
  "BEC.144": "Demontering av mast, torn o d",
  "BEC.15": "Demontering av anläggningskompletteringar i mark",
  "BEC.150":
    "Demontering av enheter bestående av anläggningskompletteringar i mark",
  "BEC.1501":
    "Demontering av enheter bestående av stolpfundament, skyltstolpe och skylt",
  "BEC.1502":
    "Demontering av enheter bestående av stolpfundament, belysningsstolpe och belysningsarmatur e d",
  "BEC.1503":
    "Demontering av enheter bestående av stängsel, staket, räcke med fundament, stolpe e d",
  "BEC.152": "Demontering av stolpfundament",
  "BEC.153": "Demontering av skyltstolpe",
  "BEC.154": "Demontering av belysningsstolpe",
  "BEC.155": "Demontering av stängsel, staket o d",
  "BEC.156": "Demontering av vägräcke",
  "BEC.157": "Demontering av kantstöd",
  "BEC.158": "Demontering av diverse anläggningskompletteringar i mark",
  "BEC.1581": "Demontering av refugelement",
  "BEC.1583": "Demontering av plattform på mark",
  "BEC.1584": "Demontering av utrustningar och utsmyckningar",
  "BEC.1585": "Demontering av skylt",
  "BEC.16":
    "Demontering av anläggningskompletteringar till bro, brygga, kaj o d",
  "BEC.161": "Demontering av anläggningskompletteringar till bro",
  "BEC.1615": "Demontering av brolager",
  "BEC.1618": "Demontering av diverse anläggningskompletteringar till bro",
  "BEC.17": "Demontering av spåranläggning",
  "BEC.171": "Demontering av spår",
  "BEC.172": "Demontering av växel eller spårkorsning",
  "BEC.173": "Demontering av spårkompletteringar",
  "BEC.174": "Demontering av bangårdsutrustning",
  "BEC.4": "Demontering av hus",
  "BEC.41": "Demontering av hus för återanvändning",
  "BEC.411": "Demontering av hus för uppläggning i upplag",
  "BEC.412": "Demontering av hus för återmontering",
  BED: "RIVNING",
  "BED.1": "Rivning av anläggning",
  "BED.11": "Rivning av ledning, kabel m m",
  "BED.111": "Rivning av rörledning",
  "BED.1111": "Rivning av hel rörledning",
  "BED.1112": "Rivning av del av rörledning",
  "BED.1113": "Rivning av stödkonstruktion för rörledning",
  "BED.112": "Rivning av el- och telekabel",
  "BED.1121": "Rivning av hel el- och telekabel",
  "BED.1122": "Rivning av del av el- och telekabel",
  "BED.12": "Rivning av väg, plan o d",
  "BED.121": "Rivning av beläggning m m på väg, plan o d",
  "BED.1211": "Rivning av beläggning av natursten",
  "BED.1212": "Rivning av beläggning av betongmarksten",
  "BED.1213": "Rivning av beläggning av betongmarkplattor",
  "BED.1214": "Rivning av bitumenbundna lager",
  "BED.1215": "Rivning av cementbundna lager",
  "BED.1216": "Rivning av platsgjuten betong",
  "BED.122": "Rivning av stödkonstruktion m m",
  "BED.1221": "Rivning av permanent spont",
  "BED.1222": "Rivning av stödmur",
  "BED.1223": "Rivning av påldäck",
  "BED.1224": "Rivning av bankpålplatta",
  "BED.1225": "Rivning av pålar för väg, plan o d",
  "BED.13": "Rivning av grundkonstruktion för hus",
  "BED.131": "Rivning av pålar för hus",
  "BED.138": "Rivning av diverse grundkonstruktioner för hus",
  "BED.14": "Rivning av bro, brygga, kaj, mur, tunnel, kammare o d",
  "BED.141": "Rivning av bro",
  "BED.1410": "Rivning av hel bro",
  "BED.1411": "Rivning av beläggning på bro",
  "BED.1412": "Rivning av bärverkskompletteringar e d i bro",
  "BED.1413": "Rivning av sekundära bärverk i bro",
  "BED.1414": "Rivning av huvudbärverk i bro",
  "BED.1415": "Rivning av undre bärverk i bro",
  "BED.1416": "Rivning av grundkonstruktioner i bro",
  "BED.1418": "Rivning av diverse brokonstruktioner",
  "BED.142": "Rivning av brygga, kaj o d",
  "BED.143": "Rivning av mur, trappa o d",
  "BED.1431": "Rivning av mur",
  "BED.1432": "Rivning av trappa",
  "BED.144": "Rivning av mast, torn o d",
  "BED.15": "Rivning av anläggningskompletteringar i mark",
  "BED.150":
    "Rivning av enheter bestående av anläggningskompletteringar i mark",
  "BED.1501":
    "Rivning av enheter bestående av stolpfundament, skyltstolpe och skylt",
  "BED.1502":
    "Rivning av enheter bestående av stolpfundament, belysningsstolpe och belysningsarmatur e d",
  "BED.1503":
    "Rivning av enheter bestående av stängsel, staket, räcke med fundament, stolpe e d",
  "BED.152": "Rivning av stolpfundament",
  "BED.153": "Rivning av skyltstolpe",
  "BED.154": "Rivning av belysningsstolpe",
  "BED.155": "Rivning av stängsel, staket o d",
  "BED.156": "Rivning av vägräcke",
  "BED.157": "Rivning av kantstöd",
  "BED.158": "Rivning av diverse anläggningskompletteringar i mark",
  "BED.1581": "Rivning av refugelement",
  "BED.1582": "Borttagning av väg- och ytmarkeringar",
  "BED.1583": "Rivning av plattform på mark",
  "BED.1584": "Rivning av utrustningar och utsmyckningar",
  "BED.1585": "Rivning av skylt",
  "BED.16": "Rivning av anläggningskompletteringar till bro, brygga, kaj o d",
  "BED.161": "Rivning av anläggningskompletteringar till bro",
  "BED.1611": "Rivning av skyltstolpe på bro",
  "BED.1612": "Rivning av belysningsstolpe på bro",
  "BED.1613": "Rivning av räcken på bro",
  "BED.1614": "Rivning av övergångskonstruktioner",
  "BED.1615": "Rivning av brolager",
  "BED.1618": "Rivning av diverse anläggningskompletteringar till bro",
  "BED.162": "Rivning av anläggningskompletteringar till brygga, kaj o d",
  "BED.163": "Rivning av anläggningskompletteringar till tunnel",
  "BED.164": "Rivning av anläggningskompletteringar till mast, torn o d",
  "BED.17": "Rivning av spåranläggning",
  "BED.171": "Rivning av spår",
  "BED.172": "Rivning av växel eller spårkorsning",
  "BED.173": "Rivning av spårkompletteringar",
  "BED.174": "Rivning av bangårdsutrustning",
  "BED.4": "Rivning av hus",
  "BED.41": "Rivning av hus för materialåtervinning",
  "BED.42": "Rivning av hus för energiutvinning",
  "BED.43": "Rivning av hus för deponering",
  BEE: "HÅLTAGNING",
  "BEE.2": "Håltagning i anläggningsdelar",
  "BEE.22": "Håltagning i anläggningsdelar i bro, brygga, kaj o d",
  "BEE.221": "Håltagning i befintliga anläggningsdelar i bro, brygga, kaj o d",
  "BEE.222":
    "Håltagning i anläggningsdelar under uppförande i bro, brygga, kaj o d",
  "BEE.23": "Håltagning i ledningsanläggning",
  "BEE.231": "Håltagning i befintlig ledningsanläggning",
  "BEE.232": "Håltagning i ledningsanläggning under uppförande",
  "BEE.4": "Håltagning i hus",
  "BEE.41": "Håltagning i befintligt hus",
  "BEE.42": "Håltagning i hus under uppförande",
  BF: "TRÄDFÄLLNING, RÖJNING M M",
  BFB: "TRÄDFÄLLNING",
  "BFB.1": "Fällning av samtliga träd inom angivet område",
  "BFB.2": "Fällning av enstaka träd",
  BFC: "RÖJNING",
  BFD: "BORTTAGNING AV STUBBAR",
  "BFD.1": "Stubbrytning",
  "BFD.11": "Stubbrytning inom område för grundläggning och för järnväg",
  "BFD.12": "Stubbrytning inom område för väg, plan o d",
  "BFD.13":
    "Stubbrytning inom område för sammansatt markyta och vegetationsyta",
  "BFD.14": "Stubbrytning där befintlig mark ska bibehållas",
  "BFD.2": "Stubbfräsning",
  BFE: "BORTTAGNING AV MARKVEGETATION OCH JORDMÅN",
  "BFE.1":
    "Borttagning av markvegetation och jordmån inom område för grundläggning och för järnväg",
  "BFE.11":
    "Borttagning av markvegetation och jordmån inom område för grundläggning och för järnväg, kulturmark",
  "BFE.12":
    "Borttagning av markvegetation och jordmån inom område för grundläggning och för järnväg, skogsmark",
  "BFE.2":
    "Borttagning av markvegetation och jordmån inom område för väg, plan o d",
  "BFE.21":
    "Borttagning av markvegetation och jordmån inom område för väg, plan o d, kulturmark",
  "BFE.22":
    "Borttagning av markvegetation och jordmån, inom område för väg, plan o d, skogsmark",
  "BFE.3":
    "Borttagning av markvegetation och jordmån inom område för sammansatt markyta och vegetationsyta",
  "BFE.31":
    "Borttagning av markvegetation och jordmån inom område för sammansatt markyta och vegetationsyta, kulturmark",
  "BFE.32":
    "Borttagning av markvegetation och jordmån inom område för sammansatt markyta och vegetationsyta, skogsmark",
  BFF: "UPPLÄGGNING OCH LAGRING AV TILLVARATAGEN MARKVEGETATION OCH JORDMÅN",
  "BFF.3": "Uppläggning och lagring av tillvaratagen markvegetation",
  "BFF.4": "Uppläggning och lagring av tillvaratagen jordmån",
  BFG: "YTRENSNING",
  "BFG.1": "Rensning av markyta",
  "BFG.2": "Rensning av sjöbotten",
  BG: "SPONT VID FÖRARBETEN M M",
  BGB: "TILLFÄLLIG SPONT",
  "BGB.1": "Valfri sponttyp",
  "BGB.11": "Valfri sponttyp för ledningsgrav",
  "BGB.12": "Valfri sponttyp för schaktgrop",
  "BGB.2": "Avsträvad spont",
  "BGB.21": "Avsträvad stålspont",
  "BGB.211": "Avsträvad stålspont för ledningsgrav",
  "BGB.212": "Avsträvad stålspont för schaktgrop",
  "BGB.22": "Släde, kassett",
  "BGB.221": "Släde, kassett för ledningsgrav",
  "BGB.222": "Släde, kassett för schaktgrop",
  "BGB.23": "Avsträvad glesspont",
  "BGB.231": "Avsträvad glesspont för ledningsgrav",
  "BGB.3": "Bakåtförankrad spont",
  "BGB.31": "Bakåtförankrad stålspont",
  "BGB.312": "Bakåtförankrad stålspont för schaktgrop",
  "BGB.32": "Bakåtförankrad glesspont",
  "BGB.321": "Bakåtförankrad glesspont för ledningsgrav",
  "BGB.322": "Bakåtförankrad glesspont för schaktgrop",
  "BGB.4": "Konsolspont",
  "BGB.41": "Konsolspont av stål",
  "BGB.411": "Konsolspont av stål för ledningsgrav",
  "BGB.412": "Konsolspont av stål för schaktgrop",
  "BGB.42": "Konsolspont av typ glesspont",
  "BGB.421": "Konsolspont av typ glesspont för ledningsgrav",
  BJ: "GEODETISKA MÄTNINGSARBETEN",
  BJB: "GEODETISKA MÄTNINGSARBETEN FÖR ANLÄGGNING OCH FÖR GRUNDLÄGGNING AV HUS",
  "BJB.1": "Stomnät",
  "BJB.11": "Stomnät i plan",
  "BJB.111": "Anslutningsnät i plan",
  "BJB.1111": "Anslutningsnät i plan för tunnel",
  "BJB.112": "Bruksnät i plan",
  "BJB.1121": "Bruksnät och byggnät i plan för tunnel",
  "BJB.1122": "Nät i plan för bro",
  "BJB.1123": "Nät i plan för mätning av rörelser",
  "BJB.12": "Stomnät i höjd",
  "BJB.121": "Anslutningsnät i höjd",
  "BJB.1211": "Anslutningsnät i höjd för tunnel",
  "BJB.122": "Bruksnät i höjd",
  "BJB.1221": "Bruksnät och byggnät i höjd för tunnel",
  "BJB.1222": "Nät i höjd för bro",
  "BJB.1223": "Nät i höjd för mätning av sättningar",
  "BJB.2": "Inmätning",
  "BJB.21": "Inmätning av husunderbyggnad, grundkonstruktion o d",
  "BJB.22": "Inmätning av bro, brygga, kaj och dammanläggning",
  "BJB.221": "Inmätning av bro, brygga och kaj",
  "BJB.222": "Inmätning av dammanläggning",
  "BJB.23": "Inmätning av väg, plan o d",
  "BJB.24": "Inmätning av spåranläggning",
  "BJB.25": "Inmätning av tunnel, bergrum m m",
  "BJB.251": "Inmätning av tunnelbyggnad för väg",
  "BJB.252": "Inmätning av tunnelbyggnad för spåranläggning",
  "BJB.26": "Inmätning av ledning, kabel m m",
  "BJB.27": "Inmätning av mark- och vattenförhållanden",
  "BJB.271": "Inmätning av jordyta",
  "BJB.272": "Inmätning av bergyta",
  "BJB.273": "Inmätning av vegetation",
  "BJB.274": "Inmätning av vattenyta",
  "BJB.275": "Inmätning av fornminne",
  "BJB.3": "Utsättning",
  "BJB.31": "Utsättning för husunderbyggnad, grundkonstruktion o d",
  "BJB.32": "Utsättning för bro, brygga, kaj och dammanläggning",
  "BJB.321": "Utsättning för bro, brygga och kaj",
  "BJB.322": "Utsättning för dammanläggning",
  "BJB.33": "Utsättning för väg, plan o d",
  "BJB.34": "Utsättning för spåranläggning",
  "BJB.35": "Utsättning för tunnel, bergrum m m",
  "BJB.351": "Utsättning för tunnelbyggnad för väg",
  "BJB.352": "Utsättning för tunnelbyggnad för spåranläggning",
  "BJB.36": "Utsättning för ledning, kabel m m",
  "BJB.37": "Utsättning för vegetationsyta o d",
  "BJB.4": "Modeller",
  "BJB.41": "Markmodell",
  "BJB.42": "Bergmodell",
  "BJB.43": "Anläggningsmodell",
  "BJB.8": "Diverse mätningstekniska arbeten",
  BJC: "GEODETISKA MÄTNINGSARBETEN FÖR HUS",
  "BJC.1": "Stomnät",
  "BJC.11": "Stomnät i plan",
  "BJC.111": "Anslutningsnät i plan",
  "BJC.112": "Bruksnät i plan",
  "BJC.1121": "Sekundärnät i plan",
  "BJC.113": "Nät eller utgångspunkter i plan för mätning av rörelser",
  "BJC.12": "Stomnät i höjd",
  "BJC.121": "Anslutningsnät i höjd",
  "BJC.122": "Bruksnät i höjd",
  "BJC.1221": "Sekundärnät i höjd",
  "BJC.123": "Nät eller utgångspunkter i höjd för mätning av sättningar",
  "BJC.2": "Inmätning",
  "BJC.21": "Inmätning allmänt",
  "BJC.22": "Inmätning av konstruktioner",
  "BJC.23": "Inmätningar som underlag för relationshandlingar",
  "BJC.3": "Utsättning",
  "BJC.31": "Utsättning allmänt",
  "BJC.32": "Utsättning för konstruktioner",
  "BJC.8": "Diverse mätningstekniska arbeten",
  "BV:B": "Tunnlar och bergrum",
  "BV:BA": "Tunnlar och bergrum, sammansatta",
  "BV:BB": "Tunnlar",
  "BV:BBA": "Tunnlar för flera ändamål",
  "BV:BBB": "Tunnlar för vägtrafik",
  "BV:BBC": "Tunnlar för spårburen trafik",
  "BV:BBD": "Tunnlar för ledningar",
  "BV:BBF": "Tunnlar för skydds- och försvarsändamål",
  "BV:BBZ": "Övriga tunnlar",
  "BV:BC": "Bergrum",
  "BV:BCB": "Bergrum för lagring",
  "BV:BCC": "Bergrum för skydds- och försvarsändamål",
  "BV:BCD": "Bergrum för industriell produktion och utvinning",
  "BV:BCE": "Bergrum för reningsverk",
  "BV:BCF": "Bergrum för vägfordon",
  "BV:BCG": "Bergrum för spårfordon",
  "BV:BCZ": "Övriga bergrum",
  "BV:C": "Kanaler och bassänger",
  "BV:CB": "Kanaler",
  "BV:CBB": "Kanaler för trafik",
  "BV:CBZ": "Övriga kanaler",
  "BV:CC": "Bassänger",
  "BV:CCB": "Bassänger för fartyg",
  "BV:CCC": "Bassänger för förvaring eller sedimentering",
  "BV:CCD": "Bassänger för bad och rekreation",
  "BV:CCZ": "Övriga bassänger",
  "BV:D": "Vägar och planer",
  "BV:DA": "Vägar och planer, sammansatta",
  "BV:DB": "Trappor",
  "BV:DC": "Vägar på mark",
  "BV:DCB": "Vägar för vägtrafik",
  "BV:DCB/B": "Vägar för biltrafik",
  "BV:DCB/C": "Vägar för gång- och cykeltrafik",
  "BV:DCB/Y": "Vägar för vägtrafik, tillfällig väg",
  "BV:DCC": "Vägar för spårburen trafik",
  "BV:DCC/B": "Järnväg",
  "BV:DCC/C": "Spårväg",
  "BV:DCD": "Vägar för flygtrafik",
  "BV:DCZ": "Övriga vägar",
  "BV:DD": "Planer, uppställningsplatser e d",
  "BV:DDB":
    "Planer, uppställningsplatser e d för uppställning och rangering av fordon eller farkoster",
  "BV:DDC":
    "Planer, uppställningsplatser e d för uppläggning och hantering av material och varor",
  "BV:DDZ": "Planer, uppställningsplatser e d för övriga ändamål",
  "BV:E": "Broar, bryggor och kajer",
  "BV:EB": "Broar",
  "BV:EBA": "Broar för flera ändamål",
  "BV:EBB": "Broar för vägtrafik",
  "BV:EBB/B": "Broar för vägtrafik, plattbro",
  "BV:EBB/C": "Broar för vägtrafik, balkbro",
  "BV:EBB/D": "Broar för vägtrafik, lådbalkbro",
  "BV:EBB/E": "Broar för vägtrafik, plattrambro",
  "BV:EBB/F": "Broar för vägtrafik, balkrambro",
  "BV:EBB/G": "Broar för vägtrafik, rörbro",
  "BV:EBB/H": "Broar för vägtrafik, valvbro",
  "BV:EBB/J": "Broar för vägtrafik, bågbro",
  "BV:EBB/K": "Broar för vägtrafik, fackverksbro",
  "BV:EBB/L": "Broar för vägtrafik, snedkabelbro",
  "BV:EBB/M": "Broar för vägtrafik, hängbro",
  "BV:EBB/N": "Broar för vägtrafik, rörlig bro",
  "BV:EBB/Y": "Broar för vägtrafik, tillfällig bro",
  "BV:EBC": "Broar för spårburen trafik",
  "BV:EBD": "Broar för flygtrafik",
  "BV:EBZ": "Övriga broar",
  "BV:EC": "Däck",
  "BV:ED": "Akvedukter",
  "BV:EE": "Ekodukter",
  "BV:EK": "Bryggor, kajer och pirar",
  "BV:EKZ": "Övriga bryggor, kajer och pirar",
  "BV:EM": "Färjelägen",
  "BV:EZ": "Övriga broar, bryggor och kajer",
  "BV:F": "Stödkonstruktioner, murar, dammar, vallar och skärmar",
  "BV:FA":
    "Sammansatta stödkonstruktioner, murar, dammar, vallar eller skärmar",
  "BV:FB": "Stödkonstruktioner",
  "BV:FBB": "Stödmurar",
  "BV:FC": "Påldäck",
  "BV:FD": "Tråg",
  "BV:FE": "Dammar och vallar",
  "BV:FEB": "Dammar",
  "BV:FEC": "Vallar",
  "BV:FG": "Skärmar",
  "BV:FGB": "Skärmar för bullerskydd",
  "BV:FGC": "Skärmar för bländskydd",
  "BV:FZ": "Övriga stödkonstruktioner, murar, dammar, vallar eller skärmar",
  "BV:G": "Master och torn",
  "BV:GB": "Master",
  "BV:GC": "Torn",
  "BV:H": "Ledningsnät",
  "BV:HC": "Rörledningsnät",
  "BV:HCB": "Vattenledningsnät",
  "BV:HCC": "Avloppsledningsnät",
  "BV:HCD": "Ledningsnät för värme och kyla",
  "BV:HCE": "Gasledningsnät",
  "BV:HCF": "Ledningsnät för petrokemiska vätskor",
  "BV:HCZ": "Övriga rörledningsnät",
  "BV:J": "Maskinbyggnadsverk",
  "BV:JH": "Pumpar, pumpstationer",
  "BV:U": "Markområden",
  "BV:UC": "Iordningställda markområden",
  "BV:UCD": "Trädgårdsmark",
  "BV:UCE": "Park- och gårdsmark",
  "BV:UCF": "Golfbanor",
  "BV:V": "Vattenområden",
  "BV:VC": "Iordningställda vattenområden",
  "BV:Z": "Övriga byggnadsverk",
  C: "TERRASSERING, PÅLNING, MARKFÖRSTÄRKNING, LAGER I MARK M M",
  CB: "SCHAKT",
  CBB: "JORDSCHAKT",
  "CBB.1": "Jordschakt för väg, plan o d samt vegetationsyta",
  "CBB.11": "Jordschakt för väg, plan o d",
  "CBB.111": "Jordschakt kategori A för väg, plan o d",
  "CBB.112": "Jordschakt kategori B för väg, plan o d",
  "CBB.113": "Jordschakt kategori C för väg, plan o d samt sammansatt yta",
  "CBB.12":
    "Jordschakt för utskiftning, utspetsning och utjämning för väg, plan o d",
  "CBB.121": "Jordschakt kategori A för utskiftning, utspetsning och utjämning",
  "CBB.122": "Jordschakt kategori B för utskiftning, utspetsning och utjämning",
  "CBB.123": "Jordschakt kategori C för utskiftning, utspetsning och utjämning",
  "CBB.13": "Blockrensning i terrass för väg, plan o d",
  "CBB.131": "Blockrensning kategori A i terrass",
  "CBB.132": "Blockrensning kategori B i terrass",
  "CBB.133": "Blockrensning kategori C i terrass",
  "CBB.14": "Jordschakt för vegetationsyta",
  "CBB.15": "Blockrensning i befintlig mark för väg, plan o d",
  "CBB.151": "Blockrensning kategori A i befintlig mark för väg, plan o d",
  "CBB.1511": "Blockrensning kategori A under underbyggnad för väg, plan o d",
  "CBB.152": "Blockrensning kategori B i befintlig mark för väg, plan o d",
  "CBB.153": "Blockrensning kategori C i befintlig mark för väg, plan o d",
  "CBB.2": "Jordschakt för byggnad",
  "CBB.21": "Jordschakt för grundläggning av byggnad, terrassering",
  "CBB.22": "Jordschakt för grundläggning av byggnad, detaljschaktning",
  "CBB.3": "Jordschakt för ledning, kabel m m",
  "CBB.31": "Jordschakt för rörledning",
  "CBB.311": "Jordschakt för va-ledning o d",
  "CBB.3111": "Jordschakt för va-ledning",
  "CBB.3112": "Jordschakt för dränledning",
  "CBB.3113": "Jordschakt för gasledning",
  "CBB.312": "Jordschakt för trumma",
  "CBB.3121": "Jordschakt för vägtrumma",
  "CBB.3122": "Jordschakt för järnvägstrumma",
  "CBB.313": "Jordschakt för värmeledning o d",
  "CBB.3131": "Jordschakt för värmeledning",
  "CBB.3132": "Jordschakt för kylledning",
  "CBB.32": "Jordschakt för el- och telekabel o d",
  "CBB.4": "Jordschakt för spåranläggning",
  "CBB.41": "Jordschakt för järnväg, terrassering",
  "CBB.42": "Jordschakt för utskiftning och utspetsning för järnväg",
  "CBB.43": "Blockrensning i terrass för järnväg",
  "CBB.44": "Jordschakt för järnväg, zonschakt",
  "CBB.45": "Jordschakt för järnväg i befintligt spår",
  "CBB.5": "Jordschakt för bro, brygga, kaj, kassun o d",
  "CBB.51": "Jordschakt för grundläggning av bro",
  "CBB.52": "Jordschakt för grundläggning av brygga, kaj o d",
  "CBB.53": "Jordschakt för grundläggning av kassun o d",
  "CBB.54": "Jordschakt för mur, trappa o d",
  "CBB.55": "Jordschakt för mast, torn o d",
  "CBB.56": "Jordschakt för fundament",
  "CBB.6": "Jordschakt för dike, avfallsanläggning, magasin m m",
  "CBB.61": "Jordschakt för dike",
  "CBB.611": "Jordschakt för bankdike",
  "CBB.612": "Jordschakt för överdike, terrängdike o d",
  "CBB.62": "Jordschakt för avfallsanläggning",
  "CBB.63": "Jordschakt för magasin, infiltrationsbädd o d",
  "CBB.631": "Jordschakt för perkolationsmagasin för dagvatten",
  "CBB.6311": "Jordschakt för friliggande perkolationsmagasin",
  "CBB.6312": "Jordschakt för perkolationsmagasin i ledningsgrav",
  "CBB.632": "Jordschakt för utjämningsmagasin m m",
  "CBB.633": "Jordschakt för infiltrationsbädd",
  "CBB.64": "Jordschakt för damm, bassäng, kanal o d",
  "CBB.7": "Avtäckning av berg, urgrävning för väg, byggnad m m",
  "CBB.71": "Avtäckning av berg",
  "CBB.711": "Avtäckning av bergyta, opåverkad av sprängning",
  "CBB.712": "Avtäckning av bergyta, befintlig sprängbotten",
  "CBB.72": "Urgrävning av svag undergrund",
  "CBB.721": "Urgrävning för väg, plan o d",
  "CBB.722": "Urgrävning för byggnad",
  "CBB.723": "Urgrävning för ledning",
  "CBB.724": "Urgrävning för järnväg",
  "CBB.725": "Urgrävning för bro, brygga, kaj, kassun o d",
  "CBB.73": "Borttagning av överlastmassor och upptryckta massor",
  "CBB.731": "Borttagning av överlastmassor",
  "CBB.732": "Borttagning av upptryckta massor",
  "CBB.74": "Jordschakt i rör, trumma, brunn o d",
  "CBB.8": "Diverse jordschakt",
  "CBB.82": "Förschakt för spont",
  "CBB.83": "Förschakt för pålning, markförstärkning m m",
  "CBB.84": "Förschakt för rivning och demontering",
  "CBB.86": "Förschakt för inmätning",
  "CBB.88": "Diverse förschakt",
  CBC: "BERGSCHAKT",
  "CBC.1": "Bergschakt för väg, plan o d samt vegetationsyta",
  "CBC.11": "Bergschakt för väg, plan o d",
  "CBC.111": "Bergschakt kategori A för väg, plan o d",
  "CBC.112":
    "Bergschakt kategori B och C för väg, plan o d samt sammansatt yta",
  "CBC.12": "Bergschakt för vegetationsyta",
  "CBC.2": "Bergschakt för byggnad",
  "CBC.21": "Bergschakt för grundläggning av byggnad på sprängbotten",
  "CBC.211":
    "Bergschakt för grundläggning av byggnad på sprängbotten, terrassering",
  "CBC.212":
    "Bergschakt för grundläggning av byggnad på sprängbotten, detaljschakt",
  "CBC.22": "Bergschakt för grundläggning av byggnad på fast berg",
  "CBC.221":
    "Bergschakt för grundläggning av byggnad på fast berg, terrassering",
  "CBC.222":
    "Bergschakt för grundläggning av byggnad på fast berg, detaljschakt",
  "CBC.3": "Bergschakt för ledning, kabel m m",
  "CBC.31": "Bergschakt för rörledning",
  "CBC.311": "Bergschakt för va-ledning o d",
  "CBC.3111": "Bergschakt för va-ledning",
  "CBC.3112": "Bergschakt för dränledning",
  "CBC.3113": "Bergschakt för gasledning",
  "CBC.312": "Bergschakt för trumma",
  "CBC.3121": "Bergschakt för vägtrumma",
  "CBC.3122": "Bergschakt för järnvägstrumma",
  "CBC.313": "Bergschakt för värmeledning o d",
  "CBC.3131": "Bergschakt för värmeledning",
  "CBC.3132": "Bergschakt för kylledning",
  "CBC.32": "Bergschakt för el- och telekabel o d",
  "CBC.4": "Bergschakt för spåranläggning",
  "CBC.41": "Bergschakt för järnväg",
  "CBC.5": "Bergschakt för bro, brygga, kaj, kassun o d",
  "CBC.51": "Bergschakt för grundläggning av bro",
  "CBC.511": "Bergschakt för grundläggning av bro på sprängbotten",
  "CBC.512": "Bergschakt för grundläggning av bro på fast berg",
  "CBC.513":
    "Bergschakt för grundläggning av bro med packad fyllning på fast berg",
  "CBC.52": "Bergschakt för grundläggning av brygga, kaj o d",
  "CBC.521": "Bergschakt för grundläggning av brygga, kaj o d på sprängbotten",
  "CBC.522": "Bergschakt för grundläggning av brygga, kaj o d på fast berg",
  "CBC.53": "Bergschakt för grundläggning av kassun o d",
  "CBC.54": "Bergschakt för mur, trappa o d",
  "CBC.541": "Bergschakt för mur, trappa o d på sprängbotten",
  "CBC.542": "Bergschakt för mur, trappa o d på fast berg",
  "CBC.55": "Bergschakt för mast, torn o d",
  "CBC.56": "Bergschakt för fundament",
  "CBC.561": "Bergschakt för grundläggning av fundament på sprängbotten",
  "CBC.562": "Bergschakt för grundläggning av fundament på fast berg",
  "CBC.6": "Bergschakt för tunnel, bergrum o d",
  "CBC.61": "Bergschakt för tunnel",
  "CBC.611": "Bergschakt för väg- och järnvägstunnel",
  "CBC.612": "Bergschakt för va-tunnel, servicetunnel o d",
  "CBC.62": "Bergschakt för bergrum",
  "CBC.621": "Bergschakt för bergrum för lagring av olja o d",
  "CBC.622": "Bergschakt för bergrum för lagring av gas o d",
  "CBC.7": "Bergschakt för dike, deponi, magasin m m",
  "CBC.71": "Bergschakt för dike",
  "CBC.73": "Bergschakt för deponi",
  "CBC.74": "Bergschakt för magasin, damm, bassäng, kanal o d",
  "CBC.75": "Bergschakt av jord-, yt-, botten- och sidoblock",
  "CBC.76": "Sprängning under dike",
  CBE: "JORDBORRNING",
  "CBE.1": "Hammarborrning i jord",
  CBF: "BERGBORRNING",
  "CBF.1": "Borrning av tunnel",
  "CBF.11": "Borrning av tunnel med tunnelborrmaskin (TBM)",
  "CBF.111": "Borrning med tunnelborrmaskin för väg och spåranläggning o d",
  "CBF.112": "Borrning med tunnelborrmaskin för teknisk försörjning",
  "CBF.12": "Borrning av tunnel med mikrotunnelteknik",
  "CBF.121": "Borrning med mikrotunnelteknik för teknisk försörjning",
  "CBF.2": "Borrning av borrhål i berg",
  "CBF.21": "Borrning av borrhål med kärnborrning",
  "CBF.211": "Kärnborrning för teknisk försörjning",
  "CBF.22": "Borrning av borrhål med hammarborrning",
  "CBF.221": "Hammarborrning för teknisk försörjning",
  "CBF.23": "Borrning av borrhål med rotationsborrning",
  "CBF.231": "Rotationsborrning för teknisk försörjning",
  "CBF.3": "Upprymning av borrhål i berg",
  "CBF.31": "Upprymning av borrhål med hammarborrning",
  "CBF.32": "Upprymning av borrhål med rotationsborrning",
  CC: "PÅLNING",
  CCB: "SLAGNING AV PÅLAR",
  "CCB.1": "Slagning av pålar av betong",
  "CCB.11": "Slagning av pålar av betong för byggnad",
  "CCB.111": "Slagning av spetsburna pålar av betong för byggnad",
  "CCB.1111": "Slagning av spetsburna pålar av betong för byggnad, utan borrör",
  "CCB.1112": "Slagning av spetsburna pålar av betong för byggnad, med borrör",
  "CCB.112": "Slagning av mantelburna pålar av betong för byggnad",
  "CCB.12": "Slagning av pålar av betong för bro",
  "CCB.121": "Slagning av spetsburna pålar av betong för bro",
  "CCB.1211": "Slagning av spetsburna pålar av betong för bro, utan borrör",
  "CCB.1212": "Slagning av spetsburna pålar av betong för bro, med borrör",
  "CCB.122": "Slagning av mantelburna pålar av betong för bro",
  "CCB.13": "Slagning av pålar av betong för väg- och järnvägsbank m m",
  "CCB.131":
    "Slagning av spetsburna pålar av betong för väg- och järnvägsbank m m",
  "CCB.1311":
    "Slagning av spetsburna pålar av betong för väg- och järnvägsbank m m, utan borrör",
  "CCB.1312":
    "Slagning av spetsburna pålar av betong för väg- och järnvägsbank m m, med borrör",
  "CCB.132":
    "Slagning av mantelburna pålar av betong för väg- och järnvägsbank m m",
  "CCB.14": "Slagning av pålar av betong för ledning",
  "CCB.141": "Slagning av spetsburna pålar av betong för ledning",
  "CCB.1411": "Slagning av spetsburna pålar av betong för ledning, utan borrör",
  "CCB.1412": "Slagning av spetsburna pålar av betong för ledning, med borrör",
  "CCB.142": "Slagning av mantelburna pålar av betong för ledning",
  "CCB.2": "Slagning av pålar av stål",
  "CCB.21": "Slagning av pålar av stål för byggnad",
  "CCB.211": "Slagning av spetsburna pålar av stål för byggnad",
  "CCB.212": "Slagning av mantelburna pålar av stål för byggnad",
  "CCB.22": "Slagning av pålar av stål för bro",
  "CCB.221": "Slagning av spetsburna pålar av stål för bro",
  "CCB.222": "Slagning av mantelburna pålar av stål för bro",
  "CCB.23": "Slagning av pålar av stål för ledning",
  "CCB.231": "Slagning av spetsburna pålar av stål för ledning",
  "CCB.24":
    "Slagning av pålar av stål för förstärkning av befintlig grundkonstruktion",
  "CCB.3": "Slagning av pålar av trä",
  "CCB.31": "Slagning av pålar av trä för väg- och järnvägsbank",
  "CCB.311": "Slagning av spetsburna pålar av trä för väg- och järnvägsbank",
  "CCB.32": "Slagning av pålar av trä för ledning",
  "CCB.322": "Slagning av spetsburna pålar av trä för ledning",
  "CCB.33": "Slagning av pålar av trä för bro, brygga, kaj o d",
  "CCB.34": "Slagning av pålar av trä för tunnel, kammare o d",
  CCC: "TRYCKNING AV PÅLAR",
  "CCC.2": "Tryckning av pålar av stål",
  "CCC.21":
    "Tryckning av pålar av stål för förstärkning av befintlig grundkonstruktion",
  "CCC.3": "Tryckning av pålar av trä",
  CCD: "BORRNING AV PÅLAR",
  "CCD.2": "Borrning av pålar av stål",
  "CCD.21": "Borrning av stålkärnepålar",
  "CCD.22": "Borrning av stålrörspålar, borrpålar",
  CCE: "GRÄVNING OCH GJUTNING AV PÅLAR",
  "CCE.1": "Grävning och gjutning av pålar av betong",
  "CCE.12": "Grävning och gjutning av pålar av betong för bro, brygga, kaj o d",
  "CCE.121": "Grävning och gjutning av pålar av betong för bro",
  CCF: "BORRNING OCH INJEKTERING AV PÅLAR",
  "CCF.2": "Borrning och injektering av pålar av stål",
  CCG: "VIBRERING AV PÅLAR",
  "CCG.2": "Vibrering av pålar av stål",
  "CCG.3": "Vibrering av pålar av trä",
  CCH: "SPECIALPÅLAR",
  "CCH.1": "Specialpålar av betong",
  "CCH.2": "Specialpålar av stål",
  CD: "MARKFÖRSTÄRKNINGSARBETEN M M",
  CDB: "JORDFÖRSTÄRKNING M M",
  "CDB.1": "Jordförstärkning genom avvattning",
  "CDB.11": "Vertikaldränering",
  "CDB.12": "Elektroosmos",
  "CDB.2": "Jordförstärkning genom stabilisering",
  "CDB.21": "Injektering",
  "CDB.211": "Jetinjektering",
  "CDB.23": "Jordförstärkning med inblandningspelare",
  "CDB.231": "Jordförstärkning med kalkpelare",
  "CDB.232": "Jordförstärkning med cementpelare",
  "CDB.233": "Jordförstärkning med kalkcementpelare",
  "CDB.24": "Jordförstärkning genom masstabilisering",
  "CDB.3": "Jordförstärkning genom ytstabilisering",
  "CDB.31": "Jordförstärkning genom kalkinblandning",
  "CDB.32": "Jordförstärkning genom cementinblandning",
  "CDB.4": "Armering av jord",
  "CDB.41": "Armering av jord med jordspikar",
  "CDB.42": "Armering av jord med jordskruvar",
  "CDB.5": "Lastutbredning m m",
  "CDB.51": "Lastutbredning med rustbädd",
  "CDB.511": "Rustbädd för väg, plan o d",
  "CDB.512": "Rustbädd för ledning",
  "CDB.52": "Lastutbredning med plåt e d",
  "CDB.522": "Lastutbredning med plåt för ledning",
  "CDB.53": "Lastutbredning med betongavjämning",
  "CDB.532": "Lastutbredning med betongavjämning för ledning",
  "CDB.6": "Djuppackning",
  "CDB.61": "Djuppackning genom vibrering",
  "CDB.611": "Djuppackning med vibroflotation",
  "CDB.612": "Djuppackning med vibrosond",
  "CDB.62": "Djuppackning med fallvikt",
  "CDB.63": "Djuppackning genom sprängning",
  CDC: "BERGFÖRANKRING",
  "CDC.1": "Bergförankring med bult, linor och nät",
  "CDC.11": "Bergförankring med förspända bultar",
  "CDC.111": "Bergförankring med förspända bultar, ingjutna",
  "CDC.12": "Bergförankring med förspända bultar, ingjutna med glidlager",
  "CDC.13": "Bergförankring med förspända linor",
  "CDC.14": "Bergförankring med ingjutna bultar utan förspänning",
  "CDC.15": "Bergförankring med ingjutna linor utan förspänning",
  "CDC.16": "Bergförankring med friktionsbultar, firmabunden typ",
  "CDC.17": "Bergförankring med skyddsnät",
  "CDC.18": "Bergförankring med diverse system",
  "CDC.2": "Provning av bergmassans och förstärkningarnas stabilitet",
  CDD: "TÄTNING OCH INFILTRATION AV BERG KRING TUNNLAR, BERGRUM O D",
  "CDD.1": "Injektering",
  "CDD.11": "Förinjektering",
  "CDD.111": "Förinjektering med cementbaserat injekteringsmedel",
  "CDD.112": "Förinjektering med icke cementbaserat injekteringsmedel",
  "CDD.12": "Efterinjektering",
  "CDD.121": "Efterinjektering med cementbaserat injekteringsmedel",
  "CDD.122": "Efterinjektering med icke cementbaserat injekteringsmedel",
  "CDD.13": "Kontaktinjektering",
  "CDD.131": "Kontaktinjektering med cementbaserat injekteringsmedel",
  "CDD.132": "Kontaktinjektering med icke cementbaserat injekteringsmedel",
  "CDD.14": "Ridåinjektering",
  "CDD.141": "Ridåinjektering med cementbaserat injekteringsmedel",
  "CDD.142": "Ridåinjektering med icke cementbaserat injekteringsmedel",
  "CDD.2": "Vatteninfiltration",
  CDE: "TÄTNING AV BERG KRING RÖRLEDNINGAR O D",
  "CDE.1": "Injektering kring rörledning o d",
  "CDE.11": "Sprickinjektering kring rörledning o d",
  "CDE.111":
    "Sprickinjektering kring rörledning o d med cementbaserat injekteringsmedel",
  "CDE.112":
    "Sprickinjektering kring rörledning o d med icke cementbaserat injekteringsmedel",
  "CDE.12": "Kontaktinjektering kring rörledning o d",
  "CDE.121":
    "Kontaktinjektering kring rörledning o d med cementbaserat injekteringsmedel",
  "CDE.122":
    "Kontaktinjektering kring rörledning o d med icke cementbaserat injekteringsmedel",
  CDF: "PERMANENT SPONT",
  "CDF.1": "Obehandlad permanent spont",
  "CDF.2": "Rostskyddsmålad permanent spont",
  "CDF.3": "Permanent spont med katodiskt korrosionsskydd",
  CE: "FYLLNING, LAGER I MARK M M",
  CEB: "FYLLNING FÖR VÄG, BYGGNAD, BRO M M",
  "CEB.1": "Fyllning för väg, plan o d samt vegetationsyta",
  "CEB.11": "Fyllning för väg, plan o d",
  "CEB.111": "Fyllning med sprängsten för väg, plan o d",
  "CEB.1111": "Fyllning kategori A med sprängsten för väg, plan o d",
  "CEB.1112": "Fyllning kategori B med sprängsten för väg, plan o d",
  "CEB.1113":
    "Fyllning kategori C med sprängsten för väg, plan o d samt sammansatt yta",
  "CEB.112": "Fyllning med jord- och krossmaterial för väg, plan o d",
  "CEB.1121":
    "Fyllning kategori A med jord- och krossmaterial för väg, plan o d",
  "CEB.1122":
    "Fyllning kategori B med jord- och krossmaterial för väg, plan o d",
  "CEB.1123":
    "Fyllning kategori C med jord- och krossmaterial för väg, plan o d samt sammansatt yta",
  "CEB.113":
    "Fyllning med sprängsten, grovkornig jord och krossmaterial efter schakt för utskiftning, utspetsning och utjämning för väg, plan o d",
  "CEB.1131":
    "Fyllning kategori A efter schakt för utskiftning, utspetsning och utjämning",
  "CEB.1132":
    "Fyllning kategori B efter schakt för utskiftning, utspetsning och utjämning",
  "CEB.1133":
    "Fyllning kategori C efter schakt för utskiftning, utspetsning och utjämning",
  "CEB.114": "Fyllning med restprodukter för väg, plan o d",
  "CEB.115": "Fyllning med återvunna material för väg, plan o d",
  "CEB.12": "Fyllning för vegetationsyta",
  "CEB.121": "Fyllning med sprängsten för vegetationsyta",
  "CEB.122": "Fyllning med jordmaterial för vegetationsyta",
  "CEB.128": "Fyllning med diverse material för vegetationsyta",
  "CEB.2": "Fyllning för byggnad, golv o d",
  "CEB.21": "Fyllning för grundläggning av byggnad",
  "CEB.211": "Fyllning med sprängsten för grundläggning av byggnad",
  "CEB.212": "Fyllning med krossmaterial för grundläggning av byggnad",
  "CEB.213": "Fyllning med krossad sprängsten för grundläggning av byggnad",
  "CEB.214":
    "Fyllning med bland- eller finkornig jord för grundläggning av byggnad",
  "CEB.215": "Fyllning med grovkornig jord för grundläggning av byggnad",
  "CEB.22": "Fyllning under fribärande golv",
  "CEB.3": "Fyllning för spåranläggning",
  "CEB.31": "Fyllning med sprängsten för järnväg",
  "CEB.32": "Fyllning med jord- och krossmaterial för järnväg",
  "CEB.321":
    "Fyllning med grov- och blandkornig jord och krossmaterial för järnväg",
  "CEB.322": "Fyllning med bland- och finkornig jord för järnväg",
  "CEB.33":
    "Fyllning med jord- och krossmaterial för breddning av befintlig järnvägsbank",
  "CEB.34":
    "Fyllning med grovkornig jord och krossmaterial efter schakt för utskiftning och utspetsning för järnväg",
  "CEB.4": "Fyllning för grundläggning av bro, mur, brygga, kaj, kassun m m",
  "CEB.41": "Fyllning för grundläggning av bro",
  "CEB.411": "Fyllning med sprängsten för grundläggning av bro",
  "CEB.412": "Fyllning med sorterad sprängsten för grundläggning av bro",
  "CEB.413": "Fyllning med krossad sprängsten för grundläggning av bro",
  "CEB.415": "Fyllning med förstärkningslagermaterial för grundläggning av bro",
  "CEB.42": "Fyllning för grundläggning av mur, trappa m m",
  "CEB.421": "Fyllning med sprängsten för grundläggning av mur, trappa m m",
  "CEB.422":
    "Fyllning med grus eller krossmaterial av grus för grundläggning av mur, trappa m m",
  "CEB.423":
    "Fyllning med krossad sprängsten för grundläggning av mur, trappa m m",
  "CEB.43": "Fyllning för grundläggning av brygga, kaj o d",
  "CEB.431": "Fyllning med sprängsten för grundläggning av brygga, kaj o d",
  "CEB.432":
    "Fyllning med sorterad sprängsten för grundläggning av brygga, kaj o d",
  "CEB.433":
    "Fyllning med krossad sprängsten för grundläggning av brygga, kaj o d",
  "CEB.434":
    "Fyllning med grus eller krossmaterial av grus för grundläggning av brygga, kaj o d",
  "CEB.44": "Fyllning för grundläggning av kassun o d",
  "CEB.45": "Fyllning för grundläggning av mast, torn o d",
  "CEB.46": "Fyllning för fundament för kontaktledningsstolpe e d",
  "CEB.5": "Fyllning mot byggnad, bro, mur o d",
  "CEB.51": "Fyllning mot byggnad",
  "CEB.52": "Fyllning mot bro, mur o d",
  "CEB.524": "Fyllning med grovkrossad sprängsten mot bro, mur o d",
  "CEB.525": "Fyllning med förstärkningslagermaterial mot bro, mur o d",
  "CEB.526": "Fyllning med bärlagermaterial mot bro, mur o d",
  "CEB.53": "Fyllning mot fundament",
  "CEB.54": "Fyllning mot spont",
  "CEB.6": "Fyllning på pålar och påldäck för väg, plan, järnväg o d",
  "CEB.61": "Fyllning på pålar för väg, plan, järnväg o d",
  "CEB.611":
    "Fyllning för lastfördelande lager på pålar för väg, plan, järnväg o d",
  "CEB.6111":
    "Fyllning för lastfördelande lager av grus på pålar för väg, plan, järnväg o d",
  "CEB.6112":
    "Fyllning för lastfördelande lager av krossmaterial på pålar för väg, plan, järnväg o d",
  "CEB.612": "Fyllning för bankfyllning på pålar för väg, plan, järnväg o d",
  "CEB.6121":
    "Fyllning för bankfyllning av grovkornig jord eller krossmaterial på pålar för väg, plan, järnväg o d",
  "CEB.6122":
    "Fyllning för bankfyllning av sprängsten på pålar för väg, plan, järnväg o d",
  "CEB.6123":
    "Fyllning för bankfyllning av restprodukter på pålar för väg, plan, järnväg o d",
  "CEB.6124":
    "Fyllning för bankfyllning av återvunna material på pålar för väg, plan, järnväg o d",
  "CEB.62": "Fyllning på påldäck för väg, plan, järnväg o d",
  "CEB.7": "Fyllning efter urgrävning m m",
  "CEB.71": "Fyllning efter urgrävning för väg, plan, byggnad, järnväg o d",
  "CEB.711": "Fyllning efter urgrävning för väg",
  "CEB.7111": "Fyllning efter urgrävning för väg kategori A",
  "CEB.72": "Fyllning genom nedpressning för väg, plan, järnväg o d",
  "CEB.73": "Fyllning för förbelastning för väg, plan, byggnad, järnväg o d",
  "CEB.74": "Fyllning för tryckbank för väg, plan, järnväg o d",
  "CEB.75": "Fyllning för damm, bassäng, kanal o d",
  "CEB.751": "Fyllning för dammanläggning",
  "CEB.7511": "Fyllning för dammkropp, tätjord",
  "CEB.7512": "Fyllning för dammkropp, filterjord",
  "CEB.7513": "Fyllning för dammkropp, stödfyllning",
  "CEB.7514": "Fyllning för dammkropp, övergångslager",
  "CEB.7515": "Fyllning för dammkropp, erosionsskydd",
  "CEB.7516": "Fyllning för dammkropp, tåsten",
  "CEB.7518": "Fyllning för dammkropp, diverse fyllning",
  "CEB.76": "Fyllning för erosionsskydd",
  "CEB.77": "Fyllning för avfallsanläggning",
  "CEB.8": "Diverse fyllning",
  "CEB.81": "Fyllning efter förschakt",
  "CEB.82": "Fyllning som stödfyllning mot lätta material",
  "CEB.83": "Fyllning för bädd vid jordförstärkning",
  CEC: "FYLLNING FÖR LEDNING, MAGASIN M M",
  "CEC.1": "Fyllning för förstärkning av ledningsbädd, utspetsning m m",
  "CEC.11": "Fyllning för förstärkning av ledningsbädd",
  "CEC.12": "Fyllning för utspetsning för ledning",
  "CEC.13": "Fyllning efter urgrävning till viss nivå för ledning",
  "CEC.14":
    "Fyllning för tjälskydd av trumma med tjock trumbädd under ledningsbädd",
  "CEC.15":
    "Fyllning under och kring isolering i isolerad trumbädd, under ledningsbädd",
  "CEC.2": "Fyllning för ledningsbädd",
  "CEC.21": "Ledningsbädd för rörledning",
  "CEC.211": "Ledningsbädd för va-ledning o d",
  "CEC.2111": "Ledningsbädd för va-ledning",
  "CEC.2112": "Ledningsbädd för dränledning",
  "CEC.2113": "Ledningsbädd för gasledning",
  "CEC.2114":
    "Ledningsbädd för ledning i väg, plan o d kategori A samt järnväg",
  "CEC.212": "Ledningsbädd för trumma",
  "CEC.2121": "Ledningsbädd för vägtrumma",
  "CEC.2122": "Ledningsbädd för järnvägstrumma",
  "CEC.213": "Ledningsbädd för värmeledning o d",
  "CEC.2131": "Ledningsbädd för värmeledning",
  "CEC.2132": "Ledningsbädd för kylledning",
  "CEC.22": "Ledningsbädd för el- och telekabel o d",
  "CEC.3": "Kringfyllning",
  "CEC.31": "Kringfyllning för rörledning",
  "CEC.311": "Kringfyllning för va-ledning o d",
  "CEC.3111": "Kringfyllning för va-ledning",
  "CEC.3112": "Kringfyllning för dränledning",
  "CEC.3113": "Kringfyllning för gasledning",
  "CEC.3114":
    "Kringfyllning för ledning i väg, plan o d kategori A samt järnväg",
  "CEC.312": "Kringfyllning för trumma",
  "CEC.3121": "Kringfyllning för vägtrumma",
  "CEC.3122": "Kringfyllning för järnvägstrumma",
  "CEC.313": "Kringfyllning för värmeledning o d",
  "CEC.3131": "Kringfyllning för värmeledning",
  "CEC.3132": "Kringfyllning för kylledning",
  "CEC.32": "Kringfyllning för el- och telekabel o d",
  "CEC.33": "Kringfyllning för avstängningsanordning, nedstigningsbrunn m m",
  "CEC.34": "Kringfyllning för perkolationsbrunn",
  "CEC.341": "Kringfyllning för perkolationsbrunn, typ 1",
  "CEC.342": "Kringfyllning för perkolationsbrunn, typ 2",
  "CEC.35": "Kringfyllning för anordning med elektrokemiskt skydd",
  "CEC.4": "Resterande fyllning",
  "CEC.41": "Resterande fyllning för rörledning",
  "CEC.411": "Resterande fyllning för va-ledning o d",
  "CEC.4111": "Resterande fyllning för va-ledning",
  "CEC.4112": "Resterande fyllning för dränledning",
  "CEC.4113": "Resterande fyllning för gasledning",
  "CEC.4114":
    "Resterande fyllning för ledning i väg, plan o d kategori A samt järnväg",
  "CEC.412": "Resterande fyllning för trumma",
  "CEC.4121": "Resterande fyllning för vägtrumma",
  "CEC.4122": "Resterande fyllning för järnvägstrumma",
  "CEC.413": "Resterande fyllning för värmeledning o d",
  "CEC.4131": "Resterande fyllning för värmeledning",
  "CEC.4132": "Resterande fyllning för kylledning",
  "CEC.42": "Resterande fyllning för el- och telekabel o d",
  "CEC.5": "Fyllning för ledningsbank",
  "CEC.51": "Fyllning för ledningsbank, packning till färdig överyta",
  "CEC.52": "Fyllning för ledningsbank, utan packning till färdig överyta",
  "CEC.6": "Fyllning för dike, magasin, infiltrationsbäddar m m",
  "CEC.61": "Fyllning för perkolationsmagasin m m",
  "CEC.611": "Fyllning för friliggande perkolationsmagasin",
  "CEC.612": "Fyllning för perkolationsmagasin i ledningsgrav",
  "CEC.62": "Fyllning för infiltrationsbädd",
  "CEC.63": "Fyllning för dike",
  "CEC.7": "Strömningsavskärande fyllning",
  "CEC.71": "Strömningsavskärande fyllning med tätjord",
  "CEC.72": "Strömningsavskärande fyllning med bentonitblandad sand",
  CED: "FYLLNING FÖR VÄG, BYGGNAD, BRO M M MED LÄTTA MATERIAL",
  "CED.1":
    "Fyllning för väg, plan o d, byggnad, bro, järnväg samt vegetationsyta m m med lätta material",
  "CED.11":
    "Fyllning med lättklinker för väg, plan o d, byggnad, bro, järnväg samt vegetationsyta m m",
  "CED.111": "Fyllning med lättklinker för väg, plan o d samt järnväg",
  "CED.112": "Fyllning med lättklinker för byggnad, bro, mur m m",
  "CED.1121":
    "Fyllning med lättklinker för grundläggning av byggnad, bro, mur m m",
  "CED.1122": "Fyllning med lättklinker mot byggnad, bro, mur m m",
  "CED.113": "Fyllning med lättklinker för vegetationsyta",
  "CED.114": "Fyllning med lättklinker i ledningsgrav",
  "CED.12":
    "Fyllning med skumbetong för väg, plan o d, byggnad, bro, järnväg samt vegetationsyta m m",
  "CED.121": "Fyllning med skumbetong för väg, plan o d samt järnväg",
  "CED.122": "Fyllning med skumbetong mot byggnad, bro m m",
  "CED.123": "Fyllning med skumbetong för vegetationsyta",
  "CED.13":
    "Fyllning med skumglas för väg, plan o d, byggnad, bro, järnväg samt vegetationsyta m m",
  "CED.131": "Fyllning med skumglas för väg, plan o d samt järnväg",
  "CED.132": "Fyllning med skumglas för byggnad, bro, mur m m",
  "CED.1321":
    "Fyllning med skumglas för grundläggning av byggnad, bro, mur m m",
  "CED.1322": "Fyllning med skumglas mot byggnad, bro, mur m m",
  "CED.133": "Fyllning med skumglas för vegetationsyta",
  "CED.134": "Fyllning med skumglas i ledningsgrav",
  CEE: "TÄTNINGS- OCH AVJÄMNINGSLAGER FÖR VÄG, BYGGNAD, JÄRNVÄG, BRO M M",
  "CEE.1":
    "Tätning och avjämning av bergterrass för väg, byggnad, järnväg, bro m m",
  "CEE.11": "Tätning och avjämning av bergterrass för väg, plan o d",
  "CEE.111":
    "Tätning och avjämning kategori A av bergterrass för väg, plan o d",
  "CEE.1111":
    "Tätning och avjämning kategori A av bergterrass för väg, plan o d utan krav på lagertjocklek",
  "CEE.1112":
    "Tätning och avjämning kategori A av bergterrass för väg, plan o d med krav på lagertjocklek",
  "CEE.112":
    "Tätning och avjämning kategori B och C av bergterrass för väg, plan o d samt sammansatt yta",
  "CEE.12":
    "Tätning och avjämning av bergterrass för byggnad, mur, järnväg, bro m m",
  "CEE.121": "Tätning och avjämning av bergterrass för byggnad",
  "CEE.122":
    "Tätning och avjämning av bergterrass för mur, trappa, fundament m m",
  "CEE.123": "Tätning och avjämning av bergterrass för järnväg",
  "CEE.124": "Tätning och avjämning av bergterrass för bro",
  "CEE.125": "Tätning och avjämning av bergterrass i ledningsgrav",
  "CEE.126": "Tätning och avjämning av bergterrass för vegetationsyta",
  "CEE.2": "Tätning och avjämning av bergbankslänt och bergskärningsslänt",
  "CEE.3": "Tätning och avjämning av jordterrass",
  "CEE.31": "Tätning och avjämning av naturligt lagrad jord",
  "CEE.32": "Tätning och avjämning av jordfyllning",
  "CEE.4": "Tätning och avjämning av sprängstensfyllning",
  CEF: "DRÄNERANDE LAGER OCH KAPILLÄRBRYTANDE LAGER",
  "CEF.1": "Dränerande lager",
  "CEF.11": "Dränerande lager för väg, plan o d samt vegetationsyta",
  "CEF.111":
    "Dränerande lager av sand, grus eller krossmaterial för väg, plan o d",
  "CEF.1111":
    "Dränerande lager av sand, grus eller krossmaterial under underbyggnad för väg, plan o d",
  "CEF.1112":
    "Dränerande lager av sand, grus eller krossmaterial i underbyggnad för väg, plan o d",
  "CEF.112": "Dränerande lager av grus eller krossmaterial för vegetationsyta",
  "CEF.12": "Dränerande lager för byggnad, bro, mur m m",
  "CEF.121":
    "Dränerande lager av grus eller krossmaterial för byggnad, bro, mur m m",
  "CEF.1211":
    "Dränerande lager av grus eller krossmaterial under fyllning för byggnad",
  "CEF.1212":
    "Dränerande lager av grus eller krossmaterial i fyllning för byggnad",
  "CEF.1213": "Dränerande lager av grus och krossmaterial under byggnad",
  "CEF.1214": "Dränerande lager av grus och krossmaterial mot byggnad",
  "CEF.1217":
    "Dränerande lager av grus eller krossmaterial för terrängmur, terrängtrappa o d",
  "CEF.122": "Dränerande lager av lättklinker för byggnad",
  "CEF.1221": "Dränerande lager av lättklinker under byggnad",
  "CEF.1222": "Dränerande lager av lättklinker mot byggnad",
  "CEF.13": "Dränerande lager på byggnadskonstruktion",
  "CEF.131": "Dränerande lager av singel eller makadam på byggnadskonstruktion",
  "CEF.132": "Dränerande lager av cementbunden makadam på byggnadskonstruktion",
  "CEF.133": "Dränerande lager av lättklinker på byggnadskonstruktion",
  "CEF.134": "Dränerande lager av mattor o d på byggnadskonstruktion",
  "CEF.14": "Dränerande lager för järnväg",
  "CEF.141":
    "Dränerande lager av grus eller krossmaterial under underbyggnad för järnväg",
  "CEF.142":
    "Dränerande lager av grus eller krossmaterial i underbyggnad för järnväg",
  "CEF.15": "Dränerande lager för avfallsanläggning",
  "CEF.151": "Dränerande lager under avfallsanläggning",
  "CEF.152": "Dränerande lager i avfallsanläggning",
  "CEF.153": "Dränerande lager över avfallsanläggning",
  "CEF.16": "Dränerande lager för vertikaldränering",
  "CEF.2": "Dränerande och kapillärbrytande lager",
  "CEF.21": "Dränerande och kapillärbrytande lager för byggnad",
  "CEF.211":
    "Dränerande och kapillärbrytande lager av singel eller makadam för byggnad",
  "CEF.2111":
    "Dränerande och kapillärbrytande lager av singel eller makadam under byggnad",
  "CEF.2112":
    "Dränerande och kapillärbrytande lager av singel eller makadam mot byggnad",
  "CEF.22": "Dränerande och kapillärbrytande lager i avfallsanläggning",
  CEG: "MATERIALSKILJANDE LAGER FÖR VÄG, BYGGNAD, BRO M M AV JORD- OCH KROSSMATERIAL",
  "CEG.1":
    "Materialskiljande lager under fyllning för väg, byggnad, bro, järnväg m m",
  "CEG.11":
    "Materialskiljande lager under fyllning för väg, plan o d samt järnväg",
  "CEG.111":
    "Materialskiljande lager kategori A under fyllning för väg, plan o d",
  "CEG.112":
    "Materialskiljande lager kategori B och C under fyllning för väg, plan o d samt sammansatt yta",
  "CEG.113": "Materialskiljande lager under fyllning för järnväg",
  "CEG.12": "Materialskiljande lager under fyllning för byggnad, bro o d",
  "CEG.121": "Materialskiljande lager under fyllning för byggnad",
  "CEG.122": "Materialskiljande lager under fyllning för bro",
  "CEG.2":
    "Materialskiljande lager under eller kring fyllning för ledningsbädd, dränering o d",
  "CEG.21":
    "Materialskiljande lager under eller kring fyllning för ledningsbädd m m",
  "CEG.22": "Materialskiljande lager under eller kring fyllning för dränering",
  "CEG.3": "Materialskiljande lager under överbyggnad för väg, plan o d",
  "CEG.31":
    "Materialskiljande lager kategori A under överbyggnad för väg, plan o d",
  "CEG.311":
    "Materialskiljande lager kategori A under överbyggnad, på terrass av materialtyp 4",
  "CEG.312":
    "Materialskiljande lager kategori A under överbyggnad, på terrass av materialtyp 5",
  "CEG.32":
    "Materialskiljande lager kategori B och C under överbyggnad för väg, plan o d samt sammansatt yta",
  "CEG.321":
    "Materialskiljande lager kategori B och C under överbyggnad, på terrass av materialtyp 4",
  "CEG.322":
    "Materialskiljande lager kategori B och C under överbyggnad, på terrass av materialtyp 5",
  CF: "UTTAG AV MASSOR, AVLÄMNANDE AV MASSOR OCH AVFALL",
  CFB: "UTTAG AV MASSOR",
  CFC: "AVLÄMNANDE AV MASSOR ELLER AVFALL",
  "CFC.3": "Avlämnande av avfall till avfallsanläggning",
  "CFC.4": "Avlämnande\nav massor på upplag",
  CL: "GAS- OCH VÄTSKETÄTANDE LAGER",
  CLB: "GAS- OCH VÄTSKETÄTANDE LAGER AV JORDMATERIAL",
  "CLB.1": "Tätande lager av jordmaterial",
  "CLB.2": "Tätande lager av bentonitblandad sand",
  "CLB.3": "Tätande lager av cementstabiliserat material",
  "CLB.4": "Tätande lager av restprodukter",
  D: "MARKÖVERBYGGNADER, ANLÄGGNINGSKOMPLETTERINGAR M M",
  DB: "LAGER AV GEOSYNTET, CELLPLAST, MINERALULL, STÅL M M",
  DBB: "LAGER AV GEOSYNTET",
  "DBB.3": "Materialskiljande lager av geosyntet",
  "DBB.31": "Materialskiljande lager av geotextil",
  "DBB.311": "Materialskiljande lager av geotextil under fyllning",
  "DBB.3111":
    "Materialskiljande lager av geotextil under fyllning för väg, plan o d",
  "DBB.3112": "Materialskiljande lager av geotextil under fyllning för järnväg",
  "DBB.3113": "Materialskiljande lager av geotextil under fyllning för bro",
  "DBB.3114":
    "Materialskiljande lager av geotextil under fyllning för brygga, kaj, kassun o d",
  "DBB.3115":
    "Materialskiljande lager av geotextil under fyllning för mur, trappa, mast, torn, fyr o d",
  "DBB.3116": "Materialskiljande lager av geotextil under fyllning för byggnad",
  "DBB.312":
    "Materialskiljande lager av geotextil i ledningsgrav, för perkolationsmagasin m m",
  "DBB.3121": "Materialskiljande lager av geotextil i ledningsgrav",
  "DBB.3122":
    "Materialskiljande lager av geotextil kring perkolationsbrunn, perkolationsmagasin o d",
  "DBB.313": "Materialskiljande lager av geotextil under överbyggnad",
  "DBB.3131":
    "Materialskiljande lager av geotextil under överbyggnad för väg, plan o d",
  "DBB.3132":
    "Materialskiljande lager av geotextil under överbyggnad för järnväg",
  "DBB.3133":
    "Materialskiljande lager av geotextil under överbyggnad för vegetationsyta",
  "DBB.314": "Materialskiljande lager av geotextil i fyllning under byggnad",
  "DBB.315": "Materialskiljande lager av geotextil på byggnad",
  "DBB.4": "Armerande lager av geosyntet",
  "DBB.41": "Armerande lager av geonät",
  "DBB.411": "Armerande lager av geonät i obundna lager",
  "DBB.412": "Armerande lager av geonät i fyllning",
  "DBB.4121": "Armerande lager av geonät över bankpålning",
  "DBB.4122": "Armerande lager av geonät i mur",
  "DBB.4123": "Armerande lager av geonät i brant slänt",
  "DBB.42": "Armerande lager av geotextil",
  "DBB.421": "Armerande lager av geotextil i obundna lager",
  "DBB.422": "Armerande lager av geotextil i fyllning",
  "DBB.4221": "Armerande lager av geotextil över bankpålning",
  "DBB.4222": "Armerande lager av geotextil i mur",
  "DBB.4223": "Armerande lager av geotextil i brant slänt",
  "DBB.5": "Tätande lager av geosyntet",
  "DBB.51": "Tätande lager av polymeriskt geosyntetiskt tätskikt",
  "DBB.52": "Tätande lager av lergeomembran",
  "DBB.53": "Tätande lager av bitumen geomembran",
  "DBB.6": "Dränerande lager av geosyntet",
  "DBB.7": "Skyddande lager av geosyntet",
  "DBB.71": "Skyddande lager av geotextil",
  "DBB.8": "Diverse lager av geosyntet",
  "DBB.81": "Lager av geosyntet i bitumenbundna lager",
  DBF: "LAGER AV SKIVOR AV MINERALULL",
  DBG: "LAGER AV SKIVOR ELLER BLOCK AV CELLPLAST",
  "DBG.1": "Termoisolerande lager av skivor av cellplast",
  "DBG.11":
    "Termoisolerande lager av skivor av cellplast för väg, ledning, bro m m",
  "DBG.111": "Termisk isolering med isolerskivor av väg, plan o d",
  "DBG.112":
    "Termisk isolering med isolerskivor av rörledning och trumma i mark",
  "DBG.1121": "Termisk isolering med isolerskivor av rörledning i mark",
  "DBG.1122": "Termisk isolering med isolerskivor av trumma",
  "DBG.113": "Termisk isolering med isolerskivor av bro, stödmur m m",
  "DBG.1131": "Termisk isolering med isolerskivor mot bro, stödmur",
  "DBG.1132":
    "Termisk isolering med isolerskivor av grundläggningsyta under bro, stödmur, terrängtrappa m m",
  "DBG.12": "Termisk isolering med isolerskivor för järnväg",
  "DBG.13":
    "Termoisolerande lager av skivor av cellplast för tunnel och bergrum",
  "DBG.2": "Dränerande lager av skivor av cellplast",
  "DBG.21":
    "Dränerande lager av skivor för väg, plan o d, järnväg samt vegetationsyta",
  "DBG.213": "Dränerande lager av skivor för vegetationsyta",
  "DBG.2132":
    "Dränerande lager av skivor för vegetationsyta på byggnadskonstruktion",
  "DBG.22":
    "Dränerande lager av skivor för kassun, bro, terrängmur o d samt för byggnad",
  "DBG.223": "Dränerande lager av skivor för terrängmur, terrängtrappa o d",
  "DBG.224": "Dränerande lager av skivor för byggnad",
  "DBG.2241": "Dränerande lager av skivor under byggnad",
  "DBG.2242": "Dränerande lager av skivor mot byggnad",
  "DBG.2243": "Dränerande lager av skivor på byggnadskonstruktion",
  "DBG.3": "Lastreducerande lager av skivor eller block av cellplast",
  "DBG.31":
    "Lastreducerande lager av skivor eller block av cellplast för väg, plan o d",
  "DBG.32":
    "Lastreducerande lager av skivor eller block av cellplast för bro, terrängmur o d",
  "DBG.33":
    "Lastreducerande lager av skivor eller block av cellplast för grundläggning av byggnad",
  "DBG.34":
    "Lastreducerande lager av skivor eller block av cellplast för vegetationsyta",
  "DBG.35":
    "Lastreducerande lager av skivor eller block av cellplast för rörledning",
  "DBG.4": "Lastfördelande lager av skivor av cellplast",
  DBH: "LAGER AV STÅL",
  "DBH.1": "Armerande lager av stål",
  "DBH.11": "Armerande lager av stål i bitumenbundna lager",
  "DBH.12": "Armerande lager av stål i obundna överbyggnadslager",
  DBJ: "LAGER AV PLASTFILM",
  "DBJ.1": "Skyddslager av plastfilm",
  DC: "MARKÖVERBYGGNADER M M",
  DCB: "OBUNDNA ÖVERBYGGNADSLAGER FÖR VÄG, PLAN O D",
  "DCB.1": "Undre förstärkningslager för väg, plan o d",
  "DCB.11": "Undre förstärkningslager kategori A",
  "DCB.12": "Undre förstärkningslager kategori B",
  "DCB.2": "Förstärkningslager för väg, plan o d",
  "DCB.21":
    "Förstärkningslager till överbyggnad med flexibel konstruktion och med bitumenbundet slitlager, betongmarkplattor m m",
  "DCB.211":
    "Förstärkningslager kategori A till överbyggnad med flexibel konstruktion och med bitumenbundet slitlager, betongmarkplattor m m",
  "DCB.212":
    "Förstärkningslager kategori B till överbyggnad med flexibel konstruktion och med bitumenbundet slitlager, betongmarkplattor m m",
  "DCB.213":
    "Förstärkningslager kategori C till överbyggnad med flexibel konstruktion och med bitumenbundet slitlager, betongmarkplattor m m",
  "DCB.22": "Förstärkningslager till överbyggnad med styv konstruktion",
  "DCB.221":
    "Förstärkningslager kategori A till överbyggnad med styv konstruktion",
  "DCB.222":
    "Förstärkningslager kategori B till överbyggnad med styv konstruktion",
  "DCB.23":
    "Förstärkningslager till överbyggnad med flexibel konstruktion och med obundet slitlager",
  "DCB.231":
    "Förstärkningslager kategori A till överbyggnad med flexibel konstruktion och med obundet slitlager",
  "DCB.232":
    "Förstärkningslager kategori B till överbyggnad med flexibel konstruktion och med obundet slitlager",
  "DCB.233":
    "Förstärkningslager kategori C till överbyggnad med flexibel konstruktion och med obundet slitlager",
  "DCB.25": "Förstärkningslager över termisk isolering med isolerskivor",
  "DCB.3": "Obundet bärlager för väg, plan o d",
  "DCB.31": "Obundet bärlager till belagda ytor",
  "DCB.311": "Obundet bärlager kategori A till belagda ytor",
  "DCB.312": "Obundet bärlager kategori B till belagda ytor",
  "DCB.313": "Obundet bärlager kategori C till belagda ytor",
  "DCB.32": "Obundet bärlager till ytor med obundet slitlager",
  "DCB.321": "Obundet bärlager kategori A till ytor med obundet slitlager",
  "DCB.322": "Obundet bärlager kategori B till ytor med obundet slitlager",
  "DCB.323": "Obundet bärlager kategori C till ytor med obundet slitlager",
  "DCB.4": "Slitlager av grus, stenmjöl m m för väg, plan o d",
  "DCB.41": "Slitlager av grus",
  "DCB.411": "Slitlager av grus kategori A",
  "DCB.412": "Slitlager av grus kategori B och C",
  "DCB.413": "Slitlager av grus för lekytor m m",
  "DCB.42": "Slitlager av stenmjöl",
  "DCB.48": "Slitlager av diverse material",
  "DCB.481": "Slitlager av krossat tegel, marmorkross m m",
  "DCB.482": "Slitlager av bark, träspån o d",
  "DCB.5": "Justeringslager av obundet material för väg, plan o d",
  "DCB.51": "Justeringslager av undre förstärkningslagermaterial",
  "DCB.511": "Justeringslager av undre förstärkningslagermaterial kategori A",
  "DCB.512": "Justeringslager av undre förstärkningslagermaterial kategori B",
  "DCB.52":
    "Justeringslager av förstärkningslagermaterial till överbyggnad med flexibel konstruktion och med bitumenbundet slitlager, betongmarkplattor m m",
  "DCB.521":
    "Justeringslager av förstärkningslagermaterial kategori A till överbyggnad med flexibel konstruktion och med bitumenbundet slitlager, betongmarkplattor m m",
  "DCB.522":
    "Justeringslager av förstärkningslagermaterial kategori B till överbyggnad med flexibel konstruktion och med bitumenbundet slitlager, betongmarkplattor m m",
  "DCB.523":
    "Justeringslager av förstärkningslagermaterial kategori C till överbyggnad med flexibel konstruktion och med bitumenbundet slitlager, betongmarkplattor m m",
  "DCB.53":
    "Justeringslager av förstärkningslagermaterial till överbyggnad med styv konstruktion",
  "DCB.531":
    "Justeringslager av förstärkningslagermaterial kategori A till överbyggnad med styv konstruktion",
  "DCB.532":
    "Justeringslager av förstärkningslagermaterial kategori B till överbyggnad med styv konstruktion",
  "DCB.54":
    "Justeringslager av förstärkningslagermaterial till överbyggnad med flexibel konstruktion och med obundet slitlager",
  "DCB.541":
    "Justeringslager av förstärkningslagermaterial kategori A till överbyggnad med flexibel konstruktion och med obundet slitlager",
  "DCB.542":
    "Justeringslager av förstärkningslagermaterial kategori B till överbyggnad med flexibel konstruktion och med obundet slitlager",
  "DCB.543":
    "Justeringslager av förstärkningslagermaterial kategori C till överbyggnad med flexibel konstruktion och med obundet slitlager",
  "DCB.55": "Justeringslager av obundet bärlagermaterial till belagda ytor",
  "DCB.551":
    "Justeringslager av obundet bärlagermaterial kategori A till belagda ytor",
  "DCB.552":
    "Justeringslager av obundet bärlagermaterial kategori B till belagda ytor",
  "DCB.553":
    "Justeringslager av obundet bärlagermaterial kategori C till belagda ytor",
  "DCB.56":
    "Justeringslager av obundet bärlagermaterial till ytor med obundet slitlager",
  "DCB.561":
    "Justeringslager av obundet bärlagermaterial kategori A till ytor med obundet slitlager",
  "DCB.562":
    "Justeringslager av obundet bärlagermaterial kategori B till ytor med obundet slitlager",
  "DCB.563":
    "Justeringslager av obundet bärlagermaterial kategori C till ytor med obundet slitlager",
  "DCB.57": "Justeringslager av slitlagermaterial av grus",
  "DCB.571": "Justeringslager av slitlagermaterial av grus kategori A",
  "DCB.572": "Justeringslager av slitlagermaterial av grus kategori B och C",
  "DCB.58": "Justeringslager av slitlagermaterial av stenmjöl",
  "DCB.581": "Justeringslager av slitlagermaterial av stenmjöl kategori A",
  "DCB.582":
    "Justeringslager av slitlagermaterial av stenmjöl kategori B och C",
  "DCB.6": "Stödremsa för väg, plan o d",
  "DCB.61": "Stödremsa av obundet bärlagermaterial till belagda ytor",
  "DCB.611":
    "Stödremsa av obundet bärlagermaterial kategori A till belagda ytor",
  "DCB.612":
    "Stödremsa av obundet bärlagermaterial kategori B till belagda ytor",
  "DCB.613":
    "Stödremsa av obundet bärlagermaterial kategori C till belagda ytor",
  "DCB.62": "Stödremsa av obundet slitlagermaterial till belagda ytor",
  "DCB.621":
    "Stödremsa av obundet slitlagermaterial kategori A till belagda ytor",
  "DCB.622":
    "Stödremsa av obundet slitlagermaterial kategori B och C till belagda ytor",
  "DCB.7": "Inblandade obundna överbyggnadslager för väg, plan o d",
  "DCB.71": "Infrästa obundna överbyggnadslager för väg, plan o d",
  "DCB.711":
    "Obundna överbyggnadslager för väg, plan o d av infräst obundet material",
  "DCB.712":
    "Obundna överbyggnadslager för väg, plan o d av infräst beläggning",
  DCC: "BITUMENBUNDNA ÖVERBYGGNADSLAGER FÖR VÄG, PLAN O D",
  "DCC.1": "Bitumenbundna överbyggnadslager kategori A för väg, plan o d",
  "DCC.11": "Bitumenbundna bärlager kategori A",
  "DCC.111": "Bärlager kategori A av asfaltmassa",
  "DCC.1111": "Bärlager kategori A av asfaltgrus",
  "DCC.1113": "Bärlager kategori A av tät asfaltbetong",
  "DCC.1114": "Bärlager kategori A av mjukgjort asfaltgrus",
  "DCC.113": "Bärlager kategori A av indränkt makadam",
  "DCC.114": "Bärlager kategori A av specialblandning",
  "DCC.115": "Bärlager kategori A av kall återvinningsmassa",
  "DCC.116": "Bärlager kategori A av halvvarm återvinningsmassa",
  "DCC.12": "Bitumenbundna bindlager kategori A",
  "DCC.121": "Bindlager kategori A av asfaltmassa",
  "DCC.1211": "Bindlager kategori A av tät asfaltbetong",
  "DCC.1213": "Bindlager kategori A av asfaltbetong",
  "DCC.122": "Bindlager kategori A av gjutasfaltmassa",
  "DCC.1221": "Bindlager kategori A av gjutasfalt",
  "DCC.123": "Bindlager kategori A av specialblandning",
  "DCC.13": "Bitumenbundna justeringslager kategori A",
  "DCC.131": "Justeringslager kategori A av asfaltmassa",
  "DCC.1311": "Justeringslager kategori A av asfaltgrus",
  "DCC.1313": "Justeringslager kategori A av tät asfaltbetong",
  "DCC.1315": "Justeringslager kategori A av asfaltbetong",
  "DCC.1317": "Justeringslager kategori A av mjukgjort asfaltgrus",
  "DCC.132": "Justeringslager kategori A av gjutasfaltmassa",
  "DCC.1321": "Justeringslager kategori A av gjutasfalt",
  "DCC.1322": "Spårlagning kategori A med spårgjutasfalt",
  "DCC.1323":
    "Komplettering med bituminiserad chipsten på justeringslager av gjutasfalt, kategori A",
  "DCC.134": "Justeringslager kategori A av indränkt makadam",
  "DCC.136": "Justeringslager kategori A av specialblandning",
  "DCC.138": "Diverse bitumenbundna justeringslager kategori A",
  "DCC.1381": "Justeringslager kategori A av kall återvinningsmassa",
  "DCC.1382": "Justeringslager kategori A av halvvarm återvinningsmassa",
  "DCC.14": "Bitumenbundna slitlager kategori A",
  "DCC.141": "Slitlager kategori A av asfaltmassa",
  "DCC.1411": "Slitlager kategori A av tät asfaltbetong",
  "DCC.1412": "Slitlager kategori A av stenrik asfaltbetong",
  "DCC.1413": "Slitlager kategori A av dränerande asfaltbetong",
  "DCC.1415": "Slitlager kategori A av mjukbitumenbundet grus",
  "DCC.142": "Slitlager kategori A av gjutasfaltmassa",
  "DCC.1421": "Slitlager kategori A av gjutasfalt med bituminiserad chipsten",
  "DCC.1422": "Slitlager kategori A av gjutasfalt",
  "DCC.1423":
    "Komplettering med bituminiserad chipsten på slitlager av gjutasfalt, kategori A",
  "DCC.144": "Slitlager kategori A av specialblandning",
  "DCC.1441": "Slitlager kategori A av cementstabiliserad asfalt",
  "DCC.145": "Slitlager kategori A av tunnskiktsbeläggning",
  "DCC.146": "Slitlager kategori A av kall återvinningsmassa",
  "DCC.147": "Slitlager kategori A av halvvarm återvinningsmassa",
  "DCC.15":
    "Slitlager kategori A av ytbehandling med bituminöst bindemedel och pågrus",
  "DCC.151": "Slitlager kategori A av ytbehandling på bitumenbundet underlag",
  "DCC.1511": "Enkel ytbehandling kategori A på bitumenbundet underlag",
  "DCC.1512": "Dubbel ytbehandling kategori A på bitumenbundet underlag",
  "DCC.1513": "Spårytbehandling kategori A på bitumenbundet underlag",
  "DCC.152": "Slitlager kategori A av ytbehandling på grus",
  "DCC.1521": "Enkel ytbehandling kategori A på grus",
  "DCC.153": "Slitlager kategori A av ytbehandling av specialblandning",
  "DCC.154": "Slitlager kategori A av tätad indränkt makadam",
  "DCC.16": "Värmebeläggningar kategori A",
  "DCC.161": "Slitlager kategori A utförda med heating",
  "DCC.1611": "Slitlager kategori A av tät asfaltbetong utfört med heating",
  "DCC.1612": "Slitlager kategori A av stenrik asfaltbetong utfört med heating",
  "DCC.162": "Slitlager kategori A utförda med repaving",
  "DCC.1621": "Slitlager kategori A av tät asfaltbetong utförd med repaving",
  "DCC.1622":
    "Slitlager kategori A av stenrik asfaltbetong utfört med repaving",
  "DCC.163": "Slitlager kategori A utförda med remixing",
  "DCC.1631": "Slitlager kategori A av tät asfaltbetong utfört med remixing",
  "DCC.1632":
    "Slitlager kategori A av stenrik asfaltbetong utfört med remixing",
  "DCC.164": "Slitlager kategori A utförda med remixing plus",
  "DCC.1641":
    "Slitlager kategori A av tät asfaltbetong utfört med remixing plus",
  "DCC.1642":
    "Slitlager kategori A av stenrik asfaltbetong utförd med remixing plus",
  "DCC.165": "Värmebeläggning kategori A av specialblandning",
  "DCC.17": "Stabilisering kategori A",
  "DCC.171": "Stabilisering kategori A med skumbitumen",
  "DCC.172": "Stabilisering kategori A med bitumenemulsion",
  "DCC.173": "Stabilisering kategori A med remixing",
  "DCC.2": "Bitumenbundna överbyggnadslager kategori B för väg, plan o d",
  "DCC.21": "Bitumenbundna bärlager kategori B",
  "DCC.211": "Bärlager kategori B av asfaltmassa",
  "DCC.2111": "Bärlager kategori B av asfaltgrus",
  "DCC.2113": "Bärlager kategori B av tät asfaltbetong",
  "DCC.2114": "Bärlager kategori B av mjukgjort asfaltgrus",
  "DCC.213": "Bärlager kategori B av indränkt makadam",
  "DCC.214": "Bärlager kategori B av specialblandning",
  "DCC.22": "Bitumenbundna bindlager kategori B",
  "DCC.221": "Bindlager kategori B av asfaltmassa",
  "DCC.2211": "Bindlager kategori B av tät asfaltbetong",
  "DCC.2213": "Bindlager kategori B av asfaltbetong",
  "DCC.222": "Bindlager kategori B av gjutasfaltmassa",
  "DCC.2221": "Bindlager kategori B av gjutasfalt",
  "DCC.223": "Bindlager kategori B av specialblandning",
  "DCC.23": "Bitumenbundna justeringslager kategori B",
  "DCC.231": "Justeringslager kategori B av asfaltmassa",
  "DCC.2311": "Justeringslager kategori B av asfaltgrus",
  "DCC.2313": "Justeringslager kategori B av tät asfaltbetong",
  "DCC.2315": "Justeringslager kategori B av asfaltbetong",
  "DCC.232": "Justeringslager kategori B av gjutasfaltmassa",
  "DCC.2321": "Justeringslager kategori B av gjutasfalt",
  "DCC.2322": "Spårlagning kategori B med spårgjutasfalt",
  "DCC.234": "Justeringslager kategori B av indränkt makadam",
  "DCC.235":
    "Justeringslager kategori B av ytbehandling på bituminöst underlag",
  "DCC.236": "Justeringslager kategori B av specialblandning",
  "DCC.24": "Bitumenbundna slitlager kategori B",
  "DCC.241": "Slitlager kategori B av asfaltmassa",
  "DCC.2411": "Slitlager kategori B av tät asfaltbetong",
  "DCC.2412": "Slitlager kategori B av stenrik asfaltbetong",
  "DCC.2413": "Slitlager kategori B av dränerande asfaltbetong",
  "DCC.2415": "Slitlager kategori B av mjukbitumenbundet grus",
  "DCC.242": "Slitlager kategori B av gjutasfaltmassa",
  "DCC.2421": "Slitlager kategori B av gjutasfalt med bituminiserad chipsten",
  "DCC.2422": "Slitlagerkategori B av gjutasfalt",
  "DCC.244": "Slitlager kategori B av specialblandning",
  "DCC.2441": "Slitlager kategori B av cementstabiliserad asfalt",
  "DCC.246": "Slitlagerkategori B av kall återvinningsmassa",
  "DCC.247": "Slitlager kategori B av halvvarm återvinningsmassa",
  "DCC.25":
    "Slitlager kategori B av ytbehandling med bituminöst bindemedel och på grus",
  "DCC.251": "Slitlager kategori B av ytbehandling på bitumenbundet underlag",
  "DCC.2511": "Enkel ytbehandling kategori B på bitumenbundet underlag",
  "DCC.2512": "Dubbel ytbehandling kategori B på bitumenbundet underlag",
  "DCC.252": "Slitlager kategori B av ytbehandling på grus",
  "DCC.2521": "Enkel ytbehandling kategori B på grus",
  "DCC.253": "Slitlager kategori B av ytbehandling av specialblandning",
  "DCC.254": "Slitlager kategori B av tätad indränkt makadam",
  "DCC.26": "Värmebeläggningar kategori B",
  "DCC.261": "Slitlager kategori B utförda med heating",
  "DCC.2611": "Slitlager kategori B av tät asfaltbetong utfört med heating",
  "DCC.2612": "Slitlager kategori B av stenrik asfaltbetong utfört med heating",
  "DCC.262": "Slitlager kategori B utförda med repaving",
  "DCC.2621": "Slitlager kategori B av tät asfaltbetong utförd med repaving",
  "DCC.2622":
    "Slitlager kategori B av stenrik asfaltbetong utfört med repaving",
  "DCC.263": "Slitlager kategori B utförda med remixing",
  "DCC.2631": "Slitlager kategori B av tät asfaltbetong utfört med remixing",
  "DCC.2632":
    "Slitlager kategori B av stenrik asfaltbetong utfört med remixing",
  "DCC.264": "Slitlager kategori B utförda med remixing plus",
  "DCC.2641":
    "Slitlager kategori B av tät asfaltbetong utfört med remixing plus",
  "DCC.2642":
    "Slitlager kategori B av stenrik asfaltbetong utförd med remixing plus",
  "DCC.265": "Värmebeläggning kategori B av specialblandning",
  "DCC.3": "Bitumenbundna överbyggnadslager kategori C för väg, plan o d",
  "DCC.31": "Bitumenbundna bärlager kategori C",
  "DCC.311": "Bärlager kategori C av asfaltmassa",
  "DCC.3111": "Bärlager kategori C av asfaltgrus",
  "DCC.3113": "Bärlager kategori C av tät asfaltbetong",
  "DCC.3114": "Bärlager kategori C av mjukgjort asfaltgrus",
  "DCC.313": "Bärlager kategori C av indränkt makadam",
  "DCC.314": "Bärlager kategori C av specialblandning",
  "DCC.32": "Bitumenbundna bindlager kategori C",
  "DCC.321": "Bindlager kategori C av asfaltmassa",
  "DCC.3211": "Bindlager kategori C av tät asfaltbetong",
  "DCC.3213": "Bindlager kategori C av asfaltbetong",
  "DCC.322": "Bindlager kategori C av gjutasfaltmassa",
  "DCC.3221": "Bindlager kategori C av gjutasfalt",
  "DCC.323": "Bindlager kategori C av specialblandning",
  "DCC.33": "Bitumenbundna justeringslager kategori C",
  "DCC.331": "Justeringslager kategori C av asfaltmassa",
  "DCC.3311": "Justeringslager kategori C av asfaltgrus",
  "DCC.3313": "Justeringslager kategori C av tät asfaltbetong",
  "DCC.3315": "Justeringslager kategori C av asfaltbetong",
  "DCC.332": "Justeringslager kategori C av gjutasfaltmassa",
  "DCC.3321": "Justeringslager kategori C av gjutasfalt",
  "DCC.3322": "Spårlagning kategori C med spårgjutasfalt",
  "DCC.334": "Justeringslager kategori C av indränkt makadam",
  "DCC.336": "Justeringslager kategori C av specialblandning",
  "DCC.34": "Bitumenbundna slitlager kategori C",
  "DCC.341": "Slitlager kategori C av asfaltmassa",
  "DCC.3411": "Slitlager kategori C av tät asfaltbetong",
  "DCC.3412": "Slitlager kategori C av stenrik asfaltbetong",
  "DCC.3413": "Slitlager kategori C av dränerande asfaltbetong",
  "DCC.3415": "Slitlager kategori C av mjukbitumenbundet grus",
  "DCC.342": "Slitlager kategori C av gjutasfaltmassa",
  "DCC.3421": "Slitlager kategori C av gjutasfalt med bituminiserad chipsten",
  "DCC.344": "Slitlager kategori C av specialblandning",
  "DCC.3441": "Slitlager kategori C av cementstabiliserad asfalt",
  "DCC.346": "Slitlager kategori C av kall återvinningsmassa",
  "DCC.35":
    "Slitlager kategori C av ytbehandling med bituminöst bindemedel och pågrus",
  "DCC.351": "Slitlager kategori C av ytbehandling på bitumenbundet underlag",
  "DCC.3511": "Enkel ytbehandling kategori C på bitumenbundet underlag",
  "DCC.3512": "Dubbel ytbehandling kategori C på bitumenbundet underlag",
  "DCC.352": "Slitlager kategori C av ytbehandling på grus",
  "DCC.3521": "Enkel ytbehandling kategori C på grus",
  "DCC.353": "Slitlager kategori C av ytbehandling av specialblandning",
  "DCC.354": "Slitlager kategori C av tätad indränkt makadam",
  "DCC.3542": "Slitlager kategori C av tätad indränkt makadam vid underhåll",
  "DCC.36": "Värmebeläggningar kategori C",
  "DCC.361": "Slitlager kategori C utförda med heating",
  "DCC.3611": "Slitlager kategori C av tät asfaltbetong utfört med heating",
  "DCC.3612": "Slitlager kategori C av stenrik asfaltbetong utfört med heating",
  "DCC.362": "Slitlager kategori C utförda med repaving",
  "DCC.3621": "Slitlager kategori C av tät asfaltbetong utförd med repaving",
  "DCC.3622":
    "Slitlager kategori C av stenrik asfaltbetong utfört med repaving",
  "DCC.363": "Slitlager kategori C utförda med remixing",
  "DCC.3631": "Slitlager kategori C av tät asfaltbetong utfört med remixing",
  "DCC.3632":
    "Slitlager kategori C av stenrik asfaltbetong utfört med remixing",
  "DCC.364": "Slitlager kategori C utförda med remixing plus",
  "DCC.3641":
    "Slitlager kategori C av tät asfaltbetong utfört med remixing plus",
  "DCC.3642":
    "Slitlager kategori C av stenrik asfaltbetong utförd med remixing plus",
  "DCC.365": "Värmebeläggning kategori C av specialblandning",
  DCD: "FÖRSEGLINGAR FÖR VÄG, PLAN O D",
  "DCD.1": "Försegling med bitumenemulsion eller bitumenlösning",
  "DCD.2": "Försegling med emulsionsslam",
  "DCD.3": "Försegling med bitumen",
  DCE: "CEMENTBUNDNA ÖVERBYGGNADSLAGER OCH FOGAR FÖR VÄG, PLAN O D",
  "DCE.1": "Cementbundna överbyggnadslager för väg, plan o d",
  "DCE.11": "Bärlager av cementbundet grus",
  "DCE.12": "Slitlager av cementbetong",
  "DCE.121": "Slitlager av cementbetong för väg",
  "DCE.122": "Slitlager av cementbetong för plan",
  "DCE.13": "Slitlager av vältbetong",
  "DCE.2": "Fogar och anslutningskonstruktioner för väg, plan o d",
  "DCE.21": "Fogar",
  "DCE.211": "Sammanhållningsfog, längsgående fog",
  "DCE.212": "Kontraktionsfog, tvärgående fog",
  "DCE.213": "Expansionsfog",
  "DCE.214": "Vägrensfog",
  "DCE.218": "Diverse fogar",
  "DCE.22": "Anslutningskonstruktioner",
  "DCE.221": "Anslutningskonstruktion mot flexibel konstruktion",
  "DCE.222": "Anslutningskonstruktion mot bro",
  "DCE.228": "Diverse anslutningskonstruktioner",
  DCF: "ÖVERBYGGNADSLAGER FÖR BRO, BRYGGA, KAJ, TUNNEL O D",
  "DCF.1":
    "Bitumenbundet skyddslager på tätskikt för bro, brygga, kaj, tunnel, kammare o d",
  "DCF.11":
    "Skyddslager på tätskikt av asfaltmastix för bro, brygga, kaj, tunnel, kammare o d",
  "DCF.111": "Skyddslager på tätskikt av asfaltmastix på bro",
  "DCF.112": "Skyddslager på tätskikt av asfaltmastix på brygga, kaj o d",
  "DCF.12":
    "Skyddslager på tätskikt av tätskiktsmatta för bro, brygga, kaj, tunnel, kammare o d",
  "DCF.121": "Skyddslager på tätskikt av tätskiktsmatta på bro",
  "DCF.122": "Skyddslager på tätskikt av tätskiktsmatta på brygga, kaj o d",
  "DCF.124": "Skyddslager på tätskikt av tätskiktsmatta för brunn, kammare e d",
  "DCF.2": "Bitumenbundet bind- och slitlager på bro, brygga, kaj, tunnel o d",
  "DCF.21":
    "Bind- och slitlager av asfaltbetong på bro, brygga, kaj, tunnel o d",
  "DCF.211": "Bind- och slitlager av asfaltbetong på bro",
  "DCF.2111": "Bindlager av asfaltbetong på bro",
  "DCF.2112": "Slitlager av asfaltbetong på bro",
  "DCF.212": "Bind- och slitlager av asfaltbetong på brygga, kaj o d",
  "DCF.2121": "Bindlager av asfaltbetong på brygga, kaj o d",
  "DCF.2122": "Slitlager av asfaltbetong på brygga, kaj o d",
  "DCF.22":
    "Bind- och slitlager av gjutasfalt för bro, brygga, kaj, tunnel o d",
  "DCF.221": "Bind- och slitlager av gjutasfalt på bro",
  "DCF.2211": "Bind- och slitlager av gjutasfalt på brobaneplatta av betong",
  "DCF.2212": "Bind- och slitlager av gjutasfalt på brobaneplatta av stål",
  "DCF.2213": "Slitlager av gjutasfalt på brobaneplatta av aluminium",
  "DCF.2214": "Slitlager av gjutasfalt på brobaneplatta av trä",
  "DCF.222": "Bind- och slitlager av gjutasfalt på brygga, kaj o d",
  "DCF.2221": "Bindlager av gjutasfalt på brygga, kaj o d",
  "DCF.2222": "Slitlager av gjutasfalt på brygga, kaj o d",
  "DCF.3": "Cementbundet slitlager på bro, brygga, kaj, tunnel o d",
  "DCF.31": "Cementbundet slitlager på bro",
  "DCF.311": "Slitlager av betong på tätskikt på bro",
  "DCF.312": "Direktgjutet slitlager av betong på bro",
  "DCF.3121": "Direktgjutet slitlager av betong på bro vid nybyggnad",
  "DCF.3122":
    "Direktgjutet slitlager av betong på bro vid förbättring eller reparation",
  "DCF.32": "Beläggningar av cementbetong på brygga, kaj o d",
  "DCF.4": "Slitlager av akrylat på bro, brygga, kaj o d",
  "DCF.41": "Slitlager av akrylat på bro",
  "DCF.411": "Slitlager av akrylat på brobaneplatta av stål",
  "DCF.412": "Slitlager av akrylat på brobaneplatta av aluminium",
  "DCF.6": "Fog med fogmassa",
  "DCF.7": "Obundna överbyggnadslager för bro, brygga, kaj, tunnel o d",
  "DCF.71": "Obundna överbyggnadslager på bro",
  "DCF.711": "Obundna skyddslager på tätskikt på bro",
  DCG: "MARKBELÄGGNINGAR",
  "DCG.1": "Beläggning av gatsten, naturstensplattor o d",
  "DCG.11": "Beläggning av gatsten",
  "DCG.111": "Beläggning av smågatsten",
  "DCG.112": "Beläggning av storgatsten",
  "DCG.113": "Beläggning av storgatsten med gräsfog",
  "DCG.12": "Beläggning av naturstensplattor",
  "DCG.13": "Beläggning av kullersten",
  "DCG.2": "Beläggning av betongmarkplattor, betongmarksten o d",
  "DCG.21": "Beläggning av betongmarkplattor",
  "DCG.22": "Beläggning av betongmarksten",
  "DCG.23": "Beläggning av gräsarmeringsplattor av betong",
  "DCG.3": "Beläggning av marktegel och trä",
  "DCG.32": "Beläggning av marktegel",
  "DCG.33": "Beläggning av trä m m",
  "DCG.331": "Beläggning av träkubb",
  "DCG.332": "Beläggning av trätrall",
  "DCG.4": "Markbeläggning av gummi",
  "DCG.41": "Beläggning av plattor, mattor o d av gummi",
  "DCG.42": "Beläggning av platsgjuten gummi",
  "DCG.5": "Markbeläggning av plast",
  "DCG.51": "Beläggning av plattor, mattor o d av plast",
  "DCG.511": "Beläggning av mattor av konstgräs",
  "DCG.53": "Beläggning av gräsarmeringsplattor av plast",
  "DCG.6": "Markbeläggning av metall",
  "DCG.61": "Beläggning av plattor av metall",
  "DCG.63": "Beläggning av gräsarmeringsplattor av metall",
  DCH: "ÖVERBYGGNADSLAGER FÖR JÄRNVÄG",
  "DCH.1": "Underballastlager för järnväg",
  "DCH.11": "Underballastlager av sprängsten",
  "DCH.12": "Underballastlager av grov krossad sprängsten",
  "DCH.13": "Underballastlager av krossad sprängsten",
  "DCH.14": "Underballastlager av grusmaterial",
  "DCH.15": "Underballastlager för förstärkning av järnväg",
  "DCH.16": "Underballastlager för frostisolering av järnväg",
  "DCH.2":
    "Lager av krossad sprängsten på underballastlager av sprängsten för järnväg",
  "DCH.3": "Ballastlager för järnväg",
  "DCH.31": "Ballastlager av makadam",
  "DCH.311": "Ballastlager av makadam klass I",
  "DCH.312": "Ballastlager av makadam klass II",
  "DCH.32": "Ballastlager av grus",
  "DCH.33": "Komplettering av ballastlager av makadam i befintligt spår",
  "DCH.331": "Komplettering av ballastlager av makadam klass I",
  "DCH.332": "Komplettering av ballastlager av makadam klass II",
  "DCH.34": "Komplettering av finmakadam i växlingsgator",
  DCJ: "SANDYTOR",
  "DCJ.1": "Sandyta av strid sand",
  "DCJ.11": "Strid sand för stötdämpande ytor",
  "DCJ.12": "Strid sand för idrottsytor o d",
  "DCJ.2": "Sandyta av formbar leksand",
  DCK: "SLÄNTBEKLÄDNADER OCH EROSIONSSKYDD",
  "DCK.1": "Släntbeklädnader",
  "DCK.11": "Släntbeklädnad av gatsten, naturstensplattor o d",
  "DCK.12": "Släntbeklädnad av betongmarkplattor, betongmarksten o d",
  "DCK.13": "Släntbeklädnad av marktegel",
  "DCK.14": "Släntbeklädnad av stenfyllda nätkorgar",
  "DCK.15": "Släntbeklädnad av jord- och krossmaterial",
  "DCK.16": "Släntbeklädnad av tillvaratagen markvegetation och jordmån",
  "DCK.2": "Erosionsskydd",
  "DCK.22": "Erosionsskydd av betongmadrasser",
  "DCK.23": "Erosionsskydd av växter",
  "DCK.25": "Erosionsskydd av jord- och krossmaterial",
  "DCK.251": "Erosionsskydd av jord- och krossmaterial på jordslänt",
  "DCK.2511": "Erosionsskydd av grovkornigt material på jordslänt",
  "DCK.2518": "Erosionsskydd av diverse jord- och krossmaterial på jordslänt",
  "DCK.252": "Erosionsskydd av jord- och krossmaterial i vatten",
  "DCK.26": "Erosionsskydd av tillvaratagen markvegetation och jordmån",
  DCL: "ÖVERBYGGNADER FÖR VEGETATIONSYTOR",
  "DCL.1": "Växtbädd",
  "DCL.11": "Växtbädd typ 1 och 2, påförd jord",
  "DCL.111": "Växtbädd typ 1",
  "DCL.1111": "Växtbädd typ 1 med jord A",
  "DCL.1112": "Växtbädd typ 1 med jord B",
  "DCL.112": "Växtbädd typ 2",
  "DCL.1121": "Växtbädd typ 2 med jord A",
  "DCL.1122": "Växtbädd typ 2 med jord B",
  "DCL.12": "Växtbädd typ 3 och 4, befintlig jord",
  "DCL.121": "Växtbädd typ 3",
  "DCL.122": "Växtbädd typ 4",
  "DCL.13": "Växtbädd typ skelettjord",
  "DCL.131": "Växtbädd typ skelettjord, nedspolning av jordmaterial",
  "DCL.132": "Växtbädd typ skelettjord, utläggning av färdigblandad jord",
  "DCL.14": "Speciella växtbäddar",
  "DCL.141": "Växtbädd på bjälklag",
  "DCL.142": "Växtbädd i urnor, lådor o d",
  "DCL.143": "Växtbädd för perkolations-, infiltrationsmagasin o d",
  "DCL.2": "Förberedelser för sådd, plantering m m",
  "DCL.21": "Jordförbättring av växtbädd",
  "DCL.211": "Jordförbättring med mineraljord",
  "DCL.212": "Jordförbättring med organisk substans",
  "DCL.213": "Jordförbättring med blandningsprodukter",
  "DCL.22": "Kalkning",
  "DCL.23": "Gödsling",
  "DCL.24": "Jordbearbetning",
  "DCL.241": "Plöjning",
  "DCL.242": "Harvning",
  "DCL.243": "Vältning",
  "DCL.244": "Sladdning",
  "DCL.245": "Fräsning",
  "DCL.246": "Handgrävning",
  "DCL.247": "Djupbearbetning för dränering",
  "DCL.25": "Avjämning m m av växtbädd",
  "DCL.26": "Gröngödsling",
  DD: "VEGETATIONSYTOR, SÅDD OCH PLANTERING M M",
  DDB: "SÅDD, PLANTERING M M",
  "DDB.1": "Sådd, torvläggning m m",
  "DDB.11": "Sådd",
  "DDB.111": "Sådd av gräs",
  "DDB.112": "Sådd av blomsteräng",
  "DDB.113": "Sådd av täckgröda",
  "DDB.12": "Torvläggning o d",
  "DDB.121": "Torvläggning med odlad grästorv",
  "DDB.122": "Torvläggning med tillvaratagen markvegetation",
  "DDB.123":
    "Torvläggning med matta av odlad moss-, sedum-, ört- och gräsvegetation",
  "DDB.124": "Läggning med färdigetablerad strandmatta, strandrulle o d",
  "DDB.13": "Sprutsådd",
  "DDB.14": "Spridning av sticklingar m m",
  "DDB.2": "Plantering av plantskoleväxter m m",
  "DDB.21": "Plantering av buskar m m",
  "DDB.211": "Plantering av rosor",
  "DDB.212": "Plantering av prydnadsbuskar",
  "DDB.213": "Plantering av häck- och landskapsväxter",
  "DDB.214": "Plantering av barrbuskar",
  "DDB.215": "Plantering av bärbuskar",
  "DDB.216": "Plantering av klätterväxter",
  "DDB.217": "Plantering av perenner, lökväxter, örtpluggplantor m m",
  "DDB.22": "Plantering av träd",
  "DDB.221": "Plantering av lövträd",
  "DDB.2211": "Plantering av fruktträd",
  "DDB.222": "Plantering av barrträd",
  "DDB.23": "Plantering av sticklingar m m",
  "DDB.3": "Plantering av tillvaratagna växter",
  "DDB.31": "Plantering av tillvaratagna buskar",
  "DDB.32": "Plantering av tillvaratagna träd",
  "DDB.5": "Påförande av tillvarataget markskikt",
  DDC: "STÖD OCH SKYDD FÖR VÄXTER",
  "DDC.1": "Stöd för växter",
  "DDC.11": "Stöd för stamträd",
  "DDC.12": "Stöd för klätterväxter",
  "DDC.18": "Stöd för diverse växter",
  "DDC.2": "Skydd för växter",
  "DDC.21": "Skydd mot mekanisk åverkan",
  "DDC.211": "Trädgropsfundament",
  "DDC.212": "Markgaller",
  "DDC.213": "Stamskydd",
  "DDC.22": "Skydd mot gnag- och betesskador",
  "DDC.23": "Skydd mot frost m m",
  "DDC.24": "Skydd av vegetationsyta mot uttorkning, ogräs m m",
  "DDC.25": "Skydd av trädrotsystem mot uttorkning",
  "DDC.26": "Skydd av trädstammar mot uttorkning",
  "DDC.27": "Skydd av trädkronor och buskar mot uttorkning",
  DDD: "FÄRDIGSTÄLLANDESKÖTSEL",
  "DDD.1": "Färdigställandeskötsel av träd, buskar m m",
  "DDD.11": "Luckring, mekanisk ogräsbekämpning kring träd, buskar m m",
  "DDD.12": "Kemisk ogräsbehandling kring träd, buskar m m",
  "DDD.13": "Jordförbättring, övergödsling för träd, buskar m m",
  "DDD.14": "Vattning av träd, buskar m m",
  "DDD.15": "Bekämpning av skadegörare och sjukdomar på träd, buskar m m",
  "DDD.2": "Färdigställandeskötsel av gräsyta",
  "DDD.21": "Gräsklippning, slåtter av gräsyta",
  "DDD.22": "Ogräsbekämpning av gräsyta",
  "DDD.23": "Övergödsling av gräsyta",
  "DDD.24": "Vattning av gräsyta",
  "DDD.25": "Kanthuggning av gräsyta",
  "DDD.8": "Färdigställandeskötsel av diverse vegetationsytor",
  DDE: "ÅTGÄRDER PÅ BEVARAD VEGETATION",
  "DDE.1": "Övergödsling av bevarad vegetation",
  "DDE.2": "Gallring i bevarad vegetation",
  "DDE.3": "Beskärning av bevarad vegetation",
  "DDE.4": "Trädvård m m på bevarade träd",
  "DDE.8": "Diverse åtgärder på bevarad vegetation",
  DE: "ANLÄGGNINGSKOMPLETTERINGAR",
  DEB: "BODAR, KURER O D",
  DEC: "KANTSTÖD",
  "DEC.1": "Kantstöd av granit",
  "DEC.11": "Kantstöd av granit, satta i grus med motstöd av grus",
  "DEC.12": "Kantstöd av granit, satta i grus med motstöd av asfaltmassa",
  "DEC.13": "Kantstöd av granit, satta i grus med motstöd av betong",
  "DEC.14": "Kantstöd av granit, satta i betong med motstöd av betong",
  "DEC.2": "Kantstöd av betong",
  "DEC.21": "Kantstöd av betong, satta i grus med motstöd av grus",
  "DEC.22": "Kantstöd av betong, satta i grus med motstöd av asfaltmassa",
  "DEC.23": "Kantstöd av betong, satta i grus med motstöd av betong",
  "DEC.24": "Kantstöd av betong, satta i betong med motstöd av betong",
  "DEC.25": "Kantstöd av betong, limmade",
  "DEC.26": "Kantstöd av betong, spikade",
  "DEC.3": "Kantstöd av trä",
  "DEC.31": "Kantstöd av trä för trafikyta",
  "DEC.32": "Kantstöd av trä för lek- och idrottsanläggning m m",
  "DEC.4": "Kantstöd av plast",
  "DEC.5": "Kantstöd av metall",
  DED: "RÄNNDALAR OCH YTVATTENRÄNNOR",
  "DED.1": "Ränndalar",
  "DED.11": "Ränndal av gatsten",
  "DED.111": "Ränndal av smågatsten",
  "DED.112": "Ränndal av storgatsten",
  "DED.12": "Ränndal av betongmarkplattor",
  "DED.2": "Ytvattenränna av förtillverkade element",
  DEE: "VÄG- OCH YTMARKERINGAR M M",
  "DEE.1": "Väg- och ytmarkeringar med markeringsmassa",
  "DEE.11": "Extruderad markeringsmassa",
  "DEE.111": "Extruderad markeringsmassa på trafikyta",
  "DEE.112": "Extruderad markeringsmassa på yta för lek, idrott m m",
  "DEE.12": "Sprejad markeringsmassa",
  "DEE.121": "Sprejad markeringsmassa på trafikyta",
  "DEE.122": "Sprejad markeringsmassa på yta för lek, idrott m m",
  "DEE.2": "Väg- och ytmarkeringar med färg",
  "DEE.21": "Målning på trafikyta",
  "DEE.22": "Målning på yta för lek, idrott m m",
  "DEE.23": "Målning av kantstöd",
  "DEE.3": "Väg- och ytmarkeringar med vägbanereflektorer",
  "DEE.4": "Väg- och ytmarkeringar med tejp",
  "DEE.5": "Reflektorer på räcken, stolpar e d",
  "DEE.6": "Frästa räfflor",
  DEF: "FÖRTILLVERKADE FUNDAMENT, STOLPAR, SKYLTAR M M",
  "DEF.0": "Förtillverkade enheter sammansatta av fundament, stolpe, skylt e d",
  "DEF.01": "Förtillverkade enheter bestående av fundament, stolpe och skylt",
  "DEF.02":
    "Förtillverkade enheter bestående av fundament, stolpe och ljusarmatur eller trafikljus",
  "DEF.03":
    "Förtillverkade enheter bestående av fundament, stolpe och anslutning för mätutrustning",
  "DEF.1": "Anordningar för vägmärken, gatunamnskyltar m m",
  "DEF.10": "Fundament och stolpe för vägmärke, gatunamnskylt m m",
  "DEF.11": "Fundament för stolpe för vägmärke, gatunamnskylt m m",
  "DEF.12": "Stolpe för vägmärke, gatunamnskylt m m",
  "DEF.121": "Stolpe för vägmärke",
  "DEF.122": "Stolpe för gatunamnskylt e d",
  "DEF.123": "Stolpe och överliggare för vägmärkesportal",
  "DEF.1231": "Stolpe och överliggare för enbent vägmärkesportal",
  "DEF.1232": "Stolpe och överliggare för tvåbent vägmärkesportal",
  "DEF.13": "Skylt för vägmärke, gatunamn m m",
  "DEF.14": "Skylt eller tavla för spåranläggning",
  "DEF.2": "Anordningar för skyltar för röranläggning m m",
  "DEF.20": "Fundament och stolpe för skylt för röranläggning m m",
  "DEF.21": "Fundament för stolpe för skylt för röranläggning m m",
  "DEF.22": "Stolpe för skylt för röranläggning m m",
  "DEF.221": "Stolpe för skylt för brunn, avstängningsanordning m m",
  "DEF.222": "Stolpe för skylt för brandpostanordning",
  "DEF.23": "Skylt för röranläggning m m",
  "DEF.231": "Skylt för va-anläggning",
  "DEF.2311": "Skylt för brunn, avstängningsanordning m m",
  "DEF.2312": "Skylt för brandpostanordning",
  "DEF.232": "Skylt för värmeanläggning",
  "DEF.233": "Skylt för kylanläggning",
  "DEF.234": "Skylt för gasanläggning",
  "DEF.235": "Skylt för sjöledning",
  "DEF.3": "Fundament för elstolpe m m",
  "DEF.31": "Fundament för stolpe e d för kraftledning",
  "DEF.32": "Fundament för kontaktledningsstolpe e d",
  "DEF.33": "Fundament för stolpe e d för ljusarmaturer",
  "DEF.34": "Fundament för stolpe för trafikstyranordning",
  "DEF.35": "Fundament för stolpe e d för teleutrustning",
  "DEF.351": "Fundament för antennmast",
  "DEF.36": "Fundament för stolpe e d för elutrustning",
  "DEF.361": "Fundament för stolpe för bilvärmaruttag",
  "DEF.362": "Fundament för stolpe för skyltar i elsystem",
  "DEF.37": "Fundament för kabelskåp, apparatskåp m m",
  DEG: "RÄCKEN, STÄNGSEL, STAKET, PLANK M M",
  "DEG.0":
    "Förtillverkade enheter sammansatta av fundament och räcken, staket, plank m m",
  "DEG.1": "Räcken för väg, plan o d samt bro",
  "DEG.11": "Räcken för väg, plan o d",
  "DEG.111": "Sidoräcken",
  "DEG.1111": "Stålbalkräcken",
  "DEG.1112": "Rörräcken",
  "DEG.1113": "Stållineräcken",
  "DEG.1114": "Betongräcken",
  "DEG.1115": "Träklädda stålräcken",
  "DEG.112": "Mitträcken",
  "DEG.1121": "Stålbalkräcken",
  "DEG.1122": "Rörräcken",
  "DEG.1123": "Stållineräcken",
  "DEG.1124": "Betongräcken",
  "DEG.113": "Räckesavslutningar",
  "DEG.1131": "Energiupptagande räckesavslutningar",
  "DEG.1132": "Ej energiupptagande räckesavslutningar",
  "DEG.115": "Räckesförankringar",
  "DEG.116": "Krockdämpare",
  "DEG.118": "Diverse räcken för väg, plan o d",
  "DEG.12": "Räcken för bro",
  "DEG.121": "Räcken för vägbroar",
  "DEG.1211": "Räcken vid körbanor på vägbro",
  "DEG.1212": "Räcken vid gång- och cykelbanor på vägbro",
  "DEG.1213": "Kompletteringar till räcken på vägbro",
  "DEG.1214": "Fallskydd på vägbro",
  "DEG.122": "Räcken för järnvägsbroar",
  "DEG.1221": "Räcken på järnvägsbro",
  "DEG.1222": "Räcken på vingmurar till järnvägsbro",
  "DEG.1223": "Kompletteringar till räcken på järnvägsbro",
  "DEG.123": "Räcken för gång- och cykelbroar",
  "DEG.16": "Övergångar mellan räcken för väg, bro e d",
  "DEG.17": "Förtillverkade fundament för räcke för väg, plan o d samt bro",
  "DEG.171": "Förtillverkade fundament för räcke för väg, plan o d",
  "DEG.1711": "Förtillverkade fundament av stål",
  "DEG.1712": "Förtillverkade fundament av betong",
  "DEG.172": "Förtillverkade fundament för räcke för bro",
  "DEG.2": "Räcken för gång- och cykelväg o d",
  "DEG.21": "Rörräcken",
  "DEG.22": "Smidesräcken",
  "DEG.27": "Förtillverkade fundament för räcke för gång- och cykelväg o d",
  "DEG.3": "Stängsel",
  "DEG.31": "Viltstängsel",
  "DEG.32": "Flätverksstängsel o d",
  "DEG.321": "Flätverksstängsel utan taggtråd",
  "DEG.322": "Flätverksstängsel med taggtråd",
  "DEG.33": "Industri- och säkerhetsstängsel",
  "DEG.37": "Förtillverkade fundament för stängsel",
  "DEG.38": "Diverse stängsel",
  "DEG.4": "Parkeringsräcken för fordon",
  "DEG.41": "Parkeringsräcken av plankor och stolpar för fordon",
  "DEG.47": "Förtillverkade fundament för parkeringsräcken för fordon",
  "DEG.5": "Staket, plank o d",
  "DEG.51": "Staket",
  "DEG.52": "Plank",
  "DEG.57": "Förtillverkade fundament för staket, plank o d",
  "DEG.6": "Bländskydd, raster o d",
  "DEG.61": "Bländskydd",
  "DEG.62": "Raster",
  "DEG.67": "Förtillverkade fundament för bländskydd, raster o d",
  "DEG.7": "Pollare, farthinder-, barriärelement m m",
  "DEG.71": "Pollare",
  "DEG.72": "Farthinderelement",
  "DEG.73": "Barriärelement",
  "DEG.77":
    "Förtillverkade fundament för pollare, farthinder-, barriärelement o d",
  DEH: "MARKERINGSSTOLPAR, BOMMAR M M",
  "DEH.1": "Kantstolpar för väg och plan",
  "DEH.11": "Kantstolpar, markförankrade",
  "DEH.12": "Kantstolpar, monterade på konstruktion",
  "DEH.2": "Vägbommar, platsbyggda",
  "DEH.3": "Markeringsstolpar",
  "DEH.7": "Förtillverkade fundament för markeringsstolpar, bommar m m",
  DEJ: "PARKERINGSMÄTARE, BILJETTAUTOMATER M M",
  "DEJ.1": "Parkeringsmätare",
  "DEJ.2": "Biljettautomater",
  "DEJ.7":
    "Förtillverkade fundament för parkeringsmätare, biljettautomater m m",
  DEK: "UTRUSTNINGAR OCH UTSMYCKNINGAR",
  "DEK.1": "Parkmöbler",
  "DEK.11": "Soffor",
  "DEK.12": "Bänkar",
  "DEK.13": "Bord",
  "DEK.14": "Stolar, stolsgrupper e d",
  "DEK.2": "Lekutrustning",
  "DEK.3": "Idrottsutrustning",
  "DEK.4": "Pergola, spaljé m m",
  "DEK.5": "Skräpkorgar, askkoppar m m",
  "DEK.6": "Cykelställ e d",
  "DEK.7":
    "Förtillverkade fundament för fasta utrustningar och utsmyckningar i mark",
  DEM: "ELEKTROKEMISKT SKYDD",
  "DEM.1": "Elektrokemiskt skydd av jordförlagd konstruktion",
  "DEM.11": "Skydd av jordförlagd konstruktion med offeranod",
  "DEM.113": "Skydd av jordförlagd rörledning m m med offeranod",
  "DEM.1131": "Skydd av jordförlagd rörledning m m med näranod",
  "DEM.1132": "Skydd av jordförlagd rörledning m m med offeranoder i anodbädd",
  "DEM.1133":
    "Skydd av jordförlagd rörledning m m med offeranod för rör i utrymme",
  "DEM.12": "Skydd av jordförlagd konstruktion med anod och yttre strömkälla",
  "DEM.123":
    "Skydd av jordförlagd rörledning m m med anod och yttre strömkälla",
  "DEM.1231":
    "Skydd av jordförlagd rörledning m m med näranod och yttre strömkälla",
  "DEM.1232":
    "Skydd av jordförlagd rörledning m m med anoder i bädd och yttre strömkälla",
  "DEM.13": "Skydd av jordförlagd konstruktion med elektrisk dränering",
  "DEM.131": "Skydd av jordförlagd konstruktion med direkt elektrisk dränering",
  "DEM.132":
    "Skydd av jordförlagd konstruktion med polariserad elektrisk dränering",
  "DEM.133":
    "Skydd av jordförlagd konstruktion med forcerad elektrisk dränering",
  "DEM.2": "Elektrokemiskt skydd av icke jordförlagd konstruktion",
  DEN: "KABELSKYDD I ANLÄGGNING",
  "DEN.1": "Skydd för kablar i mark",
  "DEN.11": "Kabelskydd av plastprofiler",
  "DEN.12": "Kabelskydd av plaströr, plastrännor o d",
  "DEN.13": "Kabelskydd av tegelstenar eller betongplattor",
  "DEN.14": "Kabelskydd av bräder",
  "DEN.15": "Kabelskydd av rör, rännor, hålblock o d av betong eller tegel",
  "DEN.2": "Skydd för kablar under hus",
  DEP: "ANLÄGGNINGSKOMPLETTERINGAR FÖR BRO, BRYGGA, KAJ O D",
  "DEP.1": "Anläggningskompletteringar för bro",
  "DEP.11": "Ytavlopp och grundavlopp för bro",
  "DEP.111": "Ytavlopp för bro",
  "DEP.1111": "Ytavlopp vid nybyggnad",
  "DEP.1112": "Ytavlopp vid reparation",
  "DEP.112": "Grundavlopp för bro",
  "DEP.1121": "Grundavlopp vid nybyggnad",
  "DEP.1122": "Grundavlopp vid reparation",
  "DEP.118": "Diverse ytavlopp och grundavlopp för bro",
  "DEP.12": "Dräneringskanaler i bindlager",
  "DEP.13": "Rännor, stuprör m m i bro",
  "DEP.131": "Rännor, stuprör m m vid nybyggnad",
  "DEP.132": "Rännor, stuprör m m vid reparation",
  "DEP.14": "Gasutlopp i bro",
  "DEP.141": "Gasutlopp vid nybyggnad",
  "DEP.142": "Gasutlopp vid reparation",
  "DEP.15": "Brolager",
  "DEP.151": "Brolager av stål",
  "DEP.1511": "Fasta brolager",
  "DEP.1512": "Rullager",
  "DEP.152": "Gummipottlager för bro",
  "DEP.153": "Kalottlager för bro",
  "DEP.154": "Glidskivelager för bro",
  "DEP.155": "Gummilager för bro",
  "DEP.1551": "Armerade gummilager",
  "DEP.1552": "Oarmerade gummilager",
  "DEP.157": "Reparation eller utbyte av lager",
  "DEP.16": "Övergångskonstruktioner i bro",
  "DEP.161": "Övergångskonstruktioner vid nybyggnad",
  "DEP.162": "Övergångskonstruktioner vid reparation",
  "DEP.163": "Utbyte av övergångskonstruktioner",
  "DEP.17": "Anläggningskompletteringar för belysning på bro",
  "DEP.171": "Kopplingsskåp för belysning",
  "DEP.173": "Kabelrör för elledning till belysning",
  "DEP.174": "Dragbrunnar för belysning",
  "DEP.18":
    "Anläggningskompletteringar för skydd och tillgänglighet, elektrisk potentialmätning m m",
  "DEP.181": "Elskyddsanordning över kontaktledningar",
  "DEP.182": "Manhål",
  "DEP.183": "Avvägningsdubbar och loddubbar",
  "DEP.1831": "Avvägningsdubbar",
  "DEP.1832": "Loddubbar",
  "DEP.184": "Förstörings- och försvarsanordningar",
  "DEP.185": "Anslutningar för elektrokemisk potentialmätning",
  "DEP.2": "Anläggningskompletteringar för brygga, kaj o d",
  "DEP.21": "Avvisarverk",
  "DEP.22": "Förtöjningsanordningar",
  "DEP.23": "Dykdalber",
  DEQ: "ANLÄGGNINGSKOMPLETTERINGAR I TUNNEL, BERGRUM OCH ÖVRIG BERGSCHAKT O D",
  "DEQ.1": "Tak- och väggdränering i tunnel, bergrum och övrig bergschakt",
  "DEQ.11": "Fria dräner",
  "DEQ.12": "Insprutade dräner",
  "DEQ.2": "Infästningar i berg med bult o d",
  DEY: "DIVERSE ANLÄGGNINGSKOMPLETTERINGAR",
  "DEY.1": "Refugelement",
  "DEY.11": "Refugelement av betong",
  DF: "SPÅRANLÄGGNING",
  DFB: "SPÅR",
  "DFB.0": "Spår, sammansatta",
  "DFB.1": "Sliprar",
  "DFB.11": "Betongsliprar",
  "DFB.12": "Träsliprar",
  "DFB.2": "Räler",
  "DFB.21": "Vignolräler",
  "DFB.22": "Övergångsräler",
  "DFB.23": "Skyddsräler",
  "DFB.24": "Ledräler",
  "DFB.25": "Gaturäler",
  "DFB.26": "Rälsdetaljer",
  "DFB.261": "Rälsskarvar",
  "DFB.262": "Rälsvandringshinder",
  "DFB.28": "Diverse räler",
  "DFB.3": "Justering av spår",
  "DFB.8": "Diverse spårarbeten",
  "DFB.81": "Diverse spårarbeten vid nybyggnad",
  "DFB.82": "Diverse spårarbeten vid underhåll",
  DFC: "VÄXLAR OCH SPÅRKORSNINGAR",
  "DFC.0": "Växlar och spårkorsningar, sammansatta",
  "DFC.1": "Sliprar i växel och spårkorsning",
  "DFC.11": "Betongsliprar i växel och spårkorsning",
  "DFC.12": "Träsliprar i växel och spårkorsning",
  "DFC.2": "Räler och anordningar i växel och spårkorsning",
  "DFC.21": "Vignolräler i växel och spårkorsning",
  "DFC.25": "Gaturäler i växel och spårkorsning",
  "DFC.3": "Justering av växel och spårkorsning",
  "DFC.8": "Diverse arbeten i växlar och spårkorsningar",
  "DFC.81": "Diverse arbeten i växlar och spårkorsningar vid nybyggnad",
  "DFC.82": "Diverse arbeten i växlar och spårkorsningar vid underhåll",
  DFD: "SPÅRKOMPLETTERINGAR",
  "DFD.1": "Vägplattor",
  "DFD.11": "Vägplattor av hårdgummi",
  "DFD.12": "Vägplattor av betong",
  "DFD.13": "Vägplattor av trä",
  "DFD.2": "Smörjapparater",
  "DFD.3": "Snöskydd",
  DFE: "BANGÅRDSUTRUSTNINGAR",
  "DFE.1": "Stoppbockar",
  "DFE.2": "Vagnvågar",
  "DFE.3": "Rangerbromsar",
  "DFE.4": "Hinderpålar",
  "DFE.5": "Vändskivor",
  DG: "ÅTERSTÄLLNINGSARBETEN",
  DGB: "ÅTERSTÄLLNINGSARBETEN I MARK",
  "DGB.1": "Återställande av väg, plan o d",
  "DGB.11": "Återställande av väg, plan o d med bitumenbundet slitlager",
  "DGB.12": "Återställande av väg, plan o d med obundet slitlager",
  "DGB.13": "Återställande av väg, plan o d med cementbundet slitlager",
  "DGB.14":
    "Återställande av väg, plan o d med beläggning av gatsten, naturstensplattor o d",
  "DGB.15":
    "Återställande av väg, plan o d med beläggning av betongmarkplattor, betongmarksten o d",
  "DGB.2": "Återställande av överbyggnad för järnväg",
  "DGB.3": "Återställande av vegetationsyta",
  "DGB.31": "Återställande av planteringsyta",
  "DGB.32": "Återställande av gräsyta",
  "DGB.33": "Återställande av naturmarksyta",
  "DGB.4": "Återställande av släntbeklädnad, sandyta o d",
  "DGB.41": "Återställande av släntbeklädnad",
  "DGB.42": "Återställande av sandyta",
  "DGB.5": "Återställande av terrängtrappor, murar m m",
  "DGB.51": "Återställande av terrängtrappor",
  "DGB.52": "Återställande av murar",
  "DGB.6": "Återställande av anläggningskompletteringar m m",
  "DGB.61": "Återställande av kantstöd",
  "DGB.62": "Återställande av ränndalar av gatsten, betongmarkplattor m m",
  "DGB.63": "Återställande av väg- och ytmarkeringar",
  "DGB.64": "Återställande av fundament, stolpar, skyltar m m",
  "DGB.65": "Återställande av räcken, stängsel, staket, plank m m",
  "DGB.68": "Återställande av diverse anläggningskompletteringar m m i mark",
  "DGB.681": "Återställande av refugelement",
  "DGB.7": "Avslutande av täkt, sidotag och upplag",
  DH: "SKÖTSEL AV MARKANLÄGGNING",
  DHB: "SKÖTSEL AV MARKANLÄGGNING UNDER GARANTITIDEN",
  "DHB.1": "Skötsel av markbeläggningar m m under garantitiden",
  "DHB.11": "Skötsel av obundna slitlager under garantitiden",
  "DHB.12": "Skötsel av bitumenbundna slitlager under garantitiden",
  "DHB.13": "Skötsel av cementbundna slitlager under garantitiden",
  "DHB.14":
    "Skötsel av beläggning av gatsten, naturstensplattor o d under garantitiden",
  "DHB.15":
    "Skötsel av beläggning av betongmarkplattor, betongmarksten o d under garantitiden",
  "DHB.2": "Skötsel av anläggningskompletteringar m m under garantitiden",
  "DHB.3": "Skötsel av vegetationsytor m m under garantitiden",
  "DHB.31": "Skötsel av träd, buskar m m under garantitiden",
  "DHB.311": "Skötsel av planteringsyta under garantitiden",
  "DHB.312": "Skötsel av träd under garantitiden",
  "DHB.313": "Skötsel av buskar m m under garantitiden",
  "DHB.314": "Skötsel av häck under garantitiden",
  "DHB.315": "Skötsel av rabattrosor under garantitiden",
  "DHB.316": "Skötsel av klätterväxter under garantitiden",
  "DHB.317": "Skötsel av perenner under garantitiden",
  "DHB.32": "Skötsel av gräsyta under garantitiden",
  "DHB.33": "Skötsel av naturmarksytor under garantitiden",
  "DHB.34": "Skötsel av sedumytor m m under garantitiden",
  "DHB.35": "Skötsel av vegetationsytor på bjälklag under garantitiden",
  DJ: "FÖRANKRINGAR",
  DJB: "FÖRANKRINGAR AV KONSTRUKTIONER",
  "DJB.1": "Förankring i berg",
  "DJB.11": "Bergstag typ 1",
  "DJB.12": "Bergstag typ 2",
  "DJB.2": "Förankring i jord",
  "DJB.21": "Injekterade jordstag",
  "DJB.28": "Diverse förankringar i jord",
  "DJB.3": "Förankring i konstruktion",
  "DJB.31": "Förankring i spont",
  E: "PLATSGJUTNA KONSTRUKTIONER",
  EB: "PLATSGJUTNA KONSTRUKTIONER I ANLÄGGNING",
  EBB: "FORMAR, FORMSTÄLLNINGAR M M FÖR BETONGGJUTNING I ANLÄGGNING",
  "EBB.1": "Form",
  "EBB.11": "Form av valfri typ",
  "EBB.12": "Form av skivor",
  "EBB.13": "Form av bräder",
  "EBB.131": "Form av sågade bräder",
  "EBB.132": "Form av råhyvlade bräder",
  "EBB.133": "Form av råspontade bräder",
  "EBB.134": "Form av slätspontade bräder",
  "EBB.135": "Form av specialprofilerade bräder",
  "EBB.14": "Form av luckor av bräder",
  "EBB.15": "Form av kassetter",
  "EBB.16": "Form till ursparningar o d",
  "EBB.161": "Form till ursparningar i bro",
  "EBB.18": "Diverse formar",
  "EBB.181": "Kvarsittande form",
  "EBB.1811": "Kvarsittande form av betong",
  "EBB.183": "Form för undervattensgjutning",
  "EBB.2": "Glidform och klätterform",
  "EBB.21": "Glidform",
  "EBB.22": "Klätterform",
  "EBB.3": "Komplettering i form",
  "EBB.31": "Profilering i form",
  "EBB.32": "Dränerande beklädnad i form",
  "EBB.33": "Gjutavstängare",
  "EBB.331": "Gjutavstängare i bro",
  "EBB.3311": "Gjutavstängare i undervattensgjuten konstruktion i bro",
  "EBB.3312": "Kvarsittande gjutavstängare i bro",
  EBC: "ARMERING, INGJUTNINGSGODS, FOGBAND M M I ANLÄGGNING",
  "EBC.1": "Armering",
  "EBC.11": "Ospänd armering och dubbning",
  "EBC.111": "Ospänd armering i bro",
  "EBC.1111": "Ospänd armering vid nybyggnad av bro",
  "EBC.1112":
    "Ospänd armering och dubbning vid förbättring och reparation av bro",
  "EBC.112": "Ospänd armering i kaj",
  "EBC.1121": "Ospänd armering vid nybyggnad av kaj",
  "EBC.1122":
    "Ospänd armering och dubbning vid förbättring och reparation av kaj",
  "EBC.113": "Ospänd armering i tunnel eller bergrum",
  "EBC.115": "Ospänd armering i betongkonstruktion i terräng",
  "EBC.12": "Spännarmering",
  "EBC.121": "Spännarmering av stål",
  "EBC.1211": "Spännarmering av stål i bro",
  "EBC.13": "Fiberarmering",
  "EBC.131": "Stålfiberarmering",
  "EBC.1311": "Stålfiberarmering i bro",
  "EBC.132": "Plastfiberarmering",
  "EBC.2": "Ingjutningsgods m m",
  "EBC.21": "Kantskoningar, svetsplattor o d",
  "EBC.22": "Kramlor",
  "EBC.24": "Gängstänger, bultgrupper o d",
  "EBC.25": "Ingjutna rör",
  "EBC.28": "Diverse ingjutningsgods",
  "EBC.3": "Fogband",
  "EBC.31": "Fogband av gummi",
  "EBC.32": "Fogband av plast",
  "EBC.321": "Fogband av plast i bro, brygga, kaj o d",
  "EBC.3211": "Fogband av plast i bro",
  "EBC.33": "Fogband av metall",
  "EBC.34": "Svällande fogband",
  "EBC.341": "Svällande fogband i bro, brygga kaj o d",
  "EBC.3411": "Svällande fogband i bro",
  "EBC.35": "Fogband av lera",
  "EBC.36": "Fogband av bitumen",
  "EBC.5":
    "Förbättring och reparation med utanpåliggande armering på betongkonstruktion",
  "EBC.51":
    "Förbättring och reparation med pålimmade stålplåtar på betongkonstruktion",
  "EBC.52":
    "Förbättring och reparation med pålimmad kolfiberväv eller kolfiberlaminat på betongkonstruktion",
  "EBC.521":
    "Förbättring och reparation med pålimmad kolfiberväv eller kolfiberlaminat på betongkonstruktion i bro",
  "EBC.5211":
    "Förbättring med pålimmad kolfiberväv eller kolfiberlaminat på betongkonstruktion i bro",
  "EBC.5212":
    "Reparation med pålimmad kolfiberväv eller kolfiberlaminat på betongkonstruktion i bro",
  "EBC.6":
    "Förbättring och reparation av betongkonstruktion med katodiskt skydd av armering",
  "EBC.61":
    "Förbättring och reparation av betongkonstruktion i bro med katodiskt skydd av armering",
  EBE: "BETONGGJUTNINGAR I ANLÄGGNING",
  "EBE.1": "Betonggjutning kategori A",
  "EBE.11": "Betonggjutning kategori A vid nybyggnad",
  "EBE.111": "Betonggjutning kategori A vid nybyggnad av bro",
  "EBE.112": "Betonggjutning kategori A vid nybyggnad av kaj",
  "EBE.113":
    "Betonggjutning kategori A vid nybyggnad av bergtunnel eller bergrum",
  "EBE.1131":
    "Betonggjutning vid bergförstärkning m m genom stödjande konstruktion i bergtunnel eller bergrum",
  "EBE.114": "Betonggjutning kategori A vid nybyggnad av betongtunnel",
  "EBE.115": "Betonggjutning kategori A vid nybyggnad i terräng",
  "EBE.1152": "Betonggjutning kategori A vid nybyggnad för väg, plan o d",
  "EBE.116": "Skyddslager av betong på tätskikt på bro, brygga, kaj o d",
  "EBE.1161": "Skyddslager av betong på tätskikt på bro",
  "EBE.117": "Kompletterande betonggjutning kategori A",
  "EBE.1171": "Undergjutning och igjutning kategori A",
  "EBE.12": "Betonggjutning kategori A vid förbättring och reparation",
  "EBE.121": "Betonggjutning kategori A vid förbättring och reparation av bro",
  "EBE.1211": "Betonggjutning kategori A vid förbättring av bro",
  "EBE.1212": "Betonggjutning kategori A vid reparation av bro",
  "EBE.122": "Betonggjutning kategori A vid förbättring och reparation av kaj",
  "EBE.1221": "Betonggjutning kategori A vid förbättring av kaj",
  "EBE.1222": "Betonggjutning kategori A vid reparation av kaj",
  "EBE.2": "Betonggjutning kategori B",
  "EBE.21": "Betonggjutning kategori B vid nybyggnad",
  "EBE.215": "Betonggjutning kategori B vid nybyggnad i terräng",
  "EBE.2151": "Betonggjutning kategori B vid nybyggnad av ledning",
  "EBE.2152": "Mur av betong",
  "EBE.2153": "Platsgjutet stöd och fundament",
  "EBE.2154": "Platsgjuten brunn, kammare e d",
  "EBE.2155": "Platsgjuten bassäng",
  "EBE.217": "Kompletterande betonggjutning kategori B",
  "EBE.2171": "Undergjutning och igjutning kategori B",
  "EBE.22": "Betonggjutning kategori B vid förbättring eller reparation",
  "EBE.221":
    "Betonggjutning kategori B vid förbättring eller reparation av bro",
  "EBE.2211":
    "Betonggjutning kategori B vid förbättring eller reparation av bro av betong",
  "EBE.2212":
    "Betonggjutning kategori B vid förbättring eller reparation av rörbro av stål",
  EBF: "SPRUTBETONG I ANLÄGGNING",
  "EBF.1": "Sprutbetong i bro",
  "EBF.11": "Sprutbetong i bro av betong",
  "EBF.111": "Sprutbetong vid nybyggnad av bro av betong",
  "EBF.112": "Sprutbetong vid förbättring och reparation av bro av betong",
  "EBF.1121": "Sprutbetong vid förbättring av bro av betong",
  "EBF.1122": "Sprutbetong vid reparation av bro av betong",
  "EBF.12": "Sprutbetong i bro av stål",
  "EBF.122": "Sprutbetong vid förbättring och reparation av rörbro av stål",
  "EBF.1221": "Sprutbetong vid förbättring av rörbro av stål",
  "EBF.1222": "Sprutbetong vid reparation av rörbro av stål",
  "EBF.3": "Sprutbetong i tunnel eller bergrum",
  "EBF.31": "Bergförstärkning med sprutbetong",
  "EBF.311": "Oarmerad sprutbetong",
  "EBF.312": "Sprutbetong med ospänd armering",
  "EBF.3121": "Oförankrad sprutbetong med ospänd armering",
  "EBF.3122": "Bergförankrad sprutbetong med ospänd armering",
  "EBF.314": "Fiberarmerad sprutbetong",
  "EBF.3141": "Oförankrad fiberarmerad sprutbetong",
  "EBF.3142": "Bergförankrad fiberarmerad sprutbetong",
  "EBF.4": "Sprutbetong i slänt",
  "EBF.41": "Sprutbetong i jordslänt",
  "EBF.42": "Sprutbetong i bergslänt",
  EBG: "PLATSGJUTNA KONSTRUKTIONER AV LÄTTBALLASTBETONG I ANLÄGGNING",
  EBH: "UNDERVATTENSGJUTNA KONSTRUKTIONER I ANLÄGGNING",
  "EBH.2": "Undervattensgjuten konstruktion typ 1",
  "EBH.21": "Undervattensgjuten konstruktion typ 1 vid nybyggnad",
  "EBH.22": "Undervattensgjuten konstruktion typ 1 vid reparation",
  "EBH.3": "Undervattensgjuten konstruktion typ 2",
  "EBH.31": "Undervattensgjuten konstruktion typ 2 vid nybyggnad",
  "EBH.32": "Undervattensgjuten konstruktion typ 2 vid reparation",
  EBJ: "INJEKTERINGAR I BETONGKONSTRUKTIONER I ANLÄGGNING",
  "EBJ.1": "Injektering vid nybyggnad",
  "EBJ.11": "Injektering av foderrör till spännarmering",
  "EBJ.111": "Injektering av foderrör till spännarmering i bro",
  "EBJ.12": "Injektering av sprickor vid nybyggnad",
  "EBJ.121": "Injektering av sprickor med cement vid nybyggnad",
  "EBJ.122": "Injektering av sprickor med epoxi vid nybyggnad",
  "EBJ.2": "Injektering vid reparation",
  "EBJ.21": "Injektering av sprickor vid reparation",
  "EBJ.211": "Injektering av sprickor i bro",
  "EBJ.22": "Injektering av håligheter m m vid reparation",
  "EBJ.221": "Injektering av håligheter och efterinjektering av spännkablar",
  ES: "PLATSGJUTNA KONSTRUKTIONER FÖR HUS",
  ESB: "FORMAR FÖR BETONGGJUTNING I HUS",
  "ESB.1": "Formar av valfri typ",
  "ESB.11": "Formar för motgjutning mot isoleringsskivor",
  "ESB.12": "Formar för motgjutning mot isoleringsblock",
  "ESB.2": "Formar av skivor",
  "ESB.21": "Formar av plåt",
  "ESB.22": "Formar av plywoodskivor",
  "ESB.23": "Formar av träfiberskivor",
  "ESB.3": "Formar av brädor och luckor av brädor",
  "ESB.31": "Formar av brädor",
  "ESB.311": "Formar av sågade brädor",
  "ESB.312": "Formar av råhyvlade brädor",
  "ESB.313": "Formar av underlagsspont",
  "ESB.314": "Formar av slätspontade brädor",
  "ESB.315": "Formar av specialprofilerade brädor",
  "ESB.32": "Formar av luckor av brädor",
  "ESB.4": "Formar av formelement",
  "ESB.41": "Formar av formelement av betong",
  "ESB.411": "Formar av formelement av betong till vägg",
  "ESB.412": "Formar av formelement av betong till bjälklag",
  "ESB.4121": "Formar av understödda formelement av betong till bjälklag",
  "ESB.4122": "Formar av fribärande formelement av betong till bjälklag",
  "ESB.42": "Formar av formelement av trä eller träbaserat material",
  "ESB.421": "Formar av formelement av trä eller träbaserat material till vägg",
  "ESB.422":
    "Formar av formelement av trä eller träbaserat material till bjälklag",
  "ESB.5": "Formar av kassetter",
  "ESB.51": "Formar av stålkassetter",
  "ESB.52": "Formar av plastkassetter",
  "ESB.6": "Formar av rör",
  "ESB.7": "Formar till kanal eller slits samt matriser och lister i form",
  "ESB.71": "Formar till kanaler",
  "ESB.72": "Formar till slitsar",
  "ESB.74": "Lister i form",
  "ESB.741": "Trekantlister i form",
  "ESB.742": "Källister i form",
  "ESB.743": "Förtagningslister i form",
  "ESB.744": "Lister för fogmarkering på betongyta",
  "ESB.75": "Matriser i form",
  "ESB.8": "Diverse formar för betonggjutning i hus",
  "ESB.81": "Glidformar",
  "ESB.82": "Klätterformar",
  ESC: "ARMERING, INGJUTNINGSGODS, FOGBAND M M I HUS",
  "ESC.1": "Armering",
  "ESC.11": "Slakarmering",
  "ESC.12": "Spännarmering",
  "ESC.13": "Fiberarmering",
  "ESC.14": "Förstärkning med fiberkompositer",
  "ESC.2": "Ingjutningsgods m m i platsgjuten betongkonstruktion",
  "ESC.21": "Kantskoningar",
  "ESC.211": "Kantförstärkta rörelsefogar",
  "ESC.22": "Ingjutningsgods till skyddsrum",
  "ESC.23": "Kramlor",
  "ESC.24": "Fogband",
  "ESC.241": "Fogband av gummi",
  "ESC.242": "Fogband av plast",
  "ESC.243": "Fogband av metall",
  "ESC.244": "Svällande fogband",
  "ESC.245": "Injekterbara rör som fogband",
  "ESC.25": "Ingjutna rör",
  "ESC.251": "Ingjutna rörledningar samt hylsor till rörledning",
  "ESC.252": "Skyddsrör till kabel",
  "ESC.26": "Ingjutna eller borrade infästningar",
  "ESC.3": "Ingjutningsgods m m i glidformsgjuten betongkonstruktion",
  ESE: "BETONGGJUTNINGAR I HUS",
  "ESE.1": "Grundkonstruktioner av platsgjuten betong",
  "ESE.11": "Grundplintar av platsgjuten betong",
  "ESE.12": "Pålplintar och pålplattor av platsgjuten betong",
  "ESE.13": "Pelarholkar av platsgjuten betong",
  "ESE.14": "Hela grundplattor av platsgjuten betong",
  "ESE.141":
    "Hela grundplattor av platsgjuten betong som underlag för beläggning",
  "ESE.1411":
    "Hela grundplattor med stålglättad yta som underlag för beläggning",
  "ESE.1412": "Hela grundplattor med brädriven yta som underlag för beläggning",
  "ESE.1413": "Hela grundplattor med borstad yta som underlag för beläggning",
  "ESE.1414": "Hela grundplattor med avdragen yta som underlag för beläggning",
  "ESE.1415": "Hela grundplattor med avjämnad yta som underlag för beläggning",
  "ESE.142":
    "Hela grundplattor av platsgjuten betong för efterbehandling till färdigt golv",
  "ESE.1421": "Hela grundplattor med yta för maskinslipning",
  "ESE.143": "Hela grundplattor av platsgjuten betong som färdigt golv",
  "ESE.1431": "Hela grundplattor med stålglättad färdig golvyta",
  "ESE.1432": "Hela grundplattor med brädriven färdig golvyta",
  "ESE.15": "Grundsulor av platsgjuten betong",
  "ESE.16": "Grundbalkar av platsgjuten betong",
  "ESE.17": "Grundmurar av platsgjuten betong",
  "ESE.18": "Diverse grundkonstruktioner av platsgjuten betong",
  "ESE.181": "Påldäck av platsgjuten betong",
  "ESE.182": "Fundament av platsgjuten betong",
  "ESE.2": "Husstommar av platsgjuten betong",
  "ESE.21": "Väggar av platsgjuten betong",
  "ESE.22": "Pelare av platsgjuten betong",
  "ESE.23": "Balkar av platsgjuten betong",
  "ESE.24": "Bjälklag av platsgjuten betong",
  "ESE.241": "Bjälklag av platsgjuten betong som underlag för beläggning",
  "ESE.2411": "Bjälklag med stålglättad yta som underlag för beläggning",
  "ESE.2412": "Bjälklag med brädriven yta som underlag för beläggning",
  "ESE.2413": "Bjälklag med borstad yta som underlag för beläggning",
  "ESE.2414": "Bjälklag med avdragen yta som underlag för beläggning",
  "ESE.2415": "Bjälklag med avjämnad yta som underlag för beläggning",
  "ESE.242":
    "Bjälklag av platsgjuten betong för efterbehandling till färdigt golv",
  "ESE.2421": "Bjälklag med yta för maskinslipning",
  "ESE.243": "Bjälklag av platsgjuten betong som färdigt golv",
  "ESE.2431": "Bjälklag med stålglättad färdig golvyta",
  "ESE.2432": "Bjälklag med brädriven färdig golvyta",
  "ESE.25": "Yttertakstommar och ytterbjälklag av platsgjuten betong",
  "ESE.251": "Ytterbjälklag med stålglättad yta",
  "ESE.252": "Ytterbjälklag med brädriven yta",
  "ESE.253": "Ytterbjälklag med borstad yta",
  "ESE.254": "Ytterbjälklag med avdragen yta",
  "ESE.255": "Ytterbjälklag med avjämnad yta",
  "ESE.26": "Trappstommar av platsgjuten betong",
  "ESE.261":
    "Trappstommar av platsgjuten betong med överyta som underlag för beläggning",
  "ESE.2611":
    "Trappstommar med stålglättad överyta som underlag för beläggning",
  "ESE.2612": "Trappstommar med brädriven överyta som underlag för beläggning",
  "ESE.2613": "Trappstommar med borstad överyta som underlag för beläggning",
  "ESE.2614": "Trappstommar med avdragen överyta som underlag för beläggning",
  "ESE.2615": "Trappstommar med avjämnad överyta som underlag för beläggning",
  "ESE.262":
    "Trappstommar av platsgjuten betong för efterbehandling till färdig slityta",
  "ESE.2621": "Trappstommar med överyta för maskinslipning",
  "ESE.263": "Trappstommar av platsgjuten betong med överyta som slityta",
  "ESE.2631": "Trappstommar med stålglättad överyta som slityta",
  "ESE.2632": "Trappstommar med brädriven överyta som slityta",
  "ESE.27": "Stommar av platsgjuten betong med hög exponeringsklass",
  "ESE.271": "Garage och parkeringshus",
  "ESE.28": "Kompletteringar av platsgjuten betong",
  "ESE.281": "Balkonger av platsgjuten betong",
  "ESE.282": "Loftgångar av platsgjuten betong",
  "ESE.283": "Lastkajer av platsgjuten betong",
  "ESE.284": "Schaktstommar av platsgjuten betong",
  "ESE.4": "Skyddsbetongbeläggningar av platsgjuten betong",
  "ESE.41": "Skyddsbetongbeläggningar som slityta",
  "ESE.42": "Skyddsbetongbeläggningar under plantering e d",
  "ESE.43": "Skyddsbetongbeläggningar under beläggning",
  "ESE.5": "Undergolv och golv av platsgjuten betong",
  "ESE.51": "Undergolv av platsgjuten betong",
  "ESE.512": "Undergolv med stålglättad yta",
  "ESE.513": "Undergolv med brädriven yta",
  "ESE.514": "Undergolv med borstad yta",
  "ESE.515": "Undergolv med avdragen yta",
  "ESE.516": "Undergolv med avjämnad yta",
  "ESE.52": "Golv av platsgjuten betong",
  "ESE.521": "Golv med yta för maskinslipning",
  "ESE.522": "Golv med stålglättad yta",
  "ESE.523": "Golv med brädriven yta",
  "ESE.53": "Golv av hårdbetong",
  "ESE.531": "Golv av hårdbetong med yta för maskinslipning",
  "ESE.532": "Golv av hårdbetong med stålglättad yta",
  "ESE.54": "Golv av fiberarmerad betong",
  "ESE.6": "Pågjutningar på trappsteg och vilplan",
  "ESE.61":
    "Skikt av betong på trappsteg och vilplan med överyta som underlag för beläggning",
  "ESE.611":
    "Skikt av betong på trappsteg och vilplan med stålglättad överyta som underlag för beläggning",
  "ESE.612":
    "Skikt av betong på trappsteg och vilplan med brädriven överyta som underlag för beläggning",
  "ESE.613":
    "Skikt av betong på trappsteg och vilplan med borstad överyta som underlag för beläggning",
  "ESE.614":
    "Skikt av betong på trappsteg och vilplan med avdragen överyta som underlag för beläggning",
  "ESE.615":
    "Skikt av betong på trappsteg och vilplan med avjämnad överyta som underlag för beläggning",
  "ESE.62":
    "Skikt av betong på trappsteg och vilplan med överyta för efterbehandling till färdig slityta",
  "ESE.621":
    "Skikt av betong på trappsteg och vilplan med överyta för maskinslipning",
  "ESE.63": "Skikt av betong på trappsteg och vilplan med färdig slityta",
  "ESE.631":
    "Skikt av betong på trappsteg och vilplan med stålglättad färdig slityta",
  "ESE.632":
    "Skikt av betong på trappsteg och vilplan med brädriven färdig slityta",
  "ESE.64": "Skikt av hårdbetong på trappsteg och vilplan",
  "ESE.641":
    "Skikt av hårdbetong på trappsteg och vilplan med yta för maskinslipning",
  "ESE.642": "Skikt av hårdbetong med stålglättad yta på trappsteg och vilplan",
  "ESE.643": "Skikt av hårdbetong med brädriven yta på trappsteg och vilplan",
  "ESE.7": "Hålkälar, försänkningar, rännor o d i betongkonstruktion",
  "ESE.71": "Hålkälar, faser o d av betong",
  "ESE.711": "Hålkälar av betong",
  "ESE.712": "Faser av betong",
  "ESE.713": "Socklar av betong",
  "ESE.714": "Avvisarlister av betong",
  "ESE.72": "Försänkningar i betongkonstruktion",
  "ESE.721":
    "Försänkningar i betongkonstruktion för skrapgaller eller torkmatta",
  "ESE.722": "Försänkningar i betongkonstruktion i våtutrymme",
  "ESE.73": "Gropar, rännor o d i betongkonstruktion",
  "ESE.8": "Diverse betonggjutningar i hus",
  "ESE.81": "Undergjutningar",
  "ESE.811": "Undergjutningar av pelare och vägg",
  "ESE.812": "Undergjutningar av maskinfundament",
  "ESE.82": "Fastgjutningar med betong",
  "ESE.821": "Fastgjutning av pelare i inspänningsholk",
  "ESE.83": "Fönsterbänkar av betong",
  "ESE.84": "Fundament av betong för kompletteringsenhet, apparat e d",
  "ESE.85": "Kabel- och rörskydd av betong",
  "ESE.86": "Slitsbottnar av betong",
  "ESE.87": "Skorstensavtäckningar av betong",
  ESF: "SPECIELLA BETONGGJUTNINGAR",
  "ESF.1": "Betonggjutningar i rörlig form",
  "ESF.11": "Betonggjutningar i glidform",
  "ESF.12": "Betonggjutningar i klätterform",
  ESG: "GJUTNINGAR AV LÄTTBALLASTBETONG I HUS",
  ESM: "EFTERBEARBETNINGAR AV HÅRDNAD BETONGYTA I HUS",
  "ESM.3": "Efterbearbetningar av hårdnad betongyta på bjälklag och trappor",
  "ESM.31": "Efterbearbetningar av hårdnad betongyta på golv",
  "ESM.311": "Maskinslipad yta på golv av betong",
  "ESM.32": "Efterbearbetningar av hårdnad betongyta på trappsteg och vilplan",
  "ESM.321": "Maskinslipad yta på trappsteg och vilplan av betong",
  F: "MURVERK",
  FB: "MURVERK O D AV NATURSTEN I ANLÄGGNING",
  FBB: "MURAR AV NATURSTEN",
  "FBB.1": "Kallmur av natursten",
  "FBB.2": "Bruksmur av natursten",
  "FBB.3": "Murverk av stenfyllda nätkorgar",
  FBC: "MURBEKLÄDNADER AV NATURSTEN",
  FBD: "BROVALV AV NATURSTEN",
  "FBD.1": "Brovalv av natursten vid nybyggnad",
  "FBD.2": "Brovalv av natursten vid förbättring och reparation",
  FC: "MURVERK AV MURSTEN, MURBLOCK O D I ANLÄGGNING",
  FCC: "MURVERK AV KALKSANDSTEN I ANLÄGGNING",
  "FCC.1": "Mur av kalksandsten",
  FCD: "MURVERK AV BETONGSTEN, BETONGBLOCK E D I ANLÄGGNING",
  "FCD.1": "Mur av betongsten eller betongblock",
  "FCD.11": "Kallmur av betongsten eller betongblock",
  "FCD.111": "Kallmur av betongsten",
  "FCD.112": "Kallmur av betongblock",
  "FCD.12": "Bruksmur av betongsten eller betongblock",
  "FCD.121": "Bruksmur av betongsten",
  "FCD.122": "Bruksmur av betongblock",
  FCF: "MURVERK AV LÄTTKLINKERBETONG I ANLÄGGNING",
  FCG: "MURVERK AV TEGEL I ANLÄGGNING",
  "FCG.1": "Mur av tegel",
  "FCG.11": "Mur av murtegel",
  "FCG.12": "Mur av fasadtegel",
  "FCG.13": "Mur av murtegel och fasadtegel",
  FD: "MURVERK AV VEGETATIONSMATERIAL I ANLÄGGNING",
  FDB: "MURVERK AV VEGETATIONSMATERIAL",
  "FDB.1": "Murverk av torvblock",
  FS: "MURVERK I HUS",
  FSC: "MURVERK AV KALKSANDSTEN I HUS",
  "FSC.1": "Murar av kalksandsten",
  "FSC.2": "Väggar av kalksandsten",
  "FSC.3": "Skorstenar av kalksandsten",
  "FSC.31": "Skorstensmantlar av kalksandsten",
  "FSC.32": "Schaktskorstenar av kalksandsten",
  "FSC.8": "Diverse murverk av kalksandsten",
  "FSC.81": "Igensättningar av slits, urtag e d med kalksandsten",
  "FSC.82": "Inklädnader av kalksandsten",
  FSD: "MURVERK AV BETONGSTEN, BETONGBLOCK E D I HUS",
  "FSD.1": "Murar av betongsten eller betongblock",
  "FSD.11": "Murar av betongsten",
  "FSD.12": "Murar av betongblock",
  "FSD.2": "Väggar av betongsten eller betongblock",
  "FSD.21": "Väggar av betongsten",
  "FSD.22": "Väggar av betongblock",
  "FSD.3": "Skorstenar av betongsten eller betongblock",
  "FSD.8": "Diverse murverk av betongsten, betongblock e d",
  "FSD.81":
    "Igensättningar av slits, urtag e d med betongsten eller betongblock",
  "FSD.811": "Igensättningar av slits, urtag e d med betongsten",
  "FSD.812": "Igensättningar av slits, urtag e d med betongblock",
  "FSD.82": "Inklädnader av betongsten eller betongblock",
  "FSD.821": "Inklädnader av betongsten",
  "FSD.822": "Inklädnader av betongblock",
  FSE: "MURVERK AV AUTOKLAVERAD LÄTTBETONG I HUS",
  "FSE.2": "Väggar av autoklaverade lättbetongblock",
  "FSE.3": "Skorstenar av autoklaverade lättbetongblock",
  "FSE.32": "Schaktskorstenar av autoklaverade lättbetongblock",
  "FSE.8": "Diverse murverk av autoklaverad lättbetong",
  "FSE.81":
    "Igensättningar av slits, urtag e d med autoklaverade lättbetongblock",
  "FSE.82": "Inklädnader av autoklaverade lättbetongblock",
  FSF: "MURVERK AV LÄTTKLINKERBETONG I HUS",
  "FSF.1": "Murar av lättklinkerbetongblock",
  "FSF.2": "Väggar av lättklinkerbetongblock",
  "FSF.3": "Skorstenar av lättklinkerbetongblock",
  "FSF.31": "Skorstensmantlar av lättklinkerbetongblock",
  "FSF.32": "Schaktskorstenar av lättklinkerbetongblock",
  "FSF.8": "Diverse murverk av lättklinkerbetong",
  "FSF.81": "Igensättningar av slits, urtag e d med lättklinkerbetongblock",
  "FSF.82": "Inklädnader av lättklinkerbetongblock",
  FSG: "MURVERK AV TEGEL I HUS",
  "FSG.1": "Murar av tegel",
  "FSG.12": "Murar av fasadtegel",
  "FSG.13": "Murar av murtegel och fasadtegel",
  "FSG.2": "Väggar av tegel",
  "FSG.21": "Väggar av murtegel",
  "FSG.22": "Väggar av fasadtegel",
  "FSG.23": "Väggar av murtegel och fasadtegel",
  "FSG.24": "Väggar av murtegelblock",
  "FSG.3": "Skorstenar, öppna spisar m m av tegel",
  "FSG.31": "Skorstenar av tegel för förbränningsgaser",
  "FSG.311": "Skorstenar av murtegel för förbränningsgaser",
  "FSG.312": "Skorstenar av fasadtegel för förbränningsgaser",
  "FSG.313": "Skorstenar av murtegel och fasadtegel för förbränningsgaser",
  "FSG.32": "Schaktskorstenar av tegel",
  "FSG.321": "Schaktskorstenar av murtegel",
  "FSG.322": "Schaktskorstenar av fasadtegel",
  "FSG.323": "Schaktskorstenar av murtegel och fasadtegel",
  "FSG.33": "Inmurning av spisar med tegel",
  "FSG.331": "Inmurning av spisar med murtegel",
  "FSG.332": "Inmurning av spisar med fasadtegel",
  "FSG.333": "Inmurning av spisar med murtegel och fasadtegel",
  "FSG.34": "Öppna spisar av tegel",
  "FSG.341": "Öppna spisar av murtegel",
  "FSG.342": "Öppna spisar av fasadtegel",
  "FSG.343": "Öppna spisar av murtegel och fasadtegel",
  "FSG.8": "Diverse murverk av tegel",
  "FSG.81": "Igensättningar av slits, urtag e d med tegel",
  "FSG.811": "Igensättningar av slits, urtag e d med murtegel",
  "FSG.812": "Igensättningar av slits, urtag e d med fasadtegel",
  "FSG.813": "Igensättningar av slits, urtag e d med murtegel och fasadtegel",
  "FSG.82": "Inklädnader av tegel",
  "FSG.821": "Inklädnader av murtegel",
  "FSG.822": "Inklädnader av fasadtegel",
  "FSG.823": "Inklädnader av murtegel och fasadtegel",
  FSH: "MURVERK AV ELDFAST MATERIAL I HUS",
  "FSH.3": "Murverk av eldfasta murstenar eller murblock",
  "FSH.31":
    "Skorstenar av eldfasta murstenar eller murblock för förbränningsgaser",
  "FSH.34": "Inklädnader av öppna spisar med eldfast tegel",
  FSJ: "MURVERK AV GLASBLOCK I HUS",
  "FSJ.1": "Murverk av glashålblock",
  "FSJ.11": "Murverk av glashålblock i väggar",
  "FSJ.12": "Murverk av glashålblock i bjälklag",
  "FSJ.2": "Murverk av enkla glasblock",
  "FSJ.21": "Murverk av enkla glasblock i väggar",
  FSY: "MURVERK AV DIVERSE MATERIAL I HUS",
  G: "Konstruktioner av monteringsfärdiga element",
  GB: "Konstruktioner av monteringsfärdiga element i anläggning",
  GBA: "KONSTRUKTIONER AV ELEMENT AV FLERA MATERIAL I ANLÄGGNING",
  GBB: "KONSTRUKTIONER AV NATURSTENSELEMENT I ANLÄGGNING",
  "GBB.5": "Konstruktion av naturstenselement i mark",
  "GBB.52": "Mur av naturstenselement",
  "GBB.57": "Trappa av naturstenselement",
  "GBB.572": "Trappa av blocksteg av natursten",
  GBC: "KONSTRUKTIONER AV BETONGELEMENT I ANLÄGGNING",
  "GBC.1": "Konstruktion av betongelement kategori A vid nybyggnad",
  "GBC.11": "Konstruktion av betongelement kategori A vid nybyggnad av bro",
  "GBC.111":
    "Grundkonstruktion av betongelement kategori A vid nybyggnad av bro",
  "GBC.1111": "Pålplattor av betongelement för bro",
  "GBC.112": "Mur av betongelement kategori A vid nybyggnad av bro",
  "GBC.113":
    "Pelare, balkar o d av betongelement kategori A vid nybyggnad av bro",
  "GBC.1131": "Pelare av betongelement kategori A vid nybyggnad av bro",
  "GBC.1132": "Balk av betongelement kategori A vid nybyggnad av bro",
  "GBC.114":
    "Väggar, skärmar o d av betongelement kategori A vid nybyggnad av bro",
  "GBC.115":
    "Brobaneplatta o d av betongelement kategori A vid nybyggnad av bro",
  "GBC.116": "Rörbro av betongelement kategori A vid nybyggnad av bro",
  "GBC.15": "Konstruktion av betongelement kategori A vid nybyggnad i mark",
  "GBC.152": "Mur av betongelement kategori A vid nybyggnad i mark",
  "GBC.153":
    "Konstruktion av betongelement för väg, plan o d kategori A vid nybyggnad i mark",
  "GBC.1531": "Pålplattor av betongelement kategori A vid nybyggnad i mark",
  "GBC.2": "Konstruktion av betongelement kategori B vid nybyggnad",
  "GBC.25": "Konstruktion av betongelement kategori B vid nybyggnad i mark",
  "GBC.251":
    "Konstruktion av betongelement för ledningar kategori B vid nybyggnad",
  "GBC.2511": "Grundplatta av betongelement kategori B vid nybyggnad",
  "GBC.2512": "Brunn, kammare o d av betongelement kategori B vid nybyggnad",
  "GBC.252": "Mur av betongelement kategori B vid nybyggnad",
  "GBC.257": "Trappa av betongelement kategori B vid nybyggnad",
  "GBC.2571": "Trapplopp av betongelement kategori B vid nybyggnad",
  "GBC.2572": "Trappa av blocksteg av betongelement kategori B vid nybyggnad",
  GBD: "KONSTRUKTIONER AV STÅLELEMENT I ANLÄGGNING",
  "GBD.1": "Konstruktion av stålelement kategori A vid nybyggnad",
  "GBD.11": "Konstruktion av stålelement kategori A vid nybyggnad av bro",
  "GBD.111": "Pelare av stålelement kategori A vid nybyggnad av bro",
  "GBD.112": "Balk av stålelement kategori A vid nybyggnad av bro",
  "GBD.1121": "Lådbalk av stålelement kategori A vid nybyggnad av bro",
  "GBD.113": "Brobaneplatta av stålelement kategori A vid nybyggnad av bro",
  "GBD.114": "Inspektionsbrygga av stålelement kategori A vid nybyggnad av bro",
  "GBD.116": "Rörbro av stålelement kategori A vid nybyggnad",
  "GBD.12":
    "Konstruktion av stålelement kategori A vid nybyggnad av tunnel eller bergrum",
  "GBD.121":
    "Pelare av stålelement kategori A vid nybyggnad av tunnel eller bergrum",
  "GBD.122":
    "Balk av stålelement kategori A vid nybyggnad av tunnel eller bergrum",
  "GBD.1221": "Telferbalk kategori A vid nybyggnad av tunnel eller bergrum",
  "GBD.1222":
    "Balk o d till hissutrustning kategori A vid nybyggnad av tunnel eller bergrum",
  "GBD.1223":
    "Balkstomme till durkplåts- eller gallerdurksgolv kategori A vid nybyggnad av tunnel eller bergrum",
  "GBD.13": "Konstruktion av stålelement kategori A vid nybyggnad av kaj",
  "GBD.14": "Konstruktion av stålelement kategori A vid nybyggnad av torn",
  "GBD.15": "Konstruktion av stålelement kategori A vid nybyggnad i mark",
  "GBD.2": "Konstruktion av stålelement kategori B vid nybyggnad",
  "GBD.3": "Konstruktion av stålelement vid reparation",
  GBE: "KONSTRUKTIONER AV ALUMINIUMELEMENT I ANLÄGGNING",
  "GBE.1": "Konstruktion av aluminiumelement kategori A vid nybyggnad",
  "GBE.11": "Konstruktion av aluminiumelement kategori A vid nybyggnad av bro",
  "GBE.111": "Pelare av aluminiumelement kategori A vid nybyggnad av bro",
  "GBE.112": "Balk av aluminiumelement kategori A vid nybyggnad av bro",
  "GBE.113":
    "Brobaneplatta av aluminiumelement kategori A vid nybyggnad av bro",
  "GBE.12":
    "Konstruktion av aluminiumelement kategori A vid nybyggnad av tunnel eller bergrum",
  "GBE.13": "Konstruktion av aluminiumelement kategori A vid nybyggnad av kaj",
  "GBE.14": "Konstruktion av aluminiumelement kategori A vid nybyggnad av torn",
  "GBE.15": "Konstruktion av aluminiumelement kategori A vid nybyggnad i mark",
  "GBE.2": "Konstruktion av aluminiumelement kategori B vid nybyggnad",
  "GBE.3": "Konstruktion av aluminiumelement vid reparation",
  GBF: "KONSTRUKTIONER AV TRÄELEMENT I ANLÄGGNING",
  "GBF.1": "Konstruktion av träelement kategori A vid nybyggnad",
  "GBF.11": "Konstruktion av träelement kategori A vid nybyggnad av bro",
  "GBF.111": "Pelare, balk o d av träelement kategori A vid nybyggnad av bro",
  "GBF.1111": "Pelare av träelement kategori A vid nybyggnad av bro",
  "GBF.1112": "Balk av träelement kategori A vid nybyggnad av bro",
  "GBF.112": "Brobaneplatta av träelement kategori A vid nybyggnad av bro",
  "GBF.113": "Båge av träelement kategori A vid nybyggnad av bro",
  "GBF.2": "Konstruktion av träelement kategori B vid nybyggnad",
  "GBF.21": "Konstruktion av träelement kategori B vid nybyggnad av bro",
  "GBF.211": "Pelare, balk o d av träelement kategori B vid nybyggnad av bro",
  "GBF.3": "Konstruktion av träelement vid reparation",
  GBG: "KONSTRUKTIONER AV POLYETENELEMENT I ANLÄGGNING",
  "GBG.1": "Konstruktion av polyetenelement kategori A vid nybyggnad",
  "GBG.11": "Konstruktion av polyetenelement kategori A vid nybyggnad av bro",
  "GBG.116": "Rörbro av polyetenelement kategori A vid nybyggnad",
  GBH: "KONSTRUKTIONER AV GLASELEMENT I ANLÄGGNING",
  GS: "KONSTRUKTIONER AV MONTERINGSFÄRDIGA ELEMENT I HUS",
  GSA: "KONSTRUKTIONER AV ELEMENT AV FLERA MATERIAL I HUS",
  "GSA.1": "Konstruktioner av volymelement av flera material",
  "GSA.2": "Grundkonstruktioner av element av flera material",
  "GSA.4":
    "Konstruktioner av förtillverkade pelare, balkar e d av flera material",
  "GSA.41": "Konstruktioner av förtillverkade pelare av flera material",
  "GSA.42": "Konstruktioner av förtillverkade balkar av flera material",
  "GSA.5": "Väggar, skärmar e d av element av flera material",
  "GSA.51": "Ytterväggar av element av flera material",
  "GSA.52": "Innerväggar av element av flera material",
  "GSA.6": "Bjälklag e d av element av flera material",
  "GSA.61": "Bjälklag av element av flera material",
  "GSA.63": "Yttertak eller ytterbjälklag av element av flera material",
  "GSA.7": "Trappor av element av flera material",
  "GSA.8": "Diverse konstruktioner av element av flera material",
  GSB: "KONSTRUKTIONER AV NATURSTENSELEMENT I HUS",
  GSC: "KONSTRUKTIONER AV BETONGELEMENT I HUS",
  "GSC.1": "Konstruktioner av volymelement av betong",
  "GSC.2": "Grundkonstruktioner av betongelement",
  "GSC.4": "Konstruktioner av förtillverkade betongpelare, betongbalkar e d",
  "GSC.41": "Konstruktioner av förtillverkade betongpelare",
  "GSC.42": "Konstruktioner av förtillverkade betongbalkar",
  "GSC.421":
    "Konstruktioner av förtillverkade betongbalkar till yttertak eller ytterbjälklag",
  "GSC.422": "Konstruktioner av förtillverkade betongbalkar till bjälklag",
  "GSC.5": "Väggar o d av betongelement",
  "GSC.51": "Ytterväggar o d av betongelement",
  "GSC.52": "Innerväggar o d av betongelement",
  "GSC.6": "Bjälklag o d av betongelement",
  "GSC.61": "Bjälklag av betongelement",
  "GSC.63": "Yttertak och ytterbjälklag av betongelement",
  "GSC.65": "Balkongplan och loftgångsplan av betongelement",
  "GSC.651": "Balkongplan av betongelement",
  "GSC.652": "Loftgångsplan av betongelement",
  "GSC.7": "Trappor av betongelement",
  "GSC.71": "Helloppstrappor av betongelement",
  "GSC.72": "Halvloppstrappor av betongelement",
  "GSC.73": "Spindelburna trappor av betongelement",
  "GSC.8": "Diverse konstruktioner av betongelement",
  "GSC.81": "Avfallsschakt av betongelement",
  "GSC.82": "Hisschakt av betongelement",
  GSE: "KONSTRUKTIONER AV ELEMENT AV AUTOKLAVERAD LÄTTBETONG I HUS",
  "GSE.4":
    "Konstruktioner av förtillverkade pelare, balkar o d av autoklaverad lättbetong",
  "GSE.42":
    "Konstruktioner av förtillverkade balkar av autoklaverad lättbetong",
  "GSE.5": "Väggar o d av element av autoklaverad lättbetong",
  "GSE.51": "Ytterväggar av element av autoklaverad lättbetong",
  "GSE.52": "Innerväggar av element av autoklaverad lättbetong",
  "GSE.6": "Bjälklag o d av element av autoklaverad lättbetong",
  "GSE.61": "Bjälklag av element av autoklaverad lättbetong",
  "GSE.63": "Yttertak och ytterbjälklag av element av autoklaverad lättbetong",
  "GSE.8": "Diverse konstruktioner av element av autoklaverad lättbetong",
  GSG: "KONSTRUKTIONER AV ELEMENT AV LÄTTKLINKERBETONG I HUS",
  "GSG.2": "Grundkonstruktioner av element av lättklinkerbetong",
  "GSG.4":
    "Konstruktioner av förtillverkade pelare, balkar e d av lättklinkerbetong",
  "GSG.41": "Konstruktioner av förtillverkade pelare av lättklinkerbetong",
  "GSG.42": "Konstruktioner av förtillverkade balkar av lättklinkerbetong",
  "GSG.5": "Väggar o d av element av lättklinkerbetong",
  "GSG.51": "Ytterväggar av element av lättklinkerbetong",
  "GSG.52": "Innerväggar av element av lättklinkerbetong",
  "GSG.6": "Bjälklag o d av element av lättklinkerbetong",
  "GSG.61": "Bjälklag av element av lättklinkerbetong",
  "GSG.63": "Yttertak och ytterbjälklag av element av lättklinkerbetong",
  "GSG.65": "Balkongplan och loftgångsplan av element av lättklinkerbetong",
  "GSG.651": "Balkongplan av element av lättklinkerbetong",
  "GSG.652": "Loftgångsplan av element av lättklinkerbetong",
  GSH: "KONSTRUKTIONER AV TEGELELEMENT I HUS",
  GSM: "KONSTRUKTIONER AV METALLELEMENT I HUS",
  "GSM.1": "Konstruktioner av element av allmänt konstruktionsstål",
  "GSM.14": "Konstruktioner av förtillverkade stålpelare, stålbalkar e d",
  "GSM.141": "Konstruktioner av förtillverkade stålpelare",
  "GSM.1413": "Konstruktioner av förtillverkade skyddsrumspelare av stål",
  "GSM.142": "Konstruktioner av förtillverkade stålbalkar",
  "GSM.15": "Väggar o d av stålelement",
  "GSM.151": "Ytterväggar o d av stålelement",
  "GSM.152": "Innerväggar o d av stålelement",
  "GSM.18": "Diverse konstruktioner av stålelement",
  "GSM.181": "Avfallsschakt av stålelement",
  "GSM.182": "Skorsten av stålelement",
  "GSM.2": "Konstruktioner av element av rostfritt stål",
  "GSM.3": "Konstruktioner av aluminiumelement",
  GSN: "KONSTRUKTIONER AV ELEMENT AV TRÄ ELLER TRÄBASERAT MATERIAL I HUS",
  "GSN.0": "Konstruktioner av element av flera trämaterial",
  "GSN.04": "Konstruktioner av förtillverkade balkar av flera trämaterial",
  "GSN.041":
    "Konstruktioner av förtillverkade balkar med liv av träskiva och flänsar av trä",
  "GSN.05": "Väggar av element av flera trämaterial",
  "GSN.051":
    "Konstruktioner av förtillverkade väggreglar med liv av träskiva och flänsar av trä",
  "GSN.07": "Konstruktioner av förtillverkade takstolar av flera trämaterial",
  "GSN.1": "Konstruktioner av träelement",
  "GSN.15": "Väggar o d av träelement",
  "GSN.151": "Ytterväggar o d av träelement",
  "GSN.1511": "Stomytterväggar av träelement",
  "GSN.1512": "Ytterväggar (ej stomytterväggar) av träelement",
  "GSN.152": "Innerväggar o d av träelement",
  "GSN.1521": "Stominnerväggar av träelement",
  "GSN.1522": "Innerväggar (ej stominnerväggar) av träelement",
  "GSN.16": "Bjälklag o d av träelement",
  "GSN.17": "Konstruktioner av förtillverkade takstolar av trä",
  "GSN.18":
    "Förtillverkade luckor av underlagsspont för inbrädning av yttertak",
  "GSN.2": "Konstruktioner av förtillverkade limträelement",
  "GSN.20": "Sammansatta konstruktioner av limträ",
  "GSN.24": "Konstruktioner av förtillverkade pelare, balkar e d av limträ",
  "GSN.241": "Konstruktioner av förtillverkade pelare av limträ",
  "GSN.242": "Konstruktioner av förtillverkade balkar av limträ",
  "GSN.243": "Konstruktioner av förtillverkade ramar av limträ",
  "GSN.244": "Konstruktioner av förtillverkade bågar av limträ",
  "GSN.3": "Konstruktioner av element av fanerträ eller plywood",
  "GSN.31": "Konstruktioner av element av fanerträ",
  "GSN.32": "Konstruktioner av element av plywood",
  "GSN.4": "Konstruktioner av element av träfiberskivor",
  "GSN.5": "Konstruktioner av element av spånskivor",
  "GSN.6": "Konstruktioner av element av träullsplattor",
  "GSN.61": "Väggar av element av träullsplattor",
  "GSN.62": "Yttertak av element av träullsplattor",
  "GSN.7": "Konstruktioner av element av korslimmat trä (KL-trä)",
  "GSN.71": "Pelare av element av korslimmat trä (KL-trä)",
  "GSN.72": "Balkar av element av korslimmat trä (KL-trä)",
  "GSN.73": "Väggar av element av korslimmat trä (KL-trä)",
  "GSN.731": "Ytterväggar av element av korslimmat trä (KL-trä)",
  "GSN.732": "Innerväggar av element av korslimmat trä (KL-trä)",
  "GSN.74": "Golvbjälklag av element av korslimmat trä (KL-trä)",
  "GSN.75": "Yttertak och ytterbjälklag av element av korslimmat trä (KL-trä)",
  "GSN.76":
    "Balkongplan och loftgångsplan av element av korslimmat trä (KL-trä)",
  "GSN.761": "Balkongplan av element av korslimmat trä (KL-trä)",
  "GSN.762": "Loftgångsplan av element av korslimmat trä (KL-trä)",
  "GSN.77": "Trappor av element av korslimmat trä (KL-trä)",
  "GSN.78": "Diverse konstruktioner av element av korslimmat trä (KL-trä)",
  "GSN.781": "Hisschakt av element av korslimmat trä (KL-trä)",
  GSP: "KONSTRUKTIONER AV ISOLERELEMENT FÖR HUS",
  "GSP.3": "Konstruktioner av isolerelement för hus",
  "GSP.32": "Konstruktioner av isolerelement i yttervägg för hus",
  "GSP.34":
    "Konstruktioner av isolerelement i yttertak eller ytterbjälklag för hus",
  "GSP.5": "Konstruktioner av isolerelement för kyl- eller fryshus",
  "GSP.52":
    "Konstruktioner av isolerelement i yttervägg för kyl- eller fryshus",
  "GSP.54":
    "Konstruktioner av isolerelement i yttertak eller ytterbjälklag för kyl- eller fryshus",
  "GSP.6": "Konstruktioner av isolerelement för kyl- eller frysrum",
  "GSP.65":
    "Konstruktioner av isolerelement i innervägg för kyl- eller frysrum",
  "GSP.66": "Konstruktioner av isolerelement i innertak för kyl- eller frysrum",
  "GSP.67": "Konstruktioner av isolerelement i golv för kyl- eller frysrum",
  GSR: "KONSTRUKTIONER AV GLASELEMENT I HUS",
  "GSR.1":
    "Konstruktioner av glaselement i yttertak, ytterbjälklag och ytterväggar",
  "GSR.11": "Yttertak av glaselement",
  "GSR.12": "Ytterväggar av glaselement",
  GSY: "KONSTRUKTIONER AV ELEMENT AV DIVERSE MATERIAL I HUS",
  H: "KONSTRUKTIONER AV LÄNGDFORMVAROR",
  HB: "KONSTRUKTIONER AV LÄNGDFORMVAROR I ANLÄGGNING",
  HBB: "KONSTRUKTIONER AV LÄNGDFORMVAROR AV METALL I ANLÄGGNING",
  "HBB.1": "Konstruktioner av längdformvaror av stål",
  "HBB.11": "Konstruktioner av allmänt konstruktionsstål",
  "HBB.12": "Konstruktioner av kallbearbetat stål",
  "HBB.121": "Linor och kablar",
  "HBB.1211": "Linor och kablar till bro",
  "HBB.122": "Spännstag",
  "HBB.1221": "Spännstag till bro",
  "HBB.13": "Konstruktioner av rostfritt stål",
  "HBB.2":
    "Konstruktioner av längdformvaror av aluminium eller aluminiumlegering",
  "HBB.21":
    "Konstruktioner av aluminium eller aluminiumlegering i bro, brygga, kaj m m",
  "HBB.211": "Konstruktioner av aluminium eller aluminiumlegering i bro",
  HBD: "KONSTRUKTIONER AV LÄNGDFORMVAROR AV TRÄ I ANLÄGGNING",
  "HBD.1": "Konstruktioner av längdformvaror av barrträ",
  "HBD.11": "Konstruktioner av barrträ i bro, brygga, kaj m m",
  "HBD.111": "Konstruktioner av barrträ i bro",
  "HBD.112": "Inbrädningar, inklädnader o d av bro",
  "HBD.1121": "Inbrädningar av bro",
  "HBD.1122": "Inklädnader av bro",
  "HBD.118": "Diverse konstruktioner av längdformvaror av barrträ",
  "HBD.12": "Konstruktioner av barrträ i mark",
  "HBD.121": "Murar av trä",
  "HBD.122": "Skärmar av trä",
  "HBD.15":
    "Kompletteringar av längdformvaror av barrträ till bro, brygga, kaj m m",
  "HBD.155": "Läkt för inbrädningar, inklädnader o d av bro",
  "HBD.1551": "Läkt för inbrädning av bro",
  "HBD.1552": "Läkt för inklädnad av bro",
  "HBD.1553": "Läkt för fribärande plåtbeslagning av bro",
  "HBD.2": "Konstruktioner av längdformvaror av limträ",
  "HBD.21": "Konstruktioner av limträ i bro, brygga, kaj m m",
  "HBD.211": "Konstruktioner av limträ i bro",
  HS: "KONSTRUKTIONER AV LÄNGDFORMVAROR I HUS",
  HSB: "Konstruktioner av längdformvaror av metall i hus",
  "HSB.1": "Konstruktioner av längdformvaror av allmänt konstruktionsstål",
  "HSB.11": "Pelare och väggstommar av stålprofil",
  "HSB.111": "Pelare av stålprofil",
  "HSB.112": "Väggstommar av stålprofil",
  "HSB.1121": "Väggstommar av stålplåtsreglar för beklädnad",
  "HSB.12": "Bjälklagsstommar av stålprofil",
  "HSB.123": "Balkar av stålprofil till bjälklag",
  "HSB.124": "Bjälklagsstommar av stålbalkar",
  "HSB.125":
    "Bjälklagsstommar av stålprofil till golv av durkplåt eller gallerdurk",
  "HSB.126": "Konstruktioner av stålprofil till undergolv",
  "HSB.1261": "Undergolvsstommar av stålplåtsreglar",
  "HSB.14": "Stommar av stålprofil till yttertak, ytterbjälklag eller skärmtak",
  "HSB.141": "Konstruktioner av stålbalkar till yttertak eller ytterbjälklag",
  "HSB.142": "Åsar av stålprofil till yttertak",
  "HSB.1421":
    "Fribärande takpanneläkt av stålplåt på takstolar eller uppregling",
  "HSB.1422":
    "Fribärande läkt av stålplåt på takstolar eller uppregling för överläggsplattor e d av plåt",
  "HSB.1423": "Läkt av stålplåt på inbrädning",
  "HSB.143": "Stommar av stålprofil till skärmtak",
  "HSB.16": "Kompletteringar av stålprofil",
  "HSB.161": "Kantskoningar av stålprofil",
  "HSB.1611": "Kantskoningar av stålprofil vid rörelsefog",
  "HSB.1612": "Kantskoningar av stålprofil vid lastkaj",
  "HSB.162": "Trappstegsskoningar av stålprofil",
  "HSB.163": "Kompletteringar av stålprofil till vägg",
  "HSB.1631": "Avväxlingsbalkar av stålprofil i murverk",
  "HSB.1632": "Avväxlingsbalkar av stålprofil i trävägg",
  "HSB.1633": "Stommar av stålprofil till skärmvägg",
  "HSB.164": "Kompletteringar av stålprofil till bjälklag",
  "HSB.165": "Kompletteringar av stålprofil till yttertak eller ytterbjälklag",
  "HSB.166": "Stommar av stålprofil till undertak",
  "HSB.1666": "Stommar av stålplåtsreglar till undertak",
  "HSB.167":
    "Kompletteringar av stålprofil till vvs-, el-, kyl- eller transportanläggning",
  "HSB.1671": "Telferbalkar av stålprofil",
  "HSB.1672": "Balkar o d av stålprofil för hissutrustning",
  "HSB.18":
    "Diverse konstruktioner av längdformvaror av allmänt konstruktionsstål",
  "HSB.2": "Konstruktioner av längdformvaror av rostfritt stål",
  "HSB.26": "Kompletteringar av profil av rostfritt stål",
  "HSB.262": "Kantskoningar av profil av rostfritt stål",
  "HSB.28": "Diverse konstruktioner av längdformvaror av rostfritt stål",
  "HSB.283": "Avväxlingsjärn, ankarjärn m m av profil av rostfritt stål",
  "HSB.3":
    "Konstruktioner av längdformvaror av aluminium eller aluminiumlegering",
  "HSB.8": "Konstruktioner av längdformvaror av diverse metaller",
  HSD: "Konstruktioner av längdformvaror av trä i hus",
  "HSD.1": "Konstruktioner av längdformvaror av barrträ",
  "HSD.11": "Syllar, stolpverk, regelstommar m m",
  "HSD.111": "Syllar till stolpverk, regelstommar m m",
  "HSD.112": "Plank- och brädstommar till vägg",
  "HSD.113": "Enkla träregelstommar till vägg",
  "HSD.114": "Dubbla träregelstommar till vägg",
  "HSD.12": "Bjälklag, underslag, uppreglingar och undergolv",
  "HSD.121": "Syllar under bottenbjälklag",
  "HSD.122": "Bjälkar",
  "HSD.123": "Blindbottnar",
  "HSD.124": "Underslag och uppreglingar",
  "HSD.1241": "Flytande underslag på sand",
  "HSD.1242": "Fasta reglar till undergolv eller golvkonstruktion av trä",
  "HSD.1243": "Fasta reglar till undergolv av skivor",
  "HSD.125": "Konstruktioner av spontade brädor i undergolv",
  "HSD.1251":
    "Konstruktioner av spontade brädor i undergolv för tätskikt, golvmassa, plattor e d",
  "HSD.1252":
    "Konstruktioner av spontade brädor i undergolv för parkett, mattor, plattor e d",
  "HSD.13": "Taklag, skärmtak och inbrädningar",
  "HSD.131": "Taklag",
  "HSD.1311": "Remstycken till taklag",
  "HSD.1312": "Fribärande takstolar",
  "HSD.1313": "Uppstolpade takstolar",
  "HSD.1314": "Åsar på underlag av trä",
  "HSD.1315": "Åsar på underlag av metall",
  "HSD.1316": "Åsar på underlag av betong, lättbetong e d",
  "HSD.132": "Stommar till skärmtak, entrétak e d",
  "HSD.133": "Inbrädningar o d av yttertak",
  "HSD.1331": "Inbrädning av yttertak - spontade brädor",
  "HSD.1332": "Inbrädning av taksprång med synlig undersida - spontade brädor",
  "HSD.1333": "Inbrädningar av yttertak - råhyvlade brädor",
  "HSD.1334": "Inklädnader av taksprång - träpanel",
  "HSD.134": "Inbrädningar av skärmtak, entrétak e d",
  "HSD.1341": "Inbrädning av skärmtak, entrétak e d - spontade bräder",
  "HSD.1342":
    "Inbrädning av skärmtak, entrétak e d - spontade brädor med synlig undersida",
  "HSD.14": "Kompletteringar av längdformvaror av barrträ till yttertak",
  "HSD.141": "Läkt för överläggsplattor e d på yttertak",
  "HSD.1411": "Fribärande takpanneläkt på takstol eller uppregling",
  "HSD.1412": "Takpanneläkt på inbrädning",
  "HSD.1413": "Läkt för överläggsplattor av skiffer på inbrädning",
  "HSD.1414": "Läkt för träspån",
  "HSD.1415":
    "Fribärande läkt för överläggsplattor av profilerad plåt e d på takstol eller uppregling",
  "HSD.142": "Nockplankor, vindskivor och vattbrädor",
  "HSD.1421": "Nockplankor",
  "HSD.1422": "Vindskivor",
  "HSD.1423": "Vattbrädor",
  "HSD.143": "Rännstommar",
  "HSD.1431": "Stommar till gesimsrännor",
  "HSD.1432": "Stommar till fotrännor",
  "HSD.1433": "Stommar till försänkta ränndalar",
  "HSD.144":
    "Diverse kompletteringar av längdformvaror av barrträ till yttertak",
  "HSD.1441": "Sargar till takluckor",
  "HSD.1442": "Stommar till takluckor",
  "HSD.1443": "Stommar till ventilationsskorstenar e d",
  "HSD.1444": "Reglar för plåtbeslag på yttertak",
  "HSD.1445": "Reglar för fastsättning av tätskiktsmatta e d på yttertak",
  "HSD.1446": "Förstärkningar för kompletteringar till yttertak",
  "HSD.15":
    "Kompletteringar av längdformvaror av barrträ till vägg eller innertak",
  "HSD.151": "Utfyllnader med läkt eller reglar",
  "HSD.152": "Stommar till undertak",
  "HSD.153": "Paneler som underlag för beklädnad",
  "HSD.1531": "Glespanel som underlag för beklädnad",
  "HSD.1532": "Tät panel som underlag för beklädnad",
  "HSD.154": "Spräckpanel bakom putsskikt",
  "HSD.155": "Läkt på vägg för beklädnad",
  "HSD.1551": "Läkt på vägg för träspån",
  "HSD.1552": "Läkt på vägg för överläggsplattor av skiffer",
  "HSD.158":
    "Diverse kompletteringar av längdformvaror av barrträ till vägg eller innertak",
  "HSD.16": "Synlig vägg- och takpanel samt lister utomhus",
  "HSD.161": "Stående panel med lockläkt utomhus",
  "HSD.162": "Stående panel med lockbrädor utomhus",
  "HSD.163": "Stående profilerad panel utomhus",
  "HSD.164": "Liggande panel på förvandring utomhus",
  "HSD.165": "Liggande profilerad panel utomhus",
  "HSD.166": "Panel på undersida av skärmtak, entrétak e d utomhus",
  "HSD.167": "Foder, lister och knutbrädor utomhus",
  "HSD.168": "Diverse synlig vägg- och takpanel samt lister utomhus",
  "HSD.17": "Synlig vägg- och takpanel inomhus",
  "HSD.171": "Synlig väggpanel inomhus",
  "HSD.172": "Synlig takpanel inomhus",
  "HSD.18": "Diverse konstruktioner av längdformvaror av barrträ",
  "HSD.181": "Inklädnader o d",
  "HSD.1811": "Inklädnader till expansionskärl e d",
  "HSD.182": "Brädgångar och plattformar i vindsutrymme e d",
  "HSD.184": "Brädor för elledningar i vindsutrymme e d",
  "HSD.185": "Förstärkning för antennfäste e d",
  "HSD.186": "Avvisare på lastkaj e d",
  "HSD.187": "Trappor av plank eller brädor",
  "HSD.188": "Platsbyggda stegar",
  "HSD.2": "Konstruktioner av längdformvaror av limträ",
  "HSD.21": "Stolpverk av limträ",
  "HSD.22": "Balkar av limträ",
  "HSD.221": "Balkar av limträ i bjälklag",
  "HSD.26": "Synlig panel utomhus av limträ",
  "HSD.261": "Stående panel",
  "HSD.262": "Liggande panel",
  "HSD.3": "Konstruktioner av längdformvaror av fanerträ",
  "HSD.31": "Syllar, stolpverk, regelstommar m m av fanerträ",
  "HSD.311": "Syllar av fanerträ till stolpverk, regelstommar m m",
  "HSD.313": "Enkla regelstommar av fanerträ till vägg",
  "HSD.314": "Dubbla regelstommar av fanerträ till vägg",
  "HSD.32": "Bjälklag av fanerträ",
  "HSD.322": "Golvbalkar av fanerträ",
  "HSD.33": "Taklag o d av fanerträ",
  "HSD.331": "Taklag av fanerträ",
  "HSD.3312": "Fribärande takbalkar av fanerträ",
  "HSD.3313": "Uppstolpade takstolar av fanerträ",
  "HSD.3314": "Åsar av fanerträ på underlag av trä",
  "HSD.3315": "Åsar av fanerträ på underlag av metall",
  "HSD.3316": "Åsar av fanerträ på underlag av betong, lättbetong e d",
  "HSD.4": "Konstruktioner av längdformvaror av lövträ",
  "HSD.47": "Synlig vägg- och takpanel inomhus av lövträ",
  "HSD.471": "Synlig väggpanel inomhus",
  "HSD.472": "Synlig takpanel inomhus",
  HSZ: "KONSTRUKTIONER AV LÄNGDFORMVAROR AV ÖVRIGA MATERIAL I HUS",
  I: "SKIKT AV TERMOISOLERVAROR M M I HUS OCH I GRUNDKONSTRUKTIONER TILL HUS",
  IB: "TERMISK ISOLERING M M I HUS (EJ PLATSBYGGT KYL- ELLER FRYSRUM)",
  IBC: "TERMISK ISOLERING AV GRUNDKONSTRUKTION",
  "IBC.1": "Termisk isolering på mark under grundkonstruktion",
  "IBC.11": "Termisk isolering av mineralull under grundkonstruktion",
  "IBC.12": "Termisk isolering av cellplast under grundkonstruktion",
  "IBC.121":
    "Termisk isolering av icke dränerande cellplast under grundkonstruktion",
  "IBC.122":
    "Termisk isolering av dränerande cellplast under grundkonstruktion",
  "IBC.13": "Termisk isolering av lättklinkerfyllning under grundkonstruktion",
  "IBC.14": "Termisk isolering av cellglas under grundkonstruktion",
  "IBC.2": "Termisk isolering i mark intill grundkonstruktion",
  "IBC.3":
    "Termisk isolering utvändigt på murad eller gjuten grundsockel eller grundkantbalk",
  "IBC.31":
    "Termisk isolering av mineralull utvändigt på murad eller gjuten grundsockel eller grundkantbalk",
  "IBC.32":
    "Termisk isolering av cellplast utvändigt på murad eller gjuten grundsockel eller grundkantbalk",
  "IBC.321":
    "Termisk isolering av icke dränerande cellplast utvändigt på murad eller gjuten grundsockel eller grundkantbalk",
  "IBC.322":
    "Termisk isolering av dränerande cellplast utvändigt på murad eller gjuten grundsockel eller grundkantbalk",
  "IBC.33":
    "Termisk isolering av lättklinkerblock utvändigt på murad eller gjuten grundsockel eller grundkantbalk",
  "IBC.34":
    "Termisk isolering av cellglas utvändigt på murad eller gjuten grundsockel eller grundkantbalk",
  "IBC.4":
    "Termisk isolering invändigt på murad eller gjuten grundsockel eller grundkantbalk, invändigt motfylld",
  "IBC.41":
    "Termisk isolering av mineralull invändigt på murad eller gjuten grundsockel eller grundkantbalk, invändigt motfylld",
  "IBC.42":
    "Termisk isolering av cellplast invändigt på murad eller gjuten grundsockel eller grundkantbalk, invändigt motfylld",
  "IBC.5":
    "Termisk isolering invändigt på murad eller gjuten grundsockel mot kryputrymme",
  "IBC.51":
    "Termisk isolering av mineralull invändigt på murad eller gjuten grundsockel mot kryputrymme",
  "IBC.52":
    "Termisk isolering av cellplast invändigt på murad eller gjuten grundsockel mot kryputrymme",
  "IBC.6": "Termisk isolering på mark i kryputrymme",
  "IBC.61": "Termisk isolering av mineralull på mark i kryputrymme",
  "IBC.62": "Termisk isolering av cellplast på mark i kryputrymme",
  "IBC.621":
    "Termisk isolering av icke dränerande cellplast på mark i kryputrymme",
  "IBC.622": "Termisk isolering av dränerande cellplast på mark i kryputrymme",
  "IBC.63": "Termisk isolering av lättklinkerfyllning på mark i kryputrymme",
  IBE: "TERMISK ISOLERING AV YTTERVÄGG",
  "IBE.1": "Termisk isolering i mark utvändigt på murad eller gjuten yttervägg",
  "IBE.11":
    "Termisk isolering av mineralull i mark utvändigt på murad eller gjuten yttervägg",
  "IBE.12":
    "Termisk isolering av cellplast i mark utvändigt på murad eller gjuten yttervägg",
  "IBE.121":
    "Termisk isolering av icke dränerande cellplast i mark utvändigt på murad eller gjuten yttervägg",
  "IBE.122":
    "Termisk isolering av dränerande cellplast i mark utvändigt på murad eller gjuten yttervägg",
  "IBE.13":
    "Termisk isolering av lättklinkerblock i mark utvändigt på murad eller gjuten yttervägg",
  "IBE.14":
    "Termisk isolering av lättklinkerfyllning i mark utvändigt mot murad eller gjuten yttervägg",
  "IBE.15":
    "Termisk isolering av cellglas i mark utvändigt på murad eller gjuten yttervägg",
  "IBE.2": "Termisk isolering i yttervägg",
  "IBE.21": "Termisk isolering i betong i yttervägg",
  "IBE.211": "Termisk isolering av mineralull i betong i yttervägg",
  "IBE.212": "Termisk isolering av cellplast i betong i yttervägg",
  "IBE.22": "Termisk isolering på betong i yttervägg",
  "IBE.221": "Termisk isolering av mineralull på betong i yttervägg",
  "IBE.222": "Termisk isolering av cellplast på betong i yttervägg",
  "IBE.223":
    "Termisk isolering av block av autoklaverad lättbetong på betong i yttervägg",
  "IBE.224":
    "Termisk isolering av block av lättklinkerbetong på betong i yttervägg",
  "IBE.225": "Termisk isolering av block av cellglas på betong i yttervägg",
  "IBE.23": "Termisk isolering på murverk i yttervägg",
  "IBE.231": "Termisk isolering av mineralull på murverk i yttervägg",
  "IBE.232": "Termisk isolering av cellplast på murverk i yttervägg",
  "IBE.233": "Termisk isolering av cellglas på murverk i yttervägg",
  "IBE.24": "Termisk isolering mellan reglar i yttervägg",
  "IBE.241": "Termisk isolering av mineralull mellan reglar i yttervägg",
  "IBE.242":
    "Termisk isolering av lösfyllnadsisolering mellan reglar i yttervägg",
  "IBE.25": "Termisk isolering mot utsida av yttre regelstomme i yttervägg",
  "IBE.251":
    "Termisk isolering av mineralull mot utsida av yttre regelstomme i yttervägg",
  "IBE.252":
    "Termisk isolering av cellplast mot utsida av yttre regelstomme i yttervägg",
  IBF: "TERMISK ISOLERING AV BJÄLKLAG",
  "IBF.1":
    "Termisk isolering på hel grundplatta eller golvbjälklag av betong, lättbetong e d",
  "IBF.11":
    "Termisk isolering av cellplast på hel grundplatta eller golvbjälklag av betong, lättbetong e d",
  "IBF.12":
    "Termisk isolering av cellglas på hel grundplatta eller golvbjälklag av betong, lättbetong e d",
  "IBF.2": "Termisk isolering mellan balkar i golvbjälklag av trä",
  "IBF.21":
    "Termisk isolering av mineralull mellan balkar i golvbjälklag av trä",
  "IBF.22":
    "Termisk isolering av lösfyllnadsisolering mellan balkar i golvbjälklag av trä",
  "IBF.3":
    "Termisk isolering mellan underramar eller balkar i inspekterbart vindsbjälklag av trä",
  "IBF.31":
    "Termisk isolering av mineralull mellan underramar eller balkar i inspekterbart vindsbjälklag av trä",
  "IBF.32":
    "Termisk isolering av lösfyllnadsisolering mellan underramar eller balkar i inspekterbart vindsbjälklag av trä",
  "IBF.4":
    "Termisk isolering på inspekterbart vindsbjälklag av betong, lättbetong e d",
  "IBF.41":
    "Termisk isolering av mineralull på inspekterbart vindsbjälklag av betong, lättbetong e d",
  "IBF.42":
    "Termisk isolering av lösfyllnadsisolering på inspekterbart vindsbjälklag av betong, lättbetong e d",
  "IBF.5": "Termisk isolering under mellanbjälklag av betong, lättbetong e d",
  "IBF.51":
    "Termisk isolering av mineralull under mellanbjälklag av betong, lättbetong e d",
  "IBF.52":
    "Termisk isolering av cellplast under mellanbjälklag av betong, lättbetong e d",
  "IBF.53":
    "Termisk isolering av träull under mellanbjälklag av betong, lättbetong e d",
  IBG: "TERMISK ISOLERING AV YTTERTAK ELLER YTTERBJÄLKLAG",
  "IBG.1":
    "Termisk isolering mellan balkar eller överramar i yttertak eller ytterbjälklag",
  "IBG.11":
    "Termisk isolering av mineralull mellan balkar eller överramar i yttertak eller ytterbjälklag",
  "IBG.12":
    "Termisk isolering av lösfyllnadsisolering mellan balkar eller överramar i yttertak eller ytterbjälklag",
  "IBG.2": "Termisk isolering under tätskikt i yttertak eller ytterbjälklag",
  "IBG.21":
    "Termisk isolering av mineralull under tätskikt i yttertak eller ytterbjälklag",
  "IBG.22":
    "Termisk isolering av cellplast under tätskikt i yttertak eller ytterbjälklag",
  "IBG.23":
    "Termisk isolering av cellglas under tätskikt i yttertak eller ytterbjälklag",
  "IBG.3": "Termisk isolering på tätskikt i yttertak eller ytterbjälklag",
  "IBG.31":
    "Termisk isolering av cellplast på tätskikt i yttertak eller ytterbjälklag",
  "IBG.311":
    "Termisk isolering av icke dränerande cellplast på tätskikt i yttertak eller ytterbjälklag",
  "IBG.312":
    "Termisk isolering av dränerande cellplast på tätskikt i yttertak eller ytterbjälklag",
  "IBG.4": "Termisk isolering i yttertak ovan ouppvärmt vindsutrymme",
  "IBG.41": "Termisk isolering av mineralull ovan ouppvärmt vindsutrymme",
  "IBG.42": "Termisk isolering av cellplast ovan ouppvärmt vindsutrymme",
  IBI: "TERMISK ISOLERING AV INNERVÄGG",
  IBK: "TERMISK ISOLERING I SKORSTEN, SCHAKT E D",
  "IBK.1": "Termisk isolering i skorsten",
  "IBK.11": "Termisk isolering i skorsten av tegel för förbränningsgaser",
  IC: "TERMISK ISOLERING AV PLATSBYGGDA KYL- OCH FRYSRUM SAMT KYL- OCH FRYSHUS",
  ICB: "TERMISK ISOLERING AV KYLRUM",
  "ICB.1": "Termisk isolering av kylrumsgolv",
  "ICB.11": "Termisk isolering av cellplast till kylrumsgolv",
  "ICB.13": "Termisk isolering av cellglas till kylrumsgolv",
  ICC: "TERMISK ISOLERING AV FRYSRUM",
  "ICC.1": "Termisk isolering av frysrumsgolv",
  "ICC.11": "Termisk isolering av cellplast till frysrumsgolv",
  "ICC.13": "Termisk isolering av cellglas till frysrumsgolv",
  ICE: "TERMISK ISOLERING AV KYLHUS",
  "ICE.1": "Termisk isolering av kylhustak",
  "ICE.11": "Termisk isolering av cellplast utvändigt till kylhustak",
  "ICE.12": "Termisk isolering av cellglas utvändigt till kylhustak",
  ICF: "TERMISK ISOLERING AV FRYSHUS",
  "ICF.1": "Termisk isolering av fryshustak",
  "ICF.11": "Termisk isolering av cellplast utvändigt till fryshustak",
  "ICF.12": "Termisk isolering av cellglas utvändigt till fryshustak",
  IF: "BRANDISOLERING AV BYGGNADSKONSTRUKTIONER MED TERMOISOLERVAROR",
  IG: "LJUDISOLERING AV BYGGNADSKONSTRUKTIONER MED TERMOISOLERVAROR",
  J: "SKIKT AV BYGGPAPP, TÄTSKIKTSMATTA, ASFALT, DUK, PLASTFILM, PLAN PLÅT, ÖVERLÄGGSPLATTOR E D",
  JB: "SKIKT AV BYGGPAPP, TÄTSKIKTSMATTA, ASFALTMASTIX, EPOXI E D I ANLÄGGNING",
  JBB: "SKILJESKIKT AV PLASTFILM, BYGGPAPP, FILT E D I ANLÄGGNING",
  "JBB.1": "Skiljeskikt av plastfilm, byggpapp, filt e d i bro",
  JBC: "VATTENTÄTA SKIKT AV TÄTSKIKTSPAPP MED BITUMEN I ANLÄGGNING",
  JBE: "VATTENTÄTA SKIKT AV TÄTSKIKTSMATTA, ASFALTMASTIX E D I ANLÄGGNING",
  "JBE.1": "Vattentäta skikt av tätskiktsmatta",
  "JBE.11": "Vattentäta skikt av tätskiktsmatta i bro",
  "JBE.111": "Vattentäta skikt av tätskiktsmatta på brobaneplatta av betong",
  "JBE.112": "Vattentäta skikt av tätskiktsmatta på brobaneplatta av stål",
  "JBE.113": "Vattentäta skikt av tätskiktsmatta på brobaneplatta av trä",
  "JBE.114": "Vattentäta skikt av tätskiktsmatta på brobaneplatta av aluminium",
  "JBE.12": "Vattentäta skikt av tätskiktsmatta i terräng",
  "JBE.124": "Vattentäta skikt av tätskiktsmatta för brunn, kammare e d",
  "JBE.2": "Vattentäta skikt av asfaltmastix",
  "JBE.21": "Vattentäta skikt av asfaltmastix i bro",
  "JBE.211": "Vattentäta skikt av asfaltmastix på brobaneplatta av betong",
  JBF: "VATTENTÄTA SKIKT AV EPOXI I ANLÄGGNING",
  "JBF.1": "Vattentäta skikt av epoxi i bro",
  "JBF.11": "Vattentäta skikt av epoxi på brobaneplatta av betong",
  "JBF.12": "Vattentäta skikt av epoxi på brobaneplatta av stål",
  JBG: "VATTENTÄTA SKIKT AV AKRYLAT ELLER POLYURETAN I ANLÄGGNING",
  "JBG.1": "Vattentäta skikt av akrylat eller polyuretan i bro",
  "JBG.11": "Vattentäta skikt av akrylat i bro",
  "JBG.111": "Vattentäta skikt av akrylat på brobaneplatta av betong",
  "JBG.112":
    "Vattentäta skikt av akrylat på brobaneplatta av stål och aluminium",
  "JBG.12": "Vattentäta skikt av polyuretan i bro",
  JBH: "AVJÄMNING AV BETONGÖVERYTOR MED ICKE CEMENTBASERAT MATERIAL I ANLÄGGNING",
  "JBH.1": "Avjämning av betongöverytor med asfaltbaserat material",
  "JBH.11": "Avjämning med asfaltspackel",
  "JBH.111": "Avjämning med asfaltspackel i bro",
  "JBH.12": "Avjämning med polymermodifierad asfaltmastix",
  "JBH.121": "Avjämning med polymermodifierad asfaltmastix i bro",
  "JBH.2": "Avjämning av betongöverytor med epoxibaserat material",
  "JBH.3": "Avjämning av betongöverytor med akrylatbaserat material",
  JBJ: "FÖRSEGLINGAR I ANLÄGGNING",
  "JBJ.1": "Kantförseglingar av tätskikt",
  "JBJ.11": "Kantförseglingar av tätskikt i bro",
  "JBJ.112": "Kantförseglingar av epoxi i bro",
  "JBJ.113": "Kantförseglingar av tätskiktsmatta i bro",
  "JBJ.1131": "Kantförsegling av tätskiktsmatta på betong",
  "JBJ.1132": "Kantförsegling av tätskiktsmatta på metall",
  "JBJ.1133": "Kantförsegling av tätskiktsmatta på trä",
  "JBJ.2": "Försegling av gjutfog",
  "JBJ.8": "Diverse förseglingar",
  "JBJ.81": "Diverse förseglingar i bro",
  JBK: "VATTENAVLEDANDE SKIKT AV BYGGPAPP, DUK, FOLIE E D FÖR PLAN PLÅT I ANLÄGGNING",
  "JBK.1": "Vattenavledande skikt av byggpapp för plan plåt",
  "JBK.11": "Vattenavledande skikt av byggpapp för plan plåt på bro",
  "JBK.12": "Detaljutföranden av vattenavledande skikt av byggpapp på bro",
  "JBK.121": "Uppdragning av vattenavledande skikt av byggpapp på sarg e d",
  "JBK.122":
    "Utförande av vattenavledande skikt av byggpapp vid brytningar i underlag",
  "JBK.123": "Utförande av vattenavledande skikt av byggpapp vid genomföringar",
  JC: "SKIKT OCH KOMPLETTERINGAR AV PLAN PLÅT FÖR ANLÄGGNING",
  JCB: "SKIKT OCH KOMPLETTERINGAR AV PLAN METALLISERAD STÅLPLÅT FÖR ANLÄGGNING",
  "JCB.5": "Kompletteringar av plan metalliserad stålplåt på bro",
  "JCB.52": "Droppbleck, listbeslag e d av metalliserad stålplåt på bro",
  "JCB.521": "Droppbleck av metalliserad stålplåt",
  "JCB.525": "Listbeslag av metalliserad stålplåt",
  "JCB.53": "Beslagning av överytor av metalliserad stålplåt på bro",
  "JCB.531": "Beslagning av metalliserad stålplåt på bärverk utan luftspalt",
  "JCB.532": "Beslagning av metalliserad stålplåt på inbrädning med luftspalt",
  "JCB.533": "Beslagning med fribärande metalliserad stålplåt på läkt",
  "JCB.58": "Diverse kompletteringar av plan metalliserad stålplåt på bro",
  "JCB.586": "Stosar av metalliserad stålplåt till rör, stolpe e d på bro",
  JCC: "SKIKT OCH KOMPLETTERINGAR AV PLAN ROSTFRI STÅLPLÅT FÖR ANLÄGGNING",
  "JCC.3":
    "Kompletteringar av plan plåt av rostfritt stål vid skikt av tätskiktsmatta i bro e d",
  "JCC.31": "Kantplåtar av rostfri stålplåt på träbro",
  "JCC.5": "Kompletteringar av plan plåt av rostfritt stål på bro",
  "JCC.52": "Droppbleck, listbeslag e d av rostfri stålplåt på bro",
  "JCC.521": "Droppbleck av rostfri stålplåt",
  "JCC.53": "Beslagning av överytor rostfri stålplåt på bro",
  "JCC.531": "Beslagning av rostfri stålplåt på bärverk utan luftspalt",
  "JCC.532": "Beslagning av rostfri stålplåt på inbrädning med luftspalt",
  "JCC.533": "Beslagning med fribärande rostfri stålplåt på läkt",
  "JCC.58": "Diverse kompletteringar av plan rostfri stålplåt på bro",
  "JCC.586": "Stosar av rostfri stålplåt till rör, stolpe e d på bro",
  JS: "SKIKT AV BYGGPAPP, TÄTSKIKTSMATTA, ASFALT, DUK, PLASTFILM E D I HUS",
  JSB: "UNDERLAGSTÄCKNINGAR AV BYGGPAPP E D I HUS",
  "JSB.1": "Underlagstäckningar av byggpapp för tätskiktsmatta",
  "JSB.11": "Underlagstäckningar av byggpapp för tätskiktsmatta i yttertak",
  "JSB.111": "UT typ 111 för tätskiktsmatta i yttertak",
  JSC: "VATTENAVLEDANDE SKIKT AV BYGGPAPP, UNDERLAGSDUK, E D FÖR PLAN PLÅT OCH ÖVERLÄGGSPLATTOR I HUS",
  "JSC.1":
    "Vattenavledande skikt av byggpapp för plan plåt och överläggsplattor",
  "JSC.11":
    "Vattenavledande skikt av byggpapp för plan plåt och överläggsplattor i yttertak",
  "JSC.111": "VU typ 111 för överläggsplattor i yttertak",
  "JSC.112": "VU typ 112 för överläggsplattor i yttertak",
  "JSC.113": "VU typ 113 för överläggsplattor i yttertak",
  "JSC.114": "VU typ 114 för plan plåt och överläggsplattor i yttertak",
  "JSC.12": "Detaljutföranden av vattenavledande skikt av byggpapp på yttertak",
  "JSC.121":
    "Uppdragning av vattenavledande skikt av byggpapp på vägg, sarg e d",
  "JSC.122": "Utförande av vattenavledande skikt av byggpapp vid genomföringar",
  "JSC.123": "Utförande av vattenavledande skikt av byggpapp vid takfot",
  "JSC.124": "Utförande av vattenavledande skikt av byggpapp vid gavel",
  "JSC.125": "Utförande av vattenavledande skikt av byggpapp vid nock",
  "JSC.126": "Utförande av vattenavledande skikt av byggpapp i vinkelränna",
  "JSC.128":
    "Diverse detaljutförande för vattenavledande skikt av byggpapp i yttertak",
  "JSC.6":
    "Vattenavledande skikt av underlagsduk för överläggsplattor och plan plåt i yttertak",
  "JSC.61":
    "Vattenavledande skikt av underlagsduk på underlagsspont e d för överläggsplattor och plan plåt i yttertak",
  "JSC.611": "VU typ 611 för överläggsplattor i yttertak",
  "JSC.612": "VU typ 612 för överläggsplattor och plan plåt i yttertak",
  "JSC.62":
    "Detaljutförande av vattenavledande skikt av underlagsduk på underlagsspont e d för överläggsplattor och plan plåt i yttertak",
  "JSC.621":
    "Uppdragning av vattenavledande skikt av underlagsduk på vägg, sarg e d",
  "JSC.622":
    "Utförande av vattenavledande skikt av underlagsduk vid genomföring",
  "JSC.623": "Utförande av vattenavledande skikt av underlagsduk vid takfot",
  "JSC.624": "Utförande av vattenavledande skikt av underlagsduk vid gavel",
  "JSC.625": "Utförande av vattenavledande skikt av underlagsduk vid nock",
  "JSC.626": "Utförande av vattenavledande skikt av underlagsduk i vinkelränna",
  "JSC.628":
    "Diverse detaljutföranden av vattenavledande skikt av underlagsduk på underlagsspont e d i yttertak",
  "JSC.65":
    "Vattenavledande skikt av frihängande underlagsduk för överläggsplattor i yttertak",
  "JSC.651": "VU typ 651",
  "JSC.652": "VU typ 652",
  "JSC.66":
    "Detaljutförande av vattenavledande skikt av frihängande underlagsduk för överläggsplattor i yttertak",
  "JSC.661":
    "Uppdragning av vattenavledande skikt av frihängande underlagsduk på vägg, sarg e d",
  "JSC.662":
    "Utförande av vattenavledande skikt av frihängande underlagsduk vid genomföring",
  "JSC.663":
    "Utförande av vattenavledande skikt av frihängande underlagsduk vid takfot",
  "JSC.664":
    "Utförande av vattenavledande skikt av frihängande underlagsduk vid gavel",
  "JSC.665":
    "Utförande av vattenavledande skikt av frihängande underlagsduk vid nock",
  "JSC.666":
    "Utförande av vattenavledande skikt av frihängande underlagsduk i vinkelränna",
  "JSC.668":
    "Diverse detaljutföranden av vattenavledande skikt av frihängande underlagsduk i yttertak",
  JSD: "SKILJESKIKT AV PLASTFILM, BYGGPAPP, FILT M M I HUS",
  "JSD.1":
    "Glid- och skyddsskikt av plastfilm, byggpapp e d under skyddsbetongbeläggning, flytande golv m m",
  "JSD.11":
    "Glid- och skyddsskikt av plastfilm, byggpapp e d till vattentätt skikt under skyddsbetongbeläggning",
  "JSD.12":
    "Glid- och skyddsskikt av plastfilm, byggpapp e d under flytande golv med beläggning av fogplattor av natursten, konstbetong e d",
  "JSD.2": "Glid- och skyddsskikt av filt e d till vattentätt skikt m m",
  "JSD.3": "Migreringsspärrar av filt e d till vattentätt skikt",
  "JSD.4":
    "Skyddsskikt av plastfilm, byggpapp, geotextil e d till skydd av underlag för pågjutning e d",
  "JSD.5": "Materialskiljande skikt av geotextil m m i vegetationstak e d",
  JSE: "VATTENTÄTA SKIKT AV ASFALT, DUK, FOLIE E D I HUS",
  "JSE.1": "Vattentäta skikt av tätskiktsmatta",
  "JSE.13": "Vattentäta skikt av tätskiktsmatta i bjälklag inomhus",
  "JSE.131": "Vattentäta enlagstäckningar av tätskiktsmatta i bjälklag inomhus",
  "JSE.131-1": "Uppdragningar av TI typ 131- på vägg, sarg e d",
  "JSE.131-2": "Anslutningar av TI typ 131- till fläns",
  "JSE.131-6": "Utförande av TI typ 131- i ränndal",
  "JSE.131-8": "Diverse detaljutföranden av TI typ 131-",
  "JSE.1311": "TI typ 1311",
  "JSE.1313": "TI typ 1313",
  "JSE.14": "Vattentäta skikt av tätskiktsmatta i ytterbjälklag",
  "JSE.141": "Vattentäta enlagstäckningar av tätskiktsmatta i ytterbjälklag",
  "JSE.1411": "TT typ 1411",
  "JSE.1412": "TT typ 1412",
  "JSE.1413": "TT typ 1413",
  "JSE.142": "Vattentäta tvålagstäckningar av tätskiktsmatta i ytterbjälklag",
  "JSE.142-1": "Uppdragningar av TT typ 142- på vägg, sarg e d",
  "JSE.142-2": "Anslutningar av TT typ 142- till fläns",
  "JSE.142-3": "Neddragningar av TT typ 142- vid fri bjälklagskant",
  "JSE.142-6": "Utförande av TT typ 142- i ränndal",
  "JSE.142-8": "Diverse detaljutföranden av TT typ 142-",
  "JSE.1421": "TT typ 1421",
  "JSE.1422": "TT typ 1422",
  "JSE.1423": "TT typ 1423",
  "JSE.15": "Vattentäta skikt av tätskiktsmatta på yttertak",
  "JSE.151": "Vattentäta enlagstäckningar av tätskiktsmatta på yttertak",
  "JSE.151-1": "Uppdragningar av TY typ 151- på vägg, sarg e d",
  "JSE.151-2": "Anslutningar av TY typ 151- till fläns",
  "JSE.151-3": "Anslutningar av TY typ 151- till fotplåt",
  "JSE.151-4": "Anslutningar av TY typ 151- till kantregel",
  "JSE.151-5": "Skarvningar av TY typ 151- vid nock",
  "JSE.151-6": "Utförande av TY typ 151- i ränndal",
  "JSE.151-7": "Utförande av TY typ 151- i fotränna",
  "JSE.151-8": "Diverse detaljutföranden av TY typ 151-",
  "JSE.1511": "TY typ 1511",
  "JSE.1512": "TY typ 1512",
  "JSE.1513": "TY typ 1513",
  "JSE.152": "Vattentäta tvålagstäckningar av tätskiktsmatta på yttertak",
  "JSE.152-1": "Uppdragningar av TY typ 152- på vägg, sarg e d",
  "JSE.152-2": "Anslutningar av TY typ 152- till fläns",
  "JSE.152-3": "Anslutningar av TY typ 152- till fotplåt",
  "JSE.152-4": "Anslutningar av TY typ 152- till kantregel",
  "JSE.152-5": "Skarvningar av TY typ 152- vid nock",
  "JSE.152-6": "Utförande av TY typ 152- i ränndal",
  "JSE.152-7": "Utförande av TY typ 152- i fotränna",
  "JSE.152-8": "Diverse detaljutföranden av TY typ 152-",
  "JSE.1521": "TY typ 1521",
  "JSE.1522": "TY typ 1522",
  "JSE.1523": "TY typ 1523",
  "JSE.1524": "TY typ 1524",
  "JSE.1525": "TY typ 1525",
  "JSE.17": "Kompletteringar till vattentätt skikt av tätskiktsmatta",
  "JSE.171":
    "Kompletteringar för avvattning till vattentätt skikt av tätskiktsmatta",
  "JSE.1711": "Brunnar till vattentätt skikt av tätskiktsmatta i ytterbjälklag",
  "JSE.1712": "Brunnar till vattentätt skikt av tätskiktsmatta på yttertak",
  "JSE.1713":
    "Bräddavlopp till vattentätt skikt av tätskiktsmatta i ytterbjälklag",
  "JSE.1714": "Bräddavlopp till vattentätt skikt av tätskiktsmatta på yttertak",
  "JSE.172":
    "Kompletteringar till genomföringar m m i vattentätt skikt av tätskiktsmatta",
  "JSE.1722":
    "Stosar av gummi till vattentätt skikt av tätskiktsmatta på yttertak",
  "JSE.1724":
    "Ventilationshuvar av gummi e d till vattentätt skikt av tätskiktsmatta på yttertak",
  "JSE.178": "Diverse kompletteringar till vattentätt skikt av tätskiktsmatta",
  "JSE.2": "Vattentäta skikt av asfaltmastix",
  "JSE.23": "Vattentäta skikt av asfaltmastix i bjälklag inomhus",
  "JSE.23-1": "Uppdragningar av TI typ 23- på vägg, sarg e d",
  "JSE.23-2": "Anslutningar av TI typ 23- till fläns",
  "JSE.23-8": "Diverse detaljutföranden av TI typ 23- i bjälklag inomhus",
  "JSE.231": "TI typ 231",
  "JSE.2311": "Uppdragningar av TI typ 231 på vägg, sarg e d",
  "JSE.2312": "Anslutningar av TI typ 231 till fläns",
  "JSE.2318": "Diverse detaljutföranden av TI typ 231 i bjälklag inomhus",
  "JSE.24": "Vattentäta skikt av asfaltmastix i ytterbjälklag",
  "JSE.24-1": "Uppdragningar av TT typ 24- på vägg, sarg e d",
  "JSE.24-2": "Anslutningar av TT typ 24- till fläns",
  "JSE.24-3": "Neddragningar av TT typ 24- vid fri bjälklagskant",
  "JSE.24-8": "Diverse detaljutföranden av TT typ 24- i ytterbjälklag",
  "JSE.241": "TT typ 241",
  "JSE.2411": "Uppdragningar av TT typ 241 på vägg, sarg e d",
  "JSE.2412": "Anslutningar av TT typ 241 till fläns",
  "JSE.2413": "Neddragningar av TT typ 241 vid fri bjälklagskant",
  "JSE.2418": "Diverse detaljutföranden av TT typ 241 i ytterbjälklag",
  "JSE.242": "TT typ 242",
  "JSE.2421": "Uppdragningar av TT typ 242 på vägg, sarg e d",
  "JSE.2422": "Anslutningar av TT typ 242 till fläns",
  "JSE.2423": "Neddragningar av TT typ 242 vid fri bjälklagskant",
  "JSE.2428": "Diverse detaljutföranden av TT typ 242 i ytterbjälklag",
  "JSE.243": "TT typ 243",
  "JSE.2431": "Uppdragningar av TT typ 243 på vägg, sarg e d",
  "JSE.2432": "Anslutningar av TT typ 243 till fläns",
  "JSE.2433": "Neddragningar av TT typ 243 vid fri bjälklagskant",
  "JSE.2438": "Diverse detaljutföranden av TT typ 243 i ytterbjälklag",
  "JSE.27": "Kompletteringar till vattentätt skikt av asfaltmastix",
  "JSE.271":
    "Kompletteringar till avvattning av vattentätt skikt av asfaltmastix",
  "JSE.2711": "Brunnar till vattentätt skikt av asfaltmastix i ytterbjälklag",
  "JSE.2713":
    "Bräddavlopp till vattentätt skikt av asfaltmastix i ytterbjälklag",
  "JSE.272":
    "Kompletteringar i vattentätt skikt av asfaltmastix vid genomföringar",
  "JSE.4": "Vattentäta skikt av duk",
  "JSE.43": "Vattentäta skikt av duk i bjälklag inomhus",
  "JSE.431": "Vattentäta skikt av gummiduk i bjälklag inomhus",
  "JSE.4311": "Uppdragningar av gummiduk på vägg, sarg e d i bjälklag inomhus",
  "JSE.4312": "Anslutningar av gummiduk till fläns i bjälklag inomhus",
  "JSE.4318":
    "Diverse detaljutföranden av vattentätt skikt av gummiduk i bjälklag inomhus",
  "JSE.432": "Vattentäta skikt av plastduk i bjälklag inomhus",
  "JSE.4321": "Uppdragningar av plastduk på vägg, sarg e d i bjälklag inomhus",
  "JSE.4322": "Anslutningar av plastduk till fläns i bjälklag inomhus",
  "JSE.4328":
    "Diverse detaljutföranden av vattentätt skikt av plastduk i bjälklag inomhus",
  "JSE.44": "Vattentäta skikt av duk i ytterbjälklag",
  "JSE.441": "Vattentäta skikt av gummiduk i ytterbjälklag",
  "JSE.441-1": "Uppdragningar av TT typ 441- på vägg, sarg e d",
  "JSE.441-2": "Anslutningar av TT typ 441- till fläns",
  "JSE.441-3": "Neddragningar av TT typ 441- vid fri bjälklagskant",
  "JSE.441-6": "Utförande av TT typ 441- i ränndal",
  "JSE.441-8":
    "Diverse detaljutföranden av TT typ 441- av gummiduk i ytterbjälklag",
  "JSE.4411": "TT typ 4411",
  "JSE.4412": "TT typ 4412",
  "JSE.442": "Vattentäta skikt av plastduk i ytterbjälklag",
  "JSE.442-1": "Uppdragningar av TT typ 442- på vägg, sarg e d",
  "JSE.442-2": "Anslutningar av TT typ 442- till fläns",
  "JSE.442-3": "Neddragningar av TT typ 442- vid fri bjälklagskant",
  "JSE.442-6": "Utförande av TT typ 442- i ränndal",
  "JSE.442-8":
    "Diverse detaljutföranden av TT typ 442- av plastduk i ytterbjälklag",
  "JSE.4421": "TT typ 4421",
  "JSE.4422": "TT typ 4422",
  "JSE.45": "Vattentäta skikt av duk på yttertak",
  "JSE.451": "Vattentäta skikt av gummiduk på yttertak",
  "JSE.451-1": "Uppdragningar av TY typ 451- på vägg, sarg e d",
  "JSE.451-2": "Anslutningar av TY typ 451- till fläns",
  "JSE.451-3": "Anslutningar av TY typ 451- till fotplåt",
  "JSE.451-4": "Anslutningar av TY typ 451- till kantregel",
  "JSE.451-5": "Skarvningar av TY typ 451- vid nock",
  "JSE.451-6": "Utförande av TY typ 451- i ränndal",
  "JSE.451-7": "Utförande av TY typ 451- i fotränna",
  "JSE.451-8":
    "Diverse detaljutföranden av TY typ 451- av gummiduk på yttertak",
  "JSE.4511": "TY typ 4511",
  "JSE.4512": "TY typ 4512",
  "JSE.4513": "TY typ 4513",
  "JSE.4514": "TY typ 4514",
  "JSE.452": "Vattentäta skikt av plastduk på yttertak",
  "JSE.452-1": "Uppdragningar av TY typ 452- på vägg, sarg e d",
  "JSE.452-2": "Anslutningar av TY typ 452- till fläns",
  "JSE.452-3": "Anslutningar av TY typ 452- till fotplåt",
  "JSE.452-4": "Anslutningar av TY typ 452- till kantregel",
  "JSE.452-5": "Skarvningar av TY typ 452- vid nock",
  "JSE.452-6": "Utförande av TY typ 452- i ränndal",
  "JSE.452-7": "Utförande av TY typ 452- i fotränna",
  "JSE.452-8":
    "Diverse detaljutföranden av TY typ 452- av plastduk på yttertak",
  "JSE.4521": "TY typ 4521",
  "JSE.4522": "TY typ 4522",
  "JSE.4523": "TY typ 4523",
  "JSE.4524": "TY typ 4524",
  "JSE.453": "Vattentäta skikt av bitumenduk på yttertak",
  "JSE.4531": "TY typ 4531",
  "JSE.47": "Kompletteringar till vattentätt skikt av duk",
  "JSE.471": "Kompletteringar för avvattning av vattentätt skikt av duk",
  "JSE.4711": "Brunnar till vattentätt skikt av duk i ytterbjälklag",
  "JSE.4712": "Brunnar till vattentätt skikt av duk på yttertak",
  "JSE.4713": "Bräddavlopp till vattentätt skikt av duk i ytterbjälklag",
  "JSE.4714": "Bräddavlopp till vattentätt skikt av duk på yttertak",
  "JSE.472": "Kompletteringar till genomföringar m m i vattentätt skikt av duk",
  "JSE.4721": "Stosar till vattentätt skikt av duk i ytterbjälklag",
  "JSE.4722": "Stosar till vattentätt skikt av duk på yttertak",
  "JSE.4723": "Ventilationshuvar till vattentätt skikt av duk i ytterbjälklag",
  "JSE.4724": "Ventilationshuvar till vattentätt skikt av duk på yttertak",
  "JSE.478": "Diverse kompletteringar till vattentätt skikt av duk",
  JSF: "FUKTSKYDDSSKIKT AV TÄTSKIKTSMATTA, DUK, PLASTFILM M M I HUS",
  "JSF.1": "Fuktskyddsskikt av tätskiktsmatta e d i hus",
  "JSF.11": "Fuktskyddsskikt av tätskiktsmatta e d för grundkonstruktion",
  "JSF.12": "Fuktskyddsskikt av tätskiktsmatta e d för bjälklag",
  "JSF.121": "Fuktskyddsskikt av tätskiktsmatta e d under bjälklag",
  "JSF.122": "Fuktskyddsskikt av tätskiktsmatta e d på bjälklag",
  "JSF.13": "Fuktskyddsskikt av tätskiktsmatta e d för innervägg",
  "JSF.131": "Fuktskyddsskikt av tätskiktsmatta e d under innervägg",
  "JSF.14": "Fuktskyddsskikt av tätskiktsmatta e d för yttervägg",
  "JSF.141": "Fuktskyddsskikt av tätskiktsmatta e d under yttervägg ovan mark",
  "JSF.142": "Fuktskyddsskikt av tätskiktsmatta e d mot yttervägg under mark",
  "JSF.15": "Fuktskyddsskikt av tätskiktsmatta e d för yttertak",
  "JSF.4": "Fuktskyddsskikt av duk, folie e d",
  "JSF.5": "Fuktskyddsskikt av plastfilm (ej kyl- och frysrum)",
  "JSF.51": "Fuktskyddsskikt av plastfilm för grundkonstruktion",
  "JSF.511": "Fuktskyddsskikt av plastfilm i krypgrund",
  "JSF.512": "Fuktskyddsskikt av plastfilm på hel grundplatta",
  "JSF.52": "Fuktskyddsskikt av plastfilm för bjälklag e d",
  "JSF.54": "Fuktskyddsskikt av plastfilm för yttervägg",
  "JSF.55": "Fuktskyddsskikt av plastfilm för yttertak",
  "JSF.6": "Fuktskyddsskikt i platsbyggt kyl- eller frysrum",
  "JSF.61": "Fuktskyddsskikt av plastfilm i platsbyggt kyl- eller frysrum",
  "JSF.611": "Fuktskyddsskikt av plastfilm i golv i platsbyggt kylrum",
  "JSF.612": "Fuktskyddsskikt av plastfilm i golv i platsbyggt frysrum",
  "JSF.7": "Fuktskyddsskikt av luftspaltbildande plastmatta",
  "JSF.71":
    "Fuktskyddsskikt av luftspaltbildande plastmatta på hel grundplatta",
  "JSF.72": "Fuktskyddsskikt av luftspaltbildande plastmatta på bjälklag e d",
  "JSF.74":
    "Fuktskyddsskikt av luftspaltbildande plastmatta mot yttervägg under mark",
  JSG: "VINDSKYDDSSKIKT AV PAPP, PLASTFILM M M I HUS",
  "JSG.1": "Vindskyddsskikt av papp",
  "JSG.12": "Vindskyddsskikt av papp för bjälklag e d",
  "JSG.14": "Vindskyddsskikt av papp för yttervägg",
  "JSG.4": "Vindskyddsskikt av duk",
  "JSG.42": "Vindskyddsskikt av duk för bjälklag e d",
  "JSG.44": "Vindskyddsskikt av duk för yttervägg",
  "JSG.5": "Vindskyddsskikt av plastfilm",
  "JSG.52": "Vindskyddsskikt av plastfilm för bjälklag e d",
  "JSG.54": "Vindskyddsskikt av plastfilm för yttervägg",
  "JSG.8": "Vindskyddsskikt av diverse material",
  JT: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER SAMT KOMPLETTERINGAR AV PLAN PLÅT FÖR HUS",
  JTB: "TAKTÄCKNINGAR, VÄGGBEKLÄDNADER, KOMPLETTERINGAR M M AV PLAN METALLISERAD STÅLPLÅT FÖR HUS",
  "JTB.1": "Taktäckningar och väggbeklädnader av plan plåt",
  "JTB.13": "Taktäckningar av plan plåt med lutning minst 1:10 (5,7°)",
  "JTB.132": "Dubbelfalsade taktäckningar av plan plåt",
  "JTB.1321": "Dubbelfalsade skivtäckningar av plan plåt",
  "JTB.1322": "Dubbelfalsade bandtäckningar av plan plåt",
  "JTB.14": "Taktäckningar av plan plåt med lutning minst 1:3 (18,4°)",
  "JTB.143": "Enkelfalsade taktäckningar av plan plåt",
  "JTB.1431": "Enkelfalsade skivtäckningar av plan plåt",
  "JTB.145": "Taktäckningar av plan plåt utförd som fjälltäckning",
  "JTB.15": "Väggbeklädnader av plan plåt",
  "JTB.152": "Dubbelfalsade väggbeklädnader av plan plåt",
  "JTB.1521": "Dubbelfalsade skivbeklädnader av plan plåt",
  "JTB.1522": "Dubbelfalsade bandbeklädnader av plan plåt",
  "JTB.153": "Enkelfalsade väggbeklädnader av plan plåt",
  "JTB.1531": "Enkelfalsade skivbeklädnader av plan plåt",
  "JTB.155": "Väggbeklädnader av plan plåt utförda som fjälltäckning",
  "JTB.2": "Kompletteringar av plåt vid taktäckning av plan plåt",
  "JTB.21":
    "Fotrännor av plåt vid taktäckning av plan plåt och lutning minst 1:1,7 (30°)",
  "JTB.211": "Fotrännor av plåt vid falsat skivtäckt tak",
  "JTB.2111": "Fotrännor av plåt utan stomme vid falsat skivtäckt tak",
  "JTB.2112":
    "Fotrännor av plåt utan stomme men med ytterbeklädnad vid falsat skivtäckt tak",
  "JTB.2113": "Fotrännor av plåt med stomme vid falsat skivtäckt tak",
  "JTB.212": "Fotrännor av plåt vid falsat bandtäckt tak",
  "JTB.2121": "Fotrännor av plåt utan stomme vid falsat bandtäckt tak",
  "JTB.2122":
    "Fotrännor av plåt utan stomme men med ytterbeklädnad vid falsat bandtäckt tak",
  "JTB.2123": "Fotrännor av plåt med stomme vid falsat bandtäckt tak",
  "JTB.214": "Fotrännor av plåt i bandformat vid taktäckning av plan plåt",
  "JTB.22": "Vinkelrännor av plåt vid taktäckning av plan plåt",
  "JTB.221": "Vinkelrännor av plåt vid falsat skivtäckt tak",
  "JTB.222": "Vinkelrännor av plåt vid falsat bandtäckt tak",
  "JTB.2221": "Vinkelrännor av plåt i skivformat vid falsat bandtäckt tak",
  "JTB.2222": "Vinkelrännor av plåt i bandformat vid falsat bandtäckt tak",
  "JTB.23": "Ränndalar av plåt vid taktäckning av plan plåt",
  "JTB.231": "Ränndalar av plåt med uppbyggt fall vid taktäckning av plan plåt",
  "JTB.2311": "Ränndalar av plåt med uppbyggt fall vid falsat skivtäckt tak",
  "JTB.2312": "Ränndalar av plåt med uppbyggt fall vid falsat bandtäckt tak",
  "JTB.233": "Försänkta ränndalar av plåt vid taktäckning av plan plåt",
  "JTB.2331": "Försänkta ränndalar av plåt vid falsat skivtäckt tak",
  "JTB.2332": "Försänkta ränndalar av plåt vid falsat bandtäckt tak",
  "JTB.24": "Gesimsrännor av plåt vid taktäckning av plan plåt",
  "JTB.241": "Gesimsrännor av plåt vid falsat skivtäckt tak",
  "JTB.242": "Gesimsrännor av plåt vid falsat bandtäckt tak",
  "JTB.244": "Gesimsrännor av plåt i bandformat vid taktäckning av plan plåt",
  "JTB.25": "Ståndskivor och hängskivor vid taktäckning av plan plåt",
  "JTB.251": "Ståndskivor vid taktäckning av plan plåt",
  "JTB.2511": "Ståndskivor vid falsat skivtäckt tak",
  "JTB.2512": "Ståndskivor vid falsat bandtäckt tak",
  "JTB.252": "Hängskivor vid taktäckning av plan plåt",
  "JTB.2521": "Hängskivor vid falsat skivtäckt tak",
  "JTB.2522": "Hängskivor vid falsat bandtäckt tak",
  "JTB.26": "Stosar till rör, takluckor m m vid taktäckning av plan plåt",
  "JTB.261": "Stosar till rör e d vid taktäckning av plan plåt",
  "JTB.2611": "Stosar till helbeslagning av rör vid taktäckning av plan plåt",
  "JTB.2612": "Ankantade stosar till rör e d vid taktäckning av plan plåt",
  "JTB.262": "Beslagning av takluckor vid taktäckning av plan plåt",
  "JTB.2621": "Beslagning av takluckor vid falsat skivtäckt tak",
  "JTB.2622": "Beslagning av takluckor vid falsat bandtäckt tak",
  "JTB.263": "Takfönster vid taktäckning av plan plåt",
  "JTB.2631": "Takfönster vid falsat skivtäckt tak",
  "JTB.2632": "Takfönster vid falsat bandtäckt tak",
  "JTB.264":
    "Beslagning av förtillverkade takfönster vid taktäckning av plan plåt",
  "JTB.2641":
    "Beslagning av förtillverkade takfönster vid falsat skivtäckt tak",
  "JTB.2642":
    "Beslagning av förtillverkade takfönster vid falsat bandtäckt tak",
  "JTB.27":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av plan plåt",
  "JTB.271": "Helbeslagning av skorstenar vid taktäckning av plan plåt",
  "JTB.2711": "Helbeslagning av skorstenar vid falsat skivtäckt tak",
  "JTB.2712": "Helbeslagning av skorstenar vid falsat bandtäckt tak",
  "JTB.272":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid taktäckning av plan plåt",
  "JTB.2721":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid falsat skivtäckt tak",
  "JTB.2722":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid falsat bandtäckt tak",
  "JTB.273": "Helbeslagning av takkupor o d vid taktäckning av plan plåt",
  "JTB.274":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av plan plåt",
  "JTB.2741":
    "Helbeslagning av murkrön, brandväggar o d vid falsat skivtäckt tak",
  "JTB.2742":
    "Helbeslagning av murkrön, brandväggar o d vid falsat bandtäckt tak",
  "JTB.275":
    "Beslagning av genomföringar för brandgasventilatorer o d vid taktäckning av plan plåt",
  "JTB.2751":
    "Beslagning av genomföringar för brandgasventilatorer o d vid falsat skivtäckt tak",
  "JTB.2752":
    "Beslagning av genomföringar för brandgasventilatorer o d vid falsat bandtäckt tak",
  "JTB.278":
    "Beslagning av diverse takuppbyggnader o d vid taktäckning av plan plåt",
  "JTB.2781":
    "Helbeslagning av diverse takuppbyggnader o d vid falsat skivtäckt tak",
  "JTB.2782":
    "Helbeslagning av diverse takuppbyggnader o d vid falsat bandtäckt tak",
  "JTB.3":
    "Kompletteringar av plan plåt vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.31": "Fotplåtar vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.311": "Fotplåtar vid taktäckning av tätskiktsmatta",
  "JTB.3111":
    "Fotplåtar med anslutning till hängränna vid taktäckning av tätskiktsmatta",
  "JTB.3112":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av tätskiktsmatta",
  "JTB.312": "Fotplåtar vid taktäckning av takduk",
  "JTB.3121":
    "Fotplåtar med anslutning till hängränna vid taktäckning av takduk",
  "JTB.3122":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av takduk",
  "JTB.34":
    "Gesimsrännor av plåt vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.341": "Gesimsrännor av plåt vid taktäckning av tätskiktsmatta",
  "JTB.342": "Gesimsrännor av plåt vid taktäckning av takduk",
  "JTB.35":
    "Ståndskivor och hängskivor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.351":
    "Ståndskivor utan vinge vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3511": "Ståndskivor utan vinge vid taktäckning av tätskiktsmatta",
  "JTB.3512": "Ståndskivor utan vinge vid taktäckning av takduk",
  "JTB.352": "Hängskivor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3521": "Hängskivor vid taktäckning av tätskiktsmatta",
  "JTB.3522": "Hängskivor vid taktäckning av takduk",
  "JTB.36":
    "Stosar till rör, takluckor m m vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.361": "Stosar till rör e d vid taktäckning av tätskiktsmatta",
  "JTB.3611":
    "Stosar till helbeslagning av rör vid taktäckning av tätskiktsmatta",
  "JTB.3612": "Ankantade stosar till rör e d vid taktäckning av tätskiktsmatta",
  "JTB.362": "Stosar till rör e d vid taktäckning av takduk",
  "JTB.3621": "Stosar till helbeslagning av rör vid taktäckning av takduk",
  "JTB.3622": "Ankantade stosar till rör e d vid taktäckning av takduk",
  "JTB.363":
    "Beslagning av takluckor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3631": "Beslagning av takluckor vid taktäckning av tätskiktsmatta",
  "JTB.3632": "Beslagning av takluckor vid taktäckning av takduk",
  "JTB.364": "Takfönster vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3641": "Takfönster vid taktäckning av tätskiktsmatta",
  "JTB.3642": "Takfönster vid taktäckning av takduk",
  "JTB.365":
    "Beslagning av förtillverkade takfönster vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3651":
    "Beslagning av förtillverkade takfönster vid taktäckning av tätskiktsmatta",
  "JTB.3652":
    "Beslagning av förtillverkade takfönster vid taktäckning av takduk",
  "JTB.37":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.371":
    "Helbeslagning av skorstenar vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3711": "Helbeslagning av skorstenar vid taktäckning av tätskiktsmatta",
  "JTB.3712": "Helbeslagning av skorstenar vid taktäckning av takduk",
  "JTB.372":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3721":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av tätskiktsmatta",
  "JTB.3722":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av takduk",
  "JTB.373":
    "Helbeslagning av takkupor o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3731": "Helbeslagning av takkupor o d vid taktäckning av tätskiktsmatta",
  "JTB.3732": "Helbeslagning av takkupor o d vid taktäckning av takduk",
  "JTB.374":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3741":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av tätskiktsmatta",
  "JTB.3742":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av takduk",
  "JTB.375":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3751":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av tätskiktsmatta",
  "JTB.3752":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av takduk",
  "JTB.376":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3761":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av tätskiktsmatta",
  "JTB.3762":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av takduk",
  "JTB.378":
    "Beslagning av diverse takuppbyggnader o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.3781":
    "Helbeslagning av diverse takuppbyggnader o d vid taktäckning av tätskiktsmatta",
  "JTB.3782":
    "Helbeslagning av diverse takuppbyggnader o d vid taktäckning av takduk",
  "JTB.4":
    "Kompletteringar av plan plåt vid taktäckning av överläggsplattor e d",
  "JTB.41": "Fotplåtar och fotrännor vid taktäckning av överläggsplattor e d",
  "JTB.411":
    "Fotplåtar med anslutning till hängränna vid taktäckning av överläggsplattor e d",
  "JTB.4111":
    "Fotplåtar med anslutning till hängränna vid taktäckning av skiffer",
  "JTB.4112":
    "Fotplåtar med anslutning till hängränna vid taktäckning av betongpannor",
  "JTB.4113":
    "Fotplåtar med anslutning till hängränna vid taktäckning av tegelpannor",
  "JTB.4114":
    "Fotplåtar med anslutning till hängränna vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4115":
    "Fotplåtar med anslutning till hängränna vid taktäckning av profilerade överläggsplattor av fiberbetong",
  "JTB.4116":
    "Fotplåtar med anslutning till hängränna vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.412":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av överläggsplattor e d",
  "JTB.4121":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av skiffer",
  "JTB.4122":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av betongpannor",
  "JTB.4123":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av tegelpannor",
  "JTB.4124":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4125":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av profilerade överläggsplattor av fiberbetong",
  "JTB.4126":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.413":
    "Fotrännor av plåt i skivformat vid taktäckning av överläggsplattor e d",
  "JTB.4131":
    "Fotrännor av plåt i skivformat utan stomme vid taktäckning av överläggsplattor e d",
  "JTB.4132":
    "Fotrännor av plåt i skivformat utan stomme men med ytterbeklädnad vid taktäckning av överläggsplattor e d",
  "JTB.4133":
    "Fotrännor av plåt i skivformat med stomme vid taktäckning av överläggsplattor e d",
  "JTB.4134":
    "Fotrännor av plåt i bandformat vid taktäckning av överläggsplattor e d",
  "JTB.42": "Vinkelrännor av plåt vid taktäckning av överläggsplattor e d",
  "JTB.421":
    "Vinkelrännor av plåt i skivformat vid taktäckning av överläggsplattor e d",
  "JTB.4211": "Vinkelrännor av plåt i skivformat vid taktäckning av skiffer",
  "JTB.4212":
    "Vinkelrännor av plåt i skivformat vid taktäckning av betongpannor",
  "JTB.4213":
    "Vinkelrännor av plåt i skivformat vid taktäckning av tegelpannor",
  "JTB.4214":
    "Vinkelrännor av plåt i skivformat vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4215":
    "Vinkelrännor av plåt i skivformat vid taktäckning överläggsplattor av fiberbetong",
  "JTB.4216":
    "Vinkelrännor av plåt i skivformat vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.422":
    "Vinkelrännor av plåt i bandformat vid taktäckning av överläggsplattor e d",
  "JTB.4221": "Vinkelrännor av plåt i bandformat vid taktäckning av skiffer",
  "JTB.4222":
    "Vinkelrännor av plåt i bandformat vid taktäckning av betongpannor",
  "JTB.4223":
    "Vinkelrännor av plåt i bandformat vid taktäckning av tegelpannor",
  "JTB.4224":
    "Vinkelrännor av plåt i bandformat vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4225":
    "Vinkelrännor av plåt i bandformat vid taktäckning överläggsplattor av fiberbetong",
  "JTB.4226":
    "Vinkelrännor av plåt i bandformat vid taktäckning vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.43": "Ränndalar av plåt vid taktäckning av överläggsplattor e d",
  "JTB.431":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av överläggsplattor e d",
  "JTB.4311": "Ränndalar av plåt med uppbyggt fall vid taktäckning av skiffer",
  "JTB.4312":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av betongpannor",
  "JTB.4313":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av tegelpannor",
  "JTB.4314":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4315":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning överläggsplattor av fiberbetong",
  "JTB.4316":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning överläggsplattor av cementbundet material",
  "JTB.433":
    "Försänkta ränndalar av plåt vid taktäckning av överläggsplattor e d",
  "JTB.4331": "Försänkta ränndalar av plåt vid taktäckning av skiffer",
  "JTB.4332": "Försänkta ränndalar av plåt vid taktäckning av betongpannor",
  "JTB.4333": "Försänkta ränndalar av plåt vid taktäckning av tegelpannor",
  "JTB.4334":
    "Försänkta ränndalar av plåt vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4335":
    "Försänkta ränndalar av plåt vid taktäckning överläggsplattor av fiberbetong",
  "JTB.4336":
    "Försänkta ränndalar av plåt vid taktäckning överläggsplattor av cementbundet material",
  "JTB.44": "Gesimsrännor av plåt vid taktäckning av överläggsplattor e d",
  "JTB.441": "Gesimsrännor av plåt vid taktäckning av skiffer",
  "JTB.442": "Gesimsrännor av plåt vid taktäckning av betongpannor",
  "JTB.443": "Gesimsrännor av plåt vid taktäckning av tegelpannor",
  "JTB.444":
    "Gesimsrännor av plåt vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.445":
    "Gesimsrännor av plåt vid taktäckning överläggsskivor av fiberbetong",
  "JTB.446":
    "Gesimsrännor av plåt vid taktäckning överläggsplattor av cementbundet material",
  "JTB.45":
    "Ståndskivor och hängskivor samt nockbeslag vid taktäckning av överläggsplattor e d",
  "JTB.451": "Ståndskivor med vinge vid taktäckning av överläggsplattor e d",
  "JTB.4511": "Ståndskivor med vinge vid taktäckning av skiffer",
  "JTB.4512": "Ståndskivor med vinge vid taktäckning av betongpannor",
  "JTB.4513": "Ståndskivor med vinge vid taktäckning av tegelpannor",
  "JTB.4514":
    "Ståndskivor med vinge vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4515":
    "Ståndskivor med vinge vid taktäckning överläggsplattor av fiberbetong",
  "JTB.4516":
    "Ståndskivor med vinge vid taktäckning överläggsplattor av cementbundet material",
  "JTB.452":
    "Hängskivor och gavelskivor vid taktäckning av överläggsplattor e d",
  "JTB.4521": "Hängskivor vid taktäckning av överläggsplattor e d",
  "JTB.4522":
    "Hängskivor och gavelskivor med vinge vid taktäckning av överläggsplattor e d",
  "JTB.453": "Nockbeslag vid taktäckning av överläggsplattor e d",
  "JTB.4531": "Nockbeslag vid taktäckning av skiffer",
  "JTB.4532": "Nockbeslag vid taktäckning av betongpannor",
  "JTB.4533": "Nockbeslag vid taktäckning av tegelpannor",
  "JTB.4534":
    "Nockbeslag vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4535": "Nockbeslag vid taktäckning överläggplattor av fiberbetong",
  "JTB.4536":
    "Nockbeslag vid taktäckning överläggsplattor av cementbundet material",
  "JTB.46":
    "Stosar till rör, takluckor m m vid taktäckning av överläggsplattor e d",
  "JTB.461": "Stosar till rör e d vid taktäckning av överläggsplattor e d",
  "JTB.4611": "Stosar till rör e d vid taktäckning av skiffer",
  "JTB.4612": "Stosar till rör e d vid taktäckning av betongpannor",
  "JTB.4613": "Stosar till rör e d vid taktäckning av tegelpannor",
  "JTB.4614":
    "Stosar till rör e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4615":
    "Stosar till rör e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTB.4616":
    "Stosar till rör e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.462": "Beslagning av takluckor vid taktäckning av överläggsplattor e d",
  "JTB.4621": "Beslagning av takluckor vid taktäckning av skiffer",
  "JTB.4622": "Beslagning av takluckor vid taktäckning av betongpannor",
  "JTB.4623": "Beslagning av takluckor vid taktäckning av tegelpannor",
  "JTB.4624":
    "Beslagning av takluckor vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4625":
    "Beslagning av takluckor vid taktäckning av överläggsplattor av fiberbetong",
  "JTB.4626":
    "Beslagning av takluckor vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.463": "Takfönster vid taktäckning av överläggsplattor e d",
  "JTB.4631": "Takfönster vid taktäckning av skiffer",
  "JTB.4632": "Takfönster vid taktäckning av betongpannor",
  "JTB.4633": "Takfönster vid taktäckning av tegelpannor",
  "JTB.4634":
    "Takfönster vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4635": "Takfönster vid taktäckning av överläggsplattor av fiberbetong",
  "JTB.4636":
    "Takfönster vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.464":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor e d",
  "JTB.4641":
    "Beslagning av förtillverkade takfönster vid taktäckning av skiffer",
  "JTB.4642":
    "Beslagning av förtillverkade takfönster vid taktäckning av betongpannor",
  "JTB.4643":
    "Beslagning av förtillverkade takfönster vid taktäckning av tegelpannor",
  "JTB.4644":
    "Beslagning av förtillverkade takfönster vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4645":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor av fiberbetong",
  "JTB.4646":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.47":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av överläggsplattor e d",
  "JTB.471":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor e d",
  "JTB.4711": "Helbeslagning av skorstenar vid taktäckning av skiffer",
  "JTB.4712": "Helbeslagning av skorstenar vid taktäckning av betongpannor",
  "JTB.4713": "Helbeslagning av skorstenar vid taktäckning av tegelpannor",
  "JTB.4714":
    "Helbeslagning av skorstenar vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4715":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor av fiberbetong",
  "JTB.4716":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.472":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor e d",
  "JTB.4721":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av skiffer",
  "JTB.4722":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av betongpannor",
  "JTB.4723":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av tegelpannor",
  "JTB.4724":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4725":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor av fiberbetong",
  "JTB.4726":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.473":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor e d",
  "JTB.4731": "Helbeslagning av takkupor e d vid taktäckning av skiffer",
  "JTB.4732": "Helbeslagning av takkupor e d vid taktäckning av betongpannor",
  "JTB.4733": "Helbeslagning av takkupor e d vid taktäckning av tegelpannor",
  "JTB.4734":
    "Helbeslagning av takkupor e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4735":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTB.4736":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.474":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor e d",
  "JTB.4741":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av skiffer",
  "JTB.4742":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av betongpannor",
  "JTB.4743":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av tegelpannor",
  "JTB.4744":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4745":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTB.4746":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.475":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor e d",
  "JTB.4751":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av skiffer",
  "JTB.4752":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av betongpannor",
  "JTB.4753":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av tegelpannor",
  "JTB.4754":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4755":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTB.4756":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.476":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor e d",
  "JTB.4761":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av skiffer",
  "JTB.4762":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av betongpannor",
  "JTB.4763":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av tegelpannor",
  "JTB.4764":
    "Täcklister, beslag över rörelsefogar vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4765":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTB.4766":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.478":
    "Beslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor e d",
  "JTB.4781":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av skiffer",
  "JTB.4782":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av betongpannor",
  "JTB.4783":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av tegelpannor",
  "JTB.4784":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTB.4785":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor av fiberbetong",
  "JTB.4786":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor av cementbundet material",
  "JTB.5": "Kompletteringar av plan plåt till yttervägg, mur, bjälklag e d",
  "JTB.52": "Fönsterbleck, droppbleck, listbeslag e d",
  "JTB.521": "Fönsterbleck",
  "JTB.522": "Droppbleck i väggöppningar",
  "JTB.5221": "Droppbleck vid fönster och dörröppningar",
  "JTB.5222": "Droppbleck vid ventilationsgaller e d",
  "JTB.523": "Droppbleck på balkong e d",
  "JTB.524": "Tröskelbeslag",
  "JTB.525": "Listbeslag, beslag över portomfattning e d",
  "JTB.526": "Skyddsbeslag på vägg bakom vattenkupa, bräddavlopp e d",
  "JTB.527": "Sockelbeslag",
  "JTB.53": "Beslagning av muröversida utan anslutning till taktäckning",
  "JTB.54": "Beslagning av skärmtak",
  "JTB.55": "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av plåt",
  "JTB.551":
    "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av plan plåt",
  "JTB.554":
    "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av profilerade överläggsplattor e d av metall",
  "JTB.58":
    "Diverse kompletteringar av plan plåt till yttervägg, mur, bjälklag e d",
  "JTB.581": "Diverse kompletteringar av plan plåt till yttervägg, mur e d",
  "JTB.5811":
    "Skyddsbeslag av plan plåt till yttervägg, mur e d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.5812":
    "Beslag av plan plåt med fläns till sarg e d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTB.582": "Diverse kompletteringar av plan plåt till bjälklag e d inomhus",
  "JTB.5821":
    "Beslag av plan plåt med fläns till tätskiktsmatta, takduk e d i bjälklag inomhus",
  "JTB.583": "Diverse kompletteringar av plan plåt till ytterbjälklag",
  "JTB.5831":
    "Skyddsbeslag av plan plåt vid tätskiktsmatta, takduk e d i ytterbjälklag",
  "JTB.5832":
    "Beslag av plan plåt med fläns till sarg e d vid tätskiktsmatta, takduk e d i ytterbjälklag",
  "JTB.7":
    "Kompletteringarav plan plåt vid taktäckning och väggbeklädnad av diverse material",
  "JTB.8": "Kompletteringar för takavvattning",
  "JTB.81": "Hängrännor",
  "JTB.811": "Halvrunda hängrännor",
  "JTB.812": "Rektangulära hängrännor",
  "JTB.813": "Skyddsbeslag vid hängrännor",
  "JTB.8131": "Längsgående skyddsbeslag vid hängrännor",
  "JTB.8132": "Gavelbeslag vid hängrännor",
  "JTB.8133": "Överspolningsskydd vid hängrännor",
  "JTB.82": "Stuprör",
  "JTB.821": "Utvändiga stuprör",
  "JTB.8211": "Cirkulära stuprör",
  "JTB.8212": "Rektangulära stuprör",
  "JTB.822": "Stuprörsanslutning till dagvattenledning",
  "JTB.823": "Stuprörsutkastare",
  "JTB.8231": "Slitplåtar vid stuprörsutkastare",
  "JTB.83": "Rännutlopp",
  "JTB.831": "Rännutlopp till utvändiga stuprör",
  "JTB.832": "Takbrunnar till invändiga stuprör",
  "JTB.84": "Bräddavlopp",
  JTC: "TAKTÄCKNINGAR, VÄGGBEKLÄDNADER, KOMPLETTERINGAR M M AV PLAN PLÅT AV ROSTFRITT STÅL FÖR HUS",
  "JTC.1": "Taktäckningar och väggbeklädnader av plan plåt",
  "JTC.11": "Taktäckningar av plan plåt med lutning mindre än 1:10 (5,7°)",
  "JTC.111": "Svetsade taktäckningar av plan plåt",
  "JTC.1112": "Svetsade bandtäckningar av plan plåt",
  "JTC.13": "Taktäckningar av plan plåt med lutning minst 1:10 (5,7°)",
  "JTC.131": "Svetsade taktäckningar av plan plåt",
  "JTC.1312": "Svetsade bandtäckningar av plan plåt",
  "JTC.14": "Taktäckningar av plan plåt med lutning minst 1:3 (18,4°)",
  "JTC.141": "Svetsade taktäckningar av plan plåt",
  "JTC.1412": "Svetsade bandtäckningar av plan plåt",
  "JTC.15": "Väggbeklädnader av plan plåt",
  "JTC.151": "Svetsade väggbeklädnader av plan plåt",
  "JTC.1512": "Svetsade bandbeklädnader av plan plåt",
  "JTC.152": "Dubbelfalsade väggbeklädnader av plan plåt",
  "JTC.1522": "Dubbelfalsade bandbeklädnader av plan plåt",
  "JTC.153": "Enkelfalsade väggbeklädnader av plan plåt",
  "JTC.1531": "Enkelfalsade skivbeklädnader av plan plåt",
  "JTC.155": "Väggbeklädnader av plan plåt utförda som fjälltäckning",
  "JTC.2": "Kompletteringar av plåt vid taktäckning av plan plåt",
  "JTC.21":
    "Fotrännor av plåt vid taktäckning av plan plåt och lutning minst 1:1,7 (30°)",
  "JTC.212": "Fotrännor av plåt vid falsat bandtäckt tak",
  "JTC.213": "Fotrännor av plåt vid svetsat bandtäckt tak",
  "JTC.2132": "Fotrännor av plåt med stomme vid svetsat bandtäckt tak",
  "JTC.214": "Fotrännor av plåt i bandformat vid taktäckning av plan plåt",
  "JTC.22": "Vinkelrännor av plåt vid taktäckning av plan plåt",
  "JTC.223": "Vinkelrännor av plåt vid svetsat bandtäckt tak",
  "JTC.23": "Ränndalar av plåt vid taktäckning av plan plåt",
  "JTC.231": "Ränndalar av plåt med uppbyggt fall vid taktäckning av plan plåt",
  "JTC.2313": "Ränndalar av plåt med uppbyggt fall vid svetsat bandtäckt tak",
  "JTC.232":
    "Ränndalar av plåt utan uppbyggt fall vid taktäckning av plan plåt",
  "JTC.2323": "Ränndalar av plåt utan uppbyggt fall vid svetsat bandtäckt tak",
  "JTC.233": "Försänkta ränndalar av plåt vid taktäckning av plan plåt",
  "JTC.2333": "Försänkta ränndalar av plåt vid svetsat bandtäckt tak",
  "JTC.24": "Gesimsrännor av plåt vid taktäckning av plan plåt",
  "JTC.243": "Gesimsrännor av plåt vid svetsat bandtäckt tak",
  "JTC.244": "Gesimsrännor av plåt i bandformat vid taktäckning av plan plåt",
  "JTC.25": "Ståndskivor och hängskivor vid taktäckning av plan plåt",
  "JTC.251": "Ståndskivor vid taktäckning av plan plåt",
  "JTC.2512": "Ståndskivor vid falsat bandtäckt tak",
  "JTC.2513": "Ståndskivor vid svetsat bandtäckt tak",
  "JTC.252": "Hängskivor vid taktäckning av plan plåt",
  "JTC.2523": "Hängskivor vid svetsat bandtäckt tak",
  "JTC.26": "Stosar till rör, takluckor m m vid taktäckning av plan plåt",
  "JTC.261": "Stosar till rör e d vid taktäckning av plan plåt",
  "JTC.2612": "Ankantade stosar till rör e d vid taktäckning av plan plåt",
  "JTC.262": "Beslagning av takluckor vid taktäckning av plan plåt",
  "JTC.2623": "Beslagning av takluckor vid svetsat bandtäckt tak",
  "JTC.263": "Takfönster vid taktäckning av plan plåt",
  "JTC.2633": "Takfönster vid svetsat bandtäckt tak",
  "JTC.264":
    "Beslagning av förtillverkade takfönster vid taktäckning av plan plåt",
  "JTC.2643":
    "Beslagning av förtillverkade takfönster vid svetsat bandtäckt tak",
  "JTC.27":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av plan plåt",
  "JTC.271": "Helbeslagning av skorstenar vid taktäckning av plan plåt",
  "JTC.2713": "Helbeslagning av skorstenar vid svetsat bandtäckt tak",
  "JTC.272":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid taktäckning av plan plåt",
  "JTC.2723":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid svetsat bandtäckt tak",
  "JTC.273": "Helbeslagning av takkupor o d vid taktäckning av plan plåt",
  "JTC.274":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av plan plåt",
  "JTC.2743":
    "Helbeslagning av murkrön, brandväggar o d vid svetsat bandtäckt tak",
  "JTC.275":
    "Beslagning av genomföringar för brandgasventilatorer o d vid taktäckning av plan plåt",
  "JTC.2753":
    "Beslagning av genomföringar för brandgasventilatorer o d vid svetsat bandtäckt tak",
  "JTC.278":
    "Beslagning av diverse takuppbyggnader o d vid taktäckning av plan plåt",
  "JTC.2783":
    "Helbeslagning av diverse takuppbyggnader o d vid svetsat bandtäckt tak",
  "JTC.3":
    "Kompletteringar av plan plåt vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.31": "Fotplåtar vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.311": "Fotplåtar vid taktäckning av tätskiktsmatta",
  "JTC.3111":
    "Fotplåtar med anslutning till hängränna vid taktäckning av tätskiktsmatta",
  "JTC.3112":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av tätskiktsmatta",
  "JTC.312": "Fotplåtar vid taktäckning av takduk",
  "JTC.3121":
    "Fotplåtar med anslutning till hängränna vid taktäckning av takduk",
  "JTC.3122":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av takduk",
  "JTC.34":
    "Gesimsrännor av plåt vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.341": "Gesimsrännor av plåt vid taktäckning av tätskiktsmatta",
  "JTC.342": "Gesimsrännor av plåt vid taktäckning av takduk",
  "JTC.35":
    "Ståndskivor och hängskivor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.351":
    "Ståndskivor utan vinge vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3511": "Ståndskivor utan vinge vid taktäckning av tätskiktsmatta",
  "JTC.3512": "Ståndskivor utan vinge vid taktäckning av takduk",
  "JTC.352": "Hängskivor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3521": "Hängskivor vid taktäckning av tätskiktsmatta",
  "JTC.3522": "Hängskivor vid taktäckning av takduk",
  "JTC.36":
    "Stosar till rör, takluckor m m vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.361": "Stosar till rör e d vid taktäckning av tätskiktsmatta",
  "JTC.3611":
    "Stosar till helbeslagning av rör vid taktäckning av tätskiktsmatta",
  "JTC.3612": "Ankantade stosar till rör e d vid taktäckning av tätskiktsmatta",
  "JTC.362": "Stosar till rör e d vid taktäckning av takduk",
  "JTC.3621": "Stosar till helbeslagning av rör vid taktäckning av takduk",
  "JTC.3622": "Ankantade stosar till rör e d vid taktäckning av takduk",
  "JTC.363":
    "Beslagning av takluckor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3631": "Beslagning av takluckor vid taktäckning av tätskiktsmatta",
  "JTC.3632": "Beslagning av takluckor vid taktäckning av takduk",
  "JTC.364": "Takfönster vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3641": "Takfönster vid taktäckning av tätskiktsmatta",
  "JTC.3642": "Takfönster vid taktäckning av takduk",
  "JTC.365":
    "Beslagning av förtillverkade takfönster vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3651":
    "Beslagning av förtillverkade takfönster vid taktäckning av tätskiktsmatta",
  "JTC.3652":
    "Beslagning av förtillverkade takfönster vid taktäckning av takduk",
  "JTC.37":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.371":
    "Helbeslagning av skorstenar vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3711": "Helbeslagning av skorstenar vid taktäckning av tätskiktsmatta",
  "JTC.3712": "Helbeslagning av skorstenar vid taktäckning av takduk",
  "JTC.372":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3721":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av tätskiktsmatta",
  "JTC.3722":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av takduk",
  "JTC.373":
    "Helbeslagning av takkupor o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3731": "Helbeslagning av takkupor o d vid taktäckning av tätskiktsmatta",
  "JTC.3732": "Helbeslagning av takkupor o d vid taktäckning av takduk",
  "JTC.374":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3741":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av tätskiktsmatta",
  "JTC.3742":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av takduk",
  "JTC.375":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3751":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av tätskiktsmatta",
  "JTC.3752":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av takduk",
  "JTC.376":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3761":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av tätskiktsmatta",
  "JTC.3762":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av takduk",
  "JTC.378":
    "Beslagning av diverse takuppbyggnader o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.3781":
    "Helbeslagning av diverse takuppbyggnader o d vid taktäckning av tätskiktsmatta",
  "JTC.3782":
    "Helbeslagning av diverse takuppbyggnader o d vid taktäckning av takduk",
  "JTC.4":
    "Kompletteringar av plan plåt vid taktäckning av överläggsplattor e d",
  "JTC.41": "Fotplåtar och fotrännor vid taktäckning av överläggsplattor e d",
  "JTC.411":
    "Fotplåtar med anslutning till hängränna vid taktäckning av överläggsplattor e d",
  "JTC.4111":
    "Fotplåtar med anslutning till hängränna vid taktäckning av skiffer",
  "JTC.4112":
    "Fotplåtar med anslutning till hängränna vid taktäckning av betongpannor",
  "JTC.4113":
    "Fotplåtar med anslutning till hängränna vid taktäckning av tegelpannor",
  "JTC.4114":
    "Fotplåtar med anslutning till hängränna vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4115":
    "Fotplåtar med anslutning till hängränna vid taktäckning av profilerade överläggsplattor av fiberbetong",
  "JTC.4116":
    "Fotplåtar med anslutning till hängränna vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.412":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av överläggsplattor e d",
  "JTC.4121":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av skiffer",
  "JTC.4122":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av betongpannor",
  "JTC.4123":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av tegelpannor",
  "JTC.4124":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4125":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av profilerade överläggsplattor av fiberbetong",
  "JTC.4126":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.413":
    "Fotrännor av plåt i skivformat vid taktäckning av överläggsplattor e d",
  "JTC.41335":
    "Fotrännor av plåt i skivformat med stomme vid taktäckning av av profilerade överläggsplattor av fiberbetong",
  "JTC.41336":
    "Fotrännor av plåt i skivformat med stomme vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.4134":
    "Fotrännor av plåt i bandformat vid taktäckning av överläggsplattor e d",
  "JTC.42": "Vinkelrännor av plåt vid taktäckning av överläggsplattor e d",
  "JTC.421":
    "Vinkelrännor av plåt i skivformat vid taktäckning av överläggsplattor e d",
  "JTC.4211": "Vinkelrännor av plåt i skivformat vid taktäckning av skiffer",
  "JTC.4212":
    "Vinkelrännor av plåt i skivformat vid taktäckning av betongpannor",
  "JTC.4213":
    "Vinkelrännor av plåt i skivformat vid taktäckning av tegelpannor",
  "JTC.4214":
    "Vinkelrännor av plåt i skivformat vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4215":
    "Vinkelrännor av plåt i skivformat vid taktäckning överläggsplattor av fiberbetong",
  "JTC.4216":
    "Vinkelrännor av plåt i skivformat vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.422":
    "Vinkelrännor av plåt i bandformat vid taktäckning av överläggsplattor e d",
  "JTC.4221": "Vinkelrännor av plåt i bandformat vid taktäckning av skiffer",
  "JTC.4222":
    "Vinkelrännor av plåt i bandformat vid taktäckning av betongpannor",
  "JTC.4223":
    "Vinkelrännor av plåt i bandformat vid taktäckning av tegelpannor",
  "JTC.4224":
    "Vinkelrännor av plåt i bandformat vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4225":
    "Vinkelrännor av plåt i bandformat vid taktäckning överläggsplattor av fiberbetong",
  "JTC.4226":
    "Vinkelrännor av plåt i bandformat vid taktäckning vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.43": "Ränndalar av plåt vid taktäckning av överläggsplattor e d",
  "JTC.431":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av överläggsplattor e d",
  "JTC.4311": "Ränndalar av plåt med uppbyggt fall vid taktäckning av skiffer",
  "JTC.4312":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av betongpannor",
  "JTC.4313":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av tegelpannor",
  "JTC.4314":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4315":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning överläggsplattor av fiberbetong",
  "JTC.4316":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning överläggsplattor av cementbundet material",
  "JTC.433":
    "Försänkta ränndalar av plåt vid taktäckning av överläggsplattor e d",
  "JTC.4331": "Försänkta ränndalar av plåt vid taktäckning av skiffer",
  "JTC.4332": "Försänkta ränndalar av plåt vid taktäckning av betongpannor",
  "JTC.4333": "Försänkta ränndalar av plåt vid taktäckning av tegelpannor",
  "JTC.4334":
    "Försänkta ränndalar av plåt vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4335":
    "Försänkta ränndalar av plåt vid taktäckning överläggsplattor av fiberbetong",
  "JTC.4336":
    "Försänkta ränndalar av plåt vid taktäckning överläggsplattor av cementbundet material",
  "JTC.44": "Gesimsrännor av plåt vid taktäckning av överläggsplattor e d",
  "JTC.441": "Gesimsrännor av plåt vid taktäckning av skiffer",
  "JTC.442": "Gesimsrännor av plåt vid taktäckning av betongpannor",
  "JTC.443": "Gesimsrännor av plåt vid taktäckning av tegelpannor",
  "JTC.444":
    "Gesimsrännor av plåt vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.445":
    "Gesimsrännor av plåt vid taktäckning överläggsskivor av fiberbetong",
  "JTC.446":
    "Gesimsrännor av plåt vid taktäckning överläggsplattor av cementbundet material",
  "JTC.45":
    "Ståndskivor och hängskivor samt nockbeslag vid taktäckning av överläggsplattor e d",
  "JTC.451": "Ståndskivor med vinge vid taktäckning av överläggsplattor e d",
  "JTC.4511": "Ståndskivor med vinge vid taktäckning av skiffer",
  "JTC.4512": "Ståndskivor med vinge vid taktäckning av betongpannor",
  "JTC.4513": "Ståndskivor med vinge vid taktäckning av tegelpannor",
  "JTC.4514":
    "Ståndskivor med vinge vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4515":
    "Ståndskivor med vinge vid taktäckning överläggsplattor av fiberbetong",
  "JTC.4516":
    "Ståndskivor med vinge vid taktäckning överläggsplattor av cementbundet material",
  "JTC.452":
    "Hängskivor och gavelskivor vid taktäckning av överläggsplattor e d",
  "JTC.4521": "Hängskivor vid taktäckning av överläggsplattor e d",
  "JTC.4522":
    "Hängskivor och gavelskivor med vinge vid taktäckning av överläggsplattor e d",
  "JTC.453": "Nockbeslag vid taktäckning av överläggsplattor e d",
  "JTC.4531": "Nockbeslag vid taktäckning av skiffer",
  "JTC.4532": "Nockbeslag vid taktäckning av betongpannor",
  "JTC.4533": "Nockbeslag vid taktäckning av tegelpannor",
  "JTC.4534":
    "Nockbeslag vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4535": "Nockbeslag vid taktäckning överläggplattor av fiberbetong",
  "JTC.4536":
    "Nockbeslag vid taktäckning överläggsplattor av cementbundet material",
  "JTC.46":
    "Stosar till rör, takluckor m m vid taktäckning av överläggsplattor e d",
  "JTC.461": "Stosar till rör e d vid taktäckning av överläggsplattor e d",
  "JTC.4611": "Stosar till rör e d vid taktäckning av skiffer",
  "JTC.4612": "Stosar till rör e d vid taktäckning av betongpannor",
  "JTC.4613": "Stosar till rör e d vid taktäckning av tegelpannor",
  "JTC.4614":
    "Stosar till rör e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4615":
    "Stosar till rör e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTC.4616":
    "Stosar till rör e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.462": "Beslagning av takluckor vid taktäckning av överläggsplattor e d",
  "JTC.4621": "Beslagning av takluckor vid taktäckning av skiffer",
  "JTC.4622": "Beslagning av takluckor vid taktäckning av betongpannor",
  "JTC.4623": "Beslagning av takluckor vid taktäckning av tegelpannor",
  "JTC.4624":
    "Beslagning av takluckor vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4625":
    "Beslagning av takluckor vid taktäckning av överläggsplattor av fiberbetong",
  "JTC.4626":
    "Beslagning av takluckor vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.463": "Takfönster vid taktäckning av överläggsplattor e d",
  "JTC.4631": "Takfönster vid taktäckning av skiffer",
  "JTC.4632": "Takfönster vid taktäckning av betongpannor",
  "JTC.4633": "Takfönster vid taktäckning av tegelpannor",
  "JTC.4634":
    "Takfönster vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4635": "Takfönster vid taktäckning av överläggsplattor av fiberbetong",
  "JTC.4636":
    "Takfönster vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.464":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor e d",
  "JTC.4641":
    "Beslagning av förtillverkade takfönster vid taktäckning av skiffer",
  "JTC.4642":
    "Beslagning av förtillverkade takfönster vid taktäckning av betongpannor",
  "JTC.4643":
    "Beslagning av förtillverkade takfönster vid taktäckning av tegelpannor",
  "JTC.4644":
    "Beslagning av förtillverkade takfönster vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4645":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor av fiberbetong",
  "JTC.4646":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.47":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av överläggsplattor e d",
  "JTC.471":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor e d",
  "JTC.4711": "Helbeslagning av skorstenar vid taktäckning av skiffer",
  "JTC.4712": "Helbeslagning av skorstenar vid taktäckning av betongpannor",
  "JTC.4713": "Helbeslagning av skorstenar vid taktäckning av tegelpannor",
  "JTC.4714":
    "Helbeslagning av skorstenar vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4715":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor av fiberbetong",
  "JTC.4716":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.472":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor e d",
  "JTC.4721":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av skiffer",
  "JTC.4722":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av betongpannor",
  "JTC.4723":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av tegelpannor",
  "JTC.4724":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4725":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor av fiberbetong",
  "JTC.4726":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.473":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor e d",
  "JTC.4731": "Helbeslagning av takkupor e d vid taktäckning av skiffer",
  "JTC.4732": "Helbeslagning av takkupor e d vid taktäckning av betongpannor",
  "JTC.4733": "Helbeslagning av takkupor e d vid taktäckning av tegelpannor",
  "JTC.4734":
    "Helbeslagning av takkupor e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4735":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTC.4736":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.474":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor e d",
  "JTC.4741":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av skiffer",
  "JTC.4742":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av betongpannor",
  "JTC.4743":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av tegelpannor",
  "JTC.4744":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4745":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTC.4746":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.475":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor e d",
  "JTC.4751":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av skiffer",
  "JTC.4752":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av betongpannor",
  "JTC.4753":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av tegelpannor",
  "JTC.4754":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4755":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTC.4756":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.476":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor e d",
  "JTC.4761":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av skiffer",
  "JTC.4762":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av betongpannor",
  "JTC.4763":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av tegelpannor",
  "JTC.4764":
    "Täcklister, beslag över rörelsefogar vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4765":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTC.4766":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.478":
    "Beslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor e d",
  "JTC.4781":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av skiffer",
  "JTC.4782":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av betongpannor",
  "JTC.4783":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av tegelpannor",
  "JTC.4784":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTC.4785":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor av fiberbetong",
  "JTC.4786":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor av cementbundet material",
  "JTC.5": "Kompletteringar av plan plåt till yttervägg, mur, bjälklag e d",
  "JTC.52": "Fönsterbleck, droppbleck, listbeslag e d",
  "JTC.521": "Fönsterbleck",
  "JTC.522": "Droppbleck i väggöppningar",
  "JTC.5221": "Droppbleck vid fönster och dörröppningar",
  "JTC.5222": "Droppbleck vid ventilationsgaller e d",
  "JTC.523": "Droppbleck på balkong e d",
  "JTC.524": "Tröskelbeslag",
  "JTC.525": "Listbeslag, beslag över portomfattning e d",
  "JTC.526": "Skyddsbeslag på vägg bakom vattenkupa, bräddavlopp e d",
  "JTC.527": "Sockelbeslag",
  "JTC.53": "Beslagning av muröversida utan anslutning till taktäckning",
  "JTC.54": "Beslagning av skärmtak",
  "JTC.55": "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av plåt",
  "JTC.551":
    "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av plan plåt",
  "JTC.554":
    "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av profilerade överläggsplattor e d av metall",
  "JTC.58":
    "Diverse kompletteringar av plan plåt till yttervägg, mur, bjälklag e d",
  "JTC.581": "Diverse kompletteringar av plan plåt till yttervägg, mur e d",
  "JTC.5811":
    "Skyddsbeslag av plan plåt till yttervägg, mur e d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.5812":
    "Beslag av plan plåt med fläns till sarg e d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTC.582": "Diverse kompletteringar av plan plåt till bjälklag e d inomhus",
  "JTC.5821":
    "Beslag av plan plåt med fläns till tätskiktsmatta, takduk e d i bjälklag inomhus",
  "JTC.583": "Diverse kompletteringar av plan plåt till ytterbjälklag",
  "JTC.5831":
    "Skyddsbeslag av plan plåt vid tätskiktsmatta, takduk e d i ytterbjälklag",
  "JTC.5832":
    "Beslag av plan plåt med fläns till sarg e d vid tätskiktsmatta, takduk e d i ytterbjälklag",
  "JTC.7":
    "Kompletteringar av plan plåt vid taktäckning och väggbeklädnad av diverse material",
  "JTC.71": "Kompletteringar av plan plåt vid taktäckning av diverse material",
  "JTC.72": "Kompletteringar av plan plåt vid taktäckning av vegetation",
  "JTC.721": "Kantavslut vid taktäckning av vegetation",
  "JTC.8": "Kompletteringar för takavvattning",
  "JTC.81": "Hängrännor",
  "JTC.811": "Halvrunda hängrännor",
  "JTC.812": "Rektangulära hängrännor",
  "JTC.813": "Skyddsbeslag vid hängrännor",
  "JTC.8131": "Längsgående skyddsbeslag vid hängrännor",
  "JTC.8132": "Gavelbeslag vid hängrännor",
  "JTC.8133": "Överspolningsskydd vid hängrännor",
  "JTC.82": "Stuprör",
  "JTC.821": "Utvändiga stuprör",
  "JTC.8211": "Cirkulära stuprör",
  "JTC.8212": "Rektangulära stuprör",
  "JTC.822": "Stuprörsanslutning till dagvattenledning",
  "JTC.823": "Stuprörsutkastare",
  "JTC.8231": "Slitplåtar vid stuprörsutkastare",
  "JTC.83": "Rännutlopp",
  "JTC.831": "Rännutlopp till utvändiga stuprör",
  "JTC.832": "Takbrunnar till invändiga stuprör",
  "JTC.84": "Bräddavlopp",
  JTD: "TAKTÄCKNINGAR, VÄGGBEKLÄDNADER, KOMPLETTERINGAR M M AV PLAN ALUMINIUMPLÅT FÖR HUS",
  "JTD.1": "Taktäckningar och väggbeklädnader av plan plåt",
  "JTD.13": "Taktäckningar av plan plåt med lutning minst 1:10 (5,7°)",
  "JTD.132": "Dubbelfalsade taktäckningar av plan plåt",
  "JTD.1321": "Dubbelfalsade skivtäckningar av plan plåt",
  "JTD.1322": "Dubbelfalsade bandtäckningar av plan plåt",
  "JTD.14": "Taktäckningar av plan plåt med lutning minst 1:3 (18,4°)",
  "JTD.145": "Taktäckningar av plan plåt utförd som fjälltäckning",
  "JTD.15": "Väggbeklädnader av plan plåt",
  "JTD.152": "Dubbelfalsade väggbeklädnader av plan plåt",
  "JTD.1521": "Dubbelfalsade skivbeklädnader av plan plåt",
  "JTD.1522": "Dubbelfalsade bandbeklädnader av plan plåt",
  "JTD.155": "Väggbeklädnader av plan plåt utförda som fjälltäckning",
  "JTD.2": "Kompletteringar av plåt vid taktäckning av plan plåt",
  "JTD.21":
    "Fotrännor av plåt vid taktäckning av plan plåt och lutning minst 1:1,7 (30°)",
  "JTD.211": "Fotrännor av plåt vid falsat skivtäckt tak",
  "JTD.2113": "Fotrännor av plåt med stomme vid falsat skivtäckt tak",
  "JTD.212": "Fotrännor av plåt vid falsat bandtäckt tak",
  "JTD.2123": "Fotrännor av plåt med stomme vid falsat bandtäckt tak",
  "JTD.214": "Fotrännor av plåt i bandformat vid taktäckning av plan plåt",
  "JTD.22": "Vinkelrännor av plåt vid taktäckning av plan plåt",
  "JTD.221": "Vinkelrännor av plåt vid falsat skivtäckt tak",
  "JTD.222": "Vinkelrännor av plåt vid falsat bandtäckt tak",
  "JTD.2221": "Vinkelrännor av plåt i skivformat vid falsat bandtäckt tak",
  "JTD.2222": "Vinkelrännor av plåt i bandformat vid falsat bandtäckt tak",
  "JTD.23": "Ränndalar av plåt vid taktäckning av plan plåt",
  "JTD.231": "Ränndalar av plåt med uppbyggt fall vid taktäckning av plan plåt",
  "JTD.2311": "Ränndalar av plåt med uppbyggt fall vid falsat skivtäckt tak",
  "JTD.2312": "Ränndalar av plåt med uppbyggt fall vid falsat bandtäckt tak",
  "JTD.2313": "Ränndalar av plåt med uppbyggt fall vid svetsat bandtäckt tak",
  "JTD.233": "Försänkta ränndalar av plåt vid taktäckning av plan plåt",
  "JTD.2331": "Försänkta ränndalar av plåt vid falsat skivtäckt tak",
  "JTD.2332": "Försänkta ränndalar av plåt vid falsat bandtäckt tak",
  "JTD.24": "Gesimsrännor av plåt vid taktäckning av plan plåt",
  "JTD.241": "Gesimsrännor av plåt vid falsat skivtäckt tak",
  "JTD.242": "Gesimsrännor av plåt vid falsat bandtäckt tak",
  "JTD.244": "Gesimsrännor av plåt i bandformat vid taktäckning av plan plåt",
  "JTD.25": "Ståndskivor och hängskivor vid taktäckning av plan plåt",
  "JTD.251": "Ståndskivor vid taktäckning av plan plåt",
  "JTD.2511": "Ståndskivor vid falsat skivtäckt tak",
  "JTD.2512": "Ståndskivor vid falsat bandtäckt tak",
  "JTD.252": "Hängskivor vid taktäckning av plan plåt",
  "JTD.2521": "Hängskivor vid falsat skivtäckt tak",
  "JTD.2522": "Hängskivor vid falsat bandtäckt tak",
  "JTD.26": "Stosar till rör, takluckor m m vid taktäckning av plan plåt",
  "JTD.261": "Stosar till rör e d vid taktäckning av plan plåt",
  "JTD.2611": "Stosar till helbeslagning av rör vid taktäckning av plan plåt",
  "JTD.2612": "Ankantade stosar till rör e d vid taktäckning av plan plåt",
  "JTD.262": "Beslagning av takluckor vid taktäckning av plan plåt",
  "JTD.2621": "Beslagning av takluckor vid falsat skivtäckt tak",
  "JTD.2622": "Beslagning av takluckor vid falsat bandtäckt tak",
  "JTD.263": "Takfönster vid taktäckning av plan plåt",
  "JTD.2631": "Takfönster vid falsat skivtäckt tak",
  "JTD.2632": "Takfönster vid falsat bandtäckt tak",
  "JTD.264":
    "Beslagning av förtillverkade takfönster vid taktäckning av plan plåt",
  "JTD.2641":
    "Beslagning av förtillverkade takfönster vid falsat skivtäckt tak",
  "JTD.2642":
    "Beslagning av förtillverkade takfönster vid falsat bandtäckt tak",
  "JTD.27":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av plan plåt",
  "JTD.271": "Helbeslagning av skorstenar vid taktäckning av plan plåt",
  "JTD.2711": "Helbeslagning av skorstenar vid falsat skivtäckt tak",
  "JTD.2712": "Helbeslagning av skorstenar vid falsat bandtäckt tak",
  "JTD.272":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid taktäckning av plan plåt",
  "JTD.2721":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid falsat skivtäckt tak",
  "JTD.2722":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid falsat bandtäckt tak",
  "JTD.273": "Helbeslagning av takkupor o d vid taktäckning av plan plåt",
  "JTD.274":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av plan plåt",
  "JTD.2741":
    "Helbeslagning av murkrön, brandväggar o d vid falsat skivtäckt tak",
  "JTD.2742":
    "Helbeslagning av murkrön, brandväggar o d vid falsat bandtäckt tak",
  "JTD.275":
    "Beslagning av genomföringar för brandgasventilatorer o d vid taktäckning av plan plåt",
  "JTD.2751":
    "Beslagning av genomföringar för brandgasventilatorer o d vid falsat skivtäckt tak",
  "JTD.2752":
    "Beslagning av genomföringar för brandgasventilatorer o d vid falsat bandtäckt tak",
  "JTD.278":
    "Beslagning av diverse takuppbyggnader o d vid taktäckning av plan plåt",
  "JTD.2781":
    "Helbeslagning av diverse takuppbyggnader o d vid falsat skivtäckt tak",
  "JTD.2782":
    "Helbeslagning av diverse takuppbyggnader o d vid falsat bandtäckt tak",
  "JTD.3":
    "Kompletteringar av plan plåt vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.31": "Fotplåtar vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.311": "Fotplåtar vid taktäckning av tätskiktsmatta",
  "JTD.3111":
    "Fotplåtar med anslutning till hängränna vid taktäckning av tätskiktsmatta",
  "JTD.3112":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av tätskiktsmatta",
  "JTD.312": "Fotplåtar vid taktäckning av takduk",
  "JTD.3121":
    "Fotplåtar med anslutning till hängränna vid taktäckning av takduk",
  "JTD.3122":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av takduk",
  "JTD.34":
    "Gesimsrännor av plåt vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.341": "Gesimsrännor av plåt vid taktäckning av tätskiktsmatta",
  "JTD.342": "Gesimsrännor av plåt vid taktäckning av takduk",
  "JTD.35":
    "Ståndskivor och hängskivor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.351":
    "Ståndskivor utan vinge vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3511": "Ståndskivor utan vinge vid taktäckning av tätskiktsmatta",
  "JTD.3512": "Ståndskivor utan vinge vid taktäckning av takduk",
  "JTD.352": "Hängskivor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3521": "Hängskivor vid taktäckning av tätskiktsmatta",
  "JTD.3522": "Hängskivor vid taktäckning av takduk",
  "JTD.36":
    "Stosar till rör, takluckor m m vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.361": "Stosar till rör e d vid taktäckning av tätskiktsmatta",
  "JTD.3611":
    "Stosar till helbeslagning av rör vid taktäckning av tätskiktsmatta",
  "JTD.3612": "Ankantade stosar till rör e d vid taktäckning av tätskiktsmatta",
  "JTD.362": "Stosar till rör e d vid taktäckning av takduk",
  "JTD.3621": "Stosar till helbeslagning av rör vid taktäckning av takduk",
  "JTD.3622": "Ankantade stosar till rör e d vid taktäckning av takduk",
  "JTD.363":
    "Beslagning av takluckor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3631": "Beslagning av takluckor vid taktäckning av tätskiktsmatta",
  "JTD.3632": "Beslagning av takluckor vid taktäckning av takduk",
  "JTD.364": "Takfönster vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3641": "Takfönster vid taktäckning av tätskiktsmatta",
  "JTD.3642": "Takfönster vid taktäckning av takduk",
  "JTD.365":
    "Beslagning av förtillverkade takfönster vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3651":
    "Beslagning av förtillverkade takfönster vid taktäckning av tätskiktsmatta",
  "JTD.3652":
    "Beslagning av förtillverkade takfönster vid taktäckning av takduk",
  "JTD.37":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.371":
    "Helbeslagning av skorstenar vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3711": "Helbeslagning av skorstenar vid taktäckning av tätskiktsmatta",
  "JTD.3712": "Helbeslagning av skorstenar vid taktäckning av takduk",
  "JTD.372":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3721":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av tätskiktsmatta",
  "JTD.3722":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av takduk",
  "JTD.373":
    "Helbeslagning av takkupor o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3731": "Helbeslagning av takkupor o d vid taktäckning av tätskiktsmatta",
  "JTD.3732": "Helbeslagning av takkupor o d vid taktäckning av takduk",
  "JTD.374":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3741":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av tätskiktsmatta",
  "JTD.3742":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av takduk",
  "JTD.375":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3751":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av tätskiktsmatta",
  "JTD.3752":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av takduk",
  "JTD.376":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3761":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av tätskiktsmatta",
  "JTD.3762":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av takduk",
  "JTD.378":
    "Beslagning av diverse takuppbyggnader o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.3781":
    "Helbeslagning av diverse takuppbyggnader o d vid taktäckning av tätskiktsmatta",
  "JTD.3782":
    "Helbeslagning av diverse takuppbyggnader o d vid taktäckning av takduk",
  "JTD.4":
    "Kompletteringar av plan plåt vid taktäckning av överläggsplattor e d",
  "JTD.41": "Fotplåtar och fotrännor vid taktäckning av överläggsplattor e d",
  "JTD.411":
    "Fotplåtar med anslutning till hängränna vid taktäckning av överläggsplattor e d",
  "JTD.4111":
    "Fotplåtar med anslutning till hängränna vid taktäckning av skiffer",
  "JTD.4112":
    "Fotplåtar med anslutning till hängränna vid taktäckning av betongpannor",
  "JTD.4113":
    "Fotplåtar med anslutning till hängränna vid taktäckning av tegelpannor",
  "JTD.4114":
    "Fotplåtar med anslutning till hängränna vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4115":
    "Fotplåtar med anslutning till hängränna vid taktäckning av profilerade överläggsplattor av fiberbetong",
  "JTD.4116":
    "Fotplåtar med anslutning till hängränna vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.412":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av överläggsplattor e d",
  "JTD.4121":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av skiffer",
  "JTD.4122":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av betongpannor",
  "JTD.4123":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av tegelpannor",
  "JTD.4124":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4125":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av profilerade överläggsplattor av fiberbetong",
  "JTD.4126":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.413":
    "Fotrännor av plåt i skivformat vid taktäckning av överläggsplattor e d",
  "JTD.4133":
    "Fotrännor av plåt i skivformat med stomme vid taktäckning av överläggsplattor e d",
  "JTD.4134":
    "Fotrännor av plåt i bandformat vid taktäckning av överläggsplattor e d",
  "JTD.42": "Vinkelrännor av plåt vid taktäckning av överläggsplattor e d",
  "JTD.421":
    "Vinkelrännor av plåt i skivformat vid taktäckning av överläggsplattor e d",
  "JTD.4211": "Vinkelrännor av plåt i skivformat vid taktäckning av skiffer",
  "JTD.4212":
    "Vinkelrännor av plåt i skivformat vid taktäckning av betongpannor",
  "JTD.4213":
    "Vinkelrännor av plåt i skivformat vid taktäckning av tegelpannor",
  "JTD.4214":
    "Vinkelrännor av plåt i skivformat vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4215":
    "Vinkelrännor av plåt i skivformat vid taktäckning överläggsplattor av fiberbetong",
  "JTD.4216":
    "Vinkelrännor av plåt i skivformat vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.422":
    "Vinkelrännor av plåt i bandformat vid taktäckning av överläggsplattor e d",
  "JTD.4221": "Vinkelrännor av plåt i bandformat vid taktäckning av skiffer",
  "JTD.4222":
    "Vinkelrännor av plåt i bandformat vid taktäckning av betongpannor",
  "JTD.4223":
    "Vinkelrännor av plåt i bandformat vid taktäckning av tegelpannor",
  "JTD.4224":
    "Vinkelrännor av plåt i bandformat vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4225":
    "Vinkelrännor av plåt i bandformat vid taktäckning överläggsplattor av fiberbetong",
  "JTD.4226":
    "Vinkelrännor av plåt i bandformat vid taktäckning vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.43": "Ränndalar av plåt vid taktäckning av överläggsplattor e d",
  "JTD.431":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av överläggsplattor e d",
  "JTD.4311": "Ränndalar av plåt med uppbyggt fall vid taktäckning av skiffer",
  "JTD.4312":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av betongpannor",
  "JTD.4313":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av tegelpannor",
  "JTD.4314":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4315":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning överläggsplattor av fiberbetong",
  "JTD.4316":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning överläggsplattor av cementbundet material",
  "JTD.433":
    "Försänkta ränndalar av plåt vid taktäckning av överläggsplattor e d",
  "JTD.4331": "Försänkta ränndalar av plåt vid taktäckning av skiffer",
  "JTD.4332": "Försänkta ränndalar av plåt vid taktäckning av betongpannor",
  "JTD.4333": "Försänkta ränndalar av plåt vid taktäckning av tegelpannor",
  "JTD.4334":
    "Försänkta ränndalar av plåt vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4335":
    "Försänkta ränndalar av plåt vid taktäckning överläggsplattor av fiberbetong",
  "JTD.4336":
    "Försänkta ränndalar av plåt vid taktäckning överläggsplattor av cementbundet material",
  "JTD.44": "Gesimsrännor av plåt vid taktäckning av överläggsplattor e d",
  "JTD.441": "Gesimsrännor av plåt vid taktäckning av skiffer",
  "JTD.442": "Gesimsrännor av plåt vid taktäckning av betongpannor",
  "JTD.443": "Gesimsrännor av plåt vid taktäckning av tegelpannor",
  "JTD.444":
    "Gesimsrännor av plåt vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.445":
    "Gesimsrännor av plåt vid taktäckning överläggsskivor av fiberbetong",
  "JTD.446":
    "Gesimsrännor av plåt vid taktäckning överläggsplattor av cementbundet material",
  "JTD.45":
    "Ståndskivor och hängskivor samt nockbeslag vid taktäckning av överläggsplattor e d",
  "JTD.451": "Ståndskivor med vinge vid taktäckning av överläggsplattor e d",
  "JTD.4511": "Ståndskivor med vinge vid taktäckning av skiffer",
  "JTD.4512": "Ståndskivor med vinge vid taktäckning av betongpannor",
  "JTD.4513": "Ståndskivor med vinge vid taktäckning av tegelpannor",
  "JTD.4514":
    "Ståndskivor med vinge vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4515":
    "Ståndskivor med vinge vid taktäckning överläggsplattor av fiberbetong",
  "JTD.4516":
    "Ståndskivor med vinge vid taktäckning överläggsplattor av cementbundet material",
  "JTD.452":
    "Hängskivor och gavelskivor vid taktäckning av överläggsplattor e d",
  "JTD.4521": "Hängskivor vid taktäckning av överläggsplattor e d",
  "JTD.4522":
    "Hängskivor och gavelskivor med vinge vid taktäckning av överläggsplattor e d",
  "JTD.453": "Nockbeslag vid taktäckning av överläggsplattor e d",
  "JTD.4531": "Nockbeslag vid taktäckning av skiffer",
  "JTD.4532": "Nockbeslag vid taktäckning av betongpannor",
  "JTD.4533": "Nockbeslag vid taktäckning av tegelpannor",
  "JTD.4534":
    "Nockbeslag vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4535": "Nockbeslag vid taktäckning överläggplattor av fiberbetong",
  "JTD.4536":
    "Nockbeslag vid taktäckning överläggsplattor av cementbundet material",
  "JTD.46":
    "Stosar till rör, takluckor m m vid taktäckning av överläggsplattor e d",
  "JTD.461": "Stosar till rör e d vid taktäckning av överläggsplattor e d",
  "JTD.4611": "Stosar till rör e d vid taktäckning av skiffer",
  "JTD.4612": "Stosar till rör e d vid taktäckning av betongpannor",
  "JTD.4613": "Stosar till rör e d vid taktäckning av tegelpannor",
  "JTD.4614":
    "Stosar till rör e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4615":
    "Stosar till rör e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTD.4616":
    "Stosar till rör e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.462": "Beslagning av takluckor vid taktäckning av överläggsplattor e d",
  "JTD.4621": "Beslagning av takluckor vid taktäckning av skiffer",
  "JTD.4622": "Beslagning av takluckor vid taktäckning av betongpannor",
  "JTD.4623": "Beslagning av takluckor vid taktäckning av tegelpannor",
  "JTD.4624":
    "Beslagning av takluckor vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4625":
    "Beslagning av takluckor vid taktäckning av överläggsplattor av fiberbetong",
  "JTD.4626":
    "Beslagning av takluckor vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.463": "Takfönster vid taktäckning av överläggsplattor e d",
  "JTD.4631": "Takfönster vid taktäckning av skiffer",
  "JTD.4632": "Takfönster vid taktäckning av betongpannor",
  "JTD.4633": "Takfönster vid taktäckning av tegelpannor",
  "JTD.4634":
    "Takfönster vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4635": "Takfönster vid taktäckning av överläggsplattor av fiberbetong",
  "JTD.4636":
    "Takfönster vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.464":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor e d",
  "JTD.4641":
    "Beslagning av förtillverkade takfönster vid taktäckning av skiffer",
  "JTD.4642":
    "Beslagning av förtillverkade takfönster vid taktäckning av betongpannor",
  "JTD.4643":
    "Beslagning av förtillverkade takfönster vid taktäckning av tegelpannor",
  "JTD.4644":
    "Beslagning av förtillverkade takfönster vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4645":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor av fiberbetong",
  "JTD.4646":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.47":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av överläggsplattor e d",
  "JTD.471":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor e d",
  "JTD.4711": "Helbeslagning av skorstenar vid taktäckning av skiffer",
  "JTD.4712": "Helbeslagning av skorstenar vid taktäckning av betongpannor",
  "JTD.4713": "Helbeslagning av skorstenar vid taktäckning av tegelpannor",
  "JTD.4714":
    "Helbeslagning av skorstenar vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4715":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor av fiberbetong",
  "JTD.4716":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.472":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor e d",
  "JTD.4721":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av skiffer",
  "JTD.4722":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av betongpannor",
  "JTD.4723":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av tegelpannor",
  "JTD.4724":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4725":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor av fiberbetong",
  "JTD.4726":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.473":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor e d",
  "JTD.4731": "Helbeslagning av takkupor e d vid taktäckning av skiffer",
  "JTD.4732": "Helbeslagning av takkupor e d vid taktäckning av betongpannor",
  "JTD.4733": "Helbeslagning av takkupor e d vid taktäckning av tegelpannor",
  "JTD.4734":
    "Helbeslagning av takkupor e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4735":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTD.4736":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.474":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor e d",
  "JTD.4741":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av skiffer",
  "JTD.4742":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av betongpannor",
  "JTD.4743":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av tegelpannor",
  "JTD.4744":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4745":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTD.4746":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.475":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor e d",
  "JTD.4751":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av skiffer",
  "JTD.4752":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av betongpannor",
  "JTD.4753":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av tegelpannor",
  "JTD.4754":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4755":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTD.4756":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.476":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor e d",
  "JTD.4761":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av skiffer",
  "JTD.4762":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av betongpannor",
  "JTD.4763":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av tegelpannor",
  "JTD.4764":
    "Täcklister, beslag över rörelsefogar vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4765":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTD.4766":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.478":
    "Beslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor e d",
  "JTD.4781":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av skiffer",
  "JTD.4782":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av betongpannor",
  "JTD.4783":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av tegelpannor",
  "JTD.4784":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTD.4785":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor av fiberbetong",
  "JTD.4786":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor av cementbundet material",
  "JTD.5": "Kompletteringar av plan plåt till yttervägg, mur, bjälklag e d",
  "JTD.52": "Fönsterbleck, droppbleck, listbeslag e d",
  "JTD.521": "Fönsterbleck",
  "JTD.522": "Droppbleck i väggöppningar",
  "JTD.5221": "Droppbleck vid fönster och dörröppningar",
  "JTD.5222": "Droppbleck vid ventilationsgaller e d",
  "JTD.523": "Droppbleck på balkong e d",
  "JTD.524": "Tröskelbeslag",
  "JTD.525": "Listbeslag, beslag över portomfattning e d",
  "JTD.526": "Skyddsbeslag på vägg bakom vattenkupa, bräddavlopp e d",
  "JTD.527": "Sockelbeslag",
  "JTD.53": "Beslagning av muröversida utan anslutning till taktäckning",
  "JTD.54": "Beslagning av skärmtak",
  "JTD.55": "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av plåt",
  "JTD.551":
    "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av plan plåt",
  "JTD.554":
    "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av profilerade överläggsplattor e d av metall",
  "JTD.58":
    "Diverse kompletteringar av plan plåt till yttervägg, mur, bjälklag e d",
  "JTD.581": "Diverse kompletteringar av plan plåt till yttervägg, mur e d",
  "JTD.5811":
    "Skyddsbeslag av plan plåt till yttervägg, mur e d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.5812":
    "Beslag av plan plåt med fläns till sarg e d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTD.582": "Diverse kompletteringar av plan plåt till bjälklag e d inomhus",
  "JTD.5821":
    "Beslag av plan plåt med fläns till tätskiktsmatta, duk e d i bjälklag inomhus",
  "JTD.583": "Diverse kompletteringar av plan plåt till ytterbjälklag",
  "JTD.5831":
    "Skyddsbeslag av plan plåt vid tätskiktsmatta, takduk e d i ytterbjälklag",
  "JTD.5832":
    "Beslag av plan plåt med fläns till sarg e d vid tätskiktsmatta, takduk e d i ytterbjälklag",
  "JTD.8": "Kompletteringar för takavvattning",
  "JTD.81": "Hängrännor",
  "JTD.811": "Halvrunda hängrännor",
  "JTD.812": "Rektangulära hängrännor",
  "JTD.813": "Skyddsbeslag vid hängrännor",
  "JTD.8131": "Längsgående skyddsbeslag vid hängrännor",
  "JTD.8132": "Gavelbeslag vid hängrännor",
  "JTD.8133": "Överspolningsskydd vid hängrännor",
  "JTD.82": "Stuprör",
  "JTD.821": "Utvändiga stuprör",
  "JTD.8211": "Cirkulära stuprör",
  "JTD.8212": "Rektangulära stuprör",
  "JTD.822": "Stuprörsanslutning till dagvattenledning",
  "JTD.823": "Stuprörsutkastare",
  "JTD.8231": "Slitplåtar vid stuprörsutkastare",
  "JTD.83": "Rännutlopp",
  "JTD.831": "Rännutlopp till utvändiga stuprör",
  "JTD.832": "Takbrunnar till invändiga stuprör",
  "JTD.84": "Bräddavlopp",
  JTE: "TAKTÄCKNINGAR, VÄGGBEKLÄDNADER, KOMPLETTERINGAR M M AV PLAN KOPPARPLÅT FÖR HUS",
  "JTE.1": "Taktäckningar och väggbeklädnader av plan plåt",
  "JTE.13": "Taktäckningar av plan plåt med lutning minst 1:10 (5,7°)",
  "JTE.132": "Dubbelfalsade taktäckningar av plan plåt",
  "JTE.1321": "Dubbelfalsade skivtäckningar av plan plåt",
  "JTE.1322": "Dubbelfalsade bandtäckningar av plan plåt",
  "JTE.14": "Taktäckningar av plan plåt med lutning minst 1:3 (18,4°)",
  "JTE.145": "Taktäckningar av plan plåt utförd som fjälltäckning",
  "JTE.15": "Väggbeklädnader av plan plåt",
  "JTE.152": "Dubbelfalsade väggbeklädnader av plan plåt",
  "JTE.1521": "Dubbelfalsade skivbeklädnader av plan plåt",
  "JTE.1522": "Dubbelfalsade bandbeklädnader av plan plåt",
  "JTE.155": "Väggbeklädnader av plan plåt utförda som fjälltäckning",
  "JTE.2": "Kompletteringar av plåt vid taktäckning av plan plåt",
  "JTE.21":
    "Fotrännor av plåt vid taktäckning av plan plåt och lutning minst 1:1,7 (30°)",
  "JTE.211": "Fotrännor av plåt vid falsat skivtäckt tak",
  "JTE.2113": "Fotrännor av plåt med stomme vid falsat skivtäckt tak",
  "JTE.212": "Fotrännor av plåt vid falsat bandtäckt tak",
  "JTE.2123": "Fotrännor av plåt med stomme vid falsat bandtäckt tak",
  "JTE.214": "Fotrännor av plåt i bandformat vid taktäckning av plan plåt",
  "JTE.22": "Vinkelrännor av plåt vid taktäckning av plan plåt",
  "JTE.221": "Vinkelrännor av plåt vid falsat skivtäckt tak",
  "JTE.222": "Vinkelrännor av plåt vid falsat bandtäckt tak",
  "JTE.2221": "Vinkelrännor av plåt i skivformat vid falsat bandtäckt tak",
  "JTE.2222": "Vinkelrännor av plåt i bandformat vid falsat bandtäckt tak",
  "JTE.23": "Ränndalar av plåt vid taktäckning av plan plåt",
  "JTE.231": "Ränndalar av plåt med uppbyggt fall vid taktäckning av plan plåt",
  "JTE.2311": "Ränndalar av plåt med uppbyggt fall vid falsat skivtäckt tak",
  "JTE.2312": "Ränndalar av plåt med uppbyggt fall vid falsat bandtäckt tak",
  "JTE.233": "Försänkta ränndalar av plåt vid taktäckning av plan plåt",
  "JTE.2331": "Försänkta ränndalar av plåt vid falsat skivtäckt tak",
  "JTE.2332": "Försänkta ränndalar av plåt vid falsat bandtäckt tak",
  "JTE.24": "Gesimsrännor av plåt vid taktäckning av plan plåt",
  "JTE.241": "Gesimsrännor av plåt vid falsat skivtäckt tak",
  "JTE.242": "Gesimsrännor av plåt vid falsat bandtäckt tak",
  "JTE.244": "Gesimsrännor av plåt i bandformat vid taktäckning av plan plåt",
  "JTE.25": "Ståndskivor och hängskivor vid taktäckning av plan plåt",
  "JTE.251": "Ståndskivor vid taktäckning av plan plåt",
  "JTE.2511": "Ståndskivor vid falsat skivtäckt tak",
  "JTE.2512": "Ståndskivor vid falsat bandtäckt tak",
  "JTE.252": "Hängskivor vid taktäckning av plan plåt",
  "JTE.2521": "Hängskivor vid falsat skivtäckt tak",
  "JTE.2522": "Hängskivor vid falsat bandtäckt tak",
  "JTE.26": "Stosar till rör, takluckor m m vid taktäckning av plan plåt",
  "JTE.261": "Stosar till rör e d vid taktäckning av plan plåt",
  "JTE.2611": "Stosar till helbeslagning av rör vid taktäckning av plan plåt",
  "JTE.2612": "Ankantade stosar till rör e d vid taktäckning av plan plåt",
  "JTE.262": "Beslagning av takluckor vid taktäckning av plan plåt",
  "JTE.2621": "Beslagning av takluckor vid falsat skivtäckt tak",
  "JTE.2622": "Beslagning av takluckor vid falsat bandtäckt tak",
  "JTE.263": "Takfönster vid taktäckning av plan plåt",
  "JTE.2631": "Takfönster vid falsat skivtäckt tak",
  "JTE.2632": "Takfönster vid falsat bandtäckt tak",
  "JTE.264":
    "Beslagning av förtillverkade takfönster vid taktäckning av plan plåt",
  "JTE.2641":
    "Beslagning av förtillverkade takfönster vid falsat skivtäckt tak",
  "JTE.2642":
    "Beslagning av förtillverkade takfönster vid falsat bandtäckt tak",
  "JTE.27":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av plan plåt",
  "JTE.271": "Helbeslagning av skorstenar vid taktäckning av plan plåt",
  "JTE.2711": "Helbeslagning av skorstenar vid falsat skivtäckt tak",
  "JTE.2712": "Helbeslagning av skorstenar vid falsat bandtäckt tak",
  "JTE.272":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid taktäckning av plan plåt",
  "JTE.2721":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid falsat skivtäckt tak",
  "JTE.2722":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid falsat bandtäckt tak",
  "JTE.273": "Helbeslagning av takkupor o d vid taktäckning av plan plåt",
  "JTE.274":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av plan plåt",
  "JTE.2741":
    "Helbeslagning av murkrön, brandväggar o d vid falsat skivtäckt tak",
  "JTE.2742":
    "Helbeslagning av murkrön, brandväggar o d vid falsat bandtäckt tak",
  "JTE.2743":
    "Helbeslagning av murkrön, brandväggar o d vid svetsat bandtäckt tak",
  "JTE.275":
    "Beslagning av genomföringar för brandgasventilatorer o d vid taktäckning av plan plåt",
  "JTE.2751":
    "Beslagning av genomföringar för brandgasventilatorer o d vid falsat skivtäckt tak",
  "JTE.2752":
    "Beslagning av genomföringar för brandgasventilatorer o d vid falsat bandtäckt tak",
  "JTE.278":
    "Beslagning av diverse takuppbyggnader o d vid taktäckning av plan plåt",
  "JTE.2781":
    "Helbeslagning av diverse takuppbyggnader o d vid falsat skivtäckt tak",
  "JTE.2782":
    "Helbeslagning av diverse takuppbyggnader o d vid falsat bandtäckt tak",
  "JTE.3":
    "Kompletteringar av plan plåt vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.31": "Fotplåtar vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.311": "Fotplåtar vid taktäckning av tätskiktsmatta",
  "JTE.3111":
    "Fotplåtar med anslutning till hängränna vid taktäckning av tätskiktsmatta",
  "JTE.3112":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av tätskiktsmatta",
  "JTE.312": "Fotplåtar vid taktäckning av takduk",
  "JTE.3121":
    "Fotplåtar med anslutning till hängränna vid taktäckning av takduk",
  "JTE.3122":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av takduk",
  "JTE.34":
    "Gesimsrännor av plåt vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.341": "Gesimsrännor av plåt vid taktäckning av tätskiktsmatta",
  "JTE.342": "Gesimsrännor av plåt vid taktäckning av takduk",
  "JTE.35":
    "Ståndskivor och hängskivor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.351":
    "Ståndskivor utan vinge vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3511": "Ståndskivor utan vinge vid taktäckning av tätskiktsmatta",
  "JTE.3512": "Ståndskivor utan vinge vid taktäckning av takduk",
  "JTE.352": "Hängskivor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3521": "Hängskivor vid taktäckning av tätskiktsmatta",
  "JTE.3522": "Hängskivor vid taktäckning av takduk",
  "JTE.36":
    "Stosar till rör, takluckor m m vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.361": "Stosar till rör e d vid taktäckning av tätskiktsmatta",
  "JTE.3611":
    "Stosar till helbeslagning av rör vid taktäckning av tätskiktsmatta",
  "JTE.3612": "Ankantade stosar till rör e d vid taktäckning av tätskiktsmatta",
  "JTE.362": "Stosar till rör e d vid taktäckning av takduk",
  "JTE.3621": "Stosar till helbeslagning av rör vid taktäckning av takduk",
  "JTE.3622": "Ankantade stosar till rör e d vid taktäckning av takduk",
  "JTE.363":
    "Beslagning av takluckor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3631": "Beslagning av takluckor vid taktäckning av tätskiktsmatta",
  "JTE.3632": "Beslagning av takluckor vid taktäckning av takduk",
  "JTE.364": "Takfönster vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3641": "Takfönster vid taktäckning av tätskiktsmatta",
  "JTE.3642": "Takfönster vid taktäckning av takduk",
  "JTE.365":
    "Beslagning av förtillverkade takfönster vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3651":
    "Beslagning av förtillverkade takfönster vid taktäckning av tätskiktsmatta",
  "JTE.3652":
    "Beslagning av förtillverkade takfönster vid taktäckning av takduk",
  "JTE.37":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.371":
    "Helbeslagning av skorstenar vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3711": "Helbeslagning av skorstenar vid taktäckning av tätskiktsmatta",
  "JTE.3712": "Helbeslagning av skorstenar vid taktäckning av takduk",
  "JTE.372":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3721":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av tätskiktsmatta",
  "JTE.3722":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av takduk",
  "JTE.373":
    "Helbeslagning av takkupor o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3731": "Helbeslagning av takkupor o d vid taktäckning av tätskiktsmatta",
  "JTE.3732": "Helbeslagning av takkupor o d vid taktäckning av takduk",
  "JTE.374":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3741":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av tätskiktsmatta",
  "JTE.3742":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av takduk",
  "JTE.375":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3751":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av tätskiktsmatta",
  "JTE.3752":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av takduk",
  "JTE.376":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3761":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av tätskiktsmatta",
  "JTE.3762":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av takduk",
  "JTE.378":
    "Beslagning av diverse takuppbyggnader o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.3781":
    "Helbeslagning av diverse takuppbyggnader o d vid taktäckning av tätskiktsmatta",
  "JTE.3782":
    "Helbeslagning av diverse takuppbyggnader o d vid taktäckning av takduk",
  "JTE.4":
    "Kompletteringar av plan plåt vid taktäckning av överläggsplattor e d",
  "JTE.41": "Fotplåtar och fotrännor vid taktäckning av överläggsplattor e d",
  "JTE.411":
    "Fotplåtar med anslutning till hängränna vid taktäckning av överläggsplattor e d",
  "JTE.4111":
    "Fotplåtar med anslutning till hängränna vid taktäckning av skiffer",
  "JTE.4112":
    "Fotplåtar med anslutning till hängränna vid taktäckning av betongpannor",
  "JTE.4113":
    "Fotplåtar med anslutning till hängränna vid taktäckning av tegelpannor",
  "JTE.4114":
    "Fotplåtar med anslutning till hängränna vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4115":
    "Fotplåtar med anslutning till hängränna vid taktäckning av profilerade överläggsplattor av fiberbetong",
  "JTE.4116":
    "Fotplåtar med anslutning till hängränna vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.412":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av överläggsplattor e d",
  "JTE.4121":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av skiffer",
  "JTE.4122":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av betongpannor",
  "JTE.4123":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av tegelpannor",
  "JTE.4124":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4125":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av profilerade överläggsplattor av fiberbetong",
  "JTE.4126":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.413":
    "Fotrännor av plåt i skivformat vid taktäckning av överläggsplattor e d",
  "JTE.4133":
    "Fotrännor av plåt i skivformat med stomme vid taktäckning av överläggsplattor e d",
  "JTE.4134":
    "Fotrännor av plåt i bandformat vid taktäckning av överläggsplattor e d",
  "JTE.42": "Vinkelrännor av plåt vid taktäckning av överläggsplattor e d",
  "JTE.421":
    "Vinkelrännor av plåt i skivformat vid taktäckning av överläggsplattor e d",
  "JTE.4211": "Vinkelrännor av plåt i skivformat vid taktäckning av skiffer",
  "JTE.4212":
    "Vinkelrännor av plåt i skivformat vid taktäckning av betongpannor",
  "JTE.4213":
    "Vinkelrännor av plåt i skivformat vid taktäckning av tegelpannor",
  "JTE.4214":
    "Vinkelrännor av plåt i skivformat vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4215":
    "Vinkelrännor av plåt i skivformat vid taktäckning överläggsplattor av fiberbetong",
  "JTE.4216":
    "Vinkelrännor av plåt i skivformat vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.422":
    "Vinkelrännor av plåt i bandformat vid taktäckning av överläggsplattor e d",
  "JTE.4221": "Vinkelrännor av plåt i bandformat vid taktäckning av skiffer",
  "JTE.4222":
    "Vinkelrännor av plåt i bandformat vid taktäckning av betongpannor",
  "JTE.4223":
    "Vinkelrännor av plåt i bandformat vid taktäckning av tegelpannor",
  "JTE.4224":
    "Vinkelrännor av plåt i bandformat vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4225":
    "Vinkelrännor av plåt i bandformat vid taktäckning överläggsplattor av fiberbetong",
  "JTE.4226":
    "Vinkelrännor av plåt i bandformat vid taktäckning vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.43": "Ränndalar av plåt vid taktäckning av överläggsplattor e d",
  "JTE.431":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av överläggsplattor e d",
  "JTE.4311": "Ränndalar av plåt med uppbyggt fall vid taktäckning av skiffer",
  "JTE.4312":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av betongpannor",
  "JTE.4313":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av tegelpannor",
  "JTE.4314":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4315":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning överläggsplattor av fiberbetong",
  "JTE.4316":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning överläggsplattor av cementbundet material",
  "JTE.433":
    "Försänkta ränndalar av plåt vid taktäckning av överläggsplattor e d",
  "JTE.4331": "Försänkta ränndalar av plåt vid taktäckning av skiffer",
  "JTE.4332": "Försänkta ränndalar av plåt vid taktäckning av betongpannor",
  "JTE.4333": "Försänkta ränndalar av plåt vid taktäckning av tegelpannor",
  "JTE.4334":
    "Försänkta ränndalar av plåt vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4335":
    "Försänkta ränndalar av plåt vid taktäckning överläggsplattor av fiberbetong",
  "JTE.4336":
    "Försänkta ränndalar av plåt vid taktäckning överläggsplattor av cementbundet material",
  "JTE.44": "Gesimsrännor av plåt vid taktäckning av överläggsplattor e d",
  "JTE.441": "Gesimsrännor av plåt vid taktäckning av skiffer",
  "JTE.442": "Gesimsrännor av plåt vid taktäckning av betongpannor",
  "JTE.443": "Gesimsrännor av plåt vid taktäckning av tegelpannor",
  "JTE.444":
    "Gesimsrännor av plåt vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.445":
    "Gesimsrännor av plåt vid taktäckning överläggsskivor av fiberbetong",
  "JTE.446":
    "Gesimsrännor av plåt vid taktäckning överläggsplattor av cementbundet material",
  "JTE.45":
    "Ståndskivor och hängskivor samt nockbeslag vid taktäckning av överläggsplattor e d",
  "JTE.451": "Ståndskivor med vinge vid taktäckning av överläggsplattor e d",
  "JTE.4511": "Ståndskivor med vinge vid taktäckning av skiffer",
  "JTE.4512": "Ståndskivor med vinge vid taktäckning av betongpannor",
  "JTE.4513": "Ståndskivor med vinge vid taktäckning av tegelpannor",
  "JTE.4514":
    "Ståndskivor med vinge vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4515":
    "Ståndskivor med vinge vid taktäckning överläggsplattor av fiberbetong",
  "JTE.4516":
    "Ståndskivor med vinge vid taktäckning överläggsplattor av cementbundet material",
  "JTE.452":
    "Hängskivor och gavelskivor vid taktäckning av överläggsplattor e d",
  "JTE.4521": "Hängskivor vid taktäckning av överläggsplattor e d",
  "JTE.4522":
    "Hängskivor och gavelskivor med vinge vid taktäckning av överläggsplattor e d",
  "JTE.453": "Nockbeslag vid taktäckning av överläggsplattor e d",
  "JTE.4531": "Nockbeslag vid taktäckning av skiffer",
  "JTE.4532": "Nockbeslag vid taktäckning av betongpannor",
  "JTE.4533": "Nockbeslag vid taktäckning av tegelpannor",
  "JTE.4534":
    "Nockbeslag vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4535": "Nockbeslag vid taktäckning överläggplattor av fiberbetong",
  "JTE.4536":
    "Nockbeslag vid taktäckning överläggsplattor av cementbundet material",
  "JTE.46":
    "Stosar till rör, takluckor m m vid taktäckning av överläggsplattor e d",
  "JTE.461": "Stosar till rör e d vid taktäckning av överläggsplattor e d",
  "JTE.4611": "Stosar till rör e d vid taktäckning av skiffer",
  "JTE.4612": "Stosar till rör e d vid taktäckning av betongpannor",
  "JTE.4613": "Stosar till rör e d vid taktäckning av tegelpannor",
  "JTE.4614":
    "Stosar till rör e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4615":
    "Stosar till rör e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTE.4616":
    "Stosar till rör e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.462": "Beslagning av takluckor vid taktäckning av överläggsplattor e d",
  "JTE.4621": "Beslagning av takluckor vid taktäckning av skiffer",
  "JTE.4622": "Beslagning av takluckor vid taktäckning av betongpannor",
  "JTE.4623": "Beslagning av takluckor vid taktäckning av tegelpannor",
  "JTE.4624":
    "Beslagning av takluckor vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4625":
    "Beslagning av takluckor vid taktäckning av överläggsplattor av fiberbetong",
  "JTE.4626":
    "Beslagning av takluckor vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.463": "Takfönster vid taktäckning av överläggsplattor e d",
  "JTE.4631": "Takfönster vid taktäckning av skiffer",
  "JTE.4632": "Takfönster vid taktäckning av betongpannor",
  "JTE.4633": "Takfönster vid taktäckning av tegelpannor",
  "JTE.4634":
    "Takfönster vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4635": "Takfönster vid taktäckning av överläggsplattor av fiberbetong",
  "JTE.4636":
    "Takfönster vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.464":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor e d",
  "JTE.4641":
    "Beslagning av förtillverkade takfönster vid taktäckning av skiffer",
  "JTE.4642":
    "Beslagning av förtillverkade takfönster vid taktäckning av betongpannor",
  "JTE.4643":
    "Beslagning av förtillverkade takfönster vid taktäckning av tegelpannor",
  "JTE.4644":
    "Beslagning av förtillverkade takfönster vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4645":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor av fiberbetong",
  "JTE.4646":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.47":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av överläggsplattor e d",
  "JTE.471":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor e d",
  "JTE.4711": "Helbeslagning av skorstenar vid taktäckning av skiffer",
  "JTE.4712": "Helbeslagning av skorstenar vid taktäckning av betongpannor",
  "JTE.4713": "Helbeslagning av skorstenar vid taktäckning av tegelpannor",
  "JTE.4714":
    "Helbeslagning av skorstenar vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4715":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor av fiberbetong",
  "JTE.4716":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.472":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor e d",
  "JTE.4721":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av skiffer",
  "JTE.4722":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av betongpannor",
  "JTE.4723":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av tegelpannor",
  "JTE.4724":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4725":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor av fiberbetong",
  "JTE.4726":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.473":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor e d",
  "JTE.4731": "Helbeslagning av takkupor e d vid taktäckning av skiffer",
  "JTE.4732": "Helbeslagning av takkupor e d vid taktäckning av betongpannor",
  "JTE.4733": "Helbeslagning av takkupor e d vid taktäckning av tegelpannor",
  "JTE.4734":
    "Helbeslagning av takkupor e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4735":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTE.4736":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.474":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor e d",
  "JTE.4741":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av skiffer",
  "JTE.4742":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av betongpannor",
  "JTE.4743":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av tegelpannor",
  "JTE.4744":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4745":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTE.4746":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.475":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor e d",
  "JTE.4751":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av skiffer",
  "JTE.4752":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av betongpannor",
  "JTE.4753":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av tegelpannor",
  "JTE.4754":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4755":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTE.4756":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.476":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor e d",
  "JTE.4761":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av skiffer",
  "JTE.4762":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av betongpannor",
  "JTE.4763":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av tegelpannor",
  "JTE.4764":
    "Täcklister, beslag över rörelsefogar vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4765":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTE.4766":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.478":
    "Beslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor e d",
  "JTE.4781":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av skiffer",
  "JTE.4782":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av betongpannor",
  "JTE.4783":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av tegelpannor",
  "JTE.4784":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTE.4785":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor av fiberbetong",
  "JTE.4786":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor av cementbundet material",
  "JTE.5": "Kompletteringar av plan plåt till yttervägg, mur, bjälklag e d",
  "JTE.52": "Fönsterbleck, droppbleck, listbeslag e d",
  "JTE.521": "Fönsterbleck",
  "JTE.522": "Droppbleck i väggöppningar",
  "JTE.5221": "Droppbleck vid fönster och dörröppningar",
  "JTE.5222": "Droppbleck vid ventilationsgaller e d",
  "JTE.523": "Droppbleck på balkong e d",
  "JTE.524": "Tröskelbeslag",
  "JTE.525": "Listbeslag, beslag över portomfattning e d",
  "JTE.526": "Skyddsbeslag på vägg bakom vattenkupa, bräddavlopp e d",
  "JTE.527": "Sockelbeslag",
  "JTE.53": "Beslagning av muröversida utan anslutning till taktäckning",
  "JTE.54": "Beslagning av skärmtak",
  "JTE.55": "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av plåt",
  "JTE.551":
    "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av plan plåt",
  "JTE.554":
    "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av profilerade överläggsplattor e d av metall",
  "JTE.58":
    "Diverse kompletteringar av plan plåt till yttervägg, mur, bjälklag e d",
  "JTE.581": "Diverse kompletteringar av plan plåt till yttervägg, mur e d",
  "JTE.5811":
    "Skyddsbeslag av plan plåt till yttervägg, mur e d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.5812":
    "Beslag av plan plåt med fläns till sarg e d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTE.582": "Diverse kompletteringar av plan plåt till bjälklag e d inomhus",
  "JTE.5821":
    "Beslag av plan plåt med fläns till tätskiktsmatta, takduk e d i bjälklag inomhus",
  "JTE.583": "Diverse kompletteringar av plan plåt till ytterbjälklag",
  "JTE.5831":
    "Skyddsbeslag av plan plåt vid tätskiktsmatta, takduk e d i ytterbjälklag",
  "JTE.5832":
    "Beslag av plan plåt med fläns till sarg e d vid tätskiktsmatta, takduk e d i ytterbjälklag",
  "JTE.8": "Kompletteringar för takavvattning",
  "JTE.81": "Hängrännor",
  "JTE.811": "Halvrunda hängrännor",
  "JTE.812": "Rektangulära hängrännor",
  "JTE.813": "Skyddsbeslag vid hängrännor",
  "JTE.8131": "Längsgående skyddsbeslag vid hängrännor",
  "JTE.8132": "Gavelbeslag vid hängrännor",
  "JTE.8133": "Överspolningsskydd vid hängrännor",
  "JTE.82": "Stuprör",
  "JTE.821": "Utvändiga stuprör",
  "JTE.8211": "Cirkulära stuprör",
  "JTE.8212": "Rektangulära stuprör",
  "JTE.822": "Stuprörsanslutning till dagvattenledning",
  "JTE.823": "Stuprörsutkastare",
  "JTE.8231": "Slitplåtar vid stuprörsutkastare",
  "JTE.83": "Rännutlopp",
  "JTE.831": "Rännutlopp till utvändiga stuprör",
  "JTE.832": "Takbrunnar till invändiga stuprör",
  "JTE.84": "Bräddavlopp",
  JTG: "TAKTÄCKNINGAR, VÄGGBEKLÄDNADER, KOMPLETTERINGAR M M AV PLAN TITANZINKPLÅT FÖR HUS",
  "JTG.1": "Taktäckningar och väggbeklädnader av plan plåt",
  "JTG.13": "Taktäckningar av plan plåt med lutning minst 1:10 (5,7°)",
  "JTG.132": "Dubbelfalsade taktäckningar av plan plåt",
  "JTG.1321": "Dubbelfalsade skivtäckningar av plan plåt",
  "JTG.1322": "Dubbelfalsade bandtäckningar av plan plåt",
  "JTG.14": "Taktäckningar av plan plåt med lutning minst 1:3 (18,4°)",
  "JTG.143": "Enkelfalsade taktäckningar av plan plåt",
  "JTG.1431": "Enkelfalsade skivtäckningar av plan plåt",
  "JTG.145": "Taktäckningar av plan plåt utförd som fjälltäckning",
  "JTG.15": "Väggbeklädnader av plan plåt",
  "JTG.152": "Dubbelfalsade väggbeklädnader av plan plåt",
  "JTG.1521": "Dubbelfalsade skivbeklädnader av plan plåt",
  "JTG.1522": "Dubbelfalsade bandbeklädnader av plan plåt",
  "JTG.153": "Enkelfalsade väggbeklädnader av plan plåt",
  "JTG.1531": "Enkelfalsade skivbeklädnader av plan plåt",
  "JTG.155": "Väggbeklädnader av plan plåt utförda som fjälltäckning",
  "JTG.2": "Kompletteringar av plåt vid taktäckning av plan plåt",
  "JTG.21":
    "Fotrännor av plåt vid taktäckning av plan plåt och lutning minst 1:1,7 (30°)",
  "JTG.211": "Fotrännor av plåt vid falsat skivtäckt tak",
  "JTG.2113": "Fotrännor av plåt med stomme vid falsat skivtäckt tak",
  "JTG.212": "Fotrännor av plåt vid falsat bandtäckt tak",
  "JTG.2123": "Fotrännor av plåt med stomme vid falsat bandtäckt tak",
  "JTG.214": "Fotrännor av plåt i bandformat vid taktäckning av plan plåt",
  "JTG.22": "Vinkelrännor av plåt vid taktäckning av plan plåt",
  "JTG.221": "Vinkelrännor av plåt vid falsat skivtäckt tak",
  "JTG.222": "Vinkelrännor av plåt vid falsat bandtäckt tak",
  "JTG.2221": "Vinkelrännor av plåt i skivformat vid falsat bandtäckt tak",
  "JTG.2222": "Vinkelrännor av plåt i bandformat vid falsat bandtäckt tak",
  "JTG.23": "Ränndalar av plåt vid taktäckning av plan plåt",
  "JTG.231": "Ränndalar av plåt med uppbyggt fall vid taktäckning av plan plåt",
  "JTG.2311": "Ränndalar av plåt med uppbyggt fall vid falsat skivtäckt tak",
  "JTG.2312": "Ränndalar av plåt med uppbyggt fall vid falsat bandtäckt tak",
  "JTG.2313": "Ränndalar av plåt med uppbyggt fall vid svetsat bandtäckt tak",
  "JTG.233": "Försänkta ränndalar av plåt vid taktäckning av plan plåt",
  "JTG.2331": "Försänkta ränndalar av plåt vid falsat skivtäckt tak",
  "JTG.2332": "Försänkta ränndalar av plåt vid falsat bandtäckt tak",
  "JTG.24": "Gesimsrännor av plåt vid taktäckning av plan plåt",
  "JTG.241": "Gesimsrännor av plåt vid falsat skivtäckt tak",
  "JTG.242": "Gesimsrännor av plåt vid falsat bandtäckt tak",
  "JTG.244": "Gesimsrännor av plåt i bandformat vid taktäckning av plan plåt",
  "JTG.25": "Ståndskivor och hängskivor vid taktäckning av plan plåt",
  "JTG.251": "Ståndskivor vid taktäckning av plan plåt",
  "JTG.2511": "Ståndskivor vid falsat skivtäckt tak",
  "JTG.2512": "Ståndskivor vid falsat bandtäckt tak",
  "JTG.252": "Hängskivor vid taktäckning av plan plåt",
  "JTG.2521": "Hängskivor vid falsat skivtäckt tak",
  "JTG.2522": "Hängskivor vid falsat bandtäckt tak",
  "JTG.26": "Stosar till rör, takluckor m m vid taktäckning av plan plåt",
  "JTG.261": "Stosar till rör e d vid taktäckning av plan plåt",
  "JTG.2611": "Stosar till helbeslagning av rör vid taktäckning av plan plåt",
  "JTG.2612": "Ankantade stosar till rör e d vid taktäckning av plan plåt",
  "JTG.262": "Beslagning av takluckor vid taktäckning av plan plåt",
  "JTG.2621": "Beslagning av takluckor vid falsat skivtäckt tak",
  "JTG.2622": "Beslagning av takluckor vid falsat bandtäckt tak",
  "JTG.263": "Takfönster vid taktäckning av plan plåt",
  "JTG.2631": "Takfönster vid falsat skivtäckt tak",
  "JTG.2632": "Takfönster vid falsat bandtäckt tak",
  "JTG.264":
    "Beslagning av förtillverkade takfönster vid taktäckning av plan plåt",
  "JTG.2641":
    "Beslagning av förtillverkade takfönster vid falsat skivtäckt tak",
  "JTG.2642":
    "Beslagning av förtillverkade takfönster vid falsat bandtäckt tak",
  "JTG.27":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av plan plåt",
  "JTG.271": "Helbeslagning av skorstenar vid taktäckning av plan plåt",
  "JTG.2711": "Helbeslagning av skorstenar vid falsat skivtäckt tak",
  "JTG.2712": "Helbeslagning av skorstenar vid falsat bandtäckt tak",
  "JTG.272":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid taktäckning av plan plåt",
  "JTG.2721":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid falsat skivtäckt tak",
  "JTG.2722":
    "Beslagning av skorstenar med kringtäckning och överbeslag vid falsat bandtäckt tak",
  "JTG.273": "Helbeslagning av takkupor o d vid taktäckning av plan plåt",
  "JTG.274":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av plan plåt",
  "JTG.2741":
    "Helbeslagning av murkrön, brandväggar o d vid falsat skivtäckt tak",
  "JTG.2742":
    "Helbeslagning av murkrön, brandväggar o d vid falsat bandtäckt tak",
  "JTG.275":
    "Beslagning av genomföringar för brandgasventilatorer o d vid taktäckning av plan plåt",
  "JTG.2751":
    "Beslagning av genomföringar för brandgasventilatorer o d vid falsat skivtäckt tak",
  "JTG.2752":
    "Beslagning av genomföringar för brandgasventilatorer o d vid falsat bandtäckt tak",
  "JTG.278":
    "Beslagning av diverse takuppbyggnader o d vid taktäckning av plan plåt",
  "JTG.2781":
    "Helbeslagning av diverse takuppbyggnader o d vid falsat skivtäckt tak",
  "JTG.2782":
    "Helbeslagning av diverse takuppbyggnader o d vid falsat bandtäckt tak",
  "JTG.3":
    "Kompletteringar av plan plåt vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.31": "Fotplåtar vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.311": "Fotplåtar vid taktäckning av tätskiktsmatta",
  "JTG.3111":
    "Fotplåtar med anslutning till hängränna vid taktäckning av tätskiktsmatta",
  "JTG.3112":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av tätskiktsmatta",
  "JTG.312": "Fotplåtar vid taktäckning av takduk",
  "JTG.3121":
    "Fotplåtar med anslutning till hängränna vid taktäckning av takduk",
  "JTG.3122":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av takduk",
  "JTG.34":
    "Gesimsrännor av plåt vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.341": "Gesimsrännor av plåt vid taktäckning av tätskiktsmatta",
  "JTG.342": "Gesimsrännor av plåt vid taktäckning av takduk",
  "JTG.35":
    "Ståndskivor och hängskivor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.351":
    "Ståndskivor utan vinge vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3511": "Ståndskivor utan vinge vid taktäckning av tätskiktsmatta",
  "JTG.3512": "Ståndskivor utan vinge vid taktäckning av takduk",
  "JTG.352": "Hängskivor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3521": "Hängskivor vid taktäckning av tätskiktsmatta",
  "JTG.3522": "Hängskivor vid taktäckning av takduk",
  "JTG.36":
    "Stosar till rör, takluckor m m vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.361": "Stosar till rör e d vid taktäckning av tätskiktsmatta",
  "JTG.3611":
    "Stosar till helbeslagning av rör vid taktäckning av tätskiktsmatta",
  "JTG.3612": "Ankantade stosar till rör e d vid taktäckning av tätskiktsmatta",
  "JTG.362": "Stosar till rör e d vid taktäckning av takduk",
  "JTG.3621": "Stosar till helbeslagning av rör vid taktäckning av takduk",
  "JTG.3622": "Ankantade stosar till rör e d vid taktäckning av takduk",
  "JTG.363":
    "Beslagning av takluckor vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3631": "Beslagning av takluckor vid taktäckning av tätskiktsmatta",
  "JTG.3632": "Beslagning av takluckor vid taktäckning av takduk",
  "JTG.364": "Takfönster vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3641": "Takfönster vid taktäckning av tätskiktsmatta",
  "JTG.3642": "Takfönster vid taktäckning av takduk",
  "JTG.365":
    "Beslagning av förtillverkade takfönster vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3651":
    "Beslagning av förtillverkade takfönster vid taktäckning av tätskiktsmatta",
  "JTG.3652":
    "Beslagning av förtillverkade takfönster vid taktäckning av takduk",
  "JTG.37":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.371":
    "Helbeslagning av skorstenar vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3711": "Helbeslagning av skorstenar vid taktäckning av tätskiktsmatta",
  "JTG.3712": "Helbeslagning av skorstenar vid taktäckning av takduk",
  "JTG.372":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3721":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av tätskiktsmatta",
  "JTG.3722":
    "Beslagning av skorstenar med neder- och överbeslag vid taktäckning av takduk",
  "JTG.373":
    "Helbeslagning av takkupor o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3731": "Helbeslagning av takkupor o d vid taktäckning av tätskiktsmatta",
  "JTG.3732": "Helbeslagning av takkupor o d vid taktäckning av takduk",
  "JTG.374":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3741":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av tätskiktsmatta",
  "JTG.3742":
    "Helbeslagning av murkrön, brandväggar o d vid taktäckning av takduk",
  "JTG.375":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3751":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av tätskiktsmatta",
  "JTG.3752":
    "Beslagning av takuppbyggnader för brandgasventilatorer o d vid taktäckning av takduk",
  "JTG.376":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3761":
    "Täcklister, beslag över rörelsefogar o d vid taktäckning av tätskiktsmatta",
  "JTG.378":
    "Beslagning av diverse takuppbyggnader o d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.3781":
    "Helbeslagning av diverse takuppbyggnader o d vid taktäckning av tätskiktsmatta",
  "JTG.3782":
    "Helbeslagning av diverse takuppbyggnader o d vid taktäckning av takduk",
  "JTG.4":
    "Kompletteringar av plan plåt vid taktäckning av överläggsplattor e d",
  "JTG.41": "Fotplåtar och fotrännor vid taktäckning av överläggsplattor e d",
  "JTG.411":
    "Fotplåtar med anslutning till hängränna vid taktäckning av överläggsplattor e d",
  "JTG.4111":
    "Fotplåtar med anslutning till hängränna vid taktäckning av skiffer",
  "JTG.4112":
    "Fotplåtar med anslutning till hängränna vid taktäckning av betongpannor",
  "JTG.4113":
    "Fotplåtar med anslutning till hängränna vid taktäckning av tegelpannor",
  "JTG.4114":
    "Fotplåtar med anslutning till hängränna vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4115":
    "Fotplåtar med anslutning till hängränna vid taktäckning av profilerade överläggsplattor av fiberbetong",
  "JTG.4116":
    "Fotplåtar med anslutning till hängränna vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.412":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av överläggsplattor e d",
  "JTG.4121":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av skiffer",
  "JTG.4122":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av betongpannor",
  "JTG.4123":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av tegelpannor",
  "JTG.4124":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4125":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av profilerade överläggsplattor av fiberbetong",
  "JTG.4126":
    "Fotplåtar med anslutning till gesimsränna vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.413":
    "Fotrännor av plåt i skivformat vid taktäckning av överläggsplattor e d",
  "JTG.4133":
    "Fotrännor av plåt i skivformat med stomme vid taktäckning av överläggsplattor e d",
  "JTG.4134":
    "Fotrännor av plåt i bandformat vid taktäckning av överläggsplattor e d",
  "JTG.42": "Vinkelrännor av plåt vid taktäckning av överläggsplattor e d",
  "JTG.421":
    "Vinkelrännor av plåt i skivformat vid taktäckning av överläggsplattor e d",
  "JTG.4211": "Vinkelrännor av plåt i skivformat vid taktäckning av skiffer",
  "JTG.4212":
    "Vinkelrännor av plåt i skivformat vid taktäckning av betongpannor",
  "JTG.4213":
    "Vinkelrännor av plåt i skivformat vid taktäckning av tegelpannor",
  "JTG.4214":
    "Vinkelrännor av plåt i skivformat vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4215":
    "Vinkelrännor av plåt i skivformat vid taktäckning överläggsplattor av fiberbetong",
  "JTG.4216":
    "Vinkelrännor av plåt i skivformat vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.422":
    "Vinkelrännor av plåt i bandformat vid taktäckning av överläggsplattor e d",
  "JTG.4221": "Vinkelrännor av plåt i bandformat vid taktäckning av skiffer",
  "JTG.4222":
    "Vinkelrännor av plåt i bandformat vid taktäckning av betongpannor",
  "JTG.4223":
    "Vinkelrännor av plåt i bandformat vid taktäckning av tegelpannor",
  "JTG.4224":
    "Vinkelrännor av plåt i bandformat vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4225":
    "Vinkelrännor av plåt i bandformat vid taktäckning överläggsplattor av fiberbetong",
  "JTG.4226":
    "Vinkelrännor av plåt i bandformat vid taktäckning vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.43": "Ränndalar av plåt vid taktäckning av överläggsplattor e d",
  "JTG.431":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av överläggsplattor e d",
  "JTG.4311": "Ränndalar av plåt med uppbyggt fall vid taktäckning av skiffer",
  "JTG.4312":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av betongpannor",
  "JTG.4313":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av tegelpannor",
  "JTG.4314":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4315":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning överläggsplattor av fiberbetong",
  "JTG.4316":
    "Ränndalar av plåt med uppbyggt fall vid taktäckning överläggsplattor av cementbundet material",
  "JTG.433":
    "Försänkta ränndalar av plåt vid taktäckning av överläggsplattor e d",
  "JTG.4331": "Försänkta ränndalar av plåt vid taktäckning av skiffer",
  "JTG.4332": "Försänkta ränndalar av plåt vid taktäckning av betongpannor",
  "JTG.4333": "Försänkta ränndalar av plåt vid taktäckning av tegelpannor",
  "JTG.4334":
    "Försänkta ränndalar av plåt vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4335":
    "Försänkta ränndalar av plåt vid taktäckning överläggsplattor av fiberbetong",
  "JTG.4336":
    "Försänkta ränndalar av plåt vid taktäckning överläggsplattor av cementbundet material",
  "JTG.44": "Gesimsrännor av plåt vid taktäckning av överläggsplattor e d",
  "JTG.441": "Gesimsrännor av plåt vid taktäckning av skiffer",
  "JTG.442": "Gesimsrännor av plåt vid taktäckning av betongpannor",
  "JTG.443": "Gesimsrännor av plåt vid taktäckning av tegelpannor",
  "JTG.444":
    "Gesimsrännor av plåt vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.445":
    "Gesimsrännor av plåt vid taktäckning överläggsskivor av fiberbetong",
  "JTG.446":
    "Gesimsrännor av plåt vid taktäckning överläggsplattor av cementbundet material",
  "JTG.45":
    "Ståndskivor och hängskivor samt nockbeslag vid taktäckning av överläggsplattor e d",
  "JTG.451": "Ståndskivor med vinge vid taktäckning av överläggsplattor e d",
  "JTG.4511": "Ståndskivor med vinge vid taktäckning av skiffer",
  "JTG.4512": "Ståndskivor med vinge vid taktäckning av betongpannor",
  "JTG.4513": "Ståndskivor med vinge vid taktäckning av tegelpannor",
  "JTG.4514":
    "Ståndskivor med vinge vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4515":
    "Ståndskivor med vinge vid taktäckning överläggsplattor av fiberbetong",
  "JTG.4516":
    "Ståndskivor med vinge vid taktäckning överläggsplattor av cementbundet material",
  "JTG.452":
    "Hängskivor och gavelskivor vid taktäckning av överläggsplattor e d",
  "JTG.4521": "Hängskivor vid taktäckning av överläggsplattor e d",
  "JTG.4522":
    "Hängskivor och gavelskivor med vinge vid taktäckning av överläggsplattor e d",
  "JTG.453": "Nockbeslag vid taktäckning av överläggsplattor e d",
  "JTG.4531": "Nockbeslag vid taktäckning av skiffer",
  "JTG.4532": "Nockbeslag vid taktäckning av betongpannor",
  "JTG.4533": "Nockbeslag vid taktäckning av tegelpannor",
  "JTG.4534":
    "Nockbeslag vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4535": "Nockbeslag vid taktäckning överläggplattor av fiberbetong",
  "JTG.4536":
    "Nockbeslag vid taktäckning överläggsplattor av cementbundet material",
  "JTG.46":
    "Stosar till rör, takluckor m m vid taktäckning av överläggsplattor e d",
  "JTG.461": "Stosar till rör e d vid taktäckning av överläggsplattor e d",
  "JTG.4611": "Stosar till rör e d vid taktäckning av skiffer",
  "JTG.4612": "Stosar till rör e d vid taktäckning av betongpannor",
  "JTG.4613": "Stosar till rör e d vid taktäckning av tegelpannor",
  "JTG.4614":
    "Stosar till rör e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4615":
    "Stosar till rör e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTG.4616":
    "Stosar till rör e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.462": "Beslagning av takluckor vid taktäckning av överläggsplattor e d",
  "JTG.4621": "Beslagning av takluckor vid taktäckning av skiffer",
  "JTG.4622": "Beslagning av takluckor vid taktäckning av betongpannor",
  "JTG.4623": "Beslagning av takluckor vid taktäckning av tegelpannor",
  "JTG.4624":
    "Beslagning av takluckor vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4625":
    "Beslagning av takluckor vid taktäckning av överläggsplattor av fiberbetong",
  "JTG.4626":
    "Beslagning av takluckor vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.463": "Takfönster vid taktäckning av överläggsplattor e d",
  "JTG.4631": "Takfönster vid taktäckning av skiffer",
  "JTG.4632": "Takfönster vid taktäckning av betongpannor",
  "JTG.4633": "Takfönster vid taktäckning av tegelpannor",
  "JTG.4634":
    "Takfönster vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4635": "Takfönster vid taktäckning av överläggsplattor av fiberbetong",
  "JTG.4636":
    "Takfönster vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.464":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor e d",
  "JTG.4641":
    "Beslagning av förtillverkade takfönster vid taktäckning av skiffer",
  "JTG.4642":
    "Beslagning av förtillverkade takfönster vid taktäckning av betongpannor",
  "JTG.4643":
    "Beslagning av förtillverkade takfönster vid taktäckning av tegelpannor",
  "JTG.4644":
    "Beslagning av förtillverkade takfönster vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4645":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor av fiberbetong",
  "JTG.4646":
    "Beslagning av förtillverkade takfönster vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.47":
    "Beslagning av skorstenar och takuppbyggnader vid taktäckning av överläggsplattor e d",
  "JTG.471":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor e d",
  "JTG.4711": "Helbeslagning av skorstenar vid taktäckning av skiffer",
  "JTG.4712": "Helbeslagning av skorstenar vid taktäckning av betongpannor",
  "JTG.4713": "Helbeslagning av skorstenar vid taktäckning av tegelpannor",
  "JTG.4714":
    "Helbeslagning av skorstenar vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4715":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor av fiberbetong",
  "JTG.4716":
    "Helbeslagning av skorstenar vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.472":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor e d",
  "JTG.4721":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av skiffer",
  "JTG.4722":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av betongpannor",
  "JTG.4723":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av tegelpannor",
  "JTG.4724":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4725":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor av fiberbetong",
  "JTG.4726":
    "Beslagning av skorstenar med under-, neder- och överbeslag vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.473":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor e d",
  "JTG.4731": "Helbeslagning av takkupor e d vid taktäckning av skiffer",
  "JTG.4732": "Helbeslagning av takkupor e d vid taktäckning av betongpannor",
  "JTG.4733": "Helbeslagning av takkupor e d vid taktäckning av tegelpannor",
  "JTG.4734":
    "Helbeslagning av takkupor e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4735":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTG.4736":
    "Helbeslagning av takkupor e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.474":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor e d",
  "JTG.4741":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av skiffer",
  "JTG.4742":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av betongpannor",
  "JTG.4743":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av tegelpannor",
  "JTG.4744":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4745":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTG.4746":
    "Helbeslagning av murkrön, brandväggar e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.475":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor e d",
  "JTG.4751":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av skiffer",
  "JTG.4752":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av betongpannor",
  "JTG.4753":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av tegelpannor",
  "JTG.4754":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4755":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTG.4756":
    "Beslagning av genomföringar för brandgasventilatorer e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.476":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor e d",
  "JTG.4761":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av skiffer",
  "JTG.4762":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av betongpannor",
  "JTG.4763":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av tegelpannor",
  "JTG.4764":
    "Täcklister, beslag över rörelsefogar vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4765":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor av fiberbetong",
  "JTG.4766":
    "Täcklister, beslag över rörelsefogar e d vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.478":
    "Beslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor e d",
  "JTG.4781":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av skiffer",
  "JTG.4782":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av betongpannor",
  "JTG.4783":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av tegelpannor",
  "JTG.4784":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av profilerade överläggsplattor e d av metall",
  "JTG.4785":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor av fiberbetong",
  "JTG.4786":
    "Helbeslagning av diverse takuppbyggnader m m vid taktäckning av överläggsplattor av cementbundet material",
  "JTG.5":
    "Kompletteringar av plan plåt till yttervägg, mur, innerbjälklag e d",
  "JTG.52": "Fönsterbleck, droppbleck, listbeslag e d",
  "JTG.521": "Fönsterbleck",
  "JTG.522": "Droppbleck i väggöppningar",
  "JTG.5221": "Droppbleck vid fönster och dörröppningar",
  "JTG.5222": "Droppbleck vid ventilationsgaller e d",
  "JTG.523": "Droppbleck på balkong e d",
  "JTG.524": "Tröskelbeslag",
  "JTG.525": "Listbeslag, beslag över portomfattning e d",
  "JTG.526": "Skyddsbeslag på vägg bakom vattenkupa, bräddavlopp e d",
  "JTG.527": "Sockelbeslag",
  "JTG.53": "Beslagning av muröversida utan anslutning till taktäckning",
  "JTG.54": "Beslagning av skärmtak",
  "JTG.55": "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av plåt",
  "JTG.551":
    "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av plan plåt",
  "JTG.554":
    "Anslutningar av ytterväggsgaller e d vid väggbeklädnad av profilerade överläggsplattor e d av metall",
  "JTG.58":
    "Diverse kompletteringar av plan plåt till yttervägg, mur, bjälklag e d",
  "JTG.581": "Diverse kompletteringar av plan plåt till yttervägg, mur e d",
  "JTG.5811":
    "Skyddsbeslag av plan plåt till yttervägg, mur e d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.5812":
    "Beslag av plan plåt med fläns till sarg e d vid taktäckning av tätskiktsmatta, takduk e d",
  "JTG.582": "Diverse kompletteringar av plan plåt till bjälklag e d inomhus",
  "JTG.5821":
    "Beslag av plan plåt med fläns till tätskiktsmatta, takduk e d i bjälklag inomhus",
  "JTG.583": "Diverse kompletteringar av plan plåt till ytterbjälklag",
  "JTG.5831":
    "Skyddsbeslag av plan plåt vid tätskiktsmatta, takduk e d i ytterbjälklag",
  "JTG.5832":
    "Beslag av plan plåt med fläns till sarg e d vid tätskiktsmatta, takduk e d i ytterbjälklag",
  "JTG.8": "Kompletteringar för takavvattning",
  "JTG.81": "Hängrännor",
  "JTG.811": "Halvrunda hängrännor",
  "JTG.812": "Rektangulära hängrännor",
  "JTG.813": "Skyddsbeslag vid hängrännor",
  "JTG.8131": "Längsgående skyddsbeslag vid hängrännor",
  "JTG.8132": "Gavelbeslag vid hängrännor",
  "JTG.8133": "Överspolningsskydd vid hängrännor",
  "JTG.82": "Stuprör",
  "JTG.821": "Utvändiga stuprör",
  "JTG.8211": "Cirkulära stuprör",
  "JTG.8212": "Rektangulära stuprör",
  "JTG.822": "Stuprörsanslutning till dagvattenledning",
  "JTG.823": "Stuprörsutkastare",
  "JTG.8231": "Slitplåtar vid stuprörsutkastare",
  "JTG.83": "Rännutlopp",
  "JTG.831": "Rännutlopp till utvändiga stuprör",
  "JTG.832": "Takbrunnar till invändiga stuprör",
  "JTG.84": "Bräddavlopp",
  JTH: "TAKTÄCKNINGAR, VÄGGBEKLÄDNADER, KOMPLETTERINGAR M M AV PLAN TITANPLÅT FÖR HUS",
  JTZ: "TAKTÄCKNINGAR, VÄGGBEKLÄDNADER, KOMPLETTERINGAR M M AV PLAN PLÅT AV ÖVRIGA METALLER FÖR HUS",
  JU: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER AV ÖVERLÄGGSPLATTOR E D AV NATURSTEN, CEMENTBUNDET MATERIAL, BRÄND LERA, TRÄ M M FÖR HUS",
  JUB: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER AV ÖVERLÄGGSPLATTOR AV NATURSTEN",
  "JUB.2": "Taktäckningar och väggbeklädnader av skiffer",
  "JUB.21": "Taktäckningar av skiffer",
  "JUB.211": "Taktäckningar av skiffer med lutning minst 1:4 (14°)",
  "JUB.212": "Taktäckningar av skiffer med lutning minst 1:3 (18°)",
  "JUB.213": "Taktäckningar av skiffer med lutning minst 1:2,5 (22°)",
  "JUB.214": "Taktäckningar av skiffer med lutning minst 1:2 (27°)",
  "JUB.22": "Väggbeklädnader av skiffer",
  JUC: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER AV ÖVERLÄGGSPLATTOR E D AV CEMENTBUNDET MATERIAL",
  "JUC.1": "Taktäckningar av betongpannor",
  "JUC.14": "Taktäckningar av betongpannor med lutning minst 1:4 (14°)",
  "JUC.15": "Taktäckningar av betongpannor med lutning minst 1:2,5 (22°)",
  "JUC.2": "Taktäckningar av profilerade överläggsplattor e d av fiberbetong",
  "JUC.24":
    "Taktäckningar av profilerade överläggsplattor av fiberbetong med lutning minst 1:4 (14°)",
  "JUC.25":
    "Taktäckningar av profilerade överläggsplattor av fiberbetong med lutning minst 1:2,5 (22°)",
  "JUC.3": "Väggbeklädnader av profilerade överläggsplattor e d av fiberbetong",
  "JUC.8":
    "Diverse taktäckningar och väggbeklädnader av överläggsplattor e d av cementbundet material",
  JUE: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER AV ÖVERLÄGGSPLATTOR AV BRÄND LERA",
  "JUE.1": "Taktäckningar av tegelpannor",
  "JUE.14": "Taktäckningar av tegelpannor med lutning minst 1:4 (14°)",
  "JUE.15": "Taktäckningar av tegelpannor med lutning minst 1:2,5 (22°)",
  JUH: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER AV ÖVERLÄGGSPLATTOR E D AV TRÄ",
  "JUH.1": "Taktäckningar och väggbeklädnader av träspån",
  "JUH.11": "Taktäckningar av träspån",
  "JUH.115": "Taktäckningar av träspån med lutning minst 1:2,5 (22°)",
  "JUH.116": "Taktäckningar av träspån med lutning minst 1:1 (45°)",
  "JUH.14": "Väggbeklädnader av träspån",
  JUJ: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER AV ÖVERLÄGGSPLATTOR E D AV PLAST",
  "JUJ.2":
    "Taktäckningar och väggbeklädnader av profilerade överläggsplattor e d av plast",
  "JUJ.21": "Taktäckningar av profilerade överläggsplattor e d av plast",
  "JUJ.214":
    "Taktäckningar av profilerade överläggsplattor e d av plast med lutning minst 1:4 (14°)",
  "JUJ.24": "Väggbeklädnader av profilerade överläggsplattor e d av plast",
  JUK: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER AV ÖVERLÄGGSPLATTOR E D AV ASFALTBASERAT MATERIAL",
  "JUK.1":
    "Taktäckningar och väggbeklädnader av plana överläggsplattor e d av asfaltbaserat material",
  "JUK.11":
    "Taktäckningar av plana överläggsplattor e d av asfaltbaserat material",
  "JUK.114":
    "Taktäckningar av plana överläggsplattor e d av asfaltbaserat material med lutning minst 1:4 (14°)",
  "JUK.2":
    "Taktäckningar och väggbeklädnader av profilerade överläggsplattor e d av asfaltbaserat material",
  "JUK.21":
    "Taktäckningar av profilerade överläggsplattor e d av asfaltbaserat material",
  JUL: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER AV ÖVERLÄGGSPLATTOR E D AV GLAS",
  "JUL.1": "Taktäckningar av glaspannor",
  "JUL.14": "Taktäckningar av glaspannor med lutning minst 1:4 (14°)",
  "JUL.15": "Taktäckningar av glaspannor med lutning minst 1:2,5 (22°)",
  JUY: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER AV ÖVERLÄGGSPLATTOR E D AV DIVERSE MATERIAL UTOM METALL FÖR HUS",
  JUZ: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER AV ÖVERLÄGGSPLATTOR E D AV ÖVRIGT MATERIAL UTOM METALL FÖR HUS",
  JV: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER M M AV ÖVERLÄGGSPLATTOR E D AV PLÅT FÖR HUS",
  JVB: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER M M AV ÖVERLÄGGSPLATTOR E D AV METALLISERAD STÅLPLÅT FÖR HUS",
  "JVB.1":
    "Taktäckningar och väggbeklädnader av överläggsplattor e d av plan plåt",
  "JVB.11": "Taktäckningar av överläggsplattor e d av plan plåt",
  "JVB.14": "Väggbeklädnader av överläggsplattor e d av plan plåt",
  "JVB.2":
    "Taktäckningar och väggbeklädnader m m av överläggsplattor e d av profilerad plåt",
  "JVB.21": "Taktäckningar av överläggsplattor e d av profilerad plåt",
  "JVB.212":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:10 (5 2/3°)",
  "JVB.213":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:7 (8°)",
  "JVB.214":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:4 (14°)",
  "JVB.22": "Taktäckningar av överläggsplattor e d av specialprofilerad plåt",
  "JVB.224":
    "Taktäckningar av överläggsplattor e d av specialprofilerad plåt med lutning minst 1:4 (14°)",
  "JVB.24": "Väggbeklädnader av överläggsplattor e d av profilerad plåt",
  "JVB.25": "Väggbeklädnader av överläggsplattor e d av specialprofilerad plåt",
  "JVB.27":
    "Överläggsskivor av profilerad plåt för stommar och stomkompletteringar",
  "JVB.273": "Väggar, skärmar o d av profilerad plåt",
  "JVB.2731": "Ytterväggar av profilerad plåt",
  "JVB.2732": "Innerväggar av profilerad plåt",
  "JVB.2733": "Skärmar av profilerad plåt",
  "JVB.275": "Bärlag, yttertak o d av profilerad plåt",
  "JVB.2751": "Bärlag av profilerad plåt",
  "JVB.2752": "Yttertak av profilerad plåt",
  "JVB.277": "Detaljer till stomme eller stomkomplettering av profilerad plåt",
  "JVB.2771": "Kompletteringar till vägg, skärm e d av profilerad plåt",
  "JVB.2772": "Kompletteringar till bärlag e d av profilerad plåt",
  "JVB.2773": "Kompletteringar till yttertak av profilerad plåt",
  JVC: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER M M AV ÖVERLÄGGSPLATTOR E D AV PLÅT AV ROSTFRITT STÅL FÖR HUS",
  "JVC.1":
    "Taktäckningar och väggbeklädnader av överläggsplattor e d av plan plåt",
  "JVC.11": "Taktäckningar av överläggsplattor e d av plan plåt",
  "JVC.14": "Väggbeklädnader av överläggsplattor e d av plan plåt",
  "JVC.2":
    "Taktäckningar och väggbeklädnader m m av överläggsplattor e d av profilerad plåt",
  "JVC.21": "Taktäckningar av överläggsplattor e d av profilerad plåt",
  "JVC.212":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:10 (5 2/3°)",
  "JVC.213":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:7 (8°)",
  "JVC.214":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:4 (14°)",
  "JVC.22": "Taktäckningar av överläggsplattor e d av specialprofilerad plåt",
  "JVC.224":
    "Taktäckningar av överläggsplattor e d av specialprofilerad plåt med lutning minst 1:4 (14°)",
  "JVC.24": "Väggbeklädnader av överläggsplattor e d av profilerad plåt",
  "JVC.25": "Väggbeklädnader av överläggsplattor e d av specialprofilerad plåt",
  "JVC.27":
    "Överläggsskivor av profilerad plåt för stommar och stomkompletteringar",
  "JVC.273": "Väggar, skärmar o d av profilerad plåt",
  "JVC.2731": "Ytterväggar av profilerad plåt",
  "JVC.2732": "Innerväggar av profilerad plåt",
  "JVC.2733": "Skärmar av profilerad plåt",
  "JVC.275": "Bärlag, yttertak o d av profilerad plåt",
  "JVC.2751": "Bärlag av profilerad plåt",
  "JVC.2752": "Yttertak av profilerad plåt",
  "JVC.277": "Detaljer till stomme eller stomkomplettering av profilerad plåt",
  "JVC.2771": "Kompletteringar till vägg, skärm e d av profilerad plåt",
  "JVC.2772": "Kompletteringar till bärlag e d av profilerad plåt",
  "JVC.2773": "Kompletteringar till yttertak av profilerad plåt",
  JVD: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER M M AV ÖVERLÄGGSPLATTOR E D AV ALUMINIUMPLÅT FÖR HUS",
  "JVD.1":
    "Taktäckningar och väggbeklädnader av överläggsplattor e d av plan plåt",
  "JVD.11": "Taktäckningar av överläggsplattor e d av plan plåt",
  "JVD.14": "Väggbeklädnader av överläggsplattor e d av plan plåt",
  "JVD.2":
    "Taktäckningar och väggbeklädnader m m av överläggsplattor e d av profilerad plåt",
  "JVD.21": "Taktäckningar av överläggsplattor e d av profilerad plåt",
  "JVD.212":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:10 (5 2/3°)",
  "JVD.213":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:7 (8°)",
  "JVD.214":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:4 (14°)",
  "JVD.22": "Taktäckningar av överläggsplattor e d av specialprofilerad plåt",
  "JVD.224":
    "Taktäckningar av överläggsplattor e d av specialprofilerad plåt med lutning minst 1:4 (14°)",
  "JVD.24": "Väggbeklädnader av överläggsplattor e d av profilerad plåt",
  "JVD.25": "Väggbeklädnader av överläggsplattor e d av specialprofilerad plåt",
  "JVD.27":
    "Överläggsskivor av profilerad plåt för stommar och stomkompletteringar",
  "JVD.273": "Väggar, skärmar o d av profilerad plåt",
  "JVD.2731": "Ytterväggar av profilerad plåt",
  "JVD.2732": "Innerväggar av profilerad plåt",
  "JVD.2733": "Skärmar av profilerad plåt",
  "JVD.275": "Bärlag, yttertak o d av profilerad plåt",
  "JVD.2751": "Bärlag av profilerad plåt",
  "JVD.2752": "Yttertak av profilerad plåt",
  "JVD.277": "Detaljer till stomme eller stomkomplettering av profilerad plåt",
  "JVD.2771": "Kompletteringar till vägg, skärm e d av profilerad plåt",
  "JVD.2772": "Kompletteringar till bärlag e d av profilerad plåt",
  "JVD.2773": "Kompletteringar till yttertak av profilerad plåt",
  JVE: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER M M AV ÖVERLÄGGSPLATTOR E D AV KOPPARPLÅT FÖR HUS",
  "JVE.1":
    "Taktäckningar och väggbeklädnader av överläggsplattor e d av plan plåt",
  "JVE.11": "Taktäckningar av överläggsplattor e d av plan plåt",
  "JVE.14": "Väggbeklädnader av överläggsplattor e d av plan plåt",
  "JVE.2":
    "Taktäckningar och väggbeklädnader m m av överläggsplattor e d av profilerad plåt",
  "JVE.21": "Taktäckningar av överläggsplattor e d av profilerad plåt",
  "JVE.212":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:10 (5 2/3°)",
  "JVE.213":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:7 (8°)",
  "JVE.214":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:4 (14°)",
  "JVE.22": "Taktäckningar av överläggsplattor e d av specialprofilerad plåt",
  "JVE.224":
    "Taktäckningar av överläggsplattor e d av specialprofilerad plåt med lutning minst 1:4 (14°)",
  "JVE.24": "Väggbeklädnader av överläggsplattor e d av profilerad plåt",
  "JVE.25": "Väggbeklädnader av överläggsplattor e d av specialprofilerad plåt",
  "JVE.27":
    "Överläggsskivor av profilerad plåt för stommar och stomkompletteringar",
  "JVE.273": "Väggar, skärmar o d av profilerad plåt",
  "JVE.2731": "Ytterväggar av profilerad plåt",
  "JVE.2732": "Innerväggar av profilerad plåt",
  "JVE.2733": "Skärmar av profilerad plåt",
  "JVE.275": "Bärlag, yttertak o d av profilerad plåt",
  "JVE.2751": "Bärlag av profilerad plåt",
  "JVE.2752": "Yttertak av profilerad plåt",
  "JVE.277": "Detaljer till stomme eller stomkomplettering av profilerad plåt",
  "JVE.2771": "Kompletteringar till vägg, skärm e d av profilerad plåt",
  "JVE.2772": "Kompletteringar till bärlag e d av profilerad plåt",
  "JVE.2773": "Kompletteringar till yttertak av profilerad plåt",
  JVG: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER M M AV ÖVERLÄGGSPLATTOR E D AV TITANZINKPLÅT FÖR HUS",
  "JVG.1":
    "Taktäckningar och väggbeklädnader av överläggsplattor e d av plan plåt",
  "JVG.11": "Taktäckningar av överläggsplattor e d av plan plåt",
  "JVG.14": "Väggbeklädnader av överläggsplattor e d av plan plåt",
  "JVG.2":
    "Taktäckningar och väggbeklädnader m m av överläggsplattor e d av profilerad plåt",
  "JVG.21": "Taktäckningar av överläggsplattor e d av profilerad plåt",
  "JVG.212":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:10 (5 2/3°)",
  "JVG.213":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:7 (8°)",
  "JVG.214":
    "Taktäckningar av överläggsplattor e d av profilerad plåt med lutning minst 1:4 (14°)",
  "JVG.22": "Taktäckningar av överläggsplattor e d av specialprofilerad plåt",
  "JVG.224":
    "Taktäckningar av överläggsplattor e d av specialprofilerad plåt med lutning minst 1:4 (14°)",
  "JVG.24": "Väggbeklädnader av överläggsplattor e d av profilerad plåt",
  "JVG.25": "Väggbeklädnader av överläggsplattor e d av specialprofilerad plåt",
  "JVG.27":
    "Överläggsskivor av profilerad plåt för stommar och stomkompletteringar",
  "JVG.273": "Väggar, skärmar o d av profilerad plåt",
  "JVG.2731": "Ytterväggar av profilerad plåt",
  "JVG.2732": "Innerväggar av profilerad plåt",
  "JVG.2733": "Skärmar av profilerad plåt",
  "JVG.275": "Bärlag, yttertak o d av profilerad plåt",
  "JVG.2751": "Bärlag av profilerad plåt",
  "JVG.2752": "Yttertak av profilerad plåt",
  "JVG.277": "Detaljer till stomme eller stomkomplettering av profilerad plåt",
  "JVG.2771": "Kompletteringar till vägg, skärm e d av profilerad plåt",
  "JVG.2772": "Kompletteringar till bärlag e d av profilerad plåt",
  "JVG.2773": "Kompletteringar till yttertak av profilerad plåt",
  JVZ: "TAKTÄCKNINGAR OCH VÄGGBEKLÄDNADER M M AV ÖVERLÄGGSPLATTOR E D AV PLÅT AV ÖVRIGA METALLER FÖR HUS",
  K: "SKIKT AV SKIVOR",
  KA: "SKIKT AV SKIVOR AV SAMMANSATT TYP",
  KB: "SKIKT AV CEMENT-, KALCIUMSILIKAT- ELLER GIPSBASERADE SKIVOR E D",
  KBB: "SKIKT AV FIBERCEMENT- ELLER KALCIUMSILIKATSKIVOR",
  "KBB.1": "Skikt av fibercement- eller kalciumsilikatskivor utomhus",
  "KBB.11":
    "Skikt av fibercement- eller kalciumsilikatskivor i vägg, pelare e d utomhus",
  "KBB.111": "Skikt av fibercement- eller kalciumsilikatskivor som vindskydd",
  "KBB.112":
    "Skikt av fibercement- eller kalciumsilikatskivor som fasadmaterial",
  "KBB.12":
    "Skikt av fibercement- eller kalciumsilikatskivor i tak e d utomhus",
  "KBB.2": "Skikt av fibercement- eller kalciumsilikatskivor inomhus",
  "KBB.21":
    "Skikt av fibercement- eller kalciumsilikatskivor i vägg, pelare e d inomhus",
  "KBB.22":
    "Skikt av fibercement- eller kalciumsilikatskivor i innertakskonstruktion",
  "KBB.24": "Skikt av fibercement- eller kalciumsilikatskivor som blindbotten",
  KBC: "Skikt av gipsbaserade skivor",
  "KBC.3": "Skikt av kartongklädda gipsskivor",
  "KBC.31": "Skikt av kartongklädda gipsskivor utomhus",
  "KBC.311": "Skikt av kartongklädda gipsskivor i vägg, pelare e d utomhus",
  "KBC.3111": "Skikt av kartongklädda gipsskivor som vindskydd",
  "KBC.312": "Skikt av kartongklädda gipsskivor i tak e d utomhus",
  "KBC.32": "Skikt av kartongklädda gipsskivor inomhus",
  "KBC.321": "Skikt av kartongklädda gipsskivor i vägg, pelare e d inomhus",
  "KBC.3211":
    "Skikt av kartongklädda gipsskivor på reglar i vägg, pelare e d inomhus",
  "KBC.3212":
    "Skikt av kartongklädda gipsskivor på underlag av träskivor i vägg, pelare e d inomhus",
  "KBC.322": "Skikt av kartongklädda gipsskivor i innertakskonstruktion",
  "KBC.323": "Inklädnader av kartongklädda gipsskivor",
  "KBC.324": "Skikt av kartongklädda gipsskivor i undergolv",
  "KBC.4": "Skikt av gipsbaserade skivor med fiberarmering",
  "KBC.41": "Skikt av gipsbaserade skivor med glasfiberarmering",
  "KBC.411": "Skikt av gipsbaserade skivor med glasfiberarmering utomhus",
  "KBC.4111":
    "Skikt av gipsbaserade skivor med glasfiberarmering i vägg, pelare e d utomhus",
  "KBC.4112":
    "Skikt av gipsbaserade skivor med glasfiberarmering i tak e d utomhus",
  "KBC.412": "Skikt av gipsbaserade skivor med glasfiberarmering inomhus",
  "KBC.4121":
    "Skikt av gipsbaserade skivor med glasfiberarmering i vägg, pelare e d inomhus",
  "KBC.4122":
    "Skikt av gipsbaserade skivor med glasfiberarmering i innertakskonstruktion",
  "KBC.4123": "Skikt av gipsbaserade skivor med glasfiberarmering i undergolv",
  "KBC.42": "Skikt av gipsbaserade skivor med pappersfiberarmering",
  "KBC.422": "Skikt av gipsbaserade skivor med pappersfiberarmering inomhus",
  "KBC.4221":
    "Skikt av gipsbaserade skivor med pappersfiberarmering i vägg, pelare e d inomhus",
  "KBC.4222":
    "Skikt av gipsbaserade skivor med pappersfiberarmering i innertakskonstruktion",
  "KBC.4223": "Inklädnader av gipsbaserade skivor med pappersfiberarmering",
  "KBC.4224":
    "Skikt av gipsbaserade skivor med pappersfiberarmering i undergolv",
  "KBC.43": "Skikt av gipsbaserade skivor med träflisarmering",
  "KBC.432": "Skikt av gipsbaserade skivor med träflisarmering inomhus",
  "KBC.4321":
    "Skikt av gipsbaserade skivor med träflisarmering i vägg, pelare e d inomhus",
  "KBC.4322":
    "Skikt av gipsbaserade skivor med träflisarmering i innertakskonstruktion",
  "KBC.4323": "Inklädnader av gipsbaserade skivor med träflisarmering",
  "KBC.4324": "Skikt av gipsbaserade skivor med träflisarmering i undergolv",
  KBD: "SKIKT AV GLASFIBERKLÄDDA CEMENTSKIVOR",
  "KBD.1": "Skikt av glasfiberklädda cementskivor utomhus",
  "KBD.11": "Skikt av glasfiberklädda cementskivor i vägg, pelare e d utomhus",
  "KBD.12": "Skikt av glasfiberklädda cementskivor i tak e d utomhus",
  "KBD.2": "Skikt av glasfiberklädda cementskivor inomhus",
  "KBD.21": "Skikt av glasfiberklädda cementskivor i vägg, pelare e d inomhus",
  "KBD.22": "Skikt av glasfiberklädda cementskivor i innertakskonstruktion",
  KBE: "SKIKT AV MAGNESIUMOXIDSKIVOR",
  KD: "SKIKT AV METALLSKIVOR",
  KDB: "SKIKT AV SKIVOR AV ALLMÄNT KONSTRUKTIONSSTÅL",
  "KDB.1": "Skikt av obehandlade stålskivor",
  "KDB.2": "Skikt av emaljerade stålskivor",
  "KDB.3": "Skikt av målningsbehandlade stålskivor",
  KDC: "SKIKT AV SKIVOR AV ROSTFRITT STÅL",
  KDD: "SKIKT AV ALUMINIUMSKIVOR",
  "KDD.1": "Skikt av emaljerade aluminiumskivor",
  "KDD.2": "Skikt av anodiserade aluminiumskivor",
  "KDD.3": "Skikt av målningsbehandlade aluminiumskivor",
  KDE: "SKIKT AV SKIVOR AV KOPPAR ELLER KOPPARLEGERING",
  KE: "SKIKT AV SKIVOR AV TRÄ ELLER TRÄBASERAT MATERIAL",
  KEB: "SKIKT AV PLYWOODSKIVOR",
  "KEB.1": "Skikt av plywoodskivor utomhus",
  "KEB.11": "Skikt av plywoodskivor i vägg, pelare e d utomhus",
  "KEB.12": "Skikt av plywoodskivor i tak e d utomhus",
  "KEB.121":
    "Skikt av plywoodskivor som underlagstak för beläggning med överläggsplattor e d",
  "KEB.122":
    "Skikt av plywoodskivor som underlagstak för beläggning med plan plåt, tätskiktsmatta, takduk e d",
  "KEB.18": "Diverse kompletteringar av skikt av plywoodskivor utomhus",
  "KEB.2": "Skikt av plywoodskivor inomhus",
  "KEB.21": "Skikt av plywoodskivor i vägg, pelare e d inomhus",
  "KEB.22": "Skikt av plywoodskivor i innertakskonstruktion",
  "KEB.23": "Skikt av plywoodskivor i undergolv",
  "KEB.231": "Skikt av plywoodskivor i undergolv på reglar e d",
  "KEB.233": "Skikt av plywoodskivor i undergolv på isolerskivor",
  "KEB.234":
    "Skikt av plywoodskivor i undergolv på underlag av betong, lättbetong e d",
  "KEB.2341":
    "Skikt av plywoodskivor i undergolv på underlag av betong, lättbetong e d som flytande, spikbart underlag för parkettstav",
  "KEB.2342":
    "Skikt av plywoodskivor i undergolv på underlag av betong, lättbetong e d som flytande, limningsbart underlag för parkettstav",
  "KEB.235": "Skikt av plywoodskivor för avjämning av trägolv",
  "KEB.24": "Skikt av plywoodskivor som blindbotten",
  KEC: "SKIKT AV LAMELLTRÄSKIVOR",
  "KEC.2": "Skikt av lamellträskivor inomhus",
  "KEC.21": "Skikt av lamellträskivor i vägg, pelare e d inomhus",
  "KEC.22": "Skikt av lamellträskivor i innertakskonstruktion",
  KEF: "SKIKT AV LIMFOGSKIVOR",
  "KEF.2": "Skikt av limfogskivor inomhus",
  "KEF.21": "Skikt av limfogskivor i vägg, pelare e d inomhus",
  "KEF.22": "Skikt av limfogskivor i innertakskonstruktion",
  KEG: "SKIKT AV TRÄFIBERSKIVOR",
  "KEG.1": "Skikt av träfiberskivor utomhus",
  "KEG.11": "Skikt av träfiberskivor i vägg, pelare e d utomhus",
  "KEG.12": "Skikt av träfiberskivor i tak e d utomhus",
  "KEG.121":
    "Skikt av träfiberskivor för vattenavledning under överläggsplattor på fribärande takpanneläkt e d",
  "KEG.2": "Skikt av träfiberskivor inomhus",
  "KEG.21": "Skikt av träfiberskivor i vägg, pelare e d inomhus",
  "KEG.22": "Skikt av träfiberskivor i innertakskonstruktion",
  "KEG.23": "Skikt av träfiberskivor i undergolv",
  "KEG.232":
    "Skikt av hårda och porösa träfiberskivor i undergolv på underlag av betong, lättbetong e d",
  "KEG.233": "Skikt av träfiberskivor för avjämning av trägolv",
  "KEG.24": "Skikt av träfiberskivor som blindbotten",
  KEH: "SKIKT AV IMPREGNERADE PORÖSA TRÄFIBERSKIVOR",
  "KEH.1": "Skikt av impregnerade porösa träfiberskivor utomhus",
  "KEH.11":
    "Skikt av impregnerade porösa träfiberskivor i vägg, pelare e d utomhus",
  "KEH.12": "Skikt av impregnerade porösa träfiberskivor i tak e d utomhus",
  "KEH.2": "Skikt av impregnerade porösa träfiberskivor inomhus",
  "KEH.21":
    "Skikt av impregnerade porösa träfiberskivor i vägg, pelare e d inomhus",
  "KEH.22":
    "Skikt av impregnerade porösa träfiberskivor i innertakskonstruktion",
  "KEH.23": "Skikt av impregnerade porösa träfiberskivor i undergolv",
  KEJ: "SKIKT AV SPÅNSKIVOR",
  "KEJ.1": "Skikt av spånskivor utomhus",
  "KEJ.2": "Skikt av spånskivor inomhus",
  "KEJ.21": "Skikt av spånskivor i vägg, pelare e d inomhus",
  "KEJ.22": "Skikt av spånskivor i innertakskonstruktion",
  "KEJ.23": "Skikt av spånskivor i undergolv",
  "KEJ.231": "Skikt av spånskivor i undergolv på reglar e d",
  "KEJ.233": "Skikt av spånskivor i undergolv på isolerskivor",
  "KEJ.2331": "Skikt av spånskivor i undergolv på isolerskivor av cellplast",
  "KEJ.234":
    "Skikt av spånskivor i undergolv på underlag av betong, lättbetong e d",
  "KEJ.235": "Skikt av spånskivor för avjämning av trägolv",
  KEK: "SKIKT AV CEMENTSPÅNSKIVOR",
  "KEK.1": "Skikt av cementspånskivor utomhus",
  "KEK.11": "Skikt av cementspånskivor i vägg, pelare e d utomhus",
  "KEK.111": "Skikt av cementspånskivor som fasadmaterial",
  "KEK.2": "Skikt av cementspånskivor inomhus",
  "KEK.21": "Skikt av cementspånskivor i vägg, pelare e d inomhus",
  KEL: "SKIKT AV OSB-SKIVOR",
  "KEL.1": "Skikt av OSB-skivor utomhus",
  "KEL.2": "Skikt av OSB-skivor inomhus",
  "KEL.21": "Skikt av OSB-skivor i vägg, pelare e d inomhus",
  KEM: "SKIKT AV TRÄULLSPLATTOR",
  "KEM.1": "Skikt av träullsplattor utomhus",
  "KEM.2": "Skikt av träullsplattor inomhus",
  KEN: "SKIKT AV FANERTRÄSKIVOR",
  "KEN.1": "Skikt av fanerträskivor utomhus",
  "KEN.12": "Skikt av fanerträskivor i tak e d utomhus",
  "KEN.121":
    "Skikt av fanerträskivor som underlagstak för beläggning med överläggsplattor e d",
  "KEN.2": "Skikt av fanerträskivor inomhus",
  "KEN.21": "Skikt av fanerträskivor i vägg, pelare e d inomhus",
  "KEN.23": "Skikt av fanerträskivor i undergolv",
  KEP: "SKIKT AV SKIVOR AV KORSLIMMAT TRÄ (KL-TRÄ)",
  KF: "SKIKT AV SKIVOR AV OORGANISKT FIBERMATERIAL, EXPANDERAT STENMATERIAL E D",
  KFB: "SKIKT AV HÅRDPRESSADE MINERALFIBERSKIVOR",
  KFC: "SKIKT AV SKIVOR AV NATRIUMSILIKATBUNDET EXPANDERAT STENMATERIAL",
  KFD: "SKIKT AV SKIVOR AV KALCIUMSILIKATBUNDET EXPANDERAT STENMATERIAL",
  "KFD.2":
    "Skikt av skivor av kalciumsilikatbundet expanderat stenmaterial i vägg, pelare e d inomhus",
  KH: "SKIKT AV GLASSKIVOR",
  KHA: "SAMMANSATTA SKIKT AV GLASSKIVOR",
  KHB: "ENKELGLASRUTOR",
  "KHB.1": "Enkelglasrutor av floatglas",
  "KHB.2": "Enkelglasrutor av maskinglas",
  "KHB.3": "Enkelglasrutor av valsat glas",
  "KHB.4": "Enkelglasrutor av blåst glas",
  KHC: "FLERSKIKTSRUTOR",
  "KHC.1": "Flerskiktsrutor av lamelltyp",
  "KHC.2": "Gelfyllda flerskiktsrutor",
  KHD: "FÖRSEGLADE RUTOR",
  KK: "SKIKT AV PLASTSKIVOR",
  KKB: "SKIKT AV SKIVOR AV KOMPAKT PLAST",
  KKC: "SKIKT AV SKIVOR AV CELLPLAST E D",
  "KKC.1": "Skikt av skivor av cellplast",
  "KKC.2": "Skikt av skivor av cement- och glasfiberklädd cellplast",
  "KKC.22": "Skikt av skivor av cement- och glasfiberklädd cellplast inomhus",
  "KKC.221":
    "Skikt av skivor av cement- och glasfiberklädd cellplast i vägg, pelare e d inomhus",
  "KKC.2211":
    "Skikt av skivor av cement- och glasfiberklädd cellplast i vägg, pelare e d inomhus på reglar",
  "KKC.2212":
    "Skikt av skivor av cement- och glasfiberklädd cellplast i vägg, pelare e d inomhus på underlag av betong, lättbetong e d",
  "KKC.222":
    "Skikt av skivor av cement- och glasfiberklädd cellplast i undergolv",
  "KKC.2221":
    "Skikt av skivor av cement- och glasfiberklädd cellplast i undergolv på underlag av betong, lättbetong e d",
  KY: "SKIKT AV SKIVOR AV DIVERSE MATERIAL",
  KYC: "SKIKT AV PLASTLAMINATSKIVOR",
  "KYC.1": "Skikt av plastlaminatskivor utomhus",
  "KYC.2": "Skikt av plastlaminatskivor inomhus",
  KYD: "SKIKT AV SKIVOR AV FIBERARMERAD POLYMERKOMPOSIT",
  "KYD.1": "Skikt av skivor av fiberarmerad polymerkomposit utomhus",
  "KYD.11":
    "Skikt av skivor av fiberarmerad polymerkomposit i vägg, pelare e d utomhus",
  "KYD.12": "Skikt av skivor av fiberarmerad polymerkomposit som fasadmaterial",
  KZ: "SKIKT AV SKIVOR AV ÖVRIGA MATERIAL",
  L: "PUTS, MÅLNING, SKYDDSBELÄGGNINGAR, SKYDDSIMPREGNERINGAR M M",
  LB: "PUTS",
  LBB: "PUTS I ANLÄGGNING",
  LBS: "PUTS FÖR HUS",
  "LBS.1": "Oarmerad puts",
  "LBS.11": "Oarmerad puts utomhus",
  "LBS.111": "Oarmerad puts på vägg utomhus",
  "LBS.12": "Oarmerad puts inomhus",
  "LBS.121": "Oarmerad puts på vägg inomhus",
  "LBS.122": "Oarmerad puts i tak inomhus",
  "LBS.2": "Armerad puts",
  "LBS.21": "Armerad puts utomhus",
  "LBS.211": "Armerad puts på underlag utomhus",
  "LBS.2111": "Armerad puts på underlag, på vägg utomhus",
  "LBS.2112": "Armerad puts på underlag, i tak utomhus",
  "LBS.212": "Armerad puts, fribärande utomhus",
  "LBS.2121": "Armerad puts, fribärande, på vägg utomhus",
  "LBS.2122": "Armerad puts, fribärande, i tak utomhus",
  "LBS.213": "Armerad puts på putsbärare utomhus",
  "LBS.22": "Armerad puts inomhus",
  "LBS.221": "Armerad puts på underlag inomhus",
  "LBS.2211": "Armerad puts på underlag, på vägg inomhus",
  "LBS.2212": "Armerad puts på underlag, i tak inomhus",
  "LBS.222": "Armerad puts, fribärande inomhus",
  "LBS.2221": "Armerad puts, fribärande, på vägg inomhus",
  "LBS.2222": "Armerad puts, fribärande, i tak inomhus",
  "LBS.2223": "Armerad puts, fribärande, inklädnad inomhus",
  "LBS.3": "Puts på skorstenar, öppna spisar o d",
  "LBS.31": "Puts på skorsten",
  "LBS.32": "Puts på öppen spis",
  "LBS.4": "Puts med speciella egenskaper",
  "LBS.41": "Ljudabsorberande puts",
  "LBS.42": "Brandskyddande puts",
  LC: "MÅLNING M M",
  LCB: "BYGGPLATSMÅLNING I ANLÄGGNING",
  "LCB.2": "Målning av betongkonstruktioner",
  "LCB.21": "Målning av betongkonstruktioner i bro",
  "LCB.211": "Målning av betongkonstruktioner i bro, nymålning",
  "LCB.212": "Målning av betongkonstruktioner i bro, ommålning",
  "LCB.3": "Målning av träkonstruktioner",
  "LCB.31": "Målning av träkonstruktioner i bro",
  "LCB.311": "Målning av träkonstruktioner i bro, nymålning",
  "LCB.312": "Målning av träkonstruktioner i bro, ommålning",
  "LCB.4": "Målning av aluminiumkonstruktioner",
  "LCB.41": "Målning av aluminiumkonstruktioner i bro",
  "LCB.412": "Målning av aluminiumkonstruktioner i bro, ommålning",
  "LCB.6": "Rostskyddsmålning av stålkonstruktioner",
  "LCB.61": "Rostskyddsmålning av stålkonstruktioner i bro",
  "LCB.612":
    "Rostskyddsmålning av svetsade stålkonstruktioner i bro, ommålning",
  "LCB.613": "Rostskyddsmålning av nitade stålkonstruktioner i bro, ommålning",
  "LCB.6131":
    "Rostskyddsmålning av nitade stålkonstruktioner i vägbro, ommålning",
  "LCB.6132":
    "Rostskyddsmålning av nitade stålkonstruktioner i järnvägsbro, ommålning",
  "LCB.614":
    "Rostskyddsmålning av svetsade och nitade stålkonstruktioner i bro, förbättring",
  "LCB.6141":
    "Rostskyddsmålning av svetsade och nitade stålkonstruktioner i bro, förbättring med krav på blästring",
  "LCB.6142":
    "Rostskyddsmålning av svetsade och nitade stålkonstruktioner i bro, förbättring utan krav på blästring",
  "LCB.615": "Rostskyddsmålning av räcke i bro",
  "LCB.6151": "Rostskyddsmålning av varmförzinkat räcke",
  "LCB.6152": "Rostskyddsmålning av icke varmförzinkat räcke, ommålning",
  "LCB.6153": "Rostskyddsmålning av estetiskt målade räcken, ommålning",
  "LCB.616": "Rostskyddsmålning av lager i bro, ommålning",
  "LCB.62": "Rostskyddsmålning av stålkonstruktioner för järnväg",
  "LCB.621": "Rostskyddsmålning av kontaktledningsstolpar e d",
  LCS: "Byggplatsmålning av hus",
  "LCS.2": "Generell byggplatsmålning",
  "LCS.21": "Utvändig målning",
  "LCS.211": "Målning av fasad och fasaddetaljer",
  "LCS.212":
    "Målning av trappor, stegar, lejdare, räcken, ledstänger o d utomhus",
  "LCS.213": "Målning av tak och takdetaljer utomhus",
  "LCS.214": "Målning av balkonger, skärmtak o d",
  "LCS.215": "Målning av diverse byggnadsdelar utomhus",
  "LCS.216": "Målning av installation utomhus",
  "LCS.2161": "Målning av rörinstallation utomhus",
  "LCS.2162": "Målning av luftbehandlingsinstallation utomhus",
  "LCS.2163": "Målning av el- och teleinstallation utomhus",
  "LCS.2164": "Målning av transportinstallation utomhus",
  "LCS.2168": "Målning av diverse installationer utomhus",
  "LCS.22": "Invändig målning",
  "LCS.221": "Målning av golv, väggar och tak inomhus",
  "LCS.2211": "Målning av golv inomhus",
  "LCS.2212": "Målning av väggar, pelare o d inomhus",
  "LCS.2213": "Målning av tak, takbalkar o d inomhus",
  "LCS.222": "Målning av innerväggspartier, skärmar, vikväggar o d inomhus",
  "LCS.223":
    "Målning av trappor, stegar, lejdare, räcken, ledstänger o d inomhus",
  "LCS.224": "Målning av inredningar o d",
  "LCS.225": "Målning av diverse byggnadsdelar inomhus",
  "LCS.226": "Målning av installation inomhus",
  "LCS.2261": "Målning av rörinstallation inomhus",
  "LCS.2262": "Målning av luftbehandlingsinstallation inomhus",
  "LCS.2263": "Målning av el- och teleinstallation inomhus",
  "LCS.2264": "Målning av transportinstallation inomhus",
  "LCS.23": "Målning av fönster, dörrar o d",
  "LCS.231": "Målning av portar, dörrar, entrépartier, luckor o d",
  "LCS.2311": "Målning av portar, dörrar, entrépartier, luckor o d utomhus",
  "LCS.2312": "Målning av portar, dörrar, entrépartier, luckor o d inomhus",
  "LCS.232": "Målning av fönster, fönsterdörrar, fönsterpartier o d",
  "LCS.2321": "Målning av fönster, fönsterdörrar, fönsterpartier o d utomhus",
  "LCS.2322": "Målning av fönster, fönsterdörrar, fönsterpartier o d inomhus",
  "LCS.233": "Målning av takfönster, lanterniner, takljuskupoler o d",
  "LCS.3": "Dekorativ målning",
  "LCS.31": "Dekorativ målning utomhus",
  "LCS.32": "Dekorativ målning inomhus",
  "LCS.4": "Antikvarisk målning",
  "LCS.41": "Antikvarisk målning utomhus",
  "LCS.42": "Antikvarisk målning inomhus",
  "LCS.5": "Brandskyddsmålning",
  "LCS.51": "Brandskyddsmålning av stålkonstruktioner",
  "LCS.511": "Brandskyddsmålning av stålkonstruktioner utomhus",
  "LCS.512": "Brandskyddsmålning av stålkonstruktioner inomhus",
  "LCS.52": "Brandskyddsmålning av träkonstruktioner",
  "LCS.521": "Brandskyddsmålning av träkonstruktioner utomhus",
  "LCS.522": "Brandskyddsmålning av träkonstruktioner inomhus",
  "LCS.6": "Rostskyddsmålning av stålkonstruktioner",
  "LCS.61": "Rostskyddsmålning av stålkonstruktioner utomhus",
  "LCS.62": "Rostskyddsmålning av stålkonstruktioner inomhus",
  "LCS.7": "Diverse målning med speciell funktion",
  "LCS.8": "Konstruerade målningsbehandlingar",
  LD: "SKYDDSBELÄGGNING",
  LDB: "SKYDDSBELÄGGNING I ANLÄGGNING",
  "LDB.1": "Metallisering",
  "LDB.11": "Förzinkning",
  "LDB.2": "Beläggningar med icke-metalliskt material",
  "LDB.21": "Anodisering",
  "LDB.3": "Reparation av skyddsbeläggningar",
  "LDB.31": "Reparation av förzinkade ytor",
  "LDB.311": "Reparation av förzinkade ytor på räcken på väg eller bro",
  "LDB.3112": "Reparation av förzinkade ytor på räcken på bro",
  LDS: "SKYDDSBELÄGGNING I HUS",
  "LDS.1": "Metallisering",
  "LDS.11": "Förzinkning",
  "LDS.12": "Aluminering",
  "LDS.13": "Förtenning",
  "LDS.15": "Förnickling",
  "LDS.16": "Förkromning",
  "LDS.2": "Beläggning med icke metalliskt, oorganiskt material",
  "LDS.21": "Anodisering",
  LE: "KLOTTERSKYDDSBEHANDLING",
  LEB: "KLOTTERSKYDDSBEHANDLING I ANLÄGGNING",
  LES: "KLOTTERSKYDDSBEHANDLING I HUS",
  LF: "SKYDDSIMPREGNERING",
  LFB: "SKYDDSIMPREGNERING I ANLÄGGNING",
  "LFB.1": "Tryckimpregnering mot röta, träförstörande insekter o d",
  "LFB.11": "Tryckimpregnering av furu eller annat lätt impregnerbart barrträ",
  "LFB.12": "Tryckimpregnering av gran eller annat svårt impregnerbart barrträ",
  "LFB.18": "Tryckimpregnering av diverse träslag",
  "LFB.3": "Skyddsimpregnering mot inträngning av klorider och vatten",
  "LFB.31":
    "Skyddsimpregnering av betongytor mot inträngning av klorider och vatten",
  "LFB.311":
    "Skyddsimpregnering av betongytor i bro mot inträngning av klorider och vatten",
  "LFB.3111":
    "Skyddsimpregnering av betongytor i bro mot inträngning av klorider och vatten, nyimpregnering",
  "LFB.3112":
    "Skyddsimpregnering av betongytor i bro mot inträngning av klorider och vatten, omimpregnering",
  LFS: "SKYDDSIMPREGNERING I HUS",
  "LFS.1": "Tryckimpregnering mot röta, träförstörande insekter o d",
  "LFS.11": "Tryckimpregnering av furu eller annat lätt impregnerbart barrträ",
  "LFS.12": "Tryckimpregnering av gran eller annat svårt impregnerbart barrträ",
  "LFS.2": "Brandskyddsimpregnering av trä",
  M: "SKIKT AV BELÄGGNINGS- OCH BEKLÄDNADSVAROR I HUS",
  MA: "SAMMANSATTA SKIKT AV BELÄGGNINGS- OCH BEKLÄDNADSVAROR I HUS",
  MB: "BELÄGGNINGAR OCH BEKLÄDNADER AV PLATTOR AV NATURSTEN, BETONG, KONSTBETONG, KERAMIK, MOSAIK E D",
  MBB: "BELÄGGNINGAR OCH BEKLÄDNADER AV PLATTOR AV NATURSTEN ELLER KONSTBETONG",
  "MBB.1": "Beläggningar av plattor av natursten eller konstbetong",
  "MBB.11": "Beläggningar av plattor av natursten eller konstbetong utomhus",
  "MBB.111":
    "Beläggningar av plattor av natursten eller konstbetong på balkong, altan e d utomhus",
  "MBB.112":
    "Beläggningar av plattor av natursten eller konstbetong på trappa, entréplan e d utomhus",
  "MBB.12": "Beläggningar av plattor av natursten eller konstbetong inomhus",
  "MBB.121":
    "Golvbeläggningar av plattor av natursten eller konstbetong inomhus",
  "MBB.1211":
    "Golvbeläggningar av plattor av natursten eller konstbetong inomhus utan krav på vattentäthet",
  "MBB.1212": "Vattentäta golvbeläggningar av naturstenplattor inomhus",
  "MBB.122":
    "Trappbeläggningar av plattor av natursten eller konstbetong inomhus",
  "MBB.2": "Beklädnader av plattor av natursten eller konstbetong",
  "MBB.21":
    "Fasadbeklädnader, marksocklar o d av plattor av natursten eller konstbetong",
  "MBB.22":
    "Väggbeklädnader o d av plattor av natursten eller konstbetong inomhus",
  "MBB.221":
    "Vattenavvisande beklädnader av plattor av natursten eller konstbetong inomhus",
  "MBB.222": "Vattentäta beklädnader av naturstensplattor inomhus",
  "MBB.4": "Socklar o d av plattor av natursten eller konstbetong",
  "MBB.41":
    "Socklar av plattor av natursten eller konstbetong vid trappa, balkong, altan e d utomhus",
  "MBB.42": "Socklar av plattor av natursten eller konstbetong inomhus",
  "MBB.8":
    "Diverse beläggningar och beklädnader av plattor av natursten eller konstbetong",
  "MBB.81": "Spishyllor av plattor av natursten eller konstbetong inomhus",
  MBC: "BELÄGGNINGAR OCH BEKLÄDNADER AV BETONGPLATTOR",
  "MBC.1": "Beläggningar av betongplattor",
  "MBC.11": "Beläggningar av betongplattor utomhus",
  "MBC.111": "Beläggningar av betongplattor på balkong, altan e d utomhus",
  "MBC.112": "Beläggningar av betongplattor på trappa, entréplan e d utomhus",
  "MBC.12": "Beläggningar av betongplattor inomhus",
  "MBC.121": "Golvbeläggningar av betongplattor inomhus",
  "MBC.122": "Trappbeläggningar av betongplattor inomhus",
  "MBC.2": "Beklädnader av betongplattor",
  "MBC.4": "Socklar o d av betongplattor",
  "MBC.41": "Socklar av betongplattor vid trappa, balkong, altan e d utomhus",
  "MBC.42": "Socklar av betongplattor inomhus",
  MBD: "BELÄGGNINGAR OCH BEKLÄDNADER AV TEGELPLATTOR",
  "MBD.1": "Beläggningar av tegelplattor",
  "MBD.11": "Beläggningar av tegelplattor utomhus",
  "MBD.111": "Beläggningar av tegelplattor på balkong, altan e d utomhus",
  "MBD.112": "Beläggningar av tegelplattor på trappa, entréplan e d utomhus",
  "MBD.12": "Beläggningar av tegelplattor inomhus",
  "MBD.121": "Golvbeläggningar av tegelplattor inomhus",
  "MBD.122": "Trappbeläggningar av tegelplattor inomhus",
  "MBD.2": "Beklädnader av tegelplattor",
  "MBD.4": "Socklar o d av tegelplattor",
  "MBD.41": "Socklar av tegelplattor vid trappa, balkong, altan e d utomhus",
  "MBD.42": "Socklar av tegelplattor inomhus",
  MBE: "BELÄGGNINGAR OCH BEKLÄDNADER AV KERAMISKA PLATTOR",
  "MBE.1": "Beläggningar av keramiska plattor",
  "MBE.11": "Beläggningar av keramiska plattor utomhus",
  "MBE.111": "Beläggningar av keramiska plattor på balkong, altan e d utomhus",
  "MBE.112":
    "Beläggningar av keramiska plattor på trappa, entréplan e d utomhus",
  "MBE.12": "Beläggningar av keramiska plattor inomhus",
  "MBE.121": "Golvbeläggningar av keramiska plattor inomhus",
  "MBE.1211":
    "Golvbeläggningar av keramiska plattor inomhus utan krav på vattentäthet",
  "MBE.1212": "Vattentäta golvbeläggningar av keramiska plattor inomhus",
  "MBE.122": "Trappbeläggningar av keramiska plattor inomhus",
  "MBE.2": "Beklädnader av keramiska plattor",
  "MBE.21": "Fasadbeklädnader, marksocklar o d av keramiska plattor",
  "MBE.22": "Väggbeklädnader o d av keramiska plattor inomhus",
  "MBE.221": "Vattenavvisande beklädnader av keramiska plattor inomhus",
  "MBE.222": "Vattentäta beklädnader av keramiska plattor inomhus",
  "MBE.4": "Socklar o d av keramiska plattor",
  "MBE.41":
    "Socklar av keramiska plattor vid trappa, balkong, altan e d utomhus",
  "MBE.42": "Socklar av keramiska plattor inomhus",
  MBY: "BELÄGGNINGAR OCH BEKLÄDNADER AV DIVERSE PLATTOR",
  MC: "BELÄGGNINGAR AV TRÄ",
  MCB: "BELÄGGNINGAR AV TRÄ UTOMHUS",
  "MCB.1": "Beläggningar av golvbrädor och golvplank utomhus",
  "MCB.11":
    "Beläggningar av golvbrädor och golvplank på balkong, altan e d utomhus",
  "MCB.12":
    "Beläggningar av golvbrädor och golvplank på trappa, entréplan e d utomhus",
  MCC: "Beläggningar av trä inomhus",
  "MCC.1": "Beläggningar av massiva trägolv inomhus",
  "MCC.11": "Beläggningar av massiva golvbrädor av trä inomhus",
  "MCC.111":
    "Beläggningar av massiva golvbrädor av trä inomhus på betong eller lättbetong",
  "MCC.113":
    "Beläggningar av massiva golvbrädor av trä inomhus på skivor av cellplast",
  "MCC.114":
    "Beläggningar av massiva golvbrädor av trä inomhus på spånskivor, plywoodskivor e d",
  NSF: "Undertak av förtillverkade komponenter",
  "MCC.115":
    "Beläggningar av massiva golvbrädor av trä inomhus på reglar och bjälkar",
  "MCC.12": "Beläggningar av massiv parkett inomhus",
  "MCC.121": "Beläggningar av massiv stavparkett inomhus",
  "MCC.1211":
    "Beläggningar av massiv stavparkett inomhus på betong eller lättbetong",
  "MCC.1214":
    "Beläggningar av massiv stavparkett inomhus på brädor, spånskivor, plywoodskivor e d",
  "MCC.122": "Beläggningar av massiva parkettbrädor inomhus",
  "MCC.1221":
    "Beläggningar av massiva parkettbrädor inomhus på betong eller lättbetong",
  "MCC.1223":
    "Beläggningar av massiva parkettbrädor inomhus på skivor av cellplast",
  "MCC.1224":
    "Beläggningar av massiva parkettbrädor inomhus på spånskivor, plywoodskivor e d",
  "MCC.1225":
    "Beläggningar av massiva parkettbrädor inomhus på reglar och bjälkar",
  "MCC.123":
    "Beläggningar av massiv mosaikparkett eller industriparkett inomhus",
  "MCC.1231":
    "Beläggningar av massiv mosaikparkett eller industriparkett inomhus på betong eller lättbetong",
  "MCC.1234":
    "Beläggningar av massiv mosaikparkett eller industriparkett inomhus på spånskivor, plywoodskivor e d",
  "MCC.124": "Beläggningar av massiv träkubb inomhus",
  "MCC.1241":
    "Beläggningar av massiv träkubb inomhus på betong eller lättbetong",
  "MCC.2": "Beläggningar av lamellparkett inomhus",
  "MCC.21": "Beläggningar av lamellparkett inomhus på betong eller lättbetong",
  "MCC.23": "Beläggningar av lamellparkett inomhus på skivor av cellplast",
  "MCC.24":
    "Beläggningar av lamellparkett inomhus på spånskivor, plywoodskivor e d",
  "MCC.25": "Beläggningar av lamellparkett inomhus på reglar och bjälkar",
  "MCC.3": "Beläggningar av fanergolv inomhus",
  "MCC.31": "Beläggningar av fanergolv inomhus på betong eller lättbetong",
  "MCC.34":
    "Beläggningar av fanergolv inomhus på spånskivor, plywoodskivor e d",
  ME: "BELÄGGNINGAR AV LAMINATGOLV",
  MEB: "BELÄGGNINGAR AV LAMINATGOLV PÅ BETONG ELLER LÄTTBETONG",
  MEC: "BELÄGGNINGAR AV LAMINATGOLV PÅ SKIVOR AV CELLPLAST",
  MED: "BELÄGGNINGAR AV LAMINATGOLV PÅ SPÅNSKIVOR, PLYWOODSKIVOR E D",
  MF: "BELÄGGNINGAR AV TEXTIL, KORK, LINOLEUM, GUMMI, PLAST M M OCH TÄTSKIKT AV PLASTMATTA",
  MFB: "BELÄGGNINGAR AV MATTA ELLER PLATTOR AV TEXTIL",
  "MFB.3": "Golvbeläggningar av matta eller plattor av textil",
  "MFB.33":
    "Golvbeläggningar av matta eller plattor av textil på betong eller lättbetong",
  "MFB.34": "Golvbeläggningar av matta eller plattor av textil på skivor",
  "MFB.4": "Trappbeläggningar av matta eller plattor av textil",
  "MFB.41": "Trappbeläggningar av matta eller plattor av textil på betong",
  "MFB.44": "Trappbeläggningar av matta eller plattor av textil på skivor",
  MFD: "BELÄGGNINGAR AV KORKPLATTOR",
  "MFD.1": "Golvbeläggningar av korkplattor",
  "MFD.11": "Golvbeläggningar av korkplattor på betong",
  "MFD.12": "Golvbeläggningar av korkplattor på lättbetong",
  "MFD.14": "Golvbeläggningar av korkplattor på skivor",
  "MFD.2": "Trappbeläggningar av korkplattor",
  "MFD.21": "Trappbeläggningar av korkplattor på betong",
  "MFD.24": "Trappbeläggningar av korkplattor på skivor",
  MFG: "BELÄGGNINGAR AV MATTA ELLER PLATTOR AV LINOLEUM",
  "MFG.1": "Golvbeläggningar av matta eller plattor av linoleum",
  "MFG.11": "Golvbeläggningar av matta eller plattor av linoleum på betong",
  "MFG.12": "Golvbeläggningar av matta eller plattor av linoleum på lättbetong",
  "MFG.14": "Golvbeläggningar av matta eller plattor av linoleum på skivor",
  "MFG.2": "Trappbeläggningar av matta eller plattor av linoleum",
  "MFG.21": "Trappbeläggningar av matta eller plattor av linoleum på betong",
  "MFG.24": "Trappbeläggningar av matta eller plattor av linoleum på skivor",
  "MFG.4": "Socklar av linoleum",
  MFH: "BELÄGGNINGAR AV MATTA ELLER PLATTOR AV GUMMI",
  "MFH.1": "Golvbeläggningar av matta eller plattor av gummi",
  "MFH.11":
    "Golvbeläggningar av matta eller plattor av gummi på betong eller lättbetong",
  "MFH.14": "Golvbeläggningar av matta eller plattor av gummi på skivor",
  "MFH.2": "Trappbeläggningar av matta eller plattor av gummi",
  "MFH.21": "Trappbeläggningar av matta eller plattor av gummi på betong",
  "MFH.24": "Trappbeläggningar av matta eller plattor av gummi på skivor",
  "MFH.4": "Socklar av gummi",
  MFK: "BELÄGGNINGAR AV MATTA ELLER PLATTOR AV PLAST OCH TÄTSKIKT AV PLASTMATTA",
  "MFK.1":
    "Beläggningar av matta eller plattor av plast utan krav på vattentäthet",
  "MFK.13":
    "Golvbeläggningar av matta eller plattor av plast utan krav på vattentäthet",
  "MFK.131":
    "Golvbeläggningar av matta eller plattor av plast utan krav på vattentäthet på betong eller lättbetong",
  "MFK.134":
    "Golvbeläggningar av matta eller plattor av plast utan krav på vattentäthet på skivor",
  "MFK.14":
    "Trappbeläggningar av matta eller plattor av plast utan krav på vattentäthet",
  "MFK.141":
    "Trappbeläggningar av matta eller plattor av plast utan krav på vattentäthet på betong",
  "MFK.144":
    "Trappbeläggningar av matta eller plattor av plast utan krav på vattentäthet på skivor",
  "MFK.2": "Vattentäta beläggningar och tätskikt av plastmatta",
  "MFK.21": "Vattentäta golvbeläggningar och tätskikt av plastmatta",
  "MFK.211": "Vattentäta golvbeläggningar av plastmatta",
  "MFK.2111": "Vattentäta golvbeläggningar av plastmatta på betong",
  "MFK.2112": "Vattentäta golvbeläggningar av plastmatta på lättbetong",
  "MFK.2114": "Vattentäta golvbeläggningar av plastmatta på skivor",
  "MFK.212": "Tätskikt av plastmatta under golvbeläggning av plattor",
  "MFK.2121":
    "Tätskikt av plastmatta på underlag av betong eller lättbetong till golvbeläggning av plattor",
  "MFK.2124":
    "Tätskikt av plastmatta på underlag av skivor till golvbeläggning av plattor",
  MH: "BELÄGGNINGAR AV MASSA M M",
  MHB: "BELÄGGNINGAR AV CEMENTMOSAIKMASSA",
  "MHB.1": "Golvbeläggningar av cementmosaikmassa",
  "MHB.11": "Golvbeläggningar av cementmosaikmassa på betong",
  "MHB.12":
    "Golvbeläggningar av cementmosaikmassa på fyllning, isolerplattor e d",
  "MHB.2": "Trappbeläggningar av cementmosaikmassa",
  "MHB.21": "Beläggningar av cementmosaikmassa på trapplopp",
  "MHB.22": "Beläggningar av cementmosaikmassa på vilplan",
  "MHB.4": "Socklar, faser och hålkälar av cementmosaikmassa",
  "MHB.8": "Diverse beläggningar av cementmosaikmassa",
  "MHB.81": "Beläggningar av cementmosaikmassa på fönsterbänkar",
  MHC: "BELÄGGNINGAR AV HÅRDBETONGMASSA",
  "MHC.1": "Golvbeläggningar av hårdbetongmassa",
  "MHC.4": "Socklar, faser, hålkäl och anslutningar av hårdbetongmassa",
  MHD: "BELÄGGNINGAR AV MAGNESITMASSA",
  "MHD.1": "Golvbeläggningar av magnesitmassa",
  "MHD.2": "Trappbeläggningar av magnesitmassa",
  "MHD.4": "Socklar, faser och hålkälar av magnesitmassa",
  "MHD.8": "Diverse beläggningar av magnesitmassa",
  MHF: "BELÄGGNINGAR AV BITUMENBUNDEN MASSA",
  "MHF.1": "Beläggningar av gjutasfaltmassa",
  "MHF.11": "Golvbeläggningar av gjutasfaltmassa",
  "MHF.111": "Golvbeläggningar av syrabeständig gjutasfaltmassa",
  "MHF.112": "Golvbeläggningar av ljudabsorberande gjutasfaltmassa",
  "MHF.14": "Socklar av gjutasfaltmassa",
  "MHF.15": "Faser av gjutasfaltmassa",
  "MHF.16": "Lister av gjutasfaltmassa",
  "MHF.2": "Beläggningar av asfaltemulsionsmassa",
  "MHF.21": "Golvbeläggningar av asfaltemulsionsmassa",
  "MHF.25": "Faser av asfaltemulsionsmassa",
  MHG: "BELÄGGNINGAR AV PLASTBASERAD MASSA",
  "MHG.1": "Beläggningar av plastmassa i tjockskikt",
  "MHG.11": "Beläggningar av plastmassa i tjockskikt utan krav på vattentäthet",
  "MHG.111":
    "Golvbeläggningar av plastmassa i tjockskikt utan krav på vattentäthet",
  "MHG.112":
    "Trappbeläggningar av plastmassa i tjockskikt utan krav på vattentäthet",
  "MHG.12": "Vattentäta beläggningar av plastmassa i tjockskikt",
  "MHG.121": "Vattentäta golvbeläggningar av plastmassa i tjockskikt",
  "MHG.14": "Socklar, faser och hålkälar av plastmassa i tjockskikt",
  "MHG.141": "Socklar av plastmassa i tjockskikt utan krav på vattentäthet",
  "MHG.2": "Beläggningar av plastmassa i tunnskikt",
  "MHG.21": "Beläggningar av plastmassa i tunnskikt utan krav på vattentäthet",
  "MHG.211":
    "Golvbeläggningar av plastmassa i tunnskikt utan krav på vattentäthet",
  "MHG.212":
    "Trappbeläggningar av plastmassa i tunnskikt utan krav på vattentäthet",
  "MHG.24": "Socklar, faser och hålkälar av plastmassa i tunnskikt",
  MHJ: "BELÄGGNINGAR AV GOLVAVJÄMNINGSMASSA",
  "MHJ.1": "Beläggningar av golvavjämningsmassa som underlag för beläggning",
  "MHJ.11":
    "Beläggningar av golvavjämningsmassa för avjämning av underlag för beläggning",
  "MHJ.12": "Beläggningar av golvavjämningsmassa för uppbyggnad av fall",
  "MHJ.2": "Beläggningar av golvavjämningsmassa som slitskikt",
  MJ: "BEKLÄDNADER AV TEXTIL, KORK, LINOLEUM, GUMMI, PLAST E D OCH TÄTSKIKT AV PLASTMATTA",
  MJD: "BEKLÄDNADER AV KORKPLATTOR",
  MJG: "BEKLÄDNADER AV MATTA ELLER PLATTOR AV LINOLEUM",
  MJH: "BEKLÄDNADER AV MATTA ELLER PLATTOR AV GUMMI",
  MJK: "BEKLÄDNADER AV MATTA ELLER PLATTOR AV PLAST OCH TÄTSKIKT AV PLASTMATTA",
  "MJK.1": "Beklädnader och tätskikt av plastmatta",
  "MJK.11": "Beklädnader av plastmatta",
  "MJK.111": "Vattenavvisande beklädnader av plastmatta",
  "MJK.1111":
    "Vattenavvisande beklädnader av plastmatta med vertikalt monterad våd",
  "MJK.1112":
    "Vattenavvisande beklädnader av plastmatta med horisontalt monterad våd",
  "MJK.112": "Vattentäta beklädnader av plastmatta",
  "MJK.1121": "Vattentäta beklädnader av plastmatta med vertikalt monterad våd",
  "MJK.1122":
    "Vattentäta beklädnader av plastmatta med horisontalt monterad våd",
  "MJK.12": "Tätskikt av plastmatta under väggbeklädnad av plattor",
  "MJK.2": "Beklädnader av plastplattor",
  MK: "BEKLÄDNADER AV MASSA",
  MKB: "BEKLÄDNADER AV CEMENTMOSAIKMASSA",
  MKD: "BEKLÄDNADER AV MAGNESITMASSA",
  MKG: "BEKLÄDNADER AV PLASTBASERAD MASSA",
  "MKG.1": "Vattenavvisande beklädnader av plastmassa",
  "MKG.2": "Vattentäta beklädnader av plastmassa",
  ML: "BEKLÄDNADER AV PLATTOR, PANELER O D AV BETONG, FIBERBETONG, GIPS M M",
  MN: "BEKLÄDNADER AV PLATTOR, PANELER O D AV METALL",
  MP: "BEKLÄDNADER AV TRÄULLSPLATTOR",
  MY: "SKIKT AV DIVERSE BELÄGGNINGS- OCH BEKLÄDNADSVAROR I HUS",
  MZ: "SKIKT AV ÖVRIGA BELÄGGNINGS- OCH BEKLÄDNADSVAROR I HUS",
  N: "KOMPLETTERINGAR AV SAKVAROR M M",
  NB: "KOMPLETTERINGAR AV SAKVAROR M M I ANLÄGGNING",
  NBB: "KOMPLETTERINGAR TILL BJÄLKLAGSÖPPNINGAR M M I ANLÄGGNING",
  "NBB.1":
    "Gallerdurkstäckningar, slitsdurkstäckningar, durkplåtstäckningar m m",
  "NBB.11": "Gallerdurkstäckningar",
  "NBB.111": "Gallerdurkstäckningar av allmänt konstruktionsstål",
  "NBB.112": "Gallerdurkstäckningar av rostfritt stål",
  "NBB.113": "Gallerdurkstäckningar av aluminium",
  "NBB.12": "Slitsdurkstäckningar",
  "NBB.13": "Durkplåtstäckningar",
  "NBB.131": "Durkplåtstäckningar av allmänt konstruktionsstål",
  "NBB.2": "Bjälklagsluckor",
  "NBB.21": "Bjälklagsluckor av allmänt konstruktionsstål",
  "NBB.22": "Bjälklagsluckor av rostfritt stål",
  "NBB.23": "Bjälklagsluckor av aluminium",
  NBC: "KOMPLETTERINGAR TILL VÄGGÖPPNINGAR M M I ANLÄGGNING",
  "NBC.2": "Dörrar, dörrpartier, portar, väggluckor o d",
  "NBC.21": "Dörrar",
  "NBC.211": "Dörrar av metall",
  "NBC.2111": "Dörrar av allmänt konstruktionsstål",
  "NBC.2112": "Dörrar av rostfritt stål",
  "NBC.2113": "Dörrar av aluminium",
  "NBC.23": "Portar",
  "NBC.231": "Portar av metall",
  "NBC.2311": "Portar av allmänt konstruktionsstål",
  "NBC.28": "Diverse dörrar, dörrpartier, portar, väggluckor o d",
  "NBC.281": "Skibord",
  "NBC.282": "Galler i väggöppning m m",
  "NBC.2821": "Ventilationsgaller",
  NBD: "GRINDAR, BOMMAR O D I ANLÄGGNING",
  "NBD.1": "Grindar",
  "NBD.2": "Bommar",
  "NBD.28": "Diverse bommar",
  "NBD.3": "Vägspärrar i mark",
  NBJ: "TILLTRÄDES- OCH SKYDDSANORDNINGAR I ANLÄGGNING",
  "NBJ.1": "Stegar, stegjärn o d",
  "NBJ.11": "Stegar för fast vertikal montering",
  "NBJ.111": "Stegar av allmänt konstruktionsstål för fast vertikal montering",
  "NBJ.1111":
    "Stegar av allmänt konstruktionsstål för fast vertikal montering i bro",
  "NBJ.1113":
    "Stegar av allmänt konstruktionsstål för fast vertikal montering i ledningsanläggning",
  "NBJ.112": "Stegar av rostfritt stål för fast vertikal montering",
  "NBJ.1121": "Stegar av rostfritt stål för fast vertikal montering i bro",
  "NBJ.1123":
    "Stegar av rostfritt stål för fast vertikal montering i ledningsanläggning",
  "NBJ.113": "Stegar av aluminium för fast vertikal montering",
  "NBJ.114": "Stegar av plast för fast vertikal montering",
  "NBJ.12": "Stegjärn",
  "NBJ.121": "Stegjärn av allmänt konstruktionsstål",
  "NBJ.122": "Stegjärn av rostfritt stål",
  "NBJ.123": "Stegjärn av aluminium",
  "NBJ.2": "Skyddsräcken vid uppstigningsöppningar",
  "NBJ.21":
    "Skyddsräcken av allmänt konstruktionsstål vid uppstigningsöppningar",
  "NBJ.211":
    "Skyddsräcken av allmänt konstruktionsstål vid uppstigningsöppningar i bro",
  "NBJ.213":
    "Skyddsräcken av allmänt konstruktionsstål vid uppstigningsöppningar i ledningsanläggning",
  "NBJ.2131": "Skyddsräcken av allmänt konstruktionsstål i brunn, kammare e d",
  "NBJ.22": "Skyddsräcken av rostfritt stål vid uppstigningsöppningar",
  "NBJ.221": "Skyddsräcken av rostfritt stål vid uppstigningsöppningar i bro",
  "NBJ.223":
    "Skyddsräcken av rostfritt stål vid uppstigningsöppningar i ledningsanläggning",
  "NBJ.2231": "Skyddsräcken av rostfritt stål i brunn, kammare e d",
  "NBJ.23": "Skyddsräcken av aluminium vid uppstigningsöppningar",
  "NBJ.231": "Skyddsräcken av aluminium vid uppstigningsöppningar i bro",
  "NBJ.3": "Skyddsräcken vid avsatser e d",
  "NBJ.31": "Skyddsräcken av allmänt konstruktionsstål vid avsatser e d",
  "NBJ.32": "Skyddsräcken av rostfritt stål vid avsatser e d",
  "NBJ.33": "Skyddsräcken av aluminium vid avsatser e d",
  NBK: "TRAPPOR, TRAPPRÄCKEN M M I ANLÄGGNING",
  "NBK.1": "Trappor m m",
  "NBK.11": "Trappor av metall",
  "NBK.111": "Trappor av metall i bro",
  "NBK.113": "Trappor av metall i ledningsanläggning",
  "NBK.1131": "Trappor av allmänt konstruktionsstål",
  "NBK.12": "Trappor av trä",
  "NBK.3": "Trappräcken, ledstänger o d",
  "NBK.31": "Trappräcken",
  "NBK.311": "Trappräcken i bro",
  "NBK.32": "Ledstänger",
  NS: "KOMPLETTERINGAR AV SAKVAROR M M I HUS",
  NSB: "KOMPLETTERINGAR TILL BJÄLKLAGSÖPPNINGAR M M",
  "NSB.1":
    "Gallerdurkstäckningar, slitsdurkstäckningar, durkplåtstäckningar m m",
  "NSB.11": "Gallerdurkstäckningar",
  "NSB.111": "Gallerdurkstäckningar av allmänt konstruktionsstål",
  "NSB.112": "Gallerdurkstäckningar av rostfritt stål",
  "NSB.113": "Gallerdurkstäckningar av aluminium",
  "NSB.12": "Slitsdurkstäckningar",
  "NSB.13": "Durkplåtstäckningar",
  "NSB.131": "Durkplåtstäckningar av allmänt konstruktionsstål",
  "NSB.2": "Bjälklagsluckor",
  "NSB.3": "Skrapgaller",
  "NSB.4": "Torkmattor",
  "NSB.6": "Golvtrallar",
  "NSB.62": "Golvtrallar av trä",
  "NSB.63": "Golvtrallar av plast",
  NSC: "Kompletteringar till väggöppningar m m",
  "NSC.1": "Fönster, fönsterdörrar, fönsterpartier, väggpartier o d",
  "NSC.11": "Fönster och fönsterdörrar",
  "NSC.110": "Fönster och fönsterdörrar sammansatta av flera material",
  "NSC.1101": "Fönster och fönsterdörrar av trä och allmänt konstruktionsstål",
  "NSC.1103": "Fönster och fönsterdörrar av trä och aluminium",
  "NSC.111": "Fönster och fönsterdörrar av metall",
  "NSC.1111": "Fönster och fönsterdörrar av allmänt konstruktionsstål",
  "NSC.1113": "Fönster och fönsterdörrar av aluminium",
  "NSC.112": "Fönster och fönsterdörrar av trä",
  "NSC.114": "Fönster och fönsterdörrar av plast",
  "NSC.117":
    "Renoveringssystem med beklädnadsprofiler för fönster och fönsterdörrar av trä",
  "NSC.1171":
    "Renoveringssystem med beklädnadsprofiler av metall för fönster och fönsterdörrar av trä",
  "NSC.1172":
    "Renoveringssystem med beklädnadsprofiler av plast för fönster och fönsterdörrar av trä",
  "NSC.12": "Fönsterpartier",
  "NSC.121": "Fönsterpartier av metall",
  "NSC.1211": "Fönsterpartier av allmänt konstruktionsstål",
  "NSC.1213": "Fönsterpartier av aluminium",
  "NSC.122": "Fönsterpartier av trä",
  "NSC.13": "Väggpartier",
  "NSC.131": "Väggpartier av glas och metall",
  "NSC.1311": "Väggpartier av glas och allmänt konstruktionsstål",
  "NSC.1312": "Väggpartier av glas och rostfritt stål",
  "NSC.1313": "Väggpartier av glas och aluminium",
  "NSC.132": "Väggpartier av glas och trä",
  "NSC.2": "Dörrar, dörrpartier, portar, väggluckor o d",
  "NSC.21": "Ytterdörrar",
  "NSC.211": "Ytterdörrar av metall",
  "NSC.2111": "Ytterdörrar av allmänt konstruktionsstål",
  "NSC.2113": "Ytterdörrar av aluminium",
  "NSC.212": "Ytterdörrar av trä",
  "NSC.214": "Ytterdörrar av plast",
  "NSC.216": "Ytterdörrar av glas",
  "NSC.217": "Ytterdörrar av gummi",
  "NSC.22": "Innerdörrar",
  "NSC.221": "Innerdörrar av metall",
  "NSC.2211": "Innerdörrar av allmänt konstruktionsstål",
  "NSC.2213": "Innerdörrar av aluminium",
  "NSC.222": "Innerdörrar av trä",
  "NSC.224": "Innerdörrar av plast",
  "NSC.226": "Innerdörrar av glas",
  "NSC.227": "Innerdörrar av gummi",
  "NSC.23": "Portar",
  "NSC.231": "Portar av metall",
  "NSC.2311": "Portar av allmänt konstruktionsstål",
  "NSC.2313": "Portar av aluminium",
  "NSC.232": "Portar av trä",
  "NSC.234": "Portar av plast",
  "NSC.237": "Portar av gummi",
  "NSC.24": "Väggluckor",
  "NSC.241": "Väggluckor av metall",
  "NSC.2411": "Väggluckor av allmänt konstruktionsstål",
  "NSC.2412": "Väggluckor av rostfritt stål",
  "NSC.2413": "Väggluckor av aluminium",
  "NSC.242": "Väggluckor av trä",
  "NSC.246": "Väggluckor av glas",
  "NSC.25": "Dörrar, portar och luckor för speciell användning",
  "NSC.251": "Dörrar för speciell användning",
  "NSC.2511": "Kyl- och frysrumsdörrar",
  "NSC.2512": "Arkiv- och valvdörrar",
  "NSC.2513": "Skyddsrumsdörrar",
  "NSC.2514": "Strålskyddsdörrar",
  "NSC.252": "Portar och ridåer för speciell användning",
  "NSC.2521": "Kyl- och fryshusportar",
  "NSC.253": "Luckor för speciell användning",
  "NSC.2533": "Skyddsrumsluckor",
  "NSC.2534": "Strålskyddsluckor",
  "NSC.2535": "Brandgasventilatorer",
  "NSC.2536": "Luckor till avfallsinkast",
  "NSC.2537": "Luckor till tvättinkast",
  "NSC.2538": "Diverse luckor för speciell användning",
  "NSC.3": "Grindar, bommar o d",
  "NSC.31": "Grindar",
  "NSC.32": "Bommar",
  "NSC.4": "Rörliga väggar m m",
  "NSC.41": "Skjutväggar och mobila blockväggar",
  "NSC.42": "Vikväggar",
  "NSC.43": "Ridåväggar",
  "NSC.44": "Rullgallerväggar och rulljalusiväggar",
  "NSC.7": "Kompletteringar till fönster, dörrar, grindar, rörliga väggar m m",
  "NSC.71": "Smyginklädnader och listverk för fönster, dörrar e d",
  "NSC.711": "Smyginklädnader för fönster, dörrar e d",
  "NSC.7112": "Smyginklädnader av trä för fönster, dörrar e d",
  "NSC.7113": "Smyginklädnader av träbaserat material för fönster, dörrar e d",
  "NSC.712": "Listverk för fönster, dörrar e d",
  "NSC.7121": "Listverk av metall för fönster, dörrar e d",
  "NSC.7122": "Listverk av trä för fönster, dörrar e d",
  "NSC.7123": "Listverk av träbaserat material för fönster, dörrar e d",
  "NSC.7124": "Listverk av plast för fönster, dörrar e d",
  "NSC.72": "Fönsterbänkar",
  "NSC.721": "Fönsterbänkar av natursten",
  "NSC.722": "Fönsterbänkar av trä",
  "NSC.724": "Fönsterbänkar av plast",
  "NSC.7244": "Fönsterbänkar av plastlaminat",
  "NSC.73":
    "Solavskärmningsanordningar, mörkläggningsanordningar o d till fönster, dörrar, rörliga väggar e d",
  "NSC.731": "Persienner",
  "NSC.732": "Jalusier",
  "NSC.733": "Markiser",
  "NSC.734": "Rullgardiner",
  "NSC.735": "Mörkläggningsridåer",
  "NSC.736": "Fasta skärmar vid fönster o d",
  NSD: "KOMPLETTERINGAR AV YTTERTAKSÖPPNINGAR M M",
  "NSD.1": "Takfönster, takljuskupoler, lanterniner o d",
  "NSD.11": "Takfönster",
  "NSD.110": "Takfönster sammansatta av flera material",
  "NSD.111": "Takfönster av metall",
  "NSD.1111": "Takfönster av allmänt konstruktionsstål",
  "NSD.1113": "Takfönster av aluminium",
  "NSD.112": "Takfönster av trä",
  "NSD.14": "Takljuskupoler",
  "NSD.15": "Lanterniner",
  "NSD.16": "Ljuspaneler",
  "NSD.2": "Takluckor, brandgasventilatorer o d",
  "NSD.25": "Brandgasventilatorer i yttertak och ytterbjälklag",
  "NSD.26": "Takluckor i yttertak och ytterbjälklag",
  "NSD.3": "Rörliga tak",
  "NSD.7": "Kompletteringar till takfönster, takluckor, rörliga tak m m",
  "NSD.71":
    "Smyginklädnader och listverk för takfönster, takluckor, rörliga tak m m",
  "NSD.711": "Smyginklädnader för takfönster, takluckor, rörliga tak m m",
  "NSD.7112":
    "Smyginklädnader av trä för takfönster, takluckor, rörliga tak m m",
  "NSD.7113":
    "Smyginklädnader av träbaserat material för takfönster, takluckor, rörliga tak m m",
  "NSD.712": "Listverk för takfönster, takluckor, rörliga tak m m",
  "NSD.7121": "Listverk av metall för takfönster, takluckor, rörliga tak m m",
  "NSD.7122": "Listverk av trä för takfönster, takluckor, rörliga tak m m",
  "NSD.7123":
    "Listverk av träbaserat material för takfönster, takluckor, rörliga tak m m",
  "NSD.7124": "Listverk av plast för takfönster, takluckor, rörliga tak m m",
  "NSD.73":
    "Solavskärmningsanordningar, mörkläggningsanordningar o d till takfönster, takljuskupoler e d",
  "NSD.731": "Persienner till takfönster e d",
  "NSD.732": "Jalusier till takfönster e d",
  "NSD.733": "Markiser till takfönster e d",
  "NSD.734": "Rullgardiner till takfönster e d",
  "NSD.735": "Mörkläggningsgardiner till takfönster e d",
  "NSD.74": "Intrångsskydd till takfönster, takljuskupoler e d",
  NSE: "INSTALLATIONSGOLV",
  "NSE.2": "Installationsgolv i driftrum för elektrisk utrustning",
  "NSE.21": "Installationsgolv i ställverksutrymme",
  "NSE.5": "Installationsgolv i datorhall e d",
  "NSE.6": "Installationsgolv i kontor",
  NSl: "Undertak Av Förtillverkade Komponenter",
  NSG: "SKÄRMVÄGGAR, APPARATINKLÄDNADER M M",
  "NSG.1": "Skärmväggar av skivor m m",
  "NSG.3": "Skärmväggar av nät m m",
  NSH: "SKÄRMTAK M M",
  "NSH.1": "Skärmtak",
  NSJ: "TILLTRÄDES- OCH SKYDDSANORDNINGAR",
  "NSJ.1": "Tillträdes- och skyddsanordningar av allmänt konstruktionsstål",
  "NSJ.11": "Stegar, stegjärn o d",
  "NSJ.111": "Stegar för fast vertikal montering",
  "NSJ.1111": "Stegar för fast vertikal montering utomhus",
  "NSJ.1112": "Stegar för fast vertikal montering inomhus",
  "NSJ.112": "Lösa stegar",
  "NSJ.113": "Glidskydd för lösa stegar",
  "NSJ.114": "Taktrappstegar",
  "NSJ.115": "Takpinnstegar",
  "NSJ.116": "Skorstensstegar",
  "NSJ.117": "Stegjärn",
  "NSJ.12": "Räcken vid uppstigningsöppningar på tak",
  "NSJ.13": "Gångbryggor, skorstensbryggor o d",
  "NSJ.131": "Gångbryggor",
  "NSJ.132": "Skorstensbryggor",
  "NSJ.14": "Nock- och takfotsräcken",
  "NSJ.141": "Nockräcken",
  "NSJ.142": "Takfotsräcken",
  "NSJ.15": "Förankringsöglor o d",
  "NSJ.151": "Förankringsöglor på tak",
  "NSJ.152": "Säkerhetshakar på tak",
  "NSJ.16": "Snörasskydd, snöglidhinder o d",
  "NSJ.161": "Snörasskydd",
  "NSJ.162": "Snöglidhinder",
  "NSJ.17": "Hållare, fästen m m på tak",
  "NSJ.171": "Hållare för tillfälliga skyddsräcken på yttertak",
  "NSJ.172": "Bärläktssteg",
  "NSJ.2": "Tillträdes- och skyddsanordningar av rostfritt stål",
  "NSJ.21": "Stegar, stegjärn o d",
  "NSJ.211": "Stegar för fast vertikal montering",
  "NSJ.2111": "Stegar för fast vertikal montering utomhus",
  "NSJ.2112": "Stegar för fast vertikal montering inomhus",
  "NSJ.212": "Lösa stegar",
  "NSJ.213": "Glidskydd för lösa stegar",
  "NSJ.214": "Taktrappstegar",
  "NSJ.215": "Takpinnstegar",
  "NSJ.216": "Skorstensstegar",
  "NSJ.217": "Stegjärn",
  "NSJ.22": "Räcken vid uppstigningsöppningar på tak",
  "NSJ.23": "Gångbryggor, skorstensbryggor o d",
  "NSJ.231": "Gångbryggor",
  "NSJ.232": "Skorstensbryggor",
  "NSJ.24": "Nock- och takfotsräcken",
  "NSJ.241": "Nockräcken",
  "NSJ.242": "Takfotsräcken",
  "NSJ.25": "Förankringsöglor o d",
  "NSJ.251": "Förankringsöglor på tak",
  "NSJ.252": "Säkerhetshakar på tak",
  "NSJ.26": "Snörasskydd, snöglidhinder o d",
  "NSJ.261": "Snörasskydd",
  "NSJ.262": "Snöglidhinder",
  "NSJ.27": "Hållare, fästen m m på tak",
  "NSJ.271": "Hållare för tillfälliga skyddsräcken på yttertak",
  "NSJ.272": "Bärläktssteg",
  "NSJ.3": "Tillträdes- och skyddsanordningar av aluminium",
  "NSJ.31": "Stegar, stegjärn o d",
  "NSJ.311": "Stegar för fast vertikal montering",
  "NSJ.3111": "Stegar för fast vertikal montering utomhus",
  "NSJ.3112": "Stegar för fast vertikal montering inomhus",
  "NSJ.312": "Lösa stegar",
  "NSJ.313": "Glidskydd för lösa stegar",
  "NSJ.314": "Taktrappstegar",
  "NSJ.315": "Takpinnstegar",
  "NSJ.316": "Skorstensstegar",
  "NSJ.317": "Stegjärn",
  "NSJ.32": "Räcken vid uppstigningsöppningar på tak",
  "NSJ.33": "Gångbryggor, skorstensbryggor o d",
  "NSJ.331": "Gångbryggor",
  "NSJ.332": "Skorstensbryggor",
  "NSJ.34": "Nock- och takfotsräcken",
  "NSJ.341": "Nockräcken",
  "NSJ.342": "Takfotsräcken",
  "NSJ.35": "Förankringsöglor o d",
  "NSJ.351": "Förankringsöglor på tak",
  "NSJ.352": "Säkerhetshakar på tak",
  "NSJ.36": "Snörasskydd, snöglidhinder o d",
  "NSJ.361": "Snörasskydd",
  "NSJ.362": "Snöglidhinder",
  "NSJ.37": "Hållare, fästen m m på tak",
  "NSJ.371": "Hållare för tillfälliga skyddsräcken på yttertak",
  "NSJ.372": "Bärläktssteg",
  "NSJ.4": "Tillträdes- och skyddsanordningar av koppar",
  "NSJ.41": "Stegar, stegjärn o d",
  "NSJ.411": "Stegar för fast vertikal montering",
  "NSJ.4111": "Stegar för fast vertikal montering utomhus",
  "NSJ.4112": "Stegar för fast vertikal montering inomhus",
  "NSJ.412": "Lösa stegar",
  "NSJ.413": "Glidskydd för lösa stegar",
  "NSJ.414": "Taktrappstegar",
  "NSJ.415": "Takpinnstegar",
  "NSJ.416": "Skorstensstegar",
  "NSJ.417": "Stegjärn",
  "NSJ.42": "Räcken vid uppstigningsöppningar på tak",
  "NSJ.43": "Gångbryggor, skorstensbryggor o d",
  "NSJ.431": "Gångbryggor",
  "NSJ.432": "Skorstensbryggor",
  "NSJ.44": "Nock- och takfotsräcken",
  "NSJ.441": "Nockräcken",
  "NSJ.442": "Takfotsräcken",
  "NSJ.45": "Förankringsöglor o d",
  "NSJ.451": "Förankringsöglor på tak",
  "NSJ.452": "Säkerhetshakar på tak",
  "NSJ.46": "Snörasskydd, snöglidhinder o d",
  "NSJ.461": "Snörasskydd",
  "NSJ.462": "Snöglidhinder",
  "NSJ.47": "Hållare, fästen m m på tak",
  "NSJ.471": "Hållare för tillfälliga skyddsräcken på yttertak",
  "NSJ.472": "Bärläktssteg",
  NSK: "TRAPPOR, RÄCKEN, BALKONGER M M",
  "NSK.1": "Trappor m m",
  "NSK.11": "Trappor av metall",
  "NSK.111": "Trappor av metall utomhus",
  "NSK.112": "Trappor av metall inomhus",
  "NSK.12": "Trappor av trä",
  "NSK.121": "Trappor av trä utomhus",
  "NSK.122": "Trappor av trä inomhus",
  "NSK.13": "Trappstegar inomhus",
  "NSK.131": "Trappstegar av metall inomhus",
  "NSK.132": "Trappstegar av trä inomhus",
  "NSK.2": "Ramper",
  "NSK.3": "Räcken, ledstänger o d",
  "NSK.31": "Räcken",
  "NSK.311": "Trappräcken",
  "NSK.3111": "Trappräcken utomhus",
  "NSK.3112": "Trappräcken inomhus",
  "NSK.312": "Balkongräcken, loftgångsräcken m m",
  "NSK.313": "Stödräcken",
  "NSK.315": "Avvisarräcken",
  "NSK.32": "Ledstänger",
  "NSK.321": "Ledstänger utomhus",
  "NSK.322": "Ledstänger inomhus",
  "NSK.4": "Balkonger",
  "NSK.41": "Balkonger av metall",
  NSM: "TAKLIST, SOCKELLIST, HÖRNLIST M M",
  "NSM.1": "Lister av metall",
  "NSM.11": "Taklister av metall",
  "NSM.12": "Golvlister av metall",
  "NSM.13": "Mattlister av metall",
  "NSM.15": "Hörnlister av metall",
  "NSM.152": "Hörnlister av rostfritt stål",
  "NSM.153": "Hörnlister av aluminium",
  "NSM.154": "Hörnlister av koppar och kopparlegeringar",
  "NSM.2": "Lister av trä inomhus",
  "NSM.21": "Lister av barrträ",
  "NSM.211": "Taklister av barrträ",
  "NSM.212": "Sockellister av barrträ",
  "NSM.214": "Täcklister av barrträ",
  "NSM.215": "Hörnlister av barrträ",
  "NSM.22": "Lister av lövträ",
  "NSM.28": "Diverse lister av trä",
  "NSM.281": "Kanalisationsprofiler av trä",
  "NSM.3": "Lister av träbaserat material",
  "NSM.38": "Diverse lister av träbaserat material",
  "NSM.381": "Kanalisationsprofiler av träbaserat material",
  "NSM.4": "Lister av plast",
  "NSM.48": "Diverse lister av plast",
  "NSM.481": "Kanalisationsprofiler av plast",
  NSN: "GALLER",
  P: "APPARATER, LEDNINGAR M M I RÖRSYSTEM ELLER RÖRLEDNINGSNÄT",
  PB: "RÖRLEDNINGAR I ANLÄGGNING",
  PBB: "RÖRLEDNINGAR I LEDNINGSGRAV",
  "PBB.1": "Ledning av gjutjärnsrör i ledningsgrav",
  "PBB.11": "Ledning av gråjärnsrör i ledningsgrav",
  "PBB.112": "Ledning av gråjärnsrör, avloppsrör, i ledningsgrav",
  "PBB.1121": "Ledning av gråjärnsrör, mufflösa avloppsrör, i ledningsgrav",
  "PBB.12": "Ledning av segjärnsrör i ledningsgrav",
  "PBB.121": "Ledning av segjärnsrör, tryckrör, i ledningsgrav",
  "PBB.1211": "Ledning av ytbehandlade segjärnsrör, tryckrör, i ledningsgrav",
  "PBB.122": "Ledning av segjärnsrör, avloppsrör, i ledningsgrav",
  "PBB.2": "Ledning av stålrör i ledningsgrav",
  "PBB.21": "Ledning av stålrör av olegerat stål i ledningsgrav",
  "PBB.211": "Ledning av gängade stålrör av handelskvalitet i ledningsgrav",
  "PBB.2111":
    "Ledning av skyddsmålade stålrör med medelgodstjocklek i ledningsgrav",
  "PBB.2112":
    "Ledning av förzinkade stålrör med medelgodstjocklek i ledningsgrav",
  "PBB.2113":
    "Ledning av skyddsmålade stålrör med stor godstjocklek i ledningsgrav",
  "PBB.212": "Ledning av ståltuber av handelskvalitet i ledningsgrav",
  "PBB.2121": "Ledning av icke ytbehandlade ståltuber i ledningsgrav",
  "PBB.2122": "Ledning av ytbehandlade ståltuber i ledningsgrav",
  "PBB.2123": "Ledning av isolerade ståltuber med mantelrör i ledningsgrav",
  "PBB.213": "Ledning av rör av olegerat tryckkärlsstål i ledningsgrav",
  "PBB.2131":
    "Ledning av icke fabriksisolerade rör av olegerat tryckkärlsstål i ledningsgrav",
  "PBB.2132":
    "Ledning av fabriksisolerade rör av olegerat tryckkärlsstål med mantelrör i ledningsgrav",
  "PBB.217":
    "Trumma av rör av vågprofilerad, förzinkad stålplåt, i ledningsgrav",
  "PBB.22": "Ledning av rör av legerat stål i ledningsgrav",
  "PBB.221":
    "Ledning av rör av rostfritt stål, ej tryckkärlsstål, i ledningsgrav",
  "PBB.222": "Ledning av rör av rostfritt tryckkärlsstål, i ledningsgrav",
  "PBB.2221":
    "Ledning av icke fabriksisolerade rör av rostfritt tryckkärlsstål i ledningsgrav",
  "PBB.2222":
    "Ledning av fabriksisolerade rör av rostfritt tryckkärlsstål i ledningsgrav",
  "PBB.3": "Ledning av kopparrör i ledningsgrav",
  "PBB.31": "Ledning av raka kopparrör i ledningsgrav",
  "PBB.311": "Ledning av oisolerade raka kopparrör i ledningsgrav",
  "PBB.313":
    "Ledning av raka kopparrör, isolerade och med mantelrör, i ledningsgrav",
  "PBB.3131":
    "Ledning av raka kopparrör med isolering av polyuretan och med mantelrör av polyeten i ledningsgrav",
  "PBB.3132":
    "Ledning av raka kopparrör med isolering av mineralull och med mantelrör av polyeten i ledningsgrav",
  "PBB.4": "Ledning av betongrör i ledningsgrav",
  "PBB.41": "Ledning av betongrör, tryckrör, i ledningsgrav",
  "PBB.411": "Ledning av betongrör, högtrycksrör, i ledningsgrav",
  "PBB.412": "Ledning av betongrör, lågtrycksrör, i ledningsgrav",
  "PBB.42": "Ledning av betongrör, avloppsrör, i ledningsgrav",
  "PBB.421": "Ledning av betongrör, normalavloppsrör, i ledningsgrav",
  "PBB.423": "Ledning av betongrör, dränrör, i ledningsgrav",
  "PBB.43": "Trumma av rör av betong, i ledningsgrav",
  "PBB.5": "Ledning av plaströr i ledningsgrav",
  "PBB.51": "Ledning av plaströr, tryckrör, i ledningsgrav",
  "PBB.511": "Ledning av PVC-rör, tryckrör, i ledningsgrav",
  "PBB.5111": "Ledning av PVC-rör, standardiserade tryckrör, i ledningsgrav",
  "PBB.512": "Ledning av PE-rör, tryckrör, i ledningsgrav",
  "PBB.5121": "Ledning av PE-rör, standardiserade tryckrör, i ledningsgrav",
  "PBB.5122":
    "Ledning av PE rör, standardiserade tryckrör, isolerade och med mantelrör, i ledningsgrav",
  "PBB.513": "Ledning av GRP-rör, tryckrör, i ledningsgrav",
  "PBB.5131": "Ledning av GRP-rör, standardiserade tryckrör, i ledningsgrav",
  "PBB.514": "Ledning av PEX-rör, tryckrör, i ledningsgrav",
  "PBB.5143": "Ledning av isolerade PEX-rör med mantelrör i ledningsgrav",
  "PBB.52": "Ledning av plaströr, avloppsrör, i ledningsgrav",
  "PBB.521": "Ledning av plaströr, markavloppsrör, i ledningsgrav",
  "PBB.5211":
    "Ledning av PVC-rör, standardiserade markavloppsrör, i ledningsgrav",
  "PBB.5212":
    "Ledning av PVC-rör, fabrikatspecifika markavloppsrör, i ledningsgrav",
  "PBB.5213":
    "Ledning av PE-rör, standardiserade markavloppsrör, i ledningsgrav",
  "PBB.5214":
    "Ledning av PE-rör, fabrikatspecifika markavloppsrör, i ledningsgrav",
  "PBB.5215":
    "Ledning av PP-rör, standardiserade markavloppsrör, i ledningsgrav",
  "PBB.5216":
    "Ledning av PP-rör, fabrikatspecifika markavloppsrör, i ledningsgrav",
  "PBB.5217": "Ledning av GRP-rör, markavloppsrör, i ledningsgrav",
  "PBB.5218":
    "Ledning av rör av modifierade plastmaterial, markavloppsrör, i ledningsgrav",
  "PBB.53": "Ledning av plaströr, dränrör, i ledningsgrav",
  "PBB.531": "Ledning av plaströr, standardiserade dränrör, i ledningsgrav",
  "PBB.532": "Ledning av plaströr, fabrikatspecifika dränrör, i ledningsgrav",
  "PBB.54": "Ledning av plaströr, infiltrationsrör, i ledningsgrav",
  "PBB.55": "Trumma av plaströr i ledningsgrav",
  "PBB.551": "Trumma av plaströr, standardiserade markavloppsrör",
  "PBB.552": "Trumma av plaströr, fabrikatspecifika markavloppsrör",
  "PBB.553": "Trumma av plaströr, övriga markavloppsrör",
  "PBB.8": "Ledning av rör av diverse material i ledningsgrav",
  "PBB.81": "Ledning av trärör i ledningsgrav",
  "PBB.811": "Ledning av trärör, tryckrör, i ledningsgrav",
  "PBB.812": "Ledning av trärör, avloppsrör, i ledningsgrav",
  "PBB.82": "Ledning av tegelrör, normenliga dränrör, i ledningsgrav",
  "PBB.83": "Ledning av polymerbetongrör, i ledningsgrav",
  PBC: "RÖRLEDNINGAR I SKYDDSLEDNING",
  "PBC.1": "Ledning av gjutjärnsrör i skyddsledning",
  "PBC.11": "Ledning av gråjärnsrör i skyddsledning",
  "PBC.112": "Ledning av gråjärnsrör, avloppsrör, i skyddsledning",
  "PBC.1121": "Ledning av gråjärnsrör, mufflösa avloppsrör, i skyddsledning",
  "PBC.12": "Ledning av segjärnsrör i skyddsledning",
  "PBC.121": "Ledning av segjärnsrör, tryckrör, i skyddsledning",
  "PBC.1211": "Ledning av ytbehandlade segjärnsrör, tryckrör, i skyddsledning",
  "PBC.1212":
    "Ledning av fabriksisolerade segjärnsrör, tryckrör med mantelrör, i skyddsledning",
  "PBC.122": "Ledning av segjärnsrör, avloppsrör, i skyddsledning",
  "PBC.2": "Ledning av stålrör i skyddsledning",
  "PBC.21": "Ledning av stålrör av olegerat stål i skyddsledning",
  "PBC.211": "Ledning av gängade stålrör av handelskvalitet i skyddsledning",
  "PBC.2111":
    "Ledning av skyddsmålade stålrör med medelgodstjocklek i skyddsledning",
  "PBC.2112":
    "Ledning av förzinkade stålrör med medelgodstjocklek i skyddsledning",
  "PBC.2113":
    "Ledning av skyddsmålade stålrör med stor godstjocklek i skyddsledning",
  "PBC.212": "Ledning av ståltuber av handelskvalitet i skyddsledning",
  "PBC.2121": "Ledning av icke ytbehandlade ståltuber i skyddsledning",
  "PBC.2122": "Ledning av ytbehandlade ståltuber i skyddsledning",
  "PBC.2123": "Ledning av isolerade ståltuber med mantelrör i skyddsledning",
  "PBC.213": "Ledning av rör av olegerat tryckkärlsstål i skyddsledning",
  "PBC.2131":
    "Ledning av icke fabriksisolerade rör av olegerat tryckkärlsstål i skyddsledning",
  "PBC.2132":
    "Ledning av fabriksisolerade rör av olegerat tryckkärlsstål med mantelrör i skyddsledning",
  "PBC.22": "Ledning av rör av legerat stål i skyddsledning",
  "PBC.221":
    "Ledning av rör av rostfritt stål, ej tryckkärlsstål, i skyddsledning",
  "PBC.222": "Ledning av rör av rostfritt tryckkärlsstål i skyddsledning",
  "PBC.2221":
    "Ledning av icke fabriksisolerade rör av rostfritt tryckkärlsstål i skyddsledning",
  "PBC.2222":
    "Ledning av fabriksisolerade rör av rostfritt tryckkärlsstål i skyddsledning",
  "PBC.3": "Ledning av kopparrör i skyddsledning",
  "PBC.31": "Ledning av raka kopparrör i skyddsledning",
  "PBC.311": "Ledning av oisolerade raka kopparrör i skyddsledning",
  "PBC.313":
    "Ledning av raka kopparrör, isolerade och med mantelrör, i skyddsledning",
  "PBC.3131":
    "Ledning av raka kopparrör med isolering av polyuretan och med mantelrör av polyeten i skyddsledning",
  "PBC.3132":
    "Ledning av raka kopparrör med isolering av mineralull och med mantelrör av polyeten i skyddsledning",
  "PBC.4": "Ledning av betongrör i skyddsledning",
  "PBC.41": "Ledning av betongrör, tryckrör, i skyddsledning",
  "PBC.411": "Ledning av betongrör, högtrycksrör, i skyddsledning",
  "PBC.412": "Ledning av betongrör, lågtrycksrör, i skyddsledning",
  "PBC.42": "Ledning av betongrör, avloppsrör, i skyddsledning",
  "PBC.421": "Ledning av betongrör, normalavloppsrör, i skyddsledning",
  "PBC.5": "Ledning av plaströr i skyddsledning",
  "PBC.51": "Ledning av plaströr, tryckrör, i skyddsledning",
  "PBC.511": "Ledning av PVC-rör, tryckrör, i skyddsledning",
  "PBC.5111": "Ledning av PVC-rör, standardiserade tryckrör, i skyddsledning",
  "PBC.512": "Ledning av PE-rör, tryckrör, i skyddsledning",
  "PBC.5121": "Ledning av PE-rör, standardiserade tryckrör, i skyddsledning",
  "PBC.5122":
    "Ledning av PE rör, standardiserade tryckrör, isolerade och med mantelrör, i skyddsledning",
  "PBC.513": "Ledning av GRP-rör, tryckrör, i skyddsledning",
  "PBC.5131": "Ledning av GRP-rör, standardiserade tryckrör, i skyddsledning",
  "PBC.514": "Ledning av PEX-rör, tryckrör, i skyddsledning",
  "PBC.5143": "Ledning av isolerade PEX-rör med mantelrör i skyddsledning",
  "PBC.52": "Ledning av plaströr, avloppsrör, i skyddsledning",
  "PBC.521": "Ledning av plaströr, markavloppsrör, i skyddsledning",
  "PBC.5211":
    "Ledning av PVC-rör, standardiserade markavloppsrör, i skyddsledning",
  "PBC.5212":
    "Ledning av PVC-rör, fabrikatspecifika markavloppsrör, i skyddsledning",
  "PBC.5213":
    "Ledning av PE-rör, standardiserade markavloppsrör, i skyddsledning",
  "PBC.5214":
    "Ledning av PE-rör, fabrikatspecifika markavloppsrör, i skyddsledning",
  "PBC.5215":
    "Ledning av PP-rör, standardiserade markavloppsrör, i skyddsledning",
  "PBC.5216":
    "Ledning av PP-rör, fabrikatspecifika markavloppsrör, i skyddsledning",
  "PBC.5217": "Ledning av GRP-rör, markavloppsrör, i skyddsledning",
  "PBC.5218":
    "Ledning av rör av modifierade plastmaterial, markavloppsrör, i skyddsledning",
  "PBC.8": "Ledning av rör av diverse material i skyddsledning",
  "PBC.83": "Ledning av polymerbetongrör, i skyddsledning",
  PBD: "RÖRLEDNINGAR PÅ STÖD",
  "PBD.1": "Ledning av gjutjärnsrör på stöd",
  "PBD.11": "Ledning av gråjärnsrör på stöd",
  "PBD.112": "Ledning av gråjärnsrör, avloppsrör, på stöd",
  "PBD.1121": "Ledning av gråjärnsrör, mufflösa avloppsrör, på stöd",
  "PBD.12": "Ledning av segjärnsrör på stöd",
  "PBD.121": "Ledning av segjärnsrör, tryckrör, på stöd",
  "PBD.1211": "Ledning av ytbehandlade segjärnsrör, tryckrör, på stöd",
  "PBD.1212":
    "Ledning av fabriksisolerade segjärnsrör, tryckrör med mantelrör, på stöd",
  "PBD.122": "Ledning av segjärnsrör, avloppsrör, på stöd",
  "PBD.2": "Ledning av stålrör på stöd",
  "PBD.21": "Ledning av stålrör av olegerat stål på stöd",
  "PBD.211": "Ledning av gängade stålrör av handelskvalitet på stöd",
  "PBD.2111": "Ledning av skyddsmålade stålrör med medelgodstjocklek på stöd",
  "PBD.2112": "Ledning av förzinkade stålrör med medelgodstjocklek på stöd",
  "PBD.2113": "Ledning av skyddsmålade stålrör med stor godstjocklek på stöd",
  "PBD.212": "Ledning av ståltuber av handelskvalitet på stöd",
  "PBD.2121": "Ledning av icke ytbehandlade ståltuber på stöd",
  "PBD.2122": "Ledning av ytbehandlade ståltuber på stöd",
  "PBD.2123": "Ledning av isolerade ståltuber med mantelrör på stöd",
  "PBD.213": "Ledning av rör av olegerat tryckkärlsstål på stöd",
  "PBD.2131":
    "Ledning av icke fabriksisolerade rör av olegerat tryckkärlsstål på stöd",
  "PBD.2132":
    "Ledning av fabriksisolerade rör av olegerat tryckkärlsstål med mantelrör på stöd",
  "PBD.22": "Ledning av rör av legerat stål på stöd",
  "PBD.221": "Ledning av rör av rostfritt stål, ej tryckkärlsstål, på stöd",
  "PBD.222": "Ledning av rör av rostfritt tryckkärlsstål på stöd",
  "PBD.2221":
    "Ledning av icke fabriksisolerade rör av rostfritt tryckkärlsstål på stöd",
  "PBD.2222":
    "Ledning av fabriksisolerade rör av rostfritt tryckkärlsstål på stöd",
  "PBD.3": "Ledning av kopparrör på stöd",
  "PBD.31": "Ledning av raka kopparrör på stöd",
  "PBD.311": "Ledning av oisolerade raka kopparrör, på stöd",
  "PBD.313": "Ledning av raka kopparrör, isolerade och med mantelrör, på stöd",
  "PBD.3131":
    "Ledning av raka kopparrör med isolering av polyuretan och med mantelrör av polyeten på stöd",
  "PBD.3132":
    "Ledning av raka kopparrör med isolering av mineralull och med mantelrör av polyeten på stöd",
  "PBD.4": "Ledning av betongrör på stöd",
  "PBD.41": "Ledning av betongrör, tryckrör, på stöd",
  "PBD.411": "Ledning av betongrör, högtrycksrör, på stöd",
  "PBD.412": "Ledning av betongrör, lågtrycksrör, på stöd",
  "PBD.42": "Ledning av betongrör, avloppsrör, på stöd",
  "PBD.421": "Ledning av betongrör, normalavloppsrör, på stöd",
  "PBD.5": "Ledning av plaströr på stöd",
  "PBD.51": "Ledning av plaströr, tryckrör, på stöd",
  "PBD.511": "Ledning av PVC-rör, tryckrör, på stöd",
  "PBD.5111": "Ledning av PVC-rör, standardiserade tryckrör, på stöd",
  "PBD.512": "Ledning av PE-rör, tryckrör, på stöd",
  "PBD.5121": "Ledning av PE-rör, standardiserade tryckrör, på stöd",
  "PBD.5122":
    "Ledning av PE rör, standardiserade tryckrör, isolerade och med mantelrör, på stöd",
  "PBD.513": "Ledning av GRP-rör, tryckrör, på stöd",
  "PBD.5131": "Ledning av GRP-rör, standardiserade tryckrör, på stöd",
  "PBD.514": "Ledning av PEX-rör, tryckrör, på stöd",
  "PBD.5143": "Ledning av isolerade PEX-rör med mantelrör på stöd",
  "PBD.52": "Ledning av plaströr, avloppsrör, på stöd",
  "PBD.521": "Ledning av plaströr, markavloppsrör, på stöd",
  "PBD.5211": "Ledning av PVC-rör, standardiserade markavloppsrör, på stöd",
  "PBD.5212": "Ledning av PVC-rör, fabrikatspecifika markavloppsrör, på stöd",
  "PBD.5213": "Ledning av PE-rör, standardiserade markavloppsrör, på stöd",
  "PBD.5214": "Ledning av PE-rör, fabrikatspecifika markavloppsrör, på stöd",
  "PBD.5215": "Ledning av PP-rör, standardiserade markavloppsrör, på stöd",
  "PBD.5216": "Ledning av PP-rör, fabrikatspecifika markavloppsrör, på stöd",
  "PBD.5217": "Ledning av GRP-rör, markavloppsrör, på stöd",
  "PBD.5218":
    "Ledning av rör av modifierade plastmaterial, markavloppsrör, på stöd",
  "PBD.8": "Ledning av rör av diverse material på stöd",
  "PBD.81": "Ledning av trärör på stöd",
  "PBD.811": "Ledning av trärör, tryckrör, på stöd",
  "PBD.812": "Ledning av trärör, avloppsrör, på stöd",
  "PBD.83": "Ledning av polymerbetongrör, på stöd",
  PBE: "NEDPLÖJDA RÖRLEDNINGAR",
  "PBE.5": "Nedplöjd ledning av plaströr",
  "PBE.51": "Nedplöjd ledning av plaströr, tryckrör",
  "PBE.511": "Nedplöjd ledning av PVC-rör, tryckrör",
  "PBE.512": "Nedplöjd ledning av PE-rör, tryckrör",
  "PBE.5121": "Nedplöjd ledning av PE-rör, standardiserade tryckrör",
  "PBE.513": "Nedplöjd ledning av GRP-rör, tryckrör",
  "PBE.5131": "Nedplöjd ledning av GRP-rör, standardiserade tryckrör",
  "PBE.514": "Nedplöjd ledning av PEX-rör, tryckrör",
  "PBE.53": "Nedplöjd ledning av plaströr, dränrör",
  "PBE.531": "Nedplöjd ledning av plaströr, standardiserade dränrör",
  "PBE.532": "Nedplöjd ledning av plaströr, fabrikatspecifika dränrör",
  PBF: "TRYCKTA ELLER BORRADE RÖRLEDNINGAR",
  "PBF.1": "Tryckt eller borrad ledning av gjutjärnsrör",
  "PBF.12": "Tryckt eller borrad ledning av segjärnsrör",
  "PBF.121": "Tryckt eller borrad ledning av segjärnsrör, tryckrör",
  "PBF.1211":
    "Tryckt eller borrad ledning av ytbehandlade segjärnsrör, tryckrör",
  "PBF.2": "Tryckt eller borrad ledning av stålrör",
  "PBF.21": "Tryckt eller borrad ledning av stålrör av olegerat stål",
  "PBF.212": "Tryckt eller borrad ledning av ståltuber av handelskvalitet",
  "PBF.2121": "Tryckt eller borrad ledning av icke ytbehandlade ståltuber",
  "PBF.2122": "Tryckt eller borrad ledning av ytbehandlade ståltuber ledning",
  "PBF.213": "Tryckt eller borrad ledning av rör av olegerat tryckkärlsstål",
  "PBF.2131":
    "Tryckt eller borrad ledning av icke fabriksisolerade rör av olegerat tryckkärlsstål",
  "PBF.2132":
    "Tryckt eller borrad ledning av fabriksisolerade rör av olegerat tryckkärlsstål med mantelrör",
  "PBF.22": "Tryckt eller borrad ledning av rör av legerat stål",
  "PBF.221":
    "Tryckt eller borrad ledning av rör av rostfritt stål, ej tryckkärlsstål",
  "PBF.222": "Tryckt eller borrad ledning av rör av rostfritt tryckkärlsstål",
  "PBF.2221":
    "Tryckt eller borrad ledning av icke fabriksisolerade rör av rostfritt tryckkärlsstål",
  "PBF.2222":
    "Tryckt eller borrad ledning av fabriksisolerade rör av rostfritt tryckkärlsstål",
  "PBF.4": "Tryckt eller borrad ledning av betongrör",
  "PBF.42": "Tryckt eller borrad ledning av betongrör, avloppsrör",
  "PBF.422": "Tryckt eller borrad ledning av betongrör, genomtryckningsrör",
  "PBF.43": "Tryckt eller borrad trumma av rör av betong",
  "PBF.5": "Tryckt eller borrad ledning av plaströr",
  "PBF.51": "Tryckt eller borrad ledning av plaströr, tryckrör",
  "PBF.512": "Tryckt eller borrad ledning av PE-rör, tryckrör",
  "PBF.5121": "Tryckt eller borrad ledning av PE-rör, standardiserade tryckrör",
  "PBF.52": "Tryckt eller borrad ledning av plaströr, avloppsrör",
  "PBF.521": "Tryckt eller borrad ledning av plaströr, markavloppsrör",
  "PBF.5212":
    "Tryckt eller borrad ledning av PVC-rör, fabrikatspecifika markavloppsrör",
  "PBF.5214":
    "Tryckt eller borrad ledning av PE-rör, fabrikatspecifika markavloppsrör",
  "PBF.5216":
    "Tryckt eller borrad ledning av PP-rör, fabrikatspecifika markavloppsrör",
  "PBF.5217": "Tryckt eller borrad ledning av GRP-rör, markavloppsrör",
  "PBF.8": "Tryckt eller borrad ledning av rör av diverse material",
  "PBF.83": "Tryckt eller borrad ledning av polymerbetongrör",
  PBG: "INFODRINGSLEDNINGAR I BEFINTLIGA RÖRLEDNINGAR",
  "PBG.1": "Infodringsledning av gjutjärnsrör",
  "PBG.12": "Infodringsledning av segjärnsrör",
  "PBG.121": "Infodringsledning av segjärnsrör, tryckrör",
  "PBG.1211": "Infordringsledning av ytbehandlade segjärnsrör, tryckrör",
  "PBG.2": "Infodringsledning av stålrör",
  "PBG.21": "Infodringsledning av stålrör av olegerat stål",
  "PBG.212": "Infodringsledning av ståltuber av handelskvalitet",
  "PBG.2121": "Infodringsledning av icke ytbehandlade ståltuber",
  "PBG.2122": "Infodringsledning av ytbehandlade ståltuber",
  "PBG.213": "Infodringsledning av rör av olegerat tryckkärlsstål",
  "PBG.2131":
    "Infodringsledning av icke fabrikisolerade rör av olegerat tryckkärlsstål",
  "PBG.22": "Infodringsledning av rör av legerat stål",
  "PBG.221": "Infodringsledning av rör av rostfritt stål, ej tryckkärlsstål",
  "PBG.222": "Infodringsledning av rör av rostfritt tryckkärlsstål",
  "PBG.2221":
    "Infodringsledning av icke fabriksisolerade rör av rostfritt tryckkärlsstål",
  "PBG.4": "Infodringsledning av betongrör",
  "PBG.42": "Infodringsledning av betongrör, avloppsrör",
  "PBG.421": "Infodringsledning av betongrör, normalavloppsrör",
  "PBG.422": "Infodringsledning av betongrör, genomtryckningsrör",
  "PBG.5": "Infodringsledning av plaströr",
  "PBG.51": "Infodringsledning av plaströr, tryckrör",
  "PBG.512": "Infodringsledning av PE-rör, tryckrör",
  "PBG.5121": "Infodringsledning av PE-rör, standardiserade tryckrör",
  "PBG.52": "Infodringsledning av plaströr, avloppsrör",
  "PBG.521": "Infodringsledning av plaströr, markavloppsrör",
  "PBG.5212": "Infodringsledning av PVC-rör, fabrikatspecifika markavloppsrör",
  "PBG.5214": "Infodringsledning av PE-rör, fabrikatspecifika markavloppsrör",
  "PBG.5216": "Infodringsledning av PP-rör, fabrikatspecifika markavloppsrör",
  "PBG.5217": "Infodringsledning av GRP-rör, markavloppsrör",
  PBH: "RÖRLEDNINGAR I SJÖ, VATTENDRAG E D",
  "PBH.1": "Ledning av gjutjärnsrör, i sjö, vattendrag e d",
  "PBH.12": "Ledning av segjärnsrör, i sjö, vattendrag e d",
  "PBH.121": "Ledning av segjärnsrör, tryckrör, i sjö, vattendrag e d",
  "PBH.1211":
    "Ledning av ytbehandlade segjärnsrör, tryckrör, i sjö, vattendrag e d",
  "PBH.2": "Ledning av stålrör, i sjö, vattendrag e d",
  "PBH.21": "Ledning av stålrör av olegerat stål, i sjö, vattendrag e d",
  "PBH.212": "Ledning av ståltuber av handelskvalitet, i sjö, vattendrag e d",
  "PBH.2122": "Ledning av ytbehandlade ståltuber, i sjö, vattendrag e d",
  "PBH.5": "Ledning av\nplaströr i sjö, vattendrag e d",
  "PBH.51": "Ledning av\nplaströr, tryckrör, i sjö, vattendrag e d",
  "PBH.512": "Ledning av\nPE-rör, tryckrör, i sjö, vattendrag e d",
  "PBH.5121":
    "Ledning av\nPE-rör, standardiserade tryckrör, i sjö, vattendrag e d",
  "PBH.513": "Ledning av GRP-rör, tryckrör, i sjö, vattendrag e d",
  "PBH.5131":
    "Ledning av GRP-rör, standardiserade tryckrör, i sjö, vattendrag e d",
  "PBH.8": "Ledning av rör av diverse material, i sjö, vattendrag e d",
  "PBH.81": "Ledning av trärör, i sjö, vattendrag e d",
  "PBH.811": "Ledning av trärör, tryckrör, i sjö, vattendrag e d",
  "PBH.812": "Ledning av trärör, avloppsrör, i sjö, vattendrag e d",
  PC: "ANSLUTNINGAR, FÖRANKRINGAR, KORROSIONSSKYDDSBEHANDLINGAR, INSPEKTION M M PÅ RÖRLEDNINGAR I ANLÄGGNING",
  PCB: "ANSLUTNINGAR AV RÖRLEDNING TILL RÖRLEDNING M M",
  "PCB.1": "Anslutningar av va-ledningar",
  "PCB.11": "Axiell anslutning av va-ledningar",
  "PCB.111": "Axiell anslutning av tryckledning",
  "PCB.112": "Axiell anslutning av självfallsledning",
  "PCB.12": "Anslutning med anborrning, grenrör e d av va-ledningar",
  "PCB.121": "Anslutning med anborrning, T-rör e d av tryckledning",
  "PCB.122": "Anslutning med anborrning, grenrör e d av självfallsledning",
  "PCB.123":
    "Anslutning med uppfräsning, anslutningsfoder e d av självfallsledning inifrån ledning",
  "PCB.13": "Anslutning av va-ledning till brunn, kammare e d",
  "PCB.131": "Anslutning av självfallsledning till brunn, kammare e d",
  "PCB.132": "Anslutning av tryckledning till brunn, kammare e d",
  "PCB.14": "Anslutning av va-ledning till tunnel",
  "PCB.2": "Anslutning av gasledningar",
  "PCB.21": "Axiell anslutning av gasledning",
  "PCB.22": "Anslutning med anborrning, T-rör e d av gasledning",
  "PCB.3": "Anslutning av dränledningar",
  "PCB.31": "Axiell anslutning av dränledning",
  "PCB.32": "Anslutning med anborrning, grenrör e d av dränledning",
  "PCB.4": "Anslutning av värmeledningar, kylledningar e d",
  "PCB.41": "Anslutning av värmeledningar",
  "PCB.411": "Axiell anslutning av värmeledning",
  "PCB.412": "Anslutning med anborrning, T-rör e d av värmeledning",
  "PCB.43": "Anslutning av kylledningar",
  "PCB.431": "Axiell anslutning av kylledning",
  "PCB.432": "Anslutning med anborrning, T-rör e d av kylledning",
  PCC: "ANORDNINGAR FÖR FÖRANKRING, EXPANSION, SKYDD M M AV RÖRLEDNING I ANLÄGGNING",
  "PCC.1": "Förankring på rörledning",
  "PCC.11": "Förankring med bojor på tryckrörsledning",
  "PCC.12": "Förankring av självfallsledning eller trumma",
  "PCC.2": "Fästdon, fixeringar, styrningar m m för rörledning",
  "PCC.21": "Fästdon för rörledning",
  "PCC.22": "Fixeringar för rörledning",
  "PCC.23": "Styrningar för rörledning",
  "PCC.24": "Stöd och fundament för rörledning",
  "PCC.25": "Monteringsboxar för rörledning",
  "PCC.3": "Genomföring för rörledning",
  "PCC.4":
    "Expansionselement, expansionsupptagande anordning o d för rörledning",
  "PCC.41": "Expansionselement för rörledning",
  "PCC.411": "Kompensator för rörledning",
  "PCC.4111": "Kompensator för va-ledning o d",
  "PCC.4112": "Kompensator för värmeledning o d",
  "PCC.412": "Expansionsböj för rörledning",
  "PCC.4121": "Expansionsböj för va-ledning o d",
  "PCC.4122": "Expansionsböj för värmeledning o d",
  "PCC.42": "Expansionsupptagande anordning för rörledning",
  "PCC.421": "Expansionsupptagande anordning för va-ledning o d",
  "PCC.422": "Expansionsupptagande anordning för värmeledning o d",
  "PCC.4221": "Expansionsupptagande anordning för värmeledning",
  "PCC.43": "Slangböj för rörledning",
  "PCC.5": "Larmanordning för rörledning",
  "PCC.51": "Larmanordning för va-ledning o d",
  "PCC.52": "Larmanordning för värmeledning o d",
  "PCC.521": "Larmanordning för värmeledning",
  "PCC.5211":
    "Larmanordning för indikering av fukt i isolering för värmeledning",
  "PCC.6": "Skydd för rörledning",
  "PCC.7": "Anordning för lokalisering och markering av markförlagd rörledning",
  "PCC.71": "Anordning för lokalisering av markförlagd rörledning",
  "PCC.72": "Anordning för markering av markförlagd rörledning",
  "PCC.721": "Anordning för markering med plastband",
  "PCC.8":
    "Diverse anordningar för förankring, expansion, skydd m m av rörledning i anläggning",
  "PCC.81": "Strömningsavskärande anordning",
  "PCC.811": "Strömningsavskärande anordning av gummiduk i jord",
  PCD: "KORROSIONSSKYDDSBEHANDLING AV RÖRLEDNINGAR I ANLÄGGNING",
  "PCD.1": "Korrosionsskyddsbehandling av markförlagd rörledning",
  "PCD.11": "Beläggning av lindad tejp på rörledning",
  "PCD.13": "Beläggning av bitumenmatta på rörledning",
  "PCD.2":
    "Korrosionsskyddsbehandling av rörfogar m m på markförlagd rörledning",
  "PCD.21": "Korrosionsskyddsbehandling av fogar, utvändigt skydd",
  "PCD.211":
    "Korrosionsskyddsbehandling av fogar, utvändigt skydd med bitumenbinda",
  "PCD.212":
    "Korrosionsskyddsbehandling av fogar, utvändigt skydd med krympslang, krympfilm eller tejp",
  "PCD.22": "Korrosionsskyddsbehandling av fogar, invändigt skydd",
  "PCD.221":
    "Korrosionsskyddsbehandling av fogar, invändigt skydd med bitumenlösning",
  "PCD.222":
    "Korrosionsskyddsbehandling av fogar, invändigt skydd med cementbruk",
  "PCD.23": "Korrosionsskyddsbehandling av påsvetsade rördetaljer",
  "PCD.3": "Reparation av utvändigt korrosionsskydd på rörledning",
  "PCD.31": "Reparation av utvändigt korrosionsskydd med bitumenlösning",
  "PCD.32": "Reparation av utvändigt korrosionsskydd med bitumenbinda",
  "PCD.33":
    "Reparation av utvändigt korrosionsskydd med krympslang, krympfilm eller tejp",
  "PCD.36": "Reparation av utvändigt korrosionsskydd med cementbruk",
  "PCD.4": "Reparation av korrosionsskydd för armatur och tillbehör",
  "PCD.5": "Reparation av invändigt korrosionsskydd på rörledning",
  "PCD.51": "Reparation av invändigt korrosionsskydd med bitumenlösning",
  "PCD.52": "Reparation av invändigt korrosionsskydd med cementbruk",
  "PCD.6": "Korrosionsskyddsbehandling av rörledning i brunn, kammare o d",
  "PCD.61":
    "Korrosionsskyddsbehandling av komponenter på värmekulvertledning i kammare",
  "PCD.62": "Korrosionsskyddsbehandling av va-ledning i brunn, kammare e d",
  "PCD.621": "Korrosionsskyddsbehandling av va-ledning i brunn eller kammare",
  "PCD.622": "Korrosionsskyddsbehandling av va-ledning i tunnel",
  PCE: "INSPEKTION AV RÖRLEDNINGAR I ANLÄGGNING",
  "PCE.1": "Inre inspektion av ledning",
  "PCE.11": "Inre inspektion av tryckledning",
  "PCE.12": "Inre inspektion av självfallsledning",
  "PCE.2": "Yttre inspektion av ledning",
  "PCE.21": "Yttre inspektion av ledning i mark",
  "PCE.22": "Yttre inspektion av ledning i sjö, vattendrag e d",
  "PCE.23": "Yttre inspektion av ledning på stöd",
  PCF: "RENGÖRING ELLER RENSNING AV HINDER E D I RÖRLEDNINGAR I ANLÄGGNING",
  "PCF.1": "Rengöring av tryckledning",
  "PCF.11": "Rengöring av va-ledning o d",
  "PCF.111": "Rengöring av vattenledning",
  "PCF.1111": "Spolning och desinfektion av vattenledning",
  "PCF.1112": "Mekanisk rengöring av vattenledning",
  "PCF.1113": "Rengöring av vattenledning genom spolning",
  "PCF.112": "Rengöring av tryckavloppsledning",
  "PCF.1121": "Rengöring av tryckavloppsledning genom spolning",
  "PCF.1122": "Mekanisk rengöring av tryckavloppsledning",
  "PCF.12": "Rengöring av värmeledning o d",
  "PCF.121": "Rengöring av värmeledning",
  "PCF.13": "Rengöring av gasledning o d",
  "PCF.131": "Rengöring av gasledning",
  "PCF.2": "Rengöring av självfallsledning",
  "PCF.21": "Rengöring av va-ledning e d",
  "PCF.212": "Rengöring av avloppsledning",
  "PCF.2121": "Rengöring av avloppsledning genom spolning",
  "PCF.2122": "Mekanisk rengöring av avloppsledning",
  PCG: "REPARATION AV RÖRLEDNINGAR I ANLÄGGNING",
  "PCG.1": "Reparation av rör i rörledning i anläggning",
  "PCG.11": "Reparation av rör i va-ledning",
  "PCG.111": "Reparation av rör i tryckledning",
  "PCG.112": "Reparation av rör i självfallsledning",
  "PCG.12": "Reparation av rör i gasledning",
  "PCG.2": "Reparation av fog i rörledning i anläggning",
  "PCG.21": "Reparation av fog i va-ledning",
  "PCG.211": "Reparation av fog i tryckledning",
  "PCG.212": "Reparation av fog i självfallsledning",
  "PCG.22": "Reparation av fog i gasledning",
  PD: "BRUNNAR O D I MARK",
  PDB: "BRUNNAR PÅ AVLOPPSLEDNING",
  "PDB.1": "Nedstigningsbrunn på avloppsledning",
  "PDB.11": "Nedstigningsbrunn av betong",
  "PDB.111": "Nedstigningsbrunn av betong, normalutförande",
  "PDB.112": "Nedstigningsbrunn av betong med sandfång",
  "PDB.113": "Nedstigningsbrunn av betong med inbyggd stalpledning",
  "PDB.114": "Nedstigningsbrunn av betong för rörtryckning",
  "PDB.115": "Nedstigningsbrunn av betong, elementbrunn",
  "PDB.12": "Nedstigningsbrunn av plast",
  "PDB.2": "Tillsynsbrunn på avloppsledning",
  "PDB.21": "Tillsynsbrunn av betong",
  "PDB.22": "Tillsynsbrunn av plast",
  "PDB.3": "Rensbrunn på avloppsledning",
  "PDB.31": "Rensbrunn av betong",
  "PDB.32": "Rensbrunn av plast",
  "PDB.5": "Dagvattenbrunn på avloppsledning",
  "PDB.51": "Dagvattenbrunn av betong",
  "PDB.511": "Dagvattenbrunn av betong med vattenlås och sandfång",
  "PDB.512": "Dagvattenbrunn av betong utan vattenlås, med sandfång",
  "PDB.513": "Dagvattenbrunn av betong utan vattenlås, utan sandfång",
  "PDB.52": "Dagvattenbrunn av plast",
  "PDB.521": "Dagvattenbrunn av plast med vattenlås och sandfång",
  "PDB.522": "Dagvattenbrunn av plast utan vattenlås, med sandfång",
  "PDB.523": "Dagvattenbrunn av plast utan vattenlås, utan sandfång",
  "PDB.6": "Dränbrunn på dränledning",
  "PDB.61": "Dränbrunn av betong",
  "PDB.62": "Dränbrunn av plast",
  PDC: "BRUNNAR PÅ SKYDDSLEDNING FÖR VA-LEDNING M M, TÖMNINGSLEDNING E D",
  "PDC.1":
    "Nedstigningsbrunn på skyddsledning för va-ledning m m, tömningsledning e d",
  "PDC.11":
    "Nedstigningsbrunn av betong på skyddsledning för va-ledning m m, tömningsledning e d",
  "PDC.111": "Nedstigningsbrunn av betong på skyddsledning",
  "PDC.112": "Nedstigningsbrunn av betong på tömningsledning",
  "PDC.1121":
    "Nedstigningsbrunn av betong på tömningsledning för tryckrörsledning över avloppsledning",
  "PDC.1122":
    "Nedstigningsbrunn av betong på tömningsledning för tryckrörsledning under avloppsledning",
  "PDC.1123":
    "Nedstigningsbrunn av betong på tömningsledning för tryckrörsledning utan anslutning till avloppsledning",
  "PDC.113":
    "Nedstigningsbrunn av betong för tryckrörsledning med luftningsanordning",
  "PDC.1131":
    "Nedstigningsbrunn av betong för tryckrörsledning med luftningsanordning på ledning i brunn",
  "PDC.1132":
    "Nedstigningsbrunn av betong för tryckrörsledning med luftningsanordning i brunn vid sidan av ledning",
  "PDC.1133":
    "Nedstigningsbrunn av betong för tryckrörsledning med luftningsanordning i brunn över ledning",
  "PDC.114":
    "Nedstigningsbrunn av betong för tryckrörsledning med mätdon på ledning i brunn",
  "PDC.115":
    "Nedstigningsbrunn av betong för tryckrörsledning med inhängd stigarledning i brunn",
  "PDC.116":
    "Nedstigningsbrunn av betong för tryckrörsledning med tryckreduceringsanordning",
  PDD: "BRUNNAR PÅ VÄRMELEDNING",
  PDE: "BRUNNAR PÅ SKYDDSRÖR FÖR KABEL",
  PDF: "AVSKILJNINGSBRUNNAR",
  "PDF.1": "Slamavskiljare",
  "PDF.2": "Oljeavskiljare",
  "PDF.3": "Fettavskiljare",
  PDH: "TILLBEHÖR TILL BRUNNAR",
  "PDH.1": "Komplettering och utbyte av tillbehör till brunn",
  "PDH.2": "Komplettering till platsgjuten brunn, kammare e d",
  PDY: "DIVERSE BRUNNAR O D I MARK",
  "PDY.1": "Pumpbrunn",
  "PDY.2": "Perkolationsbrunn",
  "PDY.21": "Perkolationsbrunn, typ 1",
  "PDY.22": "Perkolationsbrunn, typ 2",
  "PDY.3": "Stigortsbrunn",
  "PDY.4": "Infiltrationskassetter",
  "PDY.5": "Infiltrationsbrunn",
  "PDY.6": "Luftbrunn",
  PE: "ANORDNINGAR FÖR AVSTÄNGNING, TÖMNING, LUFTNING M M AV RÖRLEDNINGAR I ANLÄGGNING",
  PEB: "AVSTÄNGNINGSANORDNINGAR M M I MARK",
  "PEB.1": "Avstängningsanordning på tryckrörsledning",
  "PEB.11": "Avstängningsanordning på va-ledning",
  "PEB.111": "Avstängningsanordning på vattenledning",
  "PEB.1111": "Avstängningsanordning med kilslidsventil på vattenledning",
  "PEB.1112": "Avstängningsanordning med sätesventil på vattenledning",
  "PEB.1113": "Avstängningsanordning med vridspjällsventil på vattenledning",
  "PEB.112": "Avstängningsanordning på tryckspillvattenledning",
  "PEB.1121":
    "Avstängningsanordning med kilslidsventil på tryckspillvattenledning",
  "PEB.12": "Avstängningsanordning på värmeledning",
  "PEB.2": "Avstängningsanordning på självfallsledning",
  "PEB.3": "Spolpost i mark",
  "PEB.31": "Spolpost på vattenledning",
  "PEB.32": "Spolpost på tryckspillvattenledning",
  "PEB.4": "Brandpost i mark",
  "PEB.41": "Brandpost med kort trumma",
  "PEB.42": "Brandpost med lång trumma",
  "PEB.5":
    "Tillbehör till anordningar i mark för avstängning, tömning, luftning m m",
  "PEB.6": "Anordning för vattenuttag",
  "PEB.61": "Dricksfontän",
  "PEB.62": "Vattenutkastare",
  "PEB.63": "Tappställe med anslutning för slang e d",
  "PEB.7": "Bakåtströmningshindrande anordning",
  "PEB.71": "Bakåtströmningshindrande anordning på tryckrörsledning",
  "PEB.72": "Bakåtströmningshindrande anordning på självfallsledning",
  PEC: "ANORDNINGAR I UTRYMME ELLER OVAN MARK FÖR AVSTÄNGNING M M",
  "PEC.1": "Avstängningsanordning på tryckrörsledning",
  "PEC.11": "Avstängningsanordning på va-ledning",
  "PEC.111": "Avstängningsanordning på vattenledning",
  "PEC.1111": "Avstängningsanordning med kilslidsventil på vattenledning",
  "PEC.1112": "Avstängningsanordning med sätesventil på vattenledning",
  "PEC.1113": "Avstängningsanordning med vridspjällsventil på vattenledning",
  "PEC.112": "Avstängningsanordning på tryckspillvattenledning",
  "PEC.1121":
    "Avstängningsanordning med kilslidsventil på tryckspillvattenledning",
  "PEC.1122":
    "Avstängningsanordning med skjutspjällsventil på tryckspillvattenledning",
  "PEC.12": "Avstängningsanordning på värmeledning",
  "PEC.2": "Avstängningsanordning på självfallsledning",
  "PEC.21": "Avstängningsanordning på avloppsledning",
  "PEC.211": "Avstängningsanordning med skjutspjällsventil på avloppsledning",
  "PEC.212": "Avstängningsanordning med slusslucka e d på avloppsledning",
  "PEC.3": "Avtappningsanordning på rörledning",
  "PEC.31": "Avtappningsanordning på värmeledning",
  "PEC.32": "Avtappningsanordning på vattenledning",
  "PEC.4": "Luftningsanordning på tryckrörsledning",
  "PEC.41": "Luftningsanordning på va-ledning",
  "PEC.411": "Luftningsanordning på vattenledning",
  "PEC.412": "Luftningsanordning på tryckspillvattenledning",
  "PEC.42": "Luftningsanordning på värmeledning",
  "PEC.5":
    "Tillbehör till anordningar i brunn eller kammare, för avstängning, tömning, luftning m m",
  "PEC.6": "Anordning för vattenuttag",
  "PEC.61": "Spolpost i anläggningsutrymme",
  "PEC.7": "Bakåtströmningshindrande och flödesreglerande anordning e d",
  "PEC.71": "Bakåtströmningshindrande anordning på tryckrörsledning",
  "PEC.72": "Bakåtströmningshindrande anordning på självfallsledning",
  "PEC.74": "Flödesreglerande anordning på självfallsledning",
  "PEC.8": "Diverse anordningar i utrymme eller ovan mark för avstängning e d",
  "PEC.81": "Tryckreducerande anordning",
  "PEC.811": "Tryckreducerande anordning på va-ledning",
  "PEC.8111": "Tryckreducerande anordning på vattenledning",
  "PEC.82": "Vattenmätaranordning",
  PF: "PUMPANORDNINGAR I ANLÄGGNING",
  PFB: "PUMPANORDNINGAR I VA-ANLÄGGNING",
  "PFB.1": "Pumpanordning på råvattenledning i va-anläggning",
  "PFB.2": "Pumpanordningar på vattenledning i va-anläggning",
  "PFB.3": "Pumpanordningar på avloppsledning i va-anläggning",
  PG: "RENOVERING AV RÖRLEDNINGAR M M I ANLÄGGNING",
  PGB: "RENOVERING AV RÖRLEDNINGAR",
  "PGB.1": "Renovering av rörledning med formpassat rör",
  "PGB.11": "Renovering av ledning med formpassat rör för tryckledning",
  "PGB.12": "Renovering av ledning med formpassat rör för självfallsledning",
  "PGB.2": "Renovering av självfallsledning med spirallindat rör",
  "PGB.3": "Renovering av självfallsledning med rörsegment",
  "PGB.4": "Renovering av rörledning med flexibelt foder",
  "PGB.41": "Renovering av ledning med flexibelt foder för tryckledning",
  "PGB.42": "Renovering av ledning med flexibelt foder för självfallsledning",
  "PGB.43": "Renovering av trumma med flexibelt foder",
  "PGB.5": "Renovering av tryckledning med tryckslang",
  "PGB.6": "Renovering av rörledning med fogtätning",
  "PGB.61": "Renovering av självfallsledning med fogtätning",
  "PGB.7": "Renovering av rörledning med skyddsbeläggning",
  "PGB.71": "Renovering av rörledning med cementbruk",
  "PGB.711": "Renovering av tryckledning av gjutjärn eller stål med cementbruk",
  "PGB.72": "Renovering av rörledning med epoxi",
  "PGB.73": "Renovering av rörledning med polyuretan",
  "PGB.8": "Renovering av rörledning med diverse metoder",
  PGC: "RENOVERING AV ANORDNINGAR, BRUNNAR M M PÅ RÖRLEDNING",
  "PGC.1": "Renovering av brunn",
  "PGC.11": "Renovering av brunn på avloppsledning",
  "PGC.111": "Renovering av brunn genom installation av flexibelt foder",
  "PGC.112": "Renovering av brunn med beläggning genom sprutning e d",
  "PGC.113": "Renovering av brunn med formpassat material",
  "PGC.114": "Renovering av brunn med tätning genom injektering",
  "PGC.115": "Renovering av brunn med ilagning genom putsning och gjutning",
  "PGC.116": "Renovering av brunn genom installation av paneler e d",
  "PGC.117":
    "Renovering av brunn genom installation av ny brunn i befintlig brunn",
  Q: "APPARATER, KANALER, DON M M I LUFTBEHANDLINGSSYSTEM",
  S: "APPARATER, UTRUSTNING, KABLAR M M I EL- OCH TELESYSTEM",
  U: "APPARATER FÖR STYRNING OCH ÖVERVAKNING",
  W: "APPARATER, MASKINER M M I TRANSPORTSYSTEM",
  WB: "APPARATER, MASKINER M M I HISSYSTEM",
  X: "INREDNINGAR OCH UTRUSTNINGAR",
  XB: "INREDNINGAR I BOSTÄDER, KONTOR, UNDERVISNINGSLOKALER, VÅRDLOKALER, BUTIKSLOKALER M M",
  XBA: "SAMMANSATTA INREDNINGAR I BOSTÄDER, KONTOR, UNDERVISNINGSLOKALER, VÅRDLOKALER, BUTIKSLOKALER M M",
  XBB: "SKÄRMENHETER",
  "XBB.1": "Golvmonterade skärmar",
  "XBB.2": "Takmonterade skärmar",
  "XBB.3": "Lösa skärmar",
  "XBB.4": "Inredningspaneler",
  "XBB.9": "Övriga skärmenheter",
  XBC: "TAVELENHETER",
  "XBC.0": "Sammansatta tavelenheter",
  "XBC.1": "Tavlor",
  "XBC.3": "Register, skyltar o d",
  "XBC.5": "Speglar",
  "XBC.6": "Tavelenheter för bildprojektion",
  "XBC.9": "Övriga tavelenheter",
  XBD: "FÖRVARINGSENHETER",
  "XBD.0": "Sammansatta förvaringsenheter",
  "XBD.1": "Skåp",
  "XBD.2": "Hurtsar",
  "XBD.3": "Hyllenheter",
  "XBD.5": "Hållare, hängare, krokar m m",
  "XBD.6": "Behållare, lådor och kärl",
  "XBD.9": "Övriga förvaringsenheter",
  XBE: "BORDSENHETER",
  "XBE.1": "Bord",
  "XBE.2": "Bänkskivor o d",
  "XBE.4": "Diskar",
  "XBE.6": "Bänkar",
  "XBE.9": "Övriga bordsenheter",
  XBF: "SITTENHETER",
  "XBF.1": "Pallar o d",
  "XBF.2": "Stolar",
  "XBF.3": "Fåtöljer",
  "XBF.4": "Soffor",
  "XBF.6": "Sittbänkar",
  "XBF.9": "Övriga sittenheter",
  XBG: "LIGGENHETER",
  "XBG.1": "Sängar",
  "XBG.2": "Britsar",
  "XBG.8": "Diverse liggenheter",
  "XBG.9": "Övriga liggenheter",
  XBH: "TEXTILA ENHETER M M",
  "XBH.1": "Gardiner",
  "XBH.2": "Draperier, förhängen o d",
  "XBH.3": "Inredningsridåer",
  "XBH.4": "Överkast",
  "XBH.5": "Mattor",
  "XBH.6": "Väggtextilier",
  "XBH.9": "Övriga textila enheter m m",
  XBY: "DIVERSE INREDNINGAR I BOSTÄDER, KONTOR, UNDERVISNINGSLOKALER, VÅRDLOKALER, BUTIKSLOKALER M M",
  "XBY.1": "Tekniska inredningsenheter",
  "XBY.2": "Kontorsutrustning",
  "XBY.3": "Växter och växtarrangemang",
  "XBY.4": "Konst och dekoration",
  "XBY.5": "Inredningar för speciella ändamål",
  XBZ: "ÖVRIGA INREDNINGAR I BOSTÄDER, KONTOR, UNDERVISNINGSLOKALER, VÅRDLOKALER, BUTIKSLOKALER M M",
  XC: "INREDNINGAR FÖR STORDRIFTSBEREDNING, TILLAGNING ELLER SERVERING AV LIVSMEDEL",
  XCB: "INREDNINGSENHETER I STORKÖK E D",
  "XCB.1": "Bänkar m m",
  "XCB.2": "Skåp",
  "XCB.3": "Hyllor",
  "XCB.31": "Konsolhyllor och hyllställningar",
  "XCB.4": "Hållare, hängare, ställ o d",
  "XCB.5": "Inredningsenheter i serveringsutrymmen",
  "XCB.51": "Serveringsdiskar",
  "XCB.52": "Inredningsenheter i råkoststationer",
  "XCB.53": "Inredningsenheter i kaffestationer",
  "XCB.54": "Montrar",
  "XCB.55": "Inredningsenheter i kassastationer",
  "XCB.56": "Brickbanor, diskfronter o d",
  "XCB.8": "Diverse inredningsenheter i storkök e d",
  "XCB.81": "Inredningsenheter för ledningskanalisation",
  XK: "UTRUSTNINGAR FÖR MATLAGNING, FÖRVARING ELLER RENGÖRING I BOSTAD E D",
  XKA: "SAMMANSATTA UTRUSTNINGAR FÖR MATLAGNING, FÖRVARING ELLER RENGÖRING I BOSTAD E D",
  "XKA.1": "Utrustningar med sammansatt funktion i bostadskök e d",
  XKC: "KOK- OCH STEKAPPARATER M M I BOSTAD E D",
  "XKC.1": "Spisar och spishällar i bostad e d",
  "XKC.11": "Elspisar i bostad e d",
  "XKC.111": "Elspisar med metallhäll i bostad e d",
  "XKC.112": "Elspisar med glaskeramikhäll i bostad e d",
  "XKC.113": "Induktionsspisar i bostad e d",
  "XKC.12": "Gasspisar i bostad e d",
  "XKC.2": "Ugnar i bostad e d",
  "XKC.21": "Stekugnar i bostad e d",
  "XKC.22": "Kombiugnar och hetluftsugnar i bostad e d",
  "XKC.23": "Mikrovågsugnar i bostad e d",
  XKF: "DISKMASKINER I BOSTAD E D",
  XKG: "KYLENHETER OCH FRYSENHETER I BOSTAD E D",
  "XKG.0": "Kyl- och frysskåp i bostad e d",
  "XKG.1": "Kylskåp i bostad e d",
  "XKG.2": "Frysskåp i bostad e d",
  "XKG.3": "Frysboxar i bostad e d",
  XKH: "UTRUSTNINGAR FÖR TVÄTT ELLER RENGÖRING I BOSTAD E D",
  "XKH.1": "Tvättmaskiner",
  "XKH.2": "Torktumlare",
  "XKH.21": "Torktumlare med ventilation",
  "XKH.22": "Kondenstorktumlare",
  "XKH.3": "Torkskåp",
  "XKH.4": "Manglar",
  "XKH.5": "Centrifuger",
  "XKH.6": "Centraldammsugaraggregat",
  XL: "UTRUSTNINGAR OCH MASKINER FÖR STORDRIFTSBEREDNING, TILLAGNING ELLER SERVERING AV LIVSMEDEL",
  XLB: "UTRUSTNINGAR OCH MASKINER FÖR BEARBETNING M M AV LIVSMEDEL I STORKÖK E D",
  "XLB.0":
    "Utrustningar och maskiner med sammansatt funktion för bearbetning m m av livsmedel i storkök e d",
  "XLB.01": "Universalmaskiner",
  "XLB.1": "Sköljmaskiner och skalmaskiner",
  "XLB.2": "Skärmaskiner för livsmedel",
  "XLB.21": "Skivningsmaskiner",
  "XLB.22": "Brödskärningsmaskiner",
  "XLB.23": "Grönsaksskärare",
  "XLB.3": "Hackmaskiner för livsmedel",
  "XLB.31": "Snabbhackar",
  "XLB.4": "Livsmedelssågar",
  "XLB.41": "Köttsågar",
  "XLB.5": "Kvarnar för livsmedel",
  "XLB.51": "Köttkvarnar",
  "XLB.6": "Vispmaskiner och blandningsmaskiner för livsmedel",
  "XLB.61": "Vispmaskiner",
  "XLB.62": "Blandningsmaskiner",
  "XLB.7": "Glassmaskiner",
  "XLB.8":
    "Diverse utrustningar och maskiner för bearbetning av livsmedel i storkök e d",
  XLC: "KOKAPPARATER OCH STEKAPPARATER M M I STORKÖK E D",
  "XLC.0": "Sammansatta kokapparater och stekbord m m i storkök e d",
  "XLC.1": "Spisar",
  "XLC.10": "Kombinationsspisar",
  "XLC.101": "Kombinationsspisar för el och gas",
  "XLC.11": "Elspisar",
  "XLC.111": "Elspisar med metallhäll",
  "XLC.112": "Elspisar med glaskeramikhäll",
  "XLC.113": "Induktionsspisar",
  "XLC.12": "Gasspisar",
  "XLC.2": "Ugnar",
  "XLC.21": "Stekugnar",
  "XLC.22": "Varmlufts-, konvektions- och kombiugnar",
  "XLC.23": "Pizzaugnar",
  "XLC.24": "Mikrovågsugnar",
  "XLC.25": "Bandugnar",
  "XLC.3": "Kokapparater",
  "XLC.31": "Kokerier",
  "XLC.32": "Tryckkokare",
  "XLC.33": "Kokgrytor",
  "XLC.4": "Kontaktstekningsapparater",
  "XLC.41": "Stekbord",
  "XLC.42": "Stekhällar",
  "XLC.43": "Grillar",
  "XLC.44": "Fritöser",
  "XLC.45": "Salamandrar",
  "XLC.5": "Brödrostar",
  XLD: "APPARATER FÖR TILLREDNING OCH SERVERING ELLER DOSERING AV DRYCKER I STORKÖK E D",
  "XLD.1":
    "Apparater för tillredning och servering eller dosering av varma drycker",
  "XLD.11": "Pumpbryggare",
  "XLD.12": "Bryggare med flyttbara serveringskärl",
  "XLD.13": "Urnbryggare",
  "XLD.14": "Helautomatiska bryggare",
  "XLD.141": "Helautomatiska kaffebryggare",
  "XLD.142": "Helautomatiska espressobryggare",
  "XLD.15": "Manuella espressobryggare",
  "XLD.16": "Varmvattenbehållare för drycker",
  "XLD.2":
    "Apparater för tillredning och servering eller dosering av kalla drycker",
  "XLD.21": "Apparater för framställning av juice",
  "XLD.22": "Sifoner, doseringsapparater o d",
  "XLD.23": "Mjölkdispensrar",
  "XLD.24": "Dryckeskylare",
  XLE: "VARMHÅLLNINGSUTRUSTNINGAR I STORKÖK E D",
  "XLE.1": "Värmerier",
  "XLE.2": "Vattenbad",
  "XLE.3": "Värmeskåp",
  "XLE.4": "Värmehurtsar",
  "XLE.5": "Värmehällar",
  "XLE.6": "Jässkåp",
  "XLE.7": "Värmemontrar",
  "XLE.8": "Diverse varmhållningsutrustningar i storkök e d",
  "XLE.81": "Värmelister",
  "XLE.82": "Värmelampor",
  XLF: "DISKMASKINER I STORKÖK E D",
  "XLF.1": "Korgdiskmaskiner",
  "XLF.11": "Underbänksdiskmaskiner",
  "XLF.12": "Huvdiskmaskiner",
  "XLF.13": "Tunneldiskmaskiner",
  "XLF.14": "Fördiskmaskiner",
  "XLF.2": "Banddiskmaskiner",
  "XLF.3": "Grovdiskmaskiner",
  "XLF.31": "Grovdiskmaskiner utan granuler",
  "XLF.32": "Grovdiskmaskiner med granuler",
  "XLF.4": "Kombidiskmaskiner",
  "XLF.41": "Kombidiskmaskiner utan granuler",
  "XLF.42": "Kombidiskmaskiner med granuler",
  "XLF.8": "Diverse diskmaskiner i storkök e d",
  "XLF.81": "Glasdiskmaskiner",
  "XLF.82": "Brick- och tallriksdiskmaskiner",
  "XLF.83": "Bestickdiskmaskiner",
  "XLF.84": "Vagndiskmaskiner i storkök e d",
  "XLF.85": "Kombidiskmaskiner för brickor, bestick e d",
  XLG: "RULLBANOR, TRANSPORTÖRER M M I STORKÖK E D",
  "XLG.1": "Rullbanor för brickor och korgar",
  "XLG.11": "Bassängbanor",
  "XLG.12": "Rullbanor",
  "XLG.13": "Hjulbanor",
  "XLG.2": "Transportörer för brickor och korgar",
  "XLG.21": "Bandtransportörer och remtransportörer",
  "XLG.22": "Kedjetransportörer och lamelltransportörer",
  "XLG.23": "Vertikaltransportörer",
  "XLG.24": "Hängtransportörer",
  "XLG.25": "Ackumuleringstransportörer",
  "XLG.26": "Bricklastväxlare",
  "XLG.3": "Utrustningar för sortering och plockning i diskrum",
  "XLG.31": "Disksorteringsbord",
  "XLG.32": "Bestickavplockare",
  "XLG.33": "Besticksorteringsmaskiner",
  "XLG.4": "Korgstyrningsmaskiner",
  "XLG.41": "Korgavlastare",
  "XLG.42": "Korgväxel för sidoöverföring",
  XLH: "VAGNAR FÖR STORKÖK E D",
  "XLH.1": "Bordsvagnar",
  "XLH.2": "Bassängvagnar",
  "XLH.3": "Lyftvagnar",
  "XLH.4": "Dispenservagnar",
  "XLH.5": "Vagnar för brickor, korgar, kantiner e d",
  "XLH.6": "Mattransportvagnar",
  XLY: "DIVERSE UTRUSTNINGAR I STORKÖK E D",
  "XLY.0": "Diverse utrustningar med sammansatt funktion i storkök e d",
  "XLY.01": "Temperaturloggningsutrustningar",
  "XLY.1": "Vakuumförpackningsutrustningar",
  "XLY.2": "Konservöppnare",
  "XLY.3": "Vågar",
  "XLY.4": "Portioneringsutrustningar",
  "XLY.5": "Varuautomater för livsmedel",
  "XLY.6": "Tömningsutrustningar",
  "XLY.7": "Rengöringsutrustningar i storkök",
  XM: "KYLENHETER OCH FRYSENHETER M M",
  XMB: "KYLENHETER OCH FRYSENHETER FÖR LIVSMEDEL M M",
  "XMB.1": "Kylmöbler",
  "XMB.11": "Kylskåp",
  "XMB.111": "Kylskåp med inbyggt kylaggregat",
  "XMB.112": "Kylskåp anslutna till köldmediesystem",
  "XMB.113": "Kylskåp anslutna till köldbärarsystem",
  "XMB.114": "Dryckeskylskåp",
  "XMB.1141": "Dryckeskylskåp med inbyggt kylaggregat",
  "XMB.1142": "Dryckeskylskåp anslutna till köldmediesystem",
  "XMB.1143": "Dryckeskylskåp anslutna till köldbärarsystem",
  "XMB.12": "Kylbänkar och kylbrunnar",
  "XMB.121": "Kylbänkar och kylbrunnar med inbyggt kylaggregat",
  "XMB.122": "Kylbänkar och kylbrunnar anslutna till köldmediesystem",
  "XMB.123": "Kylbänkar och kylbrunnar anslutna till köldbärarsystem",
  "XMB.13": "Kylmontrar",
  "XMB.131": "Kylmontrar med inbyggt kylaggregat",
  "XMB.132": "Kylmontrar anslutna till köldmediesystem",
  "XMB.133": "Kylmontrar anslutna till köldbärarsystem",
  "XMB.14": "Kyldiskar",
  "XMB.141": "Kyldiskar med inbyggt kylaggregat",
  "XMB.142": "Kyldiskar anslutna till köldmediesystem",
  "XMB.143": "Kyldiskar anslutna till köldbärarsystem",
  "XMB.15": "Kylridåer",
  "XMB.151": "Kylridåer med inbyggt kylaggregat",
  "XMB.152": "Kylridåer anslutna till köldmediesystem",
  "XMB.153": "Kylridåer anslutna till köldbärarsystem",
  "XMB.17": "Upptiningsskåp",
  "XMB.171": "Upptiningsskåp med inbyggt kylaggregat",
  "XMB.172": "Upptiningsskåp anslutna till köldmediesystem",
  "XMB.173": "Upptiningsskåp anslutna till köldbärarsystem",
  "XMB.2": "Frysmöbler",
  "XMB.22": "Frysskåp",
  "XMB.221": "Frysskåp med inbyggt kylaggregat",
  "XMB.222": "Frysskåp anslutna till köldmediesystem",
  "XMB.23": "Frysboxar",
  "XMB.24": "Frysdiskar",
  "XMB.241": "Frysdiskar med inbyggt kylaggregat",
  "XMB.242": "Frysdiskar anslutna till köldmediesystem",
  "XMB.25": "Frysbänkar",
  "XMB.251": "Frysbänkar med inbyggt kylaggregat",
  "XMB.252": "Frysbänkar anslutna till köldmediesystem",
  "XMB.3": "Kylrum med inbyggt kylaggregat",
  "XMB.4": "Klimatrum och klimatskåp med fabriksmonterad kylutrustning",
  "XMB.41": "Klimatrum och klimatskåp med inbyggt kylaggregat",
  "XMB.42": "Klimatrum och klimatskåp anslutna till köldmediesystem",
  "XMB.43": "Klimatrum och klimatskåp anslutna till köldbärarsystem",
  "XMB.5": "Utrustningar för nedkylning",
  "XMB.51": "Nedkylningsskåp",
  "XMB.511": "Nedkylningsskåp med inbyggt kylaggregat",
  "XMB.512": "Nedkylningsskåp anslutna till köldmediesystem",
  "XMB.52": "Snabbnedkylningsskåp",
  "XMB.521": "Snabbnedkylningsskåp med inbyggt kylaggregat",
  "XMB.522": "Snabbnedkylningsskåp anslutna till köldmediesystem",
  "XMB.53": "Snabbnedkylningsrum",
  "XMB.531": "Snabbnedkylningsrum med inbyggt kylaggregat",
  "XMB.532": "Snabbnedkylningsrum anslutna till köldmediesystem",
  "XMB.6": "Utrustningar för infrysning",
  "XMB.61": "Infrysningsskåp",
  "XMB.611": "Infrysningsskåp med inbyggt kylaggregat",
  "XMB.612": "Infrysningsskåp anslutna till köldmediesystem",
  "XMB.62": "Snabbinfrysningsskåp",
  "XMB.621": "Snabbinfrysningsskåp med inbyggt kylaggregat",
  "XMB.622": "Snabbinfrysningsskåp anslutna till köldmediesystem",
  "XMB.63": "Snabbinfrysningsrum",
  "XMB.631": "Snabbinfrysningsrum med inbyggt kylaggregat",
  "XMB.632": "Snabbinfrysningsrum anslutna till köldmediesystem",
  "XMB.8": "Diverse kylenheter och frysenheter för livsmedel m m",
  "XMB.81": "Ismaskiner",
  "XMB.811": "Ismaskiner med inbyggt kylaggregat",
  "XMB.812": "Ismaskiner anslutna till köldmediesystem",
  "XMB.82": "Övriga kylmöbler",
  XZ: "ÖVRIGA INREDNINGAR OCH UTRUSTNINGAR",
  Y: "MÄRKNING, KONTROLL, DOKUMENTATION M M",
  YB: "MÄRKNING, KONTROLL, INJUSTERING M M AV ANLÄGGNING",
  YBB: "MÄRKNING OCH SKYLTNING M M AV ANLÄGGNING",
  "YBB.1": "Märkning av anläggning",
  "YBB.11": "Märkning av pumpanordning i anläggning",
  "YBB.2": "Skyltning för anläggning",
  "YBB.23": "Skyltning för tunnel, bergrum e d",
  "YBB.231": "Skyltning för tunnel",
  YBC: "KONTROLL AV ANLÄGGNING",
  "YBC.3": "Kontroll av rörledning m m",
  "YBC.31": "Kontroll av vattenledning",
  "YBC.311": "Tryck- och täthetskontroll av vattenledning",
  "YBC.3111":
    "Tryck- och täthetskontroll av vattenledning av segjärnsrör, stålrör m fl",
  "YBC.3112":
    "Tryck- och täthetskontroll av vattenledning av PVC-rör, GRP-rör m fl",
  "YBC.3113":
    "Tryck- och täthetskontroll av vattenledning av rör av PE, PP och PB",
  "YBC.312": "Kontroll av riktningsavvikelse hos vattenledning",
  "YBC.32": "Kontroll av värmeledning",
  "YBC.321": "Kontroll av medierör i värmeledning",
  "YBC.3211": "Tryck- och täthetskontroll av medierör på värmeledning",
  "YBC.322": "Kontroll av fogar på mantelrör i värmeledning",
  "YBC.323": "Kontroll av fuktövervakningssystem i värmeledning",
  "YBC.33": "Kontroll av svetsfogar på rör",
  "YBC.331": "Kontroll av svetsfogar på rör av stål",
  "YBC.332": "Kontroll av svetsfogar på rör av PE",
  "YBC.34": "Kontroll av avloppsledning e d",
  "YBC.341": "Täthetskontroll av avloppsledning",
  "YBC.3411": "Tryck- och täthetskontroll av tryckavloppsledning",
  "YBC.3412": "Täthetskontroll av självfallsledning",
  "YBC.342": "Deformationskontroll av avloppsledning",
  "YBC.343": "Kontroll av riktningsavvikelse hos avloppsledning",
  "YBC.346": "Kontroll av riktningsavvikelse hos dränledning",
  "YBC.35": "Kontroll av brunn på avloppsledning e d",
  "YBC.351": "Täthetskontroll av brunn på avloppsledning",
  "YBC.352": "Kontroll, avvägning av brunn på avloppsledning",
  "YBC.356": "Kontroll, avvägning av brunn på dränledning",
  "YBC.36": "Kontroll av trumma",
  "YBC.361": "Kontroll, avvägning av trumma",
  "YBC.362": "Deformationskontroll av trumma",
  "YBC.37": "Kontroll av gasledning",
  "YBC.371": "Tryck- och täthetskontroll av gasledning",
  "YBC.4": "Kontroll, injustering m m av anordning",
  "YBC.41": "Kontroll, injustering m m av pumpanordning",
  YC: "ANMÄLNINGS- OCH ANSÖKNINGSHANDLINGAR, TEKNISK DOKUMENTATION M M FÖR ANLÄGGNING",
  YCB: "ANMÄLNINGS- OCH ANSÖKNINGSHANDLINGAR FÖR ANLÄGGNING",
  "YCB.1": "Anmälningshandlingar för anläggning",
  "YCB.2": "Ansökningshandlingar för anläggning",
  YCC: "BYGGHANDLINGAR FÖR ANLÄGGNING",
  YCD: "RELATIONSHANDLINGAR FÖR ANLÄGGNING",
  "YCD.1": "Relationshandlingar för väg, plan, vegetationsyta, rörledning m m",
  "YCD.11": "Relationshandlingar för väg, plan o d samt vegetationsyta",
  "YCD.111": "Relationshandlingar för väg, plan o d",
  "YCD.112": "Relationshandlingar för vegetationsyta",
  "YCD.12": "Relationshandlingar för rörledningssystem",
  "YCD.2": "Relationshandlingar för bro, brygga, kaj o d",
  "YCD.21": "Relationshandlingar för bro",
  "YCD.22": "Relationshandlingar för kaj",
  "YCD.3": "Relationshandlingar för tunnel, bergrum o d",
  "YCD.4": "Relationshandlingar för grundläggning av hus",
  "YCD.5": "Relationshandlingar för järnväg",
  "YCD.6": "Relationshandlingar för markförstärkningar och geokonstruktion",
  YCE: "UNDERLAG FÖR RELATIONSHANDLINGAR FÖR ANLÄGGNING",
  "YCE.1":
    "Underlag för relationshandlingar för väg, plan, vegetationsyta, rörledning m m",
  "YCE.11":
    "Underlag för relationshandlingar för väg, plan o d samt vegetationsyta",
  "YCE.111": "Underlag för relationshandlingar för väg, plan o d",
  "YCE.112": "Underlag för relationshandlingar för vegetationsyta",
  "YCE.12": "Underlag för relationshandlingar för rörledningssystem",
  "YCE.2": "Underlag för relationshandlingar för bro, brygga, kaj o d",
  "YCE.21": "Underlag för relationshandlingar för bro",
  "YCE.22": "Underlag för relationshandlingar för kaj",
  "YCE.3": "Underlag för relationshandlingar för tunnel, bergrum o d",
  "YCE.4": "Underlag för relationshandlingar för grundläggning av hus",
  "YCE.5": "Underlag för relationshandlingar för järnväg",
  "YCE.6":
    "Underlag för relationshandlingar för markförstärkningar och geokonstruktion",
  YCF: "SÄKERHETSINSTRUKTIONER FÖR ANLÄGGNING",
  YCG: "UNDERLAG FÖR SÄKERHETSINSTRUKTIONER FÖR ANLÄGGNING",
  YCH: "DRIFTINSTRUKTIONER FÖR ANLÄGGNING",
  "YCH.1": "Driftinstruktioner för väg, plan, vegetationsyta, rörledning m m",
  "YCH.14": "Driftinstruktioner för rörledningar m m",
  "YCH.15": "Driftinstruktioner för pumpanordningar",
  "YCH.2": "Driftinstruktioner för bro, brygga, kaj o d",
  "YCH.3": "Driftinstruktioner för tunnel, bergrum o d",
  YCJ: "UNDERLAG FÖR DRIFTINSTRUKTIONER FÖR ANLÄGGNING",
  "YCJ.1":
    "Underlag för driftinstruktioner för väg, plan, vegetationsyta, rörledning m m",
  "YCJ.14": "Underlag för driftinstruktioner för rörledningar m m",
  "YCJ.15": "Underlag för driftinstruktioner för pumpanordningar",
  "YCJ.2": "Underlag för driftinstruktioner för bro, brygga, kaj o d",
  "YCJ.3": "Underlag för driftinstruktioner för tunnel, bergrum o d",
  YCK: "UNDERHÅLLSINSTRUKTIONER FÖR ANLÄGGNING",
  "YCK.1":
    "Underhållsinstruktioner för väg, plan, vegetationsyta, rörledning m m",
  "YCK.2": "Underhållsinstruktioner för bro, brygga, kaj o d",
  "YCK.21": "Underhållsinstruktioner för bro",
  "YCK.3": "Underhållsinstruktioner för tunnel, bergrum o d",
  YCL: "UNDERLAG FÖR UNDERHÅLLSINSTRUKTIONER FÖR ANLÄGGNING",
  "YCL.1":
    "Underlag för underhållsinstruktioner för väg, plan, vegetationsyta, rörledning m m",
  "YCL.2": "Underlag för underhållsinstruktioner för bro, brygga, kaj o d",
  "YCL.3": "Underlag för underhållsinstruktioner för tunnel, bergrum o d",
  YCM: "MILJÖDOKUMENTATION FÖR ANLÄGGNING",
  YCN: "UNDERLAG FÖR MILJÖDOKUMENTATION FÖR ANLÄGGNING",
  YCP: "INFORMATION TILL DRIFT- OCH UNDERHÅLLSPERSONAL FÖR ANLÄGGNING",
  "YCP.1":
    "Information till drift- och underhållspersonal för väg, plan, vegetationsyta, rörledning m m",
  "YCP.14":
    "Information till drift- och underhållspersonal för rörledningar m m",
  "YCP.15":
    "Information till drift- och underhållspersonal för pumpanordningar",
  YCQ: "KONTROLLPLANER FÖR ANLÄGGNING",
  "YCQ.1": "Kontrollplaner för byggande av anläggning",
  "YCQ.11": "Kontrollplaner för väg, plan, vegetationsyta, rörledning m m",
  "YCQ.111": "Kontrollplaner för väg, plan o d samt vegetationsyta",
  "YCQ.1111": "Kontrollplaner för väg, plan o d",
  "YCQ.1112": "Kontrollplaner för vegetationsytor",
  "YCQ.112": "Kontrollplaner för rörledningar m m",
  "YCQ.1121": "Kontrollplaner för rörledningar i ledningsnät",
  "YCQ.1122": "Kontrollplaner för platsgjutna konstruktioner i ledningsnät",
  "YCQ.12": "Kontrollplaner för bro, brygga, kaj o d",
  "YCQ.121": "Kontrollplaner för bro",
  "YCQ.122": "Kontrollplaner för kaj",
  "YCQ.13": "Kontrollplaner för tunnel, bergrum o d",
  "YCQ.14": "Kontrollplaner för grundläggning av hus",
  "YCQ.2": "Kontrollplaner för rivning av anläggning",
  YCR: "DOKUMENTATION AV TEKNISKA PRESTANDA FÖR ANLÄGGNING",
  "YCR.1":
    "Dokumentation av tekniska egenskaper för väg, plan, vegetationsyta, rörledning m m",
  "YCR.12": "Dokumentation av tekniska egenskaper för rörledningar m m",
  YE: "VERIFIERING AV ÖVERENSSTÄMMELSE MED KRAV PÅ PRODUKTER",
  YS: "MÄRKNING, KONTROLL, DOKUMENTATION M M I HUS",
  YSB: "MÄRKNING, SKYLTNING M M I HUS",
  "YSB.1": "Märkning i hus",
  "YSB.2": "Skyltning i hus",
  YSC: "KONTROLL OCH INJUSTERING M M I HUS",
  "YSC.1": "Kontroll i hus",
  "YSC.11": "Kontroll av täthet",
  "YSC.111": "Kontroll av lufttäthet",
  "YSC.112": "Kontroll av gastäthet",
  "YSC.1121": "Kontroll av rökgastäthet",
  "YSC.113": "Kontroll av vattentäthet",
  "YSC.1131": "Kontroll av vattentäthet i våtrum",
  "YSC.1132": "Kontroll av vattentäthet på ytterbjälklag och yttertak",
  "YSC.12": "Kontroll av fukt",
  "YSC.121": "Kontroll av relativ fuktighet (RF)",
  "YSC.122": "Kontroll av fuktkvot och ytfuktkvot",
  "YSC.13": "Kontroll av isolering",
  "YSC.131": "Kontroll av termisk isolering",
  "YSC.132": "Kontroll av ljudisolering",
  "YSC.14": "Kontroll av radonhalt",
  "YSC.15": "Kontroll av mekanisk hållfasthet",
  "YSC.16": "Kontroll av målning",
  "YSC.161": "Kontroll av byggplatsmålning",
  "YSC.162": "Kontroll av brandskyddsmålning",
  "YSC.1621": "Kontroll av brandskyddsmålning av stålkonstruktioner",
  "YSC.1622": "Kontroll av brandskyddsmålning av träkonstruktioner",
  "YSC.163": "Kontroll av rostskyddsmålning",
  "YSC.1631": "Kontroll av rostskyddsmålning av stålkonstruktioner",
  "YSC.1632": "Kontroll av rostskyddsmålning av plåt",
  "YSC.2": "Injustering i hus",
  "YSC.3": "Kontrollmätning i hus",
  YSD: "IDRIFTSÄTTNING AV HUS",
  "YSD.1": "Information till drift- och underhållspersonal för hus",
  YSK: "TEKNISK DOKUMENTATION FÖR HUS",
  "YSK.1": "Ansökningshandlingar för hus",
  "YSK.2": "Bygghandlingar för hus",
  "YSK.21": "Bygghandlingar för byggande av hus",
  "YSK.22": "Bygghandlingar för rivning av hus",
  "YSK.3": "Relationshandlingar för hus",
  "YSK.31": "Underlag för relationshandlingar för hus",
  "YSK.32": "Upprättade relationshandlingar för hus",
  "YSK.4": "Kontrollplaner för hus",
  "YSK.41": "Kontrollplaner för projektering av hus",
  "YSK.42": "Kontrollplaner för byggande av hus",
  "YSK.43": "Kontrollplaner för rivning av hus",
  "YSK.5": "Säkerhetsinstruktioner för hus",
  "YSK.6": "Driftinstruktioner för hus",
  "YSK.61": "Underlag för driftinstruktioner för hus",
  "YSK.62": "Upprättade driftinstruktioner för hus",
  "YSK.7": "Underhållsinstruktioner för hus",
  "YSK.71": "Underlag för underhållsinstruktioner för hus",
  "YSK.72": "Upprättade underhållsinstruktioner för hus",
  "YSK.8": "Deklaration av tekniska prestanda för hus",
  YSL: "MILJÖDOKUMENTATION FÖR HUS",
  "YSL.1": "Dokumentation av miljöpåverkan av varor och material i hus",
  "YSL.11": "Dokumentation av yttre miljöpåverkan av varor och material i hus",
  "YSL.12": "Dokumentation av inre miljöpåverkan av varor och material i hus",
  "YSL.2": "Miljöcertifiering av hus",
  "YSL.21": "Underlag för miljöcertifiering av hus",
  "YSL.22": "Upprättad miljöcertifiering av hus",
  YV: "MÄRKNING, KONTROLL, INJUSTERING M M AV VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  YVB: "MÄRKNING OCH SKYLTNING AV VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YVB.1":
    "Märkning och skyltning av inredningar, utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  "YVB.11":
    "Märkning av inredningar, utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  "YVB.12":
    "Skyltning av inredningar, utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  "YVB.4": "Märkning och skyltning av kylenheter och frysenheter",
  YVC: "KONTROLL OCH INJUSTERING AV VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YVC.1":
    "Kontroll och injustering av inredningar för stordriftsberedning, tillagning eller servering av livsmedel",
  "YVC.3":
    "Kontroll och injustering av utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  "YVC.4": "Kontroll och injustering av kylenheter och frysenheter",
  YX: "TEKNISK DOKUMENTATION M M FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  YXB: "ANMÄLNINGSHANDLINGAR OCH ANSÖKNINGSHANDLINGAR FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  YXC: "BYGGHANDLINGAR FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YXC.1":
    "Bygghandlingar för inredningar för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXC.3":
    "Bygghandlingar för utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  YXD: "RELATIONSHANDLINGAR FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YXD.1":
    "Relationshandlingar för inredningar för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXD.3":
    "Relationshandlingar för utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  YXE: "UNDERLAG FÖR RELATIONSHANDLINGAR FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YXE.1":
    "Underlag för relationshandlingar för inredningar för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXE.3":
    "Underlag för relationshandlingar för utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  YXF: "SÄKERHETSINSTRUKTIONER FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YXF.1":
    "Säkerhetsinstruktioner för inredningar för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXF.3":
    "Säkerhetsinstruktioner för utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  YXH: "DRIFTINSTRUKTIONER FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YXH.1":
    "Driftinstruktioner för inredningar för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXH.3":
    "Driftinstruktioner för utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXH.4": "Driftinstruktioner för kylenheter och frysenheter",
  YXJ: "UNDERLAG FÖR DRIFTINSTRUKTIONER FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YXJ.1":
    "Underlag för driftinstruktioner för inredningar för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXJ.3":
    "Underlag för driftinstruktioner för utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXJ.4": "Underlag för driftinstruktioner för kylenheter och frysenheter",
  YXK: "UNDERHÅLLSINSTRUKTIONER FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YXK.1":
    "Underhållsinstruktioner för inredningar för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXK.3":
    "Underhållsinstruktioner för utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXK.4": "Underhållsinstruktioner för kylenheter och frysenheter",
  YXL: "UNDERLAG FÖR UNDERHÅLLSINSTRUKTIONER FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YXL.1":
    "Underlag för underhållsinstruktioner för inredningar för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXL.3":
    "Underlag för underhållsinstruktioner för utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  "YXL.4":
    "Underlag för underhållsinstruktioner för kylenheter och frysenheter",
  YXM: "MILJÖDOKUMENTATION FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YXM.1": "Dokumentation av miljöpåverkan av varor och material i hus",
  "YXM.2": "Miljöcertifiering av hus",
  "YXM.21": "Underlag för miljöcertifiering av hus",
  "YXM.22": "Upprättad miljöcertifiering av hus",
  YXN: "UNDERLAG FÖR MILJÖDOKUMENTATION FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YXN.2":
    "Underlag för miljöbedömning för verksamhetsanknutna inredningar och utrustningar",
  YXP: "INFORMATION TILL DRIFT- OCH UNDERHÅLLSPERSONAL FÖR VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YXP.3":
    "Information till drift- och underhållspersonal för utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  YY: "ARBETEN EFTER SLUTBESIKTNING",
  YYS: "TILLSYN, SKÖTSEL OCH UNDERHÅLL I HUS",
  YYX: "TILLSYN, SKÖTSEL OCH UNDERHÅLL AV VERKSAMHETSANKNUTNA INREDNINGAR OCH UTRUSTNINGAR",
  "YYX.1":
    "Tillsyn, skötsel och underhåll av inredningar för stordriftsberedning, tillagning eller servering av livsmedel",
  "YYX.3":
    "Tillsyn, skötsel och underhåll av utrustningar och maskiner för stordriftsberedning, tillagning eller servering av livsmedel",
  Z: "DIVERSE TÄTNINGAR, KOMPLETTERINGAR, INFÄSTNINGAR O D",
  ZB: "DIVERSE TÄTNINGAR, KOMPLETTERINGAR, INFÄSTNINGAR O D I ANLÄGGNING",
  ZBB: "TÄTNING AV FOGAR I ANLÄGGNING",
  "ZBB.1": "Tätning av rörelsefogar m m",
  "ZBB.11": "Tätning med fogmassa",
  "ZBB.111": "Tätning av rörelsefogar med fogmassa",
  "ZBB.1114": "Tätning av rörelsefogar i däck med fogmassa",
  "ZBB.112": "Tätning av dilatationsfogar med fogmassa",
  "ZBB.12": "Tätning med foglist",
  "ZBB.121": "Tätning av rörelsefogar med foglist",
  "ZBB.1214": "Tätning av rörelsefogar i däck med foglist",
  "ZBB.122": "Tätning av dilatationsfogar med foglist",
  ZBC: "TÄTNING AV GENOMFÖRINGAR I ANLÄGGNING",
  ZBD: "KOMPLETTERINGAR AV NÄT, TRÅD M M I ANLÄGGNING",
  "ZBD.1": "Kompletteringar av nät",
  ZBE: "FÄSTDON I ANLÄGGNING",
  ZS: "DIVERSE TÄTNINGAR, KOMPLETTERINGAR, INFÄSTNINGAR O D I HUS",
  ZSB: "TÄTNING AV FOGAR I HUS",
  "ZSB.1": "Tätning av rörelsefogar m m i hus",
  "ZSB.11": "Tätning med fogmassa",
  "ZSB.111": "Tätning av rörelsefogar med fogmassa",
  "ZSB.1111": "Tätning av utvändiga rörelsefogar i yttervägg med fogmassa",
  "ZSB.1112": "Tätning av invändiga rörelsefogar i yttervägg med fogmassa",
  "ZSB.1113": "Tätning av rörelsefogar i innervägg med fogmassa",
  "ZSB.1114": "Tätning av utvändiga rörelsefogar i ytterbjälklag med fogmassa",
  "ZSB.1115": "Tätning av invändiga rörelsefogar i ytterbjälklag med fogmassa",
  "ZSB.1116": "Tätning av invändiga rörelsefogar i golv med fogmassa",
  "ZSB.112": "Tätning av dilatationsfogar med fogmassa",
  "ZSB.1121": "Tätning av utvändiga dilatationsfogar med fogmassa",
  "ZSB.1122": "Tätning av invändiga dilatationsfogar med fogmassa",
  "ZSB.12": "Tätning med foglist",
  "ZSB.121": "Tätning av rörelsefogar med foglist",
  "ZSB.1211": "Tätning av utvändiga rörelsefogar i yttervägg med foglist",
  "ZSB.1212": "Tätning av invändiga rörelsefogar i yttervägg med foglist",
  "ZSB.1213": "Tätning av rörelsefogar i innervägg med foglist",
  "ZSB.1214": "Tätning av utvändiga rörelsefogar i ytterbjälklag med foglist",
  "ZSB.1215": "Tätning av invändiga rörelsefogar i ytterbjälklag med foglist",
  "ZSB.1216": "Tätning av invändiga rörelsefogar i golv med foglist",
  "ZSB.122": "Tätning av dilatationsfogar med foglist",
  "ZSB.1221": "Tätning av utvändiga dilatationsfogar med foglist",
  "ZSB.1222": "Tätning av invändiga dilatationsfogar med foglist",
  "ZSB.13": "Tätning av fogar med fogband",
  "ZSB.131": "Tätning av fogar med expanderande fogband",
  "ZSB.2": "Brandtätning av fogar i hus",
  "ZSB.21": "Brandtätning av utvändiga fogar",
  "ZSB.22": "Brandtätning av invändiga fogar",
  ZSC: "TÄTNING AV GENOMFÖRINGAR I HUS",
  "ZSC.1": "Fukt- och lufttätning av genomföringar i hus",
  "ZSC.2": "Brandtätning av genomföringar i hus",
  ZSD: "KOMPLETTERINGAR AV NÄT, TRÅD M M I HUS",
  "ZSD.1": "Kompletteringar av nät i hus",
  "ZSD.11": "Insektsnät i ventilationsöppningar",
  "ZSD.12": "Nät för skydd mot fåglar",
  "ZSD.13": "Nät för skydd mot råttor och möss",
  ZSE: "FÄSTDON I HUS",
};
