import moment from "moment";
import { engToSwe } from "./english-to-swedish";
import { noCase } from "no-case";

export const date = (date: any) =>
  moment(date, moment.ISO_8601).format("YYYY-MM-DD");

export const amountInput = (amount: any) => {
  return Number.parseFloat(`${amount}`.replace(",", "."));
};
export function weight(amount: number) {
  return amount > 1000
    ? `${(Math.round(amount * 100) / 100 / 1000).toLocaleString("en-GB")} ton`
    : `${Math.round(amount).toLocaleString("en-GB")} kg`;
}

export function amount(amount: number) {
  return (Math.round(amount * 100) / 100).toLocaleString("en-GB");
}

export function unit(unit: string, thickness: number, unitWeight: number) {
  if (unit === "m3") {
    return "m³";
  } else if (unit === "m2") {
    return `m² × ${thickness} mm`;
  } else if (unit === "st") {
    return `st × ${unitWeight} kg`;
  } else if (unit === "m" && thickness) {
    return `m × ${thickness}mm x ${unitWeight}mm`;
  } else if (unit === "m") {
    return `m × ${unitWeight} kg/m`;
  }

  return unit;
}

export function replaceRestInRestRow(restRow?: object): any | undefined {
  if (restRow == null) {
    return undefined;
  }
  return Object.entries(restRow).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value === "Rest" ? "-" : value,
    };
  }, {});
}

export const toSE = (string: string) =>
  string &&
  // @ts-ignore
  (engToSwe[string] == null ? noCase(string) : engToSwe[string] || "");
