export default function LayerGrout(props: any) {
  return (
    <svg
      height={60}
      viewBox="2.4 -41.38 86.71 182.77"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Hatches" transform="matrix(0, 1, -1, 0, 96, -50)">
        <path
          stroke="black"
          fill={"none"}
          d="M180.1,81a3.62,3.62,0,0,1-1.86.77"
        />
        <path stroke="black" fill={"none"} d="M175.65,79.53l.19-.37" />
        <path stroke="black" fill={"none"} d="M177.31,71v-.19" />
        <path
          stroke="black"
          fill={"none"}
          d="M152.94,64.48a3.86,3.86,0,0,0-1.88.5"
        />
        <path
          stroke="black"
          fill={"none"}
          d="M150.69,69.63a4.26,4.26,0,0,1,1.69-.35"
        />
        <path stroke="black" fill={"none"} d="M155.94,78.22l-.19.75" />
        <path stroke="black" fill={"none"} d="M166,66.12v.19" />
        <path stroke="black" fill={"none"} d="M177.12,51.61l-.18.19" />
        <path stroke="black" fill={"none"} d="M181.77,62l.19.19" />
        <path stroke="black" fill={"none"} d="M188.47,73.75h.19" />
        <path stroke="black" fill={"none"} d="M96.94,50.12h0" />
        <path stroke="black" fill={"none"} d="M115.35,51.43h-.18" />
        <path stroke="black" fill={"none"} d="M114.24,64.26v-.18" />
        <path stroke="black" fill={"none"} d="M104,75.23l-.93.92" />
        <path stroke="black" fill={"none"} d="M127.8,68.88l.75-.54" />
        <path stroke="black" fill={"none"} d="M124.84,80.63l-.18.19" />
        <path stroke="black" fill={"none"} d="M136.75,86.77h-.93" />
        <path stroke="black" fill={"none"} d="M67.73,85.84H66.8" />
        <path stroke="black" fill={"none"} d="M59,71c0-.15-.31-.15-.34,0" />
        <path stroke="black" fill={"none"} d="M48.39,50.33l-.75.36" />
        <path
          stroke="black"
          fill={"none"}
          d="M63.18,36.31a2.41,2.41,0,0,1,.84-.87"
        />
        <path stroke="black" fill={"none"} d="M136,31.71l.93-.19" />
        <path stroke="black" fill={"none"} d="M159.08,26.7l1.12-.57" />
        <path
          stroke="black"
          fill={"none"}
          d="M177.5,20.92a1.59,1.59,0,0,1,1.11.17"
        />
        <path
          stroke="black"
          fill={"none"}
          d="M180.66,30.58a7.51,7.51,0,0,1,2.43,1.3"
        />
        <path stroke="black" fill={"none"} d="M153.69,37.12l-2,1.12" />
        <path stroke="black" fill={"none"} d="M108.48,20.76l-.75.18" />
        <path stroke="black" fill={"none"} d="M98.23,14.39l-.56.37" />
        <path
          stroke="black"
          fill={"none"}
          d="M58.42,13.48a2.85,2.85,0,0,0-1.5.14"
        />
        <path stroke="black" fill={"none"} d="M31.07,17.34l-1.67.41" />
        <path
          stroke="black"
          fill={"none"}
          d="M17.26,52.86a3.59,3.59,0,0,1,1.18-.65"
        />
        <path stroke="black" fill={"none"} d="M15.08,82.68l.37-.19" />
        <path stroke="black" fill={"none"} d="M32.56,65.19h.19" />
        <path
          stroke="black"
          fill={"none"}
          d="M62.68,51.78c.06-.1-.2-.09-.14,0"
        />
        <path
          stroke="black"
          fill={"none"}
          d="M73.49,66.68a.29.29,0,0,0-.36.19"
        />
        <path
          stroke="black"
          fill={"none"}
          d="M77.77,81.38a.28.28,0,0,1,.36.19"
        />
        <path stroke="black" fill={"none"} d="M93.61,88.79l1.29.76" />
        <path stroke="black" fill={"none"} d="M156.29,89.56h0" />
        <path stroke="black" fill={"none"} d="M180.47,91.05h.37" />
        <path stroke="black" fill={"none"} d="M185.68,44.36l.19-.38" />
        <path stroke="black" fill={"none"} d="M188.28,14l.21-.36" />
        <path
          stroke="black"
          fill={"none"}
          d="M161.31,13.1a.27.27,0,0,1-.17-.36"
        />
        <path stroke="black" fill={"none"} d="M129.49,13.85h-.55" />
        <path stroke="black" fill={"none"} d="M93.59,28.36H93.4" />
        <path
          stroke="black"
          fill={"none"}
          d="M150.12,50.11c.06-.1-.2-.1-.14,0"
        />
        <path stroke="black" fill={"none"} d="M133,49h-.37" />
        <path stroke="black" fill={"none"} d="M124.29,39.15h-.19" />
        <path stroke="black" fill={"none"} d="M101.22,36.91l.18.19" />
        <path stroke="black" fill={"none"} d="M72.38,44.17h0" />
        <path stroke="black" fill={"none"} d="M89.68,68.73l.19-.37" />
        <path stroke="black" fill={"none"} d="M88.94,77.66h0" />
        <path stroke="black" fill={"none"} d="M115.76,89.18a1,1,0,0,0,.9.57" />
        <path stroke="black" fill={"none"} d="M37.22,88.26v.19" />
        <path stroke="black" fill={"none"} d="M25.12,82.87h.19" />
        <path stroke="black" fill={"none"} d="M19.17,65.19H19" />
        <path stroke="black" fill={"none"} d="M45,79v-.19" />
        <path stroke="black" fill={"none"} d="M23.47,24.24l-.38-.37" />
        <path stroke="black" fill={"none"} d="M16.19,35.61h-.37" />
        <path stroke="black" fill={"none"} d="M12.1,12.35l-.56.37" />
        <path stroke="black" fill={"none"} d="M38.52,38.59l-.19.37" />
        <path stroke="black" fill={"none"} d="M31.82,54.59l.19.18" />
        <path stroke="black" fill={"none"} d="M59.36,57.75h-.19" />
        <path stroke="black" fill={"none"} d="M73.12,24.63v-.18" />
        <path
          stroke="black"
          fill="none"
          d="M138.05,77.79a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M112.19,53.6a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M136.56,38.9a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M167.82,53.23a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M163.17,73.51a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M171,63.83a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M181.4,75.74a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M189,90.44a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M186.61,45a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M175.82,28.67a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M189,22.34a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M190.89,54.16a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M78.33,67.37a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M81.49,55.65a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M80.94,27.55a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M81.31,14.9a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M113.68,30.16a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M118.89,14.53a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M113.87,9.88a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M143.08,9.69a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M139.54,19.18a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M145.68,28.67a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M141.4,58.44a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M135.82,64.2a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M149,85.41a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M77.22,89.13a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M59.17,88.58a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M42.8,59.55a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M42.05,22.72a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M41.12,14.16a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M51.36,25.69a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M51.73,41.51a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M86.15,38.72a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M92.84,56.39a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M16.19,79.46a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M11.54,92.48a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M28.1,93.6a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M22,9.13a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M29.59,10.06a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M32.94,45a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M9.12,47.46a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M9.49,27.18a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M22.52,31.65a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M31.63,69.6a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M43.36,75.18a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M45.77,86.9a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M120.19,73.32a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M107,86.16a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M98.8,60.3a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M109.22,38.34a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M87.63,14.34a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M49.31,8.89a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M126.7,57.82a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M174.89,12.43a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M14,74.75a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M25.31,40.52a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M100.29,28.24a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M166.89,91.87a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M74.42,10.19a1,1,0,0,0,0-2,1,1,0,0,0,0,2Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M64,19.55a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M73.68,35.18a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M68.84,54.34a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M56.25,79.52v.37a.41.41,0,0,0,0,.19.53.53,0,0,0,.27.27.41.41,0,0,0,.19,0l.13,0a.38.38,0,0,0,.22-.13l.08-.1a.45.45,0,0,0,.07-.25v-.37a.41.41,0,0,0,0-.19.39.39,0,0,0-.11-.17.44.44,0,0,0-.16-.1.41.41,0,0,0-.19,0l-.13,0a.43.43,0,0,0-.22.12l-.08.11a.43.43,0,0,0-.07.25Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M22,90.44a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
        <path
          stroke="black"
          fill="none"
          d="M9.49,66.25a.5.5,0,0,0,0-1,.5.5,0,0,0,0,1Z"
        />
      </g>
    </svg>
  );
}
