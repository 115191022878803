import { Box, Theme } from "@material-ui/core";
import { ReportFooter } from "../components/report-footer";
import { ReportContainer } from "../components/report-container";
import { ReportHeader } from "../components/report-header";
import { ReportBodyAlignSpaceBetween } from "../components/body";
import { ReportTable, IReportTableProps } from "../components/table";
import { ReportTypography } from "../components/report-typography";
import { useProjectQuery } from "../../../lib/generated/graphql";
import { omitRedundantFields } from "./report-gwp-summary-page";
import { makeStyles } from "@material-ui/styles";
import { bsabCodes } from "../bsab-codes";
import useDsrQueryHook from "../hooks/useDsrQueryHook";

import { ReportPageProps } from "../../report-page";
import { rounderInt, rounderTwo } from "../../../lib/rounder";

const useStyles = makeStyles((theme: Theme) => ({
  bimImage: {
    maxWidth: 327,
    maxHeight: 179,
  },
}));

export default function ReportBSABImagePage({
  pageNumber,
  projectId,
  scenarioId,
  selectedDatasetIds,
}: ReportPageProps) {
  const chapter = "Resultat";
  const { bimImage } = useStyles();
  const project = useProjectQuery({ variables: { projectId } })?.data?.project;

  const { data } = useDsrQueryHook({
    filter: [],
    group: [{ col: "ProductBSAB" }],
    aggregations: [
      { col: "A1_A5PerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "weightPerBTA", op: "sum", reduceAggOp: "sum" },
      { col: "weight", op: "sum", reduceAggOp: "sum" },
    ],
    sort: [{ col: "A1_A5PerBTA", desc: true }],
    topN: 8,
  });

  const rows = omitRedundantFields(data).map(
    ({ weight = 0, ProductBSAB = "-", ...row }: any) => ({
      ...row,
      ProductBSAB: bsabCodes[ProductBSAB ?? "-"] || "Saknar BSAB96-kod",
      ProductBSABCode: ProductBSAB,
      weightPerBTA: rounderInt(row.weightPerBTA),
      A1_A5PerBTA: rounderTwo(row.A1_A5PerBTA),
    })
  );

  const columns: IReportTableProps["columns"] = [
    {
      label: "BSAB96-kod",
      value: "ProductBSABCode",
      width: "12%",
      align: "left",
    },
    {
      label: "Beskrivning",
      value: "ProductBSAB",
      width: "60%",
      align: "left",
    },
    {
      label: "Vikt material, kg/BTA",
      value: "weightPerBTA",
      align: "right",
    },
    {
      label: "kg CO₂e/BTA",
      value: "A1_A5PerBTA",
      align: "right",
    },
  ];

  return (
    <ReportContainer>
      <ReportHeader chapter={chapter} />
      <ReportBodyAlignSpaceBetween>
        <Box mt={5} mb={1} textAlign="left">
          <ReportTypography variant="h3">
            BSAB96-koder med högst påverkan
          </ReportTypography>
        </Box>
        <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
          {(project?.images ?? []).map(({ data: { url }, description }) => (
            <Box key={url}>
              <img className={bimImage} src={url ?? ""} />
              <Box textAlign="center">
                <ReportTypography variant="caption">
                  {description}
                </ReportTypography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box flexGrow={1}>
          <ReportTable
            showSumRow={false}
            columns={columns}
            hasRawMaterials={true}
            rows={rows}
          />
        </Box>
      </ReportBodyAlignSpaceBetween>
      <ReportFooter pageNumber={pageNumber} skipPaddingTop />
    </ReportContainer>
  );
}
